import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { useFormActions } from "../FormActionsProvider";

interface CreateSchema {
  label: string;
  rate: number;
}

interface UpdateSchema {
  label: string;
}

export const useCreateFormDefinition = () => {
  const { setAllowCreateAdditional } = useFormActions();

  setAllowCreateAdditional(true);

  return new FormDefinitionBuilder<CreateSchema>()
    .text("label", {
      label: "Tax label",
      required: true,
    })
    .decimal("rate", {
      label: "Tax amount",
      required: true,
      decimalPlaces: 3,
      prefix: "%",
    })
    .getDefinition();
};

export const useUpdateFormDefinition = () => {
  return new FormDefinitionBuilder<UpdateSchema>()
    .text("label", {
      label: "Tax label",
      required: true,
    })
    .getDefinition();
};

export const makeCreateRequest =
  ({ api }: { api: Api }) =>
  (data: CreateSchema) => {
    return api.taxRates.createTaxRate({
      createTaxRateRequest: data,
    });
  };

export const makeUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (data: UpdateSchema) => {
    return api.taxRates.updateTaxRate({
      id,
      updateTaxRateRequest: data,
    });
  };
