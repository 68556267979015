import { ReactNode } from "react";

import { colors } from "../../colors";
import { useGenericComponents } from "../GenericComponentsProvider";

export type SaveState = "saving" | "saved" | "error" | "none";

export const SaveStateIndicator = ({ saveState }: { saveState: SaveState }) => {
  const { Icon, Text, View } = useGenericComponents();
  const states: Record<SaveState, { left: ReactNode; right: ReactNode }> = {
    none: {
      left: null,
      right: null,
    },
    saving: {
      left: (
        <View className="w-6 h-6 flex items-center justify-center">
          <Icon name="syncOutline" color={colors.grey[600]} size={24} />
        </View>
      ),
      right: <Text className="text-label-400 text-grey-600">Saving</Text>,
    },
    saved: {
      left: (
        <Icon
          name="checkmarkCircleOutline"
          color={colors.grey[600]}
          size={24}
        />
      ),
      right: <Text className="text-label-400 text-grey-600">Saved</Text>,
    },
    error: {
      left: (
        <Icon name="closeCircleOutline" color={colors.red[600]} size={24} />
      ),
      right: <Text className="text-label-400 text-red-600">Error</Text>,
    },
  };

  const { left, right } = states[saveState];

  return (
    <View className="flex flex-row items-center space-x-2">
      <View>{left}</View>
      <View>{right}</View>
    </View>
  );
};
