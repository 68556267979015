import { SortSchema } from "@justraviga/classmanager-sdk";

import { Api } from "../api";
import { uniqueValuesForKey } from "../collectionUtils";
import { DatatableRequest } from "../datatable";

export const fetchTrialsData = async ({
  api,
  request,
  classId,
  studentId,
}: {
  api: Api;
  request: DatatableRequest;
  classId?: string;
  studentId?: string;
}) => {
  const { where, ...rest } = request;

  const classWhere = classId ? { classId: { equals: classId } } : undefined;

  const studentWhere = studentId
    ? { studentId: { equals: studentId } }
    : undefined;

  const hasSort = rest.sort !== undefined && Object.keys(rest.sort).length > 0;

  const trialsResponse = await api.trials.listTrial({
    ...rest,
    sort: hasSort
      ? rest.sort
      : { trialAt: SortSchema.Desc, time: SortSchema.Desc },
    selectAll: true,
    where: { ...where, ...classWhere, ...studentWhere },
  });

  const classIds = uniqueValuesForKey("classId", trialsResponse.data);
  const studentIds = uniqueValuesForKey("studentId", trialsResponse.data);
  const dates = uniqueValuesForKey("trialAt", trialsResponse.data);

  const classesPromise = api.courses.listCourse({
    where: { id: { in: classIds } },
  });

  const studentsPromise = api.students.listStudent({
    where: { id: { in: studentIds } },
    includeArchived: true,
    selectAll: true,
  });

  const attendancesPromise = api.attendances.listAttendance({
    where: {
      classId: { in: classIds },
      date: { in: dates },
    },
    selectAll: true,
  });

  const [{ data: classes }, { data: students }, { data: attendances }] =
    await Promise.all([classesPromise, studentsPromise, attendancesPromise]);

  const seasonIds = uniqueValuesForKey(
    "seasonId",
    classes.map(c => c.entity),
  );
  const { data: seasons } = await api.seasons.listSeason({
    where: { id: { in: seasonIds } },
    selectAll: true,
  });

  return {
    ...trialsResponse,
    data: trialsResponse.data.map(trial => {
      const classDto = classes.find(c => c.entity.id === trial.classId)!;
      return {
        ...trial,
        attendance: attendances.find(
          a =>
            a.classId === trial.classId &&
            a.studentId === trial.studentId &&
            a.date === trial.trialAt,
        ),
        class: classDto,
        student: students.find(s => s.id === trial.studentId)!,
        season: seasons.find(s => s.id === classDto.entity.seasonId)!,
      };
    }),
  };
};
