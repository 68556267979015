import { useAuthState } from "../../../AuthStateProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const DashboardPageHeader = () => {
  const { Text, View } = useGenericComponents();
  const { user } = useAuthState();

  return (
    <View className={"flex flex-row items-center justify-between pb-5"}>
      <Text className={"text-heading4-600 md:text-heading3-600"}>
        Hello, {user?.firstname} 👋
      </Text>
      {/*{!isDesktop && (*/}
      {/*  <View>*/}
      {/*    <QuickActionButton*/}
      {/*      icon={"addOutline"}*/}
      {/*      onClick={showCreateMenu}*/}
      {/*      variant={"brand"}*/}
      {/*      size={"sm"}*/}
      {/*    />*/}
      {/*  </View>*/}
      {/*)}*/}
      {/*{isDesktop && (*/}
      {/*  <View>*/}
      {/*    <Button*/}
      {/*      variant={"brand"}*/}
      {/*      size={"sm"}*/}
      {/*      leftIcon={"addCircleOutline"}*/}
      {/*      text={"Create new"}*/}
      {/*      onClick={showCreateMenu}*/}
      {/*    />*/}
      {/*  </View>*/}
      {/*)}*/}
    </View>
  );
};
