import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  isLoading: boolean;
  title: string;
  totalCount: number;
  recentCount: number;
}

export const StatCountCard = ({
  isLoading,
  title,
  totalCount,
  recentCount,
}: Props) => {
  const { View, Text, Icon, BaseCard } = useGenericComponents();

  if (isLoading) {
    return (
      <BaseCard
        title={title}
        bodySlot={
          <View className={"flex"}>
            <Text>Loading...</Text>
          </View>
        }
      />
    );
  }

  return (
    <BaseCard
      title={title}
      description={`${totalCount}`}
      descriptionSize={24}
      descriptionWeight={600}
      descriptionColor={"grey-900"}
      footerSlot={
        <View className={"flex flex-row items-center"}>
          <Icon name={"arrowUpCircleOutline"} />
          <Text> {recentCount} in last 28 days</Text>
        </View>
      }
    />
  );
};
