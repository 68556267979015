import { ContentPlaceholder } from "shared/components";

import { Button } from "@/modules/common/ui/button/Button";
import { useDiscountSchemeActions } from "@/modules/company/billing/discountSchemes/useDiscountSchemeActions";

export const NoDiscountSchemePlaceholder = () => {
  const { showCreateForm } = useDiscountSchemeActions();

  return (
    <div className={"mx-auto max-w-xl"}>
      <ContentPlaceholder
        icon={"helpCircleOutline"}
        title={"No discount schemes yet"}
        description={
          "You can use them to apply discounts on tuition for multiple students, multiple classes or a combination."
        }
        action={
          <div className={"flex flex-col items-center space-y-8"}>
            <Button
              variant={"brand"}
              size={"sm"}
              onClick={showCreateForm}
              text={"Create"}
            />
          </div>
        }
      />
    </div>
  );
};
