import { useStripeStatus } from "../../../../payments/useStripeStatus";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { basePath } from "../../../../routeUtils";
import { useAuthState } from "../../../AuthStateProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const LinksWidget = () => {
  const { View, BaseCard } = useGenericComponents();
  const { account } = useAuthState();

  return (
    <BaseCard
      collapsible={true}
      title={"Links"}
      bodySlot={
        <View className={"space-y-5"}>
          <View>
            <LinkLine
              title="Enrollment"
              relativeUrl={account!.company!.slug}
              isProtected
            />
          </View>
          <View>
            <LinkLine title="Login" relativeUrl={"login"} />
          </View>
        </View>
      }
    />
  );
};

const LinkLine = ({
  title,
  relativeUrl,
  isProtected = false,
}: {
  title: string;
  relativeUrl: string;
  isProtected?: boolean;
}) => {
  const { View, Text, IconButton, ProtectedOverlay } = useGenericComponents();
  const { protector: stripeProtector } = useStripeStatus();
  const { copyToClipboard } = getPlatformFunctions();
  const url = basePath().concat(relativeUrl);

  const iconButton = (
    <IconButton
      icon={"linkOutline"}
      variant={"secondary-outline"}
      size={"sm"}
      onClick={() => copyToClipboard(url, "Link copied to clipboard")}
    />
  );

  return (
    <View
      className={
        "flex flex-row justify-between items-end space-x-2 max-w-full"
      }>
      <View className={"flex-1 space-y-2"}>
        <Text className={"font-semibold"}>{title}</Text>
        <Text truncate={true}>{url}</Text>
      </View>
      <View>
        {isProtected ? (
          <ProtectedOverlay protector={stripeProtector}>
            {iconButton}
          </ProtectedOverlay>
        ) : (
          iconButton
        )}
      </View>
    </View>
  );
};
