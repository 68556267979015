import { ReactNode } from "react";

interface InputDescriptionProps {
  description?: ReactNode;
}

export const InputDescription = ({ description }: InputDescriptionProps) => {
  return (
    <>
      {description && (
        <div
          className={`mt-2 whitespace-pre-line text-caption-400 text-grey-600`}>
          {description}
        </div>
      )}
    </>
  );
};
