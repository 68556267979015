import { FilterFormDefinition } from "./formBuilderTypes";
import {
  CheckboxWithTextProps,
  DatePickerProps,
  DecimalInputProps,
  IntegerInputProps,
  SwitchProps,
  TextInputProps,
} from "./formComponentProps";
import { FormDefinitionBuilder } from "./formDefinitionBuilder";
import { MultiSelectProps, SelectProps } from "../components/interfaces";
import { DatatableFilterOperator } from "../datatable/datatableTypes";

export interface FilterOptions<RequestSchema extends object> {
  filterField: keyof RequestSchema | "isArchived";
  operator: DatatableFilterOperator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformer?: (value: any) => any;
}

export class FilterFormDefinitionBuilder<
  FormSchema extends object,
  WhereParam extends object,
> {
  private readonly formDefinitionBuilder: FormDefinitionBuilder<
    FormSchema & { isArchived?: boolean }
  >;
  private readonly filterOptions: Record<
    keyof FormSchema | "isArchived",
    FilterOptions<WhereParam>
  >;
  private _hasArchiveFilter: boolean;

  constructor() {
    this.formDefinitionBuilder = new FormDefinitionBuilder<
      FormSchema & { isArchived?: boolean }
    >();
    this.filterOptions = {} as Record<
      keyof FormSchema | "isArchived",
      FilterOptions<WhereParam>
    >;
    this._hasArchiveFilter = false;
  }

  text(
    name: keyof FormSchema,
    props: TextInputProps,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.text(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  integer(
    name: keyof FormSchema,
    props: IntegerInputProps,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.integer(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  decimal(
    name: keyof FormSchema,
    props: DecimalInputProps,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.decimal(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  checkbox(
    name: keyof FormSchema,
    props: CheckboxWithTextProps,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.checkbox(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  switch(
    name: keyof FormSchema | "isArchived",
    props: SwitchProps,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.switch(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  date(
    name: keyof FormSchema,
    props: Omit<DatePickerProps, "value">,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.date(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  select(
    name: keyof FormSchema,
    props: SelectProps,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.select(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  multiSelect(
    name: keyof FormSchema,
    props: MultiSelectProps,
    filterOptions: FilterOptions<WhereParam>,
  ) {
    this.formDefinitionBuilder.multiSelect(name, props);
    this.filterOptions[name] = filterOptions;
    return this;
  }

  withArchiveFilter() {
    return this.switch(
      "isArchived",
      {
        label: "Show only archived items",
      },
      {
        filterField: "isArchived",
        operator: DatatableFilterOperator.Equals,
      },
    );
  }

  group(
    heading: string,
    fields: Array<keyof FormSchema>,
    { advanced }: { advanced: boolean } = { advanced: false },
  ) {
    this.formDefinitionBuilder.group(heading, fields, { advanced });
    return this;
  }

  row(fields: Array<keyof FormSchema>, columnWidthsInPercent: number[] = []) {
    this.formDefinitionBuilder.row(fields, columnWidthsInPercent);
    return this;
  }

  getDefinition() {
    return {
      formDefinition: this.formDefinitionBuilder.getDefinition(),
      filterOptions: this.filterOptions,
      hasArchiveFilter: this._hasArchiveFilter,
    } as FilterFormDefinition<FormSchema, WhereParam>;
  }
}
