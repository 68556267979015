import {
  AggregatePaymentLinkSuccessDto,
  AggregateTransactionDto,
  CheckoutOperationRequest,
} from "@justraviga/classmanager-sdk";

import { showAlert } from "../alertState";
import { Api } from "../api";

export interface CheckoutPaymentHooks {
  onSuccess: (
    result: AggregateTransactionDto[],
    checkoutOperation: CheckoutOperationRequest,
  ) => void;
}

export async function checkoutPayment(
  api: Api,
  checkoutOperation: CheckoutOperationRequest,
  hooks?: CheckoutPaymentHooks,
): Promise<AggregateTransactionDto[]> {
  const { onSuccess } = hooks || {};

  return api.basket
    .checkout(checkoutOperation)
    .then(result => {
      showAlert({
        content: "The payment was successful",
      });
      onSuccess?.(result, checkoutOperation);
      return result;
    })
    .catch(e => {
      showAlert({
        content: `There was an error while taking payment: ${e.message || e.messages?.[0]}`,
        variant: "error",
      });
      return [];
    });
}

interface PaymentLinksHooks {
  onSuccess: (result: AggregatePaymentLinkSuccessDto) => void;
}

export async function checkoutPublicPayment(
  api: Api,
  paymentLinkId: string,
  paymentIntentId: string,
  hooks?: PaymentLinksHooks,
): Promise<AggregatePaymentLinkSuccessDto | null> {
  const { onSuccess } = hooks || {};
  return api.pay
    .processPaymentLink({
      paymentLinkId,
      processPaymentLinkRequest: {
        paymentIntentId,
      },
    })
    .then(result => {
      showAlert({
        content: "The payment was successful",
      });
      onSuccess?.(result);
      return result;
    })
    .catch(e => {
      showAlert({
        content: `There was an error while taking payment: ${e.message || e.messages?.[0]}`,
        variant: "error",
      });
      return null;
    });
}
