import { UserAccountDto } from "@justraviga/classmanager-sdk";

import { AccountSelectionContent } from "shared/components";

import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/modules/common/overlays/dialog/Dialog";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Version } from "@/modules/common/Version";
import { CompanyLogo } from "@/modules/company/CompanyLogo";
import { useAccountSelectionDialog } from "@/modules/company/switcher/accountSelectionDialogHooks";
import { Router } from "@/routing/router";

export interface AccountSelectionDialogProps {
  account: UserAccountDto | null;
}

export const AccountSelectionDialog = ({
  account,
}: AccountSelectionDialogProps) => {
  const { isOpen, setOpen } = useAccountSelectionDialog();

  const goToLogin = () => Router.push("Login");

  const handleAccountChange = () => {
    setOpen(false);
    // We leave this deliberately vague, to allow the router to work out where they should land
    Router.push("Home");
  };

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      {account && (
        <DialogTrigger
          data-testid="account-selection-dialog-trigger"
          className="hidden md:flex"
          asChild>
          <Button
            text={account.company?.name ?? "Admin"}
            variant="tertiary"
            isFullWidth={false}
            rightIcon="chevronDown"
          />
        </DialogTrigger>
      )}
      <DialogContent
        data-testid="account-selection-dialog-content"
        className={`fixed h-[100vh] w-full min-w-min overflow-y-scroll bg-white p-4 md:top-[50%] md:h-fit md:max-h-[calc(100vh-(2*4rem))] md:w-[480px] md:translate-y-[-50%]`}>
        <div className={`relative flex justify-center`}>
          <div className={"flex items-center justify-center"}>
            <ClassManagerLogo
              color={"grey-900"}
              className={"h-10 w-[209px] md:h-12 md:w-[250px]"}
            />
            <DialogClose
              data-testid="close-account-selection-dialog-btn"
              className={"absolute inset-y-0 right-0"}
              asChild>
              <IconButton
                variant="standard"
                icon={"closeOutline"}
                size="lg"
                aria-label="Close"
              />
            </DialogClose>
          </div>
        </div>
        <AccountSelectionContent
          onAccountChange={handleAccountChange}
          Version={Version}
          CompanyLogo={CompanyLogo}
          onLogout={goToLogin}
        />
      </DialogContent>
    </Dialog>
  );
};
