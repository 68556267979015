import { useSharedCompanySettingsActions } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { DeleteButton } from "@/modules/company/settings/DeleteButton";
import { LookAndFeelUpdateForm } from "@/modules/company/settings/forms/LookAndFeelUpdateForm";
import { Router } from "@/routing/router";

export const useCompanySettingsActions = () => {
  const { openSheet, closeSheet } = useSheet();
  const sharedActions = useSharedCompanySettingsActions(
    api,
    openSheet,
    closeSheet,
    GenericForm,
    UpdateFooter,
    LookAndFeelUpdateForm,
    DeleteButton,
  );

  return {
    ...sharedActions,
    showTaxSettingsPage: () => Router.push("TaxSettings"),
  };
};
