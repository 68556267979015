import { SortSchema } from "@justraviga/classmanager-sdk";

import { UseApi } from "../components/apiQueryFactory";
import { SelectItem } from "../components/interfaces";
import { getFullName, getInitials } from "../personUtil";
import { displayStudentAge } from "../studentUtils";

export const useStudentSelect = ({
  useApi,
  classId,
  checkEnrollment = true,
  checkWaitingList = true,
}: {
  useApi: UseApi;
  classId: string | undefined;
  checkEnrollment?: boolean;
  checkWaitingList?: boolean;
}): { studentList: SelectItem[] } => {
  const enrolments =
    useApi("listEnrolment", { selectAll: true }, { enabled: checkEnrollment })
      .data?.data ?? [];
  const waitingList =
    useApi(
      "listWaitingList",
      { selectAll: true },
      { enabled: checkWaitingList },
    ).data?.data ?? [];
  const students =
    useApi(
      "listStudent",
      {
        selectAll: true,
        sort: {
          firstname: SortSchema.Asc,
          lastname: SortSchema.Asc,
        },
      },
      { enabled: classId !== undefined },
    ).data?.data ?? [];

  return {
    studentList: classId
      ? students.map(student => {
          const isStudentEnrolled =
            checkEnrollment &&
            enrolments.some(
              e =>
                e.enrolment.classId == classId &&
                e.enrolment.studentId == student.id,
            );

          const isStudentWaiting =
            checkWaitingList &&
            waitingList.some(
              waiting =>
                waiting.classId == classId && waiting.studentId == student.id,
            );

          let label: string = getFullName(student);

          if (isStudentEnrolled) {
            label += " (enrolled)";
          }

          if (isStudentWaiting) {
            label += " (waiting)";
          }

          return {
            label,
            value: student.id,
            avatar: student.profilePicture || getInitials(student),
            description: displayStudentAge(student),
            disabled: isStudentWaiting || isStudentEnrolled,
          };
        })
      : [],
  };
};
