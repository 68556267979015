import { Table } from "@tanstack/react-table";

import {
  DatatableContentState,
  DatatablePaginatedData,
  useDatatable,
} from "shared/lib";

import { DatatablePagination } from "@/modules/common/datatable/common/DatatablePagination";
import { NoResultsContentPlaceholder } from "@/modules/common/datatable/content/NoResultsContentPlaceholder";

export const WebFooter = <ModelDto,>({
  table,
  contentState,
  data,
}: {
  table: Table<ModelDto>;
  contentState: DatatableContentState;
  data?: DatatablePaginatedData<ModelDto>;
}) => {
  const { datatable } = useDatatable();

  if (
    datatable.hasPagination &&
    contentState === DatatableContentState.Loaded
  ) {
    return (
      <section className={"sticky bottom-0"}>
        <DatatablePagination table={table} total={data?.pagination.total} />
      </section>
    );
  }

  if (
    datatable.hasPagination &&
    contentState === DatatableContentState.NoResults
  ) {
    return <NoResultsContentPlaceholder />;
  }

  return <></>;
};
