import { WaiverEntityWithAgreement } from "shared/lib";

import { Chip } from "@/modules/common/ui/chip/Chip";

interface WaiverCardChipProps {
  waiver: WaiverEntityWithAgreement;
}

export const WaiverAgreementChip = ({ waiver }: WaiverCardChipProps) => {
  if (waiver.agreement?.id) {
    return (
      <Chip
        leftIcon="checkmarkCircleOutline"
        rounded="md"
        label="Agreed"
        variant="success"
      />
    );
  }

  return (
    <Chip
      leftIcon="timeOutline"
      rounded="md"
      label="Pending"
      variant="neutral"
    />
  );
};
