import { Api } from "../../api";
import { colors } from "../../colors";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { BannerComponent, ViewComponent } from "../interfaces";

interface FormSchema {
  subject: string;
  body: string;
}

interface ExtendedFormSchema extends FormSchema {
  id?: string;
  recipientIds: Array<string>;
  attachments: Array<string>;
}

export type EmailFormSchema = Partial<ExtendedFormSchema>;

export const makeEmailFormDefinition = (
  Banner: BannerComponent,
  View: ViewComponent,
) =>
  new FormDefinitionBuilder<FormSchema>()
    .text("subject", { label: "Subject", required: false })
    .contentBlock(
      "after",
      "subject",
      <View className="mb-5">
        <Banner
          variant="info"
          content="Your email header and footer will be generated automatically using your company branding and contact details."
          icon={{ name: "informationCircle", color: colors.blue[600] }}
        />
      </View>,
    )
    .richText("body", { required: false, numberOfLines: 12 })
    .getDefinition();

export const makeSaveEmailRequest =
  (api: Api) => (formData: ExtendedFormSchema) =>
    api.messages.createMessage({
      createMessageRequest: formData,
    });

export const makeSendEmailRequest =
  (api: Api) => (formData: ExtendedFormSchema) =>
    api.messages.sendMessage({ createMessageRequest: formData });
