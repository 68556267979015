import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  BillDto,
  CreateFromBillTransactionRequest,
  SeasonDto,
} from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { useTuitionForMonthFirstStepForm } from "./tuitionForMonthFirstStepForm";
import { useTuitionForMonthSecondStepForm } from "./tuitionForMonthSecondStepForm";
import { Api } from "../../../api";
import { FormDefinition } from "../../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { MultiStepsHeaderStep } from "../../../forms/multiStepFormProps";
import { getPlatformFunctions } from "../../../platformSpecific";
import { useFormActions } from "../../FormActionsProvider";

export const tuitionFormMonthFormSteps: MultiStepsHeaderStep[] = [
  { index: 1, label: "Select billing period" },
  { index: 2, label: "Review & generate" },
];
export type TuitionFormMonthFormStepType = 1 | 2;

export type TuitionForMonthFormSchema = CreateFromBillTransactionRequest;

interface TuitionForMonthFormData {
  formHook: FormDefinition<TuitionForMonthFormSchema>;
  step: TuitionFormMonthFormStepType;
  setStep: Dispatch<SetStateAction<TuitionFormMonthFormStepType>>;
  // formValues: Partial<TuitionForMonthFormSchema>;
  selectedSeason: SeasonDto | undefined;
  billingMonth: string | undefined;
  billData: BillDto | undefined;
  onSuccess: () => void;
  submitFunction: (
    formData: TuitionForMonthFormSchema,
  ) => Promise<TuitionForMonthFormSchema | AggregateTransactionDto>;
}

export const useTuitionForMonthForm = (
  familyId: string,
  onFinalSuccess: () => void,
): TuitionForMonthFormData => {
  const builder = new FormDefinitionBuilder<TuitionForMonthFormSchema>();
  const [billData, setBillData] = useState<BillDto | undefined>(undefined);
  const { api } = getPlatformFunctions();
  const { setAllowCreateAdditional, setCreateButtonText, setResetAfterSubmit } =
    useFormActions();
  const [step, setStep] = useState<TuitionFormMonthFormStepType>(1);
  const { selectedSeason, billingMonth } = useTuitionForMonthFirstStepForm(
    builder,
    step,
  );

  /**
   * This function APPENDS TO THE BUILDER PASSED IN.
   * Bad function, very bad function.
   */
  useTuitionForMonthSecondStepForm(builder, step);

  const [formValues, setFormValues] =
    useState<Partial<TuitionForMonthFormSchema>>();

  const loadBillData = async (seasonId: string, billingMonth: string) => {
    return await api.bills.getBill({
      familyId,
      seasonId,
      billingMonth,
    });
  };

  //it is important to use useEffect here to prevent the stack trace error
  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText("Preview");
    setResetAfterSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = match(step)
    .with(1, () => {
      return {
        onSuccess: () => {
          setStep(2);
          setCreateButtonText("Confirm & generate");
        },
        submitFunction: async (formData: TuitionForMonthFormSchema) => {
          const newValues = { ...formData, ...formValues };
          setFormValues(newValues);

          setBillData(
            await loadBillData(formData.seasonId, formData.billingMonth),
          );

          return Promise.resolve(newValues);
        },
      };
    })
    .with(2, () => {
      return {
        onSuccess: onFinalSuccess,
        submitFunction: (formData: TuitionForMonthFormSchema) => {
          const finalValues = {
            ...formData,
            familyId,
          };
          setFormValues(finalValues);

          return makeTuitionForMonthRequest(api, finalValues);
        },
      };
    })
    .run();

  return {
    formHook:
      builder.getDefinition() as FormDefinition<TuitionForMonthFormSchema>,
    billingMonth,
    billData,
    step,
    setStep,
    selectedSeason,
    ...data,
  };
};

export const makeTuitionForMonthRequest = (
  api: Api,
  data: TuitionForMonthFormSchema,
) => {
  return api.transactions.createFromBillTransaction({
    createFromBillTransactionRequest: data,
  });
};
