import { HolidayDto } from "@justraviga/classmanager-sdk";

import {
  EntityActions,
  makeHolidayCreateFormDefinition,
  makeHolidayCreateRequest,
  makeHolidayUpdateFormDefinition,
  makeHolidayUpdateRequest,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";

type Model = HolidayDto;

type ImplementedActions = Omit<
  EntityActions<Model>,
  "archive" | "unarchive" | "archiveMany" | "unarchiveMany"
>;

export const useHolidayActions = (): ImplementedActions => {
  const defaultActions = usePlatformEntityActions<HolidayDto>({
    entity: "holiday",
  });

  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.holidays.deleteHoliday({ id: entity.id }),
      ),

    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () =>
          api.holidays.deleteManyHoliday({ deleteManyHolidayRequest: { ids } }),
        ids.length,
      ),

    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeHolidayCreateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={makeHolidayCreateFormDefinition()}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeHolidayUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={makeHolidayUpdateFormDefinition()}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),
  };
};
