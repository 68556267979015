import { useState } from "react";

import { ListCourseRequest, SortSchema } from "@justraviga/classmanager-sdk";
import { ListSeasonRequest } from "@justraviga/classmanager-sdk/dist/apis/SeasonsApi";
import type { AggregateClassDto } from "@justraviga/classmanager-sdk/dist/models/AggregateClassDto";

import { UseApi } from "../components/apiQueryFactory";

export const useClassesSeasons = ({
  useApi,
  seasonId = undefined,
  classId = undefined,
}: {
  useApi: UseApi;
  seasonId?: string;
  classId?: string;
}) => {
  const [selectedSeasonId, setSelectedSeasonId] = useState<string | undefined>(
    seasonId,
  );
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>(
    classId,
  );

  const seasonQueryParams: ListSeasonRequest = {
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  };
  const { data: seasons, isLoading: isSeasonLoading } = useApi(
    "listSeason",
    seasonQueryParams,
  );

  const classQueryParams: ListCourseRequest = {
    where: { seasonId: { equals: selectedSeasonId } },
  };
  const { data: classes, isLoading: isClassLoading } = useApi(
    "listCourse",
    classQueryParams,
    {
      enabled: selectedSeasonId !== undefined,
    },
  );

  const defaultData: AggregateClassDto[] = [];

  return {
    seasons: seasons?.data ?? [],
    classes: classes?.data ?? defaultData,
    selectedClassId,
    setSelectedClassId,
    selectedSeasonId,
    setSelectedSeasonId,
    isLoading: isSeasonLoading || isClassLoading,
  };
};
