import { useState } from "react";

import {
  ColumnDef,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";

import {
  DatatableConfiguration,
  DatatableContext,
  DatatableFilter,
  datatableFilters,
  datatableIdsSelection,
  DatatableQueryProps,
  DatatableRow,
  Dto,
  getInitialQuery,
} from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { rowActionsColumn } from "@/modules/common/datatable/columns/RowActionsColumn";
import { checkboxColumnDef } from "@/modules/common/datatable/columns/RowSelectionColumn";
import { MobileDatatableContainer } from "@/modules/common/datatable/MobileDatatableContainer";
import {
  mapMobileColumnPropsIntoColumnDef,
  mapWebColumnPropsIntoColumnDef,
} from "@/modules/common/datatable/utilities";
import { WebDatatableContainer } from "@/modules/common/datatable/WebDatatableContainer";

export const Datatable = <
  ModelDto extends Dto,
  FilterSchema extends object,
  RowDto extends ModelDto = ModelDto,
>({
  configuration,
}: {
  configuration: DatatableConfiguration<ModelDto, FilterSchema, RowDto>;
}) => {
  const props = configuration;
  const [query, setQuery] = useState<DatatableQueryProps>(getInitialQuery());
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<DatatableFilter[]>([]);
  const [modelsSelected, setModelsSelected] = useState<string[]>([]);
  const [selectMode, setSelectMode] = useState<boolean>(false);
  const [dataLoadedCount, setDataLoadedCount] = useState<number>(1);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: query.page - 1,
    pageSize: query.pageSize,
  });

  const modelSelection = datatableIdsSelection(
    modelsSelected,
    setModelsSelected,
  );

  const filtersValue = datatableFilters(filters, setFilters);

  const { md: showWebSize } = useBreakpoint();

  const resetQuery = () => {
    setQuery(getInitialQuery());
    setDataLoadedCount(dataLoadedCount + 1);
  };

  function getColumns() {
    let transformedColumns: ColumnDef<DatatableRow<RowDto>>[] = [];
    // Overwrites the logic if isMobile is true
    if (props.isMobile) {
      transformedColumns.push(
        mapMobileColumnPropsIntoColumnDef(props.mobileColumn),
      );
    } else if (showWebSize) {
      transformedColumns = (props.columns ?? []).map(column => {
        return mapWebColumnPropsIntoColumnDef(column);
      });
    } else {
      transformedColumns.push(
        mapMobileColumnPropsIntoColumnDef(props.mobileColumn),
      );
    }

    if (showWebSize && props.groupActions) {
      transformedColumns.unshift(checkboxColumnDef(modelSelection));
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { click: _, ...rowActionMenuItems } = props.rowActions ?? {};
    if (props.rowActions && Object.keys(rowActionMenuItems).length > 0) {
      const col = rowActionsColumn({
        rowActions: props.rowActions,
        permissions: props.permissions,
      });
      transformedColumns.push(col);
    }

    return transformedColumns;
  }

  const columns = getColumns();

  return (
    <>
      <DatatableContext.Provider
        value={{
          query,
          setQuery,
          resetQuery,
          filters,
          setFilters,
          globalFilter,
          setSearch: v => {
            // Reset pagination for mobile
            query.page = 1;
            // Reset pagination for desktop
            setPagination({ pageIndex: 0, pageSize });
            if (v === null) {
              setQuery(getInitialQuery());
            }
            setGlobalFilter(v ?? "");
          },
          sorting,
          setSorting,
          modelSelection,
          filtersValue,
          showWebSize,
          selectMode,
          setSelectMode,
          dataLoadedCount,
          setDataLoadedCount,
          // @ts-expect-error context doesn't accept generic type
          datatable: props,
          pageIndex,
          pageSize,
          setPagination,
        }}>
        {props.isMobile ? (
          <MobileDatatableContainer {...{ columns }} />
        ) : showWebSize ? (
          <WebDatatableContainer {...{ columns }} />
        ) : (
          <MobileDatatableContainer {...{ columns }} />
        )}
      </DatatableContext.Provider>
    </>
  );
};
