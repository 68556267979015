import { FamilyDashboard, useAuthState } from "shared/components";

import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Banner } from "@/modules/common/ui/Banner";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

export const CustomerDashboardPage = () => {
  const { account } = useAuthState();
  const {
    showTakePaymentForm: openPaymentForm,
    showAddPaymentMethodForm: openPaymentMethodForm,
  } = usePaymentMethods();
  const studentActions = useStudentActions();

  const goToEnrol = () =>
    Router.push("CourseFilterWizard", {
      mode: "enrol",
      slug: account!.company!.slug,
    });

  return (
    <CustomerLayout>
      <FamilyDashboard
        BaseCard={BaseCard}
        Button={Button}
        goToEnrol={goToEnrol}
        goToAddStudent={studentActions.showPortalCreateForm}
        goToMakePayment={openPaymentForm}
        Banner={Banner}
        openPaymentMethodForm={openPaymentMethodForm}
      />
    </CustomerLayout>
  );
};
