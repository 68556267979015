import { CourseImageProps } from "shared/lib";

import tile from "@/assets/course-image-placeholder.svg";

export const CourseImage = ({
  course,
  collapseWhenPlaceholder = true,
}: CourseImageProps) =>
  course.image ? (
    <div
      className="aspect-video w-full rounded"
      style={{
        backgroundImage: `url(${course.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}></div>
  ) : (
    <div
      className="aspect-video w-full rounded bg-brand-400"
      style={{
        backgroundImage: `url(${tile})`,
        backgroundRepeat: "repeat",
        height: collapseWhenPlaceholder ? "72px" : undefined,
      }}></div>
  );
