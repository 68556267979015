import { WaiverEntityWithAgreement } from "shared/lib";

import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { WaiverAgreementDetails } from "@/modules/company/waivers/WaiverAgreementDetails";

export const useWaiverAgreementActions = () => {
  const { openSheet } = useSheet();

  const showDetail = (waiver: WaiverEntityWithAgreement) => {
    openSheet({
      title: "Waiver details",
      content: <WaiverAgreementDetails waiver={waiver} />,
    });
  };

  return {
    showDetail,
  };
};
