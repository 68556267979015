import { ReactNode } from "react";

import { pushUnsafe } from "@swan-io/chicane";

import { cn, colors, IconName, truncateString } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { Button } from "@/modules/common/ui/button/Button";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { Router } from "@/routing/router";
import { routes } from "@/routing/routes";

// Define which routes should highlight which buttons
const routeGroups: Record<
  "home" | "classes" | "billing" | "menu",
  Array<keyof typeof routes>
> = {
  home: ["Home", "CustomerHome"],
  classes: ["FamilyCourseList", "CustomerEnrol"],
  billing: ["CustomerBilling"],
  menu: ["CustomerMenu"],
} as const;

export const CustomerNav = () => {
  const breakpoint = useBreakpoint();
  const ButtonComponent = breakpoint.md ? DesktopButton : MobileButton;

  return (
    <div className="mx-auto flex max-w-lg justify-between rounded-2xl border border-grey-300 bg-white p-4">
      <ButtonComponent
        text={"Home"}
        path={Router.CustomerHome()}
        icon={"home"}
        isActive={!!Router.getRoute(routeGroups.home)}
      />

      <ButtonComponent
        text={"Classes"}
        path={Router.FamilyCourseList()}
        icon={"calendar"}
        isActive={!!Router.getRoute(routeGroups.classes)}
      />

      <ButtonComponent
        text={"Billing"}
        path={Router.CustomerBilling()}
        icon={"card"}
        isActive={!!Router.getRoute(routeGroups.billing)}
      />

      {!breakpoint.md && (
        <ButtonComponent
          text={"Menu"}
          path={Router.CustomerMenu()}
          icon={"ellipsisHorizontalCircle"}
          isActive={!!Router.getRoute(routeGroups.menu)}
        />
      )}
    </div>
  );
};

const DesktopButton = ({
  path,
  icon,
  text,
  isActive,
}: {
  path: string;
  icon: IconName;
  text: string;
  isActive: boolean;
}) => {
  // Always use the outline variant for desktop buttons
  const iconName = `${icon}Outline` as IconName;

  const OptionalTooltip = ({ children }: { children: ReactNode }) =>
    isActive ? <>{children}</> : <Tooltip trigger={children} children={text} />;
  return (
    <OptionalTooltip>
      <Button
        text={isActive ? text : undefined}
        onClick={() => pushUnsafe(path)}
        leftIcon={iconName}
        variant={isActive ? "brand" : "tertiary"}
      />
    </OptionalTooltip>
  );
};

const MobileButton = ({
  path,
  icon,
  text,
  isActive,
}: {
  path: string;
  icon: IconName;
  text: string;
  isActive: boolean;
}) => {
  const iconName = (isActive ? icon : `${icon}Outline`) as IconName;

  const color = isActive ? colors.white : colors.grey["900"];

  return (
    <button
      className={cn(
        "flex h-16 w-16 flex-col items-center justify-center gap-y-1 rounded",
        {
          "bg-brand-800": isActive,
        },
      )}
      onClick={() => pushUnsafe(path)}>
      <Icon name={iconName} size={24} color={color} />
      <span
        className="block"
        style={{
          color,
          fontSize: "10px",
        }}>
        {truncateString(text, 12)}
      </span>
    </button>
  );
};
