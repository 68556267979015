import { useContext } from "react";

import {
  NavContext,
  NavContextType,
} from "@/modules/common/nav/contexts/navContext";

export const useNav = (): NavContextType => {
  const context = useContext<NavContextType>(NavContext);

  if (!context) {
    throw new Error("Cannot use NavContext outside of its provider");
  }

  return context;
};
