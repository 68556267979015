import { StudentDto } from "@justraviga/classmanager-sdk";

import {
  displayStudentAge,
  displayStudentDateOfBirth,
  getFullName,
  getInitials,
} from "shared/lib";

import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { PreviewSheetContent } from "@/modules/common/sheet/preview/PreviewSheetContent";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Button } from "@/modules/common/ui/button/Button";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { StudentEmergencyContactsSection } from "@/modules/company/members/student/preview/StudentPreviewContactsSection";
import { Router } from "@/routing/router";

export const StudentPreview = ({ student }: { student: StudentDto }) => {
  const { closeSheet } = useSheet();

  return (
    <PreviewSheetContent>
      <div>
        <div className={"flex space-x-3"}>
          <div>
            <Avatar
              size="xl"
              src={student.profilePicture ?? undefined}
              name={getInitials(student)}
            />
          </div>

          <div className={"space-y-1"}>
            <h3 className={"text-body-600 text-grey-900"}>
              {getFullName(student)}
            </h3>
            <p className={"text-body-400 text-grey-600"}>
              {displayStudentAge(student)}
            </p>
            <p className={"text-body-400 text-grey-600"}>
              {displayStudentDateOfBirth(student)}
            </p>
          </div>
        </div>

        <div className={"pb-8 pt-4"}>
          <Button
            text={"Go to student"}
            isFullWidth={true}
            variant={"secondary"}
            onClick={() => {
              closeSheet();
              Router.push("StudentDetails", { id: student.id });
            }}
          />
        </div>
      </div>

      <PreviewSheetContent.detailCard>
        <PreviewSheetContent.subTitle title="Medical information" />
        <PreviewSheetContent.infoRow text={student.medicalInfo ?? "-"} />
      </PreviewSheetContent.detailCard>

      <HorizontalSeparator spacing={0} />

      <StudentEmergencyContactsSection familyId={student.familyId} />
    </PreviewSheetContent>
  );
};
