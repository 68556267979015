import { PropsWithChildren } from "react";

import {
  ListStudent200Response,
  StudentGender,
  WaiverDto,
  WaiverType,
} from "@justraviga/classmanager-sdk";
import { CodeBlock } from "react-code-blocks";

import { ContentPlaceholder, NoLocationsPlaceholder } from "shared/components";
import { waiverEntityFactory } from "shared/lib";

import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { DocHeader } from "@/modules/admin/docs/components/DocHeader";
import {
  ToggleContent,
  ToggleDoc,
} from "@/modules/admin/docs/components/ToggleDoc";
import { DocIntro } from "@/modules/admin/docs/DocWrapper";
import {
  CardContainer,
  CardContainerActions,
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import {
  CompanyContent,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { StudentCard } from "@/modules/company/members/family/detail/StudentCard";
import { WaiverCard } from "@/modules/company/waivers/WaiverCard";

const students: ListStudent200Response = {
  data: [
    {
      id: "stu_01hvr668q470e6ndjvxxmn8jhz",
      companyId: "co_01hvr6646655e9hbpsqkbryzxf",
      familyId: "fml_01hvr666988tkzt1vy3jc661mw",
      firstname: "Cordia",
      lastname: "Gaylord",
      gender: StudentGender.NonBinary,
      dateOfBirth: "2011-04-18",
      mediaConsent: true,
      medicalInfo: "Vision impairment",
      profilePicture: null,
      discountable: true,
      joinedAt: "2024-04-18",
      deletedAt: null,
      archivedAt: null,
      createdAt: new Date(),
    },
    {
      id: "stu_01hvr668qnwew0ndz1yggzp9w0",
      companyId: "co_01hvr6646655e9hbpsqkbryzxf",
      familyId: "fml_01hvr666988tkzt1vy3jc661mw",
      firstname: "Felicia",
      lastname: "Gaylord",
      gender: StudentGender.Other,
      dateOfBirth: "2020-04-18",
      mediaConsent: true,
      medicalInfo: null,
      profilePicture: null,
      discountable: true,
      joinedAt: "2024-04-18",
      deletedAt: null,
      archivedAt: null,
      createdAt: new Date(),
    },
    {
      id: "stu_01hvr668r3wd2hf4n4m7dh35qc",
      companyId: "co_01hvr6646655e9hbpsqkbryzxf",
      familyId: "fml_01hvr666988tkzt1vy3jc661mw",
      firstname: "Hailee",
      lastname: "Gaylord",
      gender: StudentGender.PreferNotToSay,
      dateOfBirth: "2014-04-18",
      mediaConsent: false,
      medicalInfo: "Autism spectrum disorder",
      profilePicture: null,
      discountable: false,
      joinedAt: "2024-04-18",
      deletedAt: null,
      archivedAt: null,
      createdAt: new Date(),
    },
    {
      id: "stu_01hvr666jk491weqz1jefqbyze",
      companyId: "co_01hvr6646655e9hbpsqkbryzxf",
      familyId: "fml_01hvr666988tkzt1vy3jc661mw",
      firstname: "Margaret",
      lastname: "Balistreri",
      gender: null,
      dateOfBirth: null,
      mediaConsent: false,
      medicalInfo: null,
      profilePicture: null,
      discountable: true,
      joinedAt: "2024-04-18",
      deletedAt: null,
      archivedAt: null,
      createdAt: new Date(),
    },
  ],
  pagination: {
    total: 4,
    count: 4,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
  },
};

const waivers: { data: WaiverDto[]; pagination: { [key: string]: unknown } } = {
  data: [
    {
      id: "wvr_01hvvfkwcyr2vsnh9fravbqbsh",
      name: "Privacy Policy",
      content:
        "We at \"A Dance school\" want you to understand what information we collect, and how we use and share it. That's why we encourage you to read our Privacy Policy. This helps you use  in the way that's right for you.\n\nIn the Privacy Policy, we explain how we collect, use, share, retain and transfer information. We also let you know your rights. Each section of the Policy includes helpful examples and simpler language to make our practices easier to understand. We've also added links to resources where you can learn more about the privacy topics that interest you.",
      companyId: "co_01hvvfhr18wgzvpegz8a6m5eph",
      createdAt: new Date("2024-04-19T15:29:19.000000Z"),
      updatedAt: new Date("2024-04-19T15:49:29.000000Z"),
      archivedAt: null,
      isLocked: true,
      type: WaiverType.PrivacyPolicy,
    },
    {
      id: "wvr_01hvvfssjcj6w10xya2ab8gyzk",
      name: "Cookies Policy",
      content:
        'Cookies are small pieces of text used to store information in web browsers. Cookies are used to store and receive identifiers and other information on computers, phones and other devices. Other technologies, including data that we store on your web browser or device, identifiers associated with your device and other software, are used for similar purposes. In this policy, we refer to all of these technologies as "cookies".',
      companyId: "co_01hvvfhr18wgzvpegz8a6m5eph",
      createdAt: new Date("2024-04-19T15:32:32.000000Z"),
      updatedAt: new Date("2024-04-19T15:50:02.000000Z"),
      archivedAt: null,
      isLocked: false,
      type: WaiverType.Custom,
    },
  ],
  pagination: {
    total: 2,
    count: 2,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
  },
};

const BaseCardContainerPageExample = ({ isEmpty }: { isEmpty: boolean }) => {
  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Create",
    onClick: () => alert("show create waiver form"),
  };

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Archived Items",
      leftIcon: "archiveOutline",
      onClick: () => alert("navigate to archived items"),
    },
  ];

  return (
    <CardContainer
      variant={"page"}
      isEmpty={isEmpty}
      primaryAction={primaryAction}
      menuItems={menuItems}>
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Waivers"} />
        <CardContainerActions />
      </CompanyTitleBar>
      <CompanyContent>
        <CardContainerPlaceholder>
          <div className={"flex flex-col gap-y-4"}>
            <NoLocationsPlaceholder
              onCreateClick={() => alert("create waiver")}
              goToArchivedItems={() => alert("go to archive items")}
            />
            <Button
              text="Go to archived items"
              variant={"subtle"}
              onClick={() => alert("go to archive items")}
            />
          </div>
        </CardContainerPlaceholder>
        <CardContainerContent
          className={"grid grid-cols-1 gap-y-5 md:grid-cols-2 md:gap-8"}>
          {waivers?.data.map((waiver, key) => (
            <WaiverCard waiver={waiverEntityFactory(waiver)} key={key} />
          ))}
        </CardContainerContent>
      </CompanyContent>
    </CardContainer>
  );
};

const BaseCardContainerSectionExample = ({ isEmpty }: { isEmpty: boolean }) => {
  return (
    <CardContainer
      primaryAction={{
        icon: "addOutline",
        title: "Add Student",
        onClick: () => alert("Open add student form"),
      }}
      variant={"section"}
      isEmpty={isEmpty}>
      <div className={"flex flex-1 flex-col space-y-3"}>
        <div className={"flex items-center justify-between"}>
          <span className={"text-body-600 text-grey-900"}>{"Students"}</span>
          <CardContainerActions />
        </div>
        <CardContainerPlaceholder>
          <ContentPlaceholder
            icon="helpCircleOutline"
            title="No students added yet!"
            action={
              <Button
                variant={"brand"}
                onClick={() => alert("create student with family")}
                text={"Add Student"}
              />
            }
          />
        </CardContainerPlaceholder>
        <CardContainerContent>
          {students.data.map((student, index) => {
            return <StudentCard key={index} student={student} />;
          })}
        </CardContainerContent>
      </div>
    </CardContainer>
  );
};

const CodeSpan = ({ children }: PropsWithChildren) => (
  <span className="bg-indigo-800 m-1 p-1 text-white">
    <code>{children}</code>
  </span>
);
const NamedComponent = ({ children }: PropsWithChildren) => (
  <CodeSpan>&lt;{children} &#8725;&gt;</CodeSpan>
);

const Intro = () => (
  <>
    The <NamedComponent>CardContainer</NamedComponent> component is used to wrap
    and coordinate the Card Family of components component is used to wrap and
    coordinate the Card Family of components in order to create a section or
    page whose primary purpose is to display a selection of Card components
    where we will want to have general actions available such as a{" "}
    <CodeSpan>primaryAction</CodeSpan>
    plus a range of other actions that are made available via{" "}
    <CodeSpan>menuItems</CodeSpan>.
    <br />
    <br />
    You can define two variants of{" "}
    <NamedComponent>CardContainer</NamedComponent> via the{" "}
    <CodeSpan>variant</CodeSpan> prop. These are either a
    <CodeSpan>"page"</CodeSpan> or a <CodeSpan>"section"</CodeSpan>.
    <br />
    <br />
    The components intended to be used within a CardContainer are the following:
    <br />
    <br />
    <ul className="ml-4 list-inside list-disc">
      <li className="pb-2">
        <NamedComponent>CardContainerPlaceholder</NamedComponent>: This is the
        placeholder that will be rendered if no data is available
      </li>
      <li className="pb-2">
        <NamedComponent>CardContainerActions</NamedComponent>: This renders the{" "}
        <NamedComponent>CardContainerPrimaryAction</NamedComponent> and the{" "}
        <NamedComponent>CardContainerMenu</NamedComponent> described below.
      </li>
      <ul className="ml-8 list-inside list-[revert]">
        <li className="pb-2">
          <NamedComponent>CardContainerPrimaryAction</NamedComponent>: This is
          the primary action that will be displayed alongside the title
        </li>
        <li className="pb-2">
          <NamedComponent>CardContainerMenu</NamedComponent>: This component
          renders the extended actions available via an{" "}
          <NamedComponent>ActionMenu</NamedComponent>
        </li>
      </ul>
      <li className="pb-2">
        <NamedComponent>CardContainerContent</NamedComponent>: This is the main
        content displayed within a CardContainer and is rendered if the
        CardContainer is not empty.
      </li>
      <li className="pb-2">
        <NamedComponent>Card</NamedComponent>: This is a styled base component
        that can be used to construct a Card.
      </li>
      <li className="pb-2">
        <NamedComponent>DetailCard</NamedComponent>: This extends the Card
        component above with the addition of an onClick handler and hover state
      </li>
    </ul>
    <br />
    <br />
    In the examples below the relevant Cards (
    <NamedComponent>WaiverCard</NamedComponent> &{" "}
    <NamedComponent>StudentCard</NamedComponent>) have been constructed using
    the cards mentioned above.
  </>
);

const CardContainerPageExample = () => (
  <ToggleDoc>
    <DocHeader title="CardContainer Page" />
    <BaseCardContainerPageExample isEmpty={false} />
    <ToggleContent>
      <CodeBlock
        as={undefined}
        forwardedAs={undefined}
        codeContainerStyle={{
          borderRadius: "0.5rem",
          padding: "1.2rem",
          fontFamily: "monospace",
          fontSize: "14px",
        }}
        text={`
export const CardContainerPageExample = () => {
  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Create",
    onClick: () => alert("show create waiver form"),
  };

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Archived Items",
      leftIcon: "archiveOutline",
      onClick: () => alert("navigate to archived items"),
    },
  ];

  return (
    <CardContainer
      variant={"page"}
      isEmpty={false}
      primaryAction={primaryAction}
      menuItems={menuItems}>
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Waivers"} />
        <CardContainerActions />
      </CompanyTitleBar>
      <CompanyContent>
        <CardContainerPlaceholder>
          <div className={"flex flex-col gap-y-4"}>
            <NoWaiversPlaceholder
              onCreateWaiverClick={() => alert("create waiver")}
            />
            <Button
              text="Go to archived items"
              variant={"subtle"}
              onClick={() => alert("go to archive items")}
            />
          </div>
        </CardContainerPlaceholder>
        <CardContainerContent
          className={"grid grid-cols-1 gap-y-5 md:grid-cols-2 md:gap-8"}>
          {waivers?.data.map((waiver, key) => (
            <WaiverCard waiver={waiver} key={key} />
          ))}
        </CardContainerContent>
      </CompanyContent>
    </CardContainer>
  );
};
`}
        showLineNumbers={false}
        language={"typescript"}
        wrapLongLines={true}
      />
    </ToggleContent>
  </ToggleDoc>
);

const EmptyCardContainerPageExample = () => (
  <ToggleDoc>
    <DocHeader title="CardContainer Page - (data isEmpty)" />
    <BaseCardContainerPageExample isEmpty />
    <ToggleContent>
      <CodeBlock
        as={undefined}
        forwardedAs={undefined}
        codeContainerStyle={{
          borderRadius: "0.5rem",
          padding: "1.2rem",
          fontFamily: "monospace",
          fontSize: "14px",
        }}
        text={`
export const CardContainerPageExample = () => {
  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Create",
    onClick: () => alert("show create waiver form"),
  };

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Archived Items",
      leftIcon: "archiveOutline",
      onClick: () => alert("navigate to archived items"),
    },
  ];

  return (
    <CardContainer
      variant={"page"}
      primaryAction={primaryAction}
      isEmpty
      menuItems={menuItems}>
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Waivers"} />
        <CardContainerActions />
      </CompanyTitleBar>
      <CompanyContent>
        <CardContainerPlaceholder>
          <div className={"flex flex-col gap-y-4"}>
            <NoWaiversPlaceholder
              onCreateWaiverClick={() => alert("create waiver")}
            />
            <Button
              text="Go to archived items"
              variant={"subtle"}
              onClick={() => alert("go to archive items")}
            />
          </div>
        </CardContainerPlaceholder>
        <CardContainerContent
          className={"grid grid-cols-1 gap-y-5 md:grid-cols-2 md:gap-8"}>
          {waivers?.data.map((waiver, key) => (
            <WaiverCard waiver={waiver} key={key} />
          ))}
        </CardContainerContent>
      </CompanyContent>
    </CardContainer>
  );
};
`}
        showLineNumbers={false}
        language={"typescript"}
        wrapLongLines={true}
      />
    </ToggleContent>
  </ToggleDoc>
);

const EmptyCardContainerSectionExample = () => (
  <ToggleDoc>
    <DocHeader title="CardContainer Section - embedded within a page (data is empty)" />
    <BaseCardContainerSectionExample isEmpty={true} />
    <ToggleContent>
      <CodeBlock
        as={undefined}
        forwardedAs={undefined}
        codeContainerStyle={{
          borderRadius: "0.5rem",
          padding: "1.2rem",
          fontFamily: "monospace",
          fontSize: "14px",
        }}
        text={`
const CardContainerSectionExample = () => {
  return (
    <CardContainer
      primaryAction={{
        icon: "addOutline",
        title: "Add Student",
        onClick: () => alert("Open add student form"),
      }}
      variant={"section"}
      isEmpty
    >
      <div className={"flex flex-1 flex-col space-y-3"}>
        <div className={"flex items-center justify-between"}>
          <span className={"text-body-600 text-grey-900"}>{"Students"}</span>
          <CardContainerActions />
        </div>
        <CardContainerPlaceholder>
          <ContentPlaceholder
            icon="helpCircleOutline"
            title="No students added yet!"
            action={
              <Button
                variant={"brand"}
                onClick={() => alert("create student with family")}
                text={"Add Student"}
              />
            }
          />
        </CardContainerPlaceholder>
        <CardContainerContent>
          {students?.data.map((student, index) => {
            return <StudentCard key={index} student={student} />;
          })}
        </CardContainerContent>
      </div>
    </CardContainer>
  );
};
`}
        showLineNumbers={false}
        language={"typescript"}
        wrapLongLines={true}
      />
    </ToggleContent>
  </ToggleDoc>
);

const CardContainerSectionExample = () => (
  <ToggleDoc>
    <DocHeader title="CardContainer Section - embedded within a page" />
    <BaseCardContainerSectionExample isEmpty={false} />
    <ToggleContent>
      <CodeBlock
        as={undefined}
        forwardedAs={undefined}
        codeContainerStyle={{
          borderRadius: "0.5rem",
          padding: "1.2rem",
          fontFamily: "monospace",
          fontSize: "14px",
        }}
        text={`
const CardContainerSectionExample = ({ isEmpty }: { isEmpty: boolean }) => {
  return (
    <CardContainer
      primaryAction={{
        icon: "addOutline",
        title: "Add Student",
        onClick: () => alert("Open add student form"),
      }}
      variant={"section"}
      isEmpty={isEmpty}
    >
      <div className={"flex flex-1 flex-col space-y-3"}>
        <div className={"flex items-center justify-between"}>
          <span className={"text-body-600 text-grey-900"}>{"Students"}</span>
          <CardContainerActions />
        </div>
        <CardContainerPlaceholder>
          <ContentPlaceholder
            icon="helpCircleOutline"
            title="No students added yet!"
            action={
              <Button
                variant={"brand"}
                onClick={() => alert("create student with family")}
                text={"Add Student"}
              />
            }
          />
        </CardContainerPlaceholder>
        <CardContainerContent>
          {students.data.map((student, index) => {
            return <StudentCard key={index} student={student} />;
          })}
        </CardContainerContent>
      </div>
    </CardContainer>
  );
};
`}
        showLineNumbers={false}
        language={"typescript"}
        wrapLongLines={true}
      />
    </ToggleContent>
  </ToggleDoc>
);

export const CardContainerPage = () => {
  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title="CardContainer" />
      </AdminTitleBar>
      <AdminContent>
        <DocIntro>
          <Intro />
        </DocIntro>
        <CardContainerPageExample />
        <EmptyCardContainerPageExample />
        <CardContainerSectionExample />
        <EmptyCardContainerSectionExample />
      </AdminContent>
    </AdminLayout>
  );
};
