import { useEffect } from "react";

import { authState } from "@/modules/auth/authState";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { Router } from "@/routing/router";

export const Home = () => {
  useEffect(() => {
    authState.load().then(({ account }) => {
      if (!account) {
        return Router.push("Accounts");
      }

      if (account?.role === "admin") {
        return Router.push("AdminHome");
      }

      if (account?.role === "family") {
        return Router.push("CustomerHome");
      }

      if (account?.role === "staff") {
        return Router.push("CompanyHome");
      }
    });
  }, []);

  return <LoadingSpinnerDark />;
};
