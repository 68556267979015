import { FieldValues, UseFormReturn } from "react-hook-form";

import { cn, FieldDefinition } from "shared/lib";

import { InputErrorMessage } from "@/modules/common/form/InputErrorMessage";
import { FieldController } from "@/modules/common/formBuilder/FieldController";

export const FormField = <Schema extends FieldValues>({
  field,
  fieldDef,
  error,
  columnWidth,
  formHandlers,
}: {
  field: keyof Schema;
  fieldDef: FieldDefinition;
  error: string | undefined;
  columnWidth?: number;
  formHandlers: UseFormReturn<Schema>;
}) => {
  return (
    <div
      className={cn({ "flex-1": !columnWidth })}
      style={{
        flexBasis: columnWidth ? `${columnWidth}%` : "auto",
      }}>
      <FieldController
        field={field}
        fieldDef={fieldDef}
        formHandlers={formHandlers}
      />
      <InputErrorMessage error={error} />
    </div>
  );
};
