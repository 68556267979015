import { ContentPlaceholder } from "shared/components";
import { useLocationsAndRooms } from "shared/lib";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { Breadcrumb } from "@/modules/common/nav/Breadcrumbs";
import { LocationCard } from "@/modules/company/locations/LocationCard";
import { Router } from "@/routing/router";

export const ArchivedLocationsPage = () => {
  const { locations, roomCountForLocation, isEmpty } = useLocationsAndRooms({
    onlyArchived: true,
  });

  const crumbs: Breadcrumb[] = [
    {
      text: "Locations",
      onPress: () => Router.push("LocationList"),
    },
    {
      text: "Archived Locations",
    },
  ];

  return (
    <CompanyCardPage
      title={"Archived Locations"}
      isEmpty={isEmpty}
      crumbs={crumbs}>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon={"archiveOutline"}
          title={"No archived locations"}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {locations.map((location, key) => (
          <LocationCard
            location={location}
            roomCount={roomCountForLocation(location)}
            key={key}
          />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};
