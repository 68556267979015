import { ContentPlaceholder } from "shared/components";
import { ContentPlaceholderProps } from "shared/lib";

import { CodeProps, DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Button } from "@/modules/common/ui/button/Button";

const options: Array<Option<ContentPlaceholderProps>> = [
  {
    title: "No content were not created",
    props: [
      {
        icon: "peopleOutline",
        title: "No staff members yet",
        description: "Create a staff member and invite them to Class Manager.",
        action: (
          <Button
            text={"Create"}
            variant={"brand"}
            size={"sm"}
            onClick={() => {}}
          />
        ),
      },
    ],
  },
  {
    title: "We couldn't find any results",
    props: [
      {
        icon: "searchOutline",
        title: "We couldn’t find what you’re looking for",
        description: "Try broadening your search.",
      },
    ],
  },

  {
    title: "The page could not be loaded",
    props: [
      {
        icon: "helpCircleOutline",
        title: "The page could not be loaded",
        description: "This page is unavailable, please check back soon!",
      },
    ],
  },
];

export const ContentPlaceholderPage = () => {
  const getTriggerCode = (
    props: ContentPlaceholderProps[],
  ): CodeProps<ContentPlaceholderProps>[] => {
    return props.map(function (prop: ContentPlaceholderProps) {
      return {
        ...prop,
        trigger: "<Button text={'Options'} />",
      };
    });
  };
  return (
    <>
      <DocWrapper
        title="Content Placeholders"
        component={ContentPlaceholder}
        options={options}
        transformProps={getTriggerCode}
      />
    </>
  );
};
