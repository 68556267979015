import { FieldValues, UseFormReturn } from "react-hook-form";

import {
  cn,
  FieldDefinition,
  FormDefinition,
  FormValues,
  getContentBlockDefinitionsForRow,
  shouldShowField,
} from "shared/lib";

import { FormField } from "@/modules/common/formBuilder/FormField";
import { getError } from "@/modules/common/formBuilder/formHelpers";

export const FormGroup = <
  Schema extends FieldValues,
  Fields extends Record<keyof Schema, FieldDefinition>,
>({
  heading,
  description,
  fields,
  formDefinition,
  formHandlers,
  watchedValues,
  groupIndex,
}: {
  heading?: string | null;
  description?: string;
  fields: string[][];
  formDefinition: FormDefinition<Schema>;
  formHandlers: UseFormReturn<Schema>;
  watchedValues: FormValues<Fields>;
  groupIndex: number;
}) => {
  const atLeastOneFieldVisible = fields.some(row =>
    row.some(field => shouldShowField(field, formDefinition, watchedValues)),
  );
  if (!atLeastOneFieldVisible) {
    return null;
  }

  return (
    <fieldset>
      {heading && (
        <div className={cn({ "pt-4": groupIndex > 0 })}>
          <legend className="pb-2 text-body-600">{heading}</legend>
          {description && (
            <p className="pb-5 text-body-400 text-grey-600">{description}</p>
          )}
        </div>
      )}

      {fields.map(row => {
        const { beforeContentBlockDefinitions, afterContentBlockDefinitions } =
          getContentBlockDefinitionsForRow(formDefinition, row);

        return (
          <div key={`row-${row.join(",")}`}>
            {beforeContentBlockDefinitions.map(def => (
              <div
                key={`content-block-${def.closestField as string}-${def.position}`}>
                {def.content}
              </div>
            ))}
            <div className="flex flex-row gap-x-5">
              {row.map((field, fieldIndex) => {
                if (!shouldShowField(field, formDefinition, watchedValues)) {
                  return null;
                }

                const rowSpec = formDefinition.rows.find(r =>
                  r.fields.includes(field),
                );
                const columnWidths = rowSpec?.columnWidths ?? [];
                const columnWidth =
                  columnWidths[fieldIndex] ??
                  100 - columnWidths.reduce((a, b) => a + b, 0);
                const error = getError(String(field), formHandlers.formState);
                const fieldDef = formDefinition.fields[field];
                return (
                  <FormField
                    key={`field-${field as string}`}
                    field={field}
                    columnWidth={columnWidth}
                    fieldDef={fieldDef}
                    error={error}
                    formHandlers={formHandlers}
                  />
                );
              })}
            </div>
            {afterContentBlockDefinitions.map(def => (
              <div
                key={`content-block-${def.closestField as string}-${def.position}`}>
                {def.content}
              </div>
            ))}
          </div>
        );
      })}
    </fieldset>
  );
};
