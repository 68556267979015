import { useEffect, useState } from "react";

import { CreateStudentRequest, StudentDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../../../alertState";
import { getPlatformFunction } from "../../../../platformSpecific";
import { useAuthState } from "../../../AuthStateProvider";
import { useFormActions } from "../../../FormActionsProvider";
import { useAddStudentInCheckoutForm } from "../../../formDefinitions/addStudentInCheckout";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ButtonComponent } from "../../../interfaces";

interface AddStudentToEmptyFamilyProps {
  Button: ButtonComponent;
  setGoNext: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AddStudentToEmptyFamily = ({
  Button,
  setGoNext,
}: AddStudentToEmptyFamilyProps) => {
  const { GenericForm, Text, View } = useGenericComponents();
  const api = getPlatformFunction("api");

  const { account } = useAuthState();
  const {
    submit,
    isLoading: isLoadingNewStudent,
    setResetAfterSubmit,
  } = useFormActions();

  useEffect(() => {
    setResetAfterSubmit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [shouldSetGoNext, setShouldSetGoNext] = useState(false);

  const onSuccess = (member: StudentDto) => {
    showAlert({
      content: `${member.firstname} added successfully`,
      variant: "success",
    });
    if (shouldSetGoNext) {
      setGoNext(true);
    } else {
      setGoNext(false);
    }
  };

  return (
    <View className={"flex flex-col justify-start bg-white"}>
      <View className={"flex justify-end px-5 py-3 md:hidden"}>
        <Button variant={"tertiary"} onClick={() => setGoNext(true)}>
          <Text className={"text-body-400 text-grey-900"}>Skip for now</Text>
        </Button>
      </View>
      <View className="rounded border-0 md:border border-grey-300 bg-white w-full space-y-4 px-5 py-5 md:max-w-lg md:px-8 md:py-8">
        <View className={"flex flex-row justify-between items-center"}>
          <Text className={"text-heading5-600 text-grey-900"}>
            Who is taking classes?
          </Text>
          <Button
            className={"hidden md:inline-flex"}
            variant={"tertiary"}
            onClick={() => setGoNext(true)}
            text={"Skip for now"}
          />
        </View>
        <View>
          <GenericForm
            formDefinitionHook={useAddStudentInCheckoutForm}
            defaultValues={{}}
            apiRequest={(formData: CreateStudentRequest) => {
              return api.students.createStudent({
                createStudentRequest: {
                  ...formData,
                  familyId: account!.entityId!,
                },
              });
            }}
            onSuccess={onSuccess}
          />
        </View>
        <View className="hidden md:flex flex-col justify-start space-y-5">
          <View className={"w-full"}>
            <Button
              variant={"brand"}
              onClick={() => {
                setShouldSetGoNext(true);
                setTimeout(submit);
              }}
              text={"Add"}
              disabled={isLoadingNewStudent}
              isFullWidth={true}
            />
          </View>
          <View className={"w-full"}>
            <Button
              variant={"secondary"}
              onClick={() => {
                setShouldSetGoNext(false);
                setTimeout(submit);
              }}
              text={"Save and add another"}
              disabled={isLoadingNewStudent}
              isFullWidth={true}
            />
          </View>
        </View>
      </View>
      <View className="flex w-full flex-col justify-start space-y-5 px-5 py-5 md:hidden">
        <View className={"w-full"}>
          <Button
            variant={"brand"}
            onClick={() => {
              setShouldSetGoNext(true);
              setTimeout(submit);
            }}
            text={"Add"}
            disabled={isLoadingNewStudent}
            isFullWidth={true}
          />
        </View>
        <View className={"w-full"}>
          <Button
            variant={"secondary"}
            onClick={() => {
              setShouldSetGoNext(false);
              setTimeout(submit);
            }}
            text={"Save and add another"}
            disabled={isLoadingNewStudent}
            isFullWidth={true}
          />
        </View>
      </View>
    </View>
  );
};
