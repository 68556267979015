import { useEffect, useState } from "react";

import { WaitingListDto } from "@justraviga/classmanager-sdk";

import {
  AggregateClassRelatedData,
  aggregateClassRelatedDataEmptyList,
  fetchClassRelatedData,
} from "./fetchClassRelatedData";
import { Api } from "../api";

export interface FamilyWaitingList
  extends Omit<AggregateClassRelatedData<WaitingListDto>, "list"> {
  waitingList: WaitingListDto[];
  isLoading: boolean;
  isEmpty: boolean;
}

export const useFamilyWaitingList = (api: Api): FamilyWaitingList => {
  const [wholeList, setWholeList] = useState<
    AggregateClassRelatedData<WaitingListDto>
  >(aggregateClassRelatedDataEmptyList);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWaitingList = async ({ api }: { api: Api }) =>
      await fetchClassRelatedData<WaitingListDto>(api, () =>
        api.waitingLists.listWaitingList({
          selectAll: true,
        }),
      );

    fetchWaitingList({ api }).then(allList => {
      setWholeList(allList);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    waitingList: wholeList.list,
    students: wholeList.students,
    classes: wholeList.classes,
    seasons: wholeList.seasons,
    isLoading,
    isEmpty: wholeList.list.length === 0,
  };
};
