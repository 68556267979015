import React, { useState } from "react";

import { CodeBlock } from "react-code-blocks";

import {
  ExampleFormSchema,
  formBuildingExample,
  formBuildingIntro,
  useExampleFormDefinition,
} from "shared/components";

import { renderMarkdown } from "@/lib/utils";
import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const FormBuilderPage = () => {
  const [continueEditing, setContinueEditing] = useState(false);

  const onSubmit = (data: object) => {
    console.log("onSubmit", { data });
    // do something with data
    if (continueEditing) {
      // do something
    } else {
      // do something
    }
  };

  const {
    form,
    formHandlers: { handleSubmit },
  } = useFormBuilder<ExampleFormSchema>(
    useExampleFormDefinition(),
    onSubmit,
    {},
  );

  const saveAndContinue = () => {
    setContinueEditing(true);
    handleSubmit(onSubmit, showErrors)();
  };

  const showErrors = (errors: object) => {
    console.error({ errors });
  };

  const save = () => {
    setContinueEditing(false);
    handleSubmit(onSubmit, showErrors)();
  };

  const [showCode, setShowCode] = React.useState(false);

  const toggleCode = () => {
    setShowCode(!showCode);
  };

  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title="Form Definition Builder + Form Builder" />
      </AdminTitleBar>
      <AdminContent>
        <p>{renderMarkdown(formBuildingIntro)}</p>
        <div className="my-5 border-t-2 pt-5 md:border-2 md:p-5">
          <div className="mb-5 flex items-center justify-between">
            <h2 className="text-16">Example</h2>
            <IconButton
              onClick={toggleCode}
              variant="standard"
              icon="codeSlashOutline"
              size="sm"
              aria-label="Toggle example code"
            />
          </div>
          <div className="flex flex-wrap gap-8">
            <div className="max-w-md grow">
              {form}
              <div className="flex justify-end gap-x-2 py-8">
                <Button
                  variant="secondary"
                  onClick={saveAndContinue}
                  text="Save and continue editing"
                />
                <Button variant="primary" onClick={save} text="Save" />
              </div>
            </div>
            {showCode && (
              <div className="mt-8">
                <CodeBlock
                  as={undefined}
                  forwardedAs={undefined}
                  codeContainerStyle={{
                    borderRadius: "0.5rem",
                    padding: "1.2rem",
                    fontFamily: "monospace",
                    fontSize: "14px",
                  }}
                  text={formBuildingExample}
                  showLineNumbers={false}
                  language={"typescript"}
                  wrapLongLines={true}
                />
              </div>
            )}
          </div>
        </div>
      </AdminContent>
    </AdminLayout>
  );
};
