import { useContext } from "react";

import { BasketContext } from "./basketContext";

export const useBasket = (): BasketContext => {
  const context = useContext(BasketContext);
  if (!context) {
    throw new Error("useBasket must be used within a BasketProvider");
  }
  return context;
};
