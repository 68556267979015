import { Chip } from "./Chip";
import { useGenericComponents } from "../GenericComponentsProvider";

export interface ClearableChipProps {
  chips: {
    label: string;
    action: () => void;
  }[];
}

export const ClearableChip = ({ chips }: ClearableChipProps) => {
  const { View } = useGenericComponents();
  return (
    <View className="flex flex-row flex-wrap gap-3">
      {chips.map(chip => (
        <View key={chip.label}>
          <Chip
            label={chip.label}
            variant={"secondary"}
            rightIcon="closeOutline"
            rightIconAction={chip.action}
          />
        </View>
      ))}
    </View>
  );
};
