import { useAuthState } from "shared/components";
import { ContentPlaceholder } from "shared/components";
import { enumLabel, PermissionLayoutProps, usePermission } from "shared/lib";

export const PermissionLayout = ({
  children,
  permission,
}: PermissionLayoutProps) => {
  const { hasPermission } = usePermission();
  const { account, user } = useAuthState();

  if (permission === undefined || hasPermission(permission)) {
    return children;
  }

  return (
    <section className={"flex flex-col items-center space-y-8 p-2"}>
      <ContentPlaceholder
        icon={"helpCircleOutline"}
        title={"You don’t currently have access to this content."}
        description={
          "Contact the owner of your company if you need them to grant you permission to use it."
        }
      />
      <div
        className={
          "flex flex-col space-y-4 text-center text-label-400 text-sm text-grey-600"
        }>
        <p>
          Account: {user?.firstname} {user?.lastname}
        </p>
        <p>{`Permission Level: ${enumLabel("accountPermissionSet", account?.permissionSet)}`}</p>
      </div>
    </section>
  );
};
