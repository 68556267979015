import {
  QuickActionButton,
  useQuickActionButtonVariations,
} from "shared/components";

import { DocWrapper } from "@/modules/admin/docs/DocWrapper";

export const QuickActionButtonPage = () => {
  const variations = useQuickActionButtonVariations();
  return (
    <>
      <DocWrapper
        title="Quick Action Button"
        component={QuickActionButton}
        options={variations}
      />
    </>
  );
};
