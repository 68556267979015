import { PaginatedResourceResponse } from "@justraviga/classmanager-sdk";

export interface EntityListResponse<EntityDto> {
  data: Array<EntityDto>;
  pagination: PaginatedResourceResponse;
}

export function mapDtoToEntity<EntityDto, EntityInterface>(
  entityListResponse: EntityListResponse<EntityDto>,
  entityFactory: (entityDto: EntityDto) => EntityInterface,
) {
  return {
    ...entityListResponse,
    data: entityListResponse.data.map(entity => entityFactory(entity)),
  };
}
