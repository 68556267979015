import React from "react";

import { getLocation, Link } from "@swan-io/chicane";

import { Icon, IconName } from "@/modules/common/ui/icon/Icon";

type NavItemNoSubMenuProps = {
  icon: IconName;
  isExpanded: boolean;
  isSelected: boolean;
  label: string;
  path: string;
  onNavigate: () => void;
};

export const NavItemNoSubMenu = React.memo(
  ({
    icon,
    isExpanded,
    isSelected,
    onNavigate,
    label,
    path,
  }: NavItemNoSubMenuProps) => {
    const location = getLocation();
    const isActive = location.raw.path === path;

    return (
      <Link
        to={path}
        onClick={onNavigate}
        className={[
          "flex items-center justify-between gap-4 rounded",
          isSelected && isActive
            ? "bg-black text-white"
            : `text-brand-contrast hover:bg-grey-900/10`,
          isExpanded ? "px-4 py-2" : "p-2",
        ].join(" ")}>
        <div className="flex w-6 items-center justify-center">
          <Icon
            size={24}
            name={icon}
            className={isSelected ? "text-white" : "text-brand-contrast"}
          />
        </div>
        {isExpanded && <span className={"flex-grow"}>{label}</span>}
      </Link>
    );
  },
);
