import { ContactDto } from "@justraviga/classmanager-sdk";

import { UseApi } from "../components/apiQueryFactory";

export const useCustomerContacts = (useApi: UseApi) => {
  const { data: contactsResponse, isLoading } = useApi("listContact", {});

  const sortContacts = (contacts: ContactDto[]) => {
    return contacts.sort((a, b) => {
      return a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1;
    });
  };

  return {
    isLoading,
    contacts: isLoading ? [] : sortContacts(contactsResponse?.data || []),
  };
};
