import { LinkFamilyToCompanyRequest } from "@justraviga/classmanager-sdk";

import { DefaultValues, FormBuilder } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = LinkFamilyToCompanyRequest;

function formDefinition() {
  return new FormDefinitionBuilder<Schema>()
    .text("firstname", {
      label: "First name",
      type: "text",
      required: true,
    })
    .text("lastname", {
      label: "Last name",
      type: "text",
      required: true,
    })
    .text("phone", {
      label: "Phone number",
      type: "tel",
      required: true,
    })
    .getDefinition();
}

export const useNameAndPhoneForm = (
  builder: FormBuilder,
  onSubmit: (data: Schema) => void,
  defaultValues: DefaultValues<Schema>,
) => {
  return builder(formDefinition(), onSubmit, defaultValues);
};
