import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
} from "@justraviga/classmanager-sdk";
import type { CheckoutRequestItems } from "@justraviga/classmanager-sdk/dist/models/CheckoutRequestItems";

import { useSharedPaymentMethodActions } from "shared/components";

import { CreateFooter } from "@/modules/common/form/CreateFooter";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { AddStripePaymentMethod } from "@/modules/common/payments/AddStripePaymentMethod";
import { CheckoutPaymentForm } from "@/modules/common/payments/CheckoutPaymentForm";
import { MultistepPaymentForm } from "@/modules/common/payments/MultistepPaymentForm";

export const usePaymentMethods = (
  onPaymentSuccess?: (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => void,
) => {
  const showConfirmationDialog = useAlertDialog().showAlert;
  const { openSheet: showForm } = useSheet();

  const sharedPaymentMethodActions = useSharedPaymentMethodActions(
    showConfirmationDialog,
  );

  return {
    ...sharedPaymentMethodActions,

    showAddPaymentMethodForm: (familyId: string) =>
      showForm({
        title: "Add payment method",
        content: <AddStripePaymentMethod familyId={familyId} />,
        footer: <UpdateFooter saveButtonText="Add" />,
      }),
    showTakePaymentForm: (familyId: string, balance: number = 0) =>
      showForm({
        title: "Make payment",
        content: <MultistepPaymentForm familyId={familyId} balance={balance} />,
        footer: <CreateFooter />,
      }),
    showCheckoutPaymentForm: (
      familyId: string,
      balance: number,
      requestItems: CheckoutRequestItems,
    ) =>
      showForm({
        title: "Pay",
        content: (
          <CheckoutPaymentForm
            familyId={familyId}
            amount={balance}
            onSuccess={onPaymentSuccess}
            requestItems={requestItems}
          />
        ),
        footer: <CreateFooter />,
      }),
  };
};
