export function range(length: number) {
  return Array(length)
    .fill(1)
    .map((_i, index) => index + 1);
}

/**
 * Returns an array containing numbers between start and end (inclusive)
 */
export const rangeBetween = (start: number, end: number) => {
  const step = end > start ? 1 : -1;
  const values = [start];
  let nextValue = start;

  while (nextValue !== end) {
    nextValue += step;
    values.push(nextValue);
  }

  return values;
};

export function isNonEmptyArray(value: unknown): boolean {
  return Array.isArray(value) && value.length > 0 && value[0];
}
