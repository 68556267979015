import {
  SignWaiversContent,
  SignWaiversHeader,
  useSignPendingWaiversForm,
} from "shared/components";
import { WaiverEntityWithAgreement } from "shared/lib";

import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import {
  DialogFooter,
  DialogHeader,
} from "@/modules/common/overlays/dialog/Dialog";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";

interface PendingWaiversDialogContentProps {
  pendingWaivers: WaiverEntityWithAgreement[];
}

export const PendingWaiversDialogContent = ({
  pendingWaivers,
}: PendingWaiversDialogContentProps) => {
  const { companyName, form, formState, handleFormSubmission } =
    useSignPendingWaiversForm(pendingWaivers, useFormBuilder);

  return (
    <div className="min-h-96 relative flex h-[100vh] min-w-min flex-col md:h-fit md:max-h-[calc(100vh-(2*4rem))]">
      <div className="overflow-y-scroll p-5 pb-0">
        <DialogHeader className="mb-6">
          <SignWaiversHeader />
        </DialogHeader>
        <SignWaiversContent
          companyName={companyName}
          pendingWaivers={pendingWaivers}
          form={form}
          BaseCard={BaseCard}
        />
      </div>
      <DialogFooter className="border-t border-grey-300 p-5">
        <Button
          disabled={!formState.isValid}
          loading={formState.isSubmitting}
          text="Confirm"
          variant={"primary"}
          onClick={handleFormSubmission}
          isFullWidth
        />
      </DialogFooter>
    </div>
  );
};
