import { ReactNode } from "react";

import { CompanyDto } from "@justraviga/classmanager-sdk";

import { useGenericComponents } from "../../GenericComponentsProvider";

export const EmailCompanyFooter = ({
  company,
  classManagerLogo,
}: {
  company: CompanyDto;
  classManagerLogo: ReactNode;
}) => {
  const { View, Text } = useGenericComponents();
  const { address } = company;

  return (
    <>
      <View className={"flex flex-col items-center mt-8"}>
        <Text className={"text-body-400 text-center"}>{company.name}</Text>
        <Text className={"text-label-400 text-grey-600 mt-1 text-center"}>
          {address &&
            [
              address.address1,
              address.address2,
              address.town,
              address.state,
              address.zip,
            ]
              .filter(item => item !== null && item !== "")
              .join(", ")}
        </Text>
      </View>
      <View className={"mt-8"}>{classManagerLogo}</View>
    </>
  );
};
