import {
  ListCourseRequest,
  LocationDto,
  RoomDto,
  StaffDto,
} from "@justraviga/classmanager-sdk";

import { uniqueValuesForKey } from "../collectionUtils";
import { UseApi } from "../components/apiQueryFactory";
import { ClassEntity, useClassList } from "../entities";

export const useClassesScheduleData = ({
  apiClient,
  request,
  locations,
  rooms,
  staff,
}: {
  apiClient: UseApi;
  request: ListCourseRequest;
  locations: LocationDto[];
  rooms: RoomDto[];
  staff: StaffDto[];
}): {
  classes: ClassEntity[];
  rooms: RoomDto[];
  locations: LocationDto[];
  staff: StaffDto[];
  isLoading: boolean;
} => {
  const { classes, isLoading } = useClassList(apiClient, request);

  const roomIds =
    classes === undefined
      ? []
      : uniqueValuesForKey(
          "roomId",
          classes.data
            .filter(c => !!c.class.roomId) // filter out null roomIds
            .map(c => c.class) as { roomId: string }[], // cast to required to satisfy typescript
        );

  const filteredRooms = rooms.filter(room => roomIds.includes(room.id));

  const locationIds = uniqueValuesForKey("locationId", filteredRooms);
  const filteredLocations = locations.filter(location =>
    locationIds.includes(location.id),
  );

  const staffIds =
    classes === undefined
      ? []
      : uniqueValuesForKey(
          "staffId",
          classes.data
            .filter(classEntity => !!classEntity.class.staffId) // filter out null staffIds
            .map((classEntity: ClassEntity) => classEntity.class) as {
            staffId: string;
          }[], // cast to required to satisfy typescript
        );

  const filteredStaff = staff.filter(staff => staffIds.includes(staff.id));

  return {
    isLoading,
    classes: classes?.data ?? [],
    rooms: filteredRooms,
    locations: filteredLocations,
    staff: filteredStaff,
  };
};
