import { useEffect } from "react";

import { match } from "ts-pattern";

import { useAuthState } from "shared/components";

import { authState } from "@/modules/auth/authState";
import { ContentAreaBoundary } from "@/modules/common/ui/ContentAreaBoundary";
import { LoadingSpinnerGrey } from "@/modules/common/ui/LoadingSpinnerGrey";
import { DiscountSchemeDetails } from "@/modules/company/billing/discountSchemes/DiscountSchemeDetails";
import { DiscountSchemeList } from "@/modules/company/billing/discountSchemes/DiscountSchemeList";
import { TransactionCreateFromLessonPage } from "@/modules/company/billing/transactions/TransactionCreateFromLessonPage";
import { TransactionsCreatePage } from "@/modules/company/billing/transactions/TransactionsCreatePage";
import { TransactionsListPage } from "@/modules/company/billing/transactions/TransactionsListPage";
import { CourseDetailsPage } from "@/modules/company/classPlanner/classes/CourseDetailsPage";
import { LessonAttendancesPage } from "@/modules/company/classPlanner/lessons/LessonAttendancesPage";
import { LessonDetailsPage } from "@/modules/company/classPlanner/lessons/LessonDetailsPage";
import { ArchivedSeasonsPage } from "@/modules/company/classPlanner/seasons/ArchivedSeasonsPage";
import { SeasonDetailsPage } from "@/modules/company/classPlanner/seasons/SeasonDetailsPage";
import { SeasonListPage } from "@/modules/company/classPlanner/seasons/SeasonListPage";
import { WaitingListPage } from "@/modules/company/classPlanner/waitingList/WaitingListPage";
import { CompanyAccountPage } from "@/modules/company/CompanyAccountPage";
import { CompanyDashboardPage } from "@/modules/company/CompanyDashboardPage";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { CustomFieldsPage } from "@/modules/company/customFields/CustomFieldsPage";
import { EnrolmentsPage } from "@/modules/company/enrolments/EnrolmentsPage";
import { StudentEnrolmentsPage } from "@/modules/company/enrolments/StudentEnrolmentsPage";
import { StudentTrialsPage } from "@/modules/company/enrolments/StudentTrialsPage";
import { StudentWaitingListPage } from "@/modules/company/enrolments/StudentWaitingListPage";
import { HolidayList } from "@/modules/company/holidays/HolidayList";
import { ArchivedLocationsPage } from "@/modules/company/locations/ArchivedLocationsPage";
import { LocationDetailsPage } from "@/modules/company/locations/LocationDetailsPage";
import { LocationsPage } from "@/modules/company/locations/LocationsPage";
import { ContactsList } from "@/modules/company/members/contact/ContactsList";
import { FamilyDetailsPage } from "@/modules/company/members/family/FamilyDetailsPage";
import { FamilyListPage } from "@/modules/company/members/family/FamilyListPage";
import { FamilyPaymentMethodsPage } from "@/modules/company/members/family/FamilyPaymentMethodsPage";
import { StudentDetailsPage } from "@/modules/company/members/student/StudentDetailsPage";
import { StudentList } from "@/modules/company/members/student/StudentList";
import { EmailsPage } from "@/modules/company/messages/EmailsPage";
import { ViewEmailPage } from "@/modules/company/messages/ViewEmailPage";
import { WriteEmailPage } from "@/modules/company/messages/WriteEmailPage";
import { PricingTiersPage } from "@/modules/company/pricingTiers/PricingTiersPage";
import { SettingsPage } from "@/modules/company/settings/SettingsPage";
import { StaffDetailsPage } from "@/modules/company/staff/StaffDetailsPage";
import { StaffList } from "@/modules/company/staff/StaffList";
import { TaxSettingsPage } from "@/modules/company/tax/TaxSettingsPage";
import { AccountStatementPage } from "@/modules/company/transactions/AccountStatementPage";
import { ClassTrialsPage } from "@/modules/company/trials/ClassTrialsPage";
import { TrialsPage } from "@/modules/company/trials/TrialsPage";
import { ClassWaitingListPage } from "@/modules/company/waitingLists/ClassWaitingListPage";
import { WaiverDetails } from "@/modules/company/waivers/WaiverDetails";
import { WaiversArchivedPage } from "@/modules/company/waivers/WaiversArchivedPage";
import { WaiversMemberPage } from "@/modules/company/waivers/WaiversMemberPage";
import { WaiversPage } from "@/modules/company/waivers/WaiversPage";
import { RouteNotFound } from "@/routing/RouteNotFound";
import { Router } from "@/routing/router";

export const CompanyRouter = () => {
  const route = Router.useRoute([
    "CompanyCustomFields",
    "CompanyFamilyWaivers",
    "CompanyHome",
    "CompanySettings",
    "CompanyStudentWaivers",
    "CompanyWaiverDetails",
    "CompanyWaivers",
    "CompanyWaiversArchived",
    "ContactsList",
    "Enrolments",
    "FamilyDetails",
    "FamilyPaymentMethods",
    "AccountStatement",
    "FamilyList",
    "Holidays",
    "LocationList",
    "LocationListArchived",
    "LocationDetails",
    "StaffDetails",
    "StaffList",
    "StudentDetails",
    "ClassTrials",
    "StudentEnrolments",
    "ClassWaitingList",
    "StudentTrials",
    "StudentWaitingList",
    "StudentList",
    "SeasonList",
    "SeasonListArchived",
    "SeasonDetails",
    "TaxSettings",
    "Trials",
    "CompanyAccount",
    "PricingTiers",
    "ClassDetails",
    "WaitingList",
    "LessonDetails",
    "ClassLessonAttendances",
    "DiscountSchemeList",
    "DiscountSchemeDetails",
    "TransactionList",
    "TransactionCreate",
    "TransactionCreateFromLesson",
    "Emails",
    "WriteEmail",
    "ViewEmail",
  ]);

  // Redirect the user if they're not logged in or not a staff member
  useEffect(() => {
    authState.load().then(({ account }) => {
      if (account?.role !== "staff") {
        return Router.push("Home");
      }
    });
  }, []);

  // Company pages only make sense in the context of a company, so wait for the AuthState
  // to load before going any further. This prevents errors when trying to access company
  // settings via useSettings().
  const { account } = useAuthState();
  if (!account || !account.company) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinnerGrey />
      </div>
    );
  }

  const routeComponent = match(route)
    .with({ name: "CompanyHome" }, () => <CompanyDashboardPage />)
    .with({ name: "StaffList" }, () => <StaffList />)
    .with({ name: "StaffDetails" }, ({ params: { id } }) => (
      <StaffDetailsPage id={id} />
    ))
    .with({ name: "CompanySettings" }, () => <SettingsPage />)
    .with({ name: "CompanyAccount" }, () => <CompanyAccountPage />)
    .with({ name: "CompanyWaivers" }, () => <WaiversPage />)
    .with(
      { name: "CompanyWaiverDetails" },
      ({ params: { id, sourcePage } }) => (
        <WaiverDetails id={id} from={sourcePage} />
      ),
    )
    .with({ name: "CompanyWaiversArchived" }, () => <WaiversArchivedPage />)
    .with({ name: "CompanyFamilyWaivers" }, ({ params: { familyId } }) => (
      <WaiversMemberPage memberId={familyId} source={"Families"} />
    ))
    .with({ name: "CompanyStudentWaivers" }, ({ params: { studentId } }) => (
      <WaiversMemberPage memberId={studentId} source={"Students"} />
    ))
    .with({ name: "CompanyCustomFields" }, () => (
      <CompanyLayout>
        <ContentAreaBoundary>
          <CustomFieldsPage />
        </ContentAreaBoundary>
      </CompanyLayout>
    ))
    .with({ name: "Enrolments" }, ({ params: { classId } }) => (
      <EnrolmentsPage classId={classId} />
    ))
    .with({ name: "StudentList" }, () => <StudentList />)
    .with({ name: "StudentDetails" }, ({ params: { id } }) => (
      <CompanyLayout alwaysShowGlobalHeader={false}>
        <ContentAreaBoundary>
          <StudentDetailsPage id={id} />
        </ContentAreaBoundary>
      </CompanyLayout>
    ))
    .with({ name: "ClassTrials" }, ({ params: { classId } }) => (
      <ClassTrialsPage classId={classId} />
    ))
    .with({ name: "StudentEnrolments" }, ({ params: { studentId } }) => (
      <StudentEnrolmentsPage studentId={studentId} />
    ))
    .with({ name: "ClassWaitingList" }, ({ params: { classId } }) => (
      <ClassWaitingListPage classId={classId} />
    ))
    .with({ name: "StudentTrials" }, ({ params: { studentId } }) => (
      <StudentTrialsPage studentId={studentId} />
    ))
    .with({ name: "StudentWaitingList" }, ({ params: { studentId } }) => (
      <StudentWaitingListPage studentId={studentId} />
    ))
    .with({ name: "FamilyList" }, () => <FamilyListPage />)
    .with({ name: "FamilyDetails" }, ({ params: { id } }) => (
      <FamilyDetailsPage id={id} />
    ))
    .with({ name: "FamilyPaymentMethods" }, ({ params: { familyId } }) => (
      <FamilyPaymentMethodsPage familyId={familyId} />
    ))
    .with({ name: "AccountStatement" }, ({ params: { familyId } }) => (
      <AccountStatementPage familyId={familyId} />
    ))
    .with({ name: "ContactsList" }, ({ params: { id } }) => (
      <ContactsList id={id} />
    ))
    .with({ name: "Holidays" }, () => <HolidayList />)
    .with({ name: "LocationList" }, () => <LocationsPage />)
    .with({ name: "LocationListArchived" }, () => <ArchivedLocationsPage />)
    .with({ name: "LocationDetails" }, ({ params: { id } }) => (
      <LocationDetailsPage id={id} />
    ))
    .with({ name: "TaxSettings" }, () => <TaxSettingsPage />)
    .with({ name: "SeasonList" }, () => <SeasonListPage />)
    .with({ name: "WaitingList" }, () => <WaitingListPage />)
    .with({ name: "SeasonListArchived" }, () => <ArchivedSeasonsPage />)
    .with({ name: "SeasonDetails" }, ({ params: { id } }) => (
      <CompanyLayout alwaysShowGlobalHeader={false}>
        <ContentAreaBoundary>
          <SeasonDetailsPage id={id} />
        </ContentAreaBoundary>
      </CompanyLayout>
    ))
    .with({ name: "Trials" }, ({ params: { classId } }) => (
      <TrialsPage classId={classId} />
    ))
    .with({ name: "PricingTiers" }, ({ params: { seasonId } }) => (
      <PricingTiersPage seasonId={seasonId} />
    ))
    .with({ name: "ClassDetails" }, ({ params: { id } }) => (
      <CourseDetailsPage id={id} />
    ))
    .with({ name: "LessonDetails" }, ({ params: { id, date, time } }) => (
      <LessonDetailsPage courseId={id} date={date} time={time} />
    ))
    .with(
      { name: "ClassLessonAttendances" },
      ({ params: { id, date, time } }) => (
        <LessonAttendancesPage id={id} date={date} time={time} />
      ),
    )
    .with({ name: "DiscountSchemeList" }, () => <DiscountSchemeList />)
    .with({ name: "DiscountSchemeDetails" }, ({ params: { id } }) => (
      <DiscountSchemeDetails id={id} />
    ))
    .with({ name: "TransactionList" }, () => <TransactionsListPage />)
    .with({ name: "TransactionCreate" }, ({ params: { familyId } }) => (
      <TransactionsCreatePage familyIds={familyId ? [familyId] : []} />
    ))
    .with(
      { name: "TransactionCreateFromLesson" },
      ({ params: { lessonId } }) => (
        <TransactionCreateFromLessonPage lessonId={lessonId} />
      ),
    )
    .with({ name: "Emails" }, () => <EmailsPage />)
    .with({ name: "WriteEmail" }, ({ params: { id, contactId } }) => (
      <WriteEmailPage id={id} contactId={contactId} />
    ))
    .with({ name: "ViewEmail" }, ({ params: { id } }) => (
      <ViewEmailPage id={id} />
    ))
    // @TODO: re-enable when we have quickbooks integration ready
    // .with({ name: "Integrations" }, () => <IntegrationsPage />)
    .otherwise(() => <RouteNotFound redirectTo="CompanyHome" />);

  /**
   * todo:
   *   eventually wrap this component in the CompanyLayout and ContentAreaBoundary,
   *   but for now just add the wrappers to each route as we update them.
   */
  return routeComponent;
};
