import { LessonDto } from "@justraviga/classmanager-sdk";

import { useTrialActions } from "shared/components";
import { getCancelledAdjustment } from "shared/lib";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { useLessonActions } from "@/modules/company/classPlanner/lessons/useLessonActions";
import { Router } from "@/routing/router";

export const useLessonActionMenuItems = ({
  lesson,
  isCancelled,
  isLessonDetailsPage = false,
}: {
  lesson: LessonDto;
  isCancelled: boolean;
  isLessonDetailsPage?: boolean;
}): {
  actions: ActionMenuItemProps[];
} => {
  const { cancelLesson, undoCancelledLesson, addFeeForLesson, changeDate } =
    useLessonActions();
  const { showCreateForm: showCreateTrialForm } = useTrialActions();

  const getLessonScheduleActionItems = (): ActionMenuItemProps[] => {
    const cancelledAdjustment = getCancelledAdjustment(lesson);

    if (isCancelled) {
      return [
        {
          title: "Undo cancellation",
          leftIcon: "arrowUndoOutline",
          onClick: () =>
            cancelledAdjustment && undoCancelledLesson(cancelledAdjustment),
        },
      ];
    }

    return [
      {
        title: "Change date & time",
        leftIcon: "calendarClearOutline",
        onClick: () =>
          changeDate({
            classId: lesson.classId,
            oldDate: lesson.date,
            oldTime: lesson.startTime,
            redirectToNewLesson: isLessonDetailsPage
              ? (newDate, newTime) => {
                  Router.push("LessonDetails", {
                    id: lesson.classId,
                    date: newDate,
                    time: newTime.replace(":", "-"),
                  });
                }
              : undefined,
          }),
      },
      {
        title: "Cancel single lesson",
        leftIcon: "closeCircleOutline",
        onClick: () => cancelLesson(lesson),
      },
    ];
  };

  const getGeneralActionItems = (): ActionMenuItemProps[] => {
    const actions: ActionMenuItemProps[] = [];

    if (!isCancelled) {
      actions.push({
        title: "Add trial students",
        leftIcon: "timeOutline",
        onClick: () => showCreateTrialForm({ classId: lesson.classId, lesson }),
      });
    }

    return actions;
  };

  return {
    actions: [
      ...getGeneralActionItems(),
      {
        title: "Lesson Schedule",
        group: {
          items: getLessonScheduleActionItems(),
        },
      },
      {
        title: "Billing",
        group: {
          items: [
            {
              title: "Add fee for this lesson",
              leftIcon: "cashOutline",
              onClick: () => addFeeForLesson(lesson),
            },
          ],
        },
      },
    ],
  };
};
