import { createContext, ReactNode, useContext, useState } from "react";

import {
  AlertDialog,
  AlertDialogProps,
} from "@/modules/common/overlays/alertDialog/AlertDialog";

const AlertDialogContext = createContext({
  showAlert: {} as (
    alert: Omit<AlertDialogProps, "onResult" | "open">,
  ) => Promise<boolean>,
  closeAlert: {} as () => void,
});

export interface ConfirmAlertDialogProps
  extends Omit<AlertDialogProps, "onResult" | "open"> {}

let resolve: (value: PromiseLike<boolean> | boolean) => void;

export const AlertDialogProvider = ({ children }: { children: ReactNode }) => {
  const [currentAlert, setCurrentAlert] = useState<ConfirmAlertDialogProps>();
  const [visible, setVisible] = useState(false);

  const showAlert = (alert: ConfirmAlertDialogProps): Promise<boolean> => {
    setCurrentAlert(alert);
    setVisible(true);

    return new Promise(res => (resolve = res));
  };

  const closeAlert = () => {
    setCurrentAlert(undefined);
    setVisible(false);
  };

  const handleResult = (result: boolean) => {
    setCurrentAlert(undefined);
    setVisible(false);
    resolve(result);
  };

  return (
    <AlertDialogContext.Provider value={{ showAlert, closeAlert }}>
      {visible && currentAlert && (
        <AlertDialog {...currentAlert} onResult={handleResult} open={visible} />
      )}
      {children}
    </AlertDialogContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAlertDialog = () => useContext(AlertDialogContext);
