import { SeasonDto } from "@justraviga/classmanager-sdk";

import { SeasonPreview as SharedSeasonPreview } from "shared/components";

import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { Router } from "@/routing/router";

export const SeasonPreview = ({ season }: { season: SeasonDto }) => {
  const { closeSheet } = useSheet();

  function navigateToSettings() {
    Router.push("CompanySettings");
    closeSheet();
  }

  return (
    <SharedSeasonPreview
      season={season}
      navigateToSettings={navigateToSettings}
      HorizontalSeparator={HorizontalSeparator}
    />
  );
};
