import { InvitationStatus } from "@justraviga/classmanager-sdk";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { StatusIcon } from "@/modules/common/ui/icon/StatusIcon";
import {
  StatusIconSize,
  StatusIconType,
} from "@/modules/common/ui/icon/StatusIconType";

export const InvitationStatusIcon = ({
  status,
  size,
}: {
  status?: InvitationStatus;
  size?: StatusIconSize;
}) => {
  const [type, tooltipText] = (() => {
    switch (status) {
      case InvitationStatus.Accepted:
        return [StatusIconType.Success, "Active Class Manager Member"];
      case InvitationStatus.Invited:
        return [StatusIconType.Invited, "Class Manager Invitation Pending"];
      case InvitationStatus.NotInvited:
      default:
        return [StatusIconType.Default, "Not Invited"];
    }
  })();

  return (
    <Tooltip
      showContent={!!tooltipText}
      trigger={
        <button type="button" className="flex items-center ">
          <StatusIcon type={type} size={size} />
        </button>
      }>
      {tooltipText}
    </Tooltip>
  );
};
