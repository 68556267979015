import { AccountPermission, AddressDto } from "@justraviga/classmanager-sdk";

import { useApi } from "@/lib/api/apiClient";
import { PermissionLayout } from "@/modules/common/PermissionLayout";
import { Button } from "@/modules/common/ui/button/Button";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { useAddressesActions } from "@/modules/company/addresses/useAddressesActions";
import { DetailCard } from "@/modules/company/common/DetailCard";

interface AddressCardProps {
  entityId: string;
}

export const AddressCard = ({ entityId }: AddressCardProps) => {
  const { data, isLoading } = useApi("getAddresses", { entityId });

  const { showUpdateForm } = useAddressesActions();

  const addresses = data?.addresses || [];

  const address = addresses.length > 0 ? addresses[0] : ({} as AddressDto);

  const buildAddress = (addr: AddressDto) => {
    return [addr.address1, addr.address2, addr.town, addr.state, addr.zip]
      .filter(Boolean)
      .join(", ");
  };

  const AddressCardContent = () => {
    if (isLoading) {
      return (
        <div className={"flex items-center justify-center p-3"}>
          <LoadingSpinnerDark />
        </div>
      );
    }

    return (
      <PermissionLayout permission={AccountPermission.Addresslist}>
        <section>
          <div className={"p-2 text-base text-grey-900"}>
            {addresses.length === 0 ? "-" : buildAddress(address)}
          </div>
        </section>
      </PermissionLayout>
    );
  };

  return (
    <DetailCard
      isFoldable={false}
      title={"Address"}
      icon={"locationOutline"}
      headerButton={
        <Button
          variant={"subtle"}
          size={"xs"}
          onClick={() => showUpdateForm(entityId, address)}
          text={"Edit"}
        />
      }
      children={<AddressCardContent />}
    />
  );
};
