import { useBreakpoint } from "../../../../breakpoints";
import { ButtonComponent } from "../../../interfaces";

interface AddStudentButtonProps {
  Button: ButtonComponent;
  showCreateForm: () => void;
}
export const AddStudentButton = ({
  Button,
  showCreateForm,
}: AddStudentButtonProps) => {
  const { md: isDesktop } = useBreakpoint();

  return (
    <Button
      variant={"secondary"}
      text={"Add student"}
      leftIcon={"addCircleOutline"}
      onClick={showCreateForm}
      size={isDesktop ? "lg" : "xs"}
    />
  );
};
