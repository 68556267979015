import { useWaitingListActions } from "shared/components";
import {
  findClass,
  findSeason,
  findStudent,
  getClassLabel,
  useFamilyWaitingList,
} from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { CardContainerPlaceholder } from "@/modules/common/cardContainer/CardContainer";
import { CustomerCardPage } from "@/modules/common/cardContainer/CustomerCardPage";
import { PendingEnrolmentCard } from "@/modules/customer/common/PendingEnrolmentCard";

export const FamilyWaitingListPage = () => {
  const { isEmpty, isLoading, waitingList, students, classes, seasons } =
    useFamilyWaitingList(api);
  const { cancel } = useWaitingListActions();

  return (
    <CustomerCardPage
      title={"Waiting list"}
      isEmpty={isEmpty}
      isLoading={isLoading}
      forceBackButton={true}>
      <section className={"flex flex-col space-y-5"}>
        <div>
          <span className={"text-label-400 text-grey-600"}>
            Waiting to join
          </span>
          <h4 className={"mt-1 text-heading4-600"}>
            {waitingList.length} {getClassLabel(waitingList.length)}
          </h4>
        </div>
        <CardContainerPlaceholder
          title={"You are not waiting to join any classes"}
        />
        {waitingList.map(entity => {
          const course = findClass(classes, entity.classId);

          if (!course) {
            return null;
          }

          const season = findSeason(seasons, course.entity.seasonId)!;
          const student = findStudent(students, entity.studentId)!;

          return (
            <PendingEnrolmentCard
              key={entity.id}
              student={student}
              course={course}
              season={season}
              rightActions={{
                header: course.entity.name,
                items: [
                  {
                    title: "Cancel request",
                    onClick: () => cancel(entity.id),
                    leftIcon: "closeCircleOutline",
                  },
                ],
              }}
            />
          );
        })}
      </section>
    </CustomerCardPage>
  );
};
