import { SortSchema, WaiverDto } from "@justraviga/classmanager-sdk";

import { sortWaiversList } from "./sortWaiversList";
import { WaiverEntity, waiverEntityFactory } from "./waiver";
import { WaiverImplementedActions } from "./waiverActions";
import { UseApi } from "../../components/apiQueryFactory";
import { mapDtoToEntity } from "../entityListUtils";

export function useSharedWaiversListPage(
  waiverActions: WaiverImplementedActions,
  archived: boolean,
  useApi: UseApi,
) {
  const { data: waivers, isLoading } = useApi("listWaiver", {
    sort: {
      createdAt: SortSchema.Asc,
    },
    onlyArchived: archived,
    selectAll: true,
  });

  const isEmpty = () => !waivers?.data || waivers.data.length === 0;
  const showCreateForm = () => waiverActions.showCreateForm();

  const sortedWaiverEntities = waivers
    ? sortWaiversList(
        mapDtoToEntity<WaiverDto, WaiverEntity>(waivers, waiverEntityFactory),
      )
    : undefined;

  return {
    waivers: sortedWaiverEntities,
    isLoading,
    isEmpty,
    showCreateForm,
  };
}
