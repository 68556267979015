import { AddressDto } from "@justraviga/classmanager-sdk/dist/models/AddressDto";

import {
  makeAddressesDeleteRequest,
  makeAddressesUpdateRequest,
  useAddressUpdateFormDefinition,
} from "shared/components";
import { showAlert } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";

export const useAddressesActions = () => {
  const defaultActions = usePlatformEntityActions<AddressDto>({
    entity: "address",
  });

  const { closeSheet } = useSheet();

  const actions = {
    deleteForEntity(entityId: string) {
      return Promise.resolve(
        makeAddressesDeleteRequest({ api, entityId }),
      ).then(() => {
        showAlert({
          content: `Address deleted successfully.`,
        });
      });
    },
    showUpdateForm: (entityId: string, address: AddressDto) => {
      const isAddressNotEmpty = address && Object.keys(address).length !== 0;

      return defaultActions.showUpdateForm({
        form: (
          <>
            <GenericForm
              apiRequest={makeAddressesUpdateRequest({ api, entityId })}
              defaultValues={address}
              formDefinitionHook={useAddressUpdateFormDefinition}
              onSuccess={defaultActions.onUpdateSuccess}
            />
            {isAddressNotEmpty && (
              <div className={"-ml-4"}>
                <Button
                  text={"Delete Address"}
                  variant={"destructive-tertiary"}
                  size={"sm"}
                  onClick={() =>
                    actions.deleteForEntity(entityId).then(closeSheet)
                  }
                />
              </div>
            )}
          </>
        ),
      });
    },
  };

  return actions;
};
