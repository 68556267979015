import { StripePaymentMethodCardDataDto } from "@justraviga/classmanager-sdk";

import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { CardTypeIcon } from "../../../common/CardTypeIcon";

export const CardPaymentDetails = ({
  cardDetails,
  id,
}: {
  cardDetails: StripePaymentMethodCardDataDto;
  id: string;
}) => {
  const { View, Text, Icon } = useGenericComponents();
  return (
    <View className={"flex flex-col space-y-2"}>
      <View className={"flex flex-row items-center justify-start space-x-2"}>
        <Icon name={"cardOutline"} size={20} label={"Cardholder information"} />
        <Text className={"text-body-400 text-grey-900 truncate"}>
          Cardholder information
        </Text>
      </View>
      <View className={"flex flex-row items-center justify-start space-x-4"}>
        <CardTypeIcon cardType={cardDetails.brand} />
        <Text className={"text-body-400 text-grey-900"}>
          •••• {cardDetails.last4}
        </Text>
      </View>
      <View className={"flex flex-row items-center justify-between"}>
        <Text className={"text-body-400 text-grey-600"}>Expiry date</Text>
        <Text className={"text-body-400 text-grey-900"}>
          {cardDetails.expiryMonth}/{cardDetails.expiryYear}
        </Text>
      </View>
      <View className={"flex flex-row items-center justify-between flex-wrap"}>
        <Text className={"text-body-400 text-grey-600"}>Stripe Payment ID</Text>
        <Text className={"text-body-400 text-grey-900"}>{id}</Text>
      </View>
    </View>
  );
};
