import {
  AttendanceStatsDto,
  CompanyTrialSettingsDto,
  LessonAdjustmentReason,
  LessonAdjustmentType,
  LessonDto,
  LessonStatus,
  SortSchema,
  TrialDto,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { UseApi } from "../components/apiQueryFactory";

export interface LessonRecord {
  lesson: LessonDto;
  isOnHoliday: boolean;
  isCancelled: boolean;
  isAttendanceCompleted: boolean;
  trialsInformation: string | null;
}

export const useClassLessonsData = ({
  apiClient,
  classId,
  account,
}: {
  apiClient: UseApi;
  classId: string;
  account: UserAccountDto | null;
}): {
  records: LessonRecord[];
  isLoading: boolean;
} => {
  const { data: lessons } = apiClient("listLesson", {
    where: {
      classId: {
        in: [classId],
      },
    },
  });

  const { data: attendanceStats } = apiClient("statsAttendanceStats", {
    courseId: classId,
  });

  const { data: trials } = apiClient("listTrial", {
    selectAll: true,
    where: {
      classId: {
        equals: classId,
      },
    },
    sort: { trialAt: SortSchema.Desc, time: SortSchema.Desc },
  });

  const isLoading = !lessons || !attendanceStats || !trials;
  const records = isLoading
    ? []
    : prepareLessonsRecords({
        lessons: excludeDateChangedLessons(lessons),
        attendanceStats,
        trials: trials.data,
        trialSettings: account?.company?.settings?.trial,
      });

  return {
    isLoading,
    records,
  };
};

const excludeDateChangedLessons = (lessons: LessonDto[]) =>
  lessons.filter(lesson => {
    const adjustments = lesson.adjustments;
    const isDateChanged = adjustments.some(
      adjustment =>
        adjustment.type === LessonAdjustmentType.Deduction &&
        adjustment.reason === LessonAdjustmentReason.ChangedDate,
    );

    return !isDateChanged;
  });

const prepareLessonsRecords = ({
  lessons,
  attendanceStats,
  trials,
  trialSettings,
}: {
  lessons: LessonDto[];
  attendanceStats: AttendanceStatsDto[];
  trials: TrialDto[];
  trialSettings?: CompanyTrialSettingsDto;
}): LessonRecord[] => {
  return lessons.map(lesson => {
    const lessonAttendanceStats = attendanceStats.find(
      attendanceStats =>
        attendanceStats.classId === lesson.classId &&
        attendanceStats.date === lesson.date &&
        attendanceStats.time === lesson.startTime,
    );

    const lessonTrials = trials.filter(
      trial => trial.trialAt === lesson.date && trial.time === lesson.startTime,
    );

    const cancellationReasons = [
      LessonAdjustmentReason.CancelledLesson,
      LessonAdjustmentReason.CancelledClass,
    ];

    return {
      lesson,
      isOnHoliday: lesson.holidays.length > 0,
      isCancelled:
        lesson.status == LessonStatus.Inactive &&
        lesson.adjustments.some(adjustment =>
          cancellationReasons.includes(adjustment.reason),
        ),
      trialsInformation: getLessonTrialInformation({
        trialSettings,
        trialsQuantity: lessonTrials.length,
      }),
      isAttendanceCompleted: lessonAttendanceStats?.completePercent === 100,
    };
  });
};

const getLessonTrialInformation = ({
  trialSettings,
  trialsQuantity,
}: {
  trialSettings?: CompanyTrialSettingsDto;
  trialsQuantity: number;
}) => {
  if (!trialSettings || !trialSettings.enableOnPortal) {
    return null;
  }

  if (
    trialSettings.limitedCapacity &&
    trialSettings.maxCapacityPerLesson === trialsQuantity
  ) {
    return "No trial spaces";
  }

  return "Trial spaces available";
};
