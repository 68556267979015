import { UseQueryResult } from "@tanstack/react-query";

export interface ClientSideEntity {
  isArchivable: () => boolean;
  isArchived: () => boolean;
  isDeletable: () => boolean;
  isEditable: () => boolean;
  isRestorable: () => boolean;
  isDefined: () => boolean;
}
export type ClientSideEntityMethod = keyof ClientSideEntity;

export type ClientSideEntityFactory = <T, E>(
  entityDto?: E,
) => ClientSideEntity & T;

export interface EmptyEntity extends ClientSideEntity {
  type: "empty";
  isDefined: () => false;
}

export interface DefinedEntity<T> extends ClientSideEntity {
  type: "defined";
  isDefined: () => true;
  props: T;
}

export interface MappedEntityData<T> {
  entity: EmptyEntity | T;
}

export type UseEntityResponse<T, E> = Omit<UseQueryResult<T>, "data"> &
  MappedEntityData<E>;

export function isDefinedEntity<T>(
  entity: DefinedEntity<T> | EmptyEntity,
): entity is DefinedEntity<T> {
  return entity.type === "defined";
}
