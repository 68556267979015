import { ReactNode } from "react";

type Alignment = keyof typeof alignmentClass;

interface AlignedCellProps {
  children: ReactNode;
  align?: Alignment;
}

const alignmentClass = {
  left: "justify-start",
  center: "justify-center",
  right: "justify-end",
};

export const AlignedCell = ({ align = "left", children }: AlignedCellProps) => {
  return (
    <div className={`flex items-center ${alignmentClass[align]}`}>
      {children}
    </div>
  );
};
