import { RoomDto } from "@justraviga/classmanager-sdk";

import { ActionMenu, ActionMenuProps } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { useRoomActions } from "@/modules/company/locations/useRoomActions";

export const RoomCard = ({ room }: { room: RoomDto }) => {
  const roomActions = useRoomActions();

  const menuItems: ActionMenuProps["items"] = [
    {
      title: "Edit",
      onClick: () =>
        roomActions.showUpdateForm(room, { locationId: room.locationId }),
    },
    {
      title: "Delete",
      onClick: () => roomActions.deleteOne(room),
      destructive: true,
    },
  ];

  return (
    <div
      className={"flex items-center justify-between rounded bg-grey-200 p-4"}>
      <span className={"truncate text-body-600"}>{room.name}</span>
      <ActionMenu
        trigger={
          <IconButton icon={"ellipsisHorizontal"} variant={"standard"} />
        }
        items={menuItems}
      />
    </div>
  );
};
