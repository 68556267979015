import React, { ReactNode } from "react";

import * as dialogPrimitive from "@radix-ui/react-dialog";
import type { VariantProps } from "class-variance-authority";

import { cn } from "shared/lib";

import { baseVariants } from "@/modules/common/overlays/dialog/baseVariant";
import { Overlay } from "@/modules/common/overlays/dialog/Overlay";

const Root = dialogPrimitive.Root;
const Close = dialogPrimitive.Close;

const Header = ({ title }: { title?: string | ReactNode }) => {
  if (!title) {
    return null;
  }

  if (typeof title == "string") {
    return (
      <p
        className={"grow text-heading5-600 text-grey-900"}
        data-sheet-title={title}>
        {title}
      </p>
    );
  }

  return <div className={"grow"}>{title}</div>;
};

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof dialogPrimitive.Content>,
    VariantProps<typeof baseVariants> {
  dialog?: boolean;
}

const Content = React.forwardRef<
  React.ElementRef<typeof dialogPrimitive.Content>,
  DialogContentProps
>(({ side, dialog, className, children, ...props }, ref) => (
  <dialogPrimitive.Portal>
    {side !== "top" && <Overlay />}
    <dialogPrimitive.Content
      ref={ref}
      className={cn(baseVariants({ side, dialog }), className)}
      {...props}>
      {children}
    </dialogPrimitive.Content>
  </dialogPrimitive.Portal>
));

export { Root, Close, Content, Header };
