import { BillDto } from "@justraviga/classmanager-sdk";

import { TransactionDetails } from "./TransactionDetails";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../../../interfaces";

export const Transaction = ({
  transaction,
  HorizontalSeparator,
}: {
  transaction: BillDto;
  HorizontalSeparator: HorizontalSeparatorComponent;
}) => {
  const { View, Text } = useGenericComponents();

  const hasTuition = transaction.items.some(item => item.type === "tuition");
  // const hasRegistrationFee = transaction.items.some(
  //   item => item.type === "registration-fee",
  // );

  return (
    <View key={transaction.description} className={"w-full space-y-2"}>
      <Text className={"overflow-hidden text-heading6-600 text-grey-900"}>
        {transaction.description}
      </Text>
      {/*hasTuition && hasRegistrationFee ? (
        <Text className={"space-y-2 text-body-400 text-grey-600"}>
          You are paying the registration fee and the first monthly installment.
        </Text>
      ) :*/}
      {hasTuition ? (
        <Text className={"space-y-2 text-body-400 text-grey-600"}>
          You are paying the first monthly installment.
        </Text>
      ) : null}
      <TransactionDetails
        transaction={transaction}
        HorizontalSeparator={HorizontalSeparator}
      />
    </View>
  );
};
