import { useEffect } from "react";

import { useLogout } from "shared/lib";

import { Router } from "@/routing/router";

export const Logout = () => {
  const logout = useLogout();
  useEffect(() => {
    logout().then(() => {
      Router.push("Login");
    });
  }, [logout]);

  return null;
};
