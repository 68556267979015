import { useState } from "react";

import { uniqueValuesForKey } from "../collectionUtils";
import { UseApi } from "../components/apiQueryFactory";
import { TabsProps } from "../components/interfaces";
import { DetailedEnrolment, EnrolmentFilter } from "../enrolmentUtils";
import { getEnrolmentEndDate, getEnrolmentStartDate } from "../enrolmentUtils";
import { formatDateTime } from "../intlFormatter";

const now = formatDateTime(new Date(), "isoDate");

export const useStudentEnrolmentsData = ({
  studentId,
  useApi,
}: {
  studentId: string;
  useApi: UseApi;
}) => {
  const [currentFilter, setCurrentFilter] =
    useState<EnrolmentFilter>("current");

  const { data: student, isLoading: isStudentLoading } = useApi("getStudent", {
    id: studentId,
  });

  const { data: enrolments, isLoading: isEnrolmentLoading } = useApi(
    "listEnrolment",
    {
      where: {
        studentId: { equals: studentId },
      },
      selectAll: true,
    },
  );

  const courseIds = uniqueValuesForKey(
    "classId",
    enrolments?.data.map(aggEnrolmentDto => aggEnrolmentDto.enrolment) || [],
  );

  const { data: courses, isLoading: isCoursesLoading } = useApi(
    "listCourse",
    {
      where: {
        id: {
          in: courseIds,
        },
      },
    },
    {
      enabled: courseIds.length > 0,
    },
  );

  const seasonIds = uniqueValuesForKey(
    "seasonId",
    courses?.data.map(c => c.entity) || [],
  );

  const { data: seasons, isLoading: isSeasonLoading } = useApi(
    "listSeason",
    {
      where: {
        id: {
          in: seasonIds,
        },
      },
      selectAll: true,
    },
    {
      enabled: seasonIds.length > 0,
    },
  );

  const isLoading =
    isStudentLoading ||
    isEnrolmentLoading ||
    isCoursesLoading ||
    isSeasonLoading;

  const counts = {
    current: 0,
    upcoming: 0,
    past: 0,
  };

  if (!isLoading && enrolments && courses && seasons && student) {
    const detailedEnrolments: DetailedEnrolment[] = enrolments.data.map(e => {
      const aggClass = courses.data.find(
        c => c.entity.id === e.enrolment.classId,
      )!;
      const season = seasons.data.find(
        s => s.id === aggClass?.entity.seasonId,
      )!;
      return {
        ...e.enrolment,
        enrolmentAdjustments: e.adjustments,
        class: aggClass,
        season: season,
        student: student,
      };
    });

    counts.current =
      detailedEnrolments?.filter(
        e => getEnrolmentStartDate(e) <= now && getEnrolmentEndDate(e) >= now,
      ).length ?? 0;
    counts.upcoming =
      detailedEnrolments?.filter(e => getEnrolmentStartDate(e) > now).length ??
      0;
    counts.past =
      detailedEnrolments?.filter(e => getEnrolmentEndDate(e) < now).length ?? 0;
  }

  const tabItems: TabsProps["items"] = [
    {
      label: "Current",
      chip: {
        label: counts.current.toString(),
        variant: "primary",
      },
      onClick: () => setCurrentFilter("current"),
    },
    {
      label: "Upcoming",
      chip: {
        label: counts.upcoming.toString(),
        variant: "success",
      },
      onClick: () => setCurrentFilter("upcoming"),
    },
    {
      label: "Past",
      chip: {
        label: counts.past.toString(),
        variant: "secondary",
      },
      onClick: () => setCurrentFilter("past"),
    },
  ];

  return {
    student,
    currentFilter,
    tabItems,
    isLoading,
  };
};
