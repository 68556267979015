import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { ActionMenuDefaultItems } from "@/modules/common/ui/actionMenu/desktop/ActionMenuDefaultItems";
import { ActionMenuDestructiveItems } from "@/modules/common/ui/actionMenu/desktop/ActionMenuDestructiveItems";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const ActionMenuDesktopItems = ({
  items,
}: {
  items: ActionMenuItemProps[];
}) => {
  const defaultItems = items.filter(i => !i.destructive);
  const destructiveItems = items.filter(i => i.destructive);

  return (
    <>
      <ActionMenuDefaultItems items={defaultItems} />

      {defaultItems.length > 0 && destructiveItems.length > 0 && (
        <HorizontalSeparator spacing={1} />
      )}

      {destructiveItems.length > 0 && (
        <ActionMenuDestructiveItems items={destructiveItems} />
      )}
    </>
  );
};
