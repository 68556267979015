import React from "react";

import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  getCoreRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";

import {
  DatatableContentState,
  DatatablePaginatedData,
  DatatableRow,
  Dto,
  transformDataIntoPaginatedData,
  useDatatable,
} from "shared/lib";

import { ErrorContentPlaceholder } from "@/modules/common/datatable/content/ErrorContentPlaceholder";
import { NoContentPlaceholder } from "@/modules/common/datatable/content/NoContentPlaceholder";
import { WebFooter } from "@/modules/common/datatable/content/web/WebFooter";
import { WebHeader } from "@/modules/common/datatable/content/web/WebHeader";
import { WebTableBody } from "@/modules/common/datatable/content/web/WebTableBody";
import { WebTableHeader } from "@/modules/common/datatable/content/web/WebTableHeader";
import { DatatableSelectAllRow } from "@/modules/common/datatable/rows/DatatableSelectAllRow";

export const WebDatatableContainer = <ModelDto extends Dto>({
  columns,
}: {
  columns: ColumnDef<DatatableRow<ModelDto>>[];
}) => {
  const {
    query,
    filters,
    sorting,
    setSorting,
    setQuery,
    globalFilter,
    setSearch,
    dataLoadedCount,
    datatable,
    pageIndex,
    pageSize,
    setPagination,
  } = useDatatable();

  // SDK caches the query, so we need to increment the dataLoadedCount to force a refetch

  const { isPending, error, data } = useQuery({
    queryKey: [
      datatable.id,
      "loadedCount-" + dataLoadedCount,
      pageSize,
      pageIndex,
      globalFilter,
      sorting,
      filters,
    ],
    queryFn: async () => {
      query.page = pageIndex + 1;
      query.pageSize = pageSize;
      query.search = globalFilter;
      query.sort = sorting;
      query.filters = filters;
      setQuery(query);

      const rawData = await datatable.fetchData(query);

      try {
        return transformDataIntoPaginatedData({
          rawData,
          onClick: datatable.rowActions?.click,
        });
      } catch (e) {
        console.log({ e });
      }
    },
  });

  const defaultData = React.useMemo(() => [{ item: {}, actions: [] }], []);

  const pagination = {
    pageIndex,
    pageSize,
  };

  const table: Table<DatatableRow<ModelDto>> = useReactTable<
    DatatableRow<ModelDto>
  >({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    data: data?.data ?? defaultData,
    columns,
    defaultColumn: {
      size: undefined,
    },
    state: {
      pagination,
      globalFilter,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: !!datatable?.groupActions,
    enableRowSelection: !!datatable?.groupActions,
    onGlobalFilterChange: setSearch,

    // START Pagination
    manualPagination: true,
    pageCount: data?.pagination?.totalPages,
    onPaginationChange: setPagination,
    // END Pagination

    manualSorting: true,
    onSortingChange: setSorting,
    getRowId: <T extends Dto>(row: DatatableRow<T>) => row.item.id,
    enableFilters: false,
  });

  const getContentState = () => {
    if (isPending) {
      return DatatableContentState.Loading;
    }

    if (error) {
      console.error({ error });
      return DatatableContentState.Error;
    }

    if (data?.data?.length === 0) {
      if (query.isEmpty()) {
        return DatatableContentState.NoContent;
      }

      return DatatableContentState.NoResults;
    }

    return DatatableContentState.Loaded;
  };

  const contentState = getContentState();

  if (contentState == DatatableContentState.Error) {
    return (
      <>
        <ErrorContentPlaceholder />
      </>
    );
  }

  const paginatedData = data as unknown as DatatablePaginatedData<
    DatatableRow<ModelDto>
  >;

  const TableBody = () => {
    const { modelSelection } = useDatatable();

    if (contentState === DatatableContentState.NoContent) {
      return <NoContentPlaceholder />;
    }

    const showSelectAllBanner =
      contentState === DatatableContentState.Loaded &&
      modelSelection.hasAll(table.getCenterRows().map(row => row.id));

    return (
      <>
        <section>
          <table className={"h-full w-full table-fixed"}>
            <thead>
              <WebTableHeader
                table={table}
                contentState={contentState}
                showBorder={!showSelectAllBanner}
              />
              {showSelectAllBanner && (
                <DatatableSelectAllRow
                  table={table}
                  totalCount={data?.pagination.total}
                />
              )}
            </thead>

            <tbody>
              <WebTableBody
                table={table}
                columns={columns}
                contentState={contentState}
              />
            </tbody>
          </table>
        </section>

        <WebFooter
          contentState={contentState}
          table={table}
          data={paginatedData}
        />
      </>
    );
  };

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <WebHeader contentState={contentState} />
        <TableBody />
      </div>
    </>
  );
};
