import { ReactNode } from "react";

import { Email } from "@/modules/company/common/details/profile/iconItems/Email";
import { Person } from "@/modules/company/common/details/profile/iconItems/Person";
import { Phone } from "@/modules/company/common/details/profile/iconItems/Phone";

export const DetailProfileContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={"flex min-w-0 flex-col space-y-4"}>{children}</div>;
};

export const DetailProfileAvatarContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={"flex flex-row space-x-4"}>{children}</div>;
};

export const DetailProfileContactList = ({
  email,
  phone,
  person,
}: {
  email?: string | null;
  phone?: string | null;
  person?: {
    firstname: string;
    lastname: string | null | undefined;
  } | null;
}) => {
  return (
    <div
      className={
        "flex flex-col space-y-4 md:flex-row md:space-x-8 md:space-y-0"
      }>
      {person && <Person person={person} />}
      <Email email={email} />
      <Phone phone={phone} />
    </div>
  );
};
