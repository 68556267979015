import { ErrorResponse } from "@justraviga/classmanager-sdk";
import { UseFormReturn } from "react-hook-form";

/**
 * Automatically display validation errors from an API response in a form
 */
export const displayApiValidationErrors =
  (formHandlers: UseFormReturn) => (response: ErrorResponse | unknown) => {
    // Make sure this response contains user-facing errors
    if (
      !response ||
      typeof response !== "object" ||
      !("statusCode" in response) ||
      response.statusCode !== 422
    ) {
      // This could be something like a `RequiredError` from the SDK itself, so rethrow the caught error, so it can be
      // seen by developers or end up in logging.
      throw response;
    }

    // Show field-level errors
    if (
      "validationErrors" in response &&
      response.validationErrors &&
      typeof response.validationErrors === "object"
    ) {
      Object.entries(response.validationErrors).forEach(([key, value]) => {
        //if key contains dot, leave only last part
        const fieldKey = key.includes(".") ? key.split(".").pop()! : key;

        // Check if the key is in the form
        if (Object.keys(formHandlers.getValues()).includes(fieldKey)) {
          formHandlers.setError(fieldKey, {
            type: "custom",
            message: value,
          });
        }
      });
    }
  };
