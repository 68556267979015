import { formatDate, LessonRecord } from "shared/lib";

import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { useLessonActionMenuItems } from "@/modules/company/classPlanner/lessons/menus/useLessonActionMenuItems";

export const ClassLessonActionMenu = ({ record }: { record: LessonRecord }) => {
  const title = `${formatDate(record.lesson.date, "weekdayShortDayMonthYear")} ${record.isCancelled ? "(cancelled)" : ""}`;
  const { actions } = useLessonActionMenuItems({
    lesson: record.lesson,
    isCancelled: record.isCancelled,
  });
  return (
    <ActionMenu
      width={"w-80"}
      showActionsAsBottomSheetOnMobile={true}
      trigger={
        <IconButton
          variant="standard"
          icon={"ellipsisHorizontal"}
          size="lg"
          aria-label="Toggle menu"
        />
      }
      header={<h3 className={"text-heading6-600"}>{title}</h3>}
      items={actions}
    />
  );
};
