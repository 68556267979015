import { DiscountSchemeValueDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Model = DiscountSchemeValueDto;

export const useSharedDiscountSchemeTierActions = (
  defaultActions: DefaultEntityActions<Model>,
  api: Api,
) => {
  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.discountSchemeValues.deleteDiscountSchemeValue({ id: entity.id }),
      ),
  };
};
