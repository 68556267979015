import React, { createContext, useContext, useState } from "react";

export type FormActionsType = {
  isLoading: boolean;
  setIsLoading: (submit: FormActionsType["isLoading"]) => void;
  submit: () => void;
  setSubmit: (submit: FormActionsType["submit"]) => void;
  shouldCloseOnSuccess: () => boolean;
  setShouldCloseOnSuccess: (shouldClose: boolean) => void;
  shouldShowFooter: boolean;
  setShouldShowFooter: (shouldShowFooter: boolean) => void;
  allowCreateAdditional: boolean;
  setAllowCreateAdditional: (allowCreateAdditional: boolean) => void;
  createButtonText: string;
  setCreateButtonText: (submitButtonText: string) => void;
  isSubmitDisabled: boolean;
  setSubmitDisabled: (val: boolean) => void;
  resetAfterSubmit: boolean;
  setResetAfterSubmit: (val: boolean) => void;
};

const FormActions = createContext<FormActionsType>({
  isLoading: false,
  setIsLoading: () => {},
  submit: () => {},
  setSubmit: () => {},
  shouldCloseOnSuccess: () => true,
  setShouldCloseOnSuccess: () => {},
  shouldShowFooter: true,
  setShouldShowFooter: () => {},
  allowCreateAdditional: true,
  setAllowCreateAdditional: () => {},
  createButtonText: "Create",
  setCreateButtonText: () => {},
  isSubmitDisabled: false,
  setSubmitDisabled: () => {},
  resetAfterSubmit: true,
  setResetAfterSubmit: () => {},
});

// For some reason, if submit was held in React state, it caused it to be
// called automatically from somewhere.
let submit = () => {};

let shouldCloseAfterSuccess = true;

/**
 * This provider allows a simple method of locating forms and their submit
 * buttons in separate areas of the app with 2-way communication.
 * Buttons need to tell the form to submit itself, forms need to tell buttons
 * whether anything is currently loading.
 */
export const FormActionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldShowFooter, setShouldShowFooter] = useState<boolean>(true);
  const [allowCreateAdditional, setAllowCreateAdditional] =
    useState<boolean>(true);
  const [submitButtonText, setSubmitButtonText] = useState<string>("Create");
  //disable submit button state
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [resetAfterSubmit, setResetAfterSubmit] = useState<boolean>(true);

  const defaultValue: FormActionsType = {
    isLoading,
    setIsLoading,
    submit: () => {
      submit();
    },
    setSubmit: f => (submit = f),
    shouldCloseOnSuccess: () => shouldCloseAfterSuccess,
    setShouldCloseOnSuccess: shouldClose => {
      shouldCloseAfterSuccess = shouldClose;
    },
    shouldShowFooter,
    setShouldShowFooter,
    allowCreateAdditional,
    setAllowCreateAdditional,
    createButtonText: submitButtonText,
    setCreateButtonText: setSubmitButtonText,
    isSubmitDisabled,
    setSubmitDisabled,
    resetAfterSubmit,
    setResetAfterSubmit,
  };

  return (
    <FormActions.Provider value={defaultValue}>{children}</FormActions.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useFormActions = () => {
  return useContext(FormActions);
};
