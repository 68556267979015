import { isDefinedEntity, useClassEntity } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { Breadcrumb } from "@/modules/common/nav/Breadcrumbs";
import { Router } from "@/routing/router";

export const useClassDetailsPageData = (id: string) => {
  const { entity: classEntity, isLoading } = useClassEntity(id);

  // todo useSeasonEntity
  const { data: season } = useApi(
    "getSeason",
    { id: isDefinedEntity(classEntity) ? classEntity.class.seasonId : "" },
    { enabled: isDefinedEntity(classEntity) },
  );

  const breakpoints = useBreakpoint();

  const crumbs: Breadcrumb[] = [
    {
      text: "Seasons",
      onPress: () => Router.push("SeasonList"),
    },
  ];

  if (isDefinedEntity(classEntity)) {
    crumbs.push(
      {
        text: season?.name ?? "",
        onPress: () => {
          Router.push("SeasonDetails", { id: classEntity.class.seasonId });
        },
      },
      {
        text: classEntity.class.name,
      },
    );
  }

  return {
    isLoading,
    classEntity,
    season,
    isDesktop: breakpoints.md,
    crumbs,
  };
};
