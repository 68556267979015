import { DayOfWeek } from "./components/courseSearch/courseSearchContext";

export const ageLabel = (age: number) => {
  if (age === 1) {
    return "Under 2";
  } else if (age === 19) {
    return "Over 18";
  } else {
    return `Age ${age}`;
  }
};

export const dayOfWeekLabels: Record<DayOfWeek, string> = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};
export const dayLabel = (day: DayOfWeek) => {
  return dayOfWeekLabels[day];
};
