import { WaiverEntity } from "shared/lib";

import { Chip } from "@/modules/common/ui/chip/Chip";

interface WaiverCardChipProps {
  waiver: WaiverEntity;
}

export const WaiverChip = ({ waiver }: WaiverCardChipProps) => {
  if (waiver.requiresUpdate()) {
    return <Chip rounded="md" label={`Update this`} variant={"primary"} />;
  }

  return (
    <Chip
      rounded="md"
      label={`Last updated: ${waiver.lastUpdatedDate()}`}
      variant={"neutral"}
    />
  );
};
