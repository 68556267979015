import { AggregateClassDto } from "@justraviga/classmanager-sdk";

import { ClassEntity, classEntityFactory } from "./class";
import { getPlatformFunctions } from "../../platformSpecific";
import { useEntity } from "../useEntity";

export function useClassEntity(id: string) {
  const { useApi } = getPlatformFunctions();

  return useEntity<AggregateClassDto, ClassEntity>(
    classEntityFactory,
    useApi("getCourse", { id }),
  );
}
