import { z } from "zod";

/**
 * This allows us to override Zod error messages at a global level.
 * It doesn't prevent us from also overriding them at the schema level (i.e. on a specific-field basis).
 *
 * REMEMBER to update the corresponding test when making changes!
 */
export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (
      ["string", "number"].includes(issue.expected) &&
      issue.received === "null"
    ) {
      return { message: "Required" };
    }
    if (issue.expected.includes(`|`) && issue.received === "null") {
      return { message: "Required" };
    }
  }
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.type === "string") {
      return { message: `Must be at least ${issue.minimum} characters` };
    }
  }
  return { message: ctx.defaultError };
};
