import { useState } from "react";

import { cn, colors, IconName } from "shared/lib";

import { useGenericComponents } from "../GenericComponentsProvider";

export type ChipVariant =
  | "primary"
  | "secondary"
  | "outline"
  | "info"
  | "success"
  | "neutral"
  | "warning"
  | "danger";
export type ChipProps = {
  className?: string;
  disabled?: boolean;
  label?: string;
  leftIcon?: IconName;
  rightIcon?: IconName;
  rightIconAction?: () => void;
  rounded?: "sm" | "md";
  size?: "default" | "sm";
  variant?: ChipVariant;
};
export const Chip = ({
  className = "",
  disabled,
  variant = "primary",
  rounded = "md",
  size = "default",
  label,
  leftIcon,
  rightIcon,
  rightIconAction,
}: ChipProps) => {
  const { View, Text, Icon, Pressable } = useGenericComponents();
  const [hovered, setHovered] = useState(false);

  const {
    background: backgroundClass,
    text: textClass,
    icon: iconColor,
    iconHover: iconHoverColor,
  } = variantConfig[variant];

  const chipClass = cn(
    "relative text-sm flex flex-row align-center items-center space-x-2 max-h-7",
    backgroundClass,
    textClass,
    { "px-3 py-1 h-7": size === "default" },
    { "px-2 py-0.5 h-5": size === "sm" },
    rounded === "sm" ? "rounded" : "rounded-full",
    className,
    { "opacity-20": disabled },
  );

  return (
    <View className="bg-white rounded-full">
      <View className={chipClass}>
        {leftIcon && (
          <Icon
            className="h-4 w-auto"
            size={size === "sm" ? 16 : 20}
            name={leftIcon}
            color={iconColor}
          />
        )}
        {label && (
          <Text className={cn(textClass, "whitespace-nowrap")}>{label}</Text>
        )}
        {rightIcon ? (
          rightIconAction ? (
            <Pressable
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={() => rightIconAction?.()}
              className={
                "rounded-full w-6 h-6 flex items-center justify-center relative -right-1"
              }>
              <Icon
                className="h-4 w-auto"
                size={size === "sm" ? 16 : 20}
                name={rightIcon}
                color={hovered ? iconHoverColor : iconColor}
              />
            </Pressable>
          ) : (
            <View
              className={
                "rounded-full w-6 h-6 flex items-center justify-center relative -right-1"
              }>
              <Icon
                className="h-4 w-auto"
                size={size === "sm" ? 16 : 20}
                name={rightIcon}
                color={iconColor}
              />
            </View>
          )
        ) : null}
      </View>
    </View>
  );
};

const variantConfig: Record<
  ChipVariant,
  { background: string; text: string; icon: string; iconHover: string }
> = {
  info: {
    background: "bg-blue-200 border border-transparent",
    text: "text-blue-800",
    icon: colors.blue[800],
    iconHover: colors.blue[500],
  },
  success: {
    background: "bg-green-200 border border-transparent",
    text: "text-green-800",
    icon: colors.green[800],
    iconHover: colors.green[500],
  },
  warning: {
    background: "bg-yellow-200 border border-transparent",
    text: "text-yellow-800",
    icon: colors.yellow[800],
    iconHover: colors.yellow[500],
  },
  danger: {
    background: "bg-red-200 border border-transparent",
    text: "text-red-800",
    icon: colors.red[800],
    iconHover: colors.red[500],
  },
  neutral: {
    background: "bg-grey-200 border border-transparent",
    text: "text-grey-800",
    icon: colors.grey[800],
    iconHover: colors.grey[500],
  },
  primary: {
    background: "bg-grey-900 border border-transparent",
    text: "text-grey-200",
    icon: colors.grey[200],
    iconHover: colors.grey[500],
  },
  secondary: {
    background: "bg-grey-200 border border-transparent",
    text: "text-grey-900",
    icon: colors.grey[900],
    iconHover: colors.grey[600],
  },
  outline: {
    background: "bg-white border border-grey-400",
    text: "text-grey-900",
    icon: colors.grey[900],
    iconHover: colors.grey[600],
  },
};
