import { useAuthenticatedAccountEntity, useAuthState } from "shared/components";
import { getEntityInitials } from "shared/lib";

import { Avatar } from "@/modules/common/ui/avatar/Avatar";

export const ProfileAvatar = () => {
  const { user } = useAuthState();
  const entity = useAuthenticatedAccountEntity();

  return (
    <Avatar
      name={getEntityInitials(entity ?? user)}
      src={entity?.profilePicture ?? ""}
      size="md"
    />
  );
};
