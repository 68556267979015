import {
  UpdateCompanySettingsRequest,
  UserAccountDto,
  WeekDay,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";

type UpdateStartDayOfWeekSchema =
  Required<UpdateCompanySettingsRequest>["general"];

export const useStartDayOfWeekFormDefinition = () => {
  return new FormDefinitionBuilder<UpdateStartDayOfWeekSchema>()
    .select("startDayOfWeek", {
      label: "Select day",
      data: [
        { value: WeekDay.Monday, label: "Monday" },
        { value: WeekDay.Saturday, label: "Saturday" },
        { value: WeekDay.Sunday, label: "Sunday" },
      ],
      selectedOnTop: false,
      required: true,
    })
    .getDefinition();
};

export const makeStartDayOfWeekFormRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  (formData: UpdateStartDayOfWeekSchema) => {
    return api.companies
      .updateCompanySettings({
        updateCompanySettingsRequest: {
          general: formData,
        },
      })
      .then(company => {
        setAccount({
          ...account,
          company,
        });
        return company;
      });
  };
