import { ClassColor } from "@justraviga/classmanager-sdk";

import { colorPaletteColors } from "shared/lib";

export const ColorIndicator = ({ color }: { color: ClassColor }) => {
  return (
    <div
      className="h-6 w-6 rounded border border-grey-900"
      style={{ backgroundColor: colorPaletteColors[color] }}
    />
  );
};
