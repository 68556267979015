import { UserDto } from "@justraviga/classmanager-sdk";

import { useAuthState, useUserDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Router } from "@/routing/router";

const UserList = () => {
  const { setUser } = useAuthState();

  const onImpersonateUserSuccess = (user: UserDto, token: string) => {
    setUser(user, token);
    Router.push("Accounts");
  };

  const { datatable } = useUserDatatable({
    Datatable,
    api,
    onImpersonateUserSuccess,
  });

  return <div>{datatable}</div>;
};

export const AdminHome = () => {
  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title={"Admin Home"} />
      </AdminTitleBar>
      <AdminContent>
        <UserList />
      </AdminContent>
    </AdminLayout>
  );
};
