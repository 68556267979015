import {
  LocationDto,
  RoomDto,
  StaffDto,
  WeekDay,
} from "@justraviga/classmanager-sdk";

import { FilterFormDefinitionBuilder, getFullName } from "shared/lib";
import { DatatableFilterOperator } from "shared/lib";
import { enumLabel } from "shared/lib";

export interface ClassScheduleFormSchema {
  dayOfWeek: WeekDay;
  staffId: string;
  locationId: string;
  showArchived: boolean;
  hideUpcoming: boolean;
}

export interface ClassScheduleParameter {
  dayOfWeek?: WeekDay;
  staffId?: string;
  roomId?: string;
  showArchived?: boolean;
  hideUpcoming?: boolean;
}

export const useClassScheduleFilterForm = ({
  classStaffMembers,
  classLocations,
  classRooms,
}: {
  classStaffMembers: StaffDto[];
  classLocations: LocationDto[];
  classRooms: RoomDto[];
}) =>
  new FilterFormDefinitionBuilder<
    ClassScheduleFormSchema,
    ClassScheduleParameter
  >()
    .multiSelect(
      "dayOfWeek",
      {
        label: "Day of the week",
        data: Object.values(WeekDay).map(value => ({
          label: enumLabel("weekDays", value),
          value,
        })),
        selectedOnTop: false,
      },
      {
        filterField: "dayOfWeek",
        operator: DatatableFilterOperator.In,
      },
    )
    .select(
      "staffId",
      {
        label: "Teacher/instructor",
        data: classStaffMembers.map(value => {
          return {
            label: getFullName(value),
            value: value.id,
          };
        }),
        localSearch: true,
      },
      {
        filterField: "staffId",
        operator: DatatableFilterOperator.Equals,
      },
    )
    .select(
      "locationId",
      {
        label: "Location",
        data: classLocations.map(value => {
          return {
            label: value.name,
            value: value.id,
          };
        }),
      },
      {
        filterField: "roomId",
        operator: DatatableFilterOperator.In,
        transformer: value => {
          const rooms = classRooms
            .filter(room => room.locationId === value)
            .map(room => room.id);
          return rooms.length > 0 ? rooms : undefined;
        },
      },
    )
    .withArchiveFilter()
    .getDefinition();
