import * as React from "react";
import { forwardRef } from "react";

import {
  cn,
  colors,
  getAccessibleColorClass,
  getAccessibleTextColor,
  WebButtonProps,
} from "shared/lib";

import { buttonVariants } from "@/modules/common/ui/button/buttonVariants";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { LoadingSpinnerGrey } from "@/modules/common/ui/LoadingSpinnerGrey";
import { LoadingSpinnerLight } from "@/modules/common/ui/LoadingSpinnerLight";

const iconVariants = {
  primary: "",
  secondary: "",
  tertiary: "",
  tertiaryLight: "",
  link: "",
  linkLight: "",
  subtle: "",
  destructive: "",
  "destructive-outline": "",
  "destructive-tertiary": "",
  "destructive-link": "",
  brand: "white",
  classmanager: "",
  success: "white",
};

interface StyledWebButtonProps extends WebButtonProps {
  color?: string;
  type?: "submit" | "button" | "reset";
}

export const Button = forwardRef<HTMLButtonElement, StyledWebButtonProps>(
  (
    {
      text,
      className,
      variant = "primary",
      size = "lg",
      leftIcon,
      rightIcon,
      disabled,
      isFullWidth = false,
      loading,
      children,
      link,
      ...props
    },
    ref,
  ) => {
    const loadingSpinnerVariants = {
      primary: <LoadingSpinnerLight />,
      secondary: <LoadingSpinnerDark />,
      tertiary: <LoadingSpinnerDark />,
      tertiaryLight: <LoadingSpinnerDark />,
      link: <LoadingSpinnerDark />,
      linkLight: <LoadingSpinnerDark />,
      subtle: <LoadingSpinnerDark />,
      destructive: <LoadingSpinnerLight />,
      "destructive-outline": <LoadingSpinnerGrey />,
      "destructive-tertiary": <LoadingSpinnerDark />,
      "destructive-link": <LoadingSpinnerDark />,
      brand:
        getAccessibleColorClass(colors.brand[800]) === "grey-900" ? (
          <LoadingSpinnerDark />
        ) : (
          <LoadingSpinnerLight />
        ),
      classmanager: <LoadingSpinnerLight />,
      success: <LoadingSpinnerLight />,
    };

    const loadingSpinner = loadingSpinnerVariants[
      variant!
    ] as React.JSX.Element;

    return (
      <button
        type="button"
        ref={ref}
        className={cn(
          buttonVariants({ variant, size, className, isFullWidth }),
        )}
        data-size={size}
        disabled={disabled}
        {...props}>
        {loading ? (
          <>{loadingSpinner}</>
        ) : (
          <>
            {leftIcon && (
              <Icon size={24} name={leftIcon} color={iconVariants[variant!]} />
            )}
            {link ? (
              <a href={link.href}>{link.text}</a>
            ) : (
              text && (
                <span
                  className={cn(
                    variant === "brand"
                      ? getAccessibleTextColor(colors.brand[800])
                      : "",
                    {
                      "max-w-xl truncate": !isFullWidth,
                    },
                  )}>
                  {text}
                </span>
              )
            )}
            {rightIcon && (
              <Icon name={rightIcon} size={16} color={iconVariants[variant!]} />
            )}
            {children}
          </>
        )}
      </button>
    );
  },
);
