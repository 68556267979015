import {
  AggregateClassDto,
  ListCourseRequest,
} from "@justraviga/classmanager-sdk";

import { ClassEntity, classEntityFactory } from "./class";
import { UseApi } from "../../components/apiQueryFactory";
import { mapDtoToEntity } from "../entityListUtils";

export function useClassList(useApi: UseApi, request: ListCourseRequest) {
  const { data: classes, isLoading } = useApi("listCourse", request);

  const mappedClasses = classes
    ? mapDtoToEntity<AggregateClassDto, ClassEntity>(
        classes,
        classEntityFactory,
      )
    : undefined;

  return {
    isLoading,
    classes: mappedClasses,
  };
}
