import { useState } from "react";

import {
  CourseSearchValues,
  courseSearchValuesToQueryParams,
  isMode,
  isStage,
  makeCourseSearchValues,
  useCatalogueContext,
} from "shared/components";
import { CourseWizardChecks } from "shared/components";

import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Router } from "@/routing/router";

import { Button } from "../common/ui/button/Button";

export const CourseFilterWizardPage = ({
  mode: modeParam,
  stage: stageParam,
}: {
  mode: string;
  stage?: string;
}) => {
  const { company } = useCatalogueContext();
  const [searchValues, setSearchValues] = useState<CourseSearchValues>(
    makeCourseSearchValues(),
  );
  const mode = isMode(modeParam) ? modeParam : "enrol";
  const stage = isStage(stageParam) ? stageParam : "location";

  const goToLandingPage = () =>
    Router.push("CompanyLandingPage", { slug: company.slug });

  const goToStage = (stage: "location" | "age", replace: boolean) => {
    if (replace) {
      Router.replace("CourseFilterWizard", {
        slug: company.slug,
        mode,
        stage,
      });
    } else {
      Router.push("CourseFilterWizard", {
        slug: company.slug,
        mode,
        stage,
      });
    }
  };

  const goToResults = (searchValues: CourseSearchValues, replace: boolean) => {
    setSearchValues(searchValues);
    if (replace) {
      Router.replace("Courses", {
        slug: company.slug,
        ...courseSearchValuesToQueryParams(searchValues),
        mode,
      });
    } else {
      Router.push("Courses", {
        slug: company.slug,
        ...courseSearchValuesToQueryParams(searchValues),
        mode,
      });
    }
  };

  return (
    <NonAuthLayout>
      <CourseWizardChecks
        Button={Button}
        IconButton={IconButton}
        goToLandingPage={goToLandingPage}
        goToResults={goToResults}
        goToStage={goToStage}
        mode={mode}
        stage={stage}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
      />
    </NonAuthLayout>
  );
};
