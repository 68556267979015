import { useDatatable } from "shared/lib";

import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";

export const DatatableGroupActions = () => {
  const { datatable, modelSelection, setSelectMode } = useDatatable();

  const actions = [...(datatable.groupActions?.additionalGroupActions ?? [])];
  if (datatable.groupActions?.archiveMany) {
    actions.push({
      title: "Archive",
      description: "Archive selected items",
      destructive: false,
      isDestructive: false,
      leftIcon: "archiveOutline",
      onClick: datatable.groupActions.archiveMany,
      permission: datatable.permissions?.archive,
    });
  }

  if (datatable.groupActions?.deleteMany) {
    actions.push({
      title: "Delete",
      description: "Delete selected items",
      destructive: true,
      isDestructive: true,
      leftIcon: "trashOutline",
      onClick: datatable.groupActions.deleteMany,
      permission: datatable.permissions?.delete,
    });
  }
  return (
    <>
      {datatable.groupActions && modelSelection.isNotEmpty() && (
        <ActionMenu
          header={`Selected ${typeof datatable.title === "string" ? datatable.title.toLowerCase() : "items"}`}
          trigger={
            <Button
              text={"Actions"}
              role="datatable-group-actions"
              rightIcon={"chevronDown"}
              variant={"secondary"}
              size={"sm"}
            />
          }
          items={actions.map(action => {
            return {
              title: action.title,
              destructive: action.destructive,
              description: action.description,
              leftIcon: action.leftIcon,
              variant: action.destructive ? "destructive" : "default",
              onClick: async () => {
                await action.onClick(modelSelection.getAll());
                modelSelection.clear();
                setSelectMode(false);
              },
              permission: action.permission,
            };
          })}
        />
      )}
    </>
  );
};
