import { AttendanceStatus } from "@justraviga/classmanager-sdk";

import { StudentRowStatus } from "shared/components";
import {
  AttendanceRecord,
  cn,
  displayStudentAge,
  getFullName,
  getInitials,
} from "shared/lib";

import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { getAttendanceRecordChips } from "@/modules/company/classPlanner/lessons/attendances/row/attendanceUtilities";
import { StudentRowActionMenu } from "@/modules/company/classPlanner/lessons/students/row/StudentRowActionMenu";
import { Router } from "@/routing/router";

export const StudentRow = ({
  record,
  isCancelled,
  isDesktop,
  status,
}: {
  record: AttendanceRecord;
  isCancelled: boolean;
  isDesktop: boolean;
  status: AttendanceStatus;
}) => {
  const chips = getAttendanceRecordChips(record);

  return (
    <div
      className={
        "flex cursor-pointer justify-between space-x-2 px-5 py-3 hover:bg-grey-100 sm:p-3"
      }
      onClick={() => Router.push("StudentDetails", { id: record.student.id })}>
      <div
        className={cn("flex items-center space-x-3 overflow-hidden", {
          "items-center": chips.length === 0,
          "opacity-60": isCancelled,
        })}>
        <div>
          <Avatar
            size="md"
            src={record.student.profilePicture ?? undefined}
            name={getInitials(record.student)}
          />
        </div>
        <div className={"flex flex-col space-y-1"}>
          <div className={"flex items-center space-x-3"}>
            <div className={"line-clamp-1 text-body-400 text-grey-900"}>
              {getFullName(record.student)}
            </div>

            <div className={"flex"}>
              {chips.length > 0 && (
                <div className={"flex gap-x-2"}>
                  {chips.map((chip, index) => (
                    <div key={`attendance-${record.student.id}-chips-${index}`}>
                      {chip}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={"text-label-400 text-grey-600"}>
            {displayStudentAge(record.student)}
          </div>
        </div>
      </div>

      <div className={"-mr-2 flex items-center space-x-2"}>
        <StudentRowStatus status={status} isDesktop={isDesktop} />
        <StudentRowActionMenu record={record} />
      </div>
    </div>
  );
};
