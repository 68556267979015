import { AccountRole } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { useAuthState, useFamilyStripeStatus } from "shared/components";
import { useBreakpoint, useLogout } from "shared/lib";

import { isInCatalogue } from "@/modules/auth/utils/isInCatalogue";
import { AccountMenuItem } from "@/modules/common/nav/AccountMenuItem";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { useAccountSelectionDialog } from "@/modules/company/switcher/accountSelectionDialogHooks";
import { Router } from "@/routing/router";

export const useUserAccountNavActionMenu = (): ActionMenuItemProps[] => {
  const { account } = useAuthState();
  const { protector } = useFamilyStripeStatus();
  const { setOpen: openAccountSelectionDialog } = useAccountSelectionDialog();
  const logout = useLogout();

  const breakpoints = useBreakpoint();

  const actionMenuItems: ActionMenuItemProps[] = [];

  const accountClickHandler = () => {
    return match(account?.role)
      .with(AccountRole.Staff, () => () => Router.push("CompanyAccount"))
      .with(AccountRole.Family, () => () => Router.push("CustomerAccount"))
      .with(AccountRole.Admin, () => () => Router.push("AdminAccount"))
      .with(undefined, () => () => {})
      .exhaustive();
  };

  const accountSwitcher = {
    onClick: () => openAccountSelectionDialog(true),
    title: "User",
    children: (
      <div className={"flex flex-row items-center gap-4"}>
        <span>{account?.company?.name ?? "Admin"}</span>
        <Icon name={"chevronDown"} />
      </div>
    ),
  };

  if (!breakpoints.md && !protector.isProtected()) {
    actionMenuItems.push(accountSwitcher);
  }

  actionMenuItems.push({
    onClick: accountClickHandler(),
    title: "Account",
    children: <AccountMenuItem />,
  });

  if (!breakpoints.md && protector.isProtected()) {
    actionMenuItems.push(accountSwitcher);
  }

  if (account?.role === "family" && !protector.isProtected()) {
    actionMenuItems.push(...familyMenuItems);
  }

  actionMenuItems.push({
    onClick: () => {
      if (isInCatalogue()) {
        logout({ resetColors: false });
      } else {
        Router.push("Logout");
      }
    },
    title: "Log out",
    leftIcon: "logOutOutline",
    destructive: true,
  });

  return actionMenuItems;
};

const familyMenuItems: ActionMenuItemProps[] = [
  {
    title: "Family members",
    group: {
      items: [
        {
          title: "My students",
          leftIcon: "peopleOutline",
          rightIcon: "chevronForwardOutline",
          justifyContent: "between",
          onClick: () => Router.push("CustomerStudents"),
        },
        {
          title: "My contacts",
          leftIcon: "callOutline",
          rightIcon: "chevronForwardOutline",
          justifyContent: "between",
          onClick: () => Router.push("CustomerContacts"),
        },
      ],
    },
  },
  {
    title: "Company",
    group: {
      items: [
        {
          title: "Waivers & Policies",
          leftIcon: "documentTextOutline",
          rightIcon: "chevronForwardOutline",
          justifyContent: "between",
          onClick: () => Router.push("CustomerWaivers"),
        },
      ],
    },
  },
];
