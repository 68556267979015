import { Api } from "../api";
import { uniqueValuesForKey } from "../collectionUtils";
import { DatatableRequest } from "../datatable";

export const fetchWaitingLists = async ({
  api,
  request,
  studentId,
  classId,
  selectAll,
}: {
  api: Api;
  request: DatatableRequest;
  classId?: string;
  studentId?: string;
  selectAll: boolean;
}) => {
  const { where, ...rest } = request;

  const classWhere = classId ? { classId: { equals: classId } } : undefined;
  const studentWhere = studentId
    ? { studentId: { equals: studentId } }
    : undefined;

  const waitingListResponse = await api.waitingLists.listWaitingList({
    ...rest,
    where: { ...where, ...classWhere, ...studentWhere },
    selectAll,
  });

  const classIds = uniqueValuesForKey("classId", waitingListResponse.data);
  const studentIds = uniqueValuesForKey("studentId", waitingListResponse.data);

  const classesPromise = api.courses.listCourse({
    where: { id: { in: classIds } },
  });
  const studentsPromise = api.students.listStudent({
    where: { id: { in: studentIds } },
    includeArchived: true,
    selectAll: true,
  });

  const [{ data: classes }, { data: students }] = await Promise.all([
    classesPromise,
    studentsPromise,
  ]);

  const seasonIds = uniqueValuesForKey(
    "seasonId",
    classes.map(c => c.entity),
  );
  const { data: seasons } = await api.seasons.listSeason({
    where: { id: { in: seasonIds } },
    selectAll: true,
  });

  return {
    ...waitingListResponse,
    data: waitingListResponse.data.map(waitingList => {
      const classDto = classes.find(c => c.entity.id === waitingList.classId)!;
      return {
        ...waitingList,
        id: waitingList.id as string,
        class: classDto,
        student: students.find(s => s.id === waitingList.studentId)!,
        season: seasons.find(s => s.id === classDto.entity.seasonId)!,
      };
    }),
  };
};
