import { ReactNode } from "react";

import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import remarkStringify from "remark-stringify";
import { unified } from "unified";

/**
 * Convert a markdown text string to a platform-specific set of text nodes.
 * Only BOLD is currently supported.
 */
export const markdownToReactNodes = (
  markdown: string,
  renderRegular: (v: string, index: number) => ReactNode,
  renderBold: (v: string, index: number) => ReactNode,
): Array<ReactNode> => {
  // We could easily call a third-party library here to parse the markdown,
  // but for now we'll keep it simple.
  const parts = markdown.replaceAll(/\*\*(.+?)\*\*/g, "**__$1__**").split("**");

  const filteredParts = parts.filter(part => part !== "");
  return filteredParts.map((part, i) =>
    /__(.+?)__/.test(part)
      ? renderBold(part.replaceAll("__", ""), i)
      : renderRegular(part, i),
  );
};

export const markdownToHtml = (markdown: string): string =>
  unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypeStringify)
    .processSync(markdown)
    .toString();

export const htmlToMarkdown = (html: string): string =>
  unified()
    .use(rehypeParse)
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(html)
    .toString();
