import { useState } from "react";

import {
  AggregateClassDto,
  CreateEnrolmentRequestInner,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { useClassesSeasons } from "../../data/useClassesSeasons";
import { useStudentSelect } from "../../data/useStudentSelect";
import { createEnrolmentStartDate } from "../../enrolmentUtils";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { dayjs } from "../../lib/dayjs";
import { getPlatformFunctions } from "../../platformSpecific";
import { UseApi } from "../apiQueryFactory";
import { EnrolmentKey } from "../enrolments/UpdateEnrolmentStartForm";
import { useFormActions } from "../FormActionsProvider";
import { CourseSelectValue } from "../forms/CourseSelect";

export type CreateFormActionSchema = {
  course?: AggregateClassDto;
  season?: SeasonDto;
  studentIds?: string[];
  showStudentSelector?: boolean;
};

type AddSchema = Omit<CreateEnrolmentRequestInner, "studentId"> & {
  studentIds: string[];
};

type UpdateSchema = {
  date: string;
};

type FormDefinitionSchema = {
  useApi: UseApi;
  classId?: string;
  seasonId?: string;
  showStudentSelector?: boolean;
};

const useFormDefinition = ({
  useApi,
  seasonId,
  classId,
  showStudentSelector = true,
}: FormDefinitionSchema) => {
  type Schema = AddSchema;

  const { setAllowCreateAdditional, setCreateButtonText } = useFormActions();
  const { setSelectedClassId, selectedClassId } = useClassesSeasons({
    useApi,
    seasonId,
    classId,
  });
  const { studentList } = useStudentSelect({
    useApi,
    classId: selectedClassId,
    checkWaitingList: false,
  });

  setAllowCreateAdditional(false);
  setCreateButtonText("Add");

  const [startAt, setStartAt] = useState<Date>();
  const [endAt, setEndAt] = useState<Date>();

  const builder = new FormDefinitionBuilder<Schema>()
    .group("Class details", ["classId"])
    .group("Enrollment dates", ["startAt", "endAt"]);

  if (showStudentSelector) {
    builder.group("Students", ["studentIds"]);
  }

  builder
    .conditional(["classId"], ["classId"], v => {
      const courseId = v.classId as CourseSelectValue;
      setSelectedClassId(courseId ?? undefined);

      return true;
    })
    .conditional(["studentIds"], ["studentIds"], () => {
      return showStudentSelector;
    });

  if (!classId) {
    builder.courseId("classId", {
      required: true,
    });
  }

  builder
    .multiSelect("studentIds", {
      label: "Select students",
      data: studentList,
      localSearch: true,
      required: true,
    })
    .date("startAt", {
      label: "Start date",
      maxDate: endAt,
      required: true,
    })
    .conditional(["startAt"], ["startAt"], v => {
      const newStartDate = v.startAt
        ? new Date(v.startAt as string)
        : undefined;
      if (startAt?.toDateString() !== newStartDate?.toDateString()) {
        setStartAt(newStartDate);
      }
      return true;
    })
    .date("endAt", {
      label: "End date",
      minDate: startAt,
      required: true,
    })
    .conditional(["endAt"], ["endAt"], v => {
      const newEndDate = v.endAt ? new Date(v.endAt as string) : undefined;
      if (endAt?.toDateString() !== newEndDate?.toDateString()) {
        setEndAt(newEndDate);
      }
      return true;
    });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const useUpdateStartFormDefinition = () => {
  type Schema = UpdateSchema;
  const { setAllowCreateAdditional } = useFormActions();

  setAllowCreateAdditional(false);

  const builder = new FormDefinitionBuilder<Schema>().date("date", {
    label: "Start date",
    required: true,
  });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const useUpdateEndFormDefinition = () => {
  type Schema = UpdateSchema;
  const { setAllowCreateAdditional } = useFormActions();

  setAllowCreateAdditional(false);

  const builder = new FormDefinitionBuilder<Schema>().date("date", {
    label: "End date",
    required: true,
  });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeEnrolmentAddRequest =
  ({
    api,
    classId,
    season,
  }: {
    api: Api;
    classId?: string;
    season?: SeasonDto;
  }) =>
  (data: AddSchema) => {
    if (!data.startAt && season) {
      data.startAt = createEnrolmentStartDate(season);
    }

    if (!data.endAt && season) {
      data.endAt = season.endAt;
    }

    if (dayjs(data.startAt) > dayjs(data.endAt)) {
      return Promise.reject({
        statusCode: 422,
        messages: ["You can't start the enrolment after it ends"],
        validationErrors: {
          startAt: ["Start of enrolment must be before end"],
          endAt: ["End of enrolment must be after start"],
        },
      });
    }

    const waitingListData: Array<CreateEnrolmentRequestInner> =
      data.studentIds.map(studentId => ({
        classId: data.classId ?? classId,
        studentId,
        startAt: data.startAt,
        endAt: data.endAt,
      }));

    return api.enrolments.createEnrolment({
      createEnrolmentRequestInner: waitingListData,
    });
  };

export const makeDelayStartDateRequest = ({
  classId,
  studentId,
}: Omit<EnrolmentKey, "date">) => {
  const { api } = getPlatformFunctions();
  return ({ date }: UpdateSchema) =>
    api.enrolmentAdjustments.delayedStartEnrolmentEnrolmentAdjustment({
      delayedStartEnrolment: [
        {
          date,
          classId,
          studentId,
        },
      ],
    });
};

export const makeCancellationRequest = ({
  classId,
  studentId,
}: {
  classId: string;
  studentId: string;
}) => {
  const { api } = getPlatformFunctions();
  return ({ date }: UpdateSchema) =>
    api.enrolmentAdjustments.cancelEnrolmentEnrolmentAdjustment({
      enrolmentCancellation: [
        {
          date,
          classId,
          studentId,
        },
      ],
    });
};

export const makeEnrolmentAddForm = (props: FormDefinitionSchema) => () =>
  useFormDefinition(props);
