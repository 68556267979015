import { getFullName, getInitials } from "./personUtil";

type Person = {
  firstname: string | null;
  lastname: string | null;
};

type Family = {
  name: string;
};

type NameableEntity = Person | Family;

export function isFamily(entity: NameableEntity | null): entity is Family {
  return !!entity && "name" in entity;
}

export function getEntityName(entity: NameableEntity | null): string {
  if (entity === null) return "";

  if (isFamily(entity)) {
    return entity.name;
  }

  return getFullName(entity);
}

export function getEntityInitials(entity: NameableEntity | null): string {
  if (entity === null) return "??";

  if (isFamily(entity)) {
    return entity.name[0];
  }

  return getInitials(entity);
}
