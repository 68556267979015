export const colorPickerColors = {
  grey: [
    "#eff1f8",
    "#e2e4ee",
    "#d4d7e3",
    "#a5a8bb",
    "#62657a",
    "#404256",
    "#212330",
  ],
  red: [
    "#fdd2d2",
    "#fa9e9e",
    "#f87272",
    "#f74040",
    "#d90808",
    "#940505",
    "#630303",
  ],
  orange: [
    "#fde6d2",
    "#fac99e",
    "#f9ae6c",
    "#f89e4f",
    "#d76909",
    "#9a4a04",
    "#643002",
  ],
  yellow: [
    "#fdfad2",
    "#fbf59d",
    "#f9ef6c",
    "#f7e81d",
    "#dbcd06",
    "#958b04",
    "#665f00",
  ],
  lime: [
    "#ecfdd2",
    "#d5fb9d",
    "#c1f96c",
    "#a4f726",
    "#7dcd04",
    "#5b9405",
    "#3d6402",
  ],
  green: [
    "#d2fdd4",
    "#9dfba0",
    "#6cf971",
    "#5cfa61",
    "#05d10c",
    "#039608",
    "#039608",
  ],
  turquoise: [
    "#d2fdee",
    "#9dfbdc",
    "#6cf9ca",
    "#22f7b0",
    "#05cc8a",
    "#039665",
    "#026443",
  ],
  sky: [
    "#d2f7fd",
    "#9deefb",
    "#6ae8fb",
    "#20dbf8",
    "#04b6d2",
    "#038296",
    "#025764",
  ],
  blue: [
    "#d2e3fd",
    "#9dc3fb",
    "#6ea5f7",
    "#2277f7",
    "#0657d0",
    "#033e96",
    "#022964",
  ],
  purple: [
    "#d5d2fd",
    "#a39dfb",
    "#776ef7",
    "#2e1ff9",
    "#1407ca",
    "#0e0495",
    "#090264",
  ],
  pinkPurple: [
    "#e9d2fd",
    "#cf9efa",
    "#b76ef7",
    "#9320f8",
    "#7707da",
    "#54029c",
    "#360264",
  ],
  pink: [
    "#fdd2fd",
    "#fb9dfb",
    "#f76ef7",
    "#f820f8",
    "#ce03ce",
    "#a003a0",
    "#650165",
  ],
};
