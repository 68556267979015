import { useGenericComponents } from "shared/components";

export const PublicTakePaymentContainer = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  const { View } = useGenericComponents();
  return (
    <View
      className={
        "space-y-10 md:px-24 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0 lg:px-8"
      }>
      {children}
    </View>
  );
};
