export const countDigits = (num: number): number => {
  // Convert number to string
  const numStr: string = Math.abs(num).toString();

  // Return the length of the string
  return numStr.length;
};

export const numberToOrdinal = (num: number): string => {
  if (num === 0) return "0";

  const s = ["th", "st", "nd", "rd"];
  const v = num % 100;

  return num + (s[(v - 20) % 10] || s[v] || s[0]);
};
