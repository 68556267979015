import { colors } from "shared/lib";

import { Icon, IconName } from "@/modules/common/ui/icon/Icon";
import {
  StatusIconSize,
  StatusIconType,
} from "@/modules/common/ui/icon/StatusIconType";

export const StatusIcon = ({
  type,
  size = StatusIconSize.sm,
}: {
  type: StatusIconType;
  size?: StatusIconSize;
}) => {
  const iconMap: { [key: string]: IconName } = {
    default: "checkmarkCircle",
    invited: "time",
    success: "checkmarkCircle",
  };

  const iconColorMap: { [key: string]: string } = {
    default: colors.grey[500],
    invited: colors.grey[900],
    success: colors.green[600],
  };

  const getIconSize = (size: StatusIconSize) => {
    switch (size) {
      case StatusIconSize.md:
        return 24;
      case StatusIconSize.lg:
        return 28;
      default:
        return 16;
    }
  };

  return (
    <Icon
      name={iconMap[type]}
      color={iconColorMap[type]}
      size={getIconSize(size)}
    />
  );
};
