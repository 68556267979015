import { formatMoneyFromInteger } from "../../intlFormatter";
import { useGenericComponents } from "../GenericComponentsProvider";

export const PaymentFormAmount = ({ amount }: { amount: number }) => {
  const { View, Text } = useGenericComponents();
  return (
    <View className="flex flex-col gap-1 pb-8">
      <Text className="text-label-400 text-grey-600">Payment amount</Text>
      <Text className="text-body-400 text-grey-900">
        {formatMoneyFromInteger(amount)}
      </Text>
    </View>
  );
};
