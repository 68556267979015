import { CustomFieldEntity } from "@justraviga/classmanager-sdk";

import { customFieldEntityLabel } from "shared/lib";

import { Chip } from "@/modules/common/ui/chip/Chip";

export const CustomFiledCardFooter = ({
  entity,
}: {
  entity: CustomFieldEntity;
}) => {
  return (
    <div className={"flex justify-start"}>
      <Chip label={customFieldEntityLabel(entity)!} variant={"neutral"} />
    </div>
  );
};
