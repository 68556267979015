import { TaxRateDto } from "@justraviga/classmanager-sdk";

import { useSharedTaxRateActions } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";

export const useTaxRateActions = () => {
  return useSharedTaxRateActions(
    api,
    usePlatformEntityActions<TaxRateDto>({
      entity: "taxRate",
    }),
    GenericForm,
    (item: TaxRateDto) => (
      <dl className="flex flex-col gap-y-1">
        <dt className="text-label-400 text-grey-600">Tax amount</dt>
        <dd className="text-body-400 text-grey-900">{item.rate}%</dd>
      </dl>
    ),
  );
};
