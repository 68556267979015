import { useFamilyMakeUpLessons } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { CardContainerPlaceholder } from "@/modules/common/cardContainer/CardContainer";
import { CustomerCardPage } from "@/modules/common/cardContainer/CustomerCardPage";
import { FamilyMakeUpLessonsList } from "@/modules/customer/makeUpLessons/FamilyMakeUpLessonsList";

export const FamilyMakeUpLessonsPage = () => {
  const {
    upComingMakeUpLessons,
    pastMakeUpLessons,
    students,
    classes,
    seasons,
    isLoading,
    isEmpty,
  } = useFamilyMakeUpLessons(api);

  return (
    <CustomerCardPage
      title={"Make up lessons"}
      isEmpty={isEmpty}
      isLoading={isLoading}
      forceBackButton={true}>
      <div className={"mb-8"}>
        <FamilyMakeUpLessonsList
          makeUpLessons={upComingMakeUpLessons}
          students={students}
          classes={classes}
          seasons={seasons}
          title={"Upcoming"}
        />
      </div>
      <CardContainerPlaceholder title={"No make up lessons to show"} />
      {!isEmpty && pastMakeUpLessons.length > 0 && (
        <FamilyMakeUpLessonsList
          makeUpLessons={pastMakeUpLessons}
          students={students}
          classes={classes}
          seasons={seasons}
          title={"Past"}
          collapsible={true}
        />
      )}
    </CustomerCardPage>
  );
};
