import {
  CreateStudentRequest,
  StudentGender,
  UpdateStudentRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

type CreateSchema = CreateStudentRequest;
type UpdateSchema = UpdateStudentRequest;
type Action = "create" | "update";

const useDefinition = <Mode extends Action>({
  mode,
  showAdvancedToggle = true,
  showJoinedAtField = true,
}: {
  mode: Mode;
  showAdvancedToggle?: boolean;
  showJoinedAtField?: boolean;
}) => {
  type Schema = CreateSchema & UpdateSchema;

  const builder = new FormDefinitionBuilder<Schema>()
    .group("Basic information", ["firstname", "lastname"])
    .text("firstname", { label: "First name", required: true })
    .text("lastname", { label: "Last name", required: true })
    .group("Personal information", ["dateOfBirth", "gender", "joinedAt"], {
      advanced: mode === "create" && showAdvancedToggle,
    })
    .date("dateOfBirth", { label: "Date of birth" })
    .select("gender", {
      label: "Gender",
      data: Object.values(StudentGender).map(value => ({
        label: enumLabel("studentGender", value),
        value,
      })),
    })
    .date("joinedAt", { label: "Joined date" })
    .group("Medical & consent", ["mediaConsent", "medicalInfo"], {
      advanced: mode === "create" && showAdvancedToggle,
    })
    .text("medicalInfo", {
      label: "Medical information",
      description:
        "If your child doesn't have any medical information to provide, leave this section blank.",
    })
    .switch("mediaConsent", {
      label: "Photo & video consent",
      description:
        "Student consents to be in images posted on social media or used in online content.",
    })
    .conditional(["joinedAt"], [], () => showJoinedAtField);

  return builder.getDefinition() as FormDefinition<
    Mode extends "create" ? CreateSchema : UpdateSchema
  >;
};

export const useStudentCreateFormDefinition = () =>
  useDefinition({ mode: "create" });

export const useStudentUpdateFormDefinition = () =>
  useDefinition({ mode: "update" });

export const usePortalStudentCreateFormDefinition = () =>
  useDefinition({
    mode: "create",
    showAdvancedToggle: false,
    showJoinedAtField: false,
  });

export const usePortalStudentUpdateFormDefinition = () =>
  useDefinition({
    mode: "update",
    showAdvancedToggle: false,
    showJoinedAtField: false,
  });

export const usePortalStudentDateOfBirthChangeFormDefinition = () => {
  return new FormDefinitionBuilder<UpdateSchema>()
    .date("dateOfBirth", { label: "Date of birth" })
    .getDefinition() as FormDefinition<UpdateSchema>;
};

export const makeStudentCreateRequest =
  ({ api, familyId }: { api: Api; familyId: string }) =>
  (formData: CreateSchema) => {
    return api.students.createStudent({
      createStudentRequest: {
        ...formData,
        familyId,
      },
    });
  };

export const makeStudentUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.students.updateStudent({
      id,
      updateStudentRequest: formData,
    });
  };
