import { useEffect, useState } from "react";

import { SaveStateIndicator, useEmailForm } from "shared/components";
import { useBreakpoint } from "shared/lib";

import { FormFooter } from "@/modules/common/form/FormFooter";
import { imageUploadAdapter } from "@/modules/common/form/richText/ImageUploadAdaptor";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { DetailPageLayout } from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { useEmailActions } from "@/modules/company/messages/useEmailActions";
import { Router } from "@/routing/router";

const goBack = () => {
  Router.push("Emails");
};

export const WriteEmailPage = ({
  id,
  contactId,
}: {
  id?: string;
  contactId?: string;
}) => {
  const [columnWidth, setColumnWidth] = useState(0);

  const actions = useEmailActions();
  const breakpoint = useBreakpoint();

  const { content, saveAsDraft, saveState, send, sendIsDisabled, message } =
    useEmailForm({
      BaseCard,
      Button: props => <Button {...props} />,
      Tooltip,
      id,
      contactId,
      goBack,
      goToEmailPage: () => Router.push("Emails"),
      useFormBuilder,
      showRecipientsByStudentForm: actions.showRecipientsByStudentForm,
      showRecipientsByClassForm: actions.showRecipientsByClassForm,
      showRecipientsBySeasonForm: actions.showRecipientsBySeasonForm,
    });

  useEffect(() => {
    if (message?.entity.id) {
      imageUploadAdapter.setMessageId(message.entity.id);
    }
  }, [message?.entity.id]);

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        title="Create Email"
        archivedEntityName={"Email"}
        isDesktop={breakpoint.md}
        crumbs={[
          { text: "Emails", onPress: () => Router.push("Emails") },
          { text: "Create Email" },
        ]}
        actions={[
          {
            destructive: true,
            title: "Delete draft",
            onClick: async () => {
              if (message) {
                await actions.deleteOne(message);
                goBack();
              }
            },
            leftIcon: "trashOutline",
          },
        ]}
        beforeActions={
          <div className="pr-1">
            <SaveStateIndicator saveState={saveState} />
          </div>
        }
        onWidthChange={setColumnWidth}>
        <div className="pb-28 md:pb-14">{content}</div>
        <div
          className="fixed bottom-0 z-10 -mx-5 bg-white md:-mx-8"
          style={{
            width: columnWidth,
          }}>
          <FormFooter>
            <div>
              <Button
                text={"Save as draft"}
                variant="secondary"
                onClick={saveAsDraft}
                isFullWidth={true}
              />
            </div>

            <div>
              <Button
                variant="brand"
                text="Send"
                disabled={sendIsDisabled}
                onClick={send}
                isFullWidth={true}
              />
            </div>
          </FormFooter>
        </div>
      </DetailPageLayout>
    </CompanyLayout>
  );
};
