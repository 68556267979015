import { WaiverEntity } from "./waiver";
import { EntityListResponse } from "../entityListUtils";

export function sortWaiversList(
  entityListResponse: EntityListResponse<WaiverEntity>,
) {
  return {
    ...entityListResponse,
    data: entityListResponse.data.sort((waiverA, waiverB) => {
      if (waiverB.isLocked()) {
        return 1;
      }

      if (waiverA.isLocked()) {
        return -1;
      }

      return 0;
    }),
  };
}
