import { PropsWithChildren } from "react";

import {
  CardContainer,
  CardContainerActions,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { CustomerTitleBar } from "@/modules/customer/common/CustomerTitle";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";

interface CustomerCardPageProps extends PropsWithChildren {
  title: string;
  isEmpty: boolean;
  isLoading: boolean;
  forceBackButton?: boolean;
  menuItems?: ActionMenuItemProps[];
  primaryAction?: PrimaryActionItemProps;
}

export const CustomerCardPage = ({
  title,
  isEmpty,
  isLoading,
  primaryAction,
  menuItems,
  children,
  forceBackButton = false,
}: CustomerCardPageProps) => (
  <CustomerLayout>
    <CardContainer
      variant="page"
      isEmpty={isEmpty}
      primaryAction={primaryAction}
      menuItems={menuItems}>
      <CustomerTitleBar
        title={title}
        forceBackButton={forceBackButton}
        rightContent={<CardContainerActions />}
      />
      {isLoading ? (
        <div className="flex w-full items-center justify-center py-8">
          <LoadingSpinnerDark />
        </div>
      ) : (
        <div className="pb-5 pt-4 md:pb-8">{children}</div>
      )}
    </CardContainer>
  </CustomerLayout>
);
