import { useAuthRedirect } from "@/modules/auth/redirect/useAuthRedirect";
import { useLoginReturnRoute } from "@/modules/auth/utils/useLoginReturnRoute";
import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const PublicAccountLinks = () => {
  const { setRedirect } = useAuthRedirect();
  const loginReturnRoute = useLoginReturnRoute();

  const actionMenuItems: ActionMenuItemProps[] = [
    {
      title: "Log in",
      leftIcon: "logInOutline",
      onClick: () => {
        setRedirect({
          ...loginReturnRoute,
          redirectTarget: "CatalogueLogin",
        });
      },
    },
    {
      title: "Register",
      leftIcon: "createOutline",
      onClick: () => {
        setRedirect({
          ...loginReturnRoute,
          redirectTarget: "CatalogueRegister",
        });
      },
    },
  ];

  return (
    <div className="ml-auto flex items-center gap-6">
      <ActionMenu
        width={"w-80"}
        items={actionMenuItems}
        trigger={
          <div className="flex cursor-pointer flex-row items-center gap-2">
            <Avatar size="md" />
            <Icon name="chevronDown" size={16} />
          </div>
        }
      />
    </div>
  );
};
