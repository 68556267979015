import { cn, colors, getAccessibleColorClass } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";

export interface AvatarProps {
  src?: string;
  className?: string;
  alt?: string;
  size?: "sm" | "md" | "lg" | "xl";
  checked?: boolean;
  loading?: boolean;
  name?: string;
  bgColor?: string;
}

export const Avatar = ({
  src,
  className,
  alt,
  size = "md",
  checked = false,
  loading = false,
  name,
  bgColor = colors.brand["500"],
}: AvatarProps) => {
  const sizeClasses = getSizeClasses(size);

  return (
    <div
      className={cn(
        "flex items-center justify-center overflow-hidden rounded-full border-[1px] border-overlay-white-heavy",
        sizeClasses,
        className,
      )}
      style={{ backgroundColor: !src && name ? bgColor : "" }}>
      <div
        className={"relative flex h-full w-full items-center justify-center"}>
        {src ? (
          <AvatarImage src={src} alt={alt} />
        ) : (
          <AvatarFallback
            name={name}
            fallbackBackgroundColor={bgColor}
            size={size}
          />
        )}
        {checked && (
          <div
            className={
              "absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-full bg-grey-900 opacity-60"
            }></div>
        )}
        {loading && (
          <div
            className={
              "absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-full bg-white opacity-60"
            }></div>
        )}
        {loading && (
          <div className={"absolute"}>
            <LoadingSpinnerDark />
          </div>
        )}
        {checked && (
          <div className={"absolute"}>
            <Icon
              name={"checkmarkSharp"}
              size={getCheckIconSize(size)}
              color={"white"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const getCheckIconSize = (size: "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "sm":
      return 10;
    case "md":
      return 12;
    case "lg":
      return 16;
    case "xl":
      return 20;
  }
};
// eslint-disable-next-line react-refresh/only-export-components
export const getSizeClasses = (size: "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "sm":
      return "w-8 h-8";
    case "md":
      return "w-10 h-10";
    case "lg":
      return "w-12 h-12";
    case "xl":
      return "w-16 h-16";
  }
};

const getPlaceholderIconSize = (size: "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "sm":
      return 16;
    case "md":
      return 20;
    case "lg":
      return 24;
    case "xl":
      return 32;
  }
};

// eslint-disable-next-line react-refresh/only-export-components
export const getInitialFontSizeClass = (size: "sm" | "md" | "lg" | "xl") => {
  switch (size) {
    case "sm":
      return "text-caption-600";
    case "md":
      return "text-label-600";
    case "lg":
      return "text-heading6-600";
    case "xl":
      return "text-heading4-600";
  }
};

const AvatarImage = ({ src, alt }: { src?: string; alt?: string }) => {
  return (
    <img className={"aspect-square h-full w-full"} alt={alt && alt} src={src} />
  );
};

interface AvatarFallbackProps {
  size?: AvatarProps["size"];
  name?: AvatarProps["name"];
  fallbackBackgroundColor?: string;
}

const AvatarFallback = ({
  size = "md",
  name,
  fallbackBackgroundColor,
}: AvatarFallbackProps) => {
  return (
    <>
      {name ? (
        <div
          data-testid={"initial-fallback"}
          className={
            "flex h-full w-full items-center justify-center rounded-full"
          }>
          <span
            className={`${getInitialFontSizeClass(size)} text-${getAccessibleColorClass(
              fallbackBackgroundColor,
            )}`}>
            {name.toUpperCase()}
          </span>
        </div>
      ) : (
        <div
          data-testid="placeholder-fallback"
          className={
            "flex h-full w-full items-center justify-center rounded-full bg-grey-400"
          }>
          <Icon
            test-id
            name={"person"}
            size={getPlaceholderIconSize(size)}
            color={"white"}
          />
        </div>
      )}
    </>
  );
};
