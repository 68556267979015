import { TaxRateDto } from "@justraviga/classmanager-sdk";

import { SelectItem } from "../components/interfaces";

export const makeTaxRateSelectOptions = (
  taxRates: TaxRateDto[],
): SelectItem[] => {
  return taxRates.map((taxRate: TaxRateDto) => ({
    label: taxRate.label
      .concat(" - ", taxRate.rate.toString(), "%")
      .concat(taxRate.isDefault ? " (default)" : ""),
    value: taxRate.id,
  }));
};
