import { getFirstLetters, TileCellProps } from "shared/lib";

import { TrimmableText } from "@/modules/common/datatable/TrimmableText";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";

export const TileCell = ({
  title,
  subtitle,
  image,
  icon,
  hasImage = true,
  multilineSubtitle = false,
}: Omit<TileCellProps, "type">) => {
  return (
    <div className={"flex items-center justify-between"}>
      {hasImage && (
        <div className={"pr-3"}>
          <Avatar src={image} name={getFirstLetters(title ?? "")} />
        </div>
      )}

      <div>
        <div className="flex items-center text-base font-semibold text-grey-900">
          <span>
            <TrimmableText text={title}></TrimmableText>
          </span>
          <div className={"pl-2"}>{icon && icon}</div>
        </div>
        {subtitle && (
          <div className="text-sm font-normal text-grey-600">
            <TrimmableText
              text={subtitle}
              multipleLines={multilineSubtitle}></TrimmableText>
          </div>
        )}
      </div>
    </div>
  );
};
