import { LinkDto, StaffDto } from "@justraviga/classmanager-sdk";

import {
  makeStaffInviteRequest,
  useStaffInviteFormDefinition,
} from "shared/components";
import { colors } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { Banner } from "@/modules/common/ui/Banner";

type StaffSendInvitationFormProps = {
  staff: StaffDto;
  onSuccess: (linkData: LinkDto) => void;
};

export const StaffSendInvitationForm = ({
  staff,
  onSuccess,
}: StaffSendInvitationFormProps) => {
  return (
    <>
      <Banner
        content="You need to provide an email address and permission level to send an invitation to Class Manager"
        icon={{ name: "alertCircle", color: colors.red[600] }}
      />
      <GenericForm
        apiRequest={makeStaffInviteRequest({ api, id: staff.id })}
        defaultValues={staff}
        formDefinitionHook={useStaffInviteFormDefinition}
        onSuccess={onSuccess}
      />
    </>
  );
};
