import {
  AggregateClassDto,
  LocationDto,
  PublicStaffDto,
  RoomDto,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../platformSpecific";

export const useFamilyCoursePreview = (
  course: AggregateClassDto,
): {
  room: RoomDto | undefined;
  location: LocationDto | undefined;
  staff: PublicStaffDto | undefined;
} => {
  const { useApi } = getPlatformFunctions();
  const { roomId } = course.entity;

  const { data: room } = useApi(
    "getRoom",
    { id: roomId as string },
    { enabled: roomId !== undefined },
  );

  const { data: location } = useApi(
    "getLocation",
    { id: room?.locationId ?? "" },
    { enabled: room?.locationId !== undefined },
  );

  const { data: staff } = useApi(
    "getStaff",
    { id: course.entity.staffId as string },
    { enabled: course.entity.staffId !== null },
  );

  return {
    room,
    location,
    staff: staff as PublicStaffDto,
  };
};
