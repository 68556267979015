import { ClassManagerTextLogo, cn } from "shared/lib";

import letterGrey600 from "@/assets/class-manager-letter-grey-600.svg";
import letterGrey900 from "@/assets/class-manager-letter-grey-900.svg";
import letterWhite from "@/assets/class-manager-letter-white.svg";
import letterGrey500 from "@/assets/class-manager-logo-grey-500.svg";
import logoGrey500 from "@/assets/class-manager-logo-grey-500.svg";
import logoGrey600 from "@/assets/class-manager-logo-grey-600.svg";
import logoGrey900 from "@/assets/class-manager-logo-grey-900.svg";
import logoWhite from "@/assets/class-manager-logo-white.svg";

const logos = {
  white: logoWhite,
  "grey-500": logoGrey500,
  "grey-600": logoGrey600,
  "grey-900": logoGrey900,
};
const letters = {
  white: letterWhite,
  "grey-500": letterGrey500,
  "grey-600": letterGrey600,
  "grey-900": letterGrey900,
};

export const ClassManagerLogo = ({
  color,
  width, // eg. '100%' or '50px'
  height, // eg. '100%' or '50px'
  singleLetter,
  className,
}: ClassManagerTextLogo<string>) => {
  const src = singleLetter ? letters[color] : logos[color];
  return (
    <div className={cn("flex w-full justify-center", className)}>
      <img
        className={"aspect-[75/11] object-fill"}
        src={src}
        alt={"Class Manager logo"}
        style={{
          height: height || (singleLetter ? "80px" : ""),
          width: width || (singleLetter ? "80px" : ""),
        }}
      />
    </div>
  );
};
