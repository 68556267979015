import { ClientSideEntity } from "shared/lib";

type AddMenuItemFn<PlatformActionMenuItemProps> = (
  menuItem: PlatformActionMenuItemProps,
  shouldShow?: boolean,
) => ActionMenuBuilder<PlatformActionMenuItemProps>;

interface ActionMenuBuilder<PlatformActionMenuItemProps> {
  edit: AddMenuItemFn<PlatformActionMenuItemProps>;
  archive: AddMenuItemFn<PlatformActionMenuItemProps>;
  delete: AddMenuItemFn<PlatformActionMenuItemProps>;
  restore: AddMenuItemFn<PlatformActionMenuItemProps>;
  addMenuItem: AddMenuItemFn<PlatformActionMenuItemProps>;
  build: () => PlatformActionMenuItemProps[];
}

export function entityActionMenuBuilder<PlatformActionMenuItemProps>(
  entity: ClientSideEntity,
): ActionMenuBuilder<PlatformActionMenuItemProps> {
  const menu: PlatformActionMenuItemProps[] = [];

  const edit = (menuItem: PlatformActionMenuItemProps, shouldShow = true) => {
    if (shouldShow && entity.isEditable()) {
      menu.push(menuItem);
    }

    return builder;
  };

  const archive = (
    menuItem: PlatformActionMenuItemProps,
    shouldShow = true,
  ) => {
    if (shouldShow && entity.isArchivable()) {
      menu.push(menuItem);
    }

    return builder;
  };

  const restore = (
    menuItem: PlatformActionMenuItemProps,
    shouldShow = true,
  ) => {
    if (shouldShow && entity.isRestorable()) {
      menu.push(menuItem);
    }

    return builder;
  };

  const deleteItem = (
    menuItem: PlatformActionMenuItemProps,
    shouldShow = true,
  ) => {
    if (shouldShow && entity.isDeletable()) {
      menu.push({
        destructive: true,
        ...menuItem,
      });
    }

    return builder;
  };

  const addMenuItem = (
    menuItem: PlatformActionMenuItemProps,
    shouldShow = true,
  ) => {
    if (shouldShow) {
      menu.push(menuItem);
    }

    return builder;
  };

  const build = () => menu;

  const builder = {
    edit,
    archive,
    restore,
    delete: deleteItem,
    addMenuItem,
    build,
  };

  return builder;
}
