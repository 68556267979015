import { StudentDto } from "@justraviga/classmanager-sdk";

import { cn, colors } from "shared/lib";

import { Avatar } from "./Avatar";
import { useGenericComponents } from "../GenericComponentsProvider";

export const AvatarGroup = ({
  list,
  size = "md",
  maxAvatars = 3,
}: {
  list: StudentDto[];
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  maxAvatars?: number | "all";
}) => {
  const { View, Text } = useGenericComponents();
  if (list.length === 0) {
    return <Text data-testid="emptylist">-</Text>;
  }

  const avatarsToShow = maxAvatars === "all" ? list : list.slice(0, maxAvatars);
  const extraCount = list.length - avatarsToShow.length + 1;
  extraCount > 1 && avatarsToShow.pop();

  return (
    <View className={"items-center overflow-hidden flex flex-row"}>
      {avatarsToShow.map((item, index) => (
        <View
          className={cn({
            "-mr-2": index !== avatarsToShow.length - 1 || extraCount > 1,
          })}
          key={item.id}>
          <Avatar
            src={item.profilePicture ?? undefined}
            person={item}
            size={size}
          />
        </View>
      ))}
      {extraCount > 1 && (
        <Avatar
          alt={`+${extraCount}`}
          bgColor={colors.grey["400"]}
          size={size}
          key={avatarsToShow.length}
        />
      )}
    </View>
  );
};
