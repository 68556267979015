import { useWaiverEntity } from "./useWaiverEntity";
import { WaiverImplementedActions } from "./waiverActions";
import { UseApi } from "../../components/apiQueryFactory";
import { isDefinedEntity } from "../entityTypes";

export function useSharedWaiverDetailsPage(
  id: string,
  waiverActions: WaiverImplementedActions,
  useApi: UseApi,
) {
  const { entity: waiver, isLoading } = useWaiverEntity(useApi, id);

  const showEditForm = () => {
    if (isDefinedEntity(waiver)) {
      return waiverActions.showEditForm(waiver);
    }
  };

  const restore = () => {
    if (isDefinedEntity(waiver)) {
      return waiverActions.unarchive(waiver.props);
    }
  };

  return {
    isLoading,
    restore,
    showEditForm,
    waiver,
  };
}
