import { SortSchema } from "@justraviga/classmanager-sdk";

import { UseApi } from "../components/apiQueryFactory";
import {
  calculateBalances,
  getTransactionPrimaryType,
} from "../transactionUtils";

export const useFamilyTransactionsData = (
  familyId: string | undefined | null,
  useApi: UseApi,
) => {
  const { data: balanceDto } = useApi(
    "getBalance",
    {
      familyId: familyId!,
    },
    { enabled: !!familyId },
  );

  const { data: transactionResponse } = useApi(
    "listTransaction",
    {
      where: { familyId: { equals: familyId! } },
      selectAll: true,
      sort: {
        date: SortSchema.Desc,
        createdAt: SortSchema.Desc,
      },
    },
    { enabled: !!familyId },
  );

  const balance = balanceDto ? balanceDto.balance : 0;
  const transactions = (
    !transactionResponse ? [] : transactionResponse.data
  ).map(t => ({
    ...t,
    primaryType: getTransactionPrimaryType(t),
  }));

  const balances = calculateBalances(
    balance,
    transactions.map(t => [
      t.transaction.type,
      t.details.map(d => d.details.amount),
    ]),
  );

  const getBalanceForTransaction = (transactionId: string) => {
    const index = transactions.findIndex(
      transaction => transaction.transaction.id === transactionId,
    );
    return balances[index];
  };

  return {
    isLoading: !balanceDto || !transactionResponse,
    balance,
    transactions,
    getBalanceForTransaction,
  };
};
