import { AggregateClassDto } from "@justraviga/classmanager-sdk";

import { useAlert } from "../../alertState";
import { BasketContents, makeTrialKey } from "../../basket/basket";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { formatDate } from "../../intlFormatter";
import { dayjs } from "../../lib/dayjs";
import { getPlatformFunction } from "../../platformSpecific";
import { useGenericComponents } from "../GenericComponentsProvider";

interface Schema {
  dateAndTime: string;
}

const makeFormDefinition =
  (course: AggregateClassDto, basket: BasketContents) => () => {
    const useApi = getPlatformFunction("useApi");
    const { data: availabilities } = useApi("listTrialAvailability", {
      where: { classId: { in: [course.entity.id] } },
    });
    const lessonOptions = (availabilities ?? [])
      .filter(({ lesson }) =>
        dayjs(`${lesson.date} ${lesson.startTime}`).isAfter(dayjs()),
      )
      .map(({ lesson, spacesRemaining }) => {
        const date = formatDate(lesson.date, "weekdayDayMonthYear");
        const tag = basket.trials.has(
          makeTrialKey(lesson.classId, lesson.date, lesson.startTime),
        )
          ? " (added to basket)"
          : lesson.holidays.length > 0
            ? " (holiday)"
            : lesson.adjustments.length > 0
              ? " (cancelled)"
              : spacesRemaining === 0
                ? " (class full)"
                : "";
        const label = `${date}${tag}`;
        const disabled =
          basket.trials.has(
            makeTrialKey(lesson.classId, lesson.date, lesson.startTime),
          ) ||
          lesson.holidays.length > 0 ||
          lesson.adjustments.length > 0 ||
          (spacesRemaining !== null && spacesRemaining <= 0);
        return {
          disabled,
          label,
          description: lesson.startTime,
          value: `${lesson.date} ${lesson.startTime}`,
        };
      });

    return new FormDefinitionBuilder<Schema>()
      .select("dateAndTime", {
        label: "Date",
        required: true,
        data: lessonOptions,
      })
      .getDefinition();
  };

export const TrialLessonForm = ({
  course,
  onSubmit,
  basket,
}: {
  course: AggregateClassDto;
  onSubmit: (formData: { dateAndTime: string }) => Promise<object>;
  basket: BasketContents;
}) => {
  const { View, GenericForm } = useGenericComponents();
  const { showAlert } = useAlert();
  return (
    <View className="h-full -mx-5 flex flex-col space-y-4">
      <View className="grow px-5 overflow-y-auto">
        <GenericForm
          defaultValues={{}}
          formDefinitionHook={makeFormDefinition(course, basket)}
          apiRequest={onSubmit}
          onSuccess={() => {
            showAlert({
              variant: "success",
              content: "Trial added to summary",
            });
          }}
        />
      </View>
    </View>
  );
};
