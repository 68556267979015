import {
  InviteStaffRequest,
  StaffPermissionSet,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

type Schema = InviteStaffRequest;

export const useStaffInviteFormDefinition = () =>
  new FormDefinitionBuilder<Schema>()
    .text("email", {
      label: "Email address",
      type: "email",
      description:
        "Provide the email that your staff member will be using to access Class Manager.",
      required: true,
    })
    .select("permissionSet", {
      label: "Permissions",
      data: Object.values(StaffPermissionSet)
        .map(value => ({
          label: enumLabel("permissionSet", value),
          value,
        }))
        // TEMPORARY: only show 'Owner' role for initial release
        .filter(option => option.value === "owner"),
      required: true,
    })
    .getDefinition();

export const makeStaffInviteRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: Schema) => {
    return api.staff.inviteStaff({ id, inviteStaffRequest: formData });
  };
