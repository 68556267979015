import { FamilyDto, StudentDto } from "@justraviga/classmanager-sdk";

import { getFullName, getInitials } from "shared/lib";

import { useData } from "@/lib/api/apiClient";
import { Button } from "@/modules/common/ui/button/Button";
import { Chip } from "@/modules/common/ui/chip/Chip";
import {
  DetailProfileAvatarContainer,
  DetailProfileContainer,
} from "@/modules/company/common/DetailProfile";
import { AvatarWithUpload } from "@/modules/company/common/details/profile/AvatarWithUpload";
import { StudentExtraInfo } from "@/modules/company/members/student/detail/StudentExtraInfo";
import { Router } from "@/routing/router";

interface ChipListProps {
  student: StudentDto;
}

const ChipList = ({ student }: ChipListProps) => {
  return (
    <div className={"flex flex-row flex-wrap gap-x-2 gap-y-2"}>
      {!student.mediaConsent && (
        <Chip label={"No Photo Consent"} variant={"danger"} />
      )}
      {student.medicalInfo && <Chip label={"Medicals"} variant={"info"} />}
    </div>
  );
};

const Family = ({ family }: { family: FamilyDto }) => {
  return (
    <div className={"flex"}>
      <div className={"flex flex-row-reverse items-center text-body-400"}>
        <div className="-ml-3">
          <Button
            variant="tertiary"
            size="sm"
            text={family?.name}
            onClick={() => Router.push("FamilyDetails", { id: family.id })}
          />
        </div>
        <span className={"leading-none text-grey-600"}>Family</span>
      </div>
    </div>
  );
};

export const Profile = ({
  student,
  isDesktop,
  uploadAction,
}: {
  student: StudentDto;
  isDesktop: boolean;
  uploadAction: () => void;
}) => {
  const isMobile = !isDesktop;
  const family = useData("getFamily", { id: student?.familyId ?? "" });

  return (
    <DetailProfileContainer>
      <DetailProfileAvatarContainer>
        <AvatarWithUpload
          src={student.profilePicture}
          name={getInitials(student)}
          showUploadIcon={!student.archivedAt}
          uploadAction={uploadAction}
        />
        <div className={"flex h-full min-w-0 flex-col items-start gap-1"}>
          <span
            aria-label="student name"
            className={"line-clamp-1 text-heading5-600 text-grey-900"}>
            {getFullName(student)}
          </span>
          {isDesktop && <Family family={family} />}
        </div>
        {isDesktop && <ChipList student={student} />}
      </DetailProfileAvatarContainer>
      {isMobile && <ChipList student={student} />}
      {isMobile && <Family family={family} />}
      <StudentExtraInfo
        dateOfBirth={student.dateOfBirth}
        joinedAt={student.joinedAt}
        gender={student.gender}
      />
    </DetailProfileContainer>
  );
};
