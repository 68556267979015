import { CheckUserExistenceRequest } from "@justraviga/classmanager-sdk";

import { DefaultValues, FormBuilder } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = CheckUserExistenceRequest;

const definition = new FormDefinitionBuilder<Schema>()
  .text("email", {
    value: "",
    label: "Email address",
    required: true,
  })
  .getDefinition();

export const useUserExistenceCheckForm = (
  builder: FormBuilder,
  onSubmit: (data: Schema) => void,
  defaultValues: DefaultValues<Schema>,
) => {
  return builder(definition, onSubmit, defaultValues);
};
