import { useEffect } from "react";

import { setPublicCompanyId } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { setCompanyGlobals } from "@/lib/setCompanyGlobals";

export const useInvitation = (linkId: string) => {
  const {
    data: linkDetails,
    isLoading: linkLoading,
    isError: linkError,
  } = useApi("getLinkData", { id: linkId });
  const {
    data: companyDetails,
    isLoading: companyLoading,
    isError: companyError,
  } = useApi(
    "getCompany",
    {
      id: linkDetails?.companyId || "",
    },
    {
      enabled: !!linkDetails?.companyId,
    },
  );

  useEffect(() => {
    if (companyDetails) {
      setCompanyGlobals(companyDetails.settings);
      setPublicCompanyId(companyDetails.id);
    }
  }, [companyDetails]);

  return {
    isLoading: linkLoading || companyLoading,
    hasError: linkError || companyError,
    linkDetails,
    companyDetails,
  };
};
