import { createContext } from "react";

import {
  GlobalSearchResultsType,
  GlobalSearchStatus,
  SearchContextType,
  SearchDialogContextType,
  SearchResultsContextType,
  SearchStatusContextType,
} from "./searchTypes";
import { buildEmptySearchObject } from "./searchUtils";

export const SearchStatusContext = createContext<SearchStatusContextType>({
  status: GlobalSearchStatus.PENDING,
});

export const SearchDialogContext = createContext<SearchDialogContextType>({
  isSearchOpen: false,
  handleOpenChange: () => {},
});

export const SearchResultsContext = createContext<SearchResultsContextType>({
  searchResults: buildEmptySearchObject(GlobalSearchResultsType.clean),
});

export const SearchContext = createContext<SearchContextType>({
  search: () => {},
});
