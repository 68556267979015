import React from "react";

import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export interface ActionBannerProps {
  icon?: React.ReactNode;
  title?: string;
  description: string;
  secondaryButtonText?: string | null;
  secondaryButtonOnPress?: VoidFunction | null;
  buttons?: React.ReactNode[];
  onClose?: () => void;
}

export const ActionBanner = ({
  icon,
  title,
  description,
  buttons,
  secondaryButtonText,
  secondaryButtonOnPress,
  onClose,
}: ActionBannerProps) => {
  function getPadding() {
    if (secondaryButtonText && secondaryButtonOnPress) {
      return "px-4 py-2";
    }

    return "p-4";
  }

  return (
    <div
      className={`flex w-full flex-col space-y-4 border border-grey-300 bg-grey-100 ${getPadding()}`}>
      <div className={"flex items-center justify-between"}>
        <div className={"flex"}>
          {icon && <div className={"pr-4"}>{icon}</div>}

          <div className={"flex flex-col"}>
            <div className={"flex flex-row items-center justify-between"}>
              {title && (
                <p className={"text-body-600 text-grey-900"}>{title}</p>
              )}
            </div>

            <p className={"text-label-400 text-grey-600"}>{description}</p>
          </div>
        </div>

        {secondaryButtonText && secondaryButtonOnPress && (
          <Button
            variant={"tertiary"}
            text={secondaryButtonText}
            size={"sm"}
            onClick={() => secondaryButtonOnPress()}
          />
        )}

        {onClose && (
          <div className={"-mr-2 -mt-2 self-start"}>
            <IconButton
              variant="standard"
              icon={"closeOutline"}
              onClick={onClose}
            />
          </div>
        )}
      </div>

      {buttons && buttons.length > 0 && (
        <div className={"flex flex-row justify-start gap-4"}>
          {buttons.map((button, i) => (
            <div key={i}>{button}</div>
          ))}
        </div>
      )}
    </div>
  );
};
