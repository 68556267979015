import React from "react";

import { IconButton } from "@/modules/common/ui/button/IconButton";

type SubNavHideButtonProps = {
  onClick: () => void;
};

export const SubNavHideButton = React.memo(
  ({ onClick }: SubNavHideButtonProps) => (
    <div className="-mr-1 -mt-2">
      <IconButton
        variant="standard"
        icon="closeOutline"
        onClick={onClick}
        size="sm"
      />
    </div>
  ),
);
