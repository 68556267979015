import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  Breadcrumbs,
  BreadcrumbsProps,
} from "@/modules/common/nav/Breadcrumbs";

const options: Array<Option<BreadcrumbsProps>> = [
  {
    title: "Basic functionality",
    props: [
      {
        items: [
          {
            text: "Home",
            onPress: () => {},
          },
          {
            text: "Area",
            onPress: () => {},
          },
          {
            text: "Page",
          },
        ],
      },
    ],
  },
];

export const BreadcrumbPage = () => {
  return (
    <DocWrapper
      title={"Breadcrumbs"}
      component={Breadcrumbs}
      options={options}
    />
  );
};
