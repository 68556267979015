import {
  LessonId,
  resolveLessonId,
  uniqueValuesForKey,
  useLessonStudentFamilies,
} from "shared/lib";

import { LoadingSpinnerGrey } from "@/modules/common/ui/LoadingSpinnerGrey";
import { TransactionsCreatePage } from "@/modules/company/billing/transactions/TransactionsCreatePage";

export const TransactionCreateFromLessonPage = ({
  lessonId,
}: {
  lessonId: string;
}) => {
  const { courseId, date, time } = resolveLessonId(lessonId as LessonId);

  const { families, isLoading } = useLessonStudentFamilies({
    courseId,
    date: date,
    time: time,
  });

  if (isLoading) {
    return <LoadingSpinnerGrey />;
  }

  return (
    <TransactionsCreatePage familyIds={uniqueValuesForKey("id", families)} />
  );
};
