import { PropsWithChildren, useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
  StripePaymentSessionDto,
} from "@justraviga/classmanager-sdk";
import type { CheckoutRequestItems } from "@justraviga/classmanager-sdk/dist/models/CheckoutRequestItems";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { match } from "ts-pattern";

import { useFormActions, useStripeLib } from "shared/components";
import { capturePaymentIntent, checkoutPayment, showAlert } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";

import { appearance } from "./stripeAppearence";

type OperationType = "checkout" | "capture";

type OnSuccessCallback = (
  result?: AggregateTransactionDto[],
  checkoutOperation?: CheckoutOperationRequest,
) => void;

type HideFormFn = () => void;

interface TakePaymentFormProps {
  familyId: string;
  onSuccess?: OnSuccessCallback;
  requestItems?: CheckoutRequestItems;
  operationType: OperationType;
}

const TakePaymentForm = ({
  familyId,
  onSuccess,
  requestItems,
  operationType,
}: TakePaymentFormProps) => {
  const { closeSheet: hideForm } = useSheet();
  const { setSubmit, setIsLoading } = useFormActions();
  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentStatus = (paymentIntent: PaymentIntent) => {
    match(paymentIntent.status)
      .with("succeeded", async () => {
        onSuccess?.();
        showAlert({
          content: "Payment taken successfully",
        });
        hideForm();
      })
      .with("requires_capture", async () => {
        await handleRequiresCapture(
          paymentIntent,
          operationType,
          familyId,
          requestItems!,
          hideForm,
          onSuccess,
        );
      })
      .with("processing", () => {})
      .with("requires_payment_method", () => {
        showAlert({
          content: "a payment method is required",
        });
      })
      .with("requires_confirmation", () => {
        showAlert({
          content: "Your payment method requires confirmation",
        });
      })
      .with("requires_action", () => {
        showAlert({
          content: "Your payment method requires action",
        });
      })
      .with("canceled", () => {
        showAlert({
          content: "Adding payment method was canceled",
        });
      })
      .exhaustive();
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // eslint-disable-next-line camelcase
        return_url: `${window.location.origin}${window.location.pathname}`,
      },
      redirect: "if_required",
    });

    if (error) {
      showAlert({
        content: "A card or validation error occurred.",
      });
    } else if (paymentIntent) {
      try {
        await handlePaymentStatus(paymentIntent);
      } catch (e) {
        console.error(e);
      }
    }

    setIsLoading(false);
  };

  setSubmit(handleSubmit);

  if (!stripe || !elements) {
    return null;
  }

  return (
    <form className="space-y-4">
      <PaymentElement />
    </form>
  );
};

const TakePaymentContainer = ({ children }: PropsWithChildren) => (
  <div className="w-full">{children}</div>
);

interface TakePaymentProps extends PropsWithChildren {
  amount: number;
  familyId: string;
  onSuccess?: (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => void;
  requestItems?: CheckoutRequestItems;
  operationType: OperationType;
}

export const TakePayment = ({
  amount,
  familyId,
  onSuccess,
  requestItems,
  operationType,
}: TakePaymentProps) => {
  const [paymentSession, setPaymentSession] =
    useState<StripePaymentSessionDto>();
  useEffect(() => {
    // We don't use useApi here, because this request is a mutation (POST), and we don't
    // want to trigger an infinite re-fetch loop.
    api.stripe
      .stripeCreatePaymentSession({
        familyId,
        stripeCreatePaymentSessionRequest: {
          amount,
        },
      })
      .then(response => {
        setPaymentSession(response);
      });
  }, [amount, familyId]);

  const { stripe } = useStripeLib();

  if (!paymentSession) {
    return (
      <TakePaymentContainer>
        <div className="flex h-52 items-center justify-center">
          <LoadingSpinnerDark />
        </div>
      </TakePaymentContainer>
    );
  }

  const { paymentIntent, customerSession } = paymentSession;

  return (
    <TakePaymentContainer>
      {stripe && customerSession?.clientSecret && paymentIntent && (
        <Elements
          stripe={stripe}
          options={{
            clientSecret: paymentIntent.clientSecret,
            customerSessionClientSecret: customerSession.clientSecret,
            appearance: appearance,
          }}>
          <TakePaymentForm
            familyId={familyId}
            onSuccess={onSuccess}
            requestItems={requestItems}
            operationType={operationType}
          />
        </Elements>
      )}
    </TakePaymentContainer>
  );
};

async function handleCheckoutPayment(
  paymentIntent: PaymentIntent,
  familyId: string,
  requestItems: CheckoutRequestItems,
  hideForm: HideFormFn,
  onSuccess?: OnSuccessCallback,
) {
  await checkoutPayment(
    api,
    {
      familyId,
      checkoutRequest: {
        paymentIntentId: paymentIntent.id,
        items: requestItems!,
      },
    },
    {
      onSuccess: (
        result: AggregateTransactionDto[],
        checkoutOperation: CheckoutOperationRequest,
      ) => {
        hideForm();
        onSuccess?.(result, checkoutOperation);
      },
    },
  );
}

async function handleCapturePayment(
  paymentIntent: PaymentIntent,
  familyId: string,
  hideForm: HideFormFn,
  onSuccess?: OnSuccessCallback,
) {
  await capturePaymentIntent(
    api,
    {
      familyId,
      paymentIntentId: paymentIntent.id,
    },
    {
      onSuccess: result => {
        hideForm();
        onSuccess?.([result]);
      },
    },
  );
}

async function handleRequiresCapture(
  paymentIntent: PaymentIntent,
  operationType: OperationType,
  familyId: string,
  requestItems: CheckoutRequestItems,
  hideForm: HideFormFn,
  onSuccess?: OnSuccessCallback,
) {
  if (operationType === "checkout") {
    await handleCheckoutPayment(
      paymentIntent,
      familyId,
      requestItems,
      hideForm,
      onSuccess,
    );
  } else if (operationType === "capture") {
    await handleCapturePayment(paymentIntent, familyId, hideForm, onSuccess);
  } else {
    throw new Error(`Invalid operation type: ${operationType}`);
  }
}
