import { ReactNode, useState } from "react";

import {
  LocationDto,
  RoomDto,
  SeasonDto,
  StaffDto,
  WeekDay,
} from "@justraviga/classmanager-sdk";
import { UseFormReturn } from "react-hook-form";

import {
  BaseInputProps,
  BaseInputValue,
  ClassEntity,
  DatatableFilter,
  DatatableFilterOperator,
  datatableQueryToRequest,
  enumLabel,
  FilterFormDefinition,
  getInitialQuery,
  getPlatformFunctions,
  groupListFilterDefaultValues,
  PossibleFormValues,
  useClassesScheduleData,
} from "shared/lib";

import { ClassCountWidget } from "./ClassCountWidget";
import { GroupedList } from "./GroupedList";
import { SeasonScheduleFilterChips } from "./SeasonScheduleFilterChips";
import { ClassImplementedActions } from "../../../../actions/useSharedCourseActions";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { useOrderedWeekDays } from "../../../../useOrderedWeekDays";

interface SeasonScheduleWidgetProps {
  season: SeasonDto;
  locations: LocationDto[];
  rooms: RoomDto[];
  staff: StaffDto[];
  useCourseActions: () => ClassImplementedActions;
  showWebSize: boolean;
  open: ({
    title,
    onClose,
    side,
    content,
    footer,
  }: {
    title: string;
    content: React.ReactNode;
    footer: React.ReactNode | null;
    onClose: () => void;
    side: "right" | "top" | "bottom" | undefined;
  }) => void;
  close: () => void;
  DatatableFiltersSheetFooter: React.ComponentType<{
    onApply: () => void;
    onClear: () => void;
  }>;
  form: React.JSX.Element;
  formHandlers: UseFormReturn<Record<string, PossibleFormValues>>;
  BaseInput: (props: BaseInputProps) => ReactNode;
  ScheduleClassCard: (props: {
    season: SeasonDto;
    classEntity: ClassEntity;
    location: LocationDto | undefined;
    room?: RoomDto | undefined;
    staff: StaffDto | undefined;
  }) => ReactNode;
  scheduleFilters: DatatableFilter[];
  setScheduleFilters: (filters: DatatableFilter[]) => void;
  filterForm: FilterFormDefinition<object, object>;
}

export const SeasonScheduleWidget = ({
  season,
  locations,
  rooms,
  staff,
  useCourseActions,
  showWebSize,
  open,
  close,
  DatatableFiltersSheetFooter,
  form,
  formHandlers,
  BaseInput,
  ScheduleClassCard,
  scheduleFilters,
  setScheduleFilters,
  filterForm,
}: SeasonScheduleWidgetProps) => {
  const { View, Text } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const weekDays = useOrderedWeekDays();
  const [search, setSearch] = useState<BaseInputValue>("");
  const [showedWeekDays, setShowedWeekDays] = useState<WeekDay[]>(weekDays);

  const { classes, isLoading } = useClassesScheduleData({
    apiClient: useApi,
    request: {
      ...datatableQueryToRequest({
        ...getInitialQuery(),
        selectAll: true,
        sort: [
          {
            id: "startTime",
            desc: false,
          },
        ],
        search: search?.toString() || "",
        filters: [
          ...scheduleFilters,
          {
            field: "seasonId",
            operator: DatatableFilterOperator.Equals,
            value: season.id,
          },
        ],
      }),
      onlyArchived: scheduleFilters.some(
        v => v.field === "isArchived" && v.value,
      ),
    },
    locations,
    rooms,
    staff,
  });

  const { showCreateFormWithWeekDay } = useCourseActions();

  const convertScheduleFiltersToFilterValues = (
    scheduleFilters: DatatableFilter[],
  ) => {
    return scheduleFilters.map(filter =>
      filter.field === "roomId"
        ? {
            ...filter,
            field: "locationId",
            value: rooms.find(r => r.id === (filter.value as string[])[0])!
              .locationId!,
          }
        : filter,
    );
  };

  const updateFilters = (newScheduleFilters: DatatableFilter[]) => {
    setScheduleFilters(newScheduleFilters);
    formHandlers.reset(
      groupListFilterDefaultValues({
        filterForm: filterForm as FilterFormDefinition<object, object>,
        appliedFilters:
          convertScheduleFiltersToFilterValues(newScheduleFilters),
      }),
    );
  };

  const onRemoveFilter = (field: string) => {
    const newScheduleFilters = scheduleFilters.filter(f => f.field !== field);
    updateFilters(newScheduleFilters);
    if (field === "dayOfWeek") {
      setShowedWeekDays(weekDays);
    }
  };

  const onUpdateFilterValue = (field: string, value: string[]) => {
    if (value.length === 0) {
      return onRemoveFilter(field);
    }

    const newScheduleFilters = scheduleFilters.map(filter =>
      filter.field === field ? { ...filter, value } : filter,
    );
    updateFilters(newScheduleFilters);

    if (field === "dayOfWeek") {
      setShowedWeekDays(value as WeekDay[]);
    }
  };

  return (
    <GroupedList
      isLoading={isLoading}
      title={"Weekly Schedule"}
      header={<ClassCountWidget seasonId={season.id} />}
      beforeContent={
        <View>
          <SeasonScheduleFilterChips
            scheduleFilters={scheduleFilters}
            onUpdateFilterValue={onUpdateFilterValue}
            onRemoveFilter={onRemoveFilter}
            staffMembers={staff}
            rooms={rooms}
            locations={locations}
          />
          {scheduleFilters.length > 0 || search ? (
            <View className={"pt-4"}>
              <Text className={"text-label-400"}>
                {classes.length} result{classes.length > 1 ? "s" : ""}
              </Text>
            </View>
          ) : null}
        </View>
      }
      items={classes}
      renderItem={(classEntity: ClassEntity) => {
        const { class: courseDto } = classEntity;
        const room = rooms.find(r => r.id === courseDto.roomId);
        const location = room
          ? locations.find(l => l.id === room.locationId)
          : undefined;

        const staffMember = staff.find(
          (s: StaffDto) => s.id === courseDto.staffId,
        );

        return (
          <ScheduleClassCard
            key={courseDto.id}
            season={season}
            classEntity={classEntity}
            location={location}
            room={room}
            staff={staffMember}
          />
        );
      }}
      groups={showedWeekDays.map(dayOfWeek => ({
        title: () => enumLabel("weekDays", dayOfWeek),
        titleAddition: (groupItems: ClassEntity[]) => {
          return `(${groupItems.length} ${groupItems.length === 1 ? "class" : "classes"})`;
        },
        groupBy: ({ class: courseDto }: ClassEntity) =>
          courseDto.dayOfWeek === dayOfWeek,
        onAdd: () => showCreateFormWithWeekDay(season, dayOfWeek),
      }))}
      searchValue={search}
      onSearch={setSearch}
      filterValues={convertScheduleFiltersToFilterValues(scheduleFilters)}
      onFilter={filterData => {
        setScheduleFilters(filterData);

        const dayOfWeekFilter = filterData.find(f => f.field === "dayOfWeek");
        if (dayOfWeekFilter) {
          setShowedWeekDays(dayOfWeekFilter.value as WeekDay[]);
        } else if (showedWeekDays.length !== weekDays.length) {
          setShowedWeekDays(weekDays);
        }
      }}
      filterForm={filterForm as FilterFormDefinition<object, object>}
      showWebSize={showWebSize}
      open={open}
      close={close}
      DatatableFiltersSheetFooter={DatatableFiltersSheetFooter}
      form={form}
      formHandlers={formHandlers}
      BaseInput={BaseInput}
    />
  );
};
