import { StatCountCard } from "./StatCountCard";
import { formatDateTime } from "../../../../intlFormatter";
import { dayjs } from "../../../../lib/dayjs";
import { getPlatformFunction } from "../../../../platformSpecific";

export const EnrolledStudentsWidget = () => {
  const useApi = getPlatformFunction("useApi");

  const { data: total, isLoading: isLoadingTotal } = useApi(
    "getEnrolmentStudentCount",
    {},
    {},
  );

  const { data: recent, isLoading: isLoadingRecent } = useApi(
    "getEnrolmentStudentCount",
    {
      where: {
        createdAt: {
          greaterThanOrEqual: formatDateTime(
            dayjs().subtract(28, "day").toDate(),
            "isoDate",
          ),
        },
      },
    },
  );

  const isLoading = isLoadingTotal || isLoadingRecent;

  return (
    <StatCountCard
      isLoading={isLoading}
      title={"Total enrolled students"}
      totalCount={total?.count ?? 0}
      recentCount={recent?.count ?? 0}
    />
  );
};
