import { colors } from "../../colors";
import { IconButtonSize } from "../../iconUtils";
import { IconButtonProps } from "../interfaces";

/**
 * Note, we do require a bit of duplication of hover styles in order to get the best
 *   performance on web, whilst still supporting mobile.
 *   So make sure whatever is in the hoverButton property
 *   is duplicated in the button class, with an "enabled:hover:" prefix.
 */
export const iconButtonVariantConfigs = {
  primary: {
    button: "bg-grey-900 enabled:hover:bg-grey-800",
    disabledButton: "bg-grey-300",
    hoverButton: "bg-grey-800",
    iconColor: colors.white,
    disabledIconColor: colors.grey["500"],
  },
  "secondary-outline": {
    button: "bg-white border border-grey-400 enabled:hover:bg-grey-200",
    disabledButton: "bg-white",
    hoverButton: "bg-grey-200",
    iconColor: colors.black,
    disabledIconColor: colors.grey["400"],
  },
  "secondary-fill": {
    button: "bg-grey-200 enabled:hover:bg-grey-300",
    disabledButton: "bg-grey-100",
    hoverButton: "bg-grey-300",
    iconColor: colors.black,
    disabledIconColor: colors.grey["400"],
  },
  standard: {
    button: "enabled:hover:overlay-grey-lighter",
    disabledButton: "",
    hoverButton: "overlay-grey-lighter",
    iconColor: colors.grey["900"],
    disabledIconColor: colors.grey["400"],
  },
  destructive: {
    button: "bg-white border border-grey-400 enabled:hover:bg-grey-200",
    disabledButton: "bg-white",
    hoverButton: "bg-grey-200",
    iconColor: colors.red["600"],
    disabledIconColor: colors.red["200"],
  },
  success: {
    button:
      "bg-green-600 border border-overlay-white-heavy enabled:hover:bg-green-500",
    disabledButton: "bg-grey-300",
    hoverButton: "bg-green-500",
    iconColor: colors.white,
    disabledIconColor: colors.grey["500"],
  },
};

export const iconButtonSizeClasses: Record<IconButtonSize, string> = {
  xs: "h-6 w-6 p-0.5",
  sm: "h-8 w-8 p-1",
  lg: "h-10 w-10 p-2",
  "2xl": "h-12 w-12 p-2",
};

interface IconButtonVariation {
  title: string;
  props: Array<IconButtonProps>;
}

export const useIconButtonVariations = (): Array<IconButtonVariation> => {
  return [
    {
      title: "Variants",
      props: [
        {
          icon: "shareSocialOutline",
          variant: "primary",
        },
        {
          icon: "shareSocialOutline",
          variant: "secondary-outline",
        },
        {
          icon: "shareSocialOutline",
          variant: "secondary-fill",
        },
        {
          icon: "shareSocialOutline",
          variant: "standard",
        },
        {
          icon: "trashOutline",
          variant: "destructive",
        },
        {
          icon: "checkmarkCircle",
          variant: "success",
        },
      ],
    },
    {
      title: "Sizes",
      props: [
        {
          icon: "shareSocialOutline",
          size: "2xl",
        },
        {
          icon: "shareSocialOutline",
        },
        {
          icon: "shareSocialOutline",
          size: "sm",
        },
        {
          icon: "shareSocialOutline",
          size: "xs",
        },
      ],
    },
    {
      title: "Disabled",
      props: [
        {
          icon: "shareSocialOutline",
          disabled: true,
        },
        {
          icon: "shareSocialOutline",
          variant: "secondary-outline",
          disabled: true,
        },
        {
          icon: "shareSocialOutline",
          variant: "secondary-fill",
          disabled: true,
        },
        {
          icon: "shareSocialOutline",
          variant: "standard",
          disabled: true,
        },
        {
          icon: "trashOutline",
          variant: "destructive",
          disabled: true,
        },
      ],
    },
    {
      title: "lg/Rounded With Indicator",
      props: [
        {
          border: "rounded",
          size: "lg",
          hasIndicator: true,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "rounded",
          size: "lg",
          hasIndicator: true,
          indicatorNumber: 1,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "rounded",
          size: "lg",
          hasIndicator: true,
          indicatorNumber: 123,
          variant: "primary",
          icon: "shareSocialOutline",
        },
      ],
    },
    {
      title: "lg/Square With Indicator",
      props: [
        {
          border: "square",
          size: "lg",
          hasIndicator: true,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "square",
          size: "lg",
          hasIndicator: true,
          indicatorNumber: 1,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "square",
          size: "lg",
          hasIndicator: true,
          indicatorNumber: 123,
          variant: "primary",
          icon: "shareSocialOutline",
        },
      ],
    },
    {
      title: "sm/Rounded With Indicator",
      props: [
        {
          border: "rounded",
          size: "sm",
          hasIndicator: true,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "rounded",
          size: "sm",
          hasIndicator: true,
          indicatorNumber: 1,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "rounded",
          size: "sm",
          hasIndicator: true,
          indicatorNumber: 123,
          variant: "primary",
          icon: "shareSocialOutline",
        },
      ],
    },
    {
      title: "sm/Square With Indicator",
      props: [
        {
          border: "square",
          size: "sm",
          hasIndicator: true,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "square",
          size: "sm",
          hasIndicator: true,
          indicatorNumber: 1,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "square",
          size: "sm",
          hasIndicator: true,
          indicatorNumber: 123,
          variant: "primary",
          icon: "shareSocialOutline",
        },
      ],
    },
    {
      title: "xs/Rounded With Indicator",
      props: [
        {
          border: "rounded",
          size: "xs",
          hasIndicator: true,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "rounded",
          size: "xs",
          hasIndicator: true,
          indicatorNumber: 1,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "rounded",
          size: "xs",
          hasIndicator: true,
          indicatorNumber: 123,
          variant: "primary",
          icon: "shareSocialOutline",
        },
      ],
    },
    {
      title: "xs/Square With Indicator",
      props: [
        {
          border: "square",
          size: "xs",
          hasIndicator: true,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "square",
          size: "xs",
          hasIndicator: true,
          indicatorNumber: 1,
          variant: "primary",
          icon: "shareSocialOutline",
        },
        {
          border: "square",
          size: "xs",
          hasIndicator: true,
          indicatorNumber: 123,
          variant: "primary",
          icon: "shareSocialOutline",
        },
      ],
    },
    {
      title: "Indicator Variants",
      props: [
        {
          icon: "bagHandleOutline",
          variant: "secondary-outline",
          hasIndicator: true,
          indicatorNumber: 5,
          indicatorVariant: "error",
        },
        {
          icon: "bagHandleOutline",
          variant: "secondary-outline",
          hasIndicator: true,
          indicatorNumber: 5,
          indicatorVariant: "primary",
        },
      ],
    },
    {
      title: "Colours",
      props: [
        {
          variant: "secondary-outline",
          icon: "bagHandleOutline",
          color: colors.red["500"],
        },
        {
          variant: "secondary-outline",
          icon: "chatbubblesOutline",
          color: colors.brand["500"],
        },
      ],
    },
  ];
};
