import {
  cn,
  Dto,
  FilterFormDefinitionBuilder,
  mapFilterFormValues,
  PossibleFormValues,
  useDatatable,
} from "shared/lib";

import { DatatableFiltersSheetFooter } from "@/modules/common/datatable/common/DatatableFiltersSheetFooter";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const DatatableFilters = () => {
  const { openSheet, closeSheet } = useSheet();

  const { datatable, showWebSize, resetQuery, filtersValue } = useDatatable();

  const definition =
    datatable.filterForm ??
    new FilterFormDefinitionBuilder<Dto, object>().getDefinition();

  const filterForm = useFormBuilder<Record<string, PossibleFormValues>>(
    definition.formDefinition,
    () => {},
    {
      // todo add values from existing filter - although not sure if we really need this, as the form seems
      //   to maintain its state when the sheet is closed and reopened
    },
  );

  if (!datatable?.filterForm) {
    return <></>;
  }

  const reset = () => {
    filterForm.formHandlers.reset();
    filtersValue.clear();
    resetQuery();
    closeSheet();
  };

  const applyFilters = () => {
    if (!datatable.filterForm) {
      return;
    }

    const filters = mapFilterFormValues(
      filterForm.formHandlers.getValues(),
      datatable.filterForm.filterOptions,
    );
    filtersValue.replace(filters);
    closeSheet();
  };

  const submit = filterForm.formHandlers.handleSubmit(applyFilters);

  function appliedFiltersCount() {
    return filtersValue.count();
  }

  if (!datatable.filterForm) {
    return <></>;
  }

  const openFilterSheet = () => {
    openSheet({
      title: "Filters",
      side: "right",
      content: filterForm.form,
      footer: <DatatableFiltersSheetFooter onApply={submit} onClear={reset} />,
    });
  };

  return (
    <div className={"flex"}>
      {showWebSize ? (
        <Button
          leftIcon={"filterOutline"}
          size={"sm"}
          text={"Filter"}
          variant={"secondary"}
          role={"filter-trigger-button"}
          className={cn({ "border-grey-900": appliedFiltersCount() > 0 })}
          onClick={openFilterSheet}>
          {appliedFiltersCount() > 0 && (
            <div className={"py-1"}>
              <span
                className={
                  "flex h-5 w-5 items-center justify-center rounded-full bg-grey-900 p-1 text-10 text-white"
                }>
                {appliedFiltersCount()}
              </span>
            </div>
          )}
        </Button>
      ) : (
        <IconButton
          role={"filter-trigger-button"}
          icon={"filterOutline"}
          variant={"secondary-outline"}
          hasIndicator={appliedFiltersCount() > 0}
          indicatorNumber={appliedFiltersCount()}
          indicatorVariant={"primary"}
          className={appliedFiltersCount() > 0 ? "border-grey-900" : ""}
          onClick={openFilterSheet}
        />
      )}
      {showWebSize && appliedFiltersCount() > 0 && (
        <div className={"flex"}>
          <Button
            text={"Clear"}
            variant={"tertiary"}
            size={"sm"}
            className={"py-0 text-base font-normal text-grey-700 no-underline"}
            onClick={reset}
          />
        </div>
      )}
    </div>
  );
};
