import React from "react";

import { CodeBlock } from "react-code-blocks";

import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { SheetExample } from "@/modules/admin/docs/components/sheet/SheetExample";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const SheetPage = () => {
  const [showRight, setShowRight] = React.useState(false);
  const [showTop, setShowTop] = React.useState(false);
  const [showBottom, setShowBottom] = React.useState(false);
  const toggleRight = () => {
    setShowRight(!showRight);
  };
  const toggleTop = () => {
    setShowTop(!showTop);
  };
  const toggleBottom = () => {
    setShowBottom(!showBottom);
  };
  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title="Sheets" />
      </AdminTitleBar>
      <AdminContent>
        <div className="my-5 border-2 p-5">
          <div className="mb-5 flex items-center justify-between">
            <h2 className="text-16">Right</h2>
            <IconButton
              onClick={toggleRight}
              variant="standard"
              icon="codeSlashOutline"
              size="sm"
              aria-label="Toggle example code"
            />
          </div>
          <div className="flex flex-wrap gap-8">
            <div className="max-w-md grow">
              <SheetExample side={"right"} />
            </div>
            {showRight && (
              <div className="mt-8">
                <CodeBlock
                  as={undefined}
                  forwardedAs={undefined}
                  codeContainerStyle={{
                    borderRadius: "0.5rem",
                    padding: "1.2rem",
                    fontFamily: "monospace",
                    fontSize: "14px",
                  }}
                  text={`
//footer can be either React.ReactNode or React.ReactNode[] and internally use .map() to render them with appropriate spacing
// only pass footer elements as array of element so that spacing can be controlled correctly like in the example below:
// footer={[
// <Button text={"Save"} onClick={save} variant={"primary"} />,
// <Button text={"Save and Continue"} onClick={saveAndContinue} />,
// ]}
// pass in element as trigger or optionally use open to control open state and handle onOpenChange to handle close like clicking outside sheet or close button

import { Sheet, SheetProps } from "@/modules/common/dialog/Sheet";
import { Button } from "@/modules/common/ui/button/Button";
import { SheetFooter } from "@/modules/common/overlays/dialog/Sheet";

return (
    <Sheet
      side={'right'}
      trigger={<Button text={"open"} />}
      title={"Update Details"}
      footer={
        <SheetFooter
          leftContent={<Button text={"Save"} onClick={save} variant={"primary"} />}
          rightContent={<Button text={"Save and Continue"} onClick={saveAndContinue} />} 
        />
      }>
      {form}
    </Sheet>
  );
`}
                  showLineNumbers={false}
                  language={"typescript"}
                  wrapLongLines={true}
                />
              </div>
            )}
          </div>
        </div>
        <div className="my-5 border-2 p-5">
          <div className="mb-5 flex items-center justify-between">
            <h2 className="text-16">Top</h2>
            <button onClick={toggleTop}>
              <Icon name="codeSlashOutline" size={18} />
            </button>
          </div>
          <div className="flex flex-wrap gap-8">
            <div className="max-w-md grow">
              <SheetExample side={"top"} />
            </div>
            {showTop && (
              <div className="mt-8">
                <CodeBlock
                  as={undefined}
                  forwardedAs={undefined}
                  codeContainerStyle={{
                    borderRadius: "0.5rem",
                    padding: "1.2rem",
                    fontFamily: "monospace",
                    fontSize: "14px",
                  }}
                  text={`
//footer can be either React.ReactNode or React.ReactNode[] and internally use .map() to render them with appropriate spacing
// only pass footer elements as array of element so that spacing can be controlled correctly like in the example below:
// footer={[
// <Button text={"Save"} onClick={save} variant={"primary"} />,
// <Button text={"Save and Continue"} onClick={saveAndContinue} />,
// ]}
// pass in element as trigger or optionally use open to control open state and handle onOpenChange to handle close like clicking outside sheet or close button

import { Sheet, SheetProps } from "@/modules/common/dialog/Sheet";
import { Button } from "@/modules/common/ui/button/Button";
import { SheetFooter } from "@/modules/common/overlays/dialog/Sheet";

return (
    <Sheet
      side={'top'}
      trigger={<Button text={"open"} />}
      title={"Update Details"}
      footer={
        <SheetFooter
          leftContent={<Button text={"Save"} onClick={save} variant={"primary"} />}
          rightContent={<Button text={"Save and Continue"} onClick={saveAndContinue} />}
        />
      }>
      {form}
    </Sheet>
  );
`}
                  showLineNumbers={false}
                  language={"typescript"}
                  wrapLongLines={true}
                />
              </div>
            )}
          </div>
        </div>
        <div className="my-5 border-2 p-5">
          <div className="mb-5 flex items-center justify-between">
            <h2 className="text-16">Bottom</h2>
            <IconButton
              onClick={toggleBottom}
              variant="standard"
              icon="codeSlashOutline"
              size="sm"
              aria-label="Toggle example code"
            />
          </div>
          <div className="flex flex-wrap gap-8">
            <div className="max-w-md grow">
              <SheetExample side={"bottom"} />
            </div>
            {showBottom && (
              <div className="mt-8">
                <CodeBlock
                  as={undefined}
                  forwardedAs={undefined}
                  codeContainerStyle={{
                    borderRadius: "0.5rem",
                    padding: "1.2rem",
                    fontFamily: "monospace",
                    fontSize: "14px",
                  }}
                  text={`
//footer can be either React.ReactNode or React.ReactNode[] and internally use .map() to render them with appropriate spacing
// only pass footer elements as array of element so that spacing can be controlled correctly like in the example below:
// footer={[
// <Button text={"Save"} onClick={save} variant={"primary"} />,
// <Button text={"Save and Continue"} onClick={saveAndContinue} />,
// ]}
// pass in element as trigger or optionally use open to control open state and handle onOpenChange to handle close like clicking outside sheet or close button

import { Sheet, SheetProps } from "@/modules/common/dialog/Sheet";
import { Button } from "@/modules/common/ui/button/Button";
import { SheetFooter } from "@/modules/common/overlays/dialog/Sheet";

return (
    <Sheet
      side={'bottom'}
      trigger={<Button text={"open"} />}
      title={"Update Details"}
      footer={
        <SheetFooter
          leftContent={<Button text={"Save"} onClick={save} variant={"primary"} />}
          rightContent={<Button text={"Save and Continue"} onClick={saveAndContinue} />}
        />
      }>
      {form}
    </Sheet>
  );
`}
                  showLineNumbers={false}
                  language={"typescript"}
                  wrapLongLines={true}
                />
              </div>
            )}
          </div>
        </div>
      </AdminContent>
    </AdminLayout>
  );
};
