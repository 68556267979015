import { colors, TextCellProps } from "shared/lib";

import { TrimmableText } from "@/modules/common/datatable/TrimmableText";

export const TextCell = ({ text, color }: Omit<TextCellProps, "type">) => {
  const textString = typeof text === "number" ? text.toString() : text ?? "";

  return (
    <div
      className="text-base font-normal"
      style={{
        color: color ?? colors.grey[600],
      }}>
      {textString.length > 0 ? (
        <TrimmableText text={textString}></TrimmableText>
      ) : (
        "-"
      )}
    </div>
  );
};
