import { LocationDto } from "@justraviga/classmanager-sdk";

import { useBreakpoint, useLocationDetails } from "shared/lib";

import { ActionMenuProps } from "@/modules/common/ui/ActionMenu";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { AddressCard } from "@/modules/company/addresses/AddressCard";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { LocationDetailHeader } from "@/modules/company/locations/detail/LocationDetailHeader";
import { RoomsCard } from "@/modules/company/locations/detail/RoomsCard";
import { useLocationActions } from "@/modules/company/locations/useLocationActions";
import { Router } from "@/routing/router";

export const LocationDetailsPage = ({ id }: { id: LocationDto["id"] }) => {
  const breakpoints = useBreakpoint();
  const locationActions = useLocationActions();
  const { isLoading, location, rooms } = useLocationDetails(id);

  if (isLoading) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  const actions: ActionMenuProps["items"] = [
    ...(breakpoints.md
      ? []
      : [
          {
            title: "Edit",
            onClick: () => {
              locationActions.showUpdateForm(location);
            },
          },
        ]),
    {
      title: location.archivedAt ? "Restore" : "Archive",
      onClick: () => {
        location.archivedAt
          ? locationActions.unarchive(location).then()
          : locationActions.archive(location).then();
      },
    },
    {
      title: "Delete",
      destructive: true,
      onClick: () => {
        locationActions.deleteOne(location).then(() => {
          Router.push("LocationList");
        });
      },
    },
  ];

  const crumbs = [
    {
      text: "Locations",
      onPress: () => {
        Router.push("LocationList");
      },
    },
    {
      text: location.name,
    },
  ];

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        isDesktop={breakpoints.md}
        archivedEntityName={"location"}
        restoreAction={() => locationActions.unarchive(location).then()}
        editAction={() => locationActions.showUpdateForm(location)}
        actions={actions}
        crumbs={crumbs}
        isArchived={!!location.archivedAt}
        header={<LocationDetailHeader location={location} />}>
        <DetailPageGrid>
          <DetailPageGridLeftColumn>
            <RoomsCard
              showPlaceholder={!rooms.data.length}
              locationId={location.id}
              rooms={rooms.data}
            />
          </DetailPageGridLeftColumn>
          <DetailPageGridRightColumn>
            <AddressCard entityId={location.id} />
          </DetailPageGridRightColumn>
        </DetailPageGrid>
      </DetailPageLayout>
    </CompanyLayout>
  );
};
