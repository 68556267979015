import { colors } from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { Icon } from "@/modules/common/ui/icon/Icon";

interface LockedWaiverIconProps {
  isLocked?: boolean;
}

export const LockedWaiverIcon = ({
  isLocked = false,
}: LockedWaiverIconProps) =>
  isLocked ? (
    <Tooltip
      trigger={
        <div>
          <Icon name={"lockClosed"} color={colors.grey[600]} />
        </div>
      }
      children={
        <p className="text-center">
          This policy cannot be deleted. It is required when a family joins your
          organization.
        </p>
      }
    />
  ) : null;
