import { PropsWithChildren } from "react";

import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { TransactionInfo } from "./TransactionInfo";
import { TransactionPreviewHeader } from "./TransactionPreviewHeader";
import { colors } from "../../colors";
import { cn } from "../../cssUtils";
import { formatMoneyFromInteger } from "../../intlFormatter";
import {
  getTransactionLineItems,
  getTransactionPrimaryType,
} from "../../transactionUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export const TransactionPreviewContent = ({
  transaction,
  isFamilyPortal,
  showRetryPaymentForm,
}: {
  transaction: AggregateTransactionDto;
  isFamilyPortal: boolean;
  showRetryPaymentForm?: (transaction: AggregateTransactionDto) => void;
}) => {
  const { View } = useGenericComponents();

  return (
    <View>
      <Section>
        <TransactionPreviewHeader
          transaction={transaction}
          isFamilyPortal={isFamilyPortal ?? false}
        />
      </Section>

      <TransactionBanner
        transaction={transaction}
        isFamilyPortal={isFamilyPortal}
        showRetryPaymentForm={showRetryPaymentForm}
      />

      {!isFamilyPortal && (
        <Section>
          <TransactionInfo transaction={transaction} />
        </Section>
      )}

      <TransactionItems transaction={transaction} />

      <Section border={false}>
        <TransactionDetails transaction={transaction} />
      </Section>
    </View>
  );
};

const TransactionBanner = ({
  transaction,
  isFamilyPortal,
  showRetryPaymentForm,
}: {
  transaction: AggregateTransactionDto;
  isFamilyPortal: boolean;
  showRetryPaymentForm?: (transaction: AggregateTransactionDto) => void;
}) => {
  const { Banner, View } = useGenericComponents();

  const primaryType = getTransactionPrimaryType(transaction);

  if (showRetryPaymentForm && primaryType === "paymentFailed") {
    return (
      <View className={"py-4"}>
        <Banner
          title={"Failed payment"}
          content="We were unable to charge the card on file."
          icon={{
            name: "alertCircle",
            color: colors.red[600],
          }}
          action={
            isFamilyPortal
              ? undefined
              : {
                  text: "Retry",
                  onClick: () => {
                    showRetryPaymentForm(transaction);
                  },
                }
          }
        />
      </View>
    );
  }

  return <></>;
};

const TransactionDetails = ({
  transaction,
}: {
  transaction: AggregateTransactionDto;
}) => {
  const { View } = useGenericComponents();
  return (
    <>
      <InfoRowPair
        left="Subtotal"
        right={formatMoneyFromInteger(transaction.stats.subtotal)}
        textClasses="text-body-400 text-grey-600"
      />

      {transaction.stats.discountTotal !== 0 && (
        <View>
          <InfoRowPair
            left="Discount"
            right={formatMoneyFromInteger(transaction.stats.discountTotal)}
            textClasses="text-body-400 text-grey-600"
          />
        </View>
      )}

      {transaction.stats.taxTotal > 0 && (
        <InfoRowPair
          left="Tax"
          right={formatMoneyFromInteger(transaction.stats.taxTotal)}
          textClasses="text-body-400 text-grey-600"
          truncate="left"
        />
      )}

      <InfoRowPair
        left="Total"
        right={formatMoneyFromInteger(transaction.stats.total)}
        textClasses="text-heading5-600 text-grey-900 font-semibold"
      />
    </>
  );
};

const TransactionItems = ({
  transaction,
}: {
  transaction: AggregateTransactionDto;
}) => {
  const items = getTransactionLineItems({ details: transaction.details });

  return items.map((item, lineItemIndex) => (
    <Section key={`transaction-detail-${item.primaryId}-${lineItemIndex}`}>
      <InfoRowPair
        left={item.primaryTitle}
        right={formatMoneyFromInteger(item.primaryAmount)}
        textClasses="text-body-600 text-grey-900 font-semibold"
        truncate="left"
      />

      {item.additionalInfo.map((additionalItem, index) => (
        <InfoRowPair
          key={`transaction-detail-${item.primaryId}-item-${lineItemIndex}-additional-${index}`}
          left={additionalItem.title}
          right={formatMoneyFromInteger(additionalItem.amount)}
          textClasses="text-body-400 text-grey-600"
          truncate="left"
        />
      ))}

      <InfoRowPair
        left="Amount"
        right={formatMoneyFromInteger(item.totalAmount)}
        textClasses="text-body-400 text-grey-900"
      />
    </Section>
  ));
};

const Section = ({
  border = true,
  children,
}: PropsWithChildren<{ border?: boolean }>) => {
  const { View } = useGenericComponents();
  return (
    <View
      className={cn("flex-1 py-4 flex flex-col space-y-2", {
        "border-b border-grey-300 ": border,
      })}>
      {children}
    </View>
  );
};

const InfoRowPair = ({
  textClasses,
  left,
  leftClasses,
  right,
  rightClasses,
  truncate = "none",
}: {
  textClasses?: string;
  left: string;
  leftClasses?: string;
  right: string;
  rightClasses?: string;
  truncate?: "left" | "right" | "both" | "none";
}) => {
  const { Text, View } = useGenericComponents();
  const truncateLeft = ["left", "both"].includes(truncate);
  const truncateRight = ["right", "both"].includes(truncate);
  return (
    <View className="flex flex-row items-center justify-between space-x-4">
      <View className={truncateLeft ? "flex-1" : "flex-initial"}>
        <Text
          className={`${textClasses} ${leftClasses}`}
          truncate={truncateLeft}>
          {left}
        </Text>
      </View>

      <View className={truncateRight ? "flex-1" : "flex-initial"}>
        <Text
          className={`text-right ${textClasses} ${rightClasses}`}
          truncate={truncateRight}>
          {right}
        </Text>
      </View>
    </View>
  );
};
