import { CustomerLayout } from "@/modules/customer/CustomerLayout";

export const CustomerEnrol = ({ id }: { id: string }) => {
  return (
    <CustomerLayout>
      <h2 className="mb-5 text-heading2-400">Enrol on class {id}</h2>
      <div
        className="bg-blue-400"
        style={{
          height: "1000px",
        }}>
        Big content
      </div>
    </CustomerLayout>
  );
};
