import { useEnrolmentsDatatable } from "shared/components";
import { useEnrolmentsActions } from "shared/components";
import {
  getEnrolmentEndDate,
  getEnrolmentStartDate,
  useEnrolmentsData,
} from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Tabs } from "@/modules/common/nav/Tabs";
import { Button } from "@/modules/common/ui/button/Button";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import {
  Breadcrumb,
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

export const EnrolmentsPage = ({ classId }: { classId: string }) => {
  const actions = useEnrolmentsActions();

  const { course, currentFilter, season, tabItems } =
    useEnrolmentsData(classId);

  const { datatable } = useEnrolmentsDatatable({
    Datatable,
    api,
    classId,
    filter: currentFilter,
    goToStudent: id => Router.push("StudentDetails", { id }),
    showCreateForm: () => actions.showCreateForm({ course, season }),
    showUpdateStartForm: item =>
      actions.showUpdateStartForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentStartDate(item),
      }),
    showUpdateEndForm: item =>
      actions.showUpdateEndForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentEndDate(item),
      }),
    deleteOne: actions.deleteOne,
  });

  if (!course || !season) {
    return <LoadingSpinnerDark />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: "Seasons",
      onPress: () => Router.push("SeasonList"),
    },
    {
      text: season.name,
      onPress: () => Router.push("SeasonDetails", { id: season.id }),
    },
    {
      text: course.entity.name,
      onPress: () => Router.push("ClassDetails", { id: classId }),
    },
    {
      text: "Enrollments",
    },
  ];

  return (
    <CompanyLayout datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Enrollments"} />
        <Button
          text={"Enroll"}
          size="sm"
          onClick={() => actions.showCreateForm({ course, season })}
          variant="brand"
        />
      </CompanyTitleBar>
      <Tabs items={tabItems} />
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};
