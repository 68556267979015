import { useContext } from "react";

import { CourseSearchContext } from "./courseSearchContext";

export const useCourseSearch = (): CourseSearchContext => {
  const context = useContext(CourseSearchContext);
  if (!context) {
    throw new Error(
      "useCourseSearch must be used within a CourseSearchProvider",
    );
  }
  return context;
};
