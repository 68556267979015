import { createContext } from "react";

import { BasketContents, BasketMethods } from "../../basket/basket";

export type BasketContext = Omit<
  BasketMethods,
  "getContents" | "getCount" | "setAccountId"
> & {
  contents: BasketContents;
  count: number;
};

export const BasketContext = createContext(
  undefined as unknown as BasketContext,
);
