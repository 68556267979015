import { useState } from "react";

import { cn, TabsProps } from "shared/lib";

import { Chip } from "@/modules/common/ui/chip/Chip";

export const Tabs = ({ initialTab, items }: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState(initialTab ?? 0);
  return (
    <nav className="flex w-full flex-row items-end gap-x-3 border-b border-grey-300 px-5 md:px-8">
      {items.map((item, index) => (
        <button
          key={index}
          onClick={() => {
            setActiveIndex(index);
            item.onClick();
          }}
          className={cn(
            `-mb-[1px] flex flex-row items-center gap-x-2 border-b-2 p-2 text-label-400 text-grey-900`,
            activeIndex === index ? "border-grey-900" : "border-transparent",
          )}>
          <span>{item.label}</span>
          {item.chip && (
            <Chip
              label={item.chip.label}
              size="sm"
              variant={item.chip.variant}
            />
          )}
        </button>
      ))}
    </nav>
  );
};
