import { formatDateTime } from "shared/lib";

import { appVersion } from "@/lib/appVersion";
import { FooterLinks } from "@/modules/common/layout/FooterLinks";

const now = new Date();

export const FamilyFooter = () => {
  return (
    <div className="hidden flex-row items-center justify-between px-8 pb-9 pt-10 text-label-400 text-grey-500 md:flex">
      <span className="flex w-48 flex-col gap-y-1">
        <span>© Class Manager {formatDateTime(now, "year")}</span>
        <span>{appVersion}</span>
      </span>

      <span className="flex flex-col items-end gap-x-1 gap-y-1 lg:flex-row">
        <FooterLinks />
      </span>
    </div>
  );
};
