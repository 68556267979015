import { IconName } from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";

export const SummaryCard = ({
  onCardClick,
  onAddClick,
  icon,
  text,
  footer,
}: {
  onCardClick(): void;
  onAddClick(): void;
  icon: IconName;
  text: string;
  footer?: string;
}) => {
  return (
    <BaseCard
      onClick={onCardClick}
      headerRightSlot={
        <IconButton
          className={"-mr-2 -mt-2"}
          icon={"addCircleOutline"}
          variant={"standard"}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onAddClick();
          }}
        />
      }
      icon={icon}
      description={text}
      descriptionSize={16}
      descriptionColor={"grey-900"}
      footerSlot={
        footer && <p className={"text-label-400 text-grey-600"}>{footer}</p>
      }
    />
  );
};
