import { cn } from "../../../../cssUtils";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const TransactionItem = ({
  description,
  total,
  discount = false,
}: {
  description: string;
  total: number;
  discount?: boolean;
}) => {
  const { View, Text } = useGenericComponents();
  return (
    <View className={"flex flex-row justify-between"}>
      <Text
        truncate={true}
        className={cn("text-body-400 flex-shrink", {
          "text-grey-900": !discount,
          "text-grey-600": discount,
        })}>
        {description}
      </Text>
      <Text
        className={cn("text-body-400", {
          "text-grey-900": !discount,
          "text-grey-600": discount,
        })}>
        {formatMoneyFromInteger(total)}
      </Text>
    </View>
  );
};
