import { cn } from "shared/lib";

import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { FooterLinks } from "@/modules/common/layout/FooterLinks";

interface Props {
  variant?: "default" | "brand" | "classmanager";
}

export const NonAuthWebFooter = ({ variant }: Props) => {
  return (
    <footer
      className={cn(
        "flex items-center justify-between px-8 py-5 text-white",
        { "bg-brand": variant === "brand" },
        { "bg-grey-900": variant === "default" },
        { "bg-purple-900": variant === "classmanager" },
      )}>
      <span>
        <ClassManagerLogo color="white" height="32px" />
      </span>
      <div className="text-body-400 text-white">
        <FooterLinks />
      </div>
    </footer>
  );
};
