import { useFormActions } from "shared/components";

import { FormFooter } from "@/modules/common/form/FormFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";

type UpdateFooterProps = {
  saveButtonText?: string;
};

export const UpdateFooter = ({
  saveButtonText = "Save",
}: UpdateFooterProps) => {
  const { isLoading, submit, setShouldCloseOnSuccess, isSubmitDisabled } =
    useFormActions();
  const { closeSheet } = useSheet();

  return (
    <FormFooter>
      <Button text={"Cancel"} variant="secondary" onClick={closeSheet} />
      <Button
        variant={"brand"}
        text={saveButtonText}
        disabled={isLoading || isSubmitDisabled}
        loading={isLoading}
        onClick={() => {
          setShouldCloseOnSuccess(true);
          submit();
        }}
      />
    </FormFooter>
  );
};
