import { BillDto } from "@justraviga/classmanager-sdk";

export interface Tax {
  description: string;
  amount: number;
}

const quoteSubTotal = (transactions: Array<BillDto>): number =>
  transactions?.reduce((acc, transaction) => {
    return acc + transaction.subtotal;
  }, 0) ?? 0;

const quoteTotal = (transactions: Array<BillDto>): number =>
  transactions?.reduce((acc, transaction) => {
    return acc + transaction.total;
  }, 0) ?? 0;

const quoteDiscounts = (transactions: Array<BillDto>): number =>
  transactions?.reduce((acc, transaction) => {
    return acc + transaction.discountTotal;
  }, 0) ?? 0;

// const quoteTaxes = (transactions: Array<BillDto>): Tax[] =>
//   transactions?.reduce(
//     (acc: { description: string; amount: number }[], transaction) => {
//       for (const transactionTax of transaction.taxes) {
//         const tax = acc.find(
//           tax => tax.description === transactionTax.description,
//         );
//         if (tax) {
//           tax.amount += transactionTax.amount;
//         } else {
//           acc.push(transactionTax);
//         }
//       }
//       return acc;
//     },
//     [],
//   ) ?? [];

const quoteTax = (transactions: Array<BillDto>): number =>
  transactions?.reduce((acc, transaction) => {
    return acc + transaction.taxTotal;
  }, 0) ?? 0;

export const quoteDetails = (
  transactions: Array<BillDto>,
): {
  total: number;
  discounts: number;
  tax: number;
  subTotal: number;
} => {
  return {
    total: quoteTotal(transactions),
    discounts: quoteDiscounts(transactions),
    tax: quoteTax(transactions),
    subTotal: quoteSubTotal(transactions),
  };
};
