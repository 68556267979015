import {
  LessonAdjustmentReason,
  LessonDto,
} from "@justraviga/classmanager-sdk";

type CourseId = string;
type LessonDate = string;
type LessonTIme = string;
export type LessonId = `${CourseId}|${LessonDate}|${LessonTIme}`;

export const makeLessonId = (
  courseId: string,
  date: string,
  time: string,
): LessonId => `${courseId}|${date}|${time}`;

export const resolveLessonId = (
  lessonId: LessonId,
): {
  courseId: string;
  date: string;
  time: string;
} => {
  const [courseId, date, time] = lessonId.split("|");

  return { courseId, date, time };
};

export const getCancelledAdjustment = (lesson: LessonDto) => {
  return lesson.adjustments.find(
    adjustment =>
      adjustment.reason === LessonAdjustmentReason.CancelledLesson &&
      adjustment.time === lesson.startTime,
  );
};
