import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import {
  tuitionFormMonthFormSteps,
  TuitionForMonthFormSchema,
  useTuitionForMonthForm,
} from "shared/components";
import { formatDateTime } from "shared/lib";

import { GenericForm } from "@/modules/common/form/GenericForm";
import { MultiStepsHeader } from "@/modules/common/steps/MultiStepsHeader";
import { BillingPeriodSummaryCard } from "@/modules/company/billing/transactions/forms/tuitionForMonth/BillingPeriodSummaryCard";
import { BillItemsList } from "@/modules/company/billing/transactions/forms/tuitionForMonth/BillItemsList";

export const TuitionForMonthForm = ({
  familyId,
  onFinalSuccess,
}: {
  familyId: string;
  onFinalSuccess: () => void;
}) => {
  const {
    formHook,
    step,
    setStep,
    onSuccess,
    submitFunction,
    selectedSeason,
    billingMonth,
    billData,
  } = useTuitionForMonthForm(familyId, onFinalSuccess);

  const canRenderPreview = step === 2 && selectedSeason && billingMonth;

  return (
    <div className={"flex flex-col space-y-4"}>
      <MultiStepsHeader currentStep={step} steps={tuitionFormMonthFormSteps} />
      {step === 1 && (
        <p className={"pb-5 text-body-400 text-grey-600"}>
          Preview tuition can be used to generate tuition for a single billing
          period, ensure all details are correct.
        </p>
      )}
      {canRenderPreview && (
        <BillingPeriodSummaryCard
          season={selectedSeason!}
          billingMonth={billingMonth!}
          onClick={() => setStep(1)}
        />
      )}
      <GenericForm<
        AggregateTransactionDto,
        TuitionForMonthFormSchema,
        TuitionForMonthFormSchema | AggregateTransactionDto
      >
        apiRequest={submitFunction}
        defaultValues={{
          billDate: formatDateTime(new Date(), "isoDate"),
        }}
        formDefinitionHook={() => formHook}
        onSuccess={onSuccess}
      />
      {billData && step === 2 && <BillItemsList billData={billData} />}
    </div>
  );
};
