import { EnrolmentAdjustmentReason } from "@justraviga/classmanager-sdk/dist/models/EnrolmentAdjustmentReason";

import { AttendanceRecord } from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { Chip } from "@/modules/common/ui/chip/Chip";

export const getAttendanceRecordChips = (record: AttendanceRecord) => {
  const chips = [];

  if (record.trial) {
    chips.push(
      <span>
        <Chip label={"Trial"} variant={"neutral"} size={"sm"} />
      </span>,
    );
  }

  if (record.adjustments) {
    const findMakeUpLesson = record.adjustments.find(
      adjustment =>
        adjustment.reason === EnrolmentAdjustmentReason.MakeUpLesson,
    );

    findMakeUpLesson &&
      chips.push(
        <span>
          <Chip label={"Make up"} variant={"neutral"} size={"sm"} />
        </span>,
      );
  }

  if (record.student.medicalInfo) {
    chips.push(
      <Tooltip
        trigger={
          <span>
            <Chip leftIcon={"medkitOutline"} variant={"info"} size={"sm"} />
          </span>
        }
        children={<span>{record.student.medicalInfo}</span>}
      />,
    );
  }

  if (!record.student.mediaConsent) {
    chips.push(
      <Tooltip
        trigger={
          <span>
            <Chip leftIcon={"cameraOff"} variant={"danger"} size={"sm"} />
          </span>
        }
        children={<span>No photo consent</span>}
      />,
    );
  }

  return chips;
};
