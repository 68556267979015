import {
  GlobalSearchStatus,
  useSearchDialog,
  useSearchStatus,
} from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";

export const SearchIconButton = () => {
  const { status } = useSearchStatus();
  const { handleOpenChange } = useSearchDialog();

  return status === GlobalSearchStatus.HIDDEN ? null : (
    <div className="md:hidden">
      <IconButton
        border="rounded"
        onClick={() => handleOpenChange(true)}
        icon="searchOutline"
        variant="secondary-fill"
      />
    </div>
  );
};
