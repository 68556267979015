import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const AvatarWithUpload = ({
  src,
  name,
  showUploadIcon,
  uploadAction,
}: {
  src: string | null;
  name: string;
  showUploadIcon: boolean;
  uploadAction: () => void;
}) => {
  return (
    <div className="relative">
      <button className={"cursor-pointer"} onClick={uploadAction}>
        <Avatar src={src ?? undefined} size="xl" name={name} />
      </button>
      {showUploadIcon && (
        <div className="absolute bottom-0 right-0">
          <IconButton
            icon="cameraOutline"
            size="xs"
            border="rounded"
            onClick={uploadAction}
          />
        </div>
      )}
    </div>
  );
};
