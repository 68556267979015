import { useContext } from "react";

import { FamilyDto, StaffDto } from "@justraviga/classmanager-sdk";

import { AuthenticatedAccountEntityContext } from "../components/AuthenticatedAccountEntityProvider";

export type AuthenticatedEntity = StaffDto | FamilyDto;

export const useAuthenticatedAccountEntity = () => {
  const context = useContext(AuthenticatedAccountEntityContext);
  if (!context) {
    throw new Error(
      "useAuthenticatedAccountEntity must be used within an AuthenticatedAccountEntityProvider",
    );
  }
  return context.entity;
};
