import { StripePaymentMethodDto } from "@justraviga/classmanager-sdk";

import { Chip } from "shared/components";
import {
  displayExpiryDate,
  displayObscuredLast4,
  hasPaymentMethodExpired,
  isCardMethod,
  isDefaultPaymentMethod,
} from "shared/lib";

import { CardTypeIcon } from "@/modules/common/payments/CardTypeIcon";
import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";

interface PaymentMethodCardProps {
  paymentMethod: StripePaymentMethodDto;
  familyId: string;
}

function useBuildPaymentMethodActions(
  paymentMethod: StripePaymentMethodDto,
  familyId: string,
) {
  const { deletePaymentMethod, setDefaultPaymentMethod } = usePaymentMethods();
  const isDefault = isDefaultPaymentMethod(paymentMethod);

  const deleteAction: ActionMenuItemProps = {
    leftIcon: "trashOutline",
    title: "Delete",
    destructive: true,
    onClick: () =>
      deletePaymentMethod({
        paymentMethodId: paymentMethod.id,
        familyId,
        last4: displayObscuredLast4(paymentMethod.data.last4),
      }),
  };

  const makeDefaultAction: ActionMenuItemProps = {
    title: paymentMethod.data.last4,
    group: {
      items: [
        {
          leftIcon: "swapHorizontal",
          title: "Make default",
          onClick: () =>
            setDefaultPaymentMethod({
              paymentMethodId: paymentMethod.id,
              familyId,
            }),
        },
      ],
    },
  };

  return isDefault ? [deleteAction] : [makeDefaultAction, deleteAction];
}

export const PaymentMethodCard = ({
  paymentMethod,
  familyId,
}: PaymentMethodCardProps) => {
  const isDefault = isDefaultPaymentMethod(paymentMethod);
  const actions = useBuildPaymentMethodActions(paymentMethod, familyId);

  if (isCardMethod(paymentMethod.data)) {
    const isExpired = hasPaymentMethodExpired(paymentMethod);

    return (
      <BaseCard
        title={
          <div className="flex flex-col items-start gap-1">
            <div className="flex flex-row items-center space-x-4">
              <div className="rounded border border-grey-300">
                <CardTypeIcon cardType={paymentMethod.data.brand} />
              </div>
              {isDefault && (
                <div>
                  <Chip label="Default" variant="primary" size="sm" />
                </div>
              )}
              {isExpired && (
                <div>
                  <Chip label="Expired" variant="danger" size="sm" />
                </div>
              )}
            </div>
            <p className="text-body-400 text-grey-900">
              {displayObscuredLast4(paymentMethod.data.last4)}
            </p>
            <p className="text-label-400 text-grey-600">
              Expires{" "}
              {displayExpiryDate(
                paymentMethod.data.expiryMonth,
                paymentMethod.data.expiryYear,
              )}
            </p>
          </div>
        }
        headerActions={actions}
      />
    );
  }

  return (
    <BaseCard
      title={
        <div className="flex flex-col items-start gap-1">
          Unexpected Payment Method
        </div>
      }
    />
  );
};
