import { DefaultValues, FormBuilder } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { useCurrencySymbol } from "../useCurrencySymbol";

export type AmountSchema = {
  amount: number;
};

const paymentAmountDefinition = (currencySign: string) => {
  return new FormDefinitionBuilder<AmountSchema>()
    .decimal("amount", {
      label: "Amount",
      required: true,
      prefix: currencySign,
      decimalPlaces: 2,
    })
    .getDefinition();
};

export const usePaymentAmountForm = (
  builder: FormBuilder,
  onSubmit: (data: AmountSchema) => void,
  defaultValues: DefaultValues<AmountSchema>,
) => {
  const currencySymbol = useCurrencySymbol();
  return builder(
    paymentAmountDefinition(currencySymbol),
    onSubmit,
    defaultValues,
  );
};
