import { FamilyDto, StudentDto } from "@justraviga/classmanager-sdk";

import { useData } from "@/lib/api/apiClient";
import { Button } from "@/modules/common/ui/button/Button";
import { DetailCard } from "@/modules/company/common/DetailCard";
import { EmergencyContactItem } from "@/modules/company/members/student/detail/EmergencyContactItem";

export const EmergencyContactCard = ({
  student,
  openContactSheet,
}: {
  student: StudentDto;
  familyId: FamilyDto["id"];
  openContactSheet?: () => void;
}) => {
  const contacts = useData("listContact", {
    where: { familyId: { equals: student.familyId } },
  });

  const emergencyContacts = contacts.data
    .filter(c => c.isEmergency)
    .sort(a => (a.isPrimary ? -1 : 1));

  return (
    <DetailCard
      title={"Emergency Contacts"}
      isFoldable={emergencyContacts.length > 0}
      showPlaceholder={emergencyContacts.length === 0}
      footerButton={
        !student.deletedAt && (
          <div>
            <Button
              className={"-mb-2 p-2"}
              variant={"tertiaryLight"}
              text={"Create new"}
              size={"xs"}
              onClick={() => openContactSheet && openContactSheet()}
            />
          </div>
        )
      }>
      <div className={"flex flex-col space-y-8"}>
        {emergencyContacts.map((contact, index) => (
          <EmergencyContactItem
            student={student}
            contact={contact}
            key={index}
          />
        ))}
      </div>
    </DetailCard>
  );
};
