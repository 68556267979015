import { useDatatable } from "shared/lib";

import { AlignedCell } from "@/modules/common/datatable/cell/AlignedCell";
import { CheckboxCellPlaceholder } from "@/modules/common/datatable/placeholders/CheckboxCellPlaceholder";
import { TextCellPlaceholder } from "@/modules/common/datatable/placeholders/TextCellPlaceholder";

export const DatatableHeaderPlaceholderRow = () => {
  const { datatable } = useDatatable();

  return (
    <>
      <tr
        className={
          "duration-800 border-b-1 animate-pulse border-grey-300 ease-out"
        }>
        {datatable.groupActions ? (
          <th
            key={`datatable-header-placeholder-checkbox`}
            className="px-4 py-3">
            <CheckboxCellPlaceholder />
          </th>
        ) : null}

        {datatable.columns?.map(column => {
          return (
            <th
              key={`datatable-header-placeholder-${column.label}`}
              className="px-4 py-3">
              <AlignedCell align={"left"}>
                <TextCellPlaceholder />
              </AlignedCell>
            </th>
          );
        })}

        {datatable.rowActions && <th></th>}
      </tr>
    </>
  );
};
