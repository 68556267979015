import {
  useClassWaitingListDatatable,
  useEnrolmentsActions,
  useWaitingListActions,
} from "shared/components";
import { useBreakpoint } from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import {
  Breadcrumb,
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  ResponsiveCompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

export const ClassWaitingListPage = ({ classId }: { classId: string }) => {
  const actions = useWaitingListActions();
  const enrolmentActions = useEnrolmentsActions();
  const { md: isDesktop } = useBreakpoint();

  const { data: course } = useApi("getCourse", { id: classId });

  const { data: season } = useApi(
    "getSeason",
    { id: course?.entity.seasonId ?? "" },
    {
      enabled: !!course,
    },
  );

  const { datatable } = useClassWaitingListDatatable({
    Datatable,
    api,
    classId,
    showAddForm: isDesktop
      ? () => actions.showCreateForm({ courseId: classId })
      : undefined,
    goToStudent: id => Router.push("StudentDetails", { id }),
    enrollForClass: item =>
      enrolmentActions.showCreateForm({
        course: item.class,
        studentIds: [item.studentId],
        showStudentSelector: false,
      }),
    deleteOne: actions.deleteOne,
  });

  if (!course || !season) {
    return <LoadingPlaceholder />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: "Seasons",
      onPress: () => Router.push("SeasonList"),
    },
    {
      text: season.name,
      onPress: () => {
        Router.push("SeasonDetails", { id: course.entity.seasonId });
      },
    },
    {
      text: course.entity.name,
      onPress: () => {
        Router.push("ClassDetails", { id: course.entity.id });
      },
    },
    {
      text: "Waiting list",
    },
  ];

  return (
    <CompanyLayout alwaysShowGlobalHeader={false} datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <ResponsiveCompanyTitleBarTitle title={"Waiting list"} />
        {!isDesktop && (
          <IconButton
            icon={"addOutline"}
            border={"rounded"}
            onClick={() => actions.showCreateForm({ courseId: classId })}
            variant={"secondary-fill"}
          />
        )}
      </CompanyTitleBar>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};
