import { createContext } from "react";

import { AccountPermission } from "@justraviga/classmanager-sdk";

export type PermissionContext = {
  permissions: string[];
  hasPermission: (permission: AccountPermission) => boolean;
};

export const PermissionContext = createContext<PermissionContext>({
  permissions: [],
  hasPermission: () => true,
});
