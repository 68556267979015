import * as React from "react";
import { useState } from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { cn } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

const BaseCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-4 w-4 shrink-0 rounded border-grey-400 disabled:cursor-not-allowed disabled:opacity-50",
      "data-[state=unchecked]:border data-[state=checked]:bg-grey-900 data-[state=indeterminate]:bg-grey-900",
      "data-[state=unchecked]:hover:border-grey-500",
      className,
    )}
    {...props}>
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-red-500")}>
      {props.checked === "indeterminate" && (
        <div className={"flex items-center justify-center"}>
          <Icon name={"removeOutline"} size={16} color={"#FFFFFF"} />
        </div>
      )}
      {props.checked === true && (
        <Icon name={"checkmarkSharp"} size={12} color={"white"} />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
BaseCheckbox.displayName = CheckboxPrimitive.Root.displayName;

export type CheckboxValues = boolean | "indeterminate";

export interface CheckboxWithTextProps {
  checked?: CheckboxValues;
  required?: boolean;
  title?: string;
  text?: React.ReactNode;
  name?: string;
  disabled?: boolean;
  onChange?: (v: CheckboxValues) => void;
  role?: string;
  clickableArea?: "md" | "lg";
}

export const Checkbox = ({
  checked,
  required,
  title,
  text,
  name,
  disabled,
  onChange,
  role = "checkbox",
  clickableArea = "md",
}: CheckboxWithTextProps) => {
  const [internalChecked, setInternalChecked] = useState(checked);
  return (
    <label
      className={cn(
        "flex cursor-pointer flex-row gap-x-4 text-label-400 text-grey-900",
        "peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
        clickableArea === "md"
          ? ""
          : "h-full w-full items-center justify-center",
      )}>
      <div
        className={cn(
          "-m-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded hover:bg-grey-200",
          clickableArea === "md" ? "-m-2" : "",
        )}>
        <BaseCheckbox
          role={role}
          name={name}
          disabled={disabled}
          checked={internalChecked}
          onCheckedChange={e => {
            setInternalChecked(e);
            if (onChange) {
              onChange(e);
            }
          }}
        />
      </div>
      {(title || text) && (
        <div className="flex flex-col gap-1 leading-none">
          {title && (
            <div className={"relative w-fit text-grey-900"}>
              {title}
              {required && (
                <p className={"absolute -right-3 top-0 text-red-600"}>*</p>
              )}
            </div>
          )}
          {text && (
            <p className="relative w-fit text-label-400 text-grey-600">
              {text}
              {required && !title && (
                <span className={"absolute -right-3 top-0 text-red-600"}>
                  *
                </span>
              )}
            </p>
          )}
        </div>
      )}
    </label>
  );
};
