import { AccountActions } from "shared/components";

import { CompanyTitleBarTitle } from "@/modules/company/CompanyLayout";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";

export const CustomerAccountPage = () => {
  return (
    <CustomerLayout>
      <CompanyTitleBarTitle title={"Account"} />
      <div className="mt-7">
        <AccountActions />
      </div>
    </CustomerLayout>
  );
};
