import { Link as RouterLink } from "@swan-io/chicane";

import { cn } from "shared/lib";

export const Link = ({
  to,
  text,
  className,
}: {
  to: string;
  text: string;
  className?: string;
}) => {
  const target = to.startsWith("http") ? "_blank" : undefined;
  return (
    <RouterLink
      to={to}
      className={cn(
        className,
        "text-grey-900 underline decoration-grey-900 underline-offset-4 hover:text-grey-600 hover:decoration-grey-600",
      )}
      target={target}>
      {text}
    </RouterLink>
  );
};
