import {
  AggregateTransactionDto,
  StripeCapturePaymentIntentRequest,
} from "@justraviga/classmanager-sdk";

import { showAlert } from "../alertState";
import { Api } from "../api";

export interface PaymentHooks {
  onSuccess: (result: AggregateTransactionDto) => void;
}

export async function capturePaymentIntent(
  api: Api,
  data: StripeCapturePaymentIntentRequest,
  hooks?: PaymentHooks,
) {
  const { onSuccess } = hooks || {};

  return api.stripe
    .stripeCapturePaymentIntent(data)
    .then(result => {
      onSuccess?.(result);
      return result;
    })
    .catch(e => {
      showAlert({
        content: `There was an error while taking payment: ${e.message}`,
      });
      return null;
    });
}
