import React from "react";

import { toastStyles, useToast } from "@/modules/common/toast/useToast";
import { Button } from "@/modules/common/ui/button/Button";

export interface ToasterProps {
  id: string;
  title: string;
  description: string;
  triggerLabel: string;
  actionLabel?: string;
  action?: () => void;
  children?: React.ReactNode;
  toastStyle?: keyof typeof toastStyles;
  duration?: number;
}

export const Toaster = ({
  id,
  title,
  description,
  triggerLabel,
  actionLabel,
  action,
  duration = 10000,
  toastStyle = "secondary",
}: ToasterProps) => {
  const { toast } = useToast();

  return (
    <Button
      text={triggerLabel}
      variant={toastStyle}
      onClick={() => {
        toast({
          id: id,
          title: title,
          description: description,
          action: action,
          actionLabel: actionLabel,
          duration,
        });
      }}
    />
  );
};
