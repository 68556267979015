import { CompanyDto } from "@justraviga/classmanager-sdk";

import { useGenericComponents } from "../../GenericComponentsProvider";
import { CompanyLogoComponent } from "../../interfaces";

export const EmailCompanyHeader = ({
  company,
  CompanyLogo,
}: {
  company: CompanyDto;
  CompanyLogo: CompanyLogoComponent;
}) => {
  const { View, Text } = useGenericComponents();

  return (
    <View className={"flex flex-col space-y-6"}>
      {company.logo && <CompanyLogo logo={company.logo} size={"md"} />}
      <Text className={"text-heading6-400"}>{company?.name}</Text>
    </View>
  );
};
