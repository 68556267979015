import { useMemo } from "react";

import {
  AccountPermission,
  CustomFieldEntity,
  FamilyDto,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import {
  InvitationWidget,
  RecentTransactionsCard,
  useStripeStatus,
} from "shared/components";
import { CustomFieldResponsesCard } from "shared/components";
import { IconName, useBreakpoint, WebActionMenuProps } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { AddressCard } from "@/modules/company/addresses/AddressCard";
import { useTransactionActions } from "@/modules/company/billing/transactions/useTransactionActions";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { ClickableNavItem } from "@/modules/company/common/details/ClickableNavItem";
import { NavItemContainer } from "@/modules/company/common/details/NavItemContainer";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { CustomFieldResponsesContent } from "@/modules/company/customFields/entityResponses/card/CustomFieldResponsesContent";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";
import { Profile } from "@/modules/company/members/family/detail/Profile";
import { FamilyStudentGrid } from "@/modules/company/members/family/FamilyStudentGrid";
import { useFamilyActions } from "@/modules/company/members/family/useFamilyActions";
import { Router } from "@/routing/router";

export const FamilyDetailsPage = ({ id }: { id: FamilyDto["id"] }) => {
  return (
    <CompanyLayout
      alwaysShowGlobalHeader={true}
      permission={AccountPermission.Familyread}>
      <FamilyDetailsPageContent id={id} />
    </CompanyLayout>
  );
};

export const FamilyDetailsPageContent = ({ id }: { id: FamilyDto["id"] }) => {
  const studentActions = useStudentActions();
  const familyActions = useFamilyActions();
  const customFieldActions = useCustomFieldActions();
  const transactionActions = useTransactionActions();
  const { protector: stripeProtector } = useStripeStatus();
  const breakpoints = useBreakpoint();
  const isSmallScreen = !breakpoints.md;

  const { data: family } = useApi("getFamily", { id });

  const { data: students } = useApi(
    "listStudent",
    {
      where: { familyId: { equals: family?.id } },
      selectAll: true,
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
    },
    { enabled: !!family },
  );

  const { data: contacts } = useApi(
    "listContact",
    {
      where: { familyId: { equals: family?.id } },
      selectAll: true,
    },
    { enabled: !!family },
  );

  // must have exactly 1 primary contact, except when contacts haven't loaded yet 🙄
  const primaryContact = (contacts?.data ?? []).find(c => c.isPrimary);

  const actions = useMemo(() => {
    const items: WebActionMenuProps["items"] = [];

    if (family === undefined || primaryContact === undefined) {
      return items;
    }

    items.push({
      title: family.name,
      group: {
        items: [
          ...(isSmallScreen
            ? [
                {
                  title: "Edit",
                  leftIcon: "createOutline" as IconName,
                  onClick: () =>
                    familyActions.showUpdateForm(family, { primaryContact }),
                  permission: AccountPermission.Familyupdate,
                },
              ]
            : []),
          ...(family.invitationStatus === "not_invited"
            ? [
                {
                  title: "Invite to Class Manager",
                  leftIcon: "sendOutline" as IconName,
                  onClick: () => {
                    familyActions.showInviteForm(family, {
                      firstname: primaryContact.firstname,
                      lastname: primaryContact.lastname as string | undefined, // ContactDto has this as string|null
                      email: primaryContact.email as string | undefined, // ContactDto has this as string|null
                    });
                  },
                  permission: AccountPermission.Familyinvite,
                },
              ]
            : []),
        ],
      },
    });

    items.push(
      {
        title: "Tuition & fees",
        group: {
          items: [
            {
              title: "Preview & generate tuition",
              onClick: () =>
                transactionActions.showTuitionForMonthForm(family.id),
              leftIcon: "eyeOutline",
              permission: AccountPermission.Billread,
            },
            {
              title: "Generate unposted tuition",
              onClick: () =>
                transactionActions.showUnpostedTuitionForm(family.id),
              leftIcon: "schoolOutline",
              permission: AccountPermission.TransactionactioncreateFromSeason,
            },
          ],
        },
      },
      {
        title: "Billing",
        group: {
          items: [
            {
              title: "Add custom transaction",
              onClick: () =>
                transactionActions.showCustomTransactionCreateForm(),
              leftIcon: "cashOutline",
              permission: AccountPermission.TransactionactioncreateCustom,
            },
            // todo:
            //   {
            //     title: "Charge card on file",
            //     onClick: () => transactionActions,
            //     leftIcon: "cardOutline",
            //   },
            {
              title: "Record payment",
              onClick: () =>
                transactionActions.showRecordPaymentForm({
                  familyId: family.id,
                }),
              protector: stripeProtector,
              leftIcon: "receiptOutline",
              permission: AccountPermission.TransactionactioncreatePayment,
            },
          ],
        },
      },
      {
        title: family.archivedAt ? "Restore" : "Archive",
        leftIcon: family.archivedAt ? "syncOutline" : "archiveOutline",
        destructive: true,
        hasDestructiveStyle: false,
        onClick: () => {
          family.archivedAt
            ? familyActions.unarchive(family)
            : familyActions.archive(family);
        },
        permission: AccountPermission.Familyarchive,
      },
      {
        title: "Delete",
        destructive: true,
        leftIcon: "trashOutline",
        onClick: () => {
          familyActions.deleteOne(family).then(() => Router.push("FamilyList"));
        },
        permission: AccountPermission.Familydelete,
      },
    );

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen, family, familyActions, primaryContact]);

  if (
    family === undefined ||
    students === undefined ||
    contacts === undefined ||
    primaryContact === undefined
  ) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  const crumbs = [
    {
      text: "Families",
      onPress: () => {
        Router.push("FamilyList");
      },
    },
    {
      text: family?.name,
    },
  ];

  return (
    <DetailPageLayout
      isDesktop={breakpoints.md}
      archivedEntityName={"family"}
      restoreAction={() => familyActions.unarchive(family)}
      editAction={() =>
        familyActions.showUpdateForm(family, { primaryContact })
      }
      actions={actions}
      crumbs={crumbs}
      isArchived={family.archivedAt !== null}
      permissions={{
        edit: AccountPermission.Familyupdate,
      }}
      header={
        <Profile
          family={family}
          contacts={contacts.data}
          students={students.data}
          isDesktop={breakpoints.md}
          uploadAction={() => familyActions.showAvatarUploadSheet(family)}
        />
      }>
      <FamilyStudentGrid
        students={students}
        createStudentWithFamily={() =>
          studentActions.showCreateWithFamilyForm(family)
        }
      />
      <DetailPageGrid>
        <DetailPageGridLeftColumn>
          <RecentTransactionsCard
            family={family}
            useApi={useApi}
            LoadingPlaceholder={LoadingPlaceholder}
            BaseCard={BaseCard}
            showPreview={transactionActions.showPreview}
            goToAccountStatement={familyId =>
              Router.push("AccountStatement", { familyId })
            }
          />
        </DetailPageGridLeftColumn>
        <DetailPageGridRightColumn>
          <InvitationWidget
            invitationStatus={family.invitationStatus}
            getLinkData={() => familyActions.getLinkData(family)}
            getUserData={() => familyActions.getUserData(family)}
            sendInvite={() => {
              return familyActions.showInviteForm(family, {
                firstname: primaryContact.firstname,
                lastname: primaryContact.lastname as string | undefined,
                email: primaryContact.email as string | undefined,
              });
            }}
            cancelInvite={() => familyActions.cancelInvite(family)}
            revokeAccess={() => familyActions.revokeAccess(family)}
            accountPermission={AccountPermission.Familyinvite}
            ActionMenu={({ items }) => (
              <ActionMenu
                items={items}
                trigger={
                  <IconButton
                    icon={"ellipsisHorizontal"}
                    variant={"standard"}
                    size={"xs"}
                  />
                }
              />
            )}
          />
          <NavItemContainer>
            <ClickableNavItem
              text={"Payment methods"}
              iconName={"card"}
              onClick={() =>
                Router.push("FamilyPaymentMethods", {
                  familyId: family.id,
                })
              }
            />
            <ClickableNavItem
              text={"Contacts"}
              iconName={"callSharp"}
              onClick={() =>
                Router.push("ContactsList", {
                  id: family?.id,
                })
              }
            />
            <ClickableNavItem
              text={"Waivers & policies"}
              iconName={"documentText"}
              onClick={() =>
                Router.push("CompanyFamilyWaivers", {
                  familyId: family.id,
                })
              }
            />
          </NavItemContainer>

          <div className={"w-full"}>
            <CustomFieldResponsesCard
              entityId={family.id}
              defaultEntity={CustomFieldEntity.Family}
              CustomFieldResponsesContent={CustomFieldResponsesContent}
              customFieldActions={customFieldActions}
            />
          </div>

          <AddressCard entityId={family.id} />
        </DetailPageGridRightColumn>
      </DetailPageGrid>
    </DetailPageLayout>
  );
};
