import { TransactionPrimaryType } from "./transactionTypes";
import { IconName } from "../availableIcons";
import { colors } from "../colors";
import { AggregateTransactionWithPrimaryType } from "../transactionUtils";

const { grey, green, red, white } = colors;

const transactionIcons: Record<TransactionPrimaryType, IconName> = {
  creditNote: "addCircle",
  fee: "school",
  manualDebit: "removeCircle",
  payment: "card",
  paymentFailed: "card",
  paymentFailedReversal: "card",
  refund: "returnDownBack",
  unknown: "informationCircle",
};

export function getTransactionIcon(type: TransactionPrimaryType): IconName {
  return transactionIcons[type];
}

export const getBalanceColor = (amount: number) =>
  amount === 0 ? grey[900] : amount > 0 ? green[600] : red[600];

type IconColor = string;
type IconBgColor = string;

const transactionIconColors: Record<
  TransactionPrimaryType,
  [IconColor, IconBgColor]
> = {
  creditNote: [white, green[600]],
  fee: [grey[800], grey[200]],
  manualDebit: [grey[800], grey[200]],
  payment: [white, green[600]],
  paymentFailed: [white, red[600]],
  paymentFailedReversal: [grey[800], grey[200]],
  refund: [grey[800], grey[200]],
  unknown: [grey[800], grey[200]],
};

export const getTransactionIconColors = (
  transaction: AggregateTransactionWithPrimaryType,
) => {
  return transactionIconColors[transaction.primaryType];
};

const transactionHeadingColors: Record<TransactionPrimaryType, string> = {
  creditNote: green[600],
  fee: grey[900],
  manualDebit: grey[900],
  payment: green[600],
  paymentFailed: red[600],
  paymentFailedReversal: grey[600],
  refund: grey[900],
  unknown: grey[900],
};

export const getTransactionHeadingColor = (type: TransactionPrimaryType) => {
  return transactionHeadingColors[type];
};

const transactionAmountColors: Record<TransactionPrimaryType, string> = {
  creditNote: green[600],
  fee: grey[900],
  manualDebit: grey[900],
  payment: green[600],
  paymentFailed: grey[600],
  paymentFailedReversal: grey[600],
  refund: grey[900],
  unknown: grey[900],
};

export const getTransactionAmountColor = (type: TransactionPrimaryType) => {
  return transactionAmountColors[type];
};
