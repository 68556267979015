import { useState } from "react";

import {
  CreateStaffRequest,
  StaffPermissionSet,
  UpdateStaffRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

type CreateSchema = CreateStaffRequest;
type UpdateSchema = UpdateStaffRequest;
type Action = "create" | "update";

const useDefinition = <Mode extends Action>({ mode }: { mode: Mode }) => {
  const [isInvite, setIsInvite] = useState(false);

  // This is a small hack to allow us to specify all fields in the same place
  type Schema = CreateSchema & UpdateSchema;

  const builder = new FormDefinitionBuilder<Schema>()
    .group("Basic Information", ["firstname", "lastname", "email"])
    .group("Personal Information", ["jobTitle", "joinedAt"], {
      advanced: mode === "create",
    })
    .group("Contact Details", ["phone"], { advanced: mode === "create" })
    .text("firstname", { label: "First name", required: true })
    .text("lastname", { label: "Last name", required: true })
    .text("jobTitle", { label: "Job Title" })
    .text("email", {
      label: "Email",
      type: "email",
      // TODO: it gives warning: Cannot update a component (`StaffCreateForm`) while rendering a different component (`Group`).
      // TODO: To locate the bad setState() call inside `Group`
      required: isInvite,
      description:
        "Provide the email that your staff member will be using to access Class Manager.",
    })
    .switch("sendInvite", {
      label: "Send invitation to sign up for Class Manager",
      description:
        "An email and permissions level is required to send an invitation.",
    })
    .date("joinedAt", { label: "Joined Date" })
    .select("permissionSet", {
      label: "Permissions",
      required: isInvite,
      data: Object.values(StaffPermissionSet)
        .map(value => ({
          label: enumLabel("permissionSet", value),
          value,
        }))
        // TEMPORARY: only show 'Owner' role for initial release
        .filter(option => option.value === "owner"),
    })
    .text("phone", {
      label: "Phone number",
      type: "tel",
    })
    .conditional(["email"], ["sendInvite"], v => {
      if (mode === "create") {
        setIsInvite(v.sendInvite == true);
      }
      return true;
    });

  if (mode !== "create") {
    builder.without(["sendInvite"]);
  }

  return builder.getDefinition() as FormDefinition<
    Mode extends "create" ? CreateSchema : UpdateSchema
  >;
};

export const makeStaffCreateFormDefinition = () => () =>
  useDefinition({ mode: "create" });

export const makeStaffUpdateFormDefinition = () => () =>
  useDefinition({ mode: "update" });

export const makeStaffCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.staff.createStaff({
      createStaffRequest: formData,
    });
  };

export const makeStaffUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.staff.updateStaff({
      id,
      updateStaffRequest: formData,
    });
  };
