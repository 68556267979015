import React, { Suspense, useEffect } from "react";

import { QueryClientProvider } from "@tanstack/react-query";

import {
  AppcuesAdapter,
  AuthenticatedAccountEntityProvider,
  AuthStateProvider,
  FormActionsProvider,
  GenericComponentsProvider,
  PermissionProvider,
  SearchProvider,
  StripeProvider,
} from "shared/components";
import { queryClient } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { appcuesAdapter } from "@/lib/Appcues/appcuesAdapter";
import { setCompanyGlobals } from "@/lib/setCompanyGlobals";
import { stripeLoader } from "@/lib/stripe";
import { authState } from "@/modules/auth/authState";
import { genericComponents } from "@/modules/common/genericComponents";
import { ClarityIntegration } from "@/modules/common/integrations/ClarityIntegration";
import { NavProvider } from "@/modules/common/nav/contexts/navContext";
import { AlertProvider } from "@/modules/common/overlays/alert/AlertProvider";
import { AlertDialogProvider } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { SheetProvider } from "@/modules/common/overlays/dialog/context/SheetProvider";
import { searchConfig } from "@/modules/common/search/searchConfig";
import { ToasterProvider } from "@/modules/common/toast/ToasterProvider";
import { CenteredLoadingSpinner } from "@/modules/common/ui/CenteredLoadingSpinner";
import { LoadingSpinnerGrey } from "@/modules/common/ui/LoadingSpinnerGrey";
import { AccountSelectionDialogProvider } from "@/modules/company/switcher/AccountSelectionDialogProvider";
import { AppRouter } from "@/routing/AppRouter";
import "./lib/platformSpecific";
import "@/lib/setup";

export const App = () => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    // On page load, see if we have a company set, and if so, set the default date/time format options
    authState.load().then(({ account }) => {
      if (account?.company?.settings) {
        setCompanyGlobals(account.company.settings);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <LoadingSpinnerGrey />
      </div>
    );
  }

  return (
    <React.StrictMode>
      <Suspense fallback={<CenteredLoadingSpinner />}>
        <AuthStateProvider authState={authState}>
          <GenericComponentsProvider {...genericComponents}>
            <NavProvider>
              <FormActionsProvider>
                <AlertProvider>
                  <ToasterProvider>
                    <AlertDialogProvider>
                      <QueryClientProvider client={queryClient}>
                        <AuthenticatedAccountEntityProvider>
                          <PermissionProvider>
                            <SearchProvider api={api} config={searchConfig}>
                              <StripeProvider stripeLoader={stripeLoader}>
                                <AccountSelectionDialogProvider>
                                  <SheetProvider>
                                    <ClarityIntegration />
                                    <AppcuesAdapter adapter={appcuesAdapter} />
                                    <AppRouter />
                                  </SheetProvider>
                                </AccountSelectionDialogProvider>
                              </StripeProvider>
                            </SearchProvider>
                          </PermissionProvider>
                        </AuthenticatedAccountEntityProvider>
                      </QueryClientProvider>
                    </AlertDialogProvider>
                  </ToasterProvider>
                </AlertProvider>
              </FormActionsProvider>
            </NavProvider>
          </GenericComponentsProvider>
        </AuthStateProvider>
      </Suspense>
    </React.StrictMode>
  );
};
