import { CountryCode, Timezone } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "./platformSpecific";

export type CountryCodeParam = Lowercase<
  (typeof CountryCode)[keyof typeof CountryCode]
>;

export const toCountryCodeEnum = (countryCode: CountryCodeParam) =>
  countryCode.toUpperCase() as CountryCode;

/**
 * Gets the user's timezone based on the browser's settings.
 * Default to UTC if we couldn't figure it out.
 */
export function getTimezone(): Timezone {
  const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return Object.values(Timezone).includes(timezoneString as Timezone)
    ? (timezoneString as Timezone)
    : Timezone.Utc;
}

/**
 * Attempt to derive the user's country code based on the browser's settings.
 * Default to US if we couldn't figure it out
 */
export function getCountryCode(): CountryCodeParam {
  const countryCodeString = Intl.DateTimeFormat()
    .resolvedOptions()
    .locale.split("-")[1];

  return (
    Object.values(CountryCode).includes(countryCodeString as CountryCode)
      ? countryCodeString
      : CountryCode.Us
  ).toLowerCase() as CountryCodeParam;
}

export function useOpenRegistrationUrl() {
  const { getEnv, openWebsite } = getPlatformFunctions();
  const baseUrl = getEnv("PUBLIC_URL") as string;
  const countryCode = getCountryCode();

  return () =>
    openWebsite(`${baseUrl.replace(/\/$/, "")}/${countryCode}/register`);
}
