import { cva } from "class-variance-authority";

import { ButtonVariant } from "shared/lib";

export const buttonStyles: Record<ButtonVariant, Array<string>> = {
  primary: [
    "bg-grey-900 enabled:hover:bg-grey-800 disabled:bg-grey-300 disabled:text-grey-500 text-white",
  ],
  secondary: [
    "bg-white border border-grey-400 disabled:text-grey-400 group-data-[state=open]:border-grey-900 text-grey-900",
    "aria-selected:bg-green-400 enabled:hover:bg-grey-100",
  ],
  tertiary: [
    "bg-transparent text-grey-900 disabled:text-grey-400 disabled:decoration-grey-400 underline underline-offset-4",
    "decoration-grey-900 enabled:hover:text-grey-600 enabled:hover:decoration-grey-600",
  ],
  tertiaryLight: [
    "text-grey-600 disabled:text-grey-400 disabled:decoration-grey-400",
    "underline decoration-grey-600 enabled:hover:decoration-grey-500 underline-offset-4 enabled:hover:text-grey-500",
  ],
  link: [
    "bg-transparent text-grey-900 disabled:text-grey-400 disabled:decoration-grey-400 underline underline-offset-4 px-0 py-0",
    "decoration-grey-900 enabled:hover:text-grey-600 enabled:hover:decoration-grey-600",
  ],
  linkLight: [
    "text-grey-600 disabled:text-grey-400 disabled:decoration-grey-400 px-0 py-0",
    "underline decoration-grey-600 enabled:hover:decoration-grey-500 underline-offset-4 enabled:hover:text-grey-500",
  ],
  subtle: ["text-grey-600 disabled:text-grey-400 enabled:hover:text-grey-500"],
  destructive: [
    "bg-red-600 disabled:bg-grey-300 disabled:text-grey-500 enabled:hover:bg-red-700 text-white",
  ],
  "destructive-outline": [
    "bg-white text-red-600 disabled:border-grey-400 disabled:text-grey-400 enabled:hover:bg-grey-100 border",
    "border-red-600",
  ],
  "destructive-tertiary": [
    "bg-transparent text-red-600 disabled:text-grey-400 disabled:decoration-grey-400 enabled:hover:text-red-500",
    "underline underline-offset-4 decoration-red-600 enabled:hover:decoration-red-500",
  ],
  "destructive-link": [
    "bg-transparent text-red-600 disabled:text-grey-400 disabled:decoration-grey-400 enabled:hover:text-red-500 px-0 py-0",
    "underline underline-offset-4 decoration-red-600 enabled:hover:decoration-red-500",
  ],
  brand: [
    `bg-brand-800 disabled:bg-grey-400 disabled:text-grey-500 enabled:hover:overlay-white-lighter`,
  ],
  classmanager: [
    `disabled:bg-grey-500 text-white disabled:text-white bg-purple-900 enabled:hover:overlay-white-lighter`,
  ],
  success: [
    `disabled:bg-grey-400 disabled:text-grey-500 bg-green-600 text-white enabled:hover:overlay-white-lighter`,
  ],
};

export const buttonVariants = cva(
  "inline-flex items-center px-4 whitespace-nowrap space-x-4 disabled:cursor-not-allowed justify-center text-base font-normal leading-[1.2] rounded",
  {
    variants: {
      isFullWidth: {
        true: "w-full",
        false: "",
      },
      size: {
        lg: "h-10 py-2 text-body-400",
        sm: "h-8 py-1",
        xs: "h-8 py-1 px-2 space-x-2 text-label-400",
      },
      variant: buttonStyles,
    },
  },
);
