import { DiscountSchemeDto } from "@justraviga/classmanager-sdk";

import {
  DiscountSchemeCreateFormProps,
  useDiscountSchemeCreateForm,
} from "shared/components";
import { discountSchemeFormSteps } from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { MultiStepsHeader } from "@/modules/common/steps/MultiStepsHeader";
import { Banner } from "@/modules/common/ui/Banner";

export const DiscountSchemeCreateForm = ({
  onFinalSuccess,
  description,
}: DiscountSchemeCreateFormProps) => {
  const { formHook, step, submitFunction, onSuccess } =
    useDiscountSchemeCreateForm(useApi, api);

  return (
    <div className={"flex flex-col space-y-4"}>
      <MultiStepsHeader currentStep={step} steps={discountSchemeFormSteps} />
      <GenericForm
        apiRequest={submitFunction}
        defaultValues={{}}
        formDefinitionHook={() => formHook}
        onSuccess={record =>
          step === 3 ? onFinalSuccess(record as DiscountSchemeDto) : onSuccess()
        }
      />
      {step === 3 && (
        <Banner
          content={description}
          variant={"info"}
          itemPosition={"center"}
        />
      )}
    </div>
  );
};
