type RgbType = {
  r: number;
  g: number;
  b: number;
};

const isDarkColor = (bgRgb: RgbType): boolean => {
  return bgRgb.r * 0.299 + bgRgb.g * 0.587 + bgRgb.b * 0.114 > 150;
};

/**
 * Convert hex color code to an rgb object
 */
export function hexToRgb(bgHex: string): RgbType | null {
  const hex = bgHex.replace("#", "");

  if (!/^([0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/.test(hex)) {
    return null;
  }
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return { r, g, b };
}

type TextShade = "dark" | "light";

/**
 * Work out whether text should be rendered as light or dark on a given background color.
 */
const getContrastingTextShade = (bgHex: string | undefined): TextShade => {
  if (bgHex === undefined) {
    return "dark";
  }

  const bgRgb = hexToRgb(bgHex);

  if (bgRgb === null) {
    return "dark";
  }
  if (isDarkColor(bgRgb)) {
    return "dark";
  } else {
    return "light";
  }
};

/**
 * Returns the tailwind text color class that is most accessible on the given background color.
 */
export const getAccessibleTextColor = (bgHex: string | undefined) =>
  getContrastingTextShade(bgHex) === "dark" ? "text-grey-900" : "text-white";

export const getAccessibleColorClass = (bgHex: string | undefined) =>
  getContrastingTextShade(bgHex) === "dark" ? "grey-900" : "white";

export const getAccessibleHexColor = (bgHex: string | undefined) =>
  getContrastingTextShade(bgHex) === "dark" ? "#0D0D12" : "#FFFFFF";
