import { useStaffDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { InvitationStatusIcon } from "@/modules/company/common/details/profile/InvitationStatusIcon";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { useStaffActions } from "@/modules/company/staff/useStaffActions";
import { Router } from "@/routing/router";

export const StaffList = () => {
  const staffActions = useStaffActions();
  const { datatable } = useStaffDatatable({
    Datatable,
    api,
    archive: staffActions.archive,
    archiveMany: staffActions.archiveMany,
    deleteMany: staffActions.deleteMany,
    deleteOne: staffActions.deleteOne,
    invitationStatus: item => (
      <InvitationStatusIcon status={item.invitationStatus} />
    ),
    onRowClick: item => Router.push("StaffDetails", { id: item.id }),
    showCreateForm: staffActions.showCreateForm,
    showInviteForm: staffActions.showInviteForm,
    showUpdateForm: staffActions.showUpdateForm,
    unarchive: staffActions.unarchive,
  });
  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};
