import { useAuthState, useSharedContactActions } from "shared/components";
import { useCustomerContacts } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { CardContainerContent } from "@/modules/common/cardContainer/CardContainer";
import { CustomerCardPage } from "@/modules/common/cardContainer/CustomerCardPage";
import { CustomerContactCard } from "@/modules/customer/contacts/CustomerContactCard";

export const CustomerContactsList = () => {
  const { contacts, isLoading } = useCustomerContacts(useApi);
  const { showCreateForm } = useSharedContactActions();
  const { account } = useAuthState();

  return (
    <CustomerCardPage
      title="My contacts"
      primaryAction={{
        icon: "addOutline",
        title: "Create",
        onClick: () => showCreateForm(account?.entityId),
      }}
      isEmpty={false}
      isLoading={isLoading}>
      <CardContainerContent>
        {contacts.map(contact => (
          <CustomerContactCard key={contact.id} contact={contact} />
        ))}
      </CardContainerContent>
    </CustomerCardPage>
  );
};
