import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  PasswordInput,
  PasswordInputProps,
} from "@/modules/common/form/PasswordInput";

const options: Array<Option<PasswordInputProps>> = [
  {
    title: "Basic functionality",
    props: [{ label: "Enter your password", value: "secret" }],
  },
];

export const PasswordInputPage = () => {
  return (
    <>
      <DocWrapper
        title="Password Input"
        component={PasswordInput}
        options={options}
      />
    </>
  );
};
