import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CheckoutRightColumn = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { View } = useGenericComponents();
  return (
    <View className="w-full md:relative md:col-span-1 pb-8">{children}</View>
  );
};
