import { SeasonDto } from "@justraviga/classmanager-sdk";

import { SeasonStatusChip } from "shared/components";
import { getSeasonTimeFrame } from "shared/lib";

import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { useSeasonActions } from "@/modules/company/classPlanner/seasons/useSeasonActions";
import { DetailCard } from "@/modules/company/common/DetailCard";
import { Router } from "@/routing/router";

type SeasonCardProps = {
  season: SeasonDto;
};

export const SeasonCard = ({ season }: SeasonCardProps) => {
  const seasonActions = useSeasonActions();

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Edit",
      leftIcon: "createOutline",
      onClick: () => seasonActions.showUpdateForm(season),
    },
    {
      title: season.archivedAt ? "Restore" : "Archive",
      leftIcon: "archiveOutline",
      onClick: () =>
        season.archivedAt
          ? seasonActions.unarchive(season)
          : seasonActions.archive(season),
    },
    {
      title: "Delete",
      leftIcon: "trashOutline",
      onClick: () => seasonActions.deleteOne(season),
      destructive: true,
    },
  ];

  const openSeason = () => Router.push("SeasonDetails", { id: season.id });

  const { dateRange, weeksCount } = getSeasonTimeFrame(season);

  return (
    <DetailCard
      title={season.name}
      headerButton={
        <ActionMenu
          trigger={
            <IconButton
              size={"lg"}
              icon={"ellipsisHorizontal"}
              variant={"standard"}
            />
          }
          width={"w-80"}
          items={menuItems}
        />
      }
      onClick={openSeason}
      footer={
        <div className="flex justify-start p-2">
          <SeasonStatusChip season={season} />
        </div>
      }>
      <div className={"flex-grow p-2"}>
        <div className={"line-clamp-2 text-ellipsis whitespace-pre-wrap"}>
          <div className={"text-base font-normal"}>{dateRange}</div>
          <div className={"text-base font-normal"}>{weeksCount} weeks</div>
        </div>
      </div>
    </DetailCard>
  );
};
