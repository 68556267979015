import { cn, colors } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export const LessonAttendancesProgress = ({
  progress,
}: {
  progress: number;
}) => {
  return (
    <div className={"flex items-center space-x-2 "}>
      {progress === 100 && (
        <Icon name={"checkmarkCircle"} size={24} color={colors.green[600]} />
      )}
      <span
        className={cn("text-body-400 text-grey-900", {
          "text-body-600 text-green-800": progress === 100,
        })}>
        {progress}% complete
      </span>
    </div>
  );
};
