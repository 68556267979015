import { StripeRegion } from "@justraviga/classmanager-sdk";
import { loadStripe, Stripe } from "@stripe/stripe-js";

import { StripeConfig } from "./StripeContext";

interface StripeLoaderLoadParams {
  region: StripeRegion;
  connectAccountId: string;
}

export class StripeLoader {
  private stripeInstance: Stripe | null = null;
  private region: StripeRegion | null = null;
  private connectAccountId: string | null = null;
  private config: StripeConfig;

  constructor(config: StripeConfig) {
    this.config = config;
  }

  public async load({ region, connectAccountId }: StripeLoaderLoadParams) {
    if (
      this.region === region &&
      this.connectAccountId === connectAccountId &&
      this.stripeInstance
    ) {
      return this.stripeInstance;
    }

    if (this.region !== region) {
      this.region = region;
    }

    if (this.connectAccountId !== connectAccountId) {
      this.connectAccountId = connectAccountId;
    }

    const apiKey = this.config.publishableKey[this.region];
    if (!apiKey) {
      throw new Error(`No API key found for ${this.region}`);
    }

    this.stripeInstance = await loadStripe(apiKey, {
      stripeAccount: this.connectAccountId,
    });
    return this.stripeInstance;
  }
}
