import {
  DiscountSchemeDto,
  DiscountSchemeValueDto,
} from "@justraviga/classmanager-sdk";

import {
  EntityActions,
  useSharedDiscountSchemeTierActions,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { DiscountSchemeTierCreateForm } from "@/modules/company/billing/discountSchemes/tiers/DiscountSchemeTierCreateForm";
import { DiscountSchemeTierUpdateForm } from "@/modules/company/billing/discountSchemes/tiers/DiscountSchemeTierUpdateForm";

type Model = DiscountSchemeValueDto;

type ImplementedActions = Pick<
  EntityActions<Model>,
  "deleteOne" | "showCreateForm" | "showUpdateForm"
>;
export const useDiscountSchemeTierActions = (): ImplementedActions => {
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "discountSchemeValue",
  });
  const sharedActions = useSharedDiscountSchemeTierActions(defaultActions, api);

  return {
    ...sharedActions,
    showCreateForm: ({
      discountScheme,
      quantity,
    }: {
      discountScheme: DiscountSchemeDto;
      quantity: number;
    }) =>
      defaultActions.showCreateForm({
        title: "Add discount tier",
        form: (
          <DiscountSchemeTierCreateForm
            discountScheme={discountScheme}
            quantity={quantity}
            onCreateSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),
    showUpdateForm: (model: Model, discountScheme: DiscountSchemeDto) =>
      defaultActions.showUpdateForm({
        title: "Edit discount tier",
        form: (
          <DiscountSchemeTierUpdateForm
            model={model}
            discountScheme={discountScheme}
            onUpdateSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),
  };
};
