import { PropsWithChildren, useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
} from "@justraviga/classmanager-sdk";

import { BasketContext } from "./basketContext";
import {
  Basket,
  BasketContents,
  BasketStorageAdaptor,
  makeEmptyBasketContents,
  TrialKey,
} from "../../basket/basket";
import { GetQuoteFn } from "../../basket/basketUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { useAuthState } from "../AuthStateProvider";

export const BasketProvider = ({
  children,
  adaptor,
}: PropsWithChildren<{ adaptor: BasketStorageAdaptor }>) => {
  const [basket, setBasket] = useState<Basket | null>(null);

  const { account } = useAuthState();
  const { api } = getPlatformFunctions();

  useEffect(
    () => {
      const initBasket = () => Basket.create(adaptor, account?.id ?? null);
      if (basket) {
        basket.setAccountId(account?.id ?? null);
        setContents(basket.getContents());
      } else {
        initBasket().then(b => {
          setBasket(b);
          setContents(b.getContents());
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account?.id, adaptor, api.basket],
  );

  const [contents, setContents] = useState<BasketContents>(
    basket?.getContents() ?? makeEmptyBasketContents(),
  );

  if (!basket) {
    return null;
  }

  const updateContents = () => {
    setContents({ ...basket.getContents() });
  };

  // Wrap all mutating basket methods so that we can trigger re-renders when changes in state occur
  // Non-mutating methods can come directly from the Basket class
  const { hasEnrolment, hasTrial } = basket;

  const basketMethods: BasketContext = {
    addEnrolment: (courseId: string) => {
      basket.addEnrolment(courseId);
      updateContents();
    },
    addStudentToEnrolment: (courseId: string, studentId: string) => {
      basket.addStudentToEnrolment(courseId, studentId);
      updateContents();
    },
    clearStudentsFromEnrolment: (courseId: string) => {
      basket.clearStudentsFromEnrolment(courseId);
      updateContents();
    },
    addStudentToTrial: (trial, studentId) => {
      basket.addStudentToTrial(trial, studentId);
      updateContents();
    },
    clearStudentsFromTrial: (trialId: TrialKey) => {
      basket.clearStudentsFromTrial(trialId);
      updateContents();
    },
    addTrial: trial => {
      basket.addTrial(trial);
      updateContents();
    },
    clear: () => {
      basket.clear();
      updateContents();
    },
    contents,
    count: basket.getCount(),
    hasEnrolment: hasEnrolment.bind(basket),
    hasTrial: hasTrial.bind(basket),
    removeEnrolment: courseId => {
      basket.removeEnrolment(courseId);
      updateContents();
    },
    removeTrial: trial => {
      basket.removeTrial(trial);
      updateContents();
    },
    getQuote: (getQuoteFn: GetQuoteFn) => basket.getQuote(getQuoteFn),
    getSuccessfulTransaction: () => basket.getSuccessfulTransaction(),
    setSuccessfulTransaction: (
      transactions: AggregateTransactionDto[],
      checkoutOperation: CheckoutOperationRequest,
    ) => {
      basket.setSuccessfulTransaction(transactions, checkoutOperation);
      updateContents();
    },
  };

  return (
    <BasketContext.Provider value={basketMethods}>
      {children}
    </BasketContext.Provider>
  );
};
