import { ColumnDef, Table } from "@tanstack/react-table";

import { cn, DatatableContentState, range, useDatatable } from "shared/lib";

import { ActionsCell } from "@/modules/common/datatable/cell/ActionsCell";
import { DatatableDefaultCell } from "@/modules/common/datatable/cell/DatatableDefaultCell";
import { DatatablePlaceholderRow } from "@/modules/common/datatable/rows/DatatablePlaceholderRow";

export const WebTableBody = <ModelDto,>({
  table,
  columns,
  contentState,
}: {
  table: Table<ModelDto>;
  columns: ColumnDef<ModelDto>[];
  contentState: DatatableContentState;
}) => {
  const { datatable } = useDatatable();

  const placeholdersRowsCount = datatable.placeholdersCount
    ? datatable.placeholdersCount
    : 1;

  if (contentState == DatatableContentState.Loading) {
    return range(placeholdersRowsCount).map(i => {
      return (
        <DatatablePlaceholderRow
          id={i}
          key={"placeholder-" + i}
          columns={columns}
        />
      );
    });
  }

  if (contentState === DatatableContentState.Loaded) {
    return table.getRowModel().rows.map((row, index, array) => {
      const visibleCells = row.getVisibleCells();
      return (
        <tr
          key={`row-${row.id}`}
          className={cn("h-full hover:bg-grey-100", {
            "border-b-[1px] border-grey-300": index !== array.length - 1,
          })}>
          {visibleCells.map((cell, i) => {
            const isRowActionsColumn =
              datatable.rowActions && i === visibleCells.length - 1;
            const isGroupActionsColumn = datatable.groupActions && i === 0;
            return isRowActionsColumn || isGroupActionsColumn ? (
              <ActionsCell cell={cell} key={cell.id} />
            ) : (
              <DatatableDefaultCell cell={cell} key={cell.id} />
            );
          })}
        </tr>
      );
    });
  }

  return <></>;
};
