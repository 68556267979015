import { ClassValue, clsx } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        {
          // Tailwind Merge doesn't know about our custom font size classes, so we need to tell it about them.
          // Otherwise, they get removed when we use them in combination with classes like 'text-green', as it
          // just sees two classes that both start with "text-" and removes the first one.
          text: [
            "heading1-600",
            "heading1-400",
            "heading2-600",
            "heading2-400",
            "heading3-600",
            "heading3-400",
            "heading4-600",
            "heading4-400",
            "heading5-600",
            "heading5-400",
            "heading6-600",
            "heading6-400",
            "body-600",
            "body-400",
            "label-600",
            "label-400",
            "caption-600",
            "caption-400",
          ],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]): string {
  return customTwMerge(clsx(inputs));
}
