import * as React from "react";
import { useEffect, useRef } from "react";

import { renderMarkdown } from "@/lib/utils";
import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  BaseAlertDialog,
} from "@/modules/common/overlays/alertDialog/AlertDialogContent";
import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { Button } from "@/modules/common/ui/button/Button";
import { buttonStyles } from "@/modules/common/ui/button/buttonVariants";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export interface AlertDialogProps {
  title: string;
  description: string;
  onResult: (result: boolean) => void;
  open: boolean;
  cancelText?: string;
  cancelStyle?: keyof typeof buttonStyles;
  confirmButtonText?: string;
  confirmButtonStyle?: keyof typeof buttonStyles;
}

export const AlertDialog = ({
  title,
  description,
  onResult,
  cancelText,
  cancelStyle,
  confirmButtonText,
  confirmButtonStyle,
  open,
}: AlertDialogProps): React.ReactNode => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const { closeAlert } = useAlertDialog();

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent): void {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        closeAlert();
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <BaseAlertDialog open={open}>
        <AlertDialogContent ref={dialogRef}>
          <AlertDialogHeader>
            <div className="flex items-center justify-between">
              <AlertDialogTitle className="text-grey-900">
                {title}
              </AlertDialogTitle>
              <span className="-mr-2 md:hidden lg:hidden">
                <IconButton
                  onClick={closeAlert}
                  variant="standard"
                  icon="closeOutline"
                  size="sm"
                  aria-label="Close"
                />
              </span>
            </div>
            <AlertDialogDescription className="break-words text-grey-600">
              {renderMarkdown(description)}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button
              onClick={() => {
                onResult(false);
                closeAlert();
              }}
              variant={cancelStyle || "secondary"}
              text={cancelText || "Cancel"}
            />
            <Button
              onClick={() => {
                onResult(true);
                closeAlert();
              }}
              variant={confirmButtonStyle || "destructive"}
              text={confirmButtonText || "Confirm"}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </BaseAlertDialog>
    </>
  );
};
