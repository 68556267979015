import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CheckoutLeftColumn = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { View } = useGenericComponents();
  return <View className="md:col-span-2 space-y-8">{children}</View>;
};
