import { useEffect } from "react";

import { match } from "ts-pattern";

import { authState } from "@/modules/auth/authState";
import { CustomerBillingPage } from "@/modules/customer/billing/CustomerBillingPage";
import { CustomerPaymentMethodsPage } from "@/modules/customer/billing/CustomerPaymentMethodsPage";
import { CustomerContactsList } from "@/modules/customer/contacts/CustomerContactsList";
import { CustomerAccountPage } from "@/modules/customer/CustomerAccountPage";
import { CustomerClasses } from "@/modules/customer/CustomerClasses";
import { CustomerDashboardPage } from "@/modules/customer/CustomerDashboardPage";
import { CustomerEnrol } from "@/modules/customer/CustomerEnrol";
import { FamilyMakeUpLessonsPage } from "@/modules/customer/makeUpLessons/FamilyMakeUpLessonsPage";
import { CustomerStudentsPage } from "@/modules/customer/students/CustomerStudentsPage";
import { FamilyTrialsPage } from "@/modules/customer/trials/FamilyTrialsPage";
import { FamilyWaitingListPage } from "@/modules/customer/waitingList/FamilyWaitingListPage";
import { CustomerWaiversPage } from "@/modules/customer/waivers/CustomerWaiversPage";
import { FamilyCourseListPage } from "@/modules/family/FamilyCourseListPage";
import { RouteNotFound } from "@/routing/RouteNotFound";
import { Router } from "@/routing/router";

export const CustomerRouter = () => {
  const route = Router.useRoute([
    "CustomerHome",
    "CustomerAccount",
    "CustomerClasses",
    "CustomerBilling",
    "CustomerPaymentMethods",
    "CustomerWaivers",
    "CustomerEnrol",
    "CustomerContacts",
    "CustomerStudents",
    "FamilyCourseList",
    "FamilyTrials",
    "FamilyWaitingList",
    "FamilyMakeUpLessons",
  ]);

  // Redirect the user if they're not logged in or not a family
  useEffect(() => {
    authState.load().then(({ account }) => {
      if (account?.role !== "family") {
        return Router.push("Home");
      }
    });
  }, []);

  return match(route)
    .with({ name: "CustomerHome" }, () => <CustomerDashboardPage />)
    .with({ name: "CustomerAccount" }, () => <CustomerAccountPage />)
    .with({ name: "CustomerClasses" }, ({ params: { id } }) => (
      <CustomerClasses id={id} />
    ))
    .with({ name: "CustomerEnrol" }, ({ params: { id } }) => (
      <CustomerEnrol id={id} />
    ))
    .with({ name: "CustomerWaivers" }, () => <CustomerWaiversPage />)
    .with({ name: "CustomerStudents" }, () => <CustomerStudentsPage />)
    .with({ name: "CustomerBilling" }, () => <CustomerBillingPage />)
    .with({ name: "CustomerPaymentMethods" }, () => (
      <CustomerPaymentMethodsPage />
    ))
    .with({ name: "CustomerContacts" }, () => <CustomerContactsList />)
    .with({ name: "FamilyCourseList" }, () => <FamilyCourseListPage />)
    .with({ name: "FamilyTrials" }, () => <FamilyTrialsPage />)
    .with({ name: "FamilyWaitingList" }, () => <FamilyWaitingListPage />)
    .with({ name: "FamilyMakeUpLessons" }, () => <FamilyMakeUpLessonsPage />)
    .otherwise(() => <RouteNotFound redirectTo="CustomerHome" />);
};
