import { DatatableContentState, useDatatable } from "shared/lib";

import { DatatableFilters } from "@/modules/common/datatable/common/DatatableFilters";
import { DatatableGroupActions } from "@/modules/common/datatable/common/DatatableGroupActions";
import { datatableCreateWebButtonProps } from "@/modules/common/datatable/utilities";
import { SearchInput } from "@/modules/common/form/SearchInput";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const WebHeader = ({
  contentState,
}: {
  contentState: DatatableContentState;
}) => {
  const { datatable, setSearch } = useDatatable();

  const showControls =
    datatable.hasSearch !== false &&
    (contentState === DatatableContentState.Loaded ||
      contentState === DatatableContentState.Loading ||
      contentState === DatatableContentState.NoResults);

  const additionalActions = datatable.additionalActions;

  const largeTitle =
    typeof datatable.title === "string" ? (
      <h5 className={"pb-6 text-heading5-600"}>{datatable.title}</h5>
    ) : (
      datatable.title
    );

  const smallTitle =
    typeof datatable.title === "string" ? (
      <span className={"mr-4 text-heading6-600"}>{datatable.title}</span>
    ) : (
      datatable.title
    );

  return (
    <div>
      {showControls && datatable.title ? largeTitle : null}
      <section className={"flex justify-between"}>
        <div className={"flex items-center justify-start"}>
          {!showControls && datatable.title && smallTitle}

          {showControls && (
            <div className={"mr-4"} data-testid="datatable-search-input">
              <SearchInput onChange={setSearch} />
            </div>
          )}
          {showControls && (
            <div>
              <DatatableFilters />
            </div>
          )}
        </div>

        <div className={"flex items-center"}>
          <SelectedRowsText />

          {showControls && <DatatableGroupActions />}

          {datatable.createAction &&
            contentState !== DatatableContentState.NoContent && (
              <div className={"ml-4 flex space-x-4"}>
                {additionalActions && (
                  <div className={"hidden md:flex"}>
                    <ActionMenu
                      width={"w-80"}
                      header={
                        additionalActions?.title && (
                          <h3 className={"text-heading6-600 text-grey-900"}>
                            {additionalActions.title}
                          </h3>
                        )
                      }
                      trigger={
                        <Button
                          text={"Actions"}
                          role="datatable-additional-actions"
                          rightIcon={"chevronDown"}
                          variant={"secondary"}
                          size={"sm"}
                        />
                      }
                      items={additionalActions.items.map(action => ({
                        title: action.title,
                        onClick: action.onClick,
                        protector: action.protector,
                        leftIcon: action.icon,
                        group: action.items
                          ? {
                              items: action.items.map(item => ({
                                title: item.title,
                                onClick: item.onClick,
                                leftIcon: item.icon,
                              })),
                            }
                          : undefined,
                      }))}
                    />
                  </div>
                )}

                <div className={"hidden md:flex"}>
                  <ProtectedOverlay permission={datatable.permissions?.create}>
                    <Button {...datatableCreateWebButtonProps(datatable)} />
                  </ProtectedOverlay>
                </div>

                <div className={"md:hidden"}>
                  <ProtectedOverlay permission={datatable.permissions?.create}>
                    <IconButton
                      icon={"addOutline"}
                      border={"rounded"}
                      onClick={datatable.createAction}
                      variant={"secondary-fill"}
                    />
                  </ProtectedOverlay>
                </div>
              </div>
            )}
        </div>
      </section>
    </div>
  );
};

const SelectedRowsText = () => {
  const { modelSelection } = useDatatable();
  const numberOfSelectedRows = modelSelection.count();

  if (numberOfSelectedRows === 0) {
    return null;
  }

  return (
    <div className={"px-4 text-base text-grey-900"}>
      <span className={""}>{numberOfSelectedRows.toString()} selected</span>
    </div>
  );
};
