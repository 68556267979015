import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { formatDate, formatMoneyFromInteger } from "../../intlFormatter";
import { getTransactionHeadingColor } from "../../transactions/transactionStyles";
import { getTransactionPrimaryType } from "../../transactionUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

interface TransactionPreviewHeaderProps {
  transaction: AggregateTransactionDto;
  isFamilyPortal: boolean;
}

export const TransactionPreviewHeader = ({
  transaction,
  isFamilyPortal = false,
}: TransactionPreviewHeaderProps) => {
  const { View, Text } = useGenericComponents();
  return (
    <View className={"flex flex-row justify-between"}>
      <View className="flex flex-col space-y-2">
        <View>
          <Text
            className="font-semibold text-heading6-600 text-grey-900"
            truncate={true}>
            {transaction.transaction.description}
          </Text>
        </View>
        <View className={"space-x-1 flex flex-row"}>
          {!isFamilyPortal ? (
            <>
              <Text className={"text-body-400 text-grey-600"}>Family</Text>
              <Text className={"text-body-400 text-grey-900"}>
                {transaction.transaction.familyName}
              </Text>
            </>
          ) : (
            <>
              <Text className={"text-body-400 text-grey-600"}>Date</Text>
              <Text className={"text-body-400 text-grey-900"}>
                {formatDate(transaction.transaction.date, "dayMonthYear")}
              </Text>
            </>
          )}
        </View>
      </View>

      <View>
        <Text
          className={"text-heading5-600"}
          style={{
            color: getTransactionHeadingColor(
              getTransactionPrimaryType(transaction),
            ),
          }}>
          {formatMoneyFromInteger(transaction.stats.total)}
        </Text>
      </View>
    </View>
  );
};
