import { SortSchema } from "@justraviga/classmanager-sdk";
import { useSuspenseQueries } from "@tanstack/react-query";

import { useBreakpoint } from "../breakpoints";
import { getPlatformFunctions } from "../platformSpecific";

export const useSeasonDetailsData = (id: string) => {
  const { api } = getPlatformFunctions();
  const { md: isDesktop } = useBreakpoint();

  // we want to make these queries in parallel
  const [
    { data: season },
    { data: pricingSchemeValues },
    { data: locations },
    { data: rooms },
    { data: staff },
  ] = useSuspenseQueries({
    queries: [
      {
        queryKey: ["getSeason", { id }],
        queryFn: () => {
          return api.seasons.getSeason({ id });
        },
      },
      {
        queryKey: ["listPricingSchemeValue", id],
        queryFn: () =>
          api.pricingSchemeValues.listPricingSchemeValue({
            where: {
              seasonId: {
                equals: id,
              },
            },
            sort: {
              quantity: SortSchema.Asc,
            },
            selectAll: true,
          }),
      },
      {
        queryKey: ["listLocation"],
        queryFn: () =>
          api.locations.listLocation({
            sort: { name: SortSchema.Asc },
            selectAll: true,
          }),
      },
      {
        queryKey: ["listRoom"],
        queryFn: () =>
          api.rooms.listRoom({
            selectAll: true,
          }),
      },
      {
        queryKey: ["listStaff"],
        queryFn: () =>
          api.staff.listStaff({
            sort: { firstname: SortSchema.Asc, lastname: SortSchema.Asc },
            selectAll: true,
          }),
      },
    ],
  });

  return {
    isDesktop,
    season,
    pricingSchemeValues: pricingSchemeValues.data,
    locations: locations.data,
    rooms: rooms.data,
    staff: staff.data,
  };
};
