import { TransactionAmountText } from "./TransactionAmountText";
import { TransactionDescriptionAndIcon } from "./TransactionDescriptionAndIcon";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { AggregateTransactionWithPrimaryType } from "../../transactionUtils";
import { ShowPreviewType } from "../actions/useSharedTransactionActions";
import { useGenericComponents } from "../GenericComponentsProvider";

interface TransactionSummaryCellProps {
  balance: number;
  transaction: AggregateTransactionWithPrimaryType;
  showPreview: ShowPreviewType;
  showFamilyName?: boolean;
}

export const TransactionSummaryCell = ({
  balance,
  transaction,
  showPreview,
  showFamilyName = true,
}: TransactionSummaryCellProps) => {
  const { Pressable, Text, View } = useGenericComponents();

  return (
    <Pressable
      className="-mx-2 flex cursor-pointer flex-row p-2 space-x-3 hover:bg-grey-100"
      onClick={() => showPreview(transaction)}>
      <View className="grow">
        <TransactionDescriptionAndIcon
          transaction={transaction}
          showFamilyName={showFamilyName}
        />
      </View>

      <View>
        <TransactionAmountText transaction={transaction} showUnaryOperator />
        <Text className="pt-1 text-right text-label-400 text-grey-600">
          {formatMoneyFromInteger(balance)}
        </Text>
      </View>
    </Pressable>
  );
};
