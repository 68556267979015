import { ContentPlaceholder } from "shared/components";
import { useDatatable } from "shared/lib";

export const NoResultsContentPlaceholder = () => {
  const { datatable } = useDatatable();

  return (
    <>
      <section>
        <div className={"mb-5 flex justify-end"}>
          {datatable.contentPlaceholders?.noResultsContent?.action &&
            datatable.contentPlaceholders?.noResultsContent?.action}
        </div>
        <ContentPlaceholder
          icon={
            datatable.contentPlaceholders?.noResultsContent?.icon ??
            "searchOutline"
          }
          title={
            datatable.contentPlaceholders?.noResultsContent?.title ??
            "We couldn’t find what you’re looking for"
          }
          description={
            datatable.contentPlaceholders?.noResultsContent?.description ??
            "Try broadening your search"
          }
          action={datatable.contentPlaceholders?.noResultsContent?.action}
        />
      </section>
    </>
  );
};
