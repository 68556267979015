import {
  getTransactionIcon,
  getTransactionIconColors,
} from "../../transactions/transactionStyles";
import { AggregateTransactionWithPrimaryType } from "../../transactionUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export const TransactionTileIcon = ({
  transaction,
}: {
  transaction: AggregateTransactionWithPrimaryType;
}) => {
  const [color, backgroundColor] = getTransactionIconColors(transaction);
  const { View, Icon } = useGenericComponents();
  return (
    <View
      className="flex h-10 rounded-full p-2"
      style={{
        backgroundColor,
      }}>
      <Icon
        name={getTransactionIcon(transaction.primaryType)}
        size={24}
        color={color}
      />
    </View>
  );
};
