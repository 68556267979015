import { AttendanceStatus, CourseDto } from "@justraviga/classmanager-sdk";

import { AttendanceStatuses, formatDate } from "shared/lib";

import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { LessonAttendancesProgress } from "@/modules/company/classPlanner/lessons/headers/LessonAttendancesProgress";

export const LessonAttendancesHeader = ({
  courseDto,
  date,
  progress = 0,
  statuses,
  setStatuses,
  numStudents,
}: {
  courseDto: CourseDto;
  date: string;
  progress: number;
  statuses: AttendanceStatuses;
  setStatuses: (status: AttendanceStatus) => void;
  numStudents: number;
}) => {
  const getActionItems = () => {
    const items: ActionMenuItemProps[] = [
      {
        title: "Mark all as absent",
        leftIcon: "closeCircleOutline",
        onClick: () => setStatuses(AttendanceStatus.Absent),
      },
    ];

    const hasAttendances =
      Object.values(statuses).filter(status => status !== null).length > 0;

    if (hasAttendances) {
      items.push({
        title: "Reset attendance",
        leftIcon: "refreshCircle",
        onClick: () => setStatuses(AttendanceStatus.Unknown),
      });
    }

    return items;
  };

  const isEmpty = numStudents === 0;

  return (
    <div>
      <section className={"flex items-start justify-between"}>
        <div className={"space-y-2"}>
          <div className={"flex items-center"}>
            <p className={"text-heading5-600"}>{courseDto.name}</p>
          </div>
          <div className={"text-grey-600"}>
            {formatDate(date, "weekdayDayMonthYear")}
          </div>
        </div>
        {!isEmpty && (
          <div className={"hidden sm:flex"}>
            <LessonAttendancesProgress progress={progress} />
          </div>
        )}
      </section>

      {!isEmpty && (
        <section className={"flex items-center justify-between py-3"}>
          <h4 className={"text-body-400 text-grey-900"}>
            {numStudents} {numStudents === 1 ? "student" : "students"}
          </h4>

          <div className={"flex items-center"}>
            <Button
              text={"Mark all as present"}
              variant={"tertiary"}
              size={"sm"}
              onClick={() => setStatuses(AttendanceStatus.Present)}
            />

            <ActionMenu
              width={"w-80"}
              header={
                <h3 className={"truncate text-heading6-600 text-grey-900"}>
                  {formatDate(date, "weekdayDayMonthYear")}
                </h3>
              }
              trigger={
                <div className={"-mr-2"}>
                  <IconButton
                    size={"lg"}
                    icon={"ellipsisHorizontal"}
                    variant={"standard"}
                  />
                </div>
              }
              items={getActionItems()}
            />
          </div>
        </section>
      )}
    </div>
  );
};
