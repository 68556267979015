import { ContactDto, StudentDto } from "@justraviga/classmanager-sdk";

import { useSharedContactActions } from "shared/components";
import { enumLabel, getFullName } from "shared/lib";
import { breakpoint } from "shared/lib";

import { copyToClipboard, openPhoneClient } from "@/lib/utils";
import { Tooltip } from "@/modules/common/overlays/Tooltip";
import {
  ContactCardItem,
  ContactCardItemWithAction,
} from "@/modules/company/common/details/ContactItem";
import { Router } from "@/routing/router";

export const EmergencyContactItem = ({
  student,
  contact,
}: {
  student: StudentDto;
  contact: ContactDto;
  onUpdated?: () => void;
}) => {
  const contactActions = useSharedContactActions();

  return (
    <div className={"flex flex-col space-y-1"}>
      <div className={"flex flex-row items-center justify-between px-2"}>
        <span className={"text-body-600 text-grey-900"}>
          {getFullName(contact)}
        </span>
        {!student.deletedAt && (
          <button onClick={() => contactActions.showUpdateForm(contact)}>
            <span className={"text-label-400 text-grey-500"}>Edit</span>
          </button>
        )}
      </div>
      <div className={"p-2"}>
        <ContactCardItem
          icon={"personOutline"}
          text={
            contact.relation && enumLabel("contactRelation", contact.relation)
          }
        />
      </div>
      <div className={"p-2"}>
        <ContactCardItemWithAction
          mainIcon={"callOutline"}
          actions={[
            {
              icon: "callOutline",
              action: () => {
                contact.phone && openPhoneClient(contact.phone);
              },
            },
          ]}
          content={contact.phone}
          shouldShowActions={!breakpoint.md}
        />
      </div>
      <div className={"p-2"}>
        <ContactCardItemWithAction
          shouldShowActions={!!contact.email}
          mainIcon={"mailOutline"}
          actions={[
            {
              icon: "copyOutline",
              action: () => {
                contact.email &&
                  copyToClipboard(contact.email, "Email copied to clipboard");
              },
            },
            {
              icon: "mailOutline",
              action: () => {
                contact.email &&
                  Router.push("WriteEmail", { contactId: contact.id });
              },
            },
          ]}
          content={
            <Tooltip
              align={"end"}
              trigger={
                <div className={"flex flex-row space-x-4 truncate"}>
                  <span className={"truncate text-left"}>
                    {contact.email ?? "-"}
                  </span>
                </div>
              }
              children={
                <span className={"w-full break-words text-left"}>
                  {contact.email}
                </span>
              }
            />
          }
        />
      </div>
    </div>
  );
};
