import {
  ListAttendeeWhereParameterInner,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { uniqueValuesForKey } from "../collectionUtils";
import { getPlatformFunctions } from "../platformSpecific";

export const useLessonStudentFamilies = ({
  courseId,
  date,
  time,
}: {
  courseId?: string;
  date?: string;
  time?: string;
}) => {
  const { useApi } = getPlatformFunctions();

  const shouldFetch =
    courseId !== undefined && date !== undefined && time !== undefined;

  const { data: attendees, isLoading: isAttendeesLoading } = useApi(
    "listAttendee",
    {
      where: {
        0: {
          classId: {
            equals: courseId,
          },
          date: {
            equals: date,
          },
          time: {
            equals: time,
          },
        },
      } as unknown as ListAttendeeWhereParameterInner[],
    },
    {
      enabled: shouldFetch,
    },
  );

  const studentIds = uniqueValuesForKey("studentId", attendees ?? []);

  const { data: students, isLoading: isStudentsLoading } = useApi(
    "listStudent",
    {
      selectAll: true,
      where: {
        id: {
          in: studentIds,
        },
      },
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
    },
    {
      enabled: shouldFetch && studentIds.length > 0,
    },
  );

  const familyIds = uniqueValuesForKey("familyId", students?.data ?? []);

  const { data: families, isLoading: isFamiliesLoading } = useApi(
    "listFamily",
    {
      selectAll: true,
      where: {
        id: {
          in: familyIds,
        },
      },
    },
    {
      enabled: shouldFetch && familyIds.length > 0,
    },
  );

  return {
    families: families?.data ?? [],
    isLoading: isAttendeesLoading || isStudentsLoading || isFamiliesLoading,
  };
};
