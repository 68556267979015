import { ChipProps, ChipVariant } from "./Chip";
import { useAlert } from "../../alertState";

interface ChipVariation {
  title: string;
  description?: string;
  props: ChipProps[];
}

const variants: Array<ChipVariant> = [
  "primary",
  "secondary",
  "outline",
  "info",
  "success",
  "neutral",
  "warning",
  "danger",
];

export const useChipVariations = (): Array<ChipVariation> => {
  const alert = useAlert();
  const rightIconAction = () =>
    alert.showAlert({
      content: "Right icon clicked",
    });

  return [
    {
      title: "Default size",
      props: variants.map(variant => ({ label: variant, variant })),
    },
    {
      title: "Small size",
      props: variants.map(variant => ({ label: variant, variant, size: "sm" })),
    },
    {
      title: "Default size, with icons",
      props: variants.map(variant => ({
        label: variant,
        variant,
        leftIcon: "bagHandleOutline",
        rightIcon: "warning",
      })),
    },
    {
      title: "Default size, with clickable icons",
      props: variants.map(variant => ({
        label: variant,
        variant,
        leftIcon: "bagHandleOutline",
        rightIcon: "closeOutline",
        rightIconAction,
      })),
    },
    {
      title: "Disabled, with icons",
      props: variants.map(variant => ({
        disabled: true,
        label: variant,
        variant,
        leftIcon: "bagHandleOutline",
        rightIcon: "closeOutline",
        rightIconAction,
      })),
    },
    {
      title: "Small size, with icons",
      props: variants.map(variant => ({
        label: variant,
        variant,
        leftIcon: "bagHandleOutline",
        rightIcon: "closeOutline",
        rightIconAction,
        size: "sm",
      })),
    },
    {
      title: "Border radius (deprecated)",
      props: [
        { label: "Rounded: sm", rounded: "sm" },
        { label: "Rounded: md", rounded: "md" },
      ],
    },
  ];
};
