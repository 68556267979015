import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { OnChangeFn, SortingState } from "@tanstack/react-table";

import {
  DatatableConfiguration,
  DatatableFilter,
  DatatableFilterManager,
  DatatableModelSelection,
  DatatableQueryProps,
  Dto,
} from "./datatableTypes";

export const DatatableContext = createContext({
  datatable: {} as DatatableConfiguration<Dto, object>,
  pageIndex: 0,
  pageSize: 10,
  setPagination: {} as Dispatch<
    SetStateAction<{ pageIndex: number; pageSize: number }>
  >,
  showWebSize: {} as boolean,
  modelSelection: {} as DatatableModelSelection<string>,
  filters: {} as DatatableFilter[],
  filtersValue: {} as DatatableFilterManager,
  globalFilter: {} as string,
  setSearch: {} as (v: string | null) => void,
  query: {} as DatatableQueryProps,
  setQuery: {} as Dispatch<SetStateAction<DatatableQueryProps>>,
  sorting: {} as SortingState,
  setSorting: {} as OnChangeFn<SortingState>,
  selectMode: false,
  setSelectMode: {} as Dispatch<SetStateAction<boolean>>,
  dataLoadedCount: 0,
  setDataLoadedCount: {} as Dispatch<SetStateAction<number>>,
  resetQuery: {} as () => void,
  contentPlaceholders: {} as DatatableConfiguration<
    Dto,
    object
  >["contentPlaceholders"],
});

export const useDatatable = () => useContext(DatatableContext);
