import { SortSchema } from "@justraviga/classmanager-sdk";

import { getFullName } from "../personUtil";
import { getPlatformFunctions } from "../platformSpecific";

export const useFamiliesSearchData = () => {
  const { useApi } = getPlatformFunctions();
  const { data: families, isLoading: isFamiliesLoading } = useApi(
    "listFamily",
    {
      sort: {
        name: SortSchema.Asc,
      },
      selectAll: true,
    },
  );

  const { data: students, isLoading: isStudentsLoading } = useApi(
    "listStudent",
    {
      where: {
        familyId: {
          in: families?.data.map(family => family.id) ?? [],
        },
      },
      selectAll: true,
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
    },
    {
      enabled: !isFamiliesLoading,
    },
  );

  const { data: primaryContacts, isLoading: isContactsLoading } = useApi(
    "listContact",
    {
      where: {
        familyId: {
          in: families?.data.map(family => family.id) ?? [],
        },
      },
      selectAll: true,
    },
    {
      enabled: !isFamiliesLoading,
    },
  );

  const familySelectOptions =
    (families?.data ?? []).map(family => {
      const studentNames = (students?.data ?? [])
        .filter(student => student.familyId === family.id)
        .map(student => student.firstname)
        .join(", ");

      const primaryContact = (primaryContacts?.data ?? []).find(
        contact => contact.familyId === family.id && contact.isPrimary,
      );

      return {
        label: family.name,
        description: primaryContact
          ? `${getFullName(primaryContact)} · ${studentNames}`
          : studentNames,
        value: family.id,
      };
    }) ?? [];

  const isLoading = isFamiliesLoading || isStudentsLoading || isContactsLoading;

  return {
    familySelectOptions: familySelectOptions,
    isLoading,
  };
};
