import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  makeSeasonUpdateRequest,
  SeasonCreateForm,
  SeasonImplementedActions,
  useFormActions,
  useSharedSeasonActions,
  useUpdatePricingSchemeDefinition,
} from "shared/components";

import { api, useData } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { MultiStepsHeader } from "@/modules/common/steps/MultiStepsHeader";
import { Banner } from "@/modules/common/ui/Banner";
import { SeasonPreview } from "@/modules/company/classPlanner/seasons/preview/SeasonPreview";

type Model = SeasonDto;

export const useSeasonActions = (): SeasonImplementedActions => {
  const { openSheet } = useSheet();
  const defaultActions = usePlatformEntityActions<SeasonDto>({
    entity: "season",
  });

  const {
    setCreateButtonText,
    setAllowCreateAdditional,
    shouldCloseOnSuccess,
  } = useFormActions();

  const { closeSheet, setTitle } = useSheet();

  const { data: taxRates } = useData("listTaxRate", { selectAll: true });

  const sharedActions = useSharedSeasonActions(defaultActions, taxRates);

  return {
    ...sharedActions,

    showCreateForm: () => {
      return defaultActions.showCreateForm({
        form: (
          <SeasonCreateForm
            onSuccess={defaultActions.onCreateSuccess}
            MultiStepsHeader={MultiStepsHeader}
            shouldCloseOnSuccess={shouldCloseOnSuccess}
            closeSheet={closeSheet}
            setTitle={setTitle}
            setCreateButtonText={setCreateButtonText}
            setAllowCreateAdditional={setAllowCreateAdditional}
            taxRates={taxRates}
          />
        ),
      });
    },

    showPricingSchemeUpdateForm: (entity: Model) => {
      return defaultActions.showUpdateForm({
        title: "Edit pricing scheme",
        form: (
          <>
            <Banner
              content={
                <>
                  <Banner.title>Prices will reset</Banner.title>
                  <Banner.content>
                    If you select a pricing scheme that has not been previously
                    configured, all class prices will be free until edited.
                  </Banner.content>
                </>
              }
              variant={"warning"}
            />
            <GenericForm
              apiRequest={makeSeasonUpdateRequest({ api, id: entity.id })}
              defaultValues={entity}
              formDefinitionHook={useUpdatePricingSchemeDefinition}
              onSuccess={defaultActions.onUpdateSuccess}
            />
          </>
        ),
      });
    },

    showPreview: (entity: Model) => {
      openSheet({
        title: "Season preview",
        content: <SeasonPreview season={entity} />,
      });
    },
  };
};
