import { CustomerCheckoutSuccessPage as SharedCustomerCheckoutSuccessPage } from "shared/components";
// import { breakpoint, showAlert } from "shared/lib";

import { Button } from "@/modules/common/ui/button/Button";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

export const CustomerCheckoutSuccessPage = () => (
  <CustomerLayout hideNav>
    <SharedCustomerCheckoutSuccessPage
      DashboardButton={
        <div
          className={
            "flex w-full flex-col items-center justify-center space-y-4 px-5 py-2"
          }>
          {/* TEMPORARY disable the download button until the App progresses through Apple and Google review processes */}
          {/*{!breakpoint.md && (*/}
          {/*  <Button*/}
          {/*    variant={"brand"}*/}
          {/*    text={"Download the app"}*/}
          {/*    onClick={() =>*/}
          {/*      /** TODO: implement downloading the app */}
          {/*      showAlert({ content: "TODO", variant: "important" })*/}
          {/*    }*/}
          {/*  />*/}
          {/*)}*/}
          <Button
            variant={"secondary"}
            text={"Go to dashboard"}
            onClick={() => {
              Router.push("CustomerHome");
            }}
          />
        </div>
      }
      HorizontalSeparator={HorizontalSeparator}
      gotoDashboard={() => Router.push("CustomerHome")}
    />
  </CustomerLayout>
);
