import { setPlatformFunctions } from "shared/lib";

import { api, useApi, useData } from "@/lib/api/apiClient";
import { eventTracker } from "@/lib/tracking/eventTracker";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import {
  copyToClipboard,
  getEnv,
  openWebsite,
  renderMarkdown,
} from "@/lib/utils";

/**
 * Populate the shared container with all our platform-specific things
 */
setPlatformFunctions({
  getEnv,
  renderMarkdown,
  api,
  useApi,
  useData,
  usePlatformEntityActions,
  copyToClipboard,
  openWebsite,
  eventTracker,
});
