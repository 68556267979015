import { CardType, getSvgForCardType } from "shared/lib";

import { useGenericComponents } from "../../GenericComponentsProvider";

interface CardTypeIconProps {
  cardType: CardType;
}

export const CardTypeIcon = ({ cardType }: CardTypeIconProps) => {
  const { Icon, View } = useGenericComponents();
  return (
    <View className="overflow-hidden rounded-sm">
      <Icon
        name={getSvgForCardType(cardType)}
        label={`${cardType} icon`}
        size={32}
      />
    </View>
  );
};
