import { NoItemsContentPlaceholder } from "../../../ui/NoItemsContentPlaceholder";

interface Props {
  goToArchivedItems: () => void;
  onCreateClick: () => void;
}

export const NoSeasonsPlaceholder = ({
  onCreateClick,
  goToArchivedItems,
}: Props) => {
  return (
    <NoItemsContentPlaceholder
      icon={"calendarClearOutline"}
      title={"No seasons yet"}
      description={
        "You can set up your weekly calendar by creating seasons. They can be used to plan weekly classes and enroll students on an annual basis."
      }
      createAction={{
        onClick: onCreateClick,
        text: "Create season",
      }}
      goToArchivedItems={goToArchivedItems}
    />
  );
};
