import { useCallback, useEffect, useRef, useState } from "react";

import {
  colors,
  toFormattedDecimalString,
  toSanitizedDecimalString,
} from "shared/lib";

import {
  BaseInput,
  BaseInputProps,
  BaseInputValue,
} from "@/modules/common/form/BaseInput";
import { NumberInputButtons } from "@/modules/common/form/NumberInputButtons";
import { Icon, IconName } from "@/modules/common/ui/icon/Icon";

export type DecimalInputValue = number | null;
type DecimalInputInnerValue = string | null;

export interface DecimalInputProps
  extends Omit<
    BaseInputProps,
    "append" | "prepend" | "value" | "placeholder" | "onChange"
  > {
  value?: DecimalInputValue;
  prependIcon?: IconName;
  decimalPlaces: number;
  onChange?: (value: DecimalInputValue) => void;
}

export const DecimalInput = ({
  onChange,
  onBlur,
  decimalPlaces,
  prependIcon,
  value = null,
  ...props
}: DecimalInputProps) => {
  const prepend = prependIcon ? (
    <div data-testid="prepend-icon">
      <Icon name={prependIcon} size={18} color={colors.grey[500]} />
    </div>
  ) : null;

  const format = useCallback(
    (v: string | number | null | undefined): DecimalInputInnerValue => {
      return toFormattedDecimalString(v, decimalPlaces);
    },
    [decimalPlaces],
  );

  const [innerValue, setInnerValue] = useState<DecimalInputInnerValue>(
    format(value),
  );

  const toExternalValue = (v: DecimalInputInnerValue): DecimalInputValue => {
    return String(v).trim().length > 0 ? Number(v) : null;
  };

  const placeholder = `00.${"0".repeat(decimalPlaces)}`;

  const lastEmittedValueRef = useRef<DecimalInputValue>(null);

  // Whenever the user types something, make sure only numbers and decimal points are entered
  const innerOnChange = (v: BaseInputValue) => {
    const newValue = toSanitizedDecimalString(v);

    // Handle uncontrolled component
    setInnerValue(newValue);
  };

  // When the user moves away from the input, format the contents to the correct
  // number of decimal places
  const innerOnBlur = () => {
    setInnerValue(format(innerValue));
    if (onBlur) {
      onBlur();
    }
  };

  // Track changes to value from outside the component

  useEffect(() => {
    if (Number(value) !== Number(innerValue)) {
      setInnerValue(format(value));
    }
    // I deliberately don't want to trigger this effect when innerValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, format]);

  // Emit changes to inner value
  useEffect(() => {
    const externalValue = toExternalValue(innerValue);
    if (onChange && externalValue !== lastEmittedValueRef.current) {
      onChange(externalValue);
      lastEmittedValueRef.current = externalValue;
    }
  }, [innerValue, onChange]);

  const increment = () => {
    setInnerValue((v: DecimalInputInnerValue) => format(Number(v) + 1));
  };

  const decrement = () => {
    setInnerValue((v: DecimalInputInnerValue) =>
      format(Math.max(0, Number(v) - 1)),
    );
  };

  const append = <NumberInputButtons {...{ increment, decrement }} />;

  return (
    <BaseInput
      {...props}
      append={append}
      onBlur={innerOnBlur}
      onChange={innerOnChange}
      placeholder={placeholder}
      prepend={prepend}
      selectTextOnFocus={true}
      type="decimal"
      value={innerValue}
    />
  );
};
