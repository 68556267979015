import {
  JoinWaitingListPage as SharedJoinWaitingListPage,
  useCatalogueContext,
} from "shared/components";

import { MultiSelect } from "@/modules/common/form/select/MultiSelect";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Banner } from "@/modules/common/ui/Banner";
import { Button } from "@/modules/common/ui/button/Button";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { PublicLayout } from "@/modules/public/layout/PublicLayout";
import { Router } from "@/routing/router";

export const JoinWaitingListPage = ({ courseId }: { courseId: string }) => {
  const { company } = useCatalogueContext();

  return (
    <PublicLayout
      backButton={{
        text: "Back to classes",
        onClick: () => Router.push("Courses", { slug: company.slug }),
      }}>
      <SharedJoinWaitingListPage
        courseId={courseId}
        Banner={Banner}
        Button={Button}
        HorizontalSeparator={HorizontalSeparator}
        MultiSelect={MultiSelect}
        useStudentActions={useStudentActions}
        Icon={Icon}
        goToSuccess={() =>
          Router.push("JoinWaitingListSuccess", { slug: company.slug })
        }
        goToCoursePreview={() =>
          Router.push("CatalogueCoursePreview", {
            courseId,
            slug: company.slug,
          })
        }
      />
    </PublicLayout>
  );
};
