import { AuthState, createAuthState } from "shared/lib";

const storageKey = "authState";

export const authState = createAuthState(
  () => {
    const state = localStorage.getItem(storageKey);
    return Promise.resolve(state ? (JSON.parse(state) as AuthState) : null);
  },
  state => {
    localStorage.setItem(storageKey, JSON.stringify(state));
  },
);
