import { useEffect, useState } from "react";

import {
  AttendanceDto,
  AttendanceStatus,
  AttendeeDto,
  AttendeeStatus,
  EnrolmentAdjustmentDto,
  SortSchema,
  StudentDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../api";
import { debounce } from "../asyncUtils";
import { uniqueValuesForKey } from "../collectionUtils";
import { getPlatformFunctions } from "../platformSpecific";

type StudentId = string;
export type AttendanceStatuses = Record<StudentId, AttendanceStatus>;

export interface AttendanceRecord {
  student: StudentDto;
  trial?: TrialDto;
  attendance?: AttendanceDto;
  adjustments?: EnrolmentAdjustmentDto[];
}

/**
 * Keep track of what attendance status changes we need to send to the API.
 */
const apiQueue = new Map<StudentId, AttendanceStatus>();

const updateApi = (api: Api, classId: string, date: string, time: string) => {
  api.attendances.createAttendance({
    attendanceBlueprint: [...apiQueue.entries()].map(([studentId, status]) => ({
      classId,
      studentId,
      date,
      time,
      status,
    })),
  });
  apiQueue.clear();
};

const debouncedUpdateApi = debounce(updateApi, 1000);

const updateApiQueue = (
  api: Api,
  studentIds: string[],
  classId: string,
  date: string,
  time: string,
  status: AttendanceStatus,
) => {
  studentIds.forEach(studentId => apiQueue.set(studentId, status));
  debouncedUpdateApi(api, classId, date, time);
};

export const useLessonAttendancesData = ({
  classId,
  date,
  time,
}: {
  classId: string;
  date: string;
  time: string;
}) => {
  // Keep a local copy of the attendance state. This allows us to do immediate UI updates
  const [statuses, setStatuses] = useState<AttendanceStatuses>({});

  const { api, useApi } = getPlatformFunctions();

  const { data: attendances, isLoading: isLoadingAttendances } = useApi(
    "listAttendance",
    {
      where: {
        classId: {
          equals: classId,
        },
        date: {
          equals: date,
        },
        time: {
          equals: time,
        },
      },
    },
  );

  const { data: courseDto, isLoading: isLoadingClass } = useApi("getCourse", {
    id: classId,
  });

  const { data: attendees, isLoading: isLoadingAttendees } = useApi(
    "listAttendee",
    {
      where: [
        {
          classId: {
            equals: classId,
          },
          date: {
            equals: date,
          },
          time: {
            equals: time,
          },
        },
      ],
    },
  );

  const studentIds = uniqueValuesForKey("studentId", attendees ?? []);

  useEffect(
    () => {
      // We only want to set the statuses once initially from server state.
      // After that, we'll maintain it locally for performance.
      if (
        !attendances?.data ||
        !attendees ||
        Object.keys(statuses).length > 0
      ) {
        return;
      }
      const studentIds = uniqueValuesForKey("studentId", attendees ?? []);
      setStatuses(
        studentIds.reduce((acc: AttendanceStatuses, studentId) => {
          const attendance = attendances?.data.find(
            attendance => attendance.studentId === studentId,
          );
          acc[studentId] = attendance?.status ?? AttendanceStatus.Unknown;
          return acc;
        }, {} as AttendanceStatuses),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attendances?.data, attendees],
  );

  const { data: students, isLoading: isLoadingStudents } = useApi(
    "listStudent",
    {
      selectAll: true,
      where: {
        id: {
          in: studentIds,
        },
      },
      includeArchived: true,
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
    },
    {
      enabled: studentIds.length > 0,
    },
  );

  const { data: season, isLoading: isLoadingSeason } = useApi(
    "getSeason",
    { id: courseDto?.entity.seasonId ?? "" }, // Fix no "entity" on page refresh
    { enabled: !isLoadingClass && !!courseDto?.entity },
  );

  const studentsData = students?.data ?? [];

  const records: AttendanceRecord[] = prepareAttendanceRecords({
    attendees: attendees ?? [],
    students: studentsData,
    attendances: attendances?.data ?? [],
  });

  const numAttendances = Object.values(statuses).filter(
    status => status !== AttendanceStatus.Unknown,
  ).length;

  const isLoading =
    isLoadingAttendances ||
    isLoadingStudents ||
    isLoadingClass ||
    isLoadingAttendees ||
    isLoadingSeason;

  const attendanceProgress = calculateProgressPercent(
    studentsData.length,
    numAttendances,
  );

  const setAttendanceStatus = (studentId: string, status: AttendanceStatus) => {
    const newStatuses = {
      ...statuses,
      [studentId]: status,
    };
    setStatuses(newStatuses);
    updateApiQueue(api, [studentId], classId, date, time, status);
  };

  const setAttendanceStatuses = (status: AttendanceStatus) => {
    setStatuses(
      studentIds.reduce((acc, studentId) => {
        acc[studentId] = status;
        return acc;
      }, {} as AttendanceStatuses),
    );
    updateApiQueue(api, studentIds, classId, date, time, status);
  };

  return {
    statuses,
    season,
    courseDto: courseDto?.entity,
    records,
    attendanceProgress,
    isLoading,
    setAttendanceStatus,
    setAttendanceStatuses,
  };
};

const prepareAttendanceRecords = ({
  attendees,
  students,
  attendances,
}: {
  attendees: AttendeeDto[];
  students: StudentDto[];
  attendances: AttendanceDto[];
}): AttendanceRecord[] => {
  return attendees
    .filter(attendee => attendee.status === AttendeeStatus.ExpectedToAttend)
    .map(attendee => {
      const student = students.find(
        student => student.id === attendee.studentId,
      )!;
      const attendance = attendances.find(
        attendance => attendance.studentId === attendee.studentId,
      );
      const adjustments = attendee.adjustments;

      return {
        student,
        attendance,
        trial: attendee.trials.length > 0 ? attendee.trials[0] : undefined,
        adjustments,
      };
    });
};

const calculateProgressPercent = (
  numStudents: number,
  numAttendances: number,
) => {
  if (numStudents === 0) {
    return 0;
  }

  return Math.round((numAttendances / numStudents) * 100);
};
