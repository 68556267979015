import { PropsWithChildren } from "react";

export const FamilyBackground = ({ children }: PropsWithChildren) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 flex bg-brand-100">
      <div className="flex flex-grow flex-col overflow-auto md:p-8">
        <div className="flex grow flex-col bg-white md:rounded-2xl">
          {children}
        </div>
      </div>
    </div>
  );
};
