import { WaiverDto } from "@justraviga/classmanager-sdk";

import {
  makeWaiverCreateRequest,
  makeWaiverUpdateRequest,
  useSharedWaiverActions,
  useWaiverCreateFormDefinition,
  useWaiverUpdateFormDefinition,
  useWaiverUpdateLockedFormDefinition,
} from "shared/components";
import { WaiverEntity, WaiverImplementedActions } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";

export const useWaiverActions = (): WaiverImplementedActions => {
  const defaultActions = usePlatformEntityActions<WaiverDto>({
    entity: "waiver",
  });

  const sharedActions = useSharedWaiverActions(defaultActions, api);

  const showUpdateForm = (entity: WaiverDto) =>
    defaultActions.showUpdateForm({
      form: (
        <GenericForm
          apiRequest={makeWaiverUpdateRequest({ api, id: entity.id })}
          defaultValues={entity}
          formDefinitionHook={useWaiverUpdateFormDefinition}
          onSuccess={defaultActions.onUpdateSuccess}
        />
      ),
    });

  const showUpdateLockedForm = (entity: WaiverDto) =>
    defaultActions.showUpdateForm({
      title: `Edit ${entity.name}`,
      form: (
        <GenericForm
          apiRequest={makeWaiverUpdateRequest({ api, id: entity.id })}
          defaultValues={entity}
          formDefinitionHook={useWaiverUpdateLockedFormDefinition}
          onSuccess={defaultActions.onUpdateSuccess}
        />
      ),
    });

  return {
    ...sharedActions,
    showUpdateForm,
    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeWaiverCreateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={useWaiverCreateFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showEditForm: (waiver: WaiverEntity) =>
      waiver.isLocked()
        ? showUpdateLockedForm(waiver.props)
        : showUpdateForm(waiver.props),
  };
};
