import { StudentDto } from "@justraviga/classmanager-sdk";

import { getFullName } from "../../../../personUtil";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { IconComponent } from "../../../interfaces";

interface StudentNotEligibleProps {
  Icon: IconComponent;
  date: string;
  student: StudentDto;
}
export const StudentNotEligible = ({
  Icon,
  date,
  student,
}: StudentNotEligibleProps) => {
  const { View, Text } = useGenericComponents();
  return (
    <View className={"w-full flex flex-row space-x-1 items-center"}>
      <View className={"flex-initial"}>
        <Icon name={"alertCircle"} color={"red"} size={24} />
      </View>
      <View className={"flex-initial"}>
        <Text className={"text-red-600 text-sm"}>
          {getFullName(student)} does not qualify for the class age limit as of{" "}
          {date}.
        </Text>
      </View>
    </View>
  );
};
