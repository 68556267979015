import {
  AggregateClassDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

export const findStudent = (
  list: StudentDto[],
  id: string,
): StudentDto | undefined => list.find(s => s.id === id);

export const findClass = (
  list: AggregateClassDto[],
  id: string,
): AggregateClassDto | undefined => list.find(c => c.entity.id === id);

export const findSeason = (
  list: SeasonDto[],
  id: string,
): SeasonDto | undefined => list.find(c => c.id === id);
