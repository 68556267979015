import { colors } from "../../colors";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ContentPlaceholderProps } from "../interfaces";

export const ContentPlaceholder = ({
  icon,
  title,
  description,
  action,
}: ContentPlaceholderProps) => {
  const { Text, View, Icon } = useGenericComponents();

  if (typeof description === "string" && description.length > 0) {
    description = (
      <View className={"flex flex-row justify-center"}>
        <Text
          className={
            "text-center text-sm font-normal text-grey-600 whitespace-pre-wrap"
          }>
          {description}
        </Text>
      </View>
    );
  }

  return (
    <View className={"max-w-lg mx-auto p-2 text-center space-y-3"}>
      <View className={"flex items-center justify-center"}>
        <View className={"rounded-full bg-brand-100 p-2"}>
          <Icon size={24} name={icon} color={colors.brand[800]} />
        </View>
      </View>

      <View className={"flex justify-center"}>
        <Text className={"text-center text-base font-semibold text-grey-900"}>
          {title}
        </Text>
      </View>

      {description}

      {action ? (
        <View
          className={
            "flex flex-col justify-center items-center space-y-8 pt-5"
          }>
          <View>{action}</View>
        </View>
      ) : null}
    </View>
  );
};
