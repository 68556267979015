import React from "react";

import { cn, colors } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

type NavHideButtonProps = {
  onClick: () => void;
  isExpanded: boolean;
};

export const NavHideButton = React.memo(
  ({ onClick, isExpanded }: NavHideButtonProps) => {
    return (
      <div
        className={cn(
          "text-primary absolute top-5 z-30 h-8 w-8 translate-x-[-50%] cursor-pointer ",
          " overflow-clip rounded-full bg-brand transition-all",
          isExpanded ? "left-[256px]" : "left-[70px]",
        )}
        onClick={onClick}>
        <div className="flex h-full w-full items-center justify-center bg-overlay-white-medium hover:bg-overlay-white-heavy">
          <span className={"-m-2 p-2"}>
            <Icon
              name={isExpanded ? "chevronBackOutline" : "chevronForwardOutline"}
              color={colors.black}
            />
          </span>
        </div>
      </div>
    );
  },
);
