import { TransactionAmountText } from "./TransactionAmountText";
import { TransactionDescriptionAndIcon } from "./TransactionDescriptionAndIcon";
import { formatDate } from "../../intlFormatter";
import { AggregateTransactionWithPrimaryType } from "../../transactionUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

interface TransactionTileProps {
  transaction: AggregateTransactionWithPrimaryType;
  showFamilyName?: boolean;
}

export const TransactionTile = ({
  transaction,
  showFamilyName = true,
}: TransactionTileProps) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-row justify-between pr-3"}>
      <TransactionDescriptionAndIcon
        transaction={transaction}
        showFamilyName={showFamilyName}
      />
      <View className={"md:hidden flex flex-col items-end space-y-1"}>
        <View>
          <TransactionAmountText transaction={transaction} />
        </View>
        <Text className={"text-label-400 text-grey-600 whitespace-nowrap"}>
          {formatDate(transaction.transaction.date, "dayMonthYear")}
        </Text>
      </View>
    </View>
  );
};
