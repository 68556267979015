export const moneyFloatToInteger = (amount: number): number => {
  if (amount == 0) {
    return 0;
  }
  return Math.round(amount * 100);
};

export const moneyIntegerToFloat = (amount: number): number => {
  if (amount == 0) {
    return 0;
  }

  return amount / 100;
};
