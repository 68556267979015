import { ChangeEvent, ReactNode } from "react";

import { Table } from "@tanstack/react-table";

import { colors } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const DatatablePagination = <T,>({
  table,
  options = [5, 10, 20, 50, 100],
  total,
}: {
  table: Table<T>;
  options?: Array<number>;
  total?: number;
}) => {
  const paginationState = table.getState().pagination;
  const rowsStart = paginationState.pageIndex * paginationState.pageSize + 1;

  const rowsEnd = getRowsEnd(rowsStart, paginationState.pageSize, total);

  const setPageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    table.setPageSize(Number(e.target.value));
  };

  return (
    <div className={"bg-white"}>
      <HorizontalSeparator spacing={0} />
      <div className={"flex items-center justify-between px-4 py-3"}>
        <section className={"flex items-center"}>
          <div
            className={"pl-6 pr-10 text-sm font-normal text-grey-600 xl:pl-0"}>
            Showing {rowsStart}-{rowsEnd} of {total} records
          </div>
          <div className={"text-sm font-normal text-grey-600"}>
            <span className={"pr-4"}>Per page</span>
            <select
              className={"rounded border px-2 py-1.5"}
              value={paginationState.pageSize}
              onChange={setPageSize}>
              {options.map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </section>

        <section>
          <PaginationPages table={table} />
        </section>
      </div>
    </div>
  );
};

const PaginationPages = <T,>({ table }: { table: Table<T> }) => {
  const currentPage = table.getState().pagination.pageIndex + 1;

  const pagesList = () => {
    const pagesCount = table.getPageCount();
    const pages = [];

    for (let i = 1; i <= pagesCount; i++) {
      pages.push(i);
    }
    if (pagesCount <= 5) {
      return pages;
    } else {
      if (currentPage < 4) {
        return [1, 2, 3, 4, 5, 0, pagesCount];
      } else if (currentPage > pagesCount - 4) {
        return [
          1,
          0,
          pagesCount - 4,
          pagesCount - 3,
          pagesCount - 2,
          pagesCount - 1,
          pagesCount,
        ];
      } else {
        return [
          1,
          0,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          0,
          pagesCount,
        ];
      }
    }
  };

  return (
    <>
      <PaginationButton
        key={"previous"}
        disabled={!table.getCanPreviousPage()}
        onClick={() => table.previousPage()}>
        <Icon
          className={"pt-0.5"}
          name={"chevronBackOutline"}
          size={14}
          color={
            table.getCanPreviousPage() ? colors.grey[600] : colors.grey[400]
          }
        />
      </PaginationButton>

      {pagesList().map((page, index) => {
        return page === 0 ? (
          <PaginationButton
            key={"page-" + index}
            disabled={true}
            isCurrentPage={currentPage === page}
            onClick={() => {}}>
            {"..."}
          </PaginationButton>
        ) : (
          <PaginationButton
            key={"page-" + index}
            disabled={false}
            isCurrentPage={currentPage === page}
            onClick={() => table.setPageIndex(page - 1)}>
            {page}
          </PaginationButton>
        );
      })}

      <PaginationButton
        key={"next"}
        disabled={!table.getCanNextPage()}
        onClick={() => table.nextPage()}>
        <Icon
          className={"pt-0.5"}
          name={"chevronForwardOutline"}
          size={14}
          color={table.getCanNextPage() ? colors.grey[600] : colors.grey[400]}
        />
      </PaginationButton>
    </>
  );
};

const PaginationButton = ({
  disabled,
  onClick,
  children,
  isCurrentPage = false,
}: {
  disabled: boolean;
  onClick: () => void;
  children: ReactNode;
  isCurrentPage?: boolean;
}) => {
  return (
    <button
      className={`inline-flex items-center border-t-2 border-transparent px-2.5 text-sm font-normal ${
        isCurrentPage
          ? "text-grey-900"
          : disabled
            ? "text-grey-400"
            : "text-grey-600"
      }`}
      role={"pagination-button"}
      onClick={onClick}
      disabled={disabled || isCurrentPage}>
      {children}
    </button>
  );
};

function getRowsEnd(rowsStart: number, pageSize: number, total?: number) {
  const end = rowsStart + pageSize - 1;

  if (total) {
    return total > end ? end : total;
  }

  return end;
}
