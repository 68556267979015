import { Table } from "@tanstack/react-table";

import { DatatableRow, fetchAndSelectAll, useDatatable } from "shared/lib";

export const DatatableSelectAllRow = <ModelDto,>({
  table,
  totalCount,
}: {
  table: Table<ModelDto> | Table<DatatableRow<ModelDto>>;
  totalCount?: number;
}) => {
  const { query, datatable, modelSelection } = useDatatable();

  return (
    <tr>
      <td colSpan={table.getAllColumns().length}>
        <div
          className={"rounded border-2 border-grey-300 bg-grey-100 px-4 py-3"}>
          <span className={"pr-2 text-sm font-normal text-grey-900"}>
            {modelSelection.count()} rows are selected.
          </span>
          <span
            onClick={() => {
              if (modelSelection.count() === totalCount) {
                modelSelection.clear();
              } else {
                fetchAndSelectAll(query, datatable, modelSelection);
              }
            }}
            className={
              "cursor-pointer text-sm font-semibold text-grey-900 underline underline-offset-2"
            }>
            {modelSelection.count() === totalCount
              ? "Deselect All Rows"
              : `Select All ${totalCount} Rows`}
          </span>
        </div>
      </td>
    </tr>
  );
};
