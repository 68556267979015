import { useAuthState } from "shared/components";
import { CountryCodeParam } from "shared/lib";

import { RegistrationForm } from "@/modules/auth/companyRegistration/RegistrationForm";
import { TestimonialSection } from "@/modules/auth/companyRegistration/TestimonialSection";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { Button } from "@/modules/common/ui/button/Button";
import {
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/modules/common/ui/card/Card";
import { Router } from "@/routing/router";

interface RegisterCompanyPageProps {
  countryCode: CountryCodeParam;
  email?: string | undefined;
}

export const RegisterCompanyPage = ({
  countryCode,
  email,
}: RegisterCompanyPageProps) => {
  const { user, setOnLogin } = useAuthState();

  // we may have been given an email address in the query string, so we add that as a default value to the form
  const defaultValues = {
    email,
  };

  function goToLoginWithRedirect() {
    setOnLogin(() => Router.push("RegisterCompany", { country: countryCode }));
    Router.push("Login");
  }

  return (
    <NonAuthLayout size={"lg"}>
      <div className={"grid grid-cols-1 lg:grid-cols-2"}>
        <div
          className={
            "space-y-8 rounded-bl rounded-tl border-0 border-grey-300 bg-white px-8 py-10 lg:border lg:px-8 lg:py-12"
          }>
          <CardHeader>
            <ClassManagerLogo
              color="grey-900"
              className={"mx-auto"}
              width={"208px"}
            />
          </CardHeader>
          <CardHeader>
            <CardTitle>Register your company</CardTitle>
          </CardHeader>
          <CardContent className={"space-y-8"}>
            <RegistrationForm
              defaultValues={defaultValues}
              countryCode={countryCode}
            />
          </CardContent>
          <CardFooter className="flex-col space-y-8 p-0">
            <p className={"text-label-400 text-grey-600"}>
              By clicking register you have read and agree with Class Manager’s
              Terms & conditions and Privacy Policy.
            </p>
            {user === null && (
              <div className={"flex items-center justify-center space-x-2"}>
                <p className={"text-body-400 text-grey-600"}>
                  Already have an account?
                </p>
                <Button
                  variant={"link"}
                  onClick={goToLoginWithRedirect}
                  text={"Log in"}
                />
              </div>
            )}
          </CardFooter>
        </div>
        <TestimonialSection />
      </div>
    </NonAuthLayout>
  );
};
