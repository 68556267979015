import { ContentPlaceholder } from "shared/components";
import { useFamilyStudentsPageData } from "shared/lib";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CustomerCardPage } from "@/modules/common/cardContainer/CustomerCardPage";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Button } from "@/modules/common/ui/button/Button";
import { StudentCard } from "@/modules/customer/students/cards/StudentCard";
import { StudentMobileCard } from "@/modules/customer/students/cards/StudentMobileCard";

export const CustomerStudentsPage = () => {
  const { showPortalCreateForm: showCreateForm } = useStudentActions();
  const { isLoading, students, isDesktop } = useFamilyStudentsPageData();

  return (
    <CustomerCardPage
      title="My students"
      isEmpty={students?.data.length === 0}
      primaryAction={{
        icon: "addOutline",
        title: "Create",
        onClick: showCreateForm,
      }}
      isLoading={isLoading}>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon={"schoolOutline"}
          title={"No students yet"}
          action={
            <Button
              variant={"brand"}
              text={"Create"}
              size={"sm"}
              onClick={() => showCreateForm()}
            />
          }
        />
      </CardContainerPlaceholder>
      <CardContainerContent>
        {students?.data.map(student =>
          isDesktop ? (
            <StudentCard key={student.id} student={student} />
          ) : (
            <StudentMobileCard key={student.id} student={student} />
          ),
        )}
      </CardContainerContent>
    </CustomerCardPage>
  );
};
