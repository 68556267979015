import { useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  TransactionDto,
} from "@justraviga/classmanager-sdk";

import { RefundDetails } from "./RefundDetails";
import { RefundForm } from "./RefundForm";
import { getPlatformFunctions } from "../../platformSpecific";
import { FamilyTransaction } from "../datatables/useTransactionsDatatable";
import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";

export const RefundProcess = ({
  setTitle,
  transaction,
}: {
  setTitle: (title: string) => void;
  transaction: FamilyTransaction;
}) => {
  const [refundTransaction, setRefundTransaction] =
    useState<AggregateTransactionDto | null>();
  const { Loader } = useGenericComponents();
  const { usePlatformEntityActions, useApi } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<TransactionDto>({
    entity: "transaction",
  });
  const { setShouldCloseOnSuccess } = useFormActions();

  useEffect(() => {
    setShouldCloseOnSuccess(false);
  }, [setShouldCloseOnSuccess]);

  const familyId = transaction.transaction.familyId;
  const transactionDetailId = transaction.details[0].details.id;
  const { data: paymentIntent } = useApi("stripeRetrievePaymentIntent", {
    transactionDetail: transactionDetailId,
    familyId,
  });

  const onSuccess = (transaction: AggregateTransactionDto) => {
    defaultActions.onCreateSuccess();
    setRefundTransaction(transaction);
  };

  return !paymentIntent ? (
    <Loader />
  ) : refundTransaction ? (
    <RefundDetails
      paymentIntent={paymentIntent}
      setTitle={setTitle}
      transaction={refundTransaction}
    />
  ) : (
    <RefundForm
      onSuccess={onSuccess}
      paymentIntent={paymentIntent}
      transaction={transaction}
    />
  );
};
