import {
  BillingHistoryWidget,
  DashboardPageHeader,
  EnrolledStudentsWidget,
  EnrolmentTrendsWidget,
  LinksWidget,
  RecentActivityWidget,
  StripeBanner,
  TotalFamiliesWidget,
  TotalStudentsWidget,
  UpcomingBirthdaysWidget,
} from "shared/components";

import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { BillingHistoryChart } from "@/modules/company/dashboard/charts/BillingHistoryChart";
import { EnrolmentTrendChart } from "@/modules/company/dashboard/charts/EnrolmentTrendChart";
import { TodaysScheduleWidget } from "@/modules/company/dashboard/TodaysScheduleWidget";
import { Router } from "@/routing/router";

import { Banner } from "../common/ui/Banner";
// import { StripeBanner } from "./dashboard/StripeBanner";

const TopSection = () => (
  <div className="grid grid-cols-2 gap-4 py-4 md:grid-cols-3 md:gap-x-8">
    <TotalStudentsWidget />
    <div className={"max-md:order-last max-md:col-span-2"}>
      <EnrolledStudentsWidget />
    </div>
    <TotalFamiliesWidget />
  </div>
);

function goToStudent(studentId: string): void {
  Router.push("StudentDetails", { id: studentId });
}

export const CompanyDashboardPage = () => {
  return (
    <CompanyLayout>
      <CompanyContent>
        <DashboardPageHeader />
        <TopSection />
        <StripeBanner
          Banner={props => (
            <div className="pb-4 md:pb-0">
              <Banner {...props} />
            </div>
          )}
        />
        <div className="grid grid-cols-1 gap-y-4 md:grid-cols-3 md:gap-x-8 md:pt-4">
          <div className="col-span-2 space-y-4 md:space-y-8">
            <TodaysScheduleWidget />

            <BillingHistoryWidget BillingHistoryChart={BillingHistoryChart} />
            <EnrolmentTrendsWidget EnrolmentTrendChart={EnrolmentTrendChart} />
          </div>
          <div className="col-span-1 space-y-4 md:space-y-8">
            <LinksWidget />
            <RecentActivityWidget goToStudent={goToStudent} />
            <UpcomingBirthdaysWidget />
          </div>
        </div>
      </CompanyContent>
    </CompanyLayout>
  );
};
