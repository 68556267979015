import { ColumnDef, Row } from "@tanstack/react-table";
import { match } from "ts-pattern";

import {
  DatatableColumnProps,
  DatatableConfiguration,
  DatatableRow,
  Dto,
  MobileDatatableColumnProps,
  WebButtonProps,
} from "shared/lib";

import { AlignedCell } from "@/modules/common/datatable/cell/AlignedCell";
import { IconCell } from "@/modules/common/datatable/cell/IconCell";
import { SelectableCell } from "@/modules/common/datatable/cell/SelectableCell";
import { TextCell } from "@/modules/common/datatable/cell/TextCell";
import { TileCell } from "@/modules/common/datatable/cell/TileCell";
import { TextCellPlaceholder } from "@/modules/common/datatable/placeholders/TextCellPlaceholder";
import { TileCellPlaceholder } from "@/modules/common/datatable/placeholders/TileCellPlaceholder";

export const mapWebColumnPropsIntoColumnDef = <ModelDto extends Dto>(
  column: DatatableColumnProps<ModelDto>,
): ColumnDef<DatatableRow<ModelDto>> => {
  return {
    meta: {
      align: column.align,
      placeholder:
        column.placeholder === "text" ? (
          <TextCellPlaceholder />
        ) : (
          <TileCellPlaceholder />
        ),
    },
    cell: ({ row }: { row: Row<DatatableRow<ModelDto>> }) => {
      const datatableRow = row.original as DatatableRow<ModelDto>;
      const onClick = datatableRow.onClick;

      const cell = column.value(row.original);

      const cellComponent = match(cell)
        .with({ type: "text" }, props => <TextCell {...props} />)
        .with({ type: "tile" }, props => <TileCell {...props} />)
        .with({ type: "icon" }, props => <IconCell {...props} />)
        .with({ type: "custom" }, cell => <>{cell.children}</>)
        .exhaustive();

      return (
        <div
          className={onClick ? " cursor-pointer" : ""}
          onClick={onClick ? () => onClick(datatableRow) : undefined}>
          <AlignedCell align={column.align}>{cellComponent}</AlignedCell>
        </div>
      );
    },
    header: () => column.label,

    // Sorting
    id: column.sortableField ?? column.label, // Only for sorting, not used for anything else
    accessorFn: () => column.sortableField, // Only for sorting, not used for anything else
    // Temporary solution: Disable sorting
    enableSorting: false, //!!column.sortableField,
    enableMultiSort: false, //!!column.sortableField,
  };
};

export const mapMobileColumnPropsIntoColumnDef = <ModelDto,>(
  column: MobileDatatableColumnProps<ModelDto>,
): ColumnDef<DatatableRow<ModelDto>> => {
  return {
    id: "mobile",
    header: () => "Info",
    cell: ({ row }) => {
      return (
        <SelectableCell
          hasImage={column.hasImage}
          title={column.title ? column.title(row.original) : undefined}
          subtitle={column.subtitle ? column.subtitle(row.original) : undefined}
          image={column.image ? column.image(row.original) : undefined}
          children={column.children ? column.children(row.original) : undefined}
          isPendingDeleted={
            column.isPendingDeleted
              ? column.isPendingDeleted(row.original)
              : false
          }
          icon={column.icon ? column.icon(row.original) : undefined}
          row={row}
        />
      );
    },
  };
};

export function datatableCreateWebButtonProps<
  ModelDto extends Dto,
  FilterFormSchema extends object,
>(
  datatable: DatatableConfiguration<ModelDto, FilterFormSchema>,
): WebButtonProps {
  return {
    text: datatable.createLabel ?? "Create",
    onClick: datatable.createAction,
    variant: datatable.createVariant ?? "brand",
    size: "sm",
  };
}
