import {
  BaseAPI,
  Configuration,
  ErrorContext,
  ErrorResponse,
  FetchParams,
  RequestContext,
  ResponseContext,
} from "@justraviga/classmanager-sdk";

import { stringifyQueryParams } from "./serialisation";
import { cacheDependencyMap } from "../cache";
import { applyCaching } from "../cache/cachingMiddleware";
import { oneMinuteMs } from "../dateUtils";

export type PreMiddleware = (
  context: RequestContext,
) => Promise<FetchParams | void>;
export type PostMiddleware = (
  context: ResponseContext,
) => Promise<Response | void>;
export type OnErrorMiddleware = (
  context: ErrorContext,
) => Promise<Response | void>;

export interface Middleware {
  pre: PreMiddleware[];
  post: PostMiddleware[];
  onError: OnErrorMiddleware[];
}

// Create a Configuration object in the shape required by the SDK
function createConfig(
  basePath: string,
  pre: PreMiddleware[],
  post: PostMiddleware[] = [],
  onError: OnErrorMiddleware[] = [],
  cacheDurationInMs: number = 0,
) {
  const middleware = [
    ...pre.map(m => ({ pre: m })),
    ...post.map(m => ({ post: m })),
    ...onError.map(m => ({ onError: m })),
  ];
  return new Configuration({
    basePath,
    middleware,
    queryParamsStringify: stringifyQueryParams,
    fetchApi: async (requestUrl, requestInit): Promise<Response> => {
      const url =
        typeof requestUrl === "string"
          ? requestUrl
          : requestUrl instanceof URL
            ? requestUrl.toString()
            : requestUrl.url;

      if (requestInit === undefined) {
        return Promise.resolve(new Response());
      }
      const method = requestInit.method || "GET";

      return applyCaching({
        cacheDependencyMap,
        defaultRequest: async () => {
          const response = await fetch(requestUrl, requestInit);

          if (
            response &&
            response.status >= 300 &&
            response.status < 500 &&
            response.headers.get("content-type")?.includes("application/json")
          ) {
            // All API errors should follow the same format
            throw (await response.json()) as ErrorResponse;
          }

          // JSON parse all responses, except for 204s
          const body = response.status === 204 ? null : await response.json();
          return { body, status: response.status };
        },
        cacheDurationInMs,
        method,
        url,
      });
    },
  });
}

/**
 * Create an instance of an API class from the SDK
 * @param basePath The base URL for the API
 * @param middleware The standard list of pre, post and onError middleware to apply to each request
 */
export const configureApi =
  (basePath: string, middleware: Middleware) =>
  /**
   * @param apiClass One of the classes that extends runtime.BaseAPI
   */
  <T extends typeof BaseAPI>(apiClass: T): InstanceType<T> => {
    return new apiClass(
      createConfig(
        basePath,
        middleware.pre,
        middleware.post,
        middleware.onError,
        oneMinuteMs,
      ),
    ) as InstanceType<T>;
  };
