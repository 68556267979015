import { CustomFieldType } from "@justraviga/classmanager-sdk";

import { customFieldTypeLabel } from "shared/lib";

import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const CustomFieldCardHeader = ({
  type,
  menuItems,
}: {
  type: CustomFieldType;
  menuItems: ActionMenuItemProps[];
}) => {
  return (
    <div className={"flex h-4 items-center justify-between"}>
      <p className={"line-clamp-1 text-sm text-grey-600"}>
        {customFieldTypeLabel(type)}
      </p>
      <div
        className={"-mr-2"}
        onClick={e => {
          e.stopPropagation();
          return false;
        }}>
        <ActionMenu
          trigger={
            <IconButton variant={"standard"} icon={"ellipsisHorizontal"} />
          }
          items={menuItems}
        />
      </div>
    </div>
  );
};
