import React from "react";

import { useLinkProps } from "@swan-io/chicane";

import { useAuthState } from "shared/components";
import {
  cn,
  colors,
  CourseDocument,
  displayStudentAge,
  FamilyDocument,
  formatDate,
  formatTime,
  getFirstLetters,
  GlobalSearchResults,
  LinkedSearchResultType,
  SearchResultTitleType,
  SearchResultType,
  SeasonDocument,
  StaffDocument,
  StudentDocument,
  useSearchDialog,
} from "shared/lib";

import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { getEntityLink } from "@/routing/entityLinks";

interface LinkProps {
  to: string; // The route you're linking to (required)
  className?: string;
  replace?: boolean; // Replace instead of push (defaults to `false`)
  activeClassName?: string;
  activeStyle?: React.CSSProperties;
  onLinkClick: () => void;
  children: React.ReactNode;
}

const SearchLink: React.FC<LinkProps> = ({
  to,
  className,
  activeClassName,
  onLinkClick,
  children,
  ...props
}) => {
  const { active, onClick } = useLinkProps({ href: to });

  return (
    <a
      {...props}
      onClick={evt => {
        onLinkClick();
        onClick(evt);
      }}
      className={cn(className, active && activeClassName)}>
      {children}
    </a>
  );
};

export const LinkedSearchResult: React.FC<LinkedSearchResultType> = ({
  id,
  children,
}) => {
  const { account } = useAuthState();
  const { handleOpenChange } = useSearchDialog();

  const target = account?.role && getEntityLink(account.role, id);

  const onLinkClick = () => {
    handleOpenChange(false);
  };

  return target ? (
    <SearchLink data-testid="search-link" to={target} onLinkClick={onLinkClick}>
      {children}
    </SearchLink>
  ) : (
    <div data-testid="no-link" key={id}>
      {children}
    </div>
  );
};

export const SearchResultContent: React.FC<SearchResultType> = ({
  title,
  description,
  children,
}) => (
  <div
    className={`cursor-pointer px-4 py-2 last:pb-4 hover:bg-grey-100`}
    data-testid="search-result-content">
    <div className={`flex justify-start gap-3`}>
      <div className={`flex flex-col justify-center`}>{children}</div>
      <div
        className={`flex flex-col ${description ? "justify-between gap-y-1" : "justify-center"}`}>
        <div className={`line-clamp-1 text-base font-semibold`}>{title}</div>
        {description && (
          <div className={`line-clamp-1 text-body-400 text-sm  text-grey-600`}>
            {description}
          </div>
        )}
      </div>
    </div>
  </div>
);

const SearchResult: React.FC<SearchResultType> = props => (
  <LinkedSearchResult id={props.id}>
    <SearchResultContent {...props} />
  </LinkedSearchResult>
);

const FamilySearchResult: React.FC<{ family: FamilyDocument }> = ({
  family,
}) => {
  return (
    <SearchResult title={family.name} description={""} id={family.id}>
      <Avatar
        name={getFirstLetters(family.name)}
        src={family.profilePicture ?? undefined}
      />
    </SearchResult>
  );
};

const StudentSearchResult: React.FC<{ student: StudentDocument }> = ({
  student,
}) => {
  return (
    <SearchResult
      title={`${student.firstname} ${student.lastname}`}
      description={displayStudentAge(student)}
      id={student.id}>
      <Avatar
        name={getFirstLetters(`${student.firstname} ${student.lastname}`)}
        src={student.profilePicture ?? undefined}
      />
    </SearchResult>
  );
};

const StaffSearchResult: React.FC<{ staff: StaffDocument }> = ({ staff }) => {
  return (
    <SearchResult
      title={`${staff.firstname} ${staff.lastname}`}
      description={staff.email}
      id={staff.id}>
      <Avatar
        name={getFirstLetters(`${staff.firstname} ${staff.lastname}`)}
        src={staff.profilePicture ?? undefined}
      />
    </SearchResult>
  );
};

const CourseSearchResult: React.FC<{ course: CourseDocument }> = ({
  course,
}) => {
  const dayAbbreviation =
    course.dayOfWeek.slice(0, 3).charAt(0).toUpperCase() +
    course.dayOfWeek.slice(1, 3);
  const description = `${dayAbbreviation} • ${formatTime(course.startTime, "hourMinute")}`;
  return (
    <SearchResult
      title={course.name}
      description={description} // Tue • 3:00pm
      id={course.id}>
      <div className={"rounded-full bg-brand-100 p-2"}>
        <Icon size={20} name={"calendarNumber"} color={colors.brand[800]} />
      </div>
    </SearchResult>
  );
};

const SeasonSearchResult: React.FC<{ season: SeasonDocument }> = ({
  season,
}) => {
  return (
    <SearchResult
      title={season.name}
      description={`${formatDate(season.startAt, "dayMonthYear")} - ${formatDate(season.endAt, "dayMonthYear")}`}
      id={season.id}>
      <div className={"rounded-full bg-brand-100 p-2"}>
        <Icon size={20} name={"calendarClear"} color={colors.brand[800]} />
      </div>
    </SearchResult>
  );
};

export const SearchResultTitle: React.FC<SearchResultTitleType> = ({
  title,
  count,
}) => {
  return (
    <div className={`flex items-center px-4 py-3 text-grey-600`}>
      <p className={`line-clamp-1 flex-grow`}>
        {title}
        <span className="ml-2 flex-shrink-0">
          ({count > 10 ? "10+" : count})
        </span>
      </p>
    </div>
  );
};

interface SearchResultsProps {
  results: GlobalSearchResults;
}

export const SearchResults: React.FC<SearchResultsProps> = ({ results }) => (
  <div
    className={"min-h-full overflow-y-auto"}
    data-testid="search-results-container">
    {results.data.family?.documents.length > 0 && (
      <div data-testid="families-results">
        <SearchResultTitle
          title={"Families"}
          count={results?.data.family?.count}
        />
        {results?.data.family?.documents.map((family: FamilyDocument) => (
          <FamilySearchResult family={family} key={family.id} />
        ))}
      </div>
    )}
    {results.data.student?.documents.length > 0 && (
      <div data-testid="students-results">
        <SearchResultTitle
          title={"Students"}
          count={results?.data.student?.count}
        />
        {results?.data.student?.documents.map((student: StudentDocument) => (
          <StudentSearchResult student={student} key={student.id} />
        ))}
      </div>
    )}
    {results.data.staff?.documents.length > 0 && (
      <div data-testid="staff-results">
        <SearchResultTitle title={"Staff"} count={results?.data.staff?.count} />
        {results?.data.staff?.documents.map((staff: StaffDocument) => (
          <StaffSearchResult staff={staff} key={staff.id} />
        ))}
      </div>
    )}
    {results.data.course?.documents.length > 0 && (
      <div data-testid="courses-results">
        <SearchResultTitle
          title={"Courses"}
          count={results?.data.course?.count}
        />
        {results?.data.course?.documents.map((course: CourseDocument) => (
          <CourseSearchResult course={course} key={course.id} />
        ))}
      </div>
    )}
    {results.data.season?.documents.length > 0 && (
      <div data-testid="seasons-results">
        <SearchResultTitle
          title={"Seasons"}
          count={results?.data.season?.count}
        />
        {results?.data.season?.documents.map((season: SeasonDocument) => (
          <SeasonSearchResult season={season} key={season.id} />
        ))}
      </div>
    )}
  </div>
);
