import { PropsWithChildren } from "react";

import { cn } from "shared/lib";

interface FamilyContentProps extends PropsWithChildren {
  hideNav?: boolean;
}

export const FamilyContent = ({ hideNav, children }: FamilyContentProps) => {
  return (
    <div
      className={cn("grow px-5 md:px-8", {
        "pb-9": !hideNav,
      })}>
      {children}
    </div>
  );
};
