import { LinkDto, UserDto } from "@justraviga/classmanager-sdk";

import { Api } from "./api";

interface FetchInviteParams {
  linkId: string;
  onSuccess: (linkDto: LinkDto) => void;
  onError: (err: unknown) => void;
}

export const fetchInvite = async (
  apiClient: Api,
  { linkId, onSuccess, onError }: FetchInviteParams,
) => {
  try {
    const linkData = await apiClient.links.getLinkData({ id: linkId });
    onSuccess(linkData);
  } catch (err) {
    onError(err);
  }
};

export interface ProcessInviteParams {
  linkDetails: LinkDto;
  email: string;
  password: string;
  requiresRegistration: boolean;
  onError: (err: unknown) => void;
  onSuccess: () => void;
  setUser: (userDto: UserDto, token: string) => void;
}

export const processInvite = (
  apiClient: Api,
  {
    linkDetails,
    email,
    password,
    requiresRegistration,
    onError,
    onSuccess,
    setUser,
  }: ProcessInviteParams,
) => {
  let promise = (async () => {})();

  if (requiresRegistration) {
    promise = apiClient.auth
      .registerUser({
        registerUserRequest: {
          email,
          password,
          firstname: linkDetails.firstname || "",
          lastname: linkDetails.lastname || "",
        },
      })
      .then();
  }

  return promise
    .then(() =>
      apiClient.auth.logIn({
        logInRequest: { email, password },
      }),
    )
    .then(({ token, user }) => {
      setUser(user, token);
    })
    .then(() => apiClient.links.processLink({ id: linkDetails?.id }))
    .then(() => {
      onSuccess();
    })
    .catch(err => {
      onError(err);
    });
};
