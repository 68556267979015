import { useAuthState } from "./useAuthState";
import { sdkCache } from "../cache";
import { resetBrandColors } from "../colors";
import { getPlatformFunctions } from "../platformSpecific";
import { queryClient } from "../tanstackQuery";

export const useLogout = () => {
  const { clearAuthState } = useAuthState();
  const { api } = getPlatformFunctions();

  return ({ resetColors = true } = {}) =>
    api.auth
      .logOut()
      .catch(e => {
        // Probably doesn't matter, but log the error just in case
        console.error("Error logging out", e);
      })
      .finally(() => {
        // Be sure to only clear the local copy of auth state *after* calling the API
        // (otherwise there won't be an Authorisation header in the request)
        clearAuthState();

        if (resetColors) {
          resetBrandColors();
        }

        // Clear all caches
        sdkCache.clear();
        queryClient.clear();
      });
};
