import * as React from "react";

import {
  iconButtonSizeClasses,
  iconButtonVariantConfigs,
} from "shared/components";
import { cn, countDigits, getIconSize, IconButtonProps } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

type WebIconButtonProps = Omit<IconButtonProps, "onClick"> & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  role?: string;
  tabIndex?: number;
};

const IconButton = React.forwardRef<HTMLButtonElement, WebIconButtonProps>(
  (
    {
      className,
      variant = "primary",
      size = "lg",
      icon,
      disabled,
      border = "square",
      hasIndicator,
      indicatorNumber = 0,
      indicatorVariant = "error",
      onClick,
      color,
      ...props
    },
    ref,
  ) => {
    const config = iconButtonVariantConfigs[variant];
    const iconColor = disabled
      ? config.disabledIconColor
      : color ?? config.iconColor;

    return (
      <button
        type={"button"}
        disabled={disabled}
        ref={ref}
        onClick={e => {
          if (disabled) {
            return;
          }
          onClick?.(e);
        }}
        className={cn(
          "relative h-fit w-fit cursor-pointer",
          {
            "p-3": size === "xs",
            "p-2": size === "sm",
            "p-1": size === "lg",
          },
          iconButtonSizeClasses[size],
          config.button,
          { [config.disabledButton + " cursor-not-allowed"]: disabled },
          border === "square" ? "rounded" : "rounded-full",
          className,
        )}
        {...props}>
        <div
          className={cn("flex items-center justify-center")}
          data-size={size}>
          <Icon size={getIconSize(size)} name={icon} color={iconColor} />
        </div>
        {hasIndicator && size !== "xs" && (
          <div
            data-testid="indicatorContainer"
            className={cn(
              "absolute -right-2 -top-3 flex items-center justify-center rounded-full",
              {
                "-top-1 right-0.5": size === "lg" && !indicatorNumber,
                "-right-1.5 -top-1.5": size === "sm" && !indicatorNumber,
              },
              {
                "h-3 w-3": !indicatorNumber,
                "px-1.5 py-0.5":
                  indicatorNumber && countDigits(indicatorNumber) < 3,
                "px-2 py-0.5":
                  indicatorNumber && countDigits(indicatorNumber) >= 3,
              },
              indicatorVariant === "error" ? "bg-red-600" : "bg-grey-900",
            )}>
            {indicatorNumber > 0 && (
              <span className={"text-label-400 text-white"}>
                {indicatorNumber}
              </span>
            )}
          </div>
        )}
      </button>
    );
  },
);

IconButton.displayName = "IconButton";

export { IconButton };
