import { FC, ReactNode, useState } from "react";

import {
  EnrolmentDto,
  ListEnrolment200Response,
  SortSchema,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { uniqueValuesForKey } from "../../collectionUtils";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import {
  buildEnrolmentFilter,
  DetailedEnrolment,
  EnrolmentFilter,
  getEnrolmentEndDate,
  getEnrolmentStartDate,
} from "../../enrolmentUtils";
import { formatDate } from "../../intlFormatter";
import { getFullName } from "../../personUtil";
import {
  displayStudentAge,
  mobileStudentDetailSubtitle,
} from "../../studentUtils";

type Dto = EnrolmentDto;
type RowDto = DetailedEnrolment;
type FilterForm = object;

function sortEnrolments(
  enrolments: ListEnrolment200Response,
  order: Array<StudentDto["id"]>,
): ListEnrolment200Response {
  return {
    ...enrolments,
    data: enrolments.data.sort((a, b) => {
      const aIndex = order.indexOf(a.enrolment.studentId);
      const bIndex = order.indexOf(b.enrolment.studentId);

      return aIndex - bIndex;
    }),
  };
}

interface UseEnrolmentsDatatableParams {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, RowDto> }>;
  api: Api;
  classId: string;
  filter: EnrolmentFilter;
  goToStudent: (id: string) => void;
  showCreateForm: () => void;
  showUpdateStartForm: (entity: RowDto) => void;
  showUpdateEndForm: (entity: RowDto) => void;
  deleteOne: (entity: RowDto) => Promise<void>;
}

export const useEnrolmentsDatatable = ({
  Datatable,
  api,
  classId,
  filter,
  goToStudent,
  showCreateForm,
  showUpdateStartForm,
  showUpdateEndForm,
  deleteOne,
}: UseEnrolmentsDatatableParams): { datatable: ReactNode } => {
  const applyFilter = buildEnrolmentFilter(filter);
  const placeholderTitle = {
    current: "No students enrolled for this class",
    upcoming: "No upcoming enrollments",
    past: "No past enrollments",
  }[filter];

  const [resultCount, setResultCount] = useState<number | null>(null);

  const fetchData = async (query: DatatableQueryProps) => {
    const { where, ...rest } = query.toRequest();

    // dependent on classId
    const coursePromise = api.courses.getCourse({
      id: classId,
    });

    const enrolmentsPromise = api.enrolments.listEnrolment({
      ...rest,
      selectAll: true,
      where: {
        ...where,
        classId: {
          equals: classId,
        },
      },
    });
    const [course, enrolmentsResponse] = await Promise.all([
      coursePromise,
      enrolmentsPromise,
    ]);

    // dependent on course
    const seasonPromise = api.seasons.getSeason({
      id: course.entity.seasonId,
    });

    // dependent on enrolments
    const studentIds = uniqueValuesForKey(
      "studentId",
      enrolmentsResponse.data.map(aggEnrolmentDto => aggEnrolmentDto.enrolment),
    );
    const studentsPromise = api.students.listStudent({
      where: {
        id: {
          in: studentIds,
        },
      },
      includeArchived: true,
      selectAll: true,
      sort: { firstname: SortSchema.Asc, lastname: SortSchema.Asc },
    });

    const [season, { data: students }] = await Promise.all([
      seasonPromise,
      studentsPromise,
    ]);

    setResultCount(enrolmentsResponse.pagination.total);

    const sortedEnrolments = sortEnrolments(
      enrolmentsResponse,
      uniqueValuesForKey("id", students),
    );

    const filteredDetailedEnrolments = sortedEnrolments.data
      .map(e => ({
        ...e.enrolment,
        enrolmentAdjustments: e.adjustments,
        class: course,
        season,
        student: students.find(s => s.id === e.enrolment.studentId)!,
      }))
      .filter(applyFilter);

    return {
      ...sortedEnrolments,
      data: filteredDetailedEnrolments,
    };
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: `enrolment-${filter}`,
    // This is a small hack, as the design only wants us to show a 'create' button on the 'Current' tab, when there
    // aren't any results.
    createAction:
      filter === "current" && resultCount === 0 ? showCreateForm : undefined,
    createLabel: "Enroll",
    rowActions: {
      title: item => getFullName(item.student),
      delete: deleteOne,
      click: item => goToStudent(item.student.id),
      additionalRowActions: () => [
        {
          icon: "personOutline",
          label: "Go to student",
          onClick: item => goToStudent(item.student.id),
        },
        // @TODO: uncomment this when emailing students is implemented
        // {
        //   icon: "mailOutline",
        //   label: "Email student",
        //   onClick: () => {
        //     /** todo */
        //     alert("Email student");
        //   },
        // },
        {
          title: "Enrollment",
          actions: [
            {
              icon: "calendarNumberOutline",
              label: "Edit start date",
              onClick: showUpdateStartForm,
            },
            {
              icon: "calendarOutline",
              label: "Edit end date",
              onClick: showUpdateEndForm,
            },
          ],
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: placeholderTitle,
        description: "",
      },
    },
    hasPagination: false,
    hasSearch: false,
    showTotalRecords: false,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Student",
        placeholder: "tile",
        value: row => {
          return {
            type: "tile",
            title: getFullName(row.item.student),
            subtitle: displayStudentAge(row.item.student),
            image: row.item.student.profilePicture ?? undefined,
          };
        },
      },
      {
        label: "Start date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDate(getEnrolmentStartDate(row.item), "dayMonthYear"),
        }),
      },
      {
        label: "End date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDate(getEnrolmentEndDate(row.item), "dayMonthYear"),
        }),
      },
    ],
    mobileColumn: {
      hasImage: true,
      image: row => row.item.student.profilePicture ?? undefined,
      title: row => getFullName(row.item.student),
      subtitle: row => mobileStudentDetailSubtitle(row.item.student),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
