import React from "react";

import {
  availableIcons,
  cn,
  IconProps,
  IconName as SharedIconName,
} from "shared/lib";

import styles from "@/modules/common/ui/icon/Icon.module.css";

export type IconName = SharedIconName;

export const Icon = React.memo(
  ({ name, size = 20, color, className, label }: IconProps) => {
    const [iconData, error] =
      name in availableIcons
        ? [availableIcons[name].replace("data:image/svg+xml;utf8,", ""), ""]
        : ["", `Icon "${name}" not found`];

    return (
      <div
        aria-label={label}
        data-icon-name={name}
        role="icon"
        className={cn(
          styles.icon,
          className,
          "flex flex-shrink-0 items-center justify-center",
        )}
        data-error={error}
        style={{
          color: color,
          fill: color,
          height: size,
          width: size,
        }}
        dangerouslySetInnerHTML={{ __html: iconData }}
      />
    );
  },
);
