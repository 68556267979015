import { PropsWithChildren } from "react";

import { cn } from "shared/lib";

import { NonAuthWebFooter } from "@/modules/common/NonAuthWebFooter";
import { NonAuthWebFooterSmall } from "@/modules/common/NonAuthWebFooterSmall";

interface NonAuthLayoutProps extends PropsWithChildren {
  branded?: boolean;
  size?: "md" | "lg";
}

export const NonAuthLayout = ({
  children,
  branded = false,
  size = "md",
}: NonAuthLayoutProps) => {
  return (
    <div
      className={cn("flex min-h-[100vh] flex-col justify-between", {
        "md:bg-purple-100": size === "md",
        "lg:bg-purple-100": size === "lg",
      })}>
      <div
        className={cn("flex grow flex-col items-center md:py-8", {
          "md:justify-center": size === "md",
          "lg:justify-center": size === "lg",
        })}>
        <div
          className={cn("w-full", {
            "max-w-lg": size === "md",
            "max-w-[1024px]": size === "lg",
          })}>
          {children}
        </div>
      </div>
      <div
        className={cn({
          "md:hidden": size === "md",
          "lg:hidden": size === "lg",
        })}>
        <NonAuthWebFooterSmall />
      </div>
      <div
        className={cn({
          "max-md:hidden": size === "md",
          "max-lg:hidden": size === "lg",
        })}>
        <NonAuthWebFooter variant={branded ? "brand" : "classmanager"} />
      </div>
    </div>
  );
};
