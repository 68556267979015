import {
  CompanyTrialSettingsDto,
  LocationDto,
  PricingSchemeValueDto,
  SeasonDto,
  StaffDto,
} from "@justraviga/classmanager-sdk";
import { AggregateClassDto } from "@justraviga/classmanager-sdk/dist/models/AggregateClassDto";

import { CoursePreviewMode } from "./CoursePreviewButton";
import { courseIsFull } from "../../../../classUtils";
import { useCatalogueData } from "../../../../data/useCatalogueData";
import { useFamilyCompany } from "../../../../data/useFamilyCompany";
import { getPlatformFunctions } from "../../../../platformSpecific";

export type CoursePreviewProps = {
  course: AggregateClassDto;
  season: SeasonDto;
  location?: LocationDto;
  teacher?: StaffDto;
  seasonPricingValues: PricingSchemeValueDto[];
  shouldRenderPriceList: boolean;
  trialSettings: CompanyTrialSettingsDto;
  previewMode: CoursePreviewMode;
  isFull: boolean;
  proceed: boolean;
};

export const useCoursePreview = ({
  courseId,
  mode,
}: {
  courseId: string;
  mode: CoursePreviewMode;
}): CoursePreviewProps => {
  const { useApi } = getPlatformFunctions();

  const { courses, seasons, locations, rooms, staff, pricingSchemeValues } =
    useCatalogueData(useApi, true);

  const course = courses?.find(course => course.entity.id === courseId);
  const season = seasons?.find(season => season.id === course?.entity.seasonId);
  const { company: familyCompany } = useFamilyCompany();
  const trialSettings = familyCompany?.settings.trial;

  if (!course || !season) {
    return {
      proceed: false,
    } as CoursePreviewProps;
  }

  const locationId = rooms?.find(
    room => room.id === course?.entity.roomId,
  )?.locationId;

  const location = locations?.find(location => location.id === locationId);
  const teacher = staff?.find(staff => staff.id === course?.entity.staffId);
  const seasonPricingValues =
    pricingSchemeValues?.filter(value => value.seasonId === season.id) ?? [];
  const isFull = courseIsFull(course);
  const shouldRenderPriceList = !course.entity.price;

  return {
    course,
    season,
    location,
    teacher,
    seasonPricingValues,
    shouldRenderPriceList,
    trialSettings,
    previewMode: isFull ? "full" : mode,
    isFull,
    proceed: true,
  };
};
