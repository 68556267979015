import { PricingScheme, SeasonDto } from "@justraviga/classmanager-sdk";

import { pricingSchemeTypeText } from "../../../pricingSchemeUtils";
import { useGenericComponents } from "../../GenericComponentsProvider";

export const CreatePricingTierCardContents = ({
  season,
}: {
  season: SeasonDto;
}) => {
  const { Text } = useGenericComponents();
  switch (season.pricingScheme) {
    case PricingScheme.ClassCount:
      return (
        <Text className={"text-label-400 text-white"}>
          {pricingSchemeTypeText[season.pricingScheme]}
        </Text>
      );
    case PricingScheme.TotalDuration:
      return (
        <Text className={"text-label-400"}>
          {pricingSchemeTypeText[season.pricingScheme]}
        </Text>
      );
    case PricingScheme.None:
    default:
      return null;
  }
};
