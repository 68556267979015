import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
} from "react";

import { DocContainer } from "./DocContainer";

interface ToggleContextType {
  showContent: boolean;
  toggleContent: () => void;
}

const ToggleContext = createContext<ToggleContextType | undefined>(undefined);

const useToggle = () => {
  const context = useContext(ToggleContext);
  if (!context) {
    throw new Error(
      "useToggle() should be used within a <ToggleDoc /> component",
    );
  }
  return context;
};

export const ToggleDoc = ({ children }: PropsWithChildren) => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => setShowContent(!showContent);

  return (
    <ToggleContext.Provider value={{ showContent, toggleContent }}>
      <DocContainer>{children}</DocContainer>
    </ToggleContext.Provider>
  );
};

export const ToggleContent = ({ children }: PropsWithChildren) => {
  const { showContent } = useToggle();

  return showContent ? <div className="mt-8">{children}</div> : null;
};

interface ToggleTriggerProps {
  renderTrigger: (toggleContent: () => void) => ReactNode;
}

export const ToggleTrigger = ({ renderTrigger }: ToggleTriggerProps) => {
  const { toggleContent } = useToggle();

  return renderTrigger(toggleContent);
};
