import { Cell, flexRender } from "@tanstack/react-table";

export const ActionsCell = <ModelDto,>({
  cell,
}: {
  cell: Cell<ModelDto, unknown>;
}) => {
  return (
    <td className={`h-full`}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};
