import { useEffect } from "react";

import {
  CourseSearchValues,
  makeCourseSearchValues,
} from "./courseSearchContext";
import { CourseSearchMode, CourseWizardStage } from "./courseSearchTypes";
import { CourseWizardForm } from "./CourseWizardForm";
import { activeCourses, coursesHaveMultipleAges } from "./courseWizardLogic";
import { useCatalogueData } from "../../data/useCatalogueData";
import { getPlatformFunctions } from "../../platformSpecific";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ButtonComponent, IconButtonComponent } from "../interfaces";

interface CourseWizardChecksProps {
  Button: ButtonComponent;
  IconButton: IconButtonComponent;
  goToLandingPage: () => void;
  goToResults: (searchValues: CourseSearchValues, replace: boolean) => void;
  goToStage: (stage: CourseWizardStage, replace: boolean) => void;
  mode: CourseSearchMode;
  stage: CourseWizardStage;
  searchValues: CourseSearchValues;
  setSearchValues: (values: CourseSearchValues) => void;
}

export const CourseWizardChecks = ({
  Button,
  IconButton,
  goToLandingPage,
  goToResults,
  goToStage,
  mode,
  stage,
  searchValues,
  setSearchValues,
}: CourseWizardChecksProps) => {
  const { useApi } = getPlatformFunctions();
  const { Loader } = useGenericComponents();

  const { isLoading, courses, seasons, locations, assignedLocationIds } =
    useCatalogueData(useApi, true, mode);

  const shouldSkipLocationStage =
    !assignedLocationIds || assignedLocationIds.length <= 1;
  const shouldSkipAgeStage =
    !courses || !coursesHaveMultipleAges(courses, mode, seasons);

  // Skip to the results page if no courses can be enrolled on or trialled
  // Also skip age stage if courses have one or fewer assigned to ages
  const shouldSkipToResults =
    !courses ||
    activeCourses(courses, mode, seasons).length === 0 ||
    (stage === "age" && shouldSkipAgeStage);

  // Skip location stage if there's one or fewer locations
  const shouldSkipToAgeStage = stage === "location" && shouldSkipLocationStage;

  // we can't do a navigate in a render cycle so, we need to use an effect
  useEffect(() => {
    if (!isLoading && shouldSkipToResults) {
      goToResults(makeCourseSearchValues(), true);
      return;
    }
    if (!isLoading && shouldSkipToAgeStage) {
      goToStage("age", true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSkipToResults, shouldSkipToAgeStage, isLoading]);

  if (isLoading || !courses || !locations || !seasons || !assignedLocationIds) {
    return <Loader />;
  }

  const goBack = () => {
    if (stage === "age" && !shouldSkipLocationStage) {
      goToStage("location", false);
    } else {
      goToLandingPage();
    }
  };

  const goNext = () => {
    if (stage === "location" && !shouldSkipAgeStage) {
      goToStage("age", false);
    } else {
      // Remove any 'view all' values before sending
      goToResults(
        {
          ages: searchValues.ages.filter(age => age !== 0),
          locationIds: searchValues.locationIds.filter(id => id !== ""),
          days: [],
          query: "",
          seasonIds: [],
        },
        false,
      );
    }
  };

  return (
    <CourseWizardForm
      Button={Button}
      IconButton={IconButton}
      goNext={goNext}
      goBack={goBack}
      goToResults={goToResults}
      stage={stage}
      courses={courses}
      locations={locations}
      assignedLocationIds={assignedLocationIds}
      searchValues={searchValues}
      setSearchValues={setSearchValues}
    />
  );
};
