import {
  makeResetPasswordRequest,
  useResetPasswordFormDefinition,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { InvalidHeader } from "@/modules/auth/common/InvalidHeader";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { useResetPasswordPageData } from "@/modules/auth/reset/useResetPasswordPageData";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { Button } from "@/modules/common/ui/button/Button";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { Router } from "@/routing/router";

export const ResetPasswordPage = ({
  token,
  email,
}: {
  token: string;
  email: string;
}) => {
  const { submit, isLoading, isSubmitting, isTokenValid, onSuccess } =
    useResetPasswordPageData(email, token);

  if (isLoading) {
    return (
      <NonAuthLayout>
        <LoadingTokenStatus />
      </NonAuthLayout>
    );
  }

  return (
    <NonAuthLayout>
      {!isTokenValid ? (
        <InvalidToken />
      ) : (
        <AuthPageCard
          showTerms={false}
          header={
            <ClassManagerLogo
              color="grey-900"
              className={"mx-auto h-10 md:h-12 md:max-w-[250px]"}
            />
          }
          title={"Enter your new password"}
          content={
            <>
              <GenericForm
                apiRequest={makeResetPasswordRequest({ api, token, email })}
                defaultValues={{}}
                formDefinitionHook={useResetPasswordFormDefinition}
                onSuccess={onSuccess}
              />
              <Button
                onClick={submit}
                loading={isSubmitting}
                variant="classmanager"
                text="Reset password and log in"
                isFullWidth={true}
              />
            </>
          }
        />
      )}
    </NonAuthLayout>
  );
};

const LoadingTokenStatus = () => (
  <AuthPageCard
    header={
      <ClassManagerLogo
        color="grey-900"
        className={"mx-auto h-10 md:h-12 md:max-w-[250px]"}
      />
    }
    title={"Checking your token..."}
    content={
      <div className={"flex items-center justify-center"}>
        <LoadingSpinnerDark />
      </div>
    }
  />
);

const InvalidToken = () => (
  <AuthPageCard
    header={<InvalidHeader />}
    title={"Password reset link is invalid"}
    subtitle={
      "This link is no longer valid, you can request a new one by clicking the button below."
    }
    content={
      <>
        <Button
          variant={"classmanager"}
          text={"Request another link"}
          isFullWidth={true}
          onClick={() => Router.push("ForgottenPassword")}
        />
      </>
    }
    footer={
      <>
        <Button
          variant={"tertiary"}
          text={"Back to log in"}
          onClick={() => Router.push("Login")}
        />
      </>
    }
  />
);
