import { ReactNode } from "react";

import { cn } from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";

interface CustomerTitleBarProps {
  title: string;
  forceBackButton?: boolean;
  rightContent?: ReactNode;
}

export const CustomerTitleBar = ({
  title,
  forceBackButton = false,
  rightContent,
}: CustomerTitleBarProps) => (
  <div className="flex flex-row items-center justify-between py-5">
    <div className="flex flex-row items-center justify-start gap-2">
      <IconButton
        className={cn("w-auto px-0", {
          "md:hidden": !forceBackButton,
        })}
        data-testid="back-icon-btn"
        variant="standard"
        icon="chevronBackOutline"
        onClick={() => window.history.back()}
      />
      <CustomerTitleBarTitle title={title} />
    </div>
    {rightContent}
  </div>
);

interface CustomerTitleBarTitleProps {
  title: string;
}

export const CustomerTitleBarTitle = ({
  title,
}: CustomerTitleBarTitleProps) => (
  <h1 className="text-heading4-600 text-grey-900 md:text-heading5-600">
    {title}
  </h1>
);
