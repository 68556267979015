import { ReactNode, useEffect } from "react";

import { AdminNav } from "@/modules/admin/AdminNav";
import { authState } from "@/modules/auth/authState";
import { GlobalHeader } from "@/modules/common/GlobalHeader";
import { Router } from "@/routing/router";

export const AdminLayout = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    authState.load().then(({ account }) => {
      if (account?.role !== "admin") {
        Router.push("Home");
      }
    });
  }, []);

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 flex">
      <AdminNav />
      <div className="flex flex-grow flex-col overflow-auto">
        <GlobalHeader hasSideNav={true} />
        {children}
      </div>
    </div>
  );
};

export const AdminTitleBar = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-row items-center justify-between px-5 py-5 text-heading5-600 text-grey-900 md:px-8">
      {children}
    </div>
  );
};

export const AdminTitleBarTitle = ({ title }: { title: string }) => {
  return <span className={"text-heading5-600 text-grey-900"}>{title}</span>;
};

export const AdminContent = ({ children }: { children: ReactNode }) => {
  return <div className={"px-5 pb-5 pt-4 md:px-8 md:pb-8"}>{children}</div>;
};
