import { IconName } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export const IconCell = ({
  title,
  subtitle,
  icon,
  iconColor,
  iconBgColor,
}: {
  title: string;
  subtitle?: string | null | undefined;
  icon: IconName;
  iconColor: string;
  iconBgColor: string;
}) => {
  return (
    <div className={"flex items-center justify-between"}>
      <div className={"pr-3"}>
        <div
          className="flex h-10 rounded-full p-2"
          style={{
            backgroundColor: iconBgColor,
          }}>
          <Icon name={icon} size={24} color={iconColor} />
        </div>
      </div>

      <div>
        <div className="flex items-center truncate text-base font-semibold text-grey-900">
          {title}
        </div>
        {subtitle && (
          <div className="truncate text-sm font-normal text-grey-600">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};
