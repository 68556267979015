import { ReactNode } from "react";

import { cn } from "shared/lib";

type MainNavProps = {
  children: ReactNode;
  isExpanded: boolean;
  isVisible: boolean;
};

export const MainNav = ({ children, isExpanded, isVisible }: MainNavProps) => (
  <div
    className={cn(
      "relative z-20 flex h-full w-[256px] min-w-[256px] flex-col overflow-y-auto overflow-x-visible pb-28",
      "bg-brand text-white shadow-xl transition-all",
      "max-xl:absolute",
      isExpanded ? "ml-0" : "ml-[-184px]",
      isVisible ? "" : "ml-[-256px]",
    )}>
    <div
      className={cn(
        "flex h-full flex-col justify-between",
        isExpanded ? "w-full" : "w-[72px] self-end",
      )}>
      {children}
    </div>
  </div>
);
