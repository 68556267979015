import { Chip, useChipVariations } from "shared/components";

import { DocWrapper } from "@/modules/admin/docs/DocWrapper";

export const ChipPage = () => {
  const variations = useChipVariations();
  return (
    <div>
      <DocWrapper title="Chip" component={Chip} options={variations} />
    </div>
  );
};
