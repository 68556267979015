import { AddStudentButton } from "./AddStudentButton";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ButtonComponent } from "../../../interfaces";

interface SummaryPageHeaderProps {
  Button: ButtonComponent;
  showCreateForm: () => void;
  title?: string;
}
export const SummaryPageHeader = ({
  Button,
  showCreateForm,
  title,
}: SummaryPageHeaderProps) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className="flex flex-row justify-between items-center">
      <Text className={"text-heading5-600 text-grey-900"}>
        {title ?? "Summary"}
      </Text>
      <AddStudentButton Button={Button} showCreateForm={showCreateForm} />
    </View>
  );
};
