import { useEffect, useState } from "react";

import "./emailPreview.css";
import { Element, Root } from "hast";
import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import { visit } from "unist-util-visit";

import { EmailBodyProps } from "shared/lib";

const markDownToHtml = async (markdown: string) => {
  const addCustomStyles = () => {
    return (tree: Root) => {
      visit(tree, "element", (node: Element) => {
        if (node.tagName === "a") {
          node.properties = {
            ...node.properties,
            style: "color: -webkit-link; text-decoration: underline;",
            target: "_blank",
          };
        }
      });
    };
  };

  const file = await unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(addCustomStyles)
    .use(rehypeStringify)
    .process(markdown);

  return String(file);
};

export const EmailBody = ({ body }: EmailBodyProps) => {
  const [messageBody, setMessageBody] = useState<string>("");

  useEffect(() => {
    markDownToHtml(body).then(setMessageBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={"email-body"} dangerouslySetInnerHTML={{ __html: messageBody }} />
  );
};
