import { useAvatarSubmit, useFormActions } from "shared/components";
import { getInitials } from "shared/lib";

import { uploadFile } from "@/lib/api/uploadFile";
import { AvatarUploader } from "@/modules/common/form/AvatarUploader";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";

export const AvatarUploadForm = ({
  dto,
}: {
  dto: {
    firstname: string;
    lastname: string | null | undefined;
    profilePicture: string | null;
    id: string;
  };
}) => {
  const { setIsLoading } = useFormActions();
  const { closeSheet } = useSheet();
  const { addNewAvatar, deleteAvatar } = useAvatarSubmit(dto, closeSheet);

  return (
    <>
      <AvatarUploader
        defaultPath={dto.profilePicture}
        preview={(
          path?: string | null | undefined,
          isLoading?: boolean | undefined,
        ) => (
          <Avatar
            name={getInitials(dto)}
            size={"xl"}
            src={path ?? undefined}
            loading={isLoading ?? false}
          />
        )}
        onUpload={async file => {
          addNewAvatar(await uploadFile(file));
        }}
        onRemove={async () => {
          deleteAvatar();
        }}
        onLoading={isLoading => {
          setIsLoading(isLoading);
        }}
      />
    </>
  );
};
