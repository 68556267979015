import { DiscountSchemeDto } from "@justraviga/classmanager-sdk";

import {
  discountSchemeCardDetails,
  discountSchemeCardIcon,
  discountSchemeSubtitle,
  enumLabel,
} from "shared/lib";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { useDiscountSchemeActions } from "@/modules/company/billing/discountSchemes/useDiscountSchemeActions";
import { Router } from "@/routing/router";

export const DiscountSchemeCard = ({
  discountScheme,
}: {
  discountScheme: DiscountSchemeDto;
}) => {
  const discountSchemeDetails = discountSchemeCardDetails(discountScheme);
  const { deleteOne, updateStatus } = useDiscountSchemeActions();
  const menuItems: ActionMenuItemProps[] = [
    {
      title: discountSchemeDetails["actionTitle"],
      onClick: () => updateStatus(discountScheme.id, !discountScheme.enabled),
    },
    {
      title: "Delete",
      onClick: () => deleteOne(discountScheme),
      destructive: true,
    },
  ];

  return (
    <BaseCard
      icon={discountSchemeCardIcon[discountScheme.scheme]}
      iconPosition={"top"}
      headerActions={menuItems}
      onClick={() =>
        Router.push("DiscountSchemeDetails", { id: discountScheme.id })
      }
      title={enumLabel("discountSchemeType", discountScheme.scheme)}
      titleColor={"grey-900"}
      titleSize={16}
      titleWeight={400}
      description={discountSchemeSubtitle[discountScheme.scheme]}
      gap={1}
      footerSlot={
        <div className={"mt-4 flex justify-start"}>
          <Chip
            label={discountSchemeDetails["chipLabel"]}
            variant={discountSchemeDetails["chipVariant"]}
            leftIcon={discountSchemeDetails["chipIcon"]}
          />
        </div>
      }
    />
  );
};
