import { LessonDto } from "@justraviga/classmanager-sdk";

import { uniqueValuesForKey } from "../../../../collectionUtils";
import { colors } from "../../../../colors";
import { cn } from "../../../../cssUtils";
import { generateDateRange } from "../../../../dateUtils";
import { formatDate, formatDateTime } from "../../../../intlFormatter";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  BaseCardComponent,
  ButtonComponent,
  IconComponent,
} from "../../../interfaces";
import { ContentPlaceholder } from "../../../ui/ContentPlaceholder";
import {
  GoToAttendancesFunction,
  GoToLessonFunction,
  LessonCard,
} from "../dashboard/LessonCard";

export const StaffScheduleWidget = ({
  BaseCard,
  Button,
  Icon,
  goToAttendances,
  goToLesson,
  staffId,
}: {
  BaseCard: BaseCardComponent;
  Button: ButtonComponent;
  Icon: IconComponent;
  goToAttendances: GoToAttendancesFunction;
  goToLesson: GoToLessonFunction;
  staffId: string;
}) => {
  const { useApi } = getPlatformFunctions();

  // Get all lessons for the week
  const startDate = formatDateTime(new Date(), "isoDate");
  const dates = generateDateRange(startDate, 7);
  const endDate = dates[6];
  const { data: lessons } = useApi("listOnDateLesson", {
    fromDate: startDate,
    toDate: endDate,
    where: {
      staffId: {
        equals: staffId,
      },
    },
  });

  // Fetch all attendance stats for these lessons
  const { data: lessonAttendances } = useApi(
    "statsAttendanceStats",
    {
      lessons: (lessons ?? []).map(lesson => ({
        classId: lesson.classId,
        date: lesson.date,
        time: lesson.startTime,
      })),
    },
    { enabled: !!lessons && lessons.length > 0 },
  );

  // Get all courses that relate to these lessons
  const courseIds = lessons ? uniqueValuesForKey("classId", lessons) : [];
  const { data: courses } = useApi("listCourse", {
    where: {
      id: {
        in: courseIds,
      },
      staffId: {
        equals: staffId,
      },
    },
  });
  const getCourse = (courseId: string) =>
    courses?.data.find(c => c.entity.id === courseId)?.entity;

  const getAttendance = (lesson: LessonDto) => {
    const attendance = lessonAttendances?.find(
      a =>
        a.classId === lesson.classId &&
        a.date === lesson.date &&
        a.time === lesson.startTime,
    );
    return attendance?.completePercent ?? 0;
  };

  const { Text, View } = useGenericComponents();

  return (
    <BaseCard
      collapsible={true}
      title={"Lessons"}
      bodySlot={
        <View className="">
          <Text className="text-heading4-600 font-semibold text-grey-900">
            Next 7 days
          </Text>
          {!lessons || lessons.length === 0 ? (
            <ContentPlaceholder
              icon="calendarClearOutline"
              title="No lessons today"
            />
          ) : (
            <View className="flex flex-col">
              {dates.map(date => {
                const lessonsForDay = lessons.filter(l => l.date === date);
                return (
                  <View
                    key={date}
                    className={cn(
                      "border-grey-300 flex flex-col space-y-3 py-5",
                      { "border-b": date !== endDate },
                    )}>
                    <View>
                      <Text className="text-label-400 text-grey-600">
                        {formatDate(date, "weekdayDayMonthYear")}
                        {lessonsForDay.length > 0
                          ? ` (${lessonsForDay.length} ${lessonsForDay.length === 1 ? "class" : "classes"})`
                          : ""}
                      </Text>
                    </View>

                    {lessonsForDay.length === 0 ? (
                      <View className="flex flex-row space-x-2 items-center my-5">
                        <Icon
                          name={"helpCircleOutline"}
                          size={24}
                          color={colors.grey[800]}
                        />
                        <Text className="text-body-400 text-grey-900">
                          No classes on this day
                        </Text>
                      </View>
                    ) : (
                      lessonsForDay.map(lesson => {
                        const course = getCourse(lesson.classId);
                        if (!course) {
                          return null;
                        }
                        const percentComplete = getAttendance(lesson);
                        return (
                          <View
                            key={[
                              lesson.classId,
                              lesson.date,
                              lesson.startTime,
                            ].join("")}>
                            <LessonCard
                              Button={Button}
                              Icon={Icon}
                              goToAttendances={goToAttendances}
                              goToLesson={goToLesson}
                              course={course}
                              lesson={lesson}
                              percentComplete={percentComplete}
                            />
                          </View>
                        );
                      })
                    )}
                  </View>
                );
              })}
            </View>
          )}
        </View>
      }
    />
  );
};
