import { useContext } from "react";

import { StripeContext } from "./StripeContext";

export function useStripeLib() {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error("useStripeLib must be used within a StripeProvider");
  }
  return context;
}
