import { useCallback } from "react";

import { StudentDto } from "@justraviga/classmanager-sdk";

import { getAgeInYears } from "../../../../dateUtils";
import { formatDate } from "../../../../intlFormatter";
import { dayjs } from "../../../../lib/dayjs";
import { getFullName } from "../../../../personUtil";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { Avatar } from "../../../ui/Avatar";

export const UpcomingBirthdaysWidget = () => {
  const { View, Text, BaseCard } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const endDate = dayjs().add(7, "day");

  const { data: students } = useApi("listBirthdaysStudent", {
    fromDate: dayjs().format("YYYY-MM-DD"),
    toDate: endDate.format("YYYY-MM-DD"),
  });

  const studentAgeOnBirthDay = useCallback((student: StudentDto) => {
    const age = getAgeInYears(student.dateOfBirth!) + 1;
    return `${age} year${age > 1 ? "s" : ""}`;
  }, []);

  return (
    <BaseCard
      collapsible={true}
      title={"Upcoming birthdays"}
      bodySlot={
        <View className={"space-y-2"}>
          <Text className={"text-2xl text-heading4-600"}>Next 7 days</Text>
          <View className={"flex flex-col space-y-2"}>
            {(students ?? []).map((student: StudentDto) => (
              <View
                className={
                  "flex flex-row items-center justify-between space-x-3 py-2"
                }
                key={student.id}>
                <View>
                  <Avatar
                    person={student}
                    size={"md"}
                    src={student.profilePicture}
                  />
                </View>
                <View className={"flex-grow space-y-1"}>
                  <Text className={"text-label-600"}>
                    {getFullName(student)}
                  </Text>
                  <Text className={"text-caption-400 text-grey-600"}>
                    {formatDate(student.dateOfBirth!, "dayMonthYear")}
                  </Text>
                </View>
                <Text className={"text-grey-600"}>
                  {studentAgeOnBirthDay(student)}
                </Text>
              </View>
            ))}
          </View>
        </View>
      }
    />
  );
};
