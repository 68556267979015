import { useContext } from "react";

import {
  SearchContext,
  SearchDialogContext,
  SearchResultsContext,
  SearchStatusContext,
} from "./searchContext";

export function useSearchStatus() {
  const context = useContext(SearchStatusContext);
  if (!context) {
    throw new Error("useSearchStatus must be used within a SearchProvider");
  }
  return context;
}

export function useSearchDialog() {
  const context = useContext(SearchDialogContext);
  if (!context) {
    throw new Error("useSearchDialog must be used within a SearchProvider");
  }
  return context;
}

export function useSearchResults() {
  const context = useContext(SearchResultsContext);
  if (!context) {
    throw new Error("useSearchResults must be used within a SearchProvider");
  }
  return context;
}

export function useSearch() {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
}
