import { useState } from "react";

import { CustomFieldEntity } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../../../platformSpecific";

export const useCustomFieldsPageData = () => {
  const { useData } = getPlatformFunctions();

  const [entityFilter, setEntityFilter] = useState<
    CustomFieldEntity | undefined
  >(undefined);

  const customFieldsResponse = useData("listCustomField", {
    selectAll: true,
  });

  // We need to know the difference between "no custom fields exist" and "none were found when filtering"
  const hasCustomFields = customFieldsResponse.data.length > 0;

  const filteredCustomFields = customFieldsResponse.data.filter(
    field => entityFilter === undefined || entityFilter === field.entity,
  );

  return {
    customFields: filteredCustomFields,
    hasCustomFields,
    entityFilter,
    setEntityFilter,
  };
};
