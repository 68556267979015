import { UpdateLocationRequest } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { useSettings } from "../useSettings";

type Schema = UpdateLocationRequest;

export const useLocationFormDefinition = () => {
  const { locale, use12HourClock } = useSettings("general");
  const clockType = use12HourClock ? "12-hour" : "24-hour";

  return new FormDefinitionBuilder<Schema>()
    .text("name", { label: "Name", required: true })
    .time("openTime", {
      label: "Opening time",
      required: true,
      locale,
      clockType,
    })
    .time("closeTime", {
      label: "Closing time",
      required: true,
      locale,
      clockType,
    })
    .group("Basic information", ["name"])
    .group("Opening hours", ["openTime", "closeTime"])
    .getDefinition() as FormDefinition<Schema>;
};

export const makeLocationCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: Schema) => {
    return api.locations.createLocation({
      updateLocationRequest: formData,
    });
  };

export const makeLocationUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: Schema) => {
    return api.locations.updateLocation({
      id,
      updateLocationRequest: formData,
    });
  };
