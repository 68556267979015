import { createContext } from "react";

import { RedirectContextType } from "@/modules/auth/redirect/authRedirectTypes";
import { CatalogueAuthReturnParams } from "@/modules/auth/redirect/authRedirectUtils";

export const AuthRedirectContext = createContext<RedirectContextType>({
  redirect: () => {},
  setRedirect: (_: CatalogueAuthReturnParams) => {},
  getRedirectProps: () => undefined,
});
