import { PropsWithChildren } from "react";

import { ResponsiveContainer } from "recharts";

export const ChartContainer = ({ children }: PropsWithChildren) => (
  <ResponsiveContainer width="100%" height="100%">
    {/* @ts-expect-error - there's a mismatch in the types of children from React and ResponsiveContainer */}
    {children}
  </ResponsiveContainer>
);
