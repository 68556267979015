import React, { Fragment, ReactNode } from "react";

import { cn } from "shared/lib";

interface ListItemProps {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  mainElement?: ReactNode;
  className?: string;
}

export const ListItem = ({
  leftElement,
  rightElement,
  mainElement,
  className,
}: ListItemProps) => {
  return (
    <div
      className={cn(
        "flex min-h-12 w-full items-center justify-between py-2",
        className,
      )}>
      {leftElement}
      {mainElement}
      {rightElement}
    </div>
  );
};

type ListChildren = React.ReactElement<ListItemProps>;

export interface ListProps {
  separator?: ReactNode;
  items: ListChildren[];
  className?: string;
  topSeparator?: ReactNode;
  bottomSeparator?: ReactNode;
}

export const List = ({
  separator,
  items,
  className,
  topSeparator,
  bottomSeparator,
}: ListProps) => {
  const listLength = items.length;
  return (
    <div className={"w-full"}>
      {topSeparator && topSeparator}
      <div className={className}>
        {items.map((item, index: number) => {
          const listItemProps = {
            ...item.props,
            className: cn(
              "py-2 flex min-h-12 items-center",
              {
                "text-grey-900 text-body-600": index === 0,
                "text-grey-600 text-label-400": index > 0,
              },
              item.props.className,
            ),
          };
          return (
            <Fragment key={index}>
              {React.cloneElement(item, listItemProps)}
              {separator &&
                index !== 0 &&
                index !== listLength - 1 &&
                separator}
            </Fragment>
          );
        })}
      </div>
      {bottomSeparator && bottomSeparator}
    </div>
  );
};
