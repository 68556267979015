import { useGenericComponents } from "../GenericComponentsProvider";
import { Chip } from "../ui/Chip";

type ChipValue = string | number;

export const MultiSelectChips = <Value extends ChipValue>({
  label,
  options,
  value,
  onChange,
}: {
  label?: string;
  onChange: (values: Value[]) => void;
  options: Array<{ label: string; value: Value; disabled?: boolean }>;
  value: Value[];
}) => {
  const { Pressable, Text, View } = useGenericComponents();
  return (
    <View>
      {label && (
        <Text className="mb-3 text-body-600 text-grey-900 font-semibold">
          {label}
        </Text>
      )}
      <View className="flex flex-row flex-wrap gap-3">
        {options.map(option => {
          const isSelected = value.includes(option.value);
          const onClick = () => {
            if (option.disabled) {
              return;
            }
            if (isSelected) {
              onChange([...value.filter(value => value !== option.value)]);
            } else {
              onChange([...value, option.value]);
            }
          };
          return (
            <Pressable
              key={option.value}
              onClick={onClick}
              className={`${option.disabled ? "cursor-not-allowed" : "cursor-pointer"}`}>
              <Chip
                label={option.label}
                variant={isSelected ? "primary" : "outline"}
                disabled={option.disabled}
              />
            </Pressable>
          );
        })}
      </View>
    </View>
  );
};
