import { ReactNode } from "react";

import { Api } from "./api";
import { PlatformEntityActions } from "./components/actions/actionInterfaces";
import { UseApi, UseData } from "./components/apiQueryFactory";
import { EventType } from "./lib/tracking/eventType";

// A handy place to define the common interfaces of platform-specific functions
type GetEnvFn = (name: string) => string | undefined;
type RenderMarkdownFn = (markdown: string) => ReactNode;
type CopyToClipboardFn = (
  textToCopy: string,
  successMessage: string,
) => Promise<void>;
type OpenWebsiteFn = (url: string) => void;

type PlatformFunctions = {
  api: Api;
  getEnv: GetEnvFn;
  renderMarkdown: RenderMarkdownFn;
  useApi: UseApi;
  useData: UseData;
  usePlatformEntityActions: PlatformEntityActions;
  copyToClipboard: CopyToClipboardFn;
  openWebsite: OpenWebsiteFn;
  eventTracker: (eventName: EventType, payload: unknown) => void;
};
type PlatformFunctionKeys = keyof PlatformFunctions;

// The actual implementations are stored here
const platformFunctions = {} as PlatformFunctions;

export function setPlatformFunctions(functions: PlatformFunctions) {
  Object.assign(platformFunctions, functions);
}

/**
 * @deprecated use `getPlatformFunctions` instead and destructure the property you need
 */
export function getPlatformFunction<T extends PlatformFunctionKeys>(
  name: T,
): (typeof platformFunctions)[T] {
  return platformFunctions[name];
}

export function getPlatformFunctions() {
  return platformFunctions;
}
