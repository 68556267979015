import { useEffect } from "react";

import { useAuthState, useCatalogueContext } from "shared/components";
import { SignWaivers } from "shared/components";

import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { useAuthRedirect } from "@/modules/auth/redirect/useAuthRedirect";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { FamilyChecksForBasket } from "@/modules/customer/checkout/FamilyChecksForBasket";

export const MemberBasketPage = () => {
  const { setRedirect } = useAuthRedirect();
  const { company } = useCatalogueContext();
  const { isLoggedIn } = useAuthState();

  useEffect(() => {
    if (!isLoggedIn) {
      setRedirect({
        name: "MemberBasket",
        slug: company.slug,
      });
    }
  }, [isLoggedIn, company.slug, setRedirect]);

  return (
    <SignWaivers
      formBuilder={useFormBuilder}
      loadingSpinner={<LoadingSpinnerDark />}
      NonAuthLayout={NonAuthLayout}
      Button={Button}
      BaseCard={BaseCard}>
      <FamilyChecksForBasket />
    </SignWaivers>
  );
};
