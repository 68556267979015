import { ClickableSettingItem } from "shared/lib";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";

export const SettingGroup = ({
  name,
  items,
}: {
  name: string;
  items: Array<ClickableSettingItem>;
}) => (
  <div className={"flex flex-col"}>
    <p className={"pb-4 pt-3 text-heading6-600"}>{name}</p>
    <div className="flex flex-col gap-5">
      {items.map(item => (
        <BaseCard
          key={item.name}
          icon={item.icon}
          iconPosition="left-framed"
          title={item.name}
          titleSize={16}
          titleColor={"grey-900"}
          description={item.description}
          descriptionColor={"grey-600"}
          descriptionSize={14}
          onClick={item.onClick}
          gap={1}
        />
      ))}
    </div>
  </div>
);
