import * as React from "react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "shared/lib";
import { useBreakpoint } from "shared/lib";

const triggerVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium  transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 hover:cursor-pointer ",
  {
    variants: {
      variant: {
        default: "bg-white border border-grey-300 text-grey-900",
      },
      size: { default: "h-10 px-3 py-2" },
    },
  },
);

export interface TriggerProps
  extends Omit<React.ButtonHTMLAttributes<HTMLDivElement>, "placeholder">,
    VariantProps<typeof triggerVariants> {
  placeholder?: string | React.ReactNode;
  children?: React.ReactNode;
  name?: string;
}

export const SelectTrigger = React.forwardRef<HTMLDivElement, TriggerProps>(
  ({ placeholder, className, children, name, disabled, ...props }, ref) => {
    const breakpoints = useBreakpoint();
    const defaultPlaceholder = breakpoints.md ? "Click to select..." : "Select";
    return (
      <div
        tabIndex={0}
        className={cn(
          triggerVariants({ variant: "default", size: "default" }),
          className,
          disabled && "hover:cursor-not-allowed focus:border-grey-300",
          "mix-w-fit flex items-center justify-between",
        )}
        data-testid="baseSelectTrigger"
        data-name={name}
        ref={ref}
        {...props}>
        <span className="min-w-0 text-body-400 text-grey-500">
          {placeholder || defaultPlaceholder}
        </span>
        {children && children}
      </div>
    );
  },
);
