import React, { MouseEventHandler, ReactNode } from "react";

import {
  FocusOutsideEvent,
  PointerDownOutsideEvent,
} from "@radix-ui/react-dismissable-layer";

import { cn } from "shared/lib";

import {
  Close,
  Content,
  Header,
  Root,
} from "@/modules/common/overlays/dialog/Base";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export interface SheetProps {
  side?: "top" | "bottom" | "right";
  title?: string | React.ReactNode;
  content?: React.ReactNode | React.ReactNode[];
  footer?: React.ReactNode | React.ReactNode[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onCloseIconClick?: MouseEventHandler<HTMLButtonElement>;
  onInteractOutside?: (
    event: PointerDownOutsideEvent | FocusOutsideEvent,
  ) => void;
  isModal?: boolean;
  padding?: string;
  zIndex?: string | number;
  dialog?: boolean;
  containerPadding?: number;
  hasBackdropOnOpen?: boolean;
}

export const Sheet = ({
  side = "right",
  title,
  content,
  footer,
  open,
  onOpenChange,
  onCloseIconClick,
  isModal = true,
  padding = "px-5 py-5",
  zIndex = 50,
  containerPadding = 5,
  hasBackdropOnOpen = false,
}: SheetProps) => {
  const handleInteractOutside = React.useCallback(
    (event: PointerDownOutsideEvent | FocusOutsideEvent) => {
      // Only handle pointer events
      if (event.type !== "dismissableLayer.pointerDownOutside") return;

      event.preventDefault();

      // Track the initial target element
      const initialTarget = event.target;

      const handlePointerUp = (upEvent: PointerEvent) => {
        const finalTarget = upEvent.target;

        // Only close if mouse up on the same element as mouse down
        if (initialTarget === finalTarget) {
          onOpenChange?.(false);
        }

        // Cleanup
        document.removeEventListener("pointerup", handlePointerUp);
      };

      document.addEventListener("pointerup", handlePointerUp, { once: true });
    },
    [onOpenChange],
  );

  return (
    <>
      {hasBackdropOnOpen && open && (
        <div
          className={
            "fixed left-0 top-0 z-50 h-full w-full bg-overlay-grey-light"
          }
        />
      )}
      <Root modal={isModal} open={open} onOpenChange={onOpenChange}>
        <Content
          side={side}
          onInteractOutside={handleInteractOutside}
          className={`z-${zIndex}`}>
          <div className={`flex flex-row-reverse justify-between ${padding}`}>
            <Close className="-mr-2 -mt-1" onClick={onCloseIconClick} asChild>
              <IconButton
                variant="standard"
                icon={"closeOutline"}
                size="lg"
                aria-label="Close"
              />
            </Close>
            {typeof title == "string" ? <Header title={title} /> : title}
          </div>
          <div
            className={cn(
              "flex flex-grow flex-col space-y-6 overflow-y-auto",
              `px-${containerPadding}`,
              {
                "space-y-8 ": side === "right",
              },
            )}>
            {content && content}
          </div>
          {footer}
        </Content>
      </Root>
    </>
  );
};

export const SheetFooter = ({
  leftContent,
  rightContent,
}: {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}) => (
  <div
    className={cn(
      "sticky bottom-0 flex flex-col-reverse gap-4 border-t-[0.5px] border-grey-300 bg-white px-5 py-4 drop-shadow-md md:flex-row md:justify-between",
      { "md:justify-end": !leftContent },
    )}>
    {leftContent}
    {rightContent}
  </div>
);
