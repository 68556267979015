// temporarily addesd in pickerItemSpacing since the SDK didnt support it yet it does work

import { Appearance } from "@stripe/stripe-js";

import { colors } from "shared/lib";

// see https://docs.stripe.com/elements/appearance-api?platform=web#additional-variables
type AdaptedAppearance = Appearance & {
  variables: { pickerItemSpacing: string };
};

export const appearance: AdaptedAppearance = {
  theme: "stripe",
  variables: {
    colorPrimary: colors.grey[900],
    colorBackground: colors.white,
    colorText: colors.grey[900],
    colorDanger: colors.grey[900],
    fontFamily: '"Open Sans", system-ui, sans-serif',
    spacingUnit: "4px",
    fontSizeBase: "16px",
    fontSizeSm: "14px",
    borderRadius: "4px",
    colorTextSecondary: colors.grey[600],
    colorTextPlaceholder: colors.grey[500],
    gridRowSpacing: "20px",
    gridColumnSpacing: "20px",
    tabSpacing: "10px",
    pickerItemSpacing: "20px",
    fontWeightMedium: "400",
  },
  rules: {
    ".Tab": {
      border: "1px solid " + colors.grey[300],
      boxShadow: "none",
      backgroundColor: "white",
    },
    ".Tab:hover": {
      color: "var(--colorText)",
      backgroundColor: colors.grey[100],
      border: "1px solid " + colors.grey[300],
      boxShadow: "none",
    },
    ".Tab:focus": {
      border: "1px solid " + colors.grey[900],
      boxShadow: "none",
    },
    ".Tab:active": {
      border: "1px solid " + colors.grey[900],
      boxShadow: "none",
    },
    ".Tab--selected": {
      border: "1px solid " + colors.grey[900],
      boxShadow: "none",
    },
    ".Tab--selected:hover": {
      border: "1px solid " + colors.grey[900],
      backgroundColor: colors.grey[100],
      boxShadow: "none",
    },
    ".Tab--selected:focus": {
      border: "1px solid " + colors.grey[900],
      boxShadow: "none",
    },
    ".TabLabel": {
      color: colors.grey[900],
      borderColor: colors.grey[900],
      backgroundColor: colors.grey[900],
    },
    ".TabIcon": {
      fill: colors.grey[900],
    },
    ".Label": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "140%",
      color: colors.grey[900],
    },
    ".Input": {
      border: "1px solid " + colors.grey[300],
      boxShadow: "none",
      backgroundColor: "white",
    },
    ".Input:focus": {
      border: "1px solid " + colors.grey[900],
      boxShadow: "none",
    },
    ".Input--invalid": {
      boxShadow: "none",
      border: "1px solid " + colors.red[600],
      backgroundColor: colors.red[100],
    },
    ".Error": {
      color: colors.red[600],
      fontSize: "12px",
      lineHeight: "140%",
    },
    ".PickerItem": {
      boxShadow: "none",
      backgroundColor: colors.white,
      border: "1px solid " + colors.grey[300],
    },
    ".PickerItem--selected": {
      boxShadow: "none",
      backgroundColor: colors.white,
      border: "1px solid " + colors.grey[900],
    },
    ".PickerItem--highlight": {
      boxShadow: "none",
      backgroundColor: colors.white,
      border: "1px solid " + colors.grey[900],
    },
    ".PickerItem--selected:hover": {
      boxShadow: "none",
      backgroundColor: colors.grey[100],
      border: "1px solid " + colors.grey[900],
    },
  },
};
