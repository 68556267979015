import quotes from "../../../assets/quotes.svg";

interface Testimonial {
  name: string;
  position: string;
  content: string;
  image: string;
}

const testimonials: Testimonial[] = [
  {
    name: "NICOLE MANNING",
    position: "Studio Owner",
    content:
      "We changed over to Class Manager this year and I can't believe I didn't do it sooner.  It's been fantastic!",
    image: "/review-1-logo.png",
  },
  {
    name: "KATHERINE-LUCY BATES",
    position: "Studio Owner",
    content:
      "Before Class Manager I did my studio management all by hand, so it was a complete game changer",
    image: "/review-2-logo.png",
  },
];

export const TestimonialSection = () => {
  const testimonial =
    testimonials[Math.floor(Math.random() * testimonials.length)];

  return (
    <div
      style={{
        padding: "5rem",
        backgroundImage: "url(/register-bg.png)",
        backgroundPosition: "bottom",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      className={
        "overflow-clip rounded-br rounded-tr bg-purple-900 max-lg:hidden"
      }>
      <div className={"space-y-8"}>
        <img src={quotes} alt="quotes" className="h-[21px]" />
        <div className={"text-heading4-600 text-white"}>
          {testimonial.content}
        </div>
        <div className={"flex flex-row items-center space-x-3"}>
          <div className={"w-16"}>
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className={"h-16 w-16 rounded border border-overlay-white-heavy"}
            />
          </div>
          <div className={"flex flex-col space-y-1"}>
            <p className={"text-heading6-600 uppercase text-white"}>
              {testimonial.name}
            </p>
            <p className={"text-label-400 text-white"}>
              {testimonial.position}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
