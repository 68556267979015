import React, { ReactNode, useState } from "react";

import { cn, colors, getAccessibleColorClass } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { useNav } from "@/modules/common/nav/contexts/useNav";
import { MainNav } from "@/modules/common/nav/MainNav";
import { NavAppVersion } from "@/modules/common/nav/NavAppVersion";
import { NavHideButton } from "@/modules/common/nav/NavHideButton";
import { NavItemList } from "@/modules/common/nav/NavItemList";
import { NavUnderlay } from "@/modules/common/nav/NavUnderlay";
import { SubNav } from "@/modules/common/nav/SubNav";
import { IconName } from "@/modules/common/ui/icon/Icon";

export type NavLink = {
  path: string;
  label: string;
  icon: IconName;
  sortSubItems?: boolean;
  subItems?: NavSubLink[];
};

type ComponentSubLink = {
  component: ReactNode;
};

type RegularSubLink = {
  path: string;
  label: string;
};

export type NavSubLink = RegularSubLink | ComponentSubLink;

function matchPathsToLocation(
  links: NavLink[] | NavSubLink[],
  location: Location,
): boolean {
  return (
    // Make sure we don't compare ComponentSubLinks here because they don't contain a path
    links
      .filter(link => !("component" in link))
      .findIndex(link =>
        location.pathname.includes((link as RegularSubLink).path),
      ) > -1
  );
}

function findCurrentPageLink(links: NavLink[]): NavLink {
  return (links.find((link: NavLink) => {
    if (link.path === location.pathname) {
      // Exact match on this path, we have successfully found the current page
      return true;
    }

    if (!link.subItems) {
      // No submenu items, so we can't match this link
      return false;
    }

    // Attempt to match on this link's submenu items
    return matchPathsToLocation(link.subItems, location);
  }) ?? null)!; // Provided the current page exists in the menu system, this will always find a match
}

export const Nav = ({
  topLinks,
  bottomLinks,
}: {
  topLinks: NavLink[];
  bottomLinks: NavLink[];
}) => {
  const { isShowing: isFloatingNavVisible, hide: hideFloatingNav } = useNav();

  const [isExpanded, setIsExpanded] = useState(true);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<NavLink | null>(
    findCurrentPageLink([...topLinks, ...bottomLinks]),
  );

  const breakpoint = useBreakpoint();
  const shouldFloatNav = !breakpoint.xl;

  const isCollapsable = !shouldFloatNav;
  const shouldShowUnderlay =
    (shouldFloatNav && isFloatingNavVisible) || isSubMenuOpen;
  const shouldShowCollapseButton =
    (isFloatingNavVisible || !shouldFloatNav) && isCollapsable;

  /**
   * Click handler for when we actually navigate to a new page.  Triggered from top-level menu items
   * that do not have a submenu, and from items on a submenu.
   */
  const handleNavigation = React.useCallback(() => {
    // Refresh the selected menu item
    setSelectedMenu(findCurrentPageLink([...topLinks, ...bottomLinks]));

    // Hide the submenu
    setIsSubMenuOpen(false);

    // Re-expand the nav if the user is not allowed to toggle it
    if (!isCollapsable) {
      setIsExpanded(true);
    }

    // Hide the nav if we're on small screens
    if (shouldFloatNav && isFloatingNavVisible) {
      hideFloatingNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFloatNav, isFloatingNavVisible, isCollapsable]);

  const hideSubMenu = React.useCallback(() => {
    setSelectedMenu(findCurrentPageLink([...topLinks, ...bottomLinks]));
    setIsSubMenuOpen(false);

    // re-expand the nav if the user is not allowed to toggle it
    if (!isCollapsable) {
      setIsExpanded(true);
    }
  }, [bottomLinks, topLinks, isCollapsable]);

  const showSubMenu = React.useCallback(
    (item: NavLink) => {
      setSelectedMenu(item);
      setIsSubMenuOpen(true);

      // collapse the nav if the user is not allowed to toggle it
      if (!isCollapsable) {
        setIsExpanded(false);
      }
    },

    [isCollapsable],
  );

  const handleUnderlayHide = React.useCallback(() => {
    if (shouldFloatNav) {
      hideFloatingNav();
    }

    hideSubMenu();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFloatNav]);

  const handleNavCollapse = React.useCallback(() => {
    setIsExpanded(expanded => !expanded);
  }, []);

  const textColor = getAccessibleColorClass(colors.brand.DEFAULT);

  return (
    <div className="z-10">
      {shouldShowUnderlay && <NavUnderlay onClick={handleUnderlayHide} />}

      <MainNav
        isExpanded={isExpanded}
        isVisible={isFloatingNavVisible || !shouldFloatNav}>
        <div
          className={cn(`my-6 flex items-center`, {
            "justify-center": !isExpanded,
            "justify-start": isExpanded,
            "px-8": isExpanded,
          })}>
          <ClassManagerLogo
            color={textColor}
            singleLetter={!isExpanded}
            width={isExpanded ? "161px" : "24px"}
            height="24px"
          />
        </div>
        {/* top nav */}
        <div className={"flex-grow pb-20 pt-4"}>
          <NavItemList
            items={topLinks}
            showSubMenu={showSubMenu}
            selectedMenuPath={selectedMenu?.path}
            isExpanded={isExpanded}
            onNavigate={handleNavigation}
          />
        </div>

        {/* bottom nav */}
        <NavItemList
          items={bottomLinks}
          showSubMenu={showSubMenu}
          selectedMenuPath={selectedMenu?.path}
          isExpanded={isExpanded}
          onNavigate={handleNavigation}
        />

        <NavAppVersion isExpanded={isExpanded} />
      </MainNav>

      <SubNav
        items={selectedMenu?.subItems}
        onCloseMenuClick={hideSubMenu}
        isExpanded={isExpanded}
        onNavigate={handleNavigation}
        isOpen={isSubMenuOpen}
      />

      {shouldShowCollapseButton && (
        <NavHideButton onClick={handleNavCollapse} isExpanded={isExpanded} />
      )}
    </div>
  );
};
