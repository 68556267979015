import {
  AggregateClassDto,
  SeasonDto,
  WeekDay,
} from "@justraviga/classmanager-sdk";

import { createClassUpdateFormDefaultValues } from "../../classUtils";
import { ClassEntity } from "../../entities";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeClassCreateFormDefinition,
  makeClassCreateRequest,
  makeClassUpdateFormDefinition,
  makeClassUpdateRequest,
} from "../formDefinitions/courseForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { EntityActions } from "../useDefaultEntityActions";
import { useSettings } from "../useSettings";

type Model = AggregateClassDto;

export type ClassImplementedActions = Omit<
  EntityActions<Model>,
  "showUpdateForm"
> & {
  showCreateFormWithWeekDay: (season: SeasonDto, dayOfWeek: WeekDay) => void;
  showUpdateForm: (entity: ClassEntity, season: SeasonDto) => void;
  showPreview: (entity: ClassEntity, season: SeasonDto) => void;
};

export const useSharedCourseActions = () => {
  const { api, useApi, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "course",
  });
  const { enableOnPortal } = useSettings("trial");

  const { data: taxRatesResponse } = useApi("listTaxRate", { selectAll: true });
  const taxRates = taxRatesResponse?.data ?? [];

  const defaultTaxRateId = taxRates.find(rate => rate.isDefault)?.id;

  return {
    showCreateFormWithWeekDay: (season: SeasonDto, dayOfWeek: WeekDay) =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeClassCreateRequest({
              seasonId: season.id,
            })}
            defaultValues={{
              startTime: "12:00",
              discountable: true,
              portalEnrolmentEnabled: true,
              limitedCapacity: false,
              capacity: 0,
              dayOfWeek: dayOfWeek,
              taxRateId: defaultTaxRateId,
              trialsEnabled: enableOnPortal,
            }}
            formDefinitionHook={makeClassCreateFormDefinition({
              pricingScheme: season.pricingScheme,
              taxRates,
            })}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showCreateForm: (season: SeasonDto) =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeClassCreateRequest({
              seasonId: season.id,
            })}
            defaultValues={{
              startTime: "12:00",
              discountable: true,
              portalEnrolmentEnabled: true,
              limitedCapacity: false,
              capacity: 0,
              taxRateId: defaultTaxRateId,
              trialsEnabled: enableOnPortal,
            }}
            formDefinitionHook={makeClassCreateFormDefinition({
              pricingScheme: season.pricingScheme,
              taxRates,
            })}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (classEntity: ClassEntity, season: SeasonDto) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeClassUpdateRequest({
              id: classEntity.class.id,
              existingImage: classEntity.class.image,
            })}
            defaultValues={createClassUpdateFormDefaultValues(
              classEntity.props,
              taxRates,
              defaultTaxRateId,
            )}
            formDefinitionHook={makeClassUpdateFormDefinition({
              pricingScheme: season.pricingScheme,
              taxRates,
            })}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),

    deleteOne: (aggCourseDto: Model) =>
      defaultActions.deleteOne(() =>
        api.courses.deleteCourse({ id: aggCourseDto.entity.id }),
      ),

    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () =>
          api.courses.deleteManyCourse({ deleteManyCourseRequest: { ids } }),
        ids.length,
      ),

    archive: (aggCourseDto: Model) =>
      defaultActions.archive(() =>
        api.courses.archiveCourse({ id: aggCourseDto.entity.id }),
      ),

    archiveMany: (ids: string[]) =>
      defaultActions.archiveMany(
        () => api.courses.archiveCourses({ archiveCoursesRequest: { ids } }),
        ids.length,
      ),

    unarchive: (aggCourseDto: Model) =>
      defaultActions.unarchive(() =>
        api.courses.unarchiveCourse({ id: aggCourseDto.entity.id }),
      ),

    unarchiveMany: (ids: string[]) =>
      defaultActions.unarchiveMany(() =>
        api.courses.unarchiveCourses({ unarchiveCoursesRequest: { ids } }),
      ),
  };
};
