import { AttendanceStatus } from "@justraviga/classmanager-sdk";

import { AttendanceRecord, cn, getInitials } from "shared/lib";

import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { AbsentIconButton } from "@/modules/company/classPlanner/lessons/attendances/row/AbsentIconButton";
import { getAttendanceRecordChips } from "@/modules/company/classPlanner/lessons/attendances/row/attendanceUtilities";
import { PresentIconButton } from "@/modules/company/classPlanner/lessons/attendances/row/PresentIconButton";

export const AttendanceRow = ({
  record,
  status,
  onChangeStatus,
}: {
  record: AttendanceRecord;
  status: AttendanceStatus;
  onChangeStatus: (status: AttendanceStatus) => void;
}) => {
  const { showPreview } = useStudentActions();

  const chips = getAttendanceRecordChips(record);

  return (
    <div className={"flex justify-between px-5 py-3 sm:p-3"}>
      <div
        className={cn("flex space-x-3 overflow-hidden", {
          "items-center": chips.length === 0,
        })}>
        <div>
          <Avatar
            size="lg"
            src={record.student.profilePicture ?? undefined}
            name={getInitials(record.student)}
          />
        </div>
        <div className={"space-y-2 overflow-hidden"}>
          <div
            className={"flex cursor-pointer items-center space-x-2 "}
            onClick={() => showPreview(record.student)}>
            <span className={"truncate text-body-600 text-grey-900"}>
              {record.student.firstname}

              <span className={"pl-1 text-body-400"}>
                {record.student.lastname}
              </span>
            </span>

            <div className={"hidden sm:flex"}>
              <Icon name={"chevronForwardOutline"} size={16} />
            </div>
            <div className={"sm:hidden"}>
              <Icon name={"chevronDown"} size={16} />
            </div>
          </div>

          {chips.length > 0 && (
            <div className={"flex gap-x-2"}>
              {chips.map((chip, index) => (
                <div key={`attendance-${record.student.id}-chips-${index}`}>
                  {chip}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={"-mr-2 flex items-center space-x-4 sm:items-start"}>
        <AbsentIconButton
          status={status}
          onClick={() => {
            onChangeStatus(
              status === "absent"
                ? AttendanceStatus.Unknown
                : AttendanceStatus.Absent,
            );
          }}
        />
        <PresentIconButton
          status={status}
          onClick={() => {
            onChangeStatus(
              status === "present"
                ? AttendanceStatus.Unknown
                : AttendanceStatus.Present,
            );
          }}
        />
      </div>
    </div>
  );
};
