import { AdminLayout } from "@/modules/admin/AdminLayout";

export const DocsPage = () => {
  return (
    <AdminLayout>
      <h2 className="mb-5 text-heading2-400">Docs Home</h2>
      <p>Select a component from the left hand menu to view documentation...</p>
    </AdminLayout>
  );
};
