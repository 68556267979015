import { AggregateClassDto } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { useBasket } from "../../../basket/useBasket";
import { useCourseSnippetTrialAction } from "../../../courseSearch/courseSnippetTrialAction";
import { ButtonComponent, Sheet } from "../../../interfaces";
import { FullScreenModal } from "../../../interfaces";

export type CoursePreviewMode = "enrol" | "trial" | "full";

interface CoursePreviewButtonProps {
  course: AggregateClassDto;
  mode: CoursePreviewMode;
  Button: ButtonComponent;
  sheet: Sheet | FullScreenModal;
  onClickWaitingList?: (courseId: string) => void;
}

export const CoursePreviewButton = ({
  course,
  mode,
  Button,
  sheet,
  onClickWaitingList,
}: CoursePreviewButtonProps) => {
  return match(mode)
    .with("enrol", () => (
      <EnrolmentButton courseId={course.entity.id} Button={Button} />
    ))
    .with("trial", () => (
      <TrialButton course={course} sheet={sheet} Button={Button} />
    ))
    .with("full", () => (
      <WaitingButton
        courseId={course.entity.id}
        Button={Button}
        onClick={onClickWaitingList!}
      />
    ))
    .exhaustive();
};

const EnrolmentButton = ({
  courseId,
  Button,
}: {
  courseId: string;
  Button: ButtonComponent;
}) => {
  const { addEnrolment, removeEnrolment, contents } = useBasket();
  const isInBasket = contents.enrolments.has(courseId);

  return isInBasket ? (
    <Button
      variant={"success"}
      text={"Added to summary"}
      onClick={() => removeEnrolment(courseId)}
      size={"lg"}
      isFullWidth={true}
      leftIcon={"checkmarkCircleOutline"}
    />
  ) : (
    <Button
      variant={"brand"}
      text={"Enroll"}
      onClick={() => addEnrolment(courseId)}
      size={"lg"}
      isFullWidth={true}
    />
  );
};

const TrialButton = ({
  course,
  sheet,
  Button,
}: Omit<CoursePreviewButtonProps, "mode">) => {
  const { showTrialForm } = useCourseSnippetTrialAction({
    course,
    sheet,
    Button,
    basket: useBasket().contents,
  });

  return (
    <Button
      variant={"secondary"}
      text={"Book trial"}
      onClick={showTrialForm}
      size={"lg"}
      isFullWidth={true}
    />
  );
};

const WaitingButton = ({
  courseId,
  Button,
  onClick,
}: {
  courseId: string;
  Button: ButtonComponent;
  onClick: (courseId: string) => void;
}) => {
  return (
    <Button
      variant={"secondary"}
      text={"Join waiting list"}
      onClick={() => onClick(courseId)}
      size={"lg"}
      isFullWidth={true}
    />
  );
};
