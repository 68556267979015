import { LessonDateChange } from "@justraviga/classmanager-sdk/dist/models/LessonDateChange";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { useFormActions } from "../FormActionsProvider";
import { useSettings } from "../useSettings";

export type LessonChangeDateSchema = Omit<
  LessonDateChange,
  "classId" | "oldDate" | "oldTime"
>;

export const useLessonChangeDateForm = () => {
  const { setAllowCreateAdditional, setCreateButtonText } = useFormActions();
  const { locale, use12HourClock } = useSettings("general");
  const clockType = use12HourClock ? "12-hour" : "24-hour";

  setAllowCreateAdditional(false);
  setCreateButtonText("Save");

  const builder = new FormDefinitionBuilder<LessonChangeDateSchema>()
    .date("newDate", { label: "Select date", required: true })
    .time("newTime", {
      label: "Start time",
      locale,
      required: true,
      clockType,
    });

  return builder.getDefinition() as FormDefinition<LessonChangeDateSchema>;
};

export const makeLessonChangeDateRequest =
  ({
    api,
    classId,
    oldDate,
    oldTime,
  }: {
    api: Api;
    classId: string;
    oldDate: string;
    oldTime: string;
  }) =>
  (formData: LessonChangeDateSchema) => {
    return api.lessonAdjustments.changeLessonDateLessonAdjustment({
      lessonDateChange: [
        {
          classId,
          oldDate,
          oldTime,
          ...formData,
        },
      ],
    });
  };
