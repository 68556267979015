import {
  CreateStudentRequest,
  StudentGender,
} from "@justraviga/classmanager-sdk";

import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

export const useAddStudentInCheckoutForm = () => {
  return (
    new FormDefinitionBuilder<CreateStudentRequest>()
      // .group("", ["firstname", "lastname", "dateOfBirth", "gender"])
      .text("firstname", { label: "First name", required: true })
      .text("lastname", { label: "Last name", required: true })
      .date("dateOfBirth", { label: "Date of birth" })
      .select("gender", {
        label: "Gender",
        data: Object.values(StudentGender).map(value => ({
          label: enumLabel("studentGender", value),
          value,
        })),
      })
      .text("medicalInfo", {
        label: "Medical information",
        description:
          "If your child doesn't have any medical information to provide, leave this section blank.",
      })
      .switch("mediaConsent", {
        label: "Photo & video consent",
        description:
          "Student consents to be in photos & videos posted on social media or used in online content.",
      })
      .getDefinition()
  );
};
