import { formatMoneyFromInteger } from "../../intlFormatter";
import { getBalanceColor } from "../../transactions/transactionStyles";
import { useGenericComponents } from "../GenericComponentsProvider";

export const AccountBalanceTitle = ({ balance }: { balance: number }) => {
  const { View, Text } = useGenericComponents();
  return (
    <View className="flex flex-row space-x-2 items-end">
      <Text className="text-heading6-400 text-grey-900">Account balance</Text>
      <Text
        className="text-heading6-600 font-semibold"
        style={{
          color: getBalanceColor(balance),
        }}>
        {formatMoneyFromInteger(balance)}
      </Text>
    </View>
  );
};
