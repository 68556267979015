import { ReactNode } from "react";

const DocSection = ({ children }: { children: ReactNode }) => {
  return <div className="my-5 border-2 p-5">{children}</div>;
};

DocSection.title = ({ title }: { title: string }) => (
  <h1 className="text-16">{title}</h1>
);

export { DocSection };
