import { ComponentType } from "react";

import {
  CourseSearchValues,
  DayOfWeek,
  makeCourseSearchValues,
} from "./courseSearchContext";
import { useCourseSearch } from "./useCourseSearch";
import { debounce } from "../../asyncUtils";
import { useBreakpoint } from "../../breakpoints";
import { ageLabel, dayLabel, dayOfWeekLabels } from "../../courseFilterLabels";
import { cn } from "../../cssUtils";
import { useCatalogueData } from "../../data/useCatalogueData";
import { getPlatformFunction } from "../../platformSpecific";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ButtonComponent, IconButtonComponent } from "../interfaces";
import { Chip } from "../ui/Chip";

export const CourseSearchBar = ({
  Button,
  IconButton,
  SearchInput,
  openFilter,
  goToResults,
}: {
  Button: ButtonComponent;
  IconButton: IconButtonComponent;
  SearchInput: ComponentType<{
    value?: string | null;
    onChange?: (value: string | null) => void;
    height: "sm" | "md";
  }>;
  openFilter: () => void;
  goToResults: (searchValues: CourseSearchValues, replace: boolean) => void;
}) => {
  const { View } = useGenericComponents();
  const useApi = getPlatformFunction("useApi");
  const breakpoint = useBreakpoint();
  const { locations, seasons } = useCatalogueData(useApi, true);

  const { searchKey, searchValues, filterCount } = useCourseSearch();

  const removeSeason = (id: string) => {
    goToResults(
      {
        ...searchValues,
        seasonIds: searchValues.seasonIds.filter(v => v !== id),
      },
      false,
    );
  };

  const removeDay = (day: string) => {
    goToResults(
      {
        ...searchValues,
        days: searchValues.days.filter(v => v !== day),
      },
      false,
    );
  };

  const removeLocation = (id: string) => {
    goToResults(
      {
        ...searchValues,
        locationIds: searchValues.locationIds.filter(v => v !== id),
      },
      false,
    );
  };

  const removeAge = (age: number) => {
    goToResults(
      {
        ...searchValues,
        ages: searchValues.ages.filter(v => v !== age),
      },
      false,
    );
  };

  const setQuery = (q: string) => {
    goToResults(
      {
        ...searchValues,
        query: q.trim(),
      },
      false,
    );
  };

  const reset = () => {
    goToResults(makeCourseSearchValues(), false);
  };

  const chips = [
    ...(seasons ?? [])
      .filter(s => searchValues.seasonIds.includes(s.id))
      .map(s => ({
        label: s.name,
        action: () => removeSeason(s.id),
      })),
    ...Object.keys(dayOfWeekLabels)
      .filter(v => searchValues.days.includes(v as DayOfWeek))
      .map(v => ({
        label: dayLabel(v as DayOfWeek),
        action: () => removeDay(v),
      })),
    ...(locations ?? [])
      .filter(v => searchValues.locationIds.includes(v.id))
      .map(v => ({
        label: v.name,
        action: () => removeLocation(v.id),
      })),
    ...searchValues.ages
      .sort((a, b) => a - b)
      .map(v => ({ label: ageLabel(v), action: () => removeAge(v) })),
  ];

  return (
    <View className={"pt-1"} key={searchKey}>
      <View className="mb-4 flex flex-row">
        <View className={"mr-4 flex-1 md:max-w-[248px]"}>
          <SearchInput
            value={searchValues.query}
            onChange={v => debounce(setQuery, 500)(v ?? "")}
            height={breakpoint.md ? "sm" : "md"}
          />
        </View>
        {breakpoint.md ? (
          <Button
            leftIcon={"filterOutline"}
            size={"sm"}
            text={"Filter"}
            variant={"secondary"}
            role={"filter-trigger-button"}
            className={cn({ "border-grey-900": filterCount > 0 })}
            onClick={openFilter}>
            {filterCount > 0 && (
              <View className={"py-1"}>
                <View
                  className={
                    "flex h-5 w-5 items-center justify-center rounded-full bg-grey-900 p-1 text-10 text-white"
                  }>
                  {filterCount}
                </View>
              </View>
            )}
          </Button>
        ) : (
          <View className={"mr-1"}>
            <IconButton
              icon={"filterOutline"}
              variant={"secondary-outline"}
              hasIndicator={filterCount > 0}
              indicatorNumber={filterCount}
              indicatorVariant={"primary"}
              className={filterCount > 0 ? "border-grey-900" : ""}
              onClick={openFilter}
            />
          </View>
        )}
        {breakpoint.md && filterCount > 0 && (
          <View className={"flex"}>
            <Button
              text={"Clear"}
              variant={"tertiary"}
              size={"sm"}
              className={
                "py-0 text-base font-normal text-grey-700 no-underline"
              }
              onClick={reset}
            />
          </View>
        )}
      </View>
      <View className="flex flex-row flex-wrap gap-3">
        {chips.map(chip => (
          <View key={chip.label}>
            <Chip
              label={chip.label}
              variant={"secondary"}
              rightIcon="closeOutline"
              rightIconAction={chip.action}
            />
          </View>
        ))}
      </View>
    </View>
  );
};
