import { cn } from "shared/lib";

import { appReleasedAt, appVersion } from "@/lib/appVersion";

interface VersionProps {
  className?: string;
}

export const Version = ({ className }: VersionProps) => {
  return (
    <div className={"flex justify-center space-x-1 text-sm text-grey-500"}>
      <div className={cn("flex space-x-1 text-sm text-grey-500", className)}>
        <p>Version</p>
        <p>{appVersion}</p>
      </div>
      <div className={"flex"}>
        <small>{appReleasedAt}</small>
      </div>
    </div>
  );
};
