import { cn } from "shared/lib";

import { NavSubLink } from "@/modules/common/nav/Nav";
import { SubNavHideButton } from "@/modules/common/nav/SubNavHideButton";
import { SubNavItem } from "@/modules/common/nav/SubNavItem";

type SubNavProps = {
  items?: NavSubLink[];
  onCloseMenuClick: () => void;
  isExpanded: boolean;
  onNavigate: () => void;
  isOpen: boolean;
};

export const SubNav = ({
  items,
  onCloseMenuClick,
  isExpanded,
  onNavigate,
  isOpen,
}: SubNavProps) => {
  return (
    <div
      className={cn(
        "absolute top-0 z-10 max-h-screen min-h-screen w-[238px] overflow-y-auto border-r border-r-grey-400 bg-white px-4 pb-10 shadow-xl transition-all",
        !isOpen ? "left-[-238px]" : isExpanded ? "left-[256px]" : "left-[72px]",
      )}>
      <div className={"flex items-center justify-end py-7"}>
        <SubNavHideButton onClick={onCloseMenuClick} />
      </div>
      <nav>
        <ul className={"space-y-2"}>
          {items?.map((item, index) => {
            if ("component" in item) {
              return (
                <div key={`nav-sub-item-${index}`} onClick={onNavigate}>
                  {item.component}
                </div>
              );
            } else {
              return (
                <SubNavItem
                  onNavigate={onNavigate}
                  key={`nav-sub-item-${index}`}
                  path={item.path}
                  label={item.label}
                />
              );
            }
          })}
        </ul>
      </nav>
    </div>
  );
};
