import { useState } from "react";

import {
  BaseDatePicker,
  BaseDatePickerValue,
} from "@/modules/common/form/datePicker/BaseDatePicker";
import { SingleDatePickerProps } from "@/modules/common/form/datePicker/SingleDatePicker";
import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";

export type RangeDatePickerValue = [BaseDatePickerValue, BaseDatePickerValue];

export interface RangeDatePickerProps
  extends Omit<SingleDatePickerProps, "value" | "onChange"> {
  value?: RangeDatePickerValue;
  onChange?: (range: RangeDatePickerValue) => void;
}

export const RangeDatePicker = ({
  label,
  name,
  error,
  description,
  required,
  locale,
  value = [null, null],
  onChange,
  onBlur,
}: RangeDatePickerProps) => {
  const [internalValue, setInternalValue] =
    useState<RangeDatePickerValue>(value);
  const onStartChange = (date: BaseDatePickerValue) => {
    if (onChange) {
      onChange([date, value[1]]);
    } else {
      setInternalValue([date, internalValue[1]]);
    }
  };
  const onEndChange = (date: BaseDatePickerValue) => {
    if (onChange) {
      onChange([value[0], date]);
    } else {
      setInternalValue([internalValue[0], date]);
    }
  };

  return (
    <Label text={label} required={required}>
      <div className={"flex flex-wrap gap-x-2"}>
        <div
          data-testid={"start-picker-testid"}
          className={"flex flex-row items-center gap-x-2 whitespace-nowrap"}>
          <BaseDatePicker
            name={name}
            value={value[0] ?? internalValue[0]}
            onChange={onStartChange}
            onBlur={onBlur}
            error={error}
            locale={locale}
          />

          <span>-</span>
        </div>
        <div data-testid={"end-picker-testid"}>
          <BaseDatePicker
            name={name}
            value={value[1] ?? internalValue[1]}
            onChange={onEndChange}
            onBlur={onBlur}
            error={error}
            locale={locale}
          />
        </div>
      </div>
      <InputDescription description={description} />
    </Label>
  );
};
