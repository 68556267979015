import { DatatableContentState, useDatatable } from "shared/lib";

import { DatatableGroupActions } from "@/modules/common/datatable/common/DatatableGroupActions";
import { Sheet } from "@/modules/common/overlays/dialog/Sheet";
import { Chip } from "@/modules/common/ui/chip/Chip";

export const MobileGroupActionsModalTopSheet = ({
  contentState,
}: {
  contentState: DatatableContentState;
}) => {
  const { datatable, selectMode, setSelectMode, modelSelection } =
    useDatatable();

  if (
    contentState !== DatatableContentState.Loaded ||
    !datatable?.groupActions ||
    !selectMode
  ) {
    return <></>;
  }

  return (
    <>
      <Sheet
        isModal={false}
        open={selectMode}
        onInteractOutside={event => {
          event.preventDefault();
        }}
        onOpenChange={() => {
          setSelectMode(false);
          modelSelection.clear();
        }}
        zIndex={1}
        side={"top"}
        padding={"px-5 pt-5 pb-1"}
        title={
          <div className={"flex grow"}>
            <div
              className={"mr-3 flex w-full items-center justify-between py-3"}>
              <div>
                <Chip
                  variant={"secondary"}
                  label={`${modelSelection.count()} Selected`}
                />
              </div>

              <DatatableGroupActions />
            </div>
          </div>
        }
      />
    </>
  );
};
