import { PropsWithChildren } from "react";

import { useAuthState } from "shared/components";
import { usePendingWaivers, WaiverEntityWithAgreement } from "shared/lib";

import { Dialog, DialogContent } from "@/modules/common/overlays/dialog/Dialog";
import { PendingWaiversDialogContent } from "@/modules/customer/waivers/PendingWaiversDialogContent";

interface PendingWaiversDialogProps {
  pendingWaivers: WaiverEntityWithAgreement[];
}

const PendingWaiversDialog = ({
  pendingWaivers,
}: PendingWaiversDialogProps) => {
  const openPendingWaiversDialog = pendingWaivers && pendingWaivers.length > 0;

  return (
    <Dialog open={openPendingWaiversDialog}>
      <DialogContent
        className={
          "min-h-96 fixed w-full overflow-hidden rounded bg-white md:my-16 md:max-w-2xl"
        }>
        <PendingWaiversDialogContent pendingWaivers={pendingWaivers} />
      </DialogContent>
    </Dialog>
  );
};

export const PendingWaivers = ({ children }: PropsWithChildren) => {
  const { account } = useAuthState();
  const { pendingWaivers } = usePendingWaivers(account);

  return (
    <>
      <PendingWaiversDialog pendingWaivers={pendingWaivers} />
      {children}
    </>
  );
};
