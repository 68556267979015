interface WaiverCardContentProps {
  content: string;
}

export const WaiverCardContent = ({ content }: WaiverCardContentProps) => (
  <div
    data-testid="waiver-content"
    className={
      "line-clamp-2 text-ellipsis whitespace-pre-wrap pt-3 text-body-400"
    }>
    {content}
  </div>
);
