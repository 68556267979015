import React from "react";

import { colors } from "../../../colors";

export const DiscountApplicationInfoCard = <T,>({
  BaseCard,
  props,
}: {
  BaseCard: React.FC<T>;
  props?: T;
}) => {
  const defaultProps = {
    title: "How are discounts applied?",
    titleColor: "grey-900",
    titleWeight: 600,
    titleSize: 16,
    description:
      "The smallest discount will be applied to students and classes.",
    descriptionSize: 14,
    descriptionColor: "grey-900",
    fillColor: colors.grey[100],
    icon: "informationCircle",
    iconColor: colors.blue[600],
    iconPosition: "left",
  } as T;

  return <BaseCard {...defaultProps} {...props} />;
};
