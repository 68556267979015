import { useStripeStatus } from "./useStripeStatus";
import {
  checkProtection,
  lockedFeature,
  unlockedFeature,
} from "../components/protector";

export function useFamilyStripeStatus() {
  const { protector: stripeCompanyProtector, isLoading } = useStripeStatus();

  const stripeFeatureProtection = stripeCompanyProtector.isProtected()
    ? lockedFeature("You don't currently have access to the Family Portal.")
    : unlockedFeature();
  const stripeFamilyProtector = checkProtection([stripeFeatureProtection]);

  return {
    protector: stripeFamilyProtector,
    isLoading,
  };
}
