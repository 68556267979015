import { EventTracker, EventType } from "shared/lib";

import { trackEvent as appcuesTrackEvent } from "@/lib/Appcues/appcuesAdapter";

const allEventTrackers: EventTracker[] = ["Appcues", "Mixpanel"];

const eventBindings: Record<EventType, EventTracker[]> = {
  StripeAccountReady: allEventTrackers,
  StripeAccountNotReady: allEventTrackers,
  StripeAccountHasErrors: ["Appcues", "Mixpanel"], // Just an example if limited...
};

export function eventTracker(eventName: EventType, payload: unknown) {
  const trackers = eventBindings[eventName] || [];

  if (trackers.includes("Appcues")) {
    appcuesTrackEvent(eventName, payload);
  }

  if (trackers.includes("Mixpanel")) {
    // Add mixpanel tracking here
  }
}
