import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  IntegerInput,
  IntegerInputProps,
} from "@/modules/common/form/IntegerInput";

const onChange = () => {};

const options: Array<Option<IntegerInputProps>> = [
  {
    title: "Basic functionality",
    props: [
      { label: "Enter a number", onChange, value: null },
      {
        label: "With value",
        value: 4,
        onChange,
      },
    ],
  },
];

export const IntegerInputPage = () => {
  return (
    <>
      <DocWrapper
        title="Integer Input"
        component={IntegerInput}
        options={options}
      />
    </>
  );
};
