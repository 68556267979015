import { useState } from "react";

import { CodeBlock } from "react-code-blocks";

import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const AlertDialogPage = () => {
  const [showCode, setShowCode] = useState(false);
  const { showAlert } = useAlertDialog();

  const toggleCode = () => {
    setShowCode(!showCode);
  };

  const triggerAlert = async () => {
    await showAlert({
      title: "Are you sure?",
      description: "This action cannot be undone.",
      confirmButtonText: "Delete",
      confirmButtonStyle: "destructive",
      cancelText: "Cancel",
    });
  };

  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title="Alert dialog" />
      </AdminTitleBar>
      <AdminContent>
        <div className="my-5 border-2 p-5">
          <div className="mb-5 flex items-center justify-between">
            <h2 className="text-16">Example</h2>
            <IconButton
              onClick={toggleCode}
              variant="standard"
              icon="codeSlashOutline"
              size="sm"
              aria-label="Toggle example code"
            />
          </div>
          <div className="flex justify-between">
            <Button
              text="Click to show alert"
              onClick={triggerAlert}
              variant="brand"
            />
            {showCode && (
              <CodeBlock
                as={undefined}
                forwardedAs={undefined}
                codeContainerStyle={{
                  borderRadius: "0.5rem",
                  padding: "1.2rem",
                  fontFamily: "monospace",
                  fontSize: "14px",
                }}
                text={`
  import { useAlertDialog } from "@/modules/common/alertDialog/AlertDialogContext";
  
  const { showAlert } = useAlertDialog();
  const result = await showAlert({
      title: "Are you sure?",
      description: "This action cannot be undone.",
      confirmText: "Delete",
      confirmStyle: "destructive",
      cancelText: "Cancel",
  });
             `}
                showLineNumbers={false}
                language={"typescript"}
                wrapLongLines={true}
              />
            )}
          </div>
        </div>
      </AdminContent>
    </AdminLayout>
  );
};
