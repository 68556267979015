import { BasketStorageAdaptor } from "shared/lib";

const basketStorageKey = "basket";

/**
 * A web-specific storage adaptor for the Basket class that uses localStorage.
 */
export const basketStorageAdaptor: BasketStorageAdaptor = {
  get: async () => {
    return localStorage.getItem(basketStorageKey);
  },
  set: (serializedValue: string) => {
    localStorage.setItem(basketStorageKey, serializedValue);
  },
  clear: async () => {
    localStorage.removeItem(basketStorageKey);
  },
};
