import { AggregateMessageDto } from "@justraviga/classmanager-sdk";

import { useSharedEmailActions } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { Router } from "@/routing/router";

export const useEmailActions = () => ({
  ...useSharedEmailActions(
    usePlatformEntityActions<AggregateMessageDto>({ entity: "message" }),
    api,
  ),
  showCreateForm: () => {
    Router.push("WriteEmail");
  },
  showUpdateForm: (id: string) => {
    Router.push("WriteEmail", { id });
  },
  showPreview: (id: string) => {
    Router.push("ViewEmail", { id });
  },
});
