import { Link } from "@swan-io/chicane";

type SubNavItemProps = {
  path: string;
  label: string;
  onNavigate?: () => void;
};

export const SubNavItem = ({ path, label, onNavigate }: SubNavItemProps) => (
  <li>
    <Link
      to={path}
      onClick={onNavigate}
      className={"flex rounded px-4 py-2 hover:bg-grey-100"}
      activeClassName={"bg-grey-200"}>
      <p className={"truncate"}>{label}</p>
    </Link>
  </li>
);
