import { AggregateClassDto, LocationDto } from "@justraviga/classmanager-sdk";

import {
  CourseSearchValues,
  makeCourseSearchValues,
} from "./courseSearchContext";
import { CourseWizardStage } from "./courseSearchTypes";
import { rangeBetween } from "../../arrayUtils";
import { courseIsSuitableForAge } from "../../classUtils";
import { ageLabel } from "../../courseFilterLabels";
import { MultiSelectChips } from "../forms/MultiSelectChips";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ButtonComponent, IconButtonComponent } from "../interfaces";

export const CourseWizardForm = ({
  Button,
  IconButton,
  goToResults,
  goNext,
  stage,
  courses,
  locations,
  assignedLocationIds,
  goBack,
  searchValues,
  setSearchValues,
}: {
  Button: ButtonComponent;
  IconButton: IconButtonComponent;
  goToResults: (searchValues: CourseSearchValues, replace: boolean) => void;
  goNext: () => void;
  stage: CourseWizardStage;
  courses: Array<AggregateClassDto>;
  locations: Array<LocationDto>;
  assignedLocationIds: Array<string>;
  goBack: () => void;
  searchValues: CourseSearchValues;
  setSearchValues: (values: CourseSearchValues) => void;
}) => {
  const { Text, View } = useGenericComponents();

  const locationOptions = [
    { label: "View all", value: "" },
    ...((locations ?? []).map(location => ({
      label: location.name,
      value: location.id,
      disabled: !assignedLocationIds.includes(location.id),
    })) ?? []),
  ];

  const ageOptions = [
    { label: "View all", value: 0 },
    ...rangeBetween(1, 19).map(year => ({
      label: ageLabel(year),
      value: year,
      disabled: !courses.some(c => courseIsSuitableForAge(c, year)),
    })),
  ];

  const titles = {
    location: "Which locations work best for you?",
    age: "What are the ages of the students you are enrolling?",
  };
  const title = titles[stage];

  const setLocationIdsFiltered = (newLocationIds: string[]) => {
    // When "view all" is selected, remove other location IDs
    const oldLocationIds = [...searchValues.locationIds];
    const locationIds =
      !oldLocationIds.includes("") && newLocationIds.includes("")
        ? [""]
        : newLocationIds.filter(id => id !== "");
    setSearchValues({ ...searchValues, locationIds });
  };

  const setAgesFiltered = (newAges: number[]) => {
    // When "view all" is selected, remove other ages
    const oldAges = [...searchValues.ages];
    const ages =
      !oldAges.includes(0) && newAges.includes(0)
        ? [0]
        : newAges.filter(age => age !== 0);
    setSearchValues({ ...searchValues, ages });
  };

  return (
    <View className="rounded-md md:border border-grey-300 h-full bg-white px-5 py-4 md:h-auto md:w-[514px] md:px-8 md:py-12">
      <View className="h-full flex flex-col">
        <View className="flex-none flex flex-row items-center justify-between">
          <View className="-ml-2">
            <IconButton
              icon={"chevronBackOutline"}
              onClick={goBack}
              variant="standard"
            />
          </View>
          <View>
            <Button
              variant="tertiary"
              size="lg"
              text="I just want to browse"
              onClick={() => goToResults(makeCourseSearchValues(), false)}
            />
          </View>
        </View>
        <View className="flex-1 py-8">
          <Text className="text-heading5-600 font-semibold text-grey-900">
            {title}
          </Text>
          <Text className="text-body-400 text-grey-600 mt-2">
            You can select more than one.
          </Text>
          <View className="mt-8">
            {stage === "location" && (
              <MultiSelectChips
                options={locationOptions}
                value={searchValues.locationIds}
                onChange={setLocationIdsFiltered}
              />
            )}
            {stage === "age" && (
              <MultiSelectChips
                options={ageOptions}
                value={searchValues.ages}
                onChange={setAgesFiltered}
              />
            )}
          </View>
        </View>
        <View className="flex-none">
          <Button
            onClick={goNext}
            text="Continue"
            variant="brand"
            isFullWidth={true}
          />
        </View>
      </View>
    </View>
  );
};
