import { createContext, PropsWithChildren } from "react";

import { StaffDto } from "@justraviga/classmanager-sdk";

import { useAuthState } from "./AuthStateProvider";
import { AuthenticatedEntity } from "../hooks/useAuthenticatedAccountEntity";
import { getPlatformFunctions } from "../platformSpecific";

interface AuthenticatedAccountEntityContextValue {
  entity: AuthenticatedEntity | null;
}

export const AuthenticatedAccountEntityContext =
  createContext<AuthenticatedAccountEntityContextValue>({
    entity: null,
  });

export const AuthenticatedAccountEntityProvider = ({
  children,
}: PropsWithChildren) => {
  const { account } = useAuthState();
  const { useApi } = getPlatformFunctions();
  const { data: staffData } = useApi(
    "getStaff",
    { id: account?.entityId ?? "" },
    { enabled: account?.role === "staff" },
  );
  const { data: familyData } = useApi(
    "getFamily",
    { id: account?.entityId ?? "" },
    { enabled: account?.role === "family" },
  );
  return (
    <AuthenticatedAccountEntityContext.Provider
      value={{ entity: (staffData as StaffDto) || familyData || null }}>
      {children}
    </AuthenticatedAccountEntityContext.Provider>
  );
};
