import { DiscountType } from "@justraviga/classmanager-sdk";

import {
  DiscountSchemeTierUpdateFormProps,
  makeDiscountSchemeTierUpdateRequest,
  useDiscountSchemeTierForm,
} from "shared/components";
import { moneyIntegerToFloat } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { DiscountSchemeTierFormBanner } from "@/modules/company/billing/discountSchemes/tiers/DiscountSchemeTierFormBanner";

export const DiscountSchemeTierUpdateForm = ({
  model,
  discountScheme,
  onUpdateSuccess,
}: DiscountSchemeTierUpdateFormProps) => {
  const form = useDiscountSchemeTierForm(discountScheme.type);

  return (
    <div className={"flex flex-col space-y-8"}>
      <DiscountSchemeTierFormBanner
        discountScheme={discountScheme}
        quantity={model.quantity}
      />
      <GenericForm
        apiRequest={makeDiscountSchemeTierUpdateRequest({
          api,
          id: model.id,
          discountScheme,
        })}
        defaultValues={{
          amount:
            discountScheme.type === DiscountType.Percent
              ? model.amount
              : moneyIntegerToFloat(model.amount),
        }}
        formDefinitionHook={() => form}
        onSuccess={onUpdateSuccess}
      />
    </div>
  );
};
