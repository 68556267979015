// eslint-disable-next-line check-file/filename-naming-convention
import { FilePurpose } from "@justraviga/classmanager-sdk";
import { Editor, FileLoader, UploadResponse } from "ckeditor5";

import { uploadAndSaveFile } from "@/lib/api/uploadFile";

class ImageUploadAdapter {
  private loader: FileLoader | undefined;
  private messageId: string | undefined;

  setMessageId(messageId: string | undefined) {
    this.messageId = messageId;
  }

  setLoader(loader: FileLoader) {
    this.loader = loader;
  }

  upload(): Promise<UploadResponse> {
    if (!this.loader) {
      return Promise.reject("No loader provided");
    }

    return this.loader.file.then(async file => {
      if (!file) {
        return Promise.reject("No file to upload");
      }

      if (!this.messageId) {
        return Promise.reject(
          "Please add a subject for your email before uploading images",
        );
      }

      const { url } = await uploadAndSaveFile(
        this.messageId,
        FilePurpose.EmailImage,
        file,
      );

      return {
        default: url,
      };
    });
  }

  abort() {
    // We don't care at the moment about cancelling requests. But if we do, we'll need to make the promise
    // returned from uploadAndSaveFile cancellable.
  }
}

// The adapter is a singleton, so we can export it directly
export const imageUploadAdapter = new ImageUploadAdapter();

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ImageUploadAdapterPlugin(editor: Editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    imageUploadAdapter.setLoader(loader);
    return imageUploadAdapter;
  };
}
