import { useEffect, useState } from "react";

import { MultiSelectProps, SelectItem, truncateString } from "shared/lib";

import { BaseSelect } from "@/modules/common/form/select/BaseSelect";
import { MultiSelectChip } from "@/modules/common/form/select/MultiSelectChip";

export const MultiSelect = ({
  placeholder,
  onSelect,
  ...props
}: MultiSelectProps) => {
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (props.value && props.data.length > 0) {
      const defaultSelectedItems = props.value.map(value => {
        const item = props.data.find(item => item.value === value);

        return item as SelectItem;
      });
      setSelectedItems(defaultSelectedItems);
    }
  }, [props.value, props.data]);

  const updateSelectedItems = (item: SelectItem) => {
    const itemExists = selectedItems.some(
      existingItem => existingItem.value === item.value,
    );

    if (itemExists) {
      const newSelectedItems = selectedItems.filter(
        existingItem => existingItem.value !== item.value,
      );
      setSelectedItems(newSelectedItems);
      onSelect && onSelect(newSelectedItems.map(item => item.value)); // Call onSelect with the new state
    } else {
      const newSelectedItems = [...selectedItems, item];
      setSelectedItems(newSelectedItems);
      onSelect && onSelect(newSelectedItems.map(item => item.value)); // Call onSelect with the new state
    }
  };

  const onClickRemoveAll = () => {
    setSelectedItems([]);
    onSelect && onSelect([]);
  };

  const renderSelectedItems = (items: SelectItem[]): React.ReactNode => {
    const length = items.length;

    if (length <= 0) {
      return "Click to select";
    }

    if (length >= 2) {
      return (
        <MultiSelectChip
          label={`${length.toString()} ${props.entityName ?? "items"}`}
        />
      );
    }
    return (
      <div className="mr-5 flex gap-3">
        {items.map((item, index) => {
          return (
            <MultiSelectChip
              key={index}
              label={truncateString(item.label, 15)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <BaseSelect
      isSelected={(item: SelectItem) =>
        selectedItems?.some(existingItem => existingItem.value === item.value)
      }
      onClickRemoveAll={onClickRemoveAll}
      placeholder={
        selectedItems.length ? renderSelectedItems(selectedItems) : placeholder
      }
      onSelect={(item?: SelectItem) => item && updateSelectedItems(item)}
      multiple={true}
      {...props}
    />
  );
};
