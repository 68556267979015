import { createContext } from "react";

export interface AccountSelectionDialogContextInterface {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const AccountSelectionDialogContext = createContext<
  AccountSelectionDialogContextInterface | undefined
>(undefined);
