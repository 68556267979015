import { useAuthState } from "shared/components";

import { useUserAccountNavActionMenu } from "@/modules/common/nav/useUserAccountNavActionMenu";
import { SearchIconButton } from "@/modules/common/search/SearchIconButton";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { ProfileAvatar } from "@/modules/common/ui/avatar/ProfileAvatar";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { AccountSelectionDialog } from "@/modules/company/switcher/AccountSelectionDialog";

const AvatarContainer = () => (
  <div className="flex cursor-pointer flex-row items-center gap-2">
    <ProfileAvatar />
    <Icon name="chevronDown" size={16} />
  </div>
);

export const UserAccountLinks = () => {
  const { account } = useAuthState();

  const items = useUserAccountNavActionMenu();

  if (!account) return null;

  const avatar = (
    <div>
      <AvatarContainer />
    </div>
  ); // Hacky AF (div wrap) but done because trigger needs apply refs to the div not a component.

  return (
    <div className="ml-auto flex items-center gap-6">
      <SearchIconButton />
      <AccountSelectionDialog account={account} />
      <ActionMenu
        width={account?.role === "family" ? "w-80" : undefined}
        items={items}
        trigger={avatar}
      />
    </div>
  );
};
