import { ReactNode, useState } from "react";

import { colors } from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";

export const Collapsible = ({
  children,
  title,
  openByDefault = false,
}: {
  children: ReactNode;
  title: string;
  openByDefault?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault);

  return (
    <section>
      <div className={"mb-8 flex flex-row items-center space-x-2"}>
        <p className={"text-label-400 text-grey-600"}>{title}</p>
        <IconButton
          data-testid="visibility-button"
          onClick={() => setIsOpen(!isOpen)}
          variant="standard"
          icon={isOpen ? "chevronUp" : "chevronDown"}
          size="xs"
          aria-label={isOpen ? "Hide" : "Show"}
          color={colors.grey[600]}
        />
      </div>
      {isOpen && <div>{children}</div>}
    </section>
  );
};
