const courseSearchModes = ["enrol", "trial"] as const;
const courseWizardStages = ["location", "age"] as const;

export type CourseSearchMode = (typeof courseSearchModes)[number];
export type CourseWizardStage = (typeof courseWizardStages)[number];

export const isMode = (v: string): v is CourseSearchMode =>
  courseSearchModes.includes(v as CourseSearchMode);
export const isStage = (v?: string): v is CourseWizardStage =>
  courseWizardStages.includes(v as CourseWizardStage);
