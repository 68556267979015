import { useEffect } from "react";

import { ContactDto, SortSchema } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { getFullName } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";

interface FormSchema {
  studentIds: Array<string>;
}

const useFormDefinition = () => {
  const { useApi } = getPlatformFunctions();
  const { data: families } = useApi("listFamily", {
    selectAll: true,
    sort: { name: SortSchema.Asc },
  });
  const { data: students } = useApi("listStudent", {
    selectAll: true,
    sort: {
      firstname: SortSchema.Asc,
      lastname: SortSchema.Asc,
    },
  });

  const options =
    families && students
      ? students.data.map(student => ({
          value: student.id,
          label: getFullName(student),
          description: families.data.find(
            family => family.id === student.familyId,
          )?.name,
        }))
      : [];

  return new FormDefinitionBuilder<FormSchema>()
    .multiSelect("studentIds", {
      label: "Select students",
      required: true,
      data: options,
      localSearch: true,
    })
    .getDefinition();
};

const makeRequest = (api: Api) => async (data: FormSchema) => {
  return api.contacts.searchContact({
    where: { studentId: { in: data.studentIds } },
  });
};

export const RecipientsByStudentForm = ({
  onSuccess,
}: {
  onSuccess: (recipients: Array<ContactDto>) => void;
}) => {
  const { GenericForm } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const formActions = useFormActions();

  useEffect(() => {
    formActions.setAllowCreateAdditional(false);
    formActions.setCreateButtonText("Add");
  }, [formActions]);

  return (
    <GenericForm
      apiRequest={makeRequest(api)}
      defaultValues={{}}
      formDefinitionHook={useFormDefinition}
      onSuccess={onSuccess}
    />
  );
};
