import React from "react";

type CompanyLogoSize = "sm" | "md" | "lg";

export const logoSizeMap: Record<CompanyLogoSize, string> = {
  sm: "w-16 h-16",
  md: "w-24 h-24",
  lg: "w-32 h-32",
};

export interface CompanyLogoProps {
  logo?: string | null;
  size?: CompanyLogoSize;
}

export type CompanyLogoComponent = React.FC<CompanyLogoProps>;
