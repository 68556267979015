import {
  StripePaymentMethodBacsDebitDataDto,
  StripePaymentMethodCardDataDto,
} from "@justraviga/classmanager-sdk";

/**
 * A type guard to check if a given Dto specifically represents a Stripe card data object
 */
export const isStripeCardData = (
  data:
    | StripePaymentMethodBacsDebitDataDto
    | StripePaymentMethodCardDataDto
    | undefined,
): data is StripePaymentMethodCardDataDto =>
  data !== undefined && "expiryMonth" in data && "expiryYear" in data;
