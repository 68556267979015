import { BillDto } from "@justraviga/classmanager-sdk";

import { TransactionItem } from "./TransactionItem";
import { TransactionItemsList } from "./TransactionItemsList";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../../../interfaces";

export const TransactionDetails = ({
  transaction,
  HorizontalSeparator,
}: {
  transaction: BillDto;
  HorizontalSeparator: HorizontalSeparatorComponent;
}) => {
  const { View } = useGenericComponents();
  return (
    <View className={"space-y-1"}>
      <TransactionItemsList
        items={transaction.items}
        HorizontalSeparator={HorizontalSeparator}
      />
      {transaction.discountTotal < 0 ? (
        <View>
          <HorizontalSeparator spacing={2} />
          <TransactionItem
            description={"Discounts"}
            total={transaction.discountTotal}
            discount={true}
          />
        </View>
      ) : null}
    </View>
  );
};
