import {
  AggregateClassDto,
  ClassSettingDto,
  CourseDto,
  EnrolmentStatsDto,
  TrialStatsDto,
  WaitingListStatsDto,
} from "@justraviga/classmanager-sdk";

import { DefinedEntity } from "../entityTypes";

export interface ClassEntityProps {
  class: CourseDto;
  settings: ClassSettingDto;
  enrolments: EnrolmentStatsDto;
  trials: TrialStatsDto;
  waitingLists: WaitingListStatsDto;
}

export interface ClassEntity
  extends DefinedEntity<AggregateClassDto>,
    ClassEntityProps {}

export function classEntityFactory(
  aggCourseDto: AggregateClassDto,
): ClassEntity {
  const {
    entity: classDto,
    stats: { enrolments, trials, waitingLists },
    settings,
  } = aggCourseDto;
  function isArchived() {
    // Classes can be future-archived as a way to say they are cancelled from date X
    return Boolean(classDto.archivedAt && classDto.archivedAt < new Date());
  }

  const dataProps: ClassEntityProps = {
    class: {
      ...classDto,
    },
    settings,
    enrolments,
    trials,
    waitingLists,
  };

  return {
    type: "defined",
    props: { ...aggCourseDto },
    isArchived,
    isArchivable: () => {
      // You can only archive classes that are not due to be archived
      return Boolean(!classDto.archivedAt);
    },
    isDeletable: () => {
      return Boolean(!classDto.deletedAt);
    },
    isEditable: () => {
      return true;
    },
    isRestorable: () => {
      return isArchived();
    },
    isDefined: () => true,

    ...dataProps,
  };
}
