import { useEffect, useState } from "react";

import { SortSchema, TrialDto } from "@justraviga/classmanager-sdk";

import {
  AggregateClassRelatedData,
  aggregateClassRelatedDataEmptyList,
  fetchClassRelatedData,
} from "./fetchClassRelatedData";
import { Api } from "../api";
import { isGreaterOrEqualThanToday, isLessThanToday } from "../dateUtils";

export interface FamilyTrialsList
  extends Omit<AggregateClassRelatedData<TrialDto>, "list"> {
  upcomingTrials: TrialDto[];
  pastTrials: TrialDto[];
  isLoading: boolean;
  isEmpty: boolean;
}

export type FamilyTrialsListProps = Omit<
  AggregateClassRelatedData<TrialDto>,
  "list"
> & {
  trials: TrialDto[];
  title: string;
  collapsible?: boolean;
};

export const useFamilyTrialsList = (api: Api): FamilyTrialsList => {
  const [wholeList, setWholeList] = useState<
    AggregateClassRelatedData<TrialDto>
  >(aggregateClassRelatedDataEmptyList);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTrialList = async (api: Api) =>
      await fetchClassRelatedData<TrialDto>(api, () =>
        api.trials.listTrial({
          selectAll: true,
          includeArchived: true,
          sort: { trialAt: SortSchema.Desc, time: SortSchema.Desc },
        }),
      );

    fetchTrialList(api).then(allList => {
      setWholeList(allList);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    upcomingTrials: wholeList.list.filter(
      t => isGreaterOrEqualThanToday(t.trialAt) && t.archivedAt === null,
    ),
    pastTrials: wholeList.list.filter(t => isLessThanToday(t.trialAt)),
    students: wholeList.students,
    classes: wholeList.classes,
    seasons: wholeList.seasons,
    isLoading,
    isEmpty: wholeList.list.length === 0,
  };
};
