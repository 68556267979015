import { FC, ReactNode, useEffect } from "react";

import { DatatableColumnProps, DatatableConfiguration } from "../../datatable";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { queryClient } from "../../tanstackQuery";
import { useAuthState } from "../AuthStateProvider";
import { CustomTransactionItem } from "../formDefinitions/customTransactionItemForm";

export const useCustomTransactionItemsDatatable = ({
  Datatable,
  items,
  showCreateForm,
  deleteOne,
  showUpdateForm,
  renderMobile,
  renderDescription,
  renderUnitPrice,
  showRowActions,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<CustomTransactionItem>;
  }>;
  items: CustomTransactionItem[];
  showCreateForm: () => void;
  deleteOne: (item: CustomTransactionItem) => Promise<void>;
  showUpdateForm: (item: CustomTransactionItem) => void;
  renderMobile: (item: CustomTransactionItem) => ReactNode;
  renderDescription: (item: CustomTransactionItem) => ReactNode;
  renderUnitPrice: (item: CustomTransactionItem) => ReactNode;
  showRowActions: boolean;
}): {
  datatable: ReactNode;
} => {
  const datatableId = "custom-transaction-items";

  const isTaxEnabled =
    useAuthState().account?.company?.settings.tax.enabled ?? false;

  // Re-render the datatable when the items change
  useEffect(() => {
    queryClient.refetchQueries({ queryKey: [datatableId] });
  }, [items]);

  const config: DatatableConfiguration<CustomTransactionItem> = {
    id: datatableId,
    hasPagination: false,
    hasSearch: false,
    title: "Transaction details",
    titleSize: "sm",
    placeholdersCount: 1,
    createAction: showCreateForm,
    showTotalRecords: false,
    createLabel: "Add item",
    scrollEnabledOnMobile: false,
    alwaysShowHeaderOnMobile: true,
    fetchData: async () => ({
      data: items,
      pagination: {},
    }),
    rowActions: showRowActions
      ? {
          delete: deleteOne,
          edit: showUpdateForm,
        }
      : undefined,
    columns: [
      {
        label: "Description",
        placeholder: "text",
        value: (row: { item: CustomTransactionItem }) => ({
          type: "custom",
          children: renderDescription(row.item),
        }),
      },
      {
        label: "Unit price",
        placeholder: "text",
        value: (row: { item: CustomTransactionItem }) => ({
          type: "custom",
          children: renderUnitPrice(row.item),
        }),
      },
      {
        label: "Discount",
        placeholder: "text",
        value: (row: { item: { discount: number } }) => ({
          type: "text",
          text: row.item.discount
            ? `-${formatMoneyFromInteger(row.item.discount)}`
            : "",
        }),
      },
      {
        label: "Tax",
        placeholder: "text",
        value: (row: { item: { taxAmount: number } }) => ({
          type: "text",
          text: formatMoneyFromInteger(row.item.taxAmount),
        }),
      },
      {
        label: "Amount",
        placeholder: "text",
        value: (row: { item: { total: number } }) => ({
          type: "text",
          text: formatMoneyFromInteger(row.item.total),
        }),
      },
    ].filter(
      item => isTaxEnabled || item.label !== "Tax",
    ) as DatatableColumnProps<CustomTransactionItem>[],
    mobileColumn: {
      children: row => renderMobile(row.item),
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "No items added yet",
        description:
          "Start creating a custom transaction to charge for fees and products for individual families or in bulk.",
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
