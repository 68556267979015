import { useContext } from "react";

import { CatalogueContext } from "./catalogueContext";

export const useCatalogueContext = (): CatalogueContext => {
  const context = useContext(CatalogueContext);
  if (!context) {
    throw new Error(
      "useCatalogueContext must be used within a CatalogueContextProvider",
    );
  }
  return context;
};
