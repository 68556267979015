import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { AccountRole } from "@justraviga/classmanager-sdk";
import { SearchClient as TypesenseSearchClient } from "typesense";

import { useAuthState } from "./AuthStateProvider";
import { Api } from "../api";
import {
  buildSearchClientConfig,
  BuildSearchClientConfigParams,
  SearchClientBuilder,
} from "../search/searchClientBuilder";
import {
  SearchContext,
  SearchDialogContext,
  SearchResultsContext,
  SearchStatusContext,
} from "../search/searchContext";
import { searchKeyLoader } from "../search/searchKeyLoader";
import { queryBuilder } from "../search/searchQueryBuilder";
import {
  FamilyDocument,
  GlobalSearchResults,
  GlobalSearchResultsType,
  GlobalSearchStatus,
  StaffDocument,
  StudentDocument,
} from "../search/searchTypes";
import {
  buildEmptySearchObject,
  globalSearchMapping,
} from "../search/searchUtils";

interface SearchProviderProps extends PropsWithChildren {
  api: Api;
  config: BuildSearchClientConfigParams;
  children: ReactNode;
}

export const SearchProvider = ({
  api,
  config,
  children,
}: SearchProviderProps) => {
  const { account } = useAuthState();
  const [searchClient, setSearchClient] = useState<
    TypesenseSearchClient | undefined
  >();
  const [status, setStatus] = useState<GlobalSearchStatus>(
    GlobalSearchStatus.PENDING,
  );
  const [searchResults, setSearchResults] = useState<GlobalSearchResults>(() =>
    buildEmptySearchObject(GlobalSearchResultsType.clean),
  );
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchClientBuilder] = useState(
    () =>
      new SearchClientBuilder(
        searchKeyLoader({ api }),
        buildSearchClientConfig(config),
      ),
  );

  const search = (searchQuery: string | null) => {
    if (searchQuery === null || searchQuery === "") {
      setSearchResults(buildEmptySearchObject(GlobalSearchResultsType.clean));
      return;
    }

    if (searchClient) {
      setStatus(GlobalSearchStatus.LOADING);
      searchClient.multiSearch
        .perform<StaffDocument[] | FamilyDocument[] | StudentDocument[]>(
          queryBuilder(searchQuery),
        )
        .then(result => {
          setStatus(GlobalSearchStatus.OK);
          setSearchResults(globalSearchMapping(result));
        })
        .catch(() => {
          setStatus(GlobalSearchStatus.ERROR);
        });
      return;
    }
  };
  const clearSearchResults = () =>
    setSearchResults(buildEmptySearchObject(GlobalSearchResultsType.clean));

  const resetClient = useCallback(async () => {
    searchClientBuilder
      .resetKey()
      .then(newClient => {
        if (newClient) {
          setStatus(GlobalSearchStatus.OK);
          setSearchClient(newClient);
        } else {
          setStatus(GlobalSearchStatus.ERROR);
        }
      })
      .catch(() => {
        setStatus(GlobalSearchStatus.ERROR);
      });
  }, [searchClientBuilder]);

  const openSearch = () => setIsSearchOpen(true);
  const closeSearch = () => {
    clearSearchResults();
    setIsSearchOpen(false);
    if (status === GlobalSearchStatus.LOADING) {
      setStatus(GlobalSearchStatus.OK);
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      closeSearch();
    }

    if (isOpen) {
      if (status !== GlobalSearchStatus.OK) {
        resetClient();
      }
      openSearch();
    }
  };

  useEffect(() => {
    if (account?.id && account.role === AccountRole.Staff) {
      resetClient();
    } else {
      setStatus(GlobalSearchStatus.HIDDEN);
    }
  }, [account, resetClient]);

  return (
    <SearchStatusContext.Provider value={{ status }}>
      <SearchDialogContext.Provider value={{ isSearchOpen, handleOpenChange }}>
        <SearchResultsContext.Provider value={{ searchResults }}>
          <SearchContext.Provider value={{ search }}>
            {children}
          </SearchContext.Provider>
        </SearchResultsContext.Provider>
      </SearchDialogContext.Provider>
    </SearchStatusContext.Provider>
  );
};
