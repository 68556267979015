import { Time } from "@justraviga/classmanager-sdk";

import { CourseOptionsTransform, CourseSelect } from "./CourseSelect";
import { useLessonEntities } from "../../data/useLessonList";
import { lessonSelectData } from "../../trialUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { SelectComponent } from "../interfaces";

type CourseId = string;
type LessonDate = string;

export type LessonSelectValue =
  | [null, null, null]
  | [CourseId, null, null]
  | [CourseId, LessonDate, Time];

export interface LessonSelectProps {
  Select: SelectComponent;
  error?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange: (value: LessonSelectValue) => void;
  required?: boolean;
  value: LessonSelectValue;
  transformCourseOptions?: CourseOptionsTransform;
}

export const LessonSelect = ({
  Select,
  error,
  name,
  onBlur,
  onChange,
  required,
  value,
  transformCourseOptions,
}: LessonSelectProps) => {
  const { View } = useGenericComponents();

  const [courseId, date, time] = value;

  const lessonDateTime = date && time ? `${date} ${time}` : null;

  const lessons = useLessonEntities(courseId);
  const lessonOptions = lessonSelectData(lessons);

  const onCourseChange = (newCourseId: string | null) => {
    if (newCourseId !== courseId) {
      onChange([newCourseId, null, null]);
    }
  };

  return (
    <View>
      <View className="pb-5">
        <CourseSelect
          error={error}
          value={courseId}
          onChange={onCourseChange}
          Select={Select}
          name="courseId"
          required={required}
          transformCourseOptions={transformCourseOptions}
        />
      </View>
      <View>
        <Select
          name={name}
          data={lessonOptions}
          error={error}
          label="Select date"
          localSearch={required}
          onBlur={onBlur}
          onSelect={v => {
            if (typeof v === "string") {
              const [date, time] = v.split(" ");
              onChange([courseId!, date, time as Time]);
            } else {
              onChange([courseId, null, null]);
            }
          }}
          required={required}
          value={lessonDateTime}
        />
      </View>
    </View>
  );
};
