import { match } from "ts-pattern";

import { IconName } from "../availableIcons";
import { CardType } from "../components/interfaces";
import { getPlatformFunctions } from "../platformSpecific";

export function getSvgForCardType(cardType: CardType): IconName {
  return match<CardType, IconName>(cardType)
    .with("amex", () => "amex")
    .with("diners", () => "diners")
    .with("discover", () => "discover")
    .with("eftpos_au", () => "eftpos")
    .with("jcb", () => "jcb")
    .with("mastercard", () => "mastercard")
    .with("unionpay", () => "chinaUnionPay")
    .with("visa", () => "visa")
    .with("unknown", () => "koreanCards")
    .exhaustive();
}

export const useSharedFamilyPaymentMethods = (familyId: string) => {
  const { useApi } = getPlatformFunctions();
  const { data: paymentMethods, ...rest } = useApi(
    "listStripePaymentMethod",
    {
      family: familyId,
    },
    {
      enabled: !!familyId,
    },
  );

  return {
    ...rest,
    paymentMethods: (paymentMethods || []).sort(a => (a.isDefault ? -1 : 1)),
  };
};
