import * as React from "react";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { TooltipContentProps } from "@radix-ui/react-tooltip";

import { cn } from "shared/lib";

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 max-w-[260px] overflow-hidden rounded bg-grey-900 px-4 py-2 shadow animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className,
    )}
    {...props}
  />
));

export interface TooltipProps {
  trigger: React.ReactNode;
  children?: React.ReactNode;
  side?: TooltipContentProps["side"];
  align?: TooltipContentProps["align"];
  showContent?: boolean;
  delayDuration?: number;
}

export const Tooltip = ({
  trigger,
  children,
  side,
  align,
  showContent = true,
  delayDuration = 500,
}: TooltipProps) => {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <TooltipRoot>
        <TooltipTrigger
          asChild={true}
          onClick={event => event.preventDefault()}>
          {trigger}
        </TooltipTrigger>
        {showContent && (
          <TooltipContent side={side} align={align} className={"min-w-fit"}>
            <div className="text-label-400 text-white">
              {children && children}
            </div>
          </TooltipContent>
        )}
      </TooltipRoot>
    </TooltipProvider>
  );
};
