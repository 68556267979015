import { useEffect, useRef } from "react";

import { useAlert } from "shared/lib";

import { Router } from "@/routing/router";

export const RedirectRoutes = [
  "Login",
  "CompanyHome",
  "CustomerHome",
  "AdminHome",
] as const;
type RedirectRoute = (typeof RedirectRoutes)[number];

interface RouteNotFoundProps {
  redirectTo: RedirectRoute;
}

export const RouteNotFound = ({ redirectTo }: RouteNotFoundProps) => {
  const hasRedirected = useRef(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!hasRedirected.current) {
      hasRedirected.current = true;
      showAlert({
        variant: "error",
        content: `The page you are looking for does not exist.`,
      });

      Router.replace(redirectTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTo]);

  return null;
};
