import { colors } from "shared/lib";

import { BaseInput, BaseInputProps } from "@/modules/common/form/BaseInput";
import { Icon, IconName } from "@/modules/common/ui/icon/Icon";

export interface TextInputProps
  extends Omit<BaseInputProps, "append" | "prepend"> {
  appendIcon?: IconName;
  prependIcon?: IconName;
}

export const TextInput = ({
  appendIcon,
  prependIcon,
  ...props
}: TextInputProps) => {
  const prepend = prependIcon ? (
    <div data-testid="prepend-icon">
      <Icon name={prependIcon} size={18} color={colors.grey[500]} />
    </div>
  ) : null;
  const append = appendIcon ? (
    <div data-testid="append-icon">
      <Icon name={appendIcon} size={18} color={colors.grey[500]} />
    </div>
  ) : null;

  return <BaseInput {...props} {...{ prepend, append }} />;
};
