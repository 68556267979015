import { ContentPlaceholder } from "shared/components";
import { useDatatable } from "shared/lib";

export const ErrorContentPlaceholder = () => {
  const { datatable } = useDatatable();

  return (
    <>
      <section>
        <div className={"mb-5 flex justify-end"}>
          {datatable.contentPlaceholders?.errorContent?.action &&
            datatable.contentPlaceholders?.errorContent?.action}
        </div>
        <ContentPlaceholder
          icon={
            datatable.contentPlaceholders?.errorContent?.icon ??
            "helpCircleOutline"
          }
          title={
            datatable.contentPlaceholders?.errorContent?.title ??
            "This page couldn’t be loaded"
          }
          description={
            datatable.contentPlaceholders?.errorContent?.description ??
            "This page is unavailable, please check back soon!"
          }
          action={datatable.contentPlaceholders?.errorContent?.action}
        />
      </section>
    </>
  );
};
