import { CreateAvatarRequest } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";

type CreateSchema = CreateAvatarRequest;

export type AvatarStatus =
  | "no-avatar"
  | "has-avatar"
  | "new-avatar"
  | "delete-avatar";

export interface AvatarUploadState {
  status: AvatarStatus;
  uploadedPath?: string;
}

interface MakeAvatarCreateOrDeleteRequest {
  api: Api;
  entityId: CreateSchema["entityId"];
  avatarUploadState: AvatarUploadState;
  onSuccess?: (hasUpdated: boolean) => void;
}

/*
 * this function will either create/delete an avatar based on the existence of the profile picture
 */
export function makeAvatarCreateOrDeleteRequest({
  api,
  entityId,
  avatarUploadState,
  onSuccess = () => {},
}: MakeAvatarCreateOrDeleteRequest) {
  const { status, uploadedPath } = avatarUploadState;

  return () => {
    if (status === "new-avatar" && uploadedPath) {
      return api.avatars
        .createAvatar({
          createAvatarRequest: {
            entityId,
            path: uploadedPath,
          },
        })
        .then(() => onSuccess(true));
    }

    if (status === "delete-avatar") {
      return api.avatars
        .deleteAvatar({
          deleteAvatarRequest: {
            entityId,
          },
        })
        .then(() => onSuccess(true));
    }

    return onSuccess?.(false);
  };
}
