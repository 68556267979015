import * as React from "react";

import { colors } from "shared/lib";

import { BaseInput, BaseInputProps } from "@/modules/common/form/BaseInput";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { IconName } from "@/modules/common/ui/icon/Icon";

export interface PasswordInputProps
  extends Omit<BaseInputProps, "append" | "prepend"> {}

export const PasswordInput = ({ ...props }: PasswordInputProps) => {
  const [type, setType] = React.useState<BaseInputProps["type"]>("password");

  const toggleVisibility = () => {
    setType(type === "password" ? "text" : "password");
  };

  const icon: IconName = type === "password" ? "eyeOutline" : "eyeOffOutline";

  const append = (
    <div className="-mr-2">
      <IconButton
        onClick={toggleVisibility}
        tabIndex={-1}
        variant="standard"
        icon={icon}
        size="sm"
        aria-label="Toggle password visibility"
        color={colors.grey["500"]}
      />
    </div>
  );

  return <BaseInput {...props} append={append} type={type} />;
};
