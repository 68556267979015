import { getFullName } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export const Person = ({
  person,
}: {
  person:
    | {
        firstname: string;
        lastname: string | null | undefined;
      }
    | null
    | undefined;
}) => {
  return (
    <div className={"flex flex-row items-center space-x-2"}>
      <Icon name={"personOutline"} />
      <span>{person ? getFullName(person) : "-"}</span>
    </div>
  );
};
