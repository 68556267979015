import { AttendanceStatus } from "@justraviga/classmanager-sdk";

import { colors } from "../../../../../../../colors";
import { useGenericComponents } from "../../../../../../GenericComponentsProvider";
import { Chip } from "../../../../../../ui/Chip";

export const StudentRowStatus = ({
  status,
  isDesktop = false,
}: {
  status: AttendanceStatus;
  isDesktop?: boolean;
}) => {
  const { Icon } = useGenericComponents();

  switch (status) {
    case "present":
      return isDesktop ? (
        <Chip label={"Present"} size={"sm"} variant={"success"} />
      ) : (
        <Icon name={"checkmarkCircle"} color={colors.green[600]} />
      );
    case "absent":
      return isDesktop ? (
        <Chip label={"Absent"} size={"sm"} variant={"primary"} />
      ) : (
        <Icon name={"closeCircle"} color={colors.grey[900]} />
      );
    case "unknown":
    default:
      return null;
  }
};
