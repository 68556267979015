import { CustomerLayout } from "@/modules/customer/CustomerLayout";

export const CustomerClasses = ({ id }: { id: string }) => {
  return (
    <CustomerLayout>
      <h2 className="mb-5 text-heading2-400">Customer Classes {id}</h2>
      <p>Dashboard or whatever goes here...</p>
    </CustomerLayout>
  );
};
