import React, { memo } from "react";

import { Row } from "@tanstack/react-table";

import { DatatableRow, getFirstLetters, useDatatable } from "shared/lib";

import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const SelectableCell = <ModelDto,>({
  hasImage,
  title,
  subtitle,
  image,
  children,
  icon,
  isPendingDeleted = false,
  row,
}: {
  hasImage?: boolean;
  title?: string;
  subtitle?: string | undefined | null;
  image?: string | undefined | null;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  isPendingDeleted?: boolean;
  row: Row<DatatableRow<ModelDto>>;
}) => {
  const { selectMode, modelSelection } = useDatatable();

  const onClick = row.original.onClick;

  return (
    <div
      role="selectable-cell"
      onClick={() => {
        if (selectMode) {
          modelSelection.toggle(row.id);
        } else if (onClick) {
          onClick(row.original);
        }
      }}
      className={`flex ${selectMode || onClick ? "cursor-pointer" : ""}`}>
      <div className={"relative flex flex-row items-start"}>
        <SelectableImage
          hasImage={hasImage ? hasImage : false}
          image={image || undefined}
          title={title}
          id={row.id}
        />
      </div>

      <div className="flex grow flex-col gap-1 overflow-hidden">
        {title && (
          <div className="flex items-center text-left">
            <span className="truncate whitespace-nowrap text-body-600 leading-tight text-grey-900">
              {title}
            </span>

            <div className={"pl-2"}>{icon && icon}</div>
          </div>
        )}
        {subtitle && (
          <div className="truncate whitespace-nowrap text-label-400 leading-tight text-grey-600">
            {subtitle}
          </div>
        )}

        {children && <div>{children}</div>}

        {isPendingDeleted && (
          <div className={"text-sm font-semibold text-grey-600"}>
            (Pending Delete)
          </div>
        )}
      </div>
    </div>
  );
};

const SelectableCheckbox = ({ id }: { id: string }) => {
  const { modelSelection } = useDatatable();

  const selected = modelSelection.has(id);

  return (
    <div className={"pr-4"}>
      <div
        className={`flex h-6 w-6 items-center justify-center rounded-full
        ${selected ? "bg-grey-900" : "border border-grey-400"} 
        `}>
        {selected ? (
          <Icon name={"checkmarkThick"} color={"#FFFFFF"} size={16} />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

const SelectableImage = memo(
  ({
    hasImage,
    image,
    title,
    id,
  }: {
    hasImage: boolean;
    image?: string;
    title?: string;
    id: string;
  }) => {
    const { selectMode, modelSelection } = useDatatable();

    if (hasImage) {
      return (
        <div className={`pr-3`}>
          <div className={"relative"}>
            <Avatar
              src={image}
              checked={modelSelection.has(id)}
              name={getFirstLetters(title || "")}
            />
          </div>
        </div>
      );
    }

    if (!selectMode) {
      return <></>;
    }

    return <SelectableCheckbox id={id} />;
  },
);
