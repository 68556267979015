import { WaiverMemberType } from "shared/lib";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { PageErrorPlaceholder } from "@/modules/common/ui/PageErrorPlaceholder";
import { useWaiversMembersBreadcrumbs } from "@/modules/company/waivers/useWaiversMembersBreadcrumbs";
import { useWaiversMembersListPage } from "@/modules/company/waivers/useWaiversMembersListPage";
import { WaiverAgreementCard } from "@/modules/company/waivers/WaiverAgreementCard";

export interface WaiversMemberPageProps {
  memberId: string;
  source: WaiverMemberType;
}

export const WaiversMemberPage = ({
  memberId,
  source,
}: WaiversMemberPageProps) => {
  const { waivers, isEmpty, familyName, isLoading } = useWaiversMembersListPage(
    { memberId, source },
  );
  const crumbs = useWaiversMembersBreadcrumbs({
    memberId,
    source,
    name: familyName,
  });

  if (isLoading) {
    return (
      <div className={"flex justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  return (
    <CompanyCardPage
      title={"Waivers & policies"}
      isEmpty={isEmpty()}
      crumbs={crumbs}>
      <CardContainerPlaceholder>
        <PageErrorPlaceholder />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {waivers?.data.map((waiver, key) => (
          <WaiverAgreementCard waiver={waiver} key={key} />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};
