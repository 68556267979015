import type {
  BillDto,
  BillItemDiscountDto,
  BillItemDto,
} from "@justraviga/classmanager-sdk";

import { formatMoneyFromInteger } from "shared/lib";

import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const BillItemsList = ({ billData }: { billData?: BillDto }) => {
  if (billData === undefined) {
    return null;
  }

  return (
    <section>
      <p className={"pb-4 text-body-600"}>Transaction details</p>
      {billData.items.map((item, index) => {
        return (
          <section key={`bill-item-${index}`}>
            <div className={"flex flex-col space-y-2"}>
              <RowItem title={item.description} amount={item.subtotal} />
              <BillDiscountItems items={item.discounts} />
              <TaxRow item={item} />
              <RowItem title={"Amount"} amount={item.total} />
            </div>
            <HorizontalSeparator spacing={4} />
          </section>
        );
      })}
      <Summary bill={billData} />
    </section>
  );
};

const RowItem = ({
  title,
  amount,
  isGrey = false,
}: {
  title: string;
  amount: number;
  isGrey?: boolean;
}) => {
  return (
    <div
      className={`flex flex-row items-center justify-between space-x-4 text-body-400 ${isGrey && "text-grey-600"}`}>
      <p className={"truncate"}>{title}</p>
      <p>{formatMoneyFromInteger(amount)}</p>
    </div>
  );
};

const TaxRow = ({ item }: { item: BillItemDto }) => {
  if (item.taxTotal === null || item.taxDescription === null) {
    return null;
  }

  return (
    <RowItem title={item.taxDescription} amount={item.taxTotal} isGrey={true} />
  );
};

const Summary = ({ bill }: { bill: BillDto }) => {
  return (
    <div className={"flex flex-col space-y-2"}>
      <RowItem title={"Subtotal"} amount={bill.subtotal} isGrey={true} />
      <RowItem title={"Discounts"} amount={bill.discountTotal} isGrey={true} />
      {bill.taxes.length > 0 && (
        <RowItem
          title={bill.taxes[0].description}
          amount={bill.taxTotal}
          isGrey={true}
        />
      )}
      <div
        className={`flex flex-row items-center justify-between space-x-4 text-heading5-600`}>
        <h5>Total</h5>
        <p>{formatMoneyFromInteger(bill.total)}</p>
      </div>
    </div>
  );
};

const BillDiscountItems = ({ items }: { items: BillItemDiscountDto[] }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className={"flex flex-col space-y-2"}>
      {items.map((item, index) => {
        return (
          <RowItem
            key={index}
            title={item.description}
            amount={item.amount}
            isGrey={true}
          />
        );
      })}
    </div>
  );
};
