import { ListAgreement200Response } from "@justraviga/classmanager-sdk";

import { WaiverAgreementMap } from "./waiver";

export const buildWaiverToAgreementMap = (
  agreements?: ListAgreement200Response,
) => {
  if (!agreements) {
    return {};
  }

  return agreements.data.reduce<WaiverAgreementMap>((acc, agreement) => {
    acc[agreement.waiverId] = agreement;

    return acc;
  }, {});
};
