import { CustomFieldEntity, StudentDto } from "@justraviga/classmanager-sdk";

import {
  CustomFieldResponsesCard,
  useSharedContactActions,
} from "shared/components";
import { getFullName, useBreakpoint } from "shared/lib";

import { useData } from "@/lib/api/apiClient";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { ActionMenuProps } from "@/modules/common/ui/ActionMenu";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { ClickableNavItem } from "@/modules/company/common/details/ClickableNavItem";
import { NavItemContainer } from "@/modules/company/common/details/NavItemContainer";
import { CustomFieldResponsesContent } from "@/modules/company/customFields/entityResponses/card/CustomFieldResponsesContent";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";
import { EmergencyContactCard } from "@/modules/company/members/student/detail/EmergencyContactCard";
import { MedicalInfoCard } from "@/modules/company/members/student/detail/MedicalInfoCard";
import { PlaceholderCard } from "@/modules/company/members/student/detail/PlaceholderCard";
import { Profile } from "@/modules/company/members/student/detail/Profile";
import { Router } from "@/routing/router";

export const StudentDetailsPage = ({ id }: { id: StudentDto["id"] }) => {
  const contactActions = useSharedContactActions();
  const studentActions = useStudentActions();
  const customFieldActions = useCustomFieldActions();
  const { md: isDesktop } = useBreakpoint();

  const student = useData("getStudent", { id });

  const actions: ActionMenuProps["items"] = [
    ...(isDesktop
      ? []
      : [
          {
            title: "Edit",
            onClick: () => {
              studentActions.showUpdateForm(student);
            },
          },
        ]),
    {
      title: "Assign make up lesson",
      onClick: () => studentActions.makeUpLesson(student),
      leftIcon: "swapHorizontalOutline",
    },
    {
      title: student.archivedAt ? "Restore" : "Archive",
      onClick: () => {
        student.archivedAt
          ? studentActions.unarchive(student).then()
          : studentActions.archive(student).then();
      },
      leftIcon: student.archivedAt ? "reloadOutline" : "archiveOutline",
    },
    {
      title: "Delete",
      destructive: true,
      onClick: () => {
        studentActions.deleteOne(student).then(() => {
          Router.push("StudentList");
        });
      },
      leftIcon: "trashOutline",
    },
  ];

  const crumbs = [
    {
      text: "Students",
      onPress: () => {
        Router.push("StudentList");
      },
    },
    {
      text: getFullName(student),
    },
  ];

  return (
    <DetailPageLayout
      isDesktop={isDesktop}
      archivedEntityName={"student"}
      restoreAction={() => studentActions.unarchive(student).then()}
      editAction={() => studentActions.showUpdateForm(student)}
      actions={actions}
      crumbs={crumbs}
      isArchived={student.archivedAt !== null}
      header={
        <Profile
          student={student}
          isDesktop={isDesktop}
          uploadAction={() => studentActions.showAvatarUploadSheet(student)}
        />
      }>
      <DetailPageGrid>
        <DetailPageGridLeftColumn>
          <PlaceholderCard
            icon="buildOutline"
            title="Weekly schedule coming soon"
          />
        </DetailPageGridLeftColumn>
        <DetailPageGridRightColumn>
          <div className={"w-full"}>
            <EmergencyContactCard
              student={student}
              familyId={student.familyId}
              openContactSheet={() => {
                contactActions.showCreateForm(student.familyId, {
                  isEmergency: true,
                });
              }}
            />
          </div>
          {isDesktop && <StudentNavItems student={student} />}
          <MedicalInfoCard student={student} />
          <div className={"w-full"}>
            <CustomFieldResponsesCard
              entityId={student.id}
              defaultEntity={CustomFieldEntity.Student}
              CustomFieldResponsesContent={CustomFieldResponsesContent}
              customFieldActions={customFieldActions}
            />
          </div>
          {!isDesktop && <StudentNavItems student={student} />}
        </DetailPageGridRightColumn>
      </DetailPageGrid>
    </DetailPageLayout>
  );
};

const StudentNavItems = ({ student }: { student: StudentDto }) => {
  const waitingList = useData("listWaitingList", {
    where: { studentId: { equals: student.id } },
  });

  const waitingListCount = waitingList.pagination.total;

  return (
    <NavItemContainer>
      <ClickableNavItem
        onClick={() =>
          Router.push("StudentEnrolments", {
            studentId: student.id,
          })
        }
        text={"Enrollments"}
        iconName={"calendarClear"}
      />
      <ClickableNavItem
        onClick={() =>
          Router.push("StudentWaitingList", {
            studentId: student.id,
          })
        }
        text={"Waiting List"}
        iconName={"reorderFour"}
        badgeCount={waitingListCount}
      />
      <ClickableNavItem
        onClick={() =>
          Router.push("StudentTrials", {
            studentId: student.id,
          })
        }
        text={"Trials"}
        iconName={"time"}
      />
      <ClickableNavItem
        onClick={() =>
          Router.push("CompanyStudentWaivers", {
            studentId: student?.id,
          })
        }
        text={"Waivers"}
        iconName={"documentText"}
      />
      <ClickableNavItem
        onClick={() =>
          Router.push("ContactsList", {
            id: student?.familyId,
          })
        }
        text={"Contacts"}
        iconName={"callSharp"}
      />
    </NavItemContainer>
  );
};
