import { useSharedWaiversMembersListPage } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { WaiversMemberPageProps } from "@/modules/company/waivers/WaiversMemberPage";

export function useWaiversMembersListPage({
  memberId,
  source,
}: WaiversMemberPageProps) {
  return useSharedWaiversMembersListPage(useApi, memberId, source);
}
