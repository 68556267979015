import { InvitationStatus } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { StatusIcon } from "@/modules/common/ui/icon/StatusIcon";
import { StatusIconType } from "@/modules/common/ui/icon/StatusIconType";

export const FamilyDatatableInvitationStatusIcon = ({
  status,
}: {
  status?: InvitationStatus;
}) => {
  /**
   * On the Family Datatable, we don't want to show an icon when no invite has been sent
   */
  if (status === InvitationStatus.NotInvited) {
    return null;
  }

  const [type, tooltipText] = match<
    InvitationStatus | undefined,
    [StatusIconType, string]
  >(status)
    .with(InvitationStatus.Accepted, () => [
      StatusIconType.Success,
      "Active Class Manager Member",
    ])
    .with(InvitationStatus.Invited, () => [
      StatusIconType.Invited,
      "Class Manager Invitation Pending",
    ])
    .otherwise(() => [StatusIconType.Default, "Not Invited"]);

  return (
    <Tooltip
      showContent={!!tooltipText}
      trigger={
        <button type="button" className="flex items-center">
          <StatusIcon type={type} />
        </button>
      }>
      {tooltipText}
    </Tooltip>
  );
};
