import { markdownToReactNodes, showAlert } from "shared/lib";

export async function copyToClipboard(
  textToCopy: string,
  successMessage: string,
) {
  navigator.clipboard.writeText(textToCopy).then(() =>
    showAlert({
      content: successMessage,
      variant: "success",
    }),
  );
}

export const openPhoneClient = (phone: string) => {
  window.location.href = `tel:${phone}`;
};

export const renderMarkdown = (markdown: string) =>
  markdownToReactNodes(
    markdown,
    v => v,
    (v, i) => <strong key={i}>{v}</strong>,
  );

/**
 * This is platform specific because we use Expo for mobile, and Vite for web
 */
export function getEnv(name: string, defaultValue?: string): string {
  const value = import.meta.env["VITE_".concat(name)];

  if (value === undefined) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }

    throw new Error(`Missing environment variable: ${name}`);
  }

  return value;
}

export function openWebsite(url: string) {
  window.location.href = url;
}
