import { useState } from "react";

import {
  LinkDto,
  LinkType,
  RegisterUserRequest,
} from "@justraviga/classmanager-sdk";

import { useAuthState, useUserRegisterForm } from "shared/components";
import { processInvite } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { RegistrationWithTerms } from "@/modules/auth/common/RegistrationWithTerms";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Router } from "@/routing/router";

interface LinkedRegisterFormProps {
  linkDetails?: LinkDto;
  companyName: string;
  email?: string;
}

export const LinkedRegisterForm = ({
  linkDetails,
  email,
  companyName,
}: LinkedRegisterFormProps) => {
  const { setUser } = useAuthState();
  const [isLoading, setLoading] = useState(false);

  const registerUser = async (data: RegisterUserRequest) => {
    if (linkDetails) {
      processInvite(api, {
        linkDetails,
        email: data.email,
        password: data.password,
        requiresRegistration: true,
        onError: () => setLoading(false),
        onSuccess: () => {
          setLoading(false);
          Router.push("Accounts");
        },
        setUser,
      });
    }
  };

  const {
    form,
    formHandlers: { handleSubmit },
  } = useUserRegisterForm(useFormBuilder, registerUser, {
    email: email!,
    password: "",
  });

  return (
    <RegistrationWithTerms
      companyName={companyName}
      buttonText="Accept invitation"
      displayTerms={linkDetails?.type === LinkType.FamilyInvitation}
      form={form}
      isLoading={isLoading}
      onFormSubmission={handleSubmit(registerUser)}
    />
  );
};
