import React from "react";

import { cn } from "shared/lib";

import { appReleasedAt, appVersion } from "@/lib/appVersion";

export const NavAppVersion = React.memo(
  ({ isExpanded }: { isExpanded: boolean }) => {
    return (
      <div
        className={cn("py-6", {
          "px-8": isExpanded,
          "flex items-center justify-center": !isExpanded,
        })}>
        <span className={`text-caption-400 text-brand-contrast`}>
          {isExpanded ? "Version: " : ""}
          {appVersion} <br />
          <small>{appReleasedAt}</small>
        </span>
      </div>
    );
  },
);
