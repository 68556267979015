import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { ActionMenuItemContainer } from "@/modules/common/ui/actionMenu/ActionMenuItemContainer";

export const MobileActionMenuDestructiveItems = ({
  items,
}: {
  items: ActionMenuItemProps[];
}) => {
  return items.map(item => {
    return (
      <div
        key={`action-menu-destructive-${item.title}`}
        onClick={e => {
          e.stopPropagation();
          item.onClick && item.onClick();
        }}
        className="cursor-pointer p-2 py-3 hover:rounded-sm hover:border-0 hover:bg-grey-100 focus-visible:outline-0">
        <ActionMenuItemContainer item={item} />
      </div>
    );
  });
};
