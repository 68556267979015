import { formatMoneyFromInteger } from "shared/lib";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";

interface EditPaymentAmountCardProps {
  amount: number;
  onClick: () => void;
}

export const EditPaymentAmountCard = ({
  amount,
  onClick,
}: EditPaymentAmountCardProps) => (
  <BaseCard
    title={"Amount"}
    headerAction={{ text: "Edit", onClick: onClick }}
    bodySlot={
      <p className="text-heading6-600 text-grey-900">
        {formatMoneyFromInteger(amount)}
      </p>
    }
  />
);
