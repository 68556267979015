import {
  DatatableConfiguration,
  DatatableFilterOperator,
  DatatableQueryProps,
  DatatableRow,
  FilterFormDefinitionBuilder,
} from "shared/lib";

export interface ExampleUser {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
}

interface FilterFormSchema {
  firstname: string;
}

interface WhereParam {
  firstname: string;
}

const filterForm = new FilterFormDefinitionBuilder<
  FilterFormSchema,
  WhereParam
>()
  .text(
    "firstname",
    { label: "Search" },
    { filterField: "firstname", operator: DatatableFilterOperator.Contains },
  )
  .group("Users filters", ["firstname"], { advanced: false })
  .getDefinition();

export const fetchExampleUsersPaginatedData = (query: DatatableQueryProps) => {
  const data = fetchExampleUsersData(query);
  const currentPageData = data.slice(
    (query.page - 1) * query.pageSize,
    query.page * query.pageSize,
  );

  return {
    data: currentPageData,
    pagination: {
      count: currentPageData.length,
      total: data.length,
      perPage: query.pageSize,
      currentPage: query.page,
      totalPages: data.length / 10,
    },
  };
};

export const fetchExampleUsersData = (query: DatatableQueryProps) => {
  if (query.search) {
    return exampleUsersData.filter(user => {
      return (
        user.firstname.includes(query.search) ||
        user.lastname.includes(query.search) ||
        user.email.includes(query.search)
      );
    });
  }

  if (query.filters.length > 0) {
    return [];
  }

  return exampleUsersData;
};

export const exampleUserDatatableConfiguration: DatatableConfiguration<
  ExampleUser,
  object
> = {
  // adding this to avoid lint error, but this is not actually working as cache key
  id: "student",
  title: "Users List",
  hasPagination: true,
  placeholdersCount: 5,
  groupActions: {
    deleteMany: () => Promise.resolve(),
  },
  createAction: () => Promise.resolve(),
  fetchData: async (query: DatatableQueryProps) => {
    return fetchExampleUsersPaginatedData(query);
  },
  columns: [
    {
      label: "name",
      placeholder: "tile",
      value: (row: DatatableRow<ExampleUser>) => {
        return {
          type: "tile",
          title: `${row.item.firstname} ${row.item.lastname}`,
        };
      },
      sortableField: "firstname",
    },
    {
      label: "Email",
      placeholder: "tile",
      value: (row: DatatableRow<ExampleUser>) => {
        return {
          type: "text",
          text: row.item.email,
        };
      },
    },
    {
      label: "Family",
      placeholder: "tile",
      value: () => {
        return {
          type: "text",
          text: "family name (WIP)",
        };
      },
    },
  ],
  mobileColumn: {
    hasImage: true,
    image: () => {
      return "https://picsum.photos/200";
    },
    title: (row: DatatableRow<ExampleUser>) =>
      `${row.item.firstname} ${row.item.lastname}`,
    subtitle: (row: DatatableRow<ExampleUser>) => row.item.email,
  },
  // @ts-expect-error This is just a test, so not going to worry about the type here
  filterForm,
  rowActions: {
    edit: () => Promise.resolve(),
  },
};

export const exampleUsersData = [
  {
    id: "usr_1234567",
    firstname: "John",
    lastname: "Doe",
    email: "john.doe@example.com",
  },
  {
    id: "usr_2345678",
    firstname: "Jane",
    lastname: "Smith",
    email: "jane.smith@example.com",
  },
  {
    id: "usr_3456789",
    firstname: "David",
    lastname: "Johnson",
    email: "david.johnson@example.com",
  },
  {
    id: "usr_4567890",
    firstname: "Emily",
    lastname: "Davis",
    email: "emily.davis@example.com",
  },
  {
    id: "usr_5678901",
    firstname: "Michael",
    lastname: "Brown",
    email: "michael.brown@example.com",
  },
  {
    id: "usr_6789012",
    firstname: "Sophia",
    lastname: "Miller",
    email: "sophia.miller@example.com",
  },
  {
    id: "usr_7890123",
    firstname: "Daniel",
    lastname: "Wilson",
    email: "daniel.wilson@example.com",
  },
  {
    id: "usr_8901234",
    firstname: "Olivia",
    lastname: "Moore",
    email: "olivia.moore@example.com",
  },
  {
    id: "usr_9012345",
    firstname: "Matthew",
    lastname: "Taylor",
    email: "matthew.taylor@example.com",
  },
  {
    id: "usr_0123456",
    firstname: "Ava",
    lastname: "Anderson",
    email: "ava.anderson@example.com",
  },
  {
    id: "usr_1234568",
    firstname: "Christopher",
    lastname: "Thomas",
    email: "christopher.thomas@example.com",
  },
  {
    id: "usr_2345679",
    firstname: "Ella",
    lastname: "White",
    email: "ella.white@example.com",
  },
  {
    id: "usr_3456780",
    firstname: "Andrew",
    lastname: "Harris",
    email: "andrew.harris@example.com",
  },
  {
    id: "usr_4567891",
    firstname: "Grace",
    lastname: "Clark",
    email: "grace.clark@example.com",
  },
  {
    id: "usr_5678902",
    firstname: "William",
    lastname: "Lewis",
    email: "william.lewis@example.com",
  },
  {
    id: "usr_6789013",
    firstname: "Madison",
    lastname: "Hall",
    email: "madison.hall@example.com",
  },
  {
    id: "usr_7890124",
    firstname: "Ethan",
    lastname: "Allen",
    email: "ethan.allen@example.com",
  },
  {
    id: "usr_8901235",
    firstname: "Aria",
    lastname: "Turner",
    email: "aria.turner@example.com",
  },
  {
    id: "usr_9012346",
    firstname: "James",
    lastname: "Martin",
    email: "james.martin@example.com",
  },
  {
    id: "usr_0123457",
    firstname: "Lily",
    lastname: "Garcia",
    email: "lily.garcia@example.com",
  },
  {
    id: "usr_4567890",
    firstname: "Emily",
    lastname: "Williams",
    email: "emily.williams@example.com",
  },
  {
    id: "usr_5678901",
    firstname: "Michael",
    lastname: "Brown",
    email: "michael.brown@example.com",
  },
  {
    id: "usr_6789012",
    firstname: "Elizabeth",
    lastname: "Jones",
    email: "elizabeth.jones@example.com",
  },
  {
    id: "usr_7890123",
    firstname: "James",
    lastname: "Garcia",
    email: "james.garcia@example.com",
  },
  {
    id: "usr_8901234",
    firstname: "Patricia",
    lastname: "Miller",
    email: "patricia.miller@example.com",
  },
  {
    id: "usr_9012345",
    firstname: "Robert",
    lastname: "Davis",
    email: "robert.davis@example.com",
  },
  {
    id: "usr_0123456",
    firstname: "Jennifer",
    lastname: "Lopez",
    email: "jennifer.lopez@example.com",
  },
  {
    id: "usr_1234568",
    firstname: "Charles",
    lastname: "Wilson",
    email: "charles.wilson@example.com",
  },
  {
    id: "usr_2345679",
    firstname: "Angela",
    lastname: "Martinez",
    email: "angela.martinez@example.com",
  },
  {
    id: "usr_3456780",
    firstname: "Thomas",
    lastname: "Anderson",
    email: "thomas.anderson@example.com",
  },
  {
    id: "usr_4567891",
    firstname: "Jessica",
    lastname: "Taylor",
    email: "jessica.taylor@example.com",
  },
  {
    id: "usr_5678902",
    firstname: "Daniel",
    lastname: "Thomas",
    email: "daniel.thomas@example.com",
  },
  {
    id: "usr_6789013",
    firstname: "Sarah",
    lastname: "Hernandez",
    email: "sarah.hernandez@example.com",
  },
  {
    id: "usr_7890124",
    firstname: "Christopher",
    lastname: "Moore",
    email: "christopher.moore@example.com",
  },
  {
    id: "usr_8901235",
    firstname: "Karen",
    lastname: "Jackson",
    email: "karen.jackson@example.com",
  },
  {
    id: "usr_9012346",
    firstname: "Brian",
    lastname: "Martin",
    email: "brian.martin@example.com",
  },
  {
    id: "usr_0123457",
    firstname: "Nancy",
    lastname: "Lee",
    email: "nancy.lee@example.com",
  },
  {
    id: "usr_1234569",
    firstname: "Gary",
    lastname: "Perez",
    email: "gary.perez@example.com",
  },
  {
    id: "usr_2345670",
    firstname: "Lisa",
    lastname: "Young",
    email: "lisa.young@example.com",
  },
  {
    id: "usr_3456781",
    firstname: "Kevin",
    lastname: "Harris",
    email: "kevin.harris@example.com",
  },
  {
    id: "usr_4567892",
    firstname: "Sophia",
    lastname: "Clark",
    email: "sophia.clark@example.com",
  },
  {
    id: "usr_5678903",
    firstname: "Ethan",
    lastname: "Rodriguez",
    email: "ethan.rodriguez@example.com",
  },
  {
    id: "usr_6789014",
    firstname: "Olivia",
    lastname: "Lewis",
    email: "olivia.lewis@example.com",
  },
  {
    id: "usr_7890125",
    firstname: "Alexander",
    lastname: "Lee",
    email: "alexander.lee@example.com",
  },
  {
    id: "usr_8901236",
    firstname: "Mia",
    lastname: "Walker",
    email: "mia.walker@example.com",
  },
  {
    id: "usr_9012347",
    firstname: "William",
    lastname: "Hall",
    email: "william.hall@example.com",
  },
  {
    id: "usr_0123458",
    firstname: "Ava",
    lastname: "Allen",
    email: "ava.allen@example.com",
  },
  {
    id: "usr_1234570",
    firstname: "Noah",
    lastname: "Young",
    email: "noah.young@example.com",
  },
  {
    id: "usr_2345681",
    firstname: "Isabella",
    lastname: "Hernandez",
    email: "isabella.hernandez@example.com",
  },
  {
    id: "usr_3456792",
    firstname: "Jacob",
    lastname: "King",
    email: "jacob.king@example.com",
  },
];
