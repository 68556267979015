import { ContentPlaceholder } from "shared/components";
import { IconName } from "shared/lib";

import { Card } from "@/modules/common/ui/card/Card";

export const PlaceholderCard = ({
  title,
  description,
  icon,
}: {
  title?: string;
  description?: string;
  icon?: IconName;
}) => {
  return (
    <Card
      className={
        "flex max-w-full flex-col items-center space-y-5 px-8 py-8 md:px-28"
      }>
      <ContentPlaceholder
        icon={icon ?? "helpCircleOutline"}
        title={title ?? "No information yet"}
        description={description}
      />
    </Card>
  );
};
