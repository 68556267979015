// eslint-disable-next-line no-restricted-imports
import dayjs, { OpUnitType, QUnitType } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export { dayjs };
export type { OpUnitType, QUnitType };
