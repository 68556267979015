import { ReactNode, useMemo, useState } from "react";

import {
  BaseInputProps,
  BaseInputValue,
  cn,
  colors,
  debounce,
} from "shared/lib";

import { useGenericComponents } from "../../GenericComponentsProvider";

export interface SearchInputProps
  extends Omit<BaseInputProps, "append" | "prepend" | "prefix"> {
  /** Debounce time in milliseconds */
  debounceDelay?: number;
  clearBtn?: "icon" | "text";
  BaseInput: (props: BaseInputProps) => ReactNode;
}

export const SearchInput = ({
  BaseInput,
  placeholder,
  debounceDelay = 500,
  clearBtn = "icon",
  height = "sm",
  onChange,
  ...props
}: SearchInputProps) => {
  const { Pressable, Icon, Text, View } = useGenericComponents();
  const [innerValue, setInnerValue] = useState<BaseInputValue>(
    props.value ?? null,
  );
  const emptyOnChange = () => {};
  const debouncedOnChange = useMemo(
    () => debounce(onChange ?? emptyOnChange, debounceDelay),
    [debounceDelay, onChange],
  );
  const clear = () => {
    setInnerValue("");
    onChange?.("");
  };
  const clearButton =
    innerValue && innerValue.length ? (
      <Pressable
        className={cn(
          "-mr-[14px] flex h-[48px] w-[48px] items-center justify-center",
        )}
        onClick={clear}
        aria-label="Clear search input">
        {clearBtn === "icon" ? <Icon name="closeOutline" size={20} /> : null}
        {clearBtn === "text" ? (
          <Text className={"text-grey-600"}>Clear</Text>
        ) : null}
      </Pressable>
    ) : (
      <View className={cn("-mr-[14px] h-[48px] w-[48px]")} />
    );

  return (
    <BaseInput
      {...props}
      height={height}
      value={innerValue}
      placeholder={placeholder || "Search"}
      prepend={<Icon name="searchOutline" size={20} color={colors.grey[500]} />}
      append={clearButton}
      onChange={value => {
        setInnerValue(value);
        debouncedOnChange(value);
      }}
      selectTextOnFocus={true}
    />
  );
};
