import { AccountActions } from "shared/components";

import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";

export const AdminAccountPage = () => {
  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title={"Admin Home"} />
      </AdminTitleBar>
      <AdminContent>
        <AccountActions />
      </AdminContent>
    </AdminLayout>
  );
};
