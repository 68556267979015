import { CoursePreviewPageProps } from "shared/components";

import {
  CatalogueAuthRoute,
  PreAuthCatalogueRoute,
} from "@/routing/CatalogueRouter";
import { Router } from "@/routing/router";

interface CatalogueBaseRouteParams {
  slug: string;
  name: PreAuthCatalogueRoute;
}

interface CoursePageQueryParams {
  ages: string[];
  days: string[];
  locationIds: string[];
  query: string;
  seasonIds: string[];
}

interface CoursesPageParams
  extends CoursePageQueryParams,
    CatalogueBaseRouteParams {
  name: "Courses";
  ages: string[];
}

interface BasketPageParams extends CatalogueBaseRouteParams {
  name: "MemberBasket";
}

interface CatalogueCoursePreviewPageParams
  extends CatalogueBaseRouteParams,
    CoursePreviewPageProps {
  name: "CatalogueCoursePreview";
}

export interface CatalogueAuthReturnParams {
  redirectTarget?: CatalogueAuthRoute;
  slug: string;
  name?: PreAuthCatalogueRoute;
  ages?: string[];
  days?: string[];
  locationIds?: string[];
  query?: string;
  seasonIds?: string[];
  courseId?: string;
}

function routeRedirect<T>(
  typeCheck: (routeParams: CatalogueAuthReturnParams) => boolean,
  navigate: (routeParams: T) => void,
) {
  return (routeParams: CatalogueAuthReturnParams) => {
    if (typeCheck(routeParams)) {
      navigate(routeParams as T);
      return true;
    }

    return false;
  };
}

function isCoursePageParams(
  routeParams: CatalogueAuthReturnParams,
): routeParams is CoursesPageParams {
  return routeParams.name === "Courses";
}

function redirectToCoursePage(routeParams: CoursesPageParams) {
  const { name, slug, ...rest } = routeParams;
  const { ages, days, locationIds, query, seasonIds } = rest;
  Router.replace(name, {
    slug,
    ages: ages ?? [],
    days: days ?? [],
    locationIds: locationIds ?? [],
    query: query ?? "",
    seasonIds: seasonIds ?? [],
  });
}

const coursePageRedirect = routeRedirect(
  isCoursePageParams,
  redirectToCoursePage,
);

function isBasketPageParams(
  routeParams: CatalogueAuthReturnParams,
): routeParams is BasketPageParams {
  return routeParams.name === "MemberBasket";
}

function redirectToBasketPage(routeParams: BasketPageParams) {
  const { name, slug } = routeParams;
  Router.replace(name, { slug });
}

const basketPageRedirect = routeRedirect(
  isBasketPageParams,
  redirectToBasketPage,
);

function isCatalogueCoursePreviewPageParams(
  routeParams: CatalogueAuthReturnParams,
): routeParams is CatalogueCoursePreviewPageParams {
  return routeParams.name === "CatalogueCoursePreview";
}

function redirectToCoursePreviewPage(
  routeParams: CatalogueCoursePreviewPageParams,
) {
  const { name, slug, ...rest } = routeParams;
  const { courseId } = rest;
  Router.replace(name, {
    slug,
    courseId: courseId ?? "",
  });
}

const coursePreviewPageRedirect = routeRedirect(
  isCatalogueCoursePreviewPageParams,
  redirectToCoursePreviewPage,
);

export function processRedirect(routeParams: CatalogueAuthReturnParams) {
  const redirected = [
    coursePageRedirect,
    basketPageRedirect,
    coursePreviewPageRedirect,
  ].some(redirect => redirect(routeParams));

  if (!redirected) {
    Router.replace("CompanyLandingPage", { slug: routeParams.slug });
  }
}
