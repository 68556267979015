import {
  CheckUserExistenceRequest,
  instanceOfErrorResponse,
  LinkDto,
} from "@justraviga/classmanager-sdk";

import { useUserExistenceCheckForm } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Button } from "@/modules/common/ui/button/Button";
import { Router } from "@/routing/router";

interface InvitationPageFormProps {
  linkDetails?: LinkDto;
}

export const InvitationPageForm = ({
  linkDetails,
}: InvitationPageFormProps) => {
  const handleContinue = async ({ email }: CheckUserExistenceRequest) => {
    if (linkDetails) {
      api.auth
        .checkUserExistence({
          checkUserExistenceRequest: { email },
        })
        .then(() => {
          Router.push("LinkedRegister", { id: linkDetails.id, email });
        })
        .catch(err => {
          // backend uses this to show the user already exists
          if (instanceOfErrorResponse(err) && err.statusCode === 400) {
            Router.push("LinkedLogin", { id: linkDetails.id, email });
          }
        });
    }
  };

  const {
    form,
    formHandlers: { handleSubmit },
  } = useUserExistenceCheckForm(useFormBuilder, handleContinue, {
    email: linkDetails?.email,
  });

  if (!linkDetails) {
    return null;
  }

  return (
    <>
      {form}
      <Button
        onClick={handleSubmit(handleContinue)}
        variant="brand"
        text="Continue"
        isFullWidth={true}
      />
    </>
  );
};
