import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  ClassImplementedActions,
  CompanyCoursePreview,
  useSharedCourseActions,
} from "shared/components";
import { ClassEntity } from "shared/lib";

import { CourseImage } from "@/modules/common/classes/CourseImage";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { ColorIndicator } from "@/modules/common/ui/ColorIndicator";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const useCourseActions = (): ClassImplementedActions => {
  const { openSheet } = useSheet();
  const sharedActions = useSharedCourseActions();

  return {
    ...sharedActions,

    showPreview: (classEntity: ClassEntity, season: SeasonDto) => {
      openSheet({
        title: "Class preview",
        content: (
          <CompanyCoursePreview
            courseEntity={classEntity}
            season={season}
            CourseImage={CourseImage}
            ColorIndicator={ColorIndicator}
            HorizontalSeparator={HorizontalSeparator}
          />
        ),
      });
    },
  };
};
