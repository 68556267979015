import { UpdateAddressesRequestAddressesInner } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = UpdateAddressesRequestAddressesInner;

const useDefinition = () => {
  const builder = new FormDefinitionBuilder<Schema>()
    .group("Address", ["address1", "address2", "town", "state", "countryCode"])
    .row(["state", "zip"])
    .text("address1", { label: "Address Line 1" })
    .text("address2", { label: "Address Line 2" })
    .text("town", { label: "Town/City" })
    .text("state", { label: "State" })
    .text("zip", { label: "Zip no." });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const useAddressUpdateFormDefinition = useDefinition;

export const makeAddressesUpdateRequest =
  ({ api, entityId }: { api: Api; entityId: string }) =>
  (formData: Schema) => {
    const addresses = [];

    //Check if the address is not empty
    if (
      formData.address1 ||
      formData.address2 ||
      formData.town ||
      formData.state ||
      formData.countryCode ||
      formData.zip
    ) {
      addresses.push(formData);
    }

    return api.addresses.updateAddresses({
      updateAddressesRequest: { entityId, addresses: addresses },
    });
  };

export const makeAddressesDeleteRequest = ({
  api,
  entityId,
}: {
  api: Api;
  entityId: string;
}) => {
  return api.addresses.updateAddresses({
    updateAddressesRequest: {
      entityId,
      addresses: [],
    },
  });
};
