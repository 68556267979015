import { RoomDto } from "@justraviga/classmanager-sdk";

import {
  EntityActions,
  makeRoomCreateRequest,
  makeRoomUpdateRequest,
  useRoomFormDefinition,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";

type Model = RoomDto;

type ImplementedActions = Omit<
  EntityActions<Model>,
  "archive" | "unarchive" | "deleteMany" | "archiveMany" | "unarchiveMany"
>;

export const useRoomActions = (): ImplementedActions => {
  const defaultActions = usePlatformEntityActions<RoomDto>({
    entity: "room",
  });

  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() => api.rooms.deleteRoom({ id: entity.id })),

    showCreateForm: ({ locationId }: { locationId: string }) =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeRoomCreateRequest({ api, locationId })}
            defaultValues={{}}
            formDefinitionHook={useRoomFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model, { locationId }: { locationId: string }) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeRoomUpdateRequest({
              api,
              id: entity.id,
              locationId,
            })}
            defaultValues={entity}
            formDefinitionHook={useRoomFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),
  };
};
