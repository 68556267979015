import { useEffect } from "react";

import { ContactDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../../../platformSpecific";

export function useSendToContact(
  updateRecipients: (recipients: ContactDto[]) => void,
  contactId?: string,
) {
  const { useApi } = getPlatformFunctions();

  const { data: contact, ...rest } = useApi(
    "getContact",
    { id: contactId || "" },
    { enabled: !!contactId },
  );

  useEffect(() => {
    if (contact) {
      updateRecipients([contact]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  return {
    contact,
    ...rest,
  };
}
