import { useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  StripePaymentIntentDto,
} from "@justraviga/classmanager-sdk";

import { isStripeCardData } from "../../third-party/stripe/stripeUtils";
import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";
import { CardPaymentDetails } from "../modules/customer/checkout/PaymentDetails/CardPaymentDetails";
import { TransactionPreviewContent } from "../transactions";

export const RefundDetails = ({
  setTitle,
  paymentIntent,
  transaction,
}: {
  paymentIntent: StripePaymentIntentDto;
  setTitle: (title: string) => void;
  transaction: AggregateTransactionDto;
}) => {
  const [showMore, setShowMore] = useState(false);
  const { Button, View } = useGenericComponents();
  const { setShouldShowFooter } = useFormActions();

  useEffect(() => {
    // Using setTimeout is a bit of a hack, but seems necessary to get mobile's FullScreenModal title to change
    setTimeout(() => {
      setTitle("Transaction details");
    });
  }, [setTitle]);

  useEffect(() => {
    setShouldShowFooter(false);
  }, [setShouldShowFooter]);

  return (
    <View>
      <TransactionPreviewContent
        transaction={transaction}
        isFamilyPortal={false}
      />

      {isStripeCardData(paymentIntent?.paymentMethod?.data) && (
        <View>
          <View className="mt-4 -ml-2">
            <Button
              variant="subtle"
              onClick={() => {
                setShowMore(v => !v);
              }}
              rightIcon={showMore ? "chevronUp" : "chevronDown"}
              text="More details"
              size="xs"
            />
          </View>
          {showMore && (
            <View className="mt-4">
              <CardPaymentDetails
                cardDetails={paymentIntent.paymentMethod.data}
                id={paymentIntent.id}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};
