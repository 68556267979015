import { FC, ReactNode } from "react";

import {
  AggregateClassDto,
  AttendanceDto,
  SeasonDto,
  StudentDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { fetchTrialsData } from "../../data/trialsData";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { displayStudentAge } from "../../studentUtils";
import { UseApi } from "../apiQueryFactory";
import {
  TrialsFilterFormSchema,
  useTrialsFilterForm,
} from "../formDefinitions/trialsFilterForm";

type Dto = TrialDto;
type RowDto = Dto & {
  attendance?: AttendanceDto;
  class: AggregateClassDto;
  student: StudentDto;
  season: SeasonDto;
};
export type TrialsRowDto = RowDto;
type FilterForm = TrialsFilterFormSchema;

interface UseTrialsDatatableParams {
  classId?: string;
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, FilterForm, RowDto>;
  }>;
  api: Api;
  useApi: UseApi;
  goToStudent: (id: string) => void;
  enrollForClass: (item: RowDto) => void;
  deleteOne: (item: Dto) => Promise<void>;
  showCreateForm: () => void;
  showUpdateForm: (item: RowDto) => void;
  archive: (item: RowDto) => Promise<void | Dto>;
  renderClassDetails: (item: RowDto) => ReactNode | void;
  renderTrialDetails: (item: RowDto) => ReactNode | void;
  renderMobileDetails: (item: RowDto) => ReactNode;
}

export const useTrialsDatatable = ({
  classId,
  Datatable,
  api,
  useApi,
  archive,
  goToStudent,
  deleteOne,
  enrollForClass,
  showCreateForm,
  showUpdateForm,
  renderClassDetails,
  renderTrialDetails,
  renderMobileDetails,
}: UseTrialsDatatableParams): { datatable: ReactNode } => {
  const filterForm = useTrialsFilterForm({ useApi });

  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    return fetchTrialsData({ api, request, classId });
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: "trial",
    title: "Trials",
    createAction: showCreateForm,
    createLabel: "Add trial students",
    rowActions: {
      title: item => `${item.student.firstname} ${item.student.lastname}`,
      click: item => goToStudent(item.student.id),
      additionalRowActions: () => [
        {
          label: "Go to student",
          icon: "openOutline",
          onClick: item => goToStudent(item.student.id),
        },
        {
          title: "Actions",
          actions: [
            {
              label: "Enroll for class",
              icon: "calendarOutline",
              onClick: enrollForClass,
              enabled: item => item.archivedAt === null,
            },
            {
              label: "Mark as not continuing",
              icon: "closeCircleOutline",
              onClick: archive,
              enabled: item => item.archivedAt === null,
            },
          ],
        },
        {
          title: "Trial",
          actions: [
            {
              label: "Edit",
              icon: "createOutline",
              onClick: showUpdateForm,
              enabled: item => item.archivedAt === null,
            },

            {
              label: "Delete",
              icon: "trashOutline",
              onClick: deleteOne,
              isDestructive: true,
            },
          ],
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "schoolOutline",
        title: "No trials yet",
        description: "Create a trial to get started!",
      },
    },
    hasPagination: true,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Student",
        placeholder: "tile",
        value: row => {
          return {
            type: "tile",
            title: `${row.item.student.firstname} ${row.item.student.lastname}`,
            subtitle: displayStudentAge(row.item.student),
            image: row.item.student.profilePicture ?? undefined,
          };
        },
      },
      {
        label: "Class",
        placeholder: "text",
        value: row => {
          return {
            type: "custom",
            children: renderClassDetails(row.item) ?? <></>,
          };
        },
      },
      {
        label: "Trial date",
        placeholder: "text",
        value: row => {
          return {
            type: "custom",
            children: renderTrialDetails(row.item) ?? <></>,
          };
        },
        sortableField: "trialAt",
      },
    ],
    mobileColumn: {
      hasImage: true,
      image: row => row.item.student.profilePicture ?? undefined,
      title: row =>
        `${row.item.student.firstname} ${row.item.student.lastname}`,
      subtitle: row => row.item.class.entity.name,
      children: row => renderMobileDetails(row.item),
    },
    filterForm,
  };

  return {
    datatable: <Datatable key={classId} configuration={config} />,
  };
};
