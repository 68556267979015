import {
  useQuery,
  UseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";

import { ApiFlattened } from "../api";

export type ApiMethod = keyof ApiFlattened;

export type ApiReturnType<
  Method,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = Method extends (...args: any) => Promise<infer R> ? R : never;

export const apiQueryFactory =
  (api: ApiFlattened) =>
  <M extends ApiMethod>(
    method: M,
    params: Parameters<ApiFlattened[M]>[0],
    queryOptions: Omit<
      Parameters<typeof useQuery>[0],
      "queryKey" | "queryFn"
    > = {},
  ) => {
    const methodFn = api[method];
    // @ts-expect-error - We're confident of the params here
    const queryFn = () => methodFn(params);

    return useQuery({
      ...queryOptions,
      // Our L1 cache. The query key is just a simple way to identify this specific request.
      // We rely on the L2 cache (the SDK) to deal with invalidating this key
      queryKey: [method, params],
      queryFn,
    }) as UseQueryResult<ApiReturnType<ApiFlattened[M]>>;
  };

export type UseApi = ReturnType<typeof apiQueryFactory>;

export const suspenseQueryFactory =
  (api: ApiFlattened) =>
  <M extends ApiMethod>(
    method: M,
    params: Parameters<ApiFlattened[M]>[0],
    queryOptions: Omit<
      Parameters<typeof useSuspenseQuery>[0],
      "queryKey" | "queryFn"
    > = {},
  ) => {
    const methodFn = api[method];
    // @ts-expect-error - We're confident of the params here
    const queryFn = () => methodFn(params);

    return useSuspenseQuery({
      ...queryOptions,
      // Our L1 cache. The query key is just a simple way to identify this specific request.
      // We rely on the L2 cache (the SDK) to deal with invalidating this key
      queryKey: [method, params],
      queryFn,
    }).data as ApiReturnType<ApiFlattened[M]>;
  };

export type UseData = ReturnType<typeof suspenseQueryFactory>;
