import { BannerComponent } from "../../../interfaces";

interface SpaceNotGuaranteedBannerProps {
  Banner: BannerComponent;
}

export const SpaceNotGuaranteedBanner = ({
  Banner,
}: SpaceNotGuaranteedBannerProps) => (
  <Banner
    className={"w-full text-label-400 text-grey-900"}
    content={"Your space is not guaranteed in the class until you pay."}
    variant={"warning"}
  />
);
