import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { exampleUserDatatableConfiguration } from "@/modules/common/datatable/DatatableExample";

export const DatatablePage = () => {
  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title="Datatable" />
      </AdminTitleBar>
      <AdminContent>
        <Datatable configuration={exampleUserDatatableConfiguration} />
      </AdminContent>
    </AdminLayout>
  );
};
