import { SeasonDto } from "@justraviga/classmanager-sdk";

import { getSeasonStatus } from "../../../../seasonUtils";
import { Chip } from "../../../ui/Chip";

interface SeasonStatusChipProps {
  season: SeasonDto;
}

export const SeasonStatusChip = ({ season }: SeasonStatusChipProps) => {
  if (season.archivedAt) {
    return <Chip label={"Archived"} variant={"neutral"} />;
  }

  const status = getSeasonStatus(season);

  return status === "upcoming" ? (
    <Chip label={"Upcoming"} variant={"success"} />
  ) : status === "past" ? (
    <Chip label={"Past"} variant={"neutral"} />
  ) : (
    <Chip label={"Current"} variant={"primary"} />
  );
};
