import { FC } from "react";

import { UserAccountDto } from "@justraviga/classmanager-sdk";

import { cn } from "../../../../cssUtils";
import { enumLabel } from "../../../../translateUtils";
import { useAuthState } from "../../../AuthStateProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  account: UserAccountDto;
  rowClick: (account: UserAccountDto) => void;
  CompanyLogo: FC<{ logo: string | null | undefined }>;
}

export const AccountCard = ({ account, rowClick, CompanyLogo }: Props) => {
  const { Icon, Pressable, Text, View } = useGenericComponents();
  const { account: currentAccount } = useAuthState();

  const isCurrentAccount = account.id === currentAccount?.id;

  return (
    <Pressable
      className={cn(
        "flex h-20 flex-row items-center space-x-4 rounded-md border border-grey-300 p-2 hover:bg-grey-100 cursor-pointer justify-between",
        { "border-grey-900": isCurrentAccount },
      )}
      onClick={() => rowClick(account)}>
      <CompanyLogo logo={account.company?.logo} />
      <View className={"flex flex-row flex-grow items-center space-x-4"}>
        <View className={"flex flex-grow flex-col gap-y-1 truncate"}>
          {Boolean(account.company?.name) && (
            <Text
              className={"truncate text-left text-heading6-600 text-grey-900"}>
              {account.company?.name}
            </Text>
          )}
          <Text className={"truncate text-left text-body-400 text-grey-600"}>
            {enumLabel("accountRole", account.role)} - Class Manager
          </Text>
        </View>
        {isCurrentAccount && <Icon name={"checkmarkCircle"} size={30} />}
      </View>
    </Pressable>
  );
};
