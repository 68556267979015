import {
  DefaultEntityActions,
  useDefaultEntityActions,
} from "shared/components";
import {
  PlatformEntityActions,
  PlatformEntityActionsParams,
  useAlert,
} from "shared/lib";

import { CreateFooter } from "@/modules/common/form/CreateFooter";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";

export const usePlatformEntityActions: PlatformEntityActions = <
  Model extends object,
>({
  entity,
}: PlatformEntityActionsParams): DefaultEntityActions<Model> =>
  useDefaultEntityActions<Model>({
    entity: entity,
    showAlert: useAlert().showAlert,
    showConfirmationDialog: useAlertDialog().showAlert,
    showForm: useSheet().openSheet,
    hideForm: useSheet().closeSheet,
    CreateFooter,
    UpdateFooter,
  });
