import { StaffScheduleWidget as SharedStaffScheduleWidget } from "shared/components";

import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { Router } from "@/routing/router";

export const StaffScheduleWidget = ({ staffId }: { staffId: string }) => {
  return (
    <SharedStaffScheduleWidget
      BaseCard={BaseCard}
      Button={Button}
      Icon={Icon}
      goToAttendances={params =>
        Router.push("ClassLessonAttendances", {
          ...params,
          id: params.courseId,
        })
      }
      goToLesson={params =>
        Router.push("LessonDetails", { ...params, id: params.courseId })
      }
      staffId={staffId}
    />
  );
};
