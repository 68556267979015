import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ButtonComponent } from "../../../interfaces";

interface ProceedToCheckoutButtonProps {
  disabled: boolean;
  validateBasketAndGoToCheckout: () => void;
  Button: ButtonComponent;
}

export const ProceedToCheckoutButton = ({
  disabled,
  validateBasketAndGoToCheckout,
  Button,
}: ProceedToCheckoutButtonProps) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className={"flex flex-col space-y-4"}>
      <Button
        variant={"brand"}
        text={"Checkout"}
        isFullWidth={true}
        onClick={validateBasketAndGoToCheckout}
        disabled={disabled}
      />
      <Text className={"text-label-400 text-grey-600 text-center"}>
        Any applicable discounts and fees will be added at checkout.
      </Text>
    </View>
  );
};
