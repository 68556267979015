import { FC, ReactNode } from "react";

import { ListUserWhereParameter, UserDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { getFullName } from "../../personUtil";

type Dto = UserDto;
type FilterForm = object;

export const useUserDatatable = ({
  Datatable,
  api,
  onImpersonateUserSuccess,
}: {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, FilterForm> }>;
  api: Api;
  onImpersonateUserSuccess: (user: UserDto, token: string) => void;
}): { datatable: ReactNode } => {
  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    request.where = (request.where ?? {}) as ListUserWhereParameter;
    return api.users.listUser(request);
  };

  async function impersonateUser(item: Dto) {
    const newUser = await api.users.impersonateUser({ user: item.id });
    if (newUser) {
      onImpersonateUserSuccess(newUser.user, newUser.token);
    }
  }

  const config: DatatableConfiguration<Dto, object> = {
    id: "user",
    title: "Users",
    hasPagination: true,
    placeholdersCount: 5,
    rowActions: {
      additionalRowActions: (item: Dto) => {
        return [
          {
            label: "Impersonate User",
            icon: "personOutline",
            onClick: () => impersonateUser(item),
          },
        ];
      },
    },
    fetchData,
    columns: [
      {
        label: "Name",
        placeholder: "tile",
        value: row => {
          return {
            type: "tile",
            title: getFullName(row.item),
            subtitle: row.item.id,
            hasImage: false,
          };
        },
      },
      {
        label: "Email",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: row.item.email,
        }),
      },
      {
        label: "Phone",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: row.item.phone ?? "",
        }),
      },
    ],
    mobileColumn: {
      hasImage: false,
      title: row => getFullName(row.item),
      subtitle: row =>
        row.item.phone
          ? `${row.item.email} / ${row.item.phone}`
          : row.item.email,
    },
    contentPlaceholders: {
      noContent: {
        icon: "personOutline",
        title: "No users yet",
        description: "No users here yet",
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
