import { cn, DatatableContentState, useDatatable } from "shared/lib";

import { DatatableFilters } from "@/modules/common/datatable/common/DatatableFilters";
import { MobileSelectButton } from "@/modules/common/datatable/content/mobile/MobileSelectButton";
import { SearchInput } from "@/modules/common/form/SearchInput";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const MobileHeader = ({
  contentState,
  totalResults,
}: {
  contentState: DatatableContentState;
  totalResults: number;
}) => {
  const { datatable, setSearch } = useDatatable();

  const recordCountDisplay = String(totalResults)
    .concat(" Record")
    .concat(totalResults === 1 ? "" : "s");

  return (
    <>
      {/** Title and create button */}
      {datatable.title || datatable.createAction ? (
        <div className={"flex items-center justify-between pb-5"}>
          <div>
            {datatable.title && (
              <h6
                className={cn(
                  "mr-4 line-clamp-1 text-grey-900",
                  datatable.titleSize === "sm"
                    ? "text-heading6-600"
                    : "text-heading5-600",
                )}>
                {datatable.title}
              </h6>
            )}
          </div>

          {datatable.createAction && (
            <ProtectedOverlay permission={datatable.permissions?.create}>
              <IconButton
                icon={"addOutline"}
                border={"rounded"}
                onClick={datatable.createAction}
                variant={"secondary-fill"}
                size={datatable.titleSize === "sm" ? "sm" : "lg"}
              />
            </ProtectedOverlay>
          )}
        </div>
      ) : null}

      {/** Search and filter */}
      {datatable.hasSearch !== false || datatable.filterForm ? (
        <section className={"flex items-center justify-between gap-4"}>
          {datatable.hasSearch !== false && (
            <div className="flex-grow">
              <SearchInput onChange={setSearch} height={"md"} />
            </div>
          )}
          <DatatableFilters />
        </section>
      ) : null}

      {/** Total records and select all button */}
      {datatable.showTotalRecords !== false || datatable.groupActions ? (
        <section>
          <div className={"flex items-center justify-between py-5"}>
            {datatable.showTotalRecords !== false && (
              <p className={"font-base text-grey-600"}>{recordCountDisplay}</p>
            )}
            <MobileSelectButton
              contentState={contentState}
              totalResults={totalResults}
            />
          </div>
        </section>
      ) : null}
    </>
  );
};
