import { FC } from "react";

import { useOpenRegistrationUrl } from "../../../../locationUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  onLogout: () => void;
  Version: FC;
  showLoginLink?: boolean;
}

export const AccountSelectionFooter = ({
  onLogout,
  Version,
  showLoginLink = true,
}: Props) => {
  const { Button, Text, View } = useGenericComponents();
  const openRegistrationUrl = useOpenRegistrationUrl();

  return (
    <View className={"space-y-4"}>
      {showLoginLink && (
        <Button
          isFullWidth={true}
          variant={"tertiary"}
          text={"Log in to a different account"}
          onClick={onLogout}
        />
      )}
      <View className={"flex flex-row items-center justify-center"}>
        <Text className={"text-body-400 text-grey-500"}>
          Trying to register a studio?
        </Text>
        <Button
          variant={"tertiary"}
          text={"Sign up"}
          onClick={openRegistrationUrl}
        />
      </View>
      <Version />
    </View>
  );
};
