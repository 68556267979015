export interface LockedFeature {
  isProtected: true;
  message: string;
}

export interface UnlockedFeature {
  isProtected: false;
}

export type ProtectedFeature = LockedFeature | UnlockedFeature;

export interface ProtectionChecker {
  isProtected: () => boolean;
  message: () => string;
  extend: (extProtector: ProtectionChecker) => ProtectionChecker;
  items: () => ProtectedFeature[];
}

export const lockedFeature = (message: string): LockedFeature => ({
  isProtected: true,
  message,
});

export const unlockedFeature = (): UnlockedFeature => ({
  isProtected: false,
});

export function checkProtection(
  protectedItems: ProtectedFeature[],
): ProtectionChecker {
  const isProtected = () => {
    return protectedItems.some(item => item.isProtected);
  };

  const message = () => {
    const protectedItem = protectedItems.find(item => item.isProtected);
    if (protectedItem) {
      return protectedItem.message;
    }

    return "";
  };

  const items = () => protectedItems;

  const extend = (protectionChecker: ProtectionChecker) => {
    return checkProtection([...protectedItems, ...protectionChecker.items()]);
  };

  return {
    isProtected,
    message,
    extend,
    items,
  };
}
