import { FC } from "react";

import { ChartAxisDto } from "@justraviga/classmanager-sdk";
import {
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";

import { CustomTooltip, TooltipSection } from "shared/components";
import { colors, dayjs, formatDate, useBreakpoint } from "shared/lib";

import { ChartContainer } from "@/modules/company/dashboard/charts/ChartContainer";
import {
  ChartData,
  CustomTooltipProps,
} from "@/modules/company/dashboard/charts/chartTypes";
import { referenceLines } from "@/modules/company/dashboard/charts/referenceLines";

const Tooltip: FC<CustomTooltipProps> = ({ active, payload }) => {
  if (!active || !payload || !payload.length) return null;

  const date = payload[0].payload.label;
  const value = payload[0].payload.value;
  return (
    <CustomTooltip title={formatDate(`${date}-01`, "monthYear")}>
      <TooltipSection
        title="Enrolled students"
        data={[]}
        total={value}
        colour={colors.brand["800"]}
      />
    </CustomTooltip>
  );
};

export const EnrolmentTrendChart: FC<{ data: ChartAxisDto[] }> = ({ data }) => {
  const breakpoint = useBreakpoint();
  const xAxisPadding = breakpoint.md ? 40 : 20;

  const formattedData: ChartData[] = data.map(item => ({
    name: dayjs(item.name).format("MMM"),
    label: item.data[0].label,
    value: item.data[0].values[0].value,
  }));

  const yAxisMax = Math.max(...formattedData.map(item => item.value)) * 1.1;

  return (
    <ChartContainer>
      <LineChart data={formattedData}>
        {...referenceLines({ yAxisMin: 0, yAxisMax, yAxisDivisions: 4 })}
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          padding={{ left: xAxisPadding, right: xAxisPadding }}
        />
        <YAxis hide tickLine={false} domain={[0, yAxisMax]} />
        <RechartsTooltip content={<Tooltip />} />
        <Line
          type="monotone"
          dataKey="value"
          stroke={colors.brand["800"]}
          strokeWidth={2}
          dot={({ cx, cy }) => (
            <CustomDot cx={cx} cy={cy} key={`chart-key-${cx}-${cy}`} />
          )}
          activeDot={false}
        />
      </LineChart>
    </ChartContainer>
  );
};

const CustomDot: FC<{ cx: number; cy: number }> = ({ cx, cy }) => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={6}
      fill={colors.brand["800"]}
      stroke={colors.brand["800"]}
      strokeWidth={0}
    />
  );
};
