import { StatCountCard } from "./StatCountCard";
import { formatDateTime } from "../../../../intlFormatter";
import { dayjs } from "../../../../lib/dayjs";
import { getPlatformFunctions } from "../../../../platformSpecific";

export const TotalStudentsWidget = () => {
  const { useApi } = getPlatformFunctions();

  const { data: total, isLoading: isLoadingTotal } = useApi(
    "countStudent",
    {},
    {},
  );

  const { data: recent, isLoading: isLoadingRecent } = useApi("countStudent", {
    where: {
      createdAt: {
        greaterThanOrEqual: formatDateTime(
          dayjs().subtract(28, "day").toDate(),
          "isoDate",
        ),
      },
    },
  });

  const isLoading = isLoadingTotal || isLoadingRecent;

  return (
    <StatCountCard
      isLoading={isLoading}
      title={"Total students"}
      totalCount={total?.count ?? 0}
      recentCount={recent?.count ?? 0}
    />
  );
};
