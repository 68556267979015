import { AccountRole, idPrefix as Prefix } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { SearchableId, SearchableIdPrefix } from "shared/lib";

import { Router } from "@/routing/router";

const Role = AccountRole;

/**
 * Get a suitable link for displaying an entity, given the current role of the user's account.
 * Typically, this will be used when handling global searches, where a list of matching
 * entities (of potentially various types) will be provided to us, and we need to handle the user clicking on one of
 * the results.
 *
 * If a suitable link cannot be found, null will be returned, which we should either display as a 404, or redirect the
 * user to the account switcher.
 */
export const getEntityLink = (
  role: AccountRole,
  id: SearchableId,
): string | null => {
  const prefix = id.split("_")[0] as SearchableIdPrefix;

  return (
    match([role, prefix])
      // Admin Portal
      .with([Role.Admin, Prefix.Staff], () => null)
      .with([Role.Admin, Prefix.Student], () => null)
      .with([Role.Admin, Prefix.Family], () => null)
      .with([Role.Admin, Prefix.Course], () => null)
      .with([Role.Admin, Prefix.Season], () => null)

      // Company Portal
      .with([Role.Staff, Prefix.Staff], () => Router.StaffDetails({ id }))
      .with([Role.Staff, Prefix.Student], () => Router.StudentDetails({ id }))
      .with([Role.Staff, Prefix.Family], () => Router.FamilyDetails({ id }))
      .with([Role.Staff, Prefix.Course], () => Router.ClassDetails({ id }))
      .with([Role.Staff, Prefix.Season], () => Router.SeasonDetails({ id }))

      // Customer Portal
      .with([Role.Family, Prefix.Staff], () => null)
      .with([Role.Family, Prefix.Student], () => null)
      .with([Role.Family, Prefix.Family], () => null)
      .with([Role.Family, Prefix.Course], () => null)
      .with([Role.Family, Prefix.Season], () => null)

      // Important; make sure we have covered all role / id combinations
      .exhaustive()
  );
};
