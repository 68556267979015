import { useEffect, useState } from "react";

export type Breakpoint = "all" | "md" | "lg" | "xl" | "xxl";

// These breakpoints should be kept aligned with those in tailwind.config.js
const breakpoints: Record<Breakpoint, number> = {
  all: 0,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
} as const;

/**
 * These breakpoint properties indicate whether the current user's browser window is *at least* as wide as the given
 * breakpoint. So if the user's window is 4000px wide, then *all* the properties will be set to true.
 *
 *    `if (breakpoint.lg)` means "display this if the viewport is at least as wide as our 'lg' breakpoint"
 *    `if (!breakpoint.md)` means "display this if the viewport is smaller than our 'md' breakpoint"
 *
 * Only import this constant directly if you're *not* in a React component. If you're in a React component, use the
 * `useBreakpoint` hook instead.
 */
export const breakpoint: Record<Breakpoint, boolean> = {
  all: true,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
};

// Only exported for testing purposes
export const onViewportResize = (width: number) => {
  let hasChanged = false;
  for (const key in breakpoints) {
    const bp = key as Breakpoint;
    const newValue = width >= breakpoints[bp];
    if (breakpoint[bp] !== newValue) {
      breakpoint[bp] = width >= breakpoints[bp];
      hasChanged = true;
    }
  }
  if (hasChanged) {
    notifySubscribers(); // Just for React
  }
};

// Check if ResizeObserver exists (not in pipeline) before using it
const viewportObserver =
  typeof ResizeObserver === "undefined"
    ? null
    : new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          for (const entry of entries) {
            if (!entry) return;
            onViewportResize(entry.contentRect.width);
          }
        });
      });
if (viewportObserver) {
  viewportObserver.observe(document.querySelector("html")!);
}

// React-specific stuff below

const subscribers = new Set<(v: typeof breakpoint) => void>();
const notifySubscribers = () => {
  subscribers.forEach(notify => notify(breakpoint));
};
const subscribe = (listener: (v: typeof breakpoint) => void) => {
  subscribers.add(listener);
  return () => {
    subscribers.delete(listener);
  };
};

export const useBreakpoint = () => {
  const [bp, setBp] = useState<typeof breakpoint>({ ...breakpoint });

  useEffect(() => {
    return subscribe(v => {
      setBp({ ...v });
    });
  }, []);

  return bp;
};
