import { MultiSearchResponse } from "typesense/lib/Typesense/MultiSearch";

import {
  CourseDocument,
  FamilyDocument,
  GlobalSearchResults,
  GlobalSearchResultsType,
  LocationDocument,
  SearchCollection,
  SeasonDocument,
  StaffDocument,
  StudentDocument,
} from "./searchTypes";

export function buildEmptySearchObject(
  type: GlobalSearchResultsType,
): GlobalSearchResults {
  return {
    type,
    totalCount: 0,
    data: {
      [SearchCollection.staff]: {
        count: 0,
        documents: [],
      },
      [SearchCollection.family]: {
        count: 0,
        documents: [],
      },
      [SearchCollection.student]: {
        count: 0,
        documents: [],
      },
      [SearchCollection.course]: {
        count: 0,
        documents: [],
      },
      [SearchCollection.season]: {
        count: 0,
        documents: [],
      },
      [SearchCollection.location]: {
        count: 0,
        documents: [],
      },
    },
  };
}

export function globalSearchMapping(
  response: MultiSearchResponse<
    | StaffDocument[]
    | FamilyDocument[]
    | StudentDocument[]
    | CourseDocument[]
    | SeasonDocument[]
    | LocationDocument[]
  >,
) {
  const tempResults = buildEmptySearchObject(GlobalSearchResultsType.response);

  response.results.forEach(collection => {
    if (
      collection?.request_params?.collection_name &&
      Object.keys(SearchCollection).indexOf(
        collection?.request_params?.collection_name,
      ) != -1
    ) {
      const collectionName = collection?.request_params
        ?.collection_name as SearchCollection;
      tempResults.data[collectionName] = {
        count: collection.found,
        // @ts-expect-error - unable to determine which collection type
        // it is without extra code
        documents: collection?.hits?.map(hit => hit.document) || [],
      };
      tempResults.totalCount += collection.found;
    }
  });

  return tempResults;
}
