import {
  CreateFromSeasonTransactionRequest,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { getPlatformFunctions } from "../../../platformSpecific";

type Schema = CreateFromSeasonTransactionRequest;

export const useUnpostedTuitionForm = () => {
  const { useApi } = getPlatformFunctions();
  const { data: seasons } = useApi("listSeason", {
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });

  const builder = new FormDefinitionBuilder<Schema>().select("seasonId", {
    label: "Select season",
    data: (seasons?.data ?? []).map(season => ({
      value: season.id,
      label: season.name,
    })),
    required: true,
  });

  return builder.getDefinition();
};

export const makeUnPostedTuitionFormRequest =
  ({ api, familyId }: { api: Api; familyId: string }) =>
  (formData: Schema) => {
    return api.transactions.createFromSeasonTransaction({
      createFromSeasonTransactionRequest: {
        ...formData,
        familyId,
      },
    });
  };
