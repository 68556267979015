import { ButtonProps } from "shared/lib";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Button } from "@/modules/common/ui/button/Button";

const loading: Array<ButtonProps> = [
  {
    loading: true,
    variant: "primary",
  },
  { loading: true, variant: "secondary" },
  { loading: true, variant: "tertiary" },
  { loading: true, variant: "tertiaryLight" },
  { loading: true, variant: "subtle" },
  { loading: true, variant: "destructive" },
  { loading: true, variant: "destructive-outline" },
  { loading: true, variant: "brand" },
  { loading: true, variant: "classmanager" },
];
const links: Array<ButtonProps> = [
  {
    link: {
      href: "https://google.com",
      text: "To google",
      target: "_blank",
    },
    variant: "brand",
  },
  {
    link: {
      href: "https://www.bbc.co.uk/",
      text: "To BBC",
      target: "_blank",
    },
    variant: "brand",
  },
];
const widths: Array<ButtonProps> = [
  {
    isFullWidth: true,
    text: "Full width",
    variant: "brand",
  },
  {
    isFullWidth: false,
    text: "default",
    variant: "brand",
  },
];

const options: Array<Option<ButtonProps>> = [
  {
    title: "Primary",
    props: [
      {
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        variant: "brand",
      },
      {
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
        variant: "brand",
      },
      {
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        variant: "brand",
      },
      {
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
        variant: "brand",
      },
      {
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        variant: "brand",
      },
      {
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
        variant: "brand",
      },
    ],
  },
  {
    title: "Secondary",
    props: [
      {
        variant: "secondary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "secondary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "secondary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "secondary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "secondary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "secondary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Tertiary",
    props: [
      {
        variant: "tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Tertiary Light",
    props: [
      {
        variant: "tertiaryLight",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "tertiaryLight",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "tertiaryLight",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "tertiaryLight",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "tertiaryLight",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "tertiaryLight",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Subtle",
    props: [
      {
        variant: "subtle",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "subtle",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "subtle",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "subtle",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "subtle",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "subtle",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Destructive",
    props: [
      {
        variant: "destructive",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "destructive",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "destructive",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "destructive",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "destructive",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "destructive",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Destructive Outline",
    props: [
      {
        variant: "destructive-outline",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "destructive-outline",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "destructive-outline",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "destructive-outline",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "destructive-outline",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "destructive-outline",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Destructive Teritary",
    props: [
      {
        variant: "destructive-tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "destructive-tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "destructive-tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "destructive-tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "destructive-tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "destructive-tertiary",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Brand",
    props: [
      {
        variant: "brand",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "brand",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "brand",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "brand",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "brand",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "brand",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Class Manager Brand",
    props: [
      {
        variant: "classmanager",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
      },
      {
        variant: "classmanager",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        disabled: true,
      },
      {
        variant: "classmanager",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
      },
      {
        variant: "classmanager",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "sm",
        disabled: true,
      },
      {
        variant: "classmanager",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
      },
      {
        variant: "classmanager",
        leftIcon: "shareSocialOutline",
        text: "Button",
        rightIcon: "chevronDown",
        size: "xs",
        disabled: true,
      },
    ],
  },
  {
    title: "Loading",
    props: loading,
  },
  {
    title: "Links",
    props: links,
  },
  {
    title: "width",
    props: widths,
  },
];

export const ButtonPage = () => {
  return (
    <>
      <DocWrapper title="Button" component={Button} options={options} />
    </>
  );
};
