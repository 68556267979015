import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { ContactDto, FamilyDto } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { showAlert } from "../../alertState";
import { DatatableConfiguration } from "../../datatable";
import { getFirstLetters } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import { useMinimalFamilyDatatable } from "../datatables/useMinimalFamilyDatatable";
import { useFormActions } from "../FormActionsProvider";
import {
  makeFamilyCreateRequest,
  useFamilyCreateFormDefinition,
} from "../formDefinitions/familyForm";
import {
  makeStudentCreateRequest,
  useStudentCreateFormDefinition,
} from "../formDefinitions/studentForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { CircularIndicatorProps } from "../interfaces/circularIndicator";
import { Avatar } from "../ui/Avatar";

export type FamilyMode = "new" | "existing";
export type FormType =
  | "CreateOrExisting"
  | "CreateFamily"
  | "ExistingFamily"
  | "CreateStudent";

interface MultiStepStudentFormContextType {
  formType: FormType;
  family: FamilyDto | null;
  setFormType: (formType: FormType) => void;
  setFamily: (family: FamilyDto | null) => void;
  onSuccess: () => void;
}

const MultiStepStudentFormContext =
  createContext<MultiStepStudentFormContextType>({
    formType: "CreateOrExisting",
    family: null,
    setFormType: () => {},
    setFamily: () => {},
    onSuccess: () => {},
  });

type Dto = FamilyDto;
type RowDto = Dto & {
  primaryContact: ContactDto;
};

interface SelectFamilyDatatableProps {
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, Record<string, never>, RowDto>;
  }>;
}

interface MultiStepStudentFormProviderProps extends PropsWithChildren {
  onSuccess: () => void;
}

const MultiStepStudentFormProvider = ({
  children,
  onSuccess,
}: MultiStepStudentFormProviderProps) => {
  const [formType, setFormType] = useState<FormType>("CreateOrExisting");
  const [family, setFamily] = useState<FamilyDto | null>(null);

  return (
    <MultiStepStudentFormContext.Provider
      value={{
        formType,
        family,
        setFormType,
        setFamily,
        onSuccess,
      }}>
      {children}
    </MultiStepStudentFormContext.Provider>
  );
};

const useMultiStepStudentForm = () => {
  const context = useContext(MultiStepStudentFormContext);
  if (!context) {
    throw new Error(
      "useMultiStepStudentForm() must be used within a MultiStepStudentFormProvider",
    );
  }
  return context;
};

interface FormHeaderProps {
  CircularIndicator: React.FC<CircularIndicatorProps>;
}

const FormHeader = ({ CircularIndicator }: FormHeaderProps) => {
  const { Text, View } = useGenericComponents();
  const { formType } = useMultiStepStudentForm();

  const { title, step } = match(formType)
    .with("CreateOrExisting", () => ({
      title: "Select or add family",
      step: 1,
    }))
    .with("CreateFamily", () => ({
      title: "Create new family",
      step: 2,
    }))
    .with("ExistingFamily", () => ({
      title: "Choose existing family",
      step: 2,
    }))
    .with("CreateStudent", () => ({
      title: "Create details",
      step: 3,
    }))
    .exhaustive();

  return (
    <View className="flex h-20 flex-row items-center justify-between rounded-md bg-grey-200 p-4">
      <Text className="text-body-600 font-semibold">{title}</Text>
      <CircularIndicator className={"h-12 w-12"} value={step} maxValue={3} />
    </View>
  );
};

const CreateOrExistingForm = () => {
  const { Text, View, Pressable, Banner } = useGenericComponents();
  const { setFormType } = useMultiStepStudentForm();
  const { setShouldShowFooter, setAllowCreateAdditional } = useFormActions();

  useEffect(() => {
    setShouldShowFooter(false);
    setAllowCreateAdditional(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View className={"flex flex-col space-y-5"}>
      <Pressable
        className="text-left"
        onClick={() => {
          setFormType("CreateFamily");
        }}>
        <Banner
          className="bg-white py-4"
          content={
            <View className="flex flex-col space-y-1">
              <Text className="text-body-600 text-grey-900">New family</Text>
              <Text>Add student to a new family account</Text>
            </View>
          }
        />
      </Pressable>
      <Pressable
        className="text-left"
        onClick={() => {
          setFormType("ExistingFamily");
        }}>
        <Banner
          className="bg-white py-4"
          content={
            <View className="flex flex-col space-y-1">
              <Text className="text-body-600 text-grey-900">
                Existing family
              </Text>
              <Text>Add student to an existing family account</Text>
            </View>
          }
        />
      </Pressable>
    </View>
  );
};

const CreateFamilyForm = () => {
  const { api } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const { setFamily, setFormType } = useMultiStepStudentForm();
  const { setShouldShowFooter, setAllowCreateAdditional } = useFormActions();

  useEffect(() => {
    setShouldShowFooter(true);
    setAllowCreateAdditional(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericForm
      onSuccess={entity => {
        showAlert({
          content: "Family created successfully",
        });
        setFamily(entity);
        setFormType("CreateStudent");
      }}
      apiRequest={makeFamilyCreateRequest({ api })}
      defaultValues={{}}
      formDefinitionHook={useFamilyCreateFormDefinition}
    />
  );
};

const ExistingFamilyForm = ({ Datatable }: SelectFamilyDatatableProps) => {
  const { api } = getPlatformFunctions();
  const { setFormType, setFamily } = useMultiStepStudentForm();
  const { setShouldShowFooter, setAllowCreateAdditional } = useFormActions();
  const { datatable } = useMinimalFamilyDatatable({
    api,
    Datatable,
    onRowClick: (family: FamilyDto) => {
      setFamily(family);
      setFormType("CreateStudent");
    },
  });

  useEffect(() => {
    setShouldShowFooter(false);
    setAllowCreateAdditional(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return datatable;
};

interface MultiStepFormFamilyCardProps {
  family?: FamilyDto | undefined;
  onEdit?: () => void;
}

export const MultiStepStudentFormFamilyCard = ({
  family,
  onEdit,
}: MultiStepFormFamilyCardProps) => {
  const { Banner, View, Text, Pressable } = useGenericComponents();

  return (
    <Banner
      className="bg-white py-4"
      content={
        <View className="flex flex-row justify-between">
          <View className="flex flex-row space-x-4">
            <Avatar
              alt={family?.name ? getFirstLetters(family.name) : undefined}
            />
            <View className="flex flex-col">
              <Text className="text-label-600 text-grey-600">Family</Text>
              <Text className="text-body-400 text-grey-900">
                {family?.name}
              </Text>
            </View>
          </View>
          {onEdit && (
            <View>
              <Pressable onClick={onEdit}>
                <Text className="text-label-400 text-grey-600">Edit</Text>
              </Pressable>
            </View>
          )}
        </View>
      }
    />
  );
};

const CreateStudentForm = () => {
  const { View, GenericForm } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const { family, setFormType, setFamily, onSuccess } =
    useMultiStepStudentForm();
  const { setShouldShowFooter, setAllowCreateAdditional } = useFormActions();

  useEffect(() => {
    setAllowCreateAdditional(true);
    setShouldShowFooter(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View className="flex flex-col space-y-5">
      <View>
        <MultiStepStudentFormFamilyCard
          family={family || undefined}
          onEdit={() => {
            setFormType("CreateOrExisting");
            setFamily(null);
          }}
        />
      </View>
      {family?.id ? (
        <View>
          <GenericForm
            apiRequest={makeStudentCreateRequest({
              api,
              familyId: family.id,
            })}
            defaultValues={{ familyId: family.id }}
            formDefinitionHook={useStudentCreateFormDefinition}
            onSuccess={() => {
              setFormType("CreateOrExisting");
              setFamily(null);
              onSuccess();
            }}
          />
        </View>
      ) : null}
    </View>
  );
};

const FormBody = ({ Datatable }: SelectFamilyDatatableProps) => {
  const { formType } = useMultiStepStudentForm();

  return match(formType)
    .with("CreateOrExisting", () => <CreateOrExistingForm />)
    .with("CreateFamily", () => <CreateFamilyForm />)
    .with("ExistingFamily", () => <ExistingFamilyForm Datatable={Datatable} />)
    .with("CreateStudent", () => <CreateStudentForm />)
    .exhaustive();
};

interface MultiStepStudentFormProps extends SelectFamilyDatatableProps {
  CircularIndicator: React.FC<CircularIndicatorProps>;
  onSuccess: () => void;
}

export const MultiStepStudentForm = ({
  CircularIndicator,
  Datatable,
  onSuccess,
}: MultiStepStudentFormProps) => {
  const { View } = useGenericComponents();

  return (
    <MultiStepStudentFormProvider onSuccess={onSuccess}>
      <View className={"flex flex-col space-y-8"}>
        <View>
          <FormHeader CircularIndicator={CircularIndicator} />
        </View>
        <View>
          <FormBody Datatable={Datatable} />
        </View>
      </View>
    </MultiStepStudentFormProvider>
  );
};
