import { Cell, flexRender } from "@tanstack/react-table";

import { DatatableRow } from "shared/lib";

export const DatatableDefaultCell = <ModelDto,>({
  cell,
}: {
  cell: Pick<Cell<ModelDto, unknown>, "row" | "column" | "getContext">;
}) => {
  const row = cell.row.original as DatatableRow<ModelDto>;
  const onClick = row.onClick;

  return (
    <td
      className={`px-4 py-3 ${onClick ? "cursor-pointer" : ""}`}
      onClick={() => {
        if (onClick) {
          onClick(row);
        }
      }}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};
