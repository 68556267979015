import { externalLink } from "@justraviga/classmanager-sdk";

export const FooterLinks = () => {
  return (
    <div className="flex flex-row items-center justify-center gap-x-2">
      <a href={externalLink.termsAndConditions} target="_blank">
        Terms &amp; conditions
      </a>
      <span>&bull;</span>
      <a href={externalLink.privacyPolicy} target="_blank">
        Privacy policy
      </a>
    </div>
  );
};
