import { PricingScheme, SeasonDto } from "@justraviga/classmanager-sdk";

import { useGenericComponents } from "../../GenericComponentsProvider";

export const CreatePricingTierCardHeader = ({
  season,
  classCountQuantity,
}: {
  season: SeasonDto;
  classCountQuantity: number;
}) => {
  const { Text } = useGenericComponents();
  switch (season.pricingScheme) {
    case PricingScheme.ClassCount:
      return (
        <Text className={"text-body-600 font-semibold text-white"}>
          {classCountQuantity +
            (classCountQuantity > 1 ? " classes " : " class ") +
            "per week"}
        </Text>
      );
    case PricingScheme.TotalDuration:
      return (
        <Text className={"text-body-600 font-semibold"}>Hourly billing</Text>
      );
    case PricingScheme.None:
    default:
      return null;
  }
};
