import {
  LessonAdjustmentDto,
  LessonAdjustmentReason,
  LessonDto,
  LessonStatus,
} from "@justraviga/classmanager-sdk";

import { useLessonAttendancesData } from "./useLessonAttendancesData";
import { getPlatformFunctions } from "../platformSpecific";

export const useLessonDetailsData = ({
  courseId,
  date,
  time,
}: {
  courseId: string;
  date: string;
  time: string;
}) => {
  const { useApi } = getPlatformFunctions();

  const lessonWithBasicData = {
    classId: courseId,
    startTime: time,
    date: date,
    adjustments: [] as LessonAdjustmentDto[],
  } as LessonDto;

  const { data: lessons, isLoading: isLessonsLoading } = useApi("listLesson", {
    where: {
      classId: {
        in: [courseId],
      },
    },
  });

  const { season, courseDto, records, isLoading, attendanceProgress } =
    useLessonAttendancesData({
      classId: courseId,
      date,
      time,
    });

  const lesson = lessons?.find(
    lesson => lesson.date === date && lesson.startTime === time,
  );

  const isHoliday = (lesson && lesson?.holidays.length > 0) ?? false;
  const holidayName = lesson?.holidays[0]?.name;

  const isEmpty = records.length === 0;

  const isCancelled =
    lesson?.status == LessonStatus.Inactive &&
    lesson?.adjustments.some(adjustment =>
      [
        LessonAdjustmentReason.CancelledLesson,
        LessonAdjustmentReason.CancelledClass,
      ].includes(adjustment.reason),
    );

  return {
    lesson: lesson ?? lessonWithBasicData,
    season,
    courseDto,
    records,
    attendanceProgress,
    isLoading: isLoading || isLessonsLoading,
    isEmpty,
    isCancelled,
    isHoliday,
    holidayName,
  };
};
