import { PropsWithChildren, useCallback, useEffect, useState } from "react";

import { useAuthState } from "shared/components";

import { AuthRedirectContext } from "@/modules/auth/redirect/authRedirectContext";
import {
  CatalogueAuthReturnParams,
  processRedirect,
} from "@/modules/auth/redirect/authRedirectUtils";
import { Router } from "@/routing/router";

export const AuthRedirectProvider = ({ children }: PropsWithChildren) => {
  const { isLoggedIn } = useAuthState();
  const [redirectProps, setRedirectProps] =
    useState<CatalogueAuthReturnParams>();

  const redirect = useCallback(() => {
    if (redirectProps) {
      processRedirect(redirectProps);
      setRedirectProps(undefined);
    }
  }, [redirectProps]);

  const setRedirect = useCallback((props: CatalogueAuthReturnParams) => {
    setRedirectProps(props);
  }, []);

  const getRedirectProps = useCallback(() => redirectProps, [redirectProps]);

  useEffect(() => {
    if (redirectProps) {
      Router.push(redirectProps.redirectTarget || "CatalogueLogin", {
        slug: redirectProps.slug,
        courseId: redirectProps.courseId ?? "",
      });
    }
  }, [redirectProps]);

  useEffect(() => {
    if (isLoggedIn) {
      redirect();
    }
  }, [isLoggedIn, redirect]);

  return (
    <AuthRedirectContext.Provider
      value={{
        getRedirectProps,
        setRedirect,
        redirect,
      }}>
      {children}
    </AuthRedirectContext.Provider>
  );
};
