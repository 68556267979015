import { LessonDto, TrialDto } from "@justraviga/classmanager-sdk";

import { getFullName } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import { TrialsRowDto } from "../datatables/useTrialsDatatable";
import {
  makeTrialCreateFormDefinition,
  makeTrialCreateRequest,
  makeTrialUpdateFormDefinition,
  makeTrialUpdateRequest,
} from "../formDefinitions/trialForm";
import { useGenericComponents } from "../GenericComponentsProvider";

type Dto = TrialDto;

export const useTrialActions = () => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const defaultActions = usePlatformEntityActions<TrialDto>({
    entity: "trial",
  });
  return {
    deleteOne: (entity: Dto) =>
      defaultActions.deleteOne(() =>
        api.trials.deleteTrial({ id: entity.id! }),
      ),
    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () => api.trials.deleteManyTrial({ deleteManyTrialRequest: { ids } }),
        ids.length,
      ),
    archive: (entity: TrialsRowDto) =>
      defaultActions.archive(
        () => api.trials.archiveTrial({ id: entity.id! }),
        {
          title: "Mark as not continuing",
          description: `Are you sure you want to mark ${getFullName(entity.student)} as not continuing this class? The trial record will be archived.`,
          confirmButtonText: "Confirm",
        },
      ),
    archiveMany: (ids: string[]) =>
      defaultActions.archiveMany(
        () => api.trials.archiveTrials({ archiveTrialsRequest: { ids } }),
        ids.length,
      ),
    unarchive: (entity: Dto) =>
      defaultActions.unarchive(() =>
        api.trials.unarchiveTrial({ id: entity.id }),
      ),
    unarchiveMany: (ids: string[]) =>
      defaultActions.unarchiveMany(() =>
        api.trials.unarchiveTrials({ unarchiveTrialsRequest: { ids } }),
      ),
    showCreateForm: ({
      classId,
      studentIds,
      lesson,
    }: {
      classId?: string;
      studentIds?: string[];
      lesson?: LessonDto;
    } = {}) => {
      const date = lesson?.date ?? null;
      const time = lesson?.startTime ?? null;
      const courseId = classId ?? null;

      return defaultActions.showCreateForm({
        title: "Add trial",
        form: (
          <GenericForm
            apiRequest={makeTrialCreateRequest({ api, studentIds })}
            defaultValues={{
              lessonDateTime: [courseId, date, time],
            }}
            formDefinitionHook={makeTrialCreateFormDefinition({
              studentIds,
            })}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      });
    },
    showUpdateForm: (trial: TrialDto) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeTrialUpdateRequest({ api, trial })}
            defaultValues={{
              ...trial,
              lessonDateTime: [trial.classId, trial.trialAt, trial.time],
            }}
            formDefinitionHook={makeTrialUpdateFormDefinition()}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),
  };
};
