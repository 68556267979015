import { ReferenceLine } from "recharts";

import { colors } from "shared/lib";

interface ReferenceLinesProps {
  yAxisMin: number;
  yAxisMax: number;
  yAxisDivisions: number;
}

export const referenceLines = ({
  yAxisMin,
  yAxisMax,
  yAxisDivisions,
}: ReferenceLinesProps) => {
  const referenceLines = generateReferenceLines(
    yAxisMin,
    yAxisMax,
    yAxisDivisions,
  );

  return referenceLines.map((line, index) => (
    <ReferenceLine key={index} y={line} stroke={colors.grey["300"]} />
  ));
};

const generateReferenceLines = (
  yAxisMin: number,
  yAxisMax: number,
  yAxisDivisions: number,
) => {
  const step = (yAxisMax - yAxisMin) / yAxisDivisions;
  return Array.from(
    { length: yAxisDivisions + 1 },
    (_, i) => yAxisMin + i * step,
  );
};
