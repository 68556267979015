import { CodeProps, DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { List, ListItem, ListProps } from "@/modules/common/ui/List";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

const options: Array<Option<ListProps>> = [
  {
    title: "Default",
    props: [
      {
        separator: <HorizontalSeparator spacing={2} />,
        items: [
          <ListItem mainElement={<p>Text Only</p>} />,
          <ListItem
            mainElement={
              <div className={"flex flex-col space-y-1"}>
                <p className={"text-body-400 text-grey-900"}>
                  Leonardo Passaquindici Arcand
                </p>
                <p className={"text-label-400 text-grey-600"}>Grandfather</p>
                <p className={"text-label-400 text-grey-600"}>0723728372</p>
                <p className={"text-label-400 text-grey-600"}>
                  leonardo.p.a@gmail.com
                </p>
              </div>
            }
          />,
          <ListItem
            mainElement={
              <div className={"flex flex-col space-y-1"}>
                <p className={"text-body-400 text-grey-900"}>
                  Catherine Passaquindici Arcand
                </p>
                <p>0723728372</p>
              </div>
            }
          />,
          <ListItem
            leftElement={
              <p className={"text-body-400 text-grey-900"}>£90.00</p>
            }
            rightElement={<p>23 May 2023, 12:00</p>}
          />,
        ],
      },
    ],
  },
];

export const ListPage = () => {
  const itemsCode = (props: ListProps[]): CodeProps<ListProps>[] => {
    return props.map(function (prop: ListProps) {
      return {
        ...prop,
        separator: `<HorizontalSeparator spacing={2} />`,
        items: `[
            <ListItem mainElement={<p>Text Only</p>} />,
            <ListItem
              mainElement={
                <div className={"flex flex-col space-y-1"}>
                  <p className={"text-grey-900 text-body-400"}>
                    Leonardo Passaquindici Arcand
                  </p>
                  <p className={"text-grey-600 text-label-400"}>Grandfather</p>
                  <p className={"text-grey-600 text-label-400"}>0723728372</p>
                  <p className={"text-grey-600 text-label-400"}>
                    leonardo.p.a@gmail.com
                  </p>
                </div>
              }
            />,
            <ListItem
              mainElement={
                <div className={"flex flex-col space-y-1"}>
                  <p className={"text-grey-900 text-body-400"}>
                    Catherine Passaquindici Arcand
                  </p>
                  <p>0723728372</p>
                </div>
              }
            />,
            <ListItem
              leftElement={
                <p className={"text-grey-900 text-body-400"}>£90.00</p>
              }
              rightElement={<p>23 May 2023, 12:00</p>}
            />,
          ]`,
      };
    });
  };
  return (
    <>
      <DocWrapper
        title="List"
        component={List}
        options={options}
        transformProps={itemsCode}
      />
    </>
  );
};
