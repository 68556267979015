import { useIconButtonVariations } from "shared/components";

import { DocWrapper } from "@/modules/admin/docs/DocWrapper";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const IconButtonPage = () => {
  const variations = useIconButtonVariations();
  return (
    <>
      <DocWrapper
        title="Icon Buttons"
        component={IconButton}
        options={variations}
      />
    </>
  );
};
