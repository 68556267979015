import { useNav } from "@/modules/common/nav/contexts/useNav";
import { UserAccountLinks } from "@/modules/common/nav/UserAccountLinks";
import { GlobalSearchDialog } from "@/modules/common/search/GlobalSearchDialog";
import { IconButton } from "@/modules/common/ui/button/IconButton";

interface GlobalHeaderProps {
  hasSideNav: boolean;
}

export const GlobalHeader = ({ hasSideNav = false }: GlobalHeaderProps) => {
  const { show: showNav, hide: hideNav, isShowing: isNavShowing } = useNav();

  return (
    <div
      data-testid="global-header-container"
      className="flex min-h-[72px] items-center justify-between px-5 py-3 md:px-8">
      {hasSideNav && (
        <div className="-ml-2 xl:hidden">
          <IconButton
            onClick={() => (isNavShowing ? hideNav() : showNav())}
            icon="menuOutline"
            size={"2xl"}
            variant={"standard"}
          />
        </div>
      )}
      <GlobalSearchDialog />
      <UserAccountLinks />
    </div>
  );
};
