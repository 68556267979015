import { FC, ReactNode } from "react";

import { TaxRateDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { colors } from "../../colors";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { BaseCardProps, ContentPlaceholderProps } from "../interfaces";

type Dto = TaxRateDto;
type RowDto = Dto;
type FilterForm = object;

export const taxRatesIntroCardProps: Omit<
  BaseCardProps<unknown>,
  "headerActions" | "padding"
> = {
  title: "About tax rates",
  titleSize: 16,
  titleWeight: 600,
  titleColor: "grey-900",
  description:
    "You can configure the tax rates you charge for everything you sell. Set a default rate to save time when setting up seasons & classes.",
  descriptionColor: "grey-900",
  gap: 1,
  fillColor: colors.grey[100],
};

export const taxRatesPlaceholderProps: ContentPlaceholderProps = {
  icon: "helpCircleOutline",
  title: "Tax not enabled",
  description:
    "We will ask you some more questions to ensure your tax is processed correctly.",
};

export const useTaxRatesDatatable = ({
  Datatable,
  api,
  showCreateForm,
  showUpdateForm,
  makeDefault,
  deleteOne,
  renderLabel,
  renderMobileColumn,
}: {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, RowDto> }>;
  api: Api;
  showCreateForm: () => void;
  showUpdateForm: (entity: RowDto) => void;
  makeDefault: (entity: RowDto) => void;
  deleteOne: (entity: RowDto) => Promise<void>;
  renderLabel: (entity: RowDto) => ReactNode;
  renderMobileColumn: (entity: RowDto) => ReactNode;
}): { datatable: ReactNode } => {
  const fetchData = async (query: DatatableQueryProps) => {
    return api.taxRates.listTaxRate({
      ...query.toRequest(),
      selectAll: true,
    });
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: "taxRate",
    title: "Tax rates",
    titleSize: "sm",
    createAction: showCreateForm,
    rowActions: {
      title: item => item.label,
      edit: showUpdateForm,
      delete: deleteOne,
      additionalRowActions: () => [
        {
          icon: "swapHorizontalOutline",
          label: "Make default",
          onClick: makeDefault,
          enabled: item => !item.isDefault,
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "No tax rates yet",
        description: "",
      },
    },
    hasPagination: false,
    hasSearch: false,
    showTotalRecords: false,
    placeholdersCount: 4,
    fetchData,
    columns: [
      {
        label: "Label",
        placeholder: "text",
        value: row => {
          return {
            type: "custom",
            children: renderLabel(row.item),
          };
        },
      },
      {
        label: "Amount",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: row.item.rate + "%",
        }),
      },
    ],
    mobileColumn: {
      children: row => renderMobileColumn(row.item),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
