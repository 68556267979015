import type { CompanySettingsGroupDto } from "@justraviga/classmanager-sdk";

import { useAuthState } from "./AuthStateProvider";

export const useSettings = <T extends keyof CompanySettingsGroupDto>(
  group: T,
): CompanySettingsGroupDto[T] => {
  const { account } = useAuthState();

  if (!account) {
    throw new Error(
      "useSettings must be used within an authenticated context.",
    );
  }
  if (!account.company) {
    throw new Error(
      "useSettings must be used within the context of a company.",
    );
  }

  return account.company.settings[group];
};
