import { HTTPQuery } from "@justraviga/classmanager-sdk";
import * as Qs from "qs";

/**
 * Serialise an object into a query string, using RFC 3986 encoding.
 */
export const stringifyQueryParams = (params: HTTPQuery): string => {
  /**
   * Set up how we want our query string to be formatted
   * This uses a third party library as it gives us much more control over
   * how we want keys and values presented to the API.
   */
  return Qs.stringify(params, {
    arrayFormat: "indices",
  });
};
