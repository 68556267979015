import React, { ReactElement, ReactNode } from "react";

import { WebActionMenuItemProps } from "shared/lib";

import { Sheet } from "@/modules/common/overlays/dialog/Sheet";
import { MobileActionMenuItems } from "@/modules/common/ui/actionMenu/mobile/MobileActionMenuItems";

export const MobileActionMenuBottomSheet = ({
  header,
  items,
  trigger,
}: {
  header?: ReactNode;
  items: WebActionMenuItemProps[];
  trigger: React.ReactNode;
}) => {
  const [bottomSheetOpen, setBottomSheetOpen] = React.useState(false);

  const mobileTrigger = React.cloneElement(trigger as ReactElement, {
    onClick: (event: Event) => {
      event.stopPropagation();
      setBottomSheetOpen(true);
    },
  });

  return (
    <>
      {mobileTrigger}
      <Sheet
        isModal={false}
        open={bottomSheetOpen}
        hasBackdropOnOpen={true}
        containerPadding={3}
        onInteractOutside={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onOpenChange={(open: boolean) => {
          setBottomSheetOpen(open);
        }}
        onCloseIconClick={event => {
          event.preventDefault();
          event.stopPropagation();
          setBottomSheetOpen(false);
        }}
        zIndex={1}
        side={"bottom"}
        padding={"px-5 pt-5 pb-1"}
        title={header}
        content={
          <div className={`bg-white p-1`}>
            <MobileActionMenuItems
              items={items}
              onActionClick={() => setBottomSheetOpen(false)}
            />
          </div>
        }
      />
    </>
  );
};
