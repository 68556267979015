import { Icon } from "@/modules/common/ui/icon/Icon";

export const NoContent = () => {
  return (
    <div className={"flex flex-row items-center space-x-2"}>
      <Icon name={"helpCircleOutline"} />
      <span>Nothing here!</span>
    </div>
  );
};
