import { PropsWithChildren, ReactNode } from "react";

import { Button } from "@/modules/common/ui/button/Button";

interface LoginFormFooterProps extends PropsWithChildren {
  message: ReactNode;
  onClick: () => void;
  linkText: string;
}

export const SwitchAuthType = ({
  message,
  onClick,
  linkText,
  children,
}: LoginFormFooterProps) => (
  <div className="flex flex-col items-center gap-8">
    <div className="flex items-center space-x-2">
      <span className="text-grey-500">{message}</span>
      <Button variant="link" onClick={onClick}>
        {linkText}
      </Button>
    </div>
    {children}
  </div>
);
