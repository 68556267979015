import { ReactNode } from "react";

import { IconName } from "../../availableIcons";
import { useBreakpoint } from "../../breakpoints";
import { useSharedAccountActions } from "../actions/useSharedAccountActions";
import { useAuthState } from "../AuthStateProvider";
import { useGenericComponents } from "../GenericComponentsProvider";

export const AccountActions = () => {
  const { View } = useGenericComponents();
  return (
    <View className={"flex flex-col md:flex-row gap-8"}>
      <View className="flex-1 md:basis-2/3">
        <ChangeEmailCard />
      </View>
      <View className="flex-1 md:basis-1/3">
        <ChangePasswordCard />
      </View>
    </View>
  );
};

const ChangeEmailCard = () => {
  const { Text } = useGenericComponents();
  const { user } = useAuthState();
  const { showEmailUpdateForm } = useSharedAccountActions();

  return (
    <UserPropertyCard
      title="Email"
      icon="mailOutline"
      action={{
        text: "Change email",
        onClick: showEmailUpdateForm,
      }}
      content={<Text className={"text-body-400"}>{user?.email}</Text>}
    />
  );
};

const ChangePasswordCard = () => {
  const { showPasswordUpdateForm } = useSharedAccountActions();
  const { Text } = useGenericComponents();
  return (
    <UserPropertyCard
      title={"Password"}
      icon={"shieldCheckmarkOutline"}
      action={{
        text: "Reset",
        onClick: showPasswordUpdateForm,
      }}
      content={<Text className={"text-body-400"}>{"•".repeat(20)}</Text>}
    />
  );
};

const UserPropertyCard = ({
  title,
  icon,
  action,
  content,
}: {
  title: string;
  icon: IconName;
  action: { onClick(): void; text: string };
  content: ReactNode;
}) => {
  const { BaseCard, View } = useGenericComponents();
  const breakpoint = useBreakpoint();
  return (
    <BaseCard
      title={title}
      icon={icon}
      iconPosition="title"
      footerAction={breakpoint.md ? undefined : action}
      bodySlot={<View className={"py-2"}>{content}</View>}
      headerAction={breakpoint.md ? action : undefined}
    />
  );
};
