import { RoomDto } from "@justraviga/classmanager-sdk";

import { DetailCard } from "@/modules/company/common/DetailCard";
import { RoomCard } from "@/modules/company/locations/detail/RoomCard";
import { RoomsCardCreateNewButton } from "@/modules/company/locations/detail/RoomsCardCreateNewButton";

type RoomsCardProps = {
  showPlaceholder: boolean;
  locationId: string;
  rooms: RoomDto[];
};

export const RoomsCard = ({
  showPlaceholder,
  locationId,
  rooms,
}: RoomsCardProps) => (
  <DetailCard
    title={"Rooms"}
    isFoldable={true}
    showPlaceholder={showPlaceholder}
    headerButton={<RoomsCardCreateNewButton locationId={locationId} />}>
    <div className={"space-y-4 p-2"}>
      {rooms.map((room: RoomDto, key) => (
        <RoomCard key={key} room={room} />
      ))}
    </div>
  </DetailCard>
);
