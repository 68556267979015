import { LoginHeader } from "@/modules/auth/common/LoginHeader";
import { Router } from "@/routing/router";

interface LinkedHeaderProps {
  linkId: string;
  companyLogo?: string | null;
}

export const LinkedHeader = ({ companyLogo, linkId }: LinkedHeaderProps) => {
  const handleBackButton = () => {
    Router.replace("Invitation", { id: linkId });
  };

  return (
    <LoginHeader
      companyLogo={companyLogo}
      handleBackButton={handleBackButton}
    />
  );
};
