import {
  BaseDatePicker,
  BaseDatePickerProps,
} from "@/modules/common/form/datePicker/BaseDatePicker";
import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";

export interface SingleDatePickerProps extends BaseDatePickerProps {
  label?: string;
  description?: string;
  required?: boolean;
}

export const SingleDatePicker = ({
  required,
  label,
  description,
  ...baseProps
}: SingleDatePickerProps) => {
  return (
    <Label text={label} required={required}>
      <BaseDatePicker {...baseProps} />
      <InputDescription description={description} />
    </Label>
  );
};
