import { FilePurpose } from "@justraviga/classmanager-sdk";

import { saveFile } from "shared/lib";

import { api } from "@/lib/api/apiClient";

export async function uploadFile(file: File) {
  // Get direct upload link from SDK
  const { url, path } = await api.files.getDirectUploadLink({
    getDirectUploadLinkRequest: {
      filename: file.name,
    },
  });

  // Upload the file to the temporary URL
  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type,
    },
  });

  if (response.status !== 200) {
    // @TODO Sentry
    throw new Error("Failed to upload file");
  }

  // Return path so calling component can show a preview
  return path;
}

export const uploadAndSaveFile = async (
  entityId: string,
  purpose: FilePurpose,
  file: File,
) => {
  const path = await uploadFile(file);
  return saveFile(entityId, purpose, path);
};
