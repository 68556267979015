import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
} from "@justraviga/classmanager-sdk";

import {
  CustomerCheckoutSummaryPage as SharedCustomerCheckoutSummaryPage,
  useBasket,
  useCatalogueContext,
} from "shared/components";

import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { Banner } from "@/modules/common/ui/Banner";
import { Button } from "@/modules/common/ui/button/Button";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

export const CustomerCheckoutSummaryPage = () => {
  const { setSuccessfulTransaction, clear: clearBasket } = useBasket();
  const onPaymentSuccess = (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => {
    setSuccessfulTransaction(result!, checkoutOperation!);
    clearBasket();
    Router.push("CustomerCheckoutSuccess", {
      slug: company.slug,
    });
  };
  const { showCheckoutPaymentForm } = usePaymentMethods(onPaymentSuccess);
  const { company } = useCatalogueContext();

  return (
    <CustomerLayout
      hideNav
      showBackButton
      backButtonText={"Back"}
      backButtonOnClick={() => {
        Router.push("MemberBasket", { slug: company.slug });
      }}>
      <SharedCustomerCheckoutSummaryPage
        Banner={Banner}
        Button={Button}
        HorizontalSeparator={HorizontalSeparator}
        openPaymentForm={async (...args) => showCheckoutPaymentForm(...args)}
        onSuccess={onPaymentSuccess}
      />
    </CustomerLayout>
  );
};
