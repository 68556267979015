import { PropsWithChildren } from "react";

import { ChartTitle } from "./ChartTitle";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const ChartWidget = ({
  children,
  title,
  chartTitle,
  lastUpdated,
  isLoading,
}: PropsWithChildren<{
  title: string;
  chartTitle: string;
  lastUpdated: Date;
  isLoading: boolean;
}>) => {
  const { BaseCard, Text, View } = useGenericComponents();

  if (isLoading) {
    return (
      <BaseCard
        collapsible={true}
        title={title}
        bodySlot={<Text>Loading...</Text>}
      />
    );
  }

  return (
    <BaseCard
      collapsible={true}
      title={title}
      bodySlot={
        <View className={"flex-col flex-1 space-y-4"}>
          <View>
            <ChartTitle title={chartTitle} lastUpdated={lastUpdated} />
          </View>
          <View className="h-96 w-full">{children}</View>
        </View>
      }
    />
  );
};
