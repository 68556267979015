import { ContentPlaceholder } from "shared/components";
import { ContentPlaceholderProps } from "shared/lib";

interface PageErrorPlaceholderProps
  extends Pick<ContentPlaceholderProps, "action"> {}

export const PageErrorPlaceholder = ({ action }: PageErrorPlaceholderProps) => (
  <ContentPlaceholder
    icon="helpCircleOutline"
    title={"This page could not be loaded"}
    description={
      "This page is currently unavailable. Try refreshing the window."
    }
    action={action}
  />
);
