import { useEffect, useState } from "react";

import {
  AccountPermission,
  InvitationStatus,
  LinkDto,
  UserDto,
} from "@justraviga/classmanager-sdk";

import {
  BannerIcon,
  BannerVariant,
  colors,
  getFullName,
  MobileActionMenuItemProps,
  MobileActionMenuProps,
  WebActionMenuItemProps,
  WebActionMenuProps,
} from "shared/lib";

import { useGenericComponents } from "../../../GenericComponentsProvider";

const InviteBanner = ({
  title,
  name,
  email,
  variant,
  actions,
  icon,
  ActionMenu,
}: {
  title: string;
  name: string;
  email: string;
  variant?: BannerVariant;
  actions?: WebActionMenuItemProps[] | MobileActionMenuItemProps[];
  icon?: BannerIcon;
  ActionMenu: React.FC<{
    items: WebActionMenuItemProps[] | MobileActionMenuItemProps[];
  }>;
}) => {
  const { Banner, View, Text } = useGenericComponents();
  return (
    <Banner
      icon={icon}
      content={
        <View className={"flex flex-col space-y-1"}>
          <Text className={"text-body-600 font-semibold text-grey-900"}>
            {title}
          </Text>
          <Text className={"text-label-400 text-grey-900"}>{name}</Text>
          <Text className={"text-label-400 text-grey-900"}>{email}</Text>
        </View>
      }
      variant={variant}
      actionSlot={actions && <ActionMenu items={actions} />}
    />
  );
};

export const InvitationWidget = ({
  invitationStatus,
  getLinkData,
  getUserData,
  sendInvite,
  cancelInvite,
  revokeAccess,
  accountPermission,
  ActionMenu,
}: {
  invitationStatus: InvitationStatus;
  getLinkData(): Promise<LinkDto>;
  getUserData(): Promise<UserDto>;
  sendInvite(): Promise<LinkDto>;
  cancelInvite(): void;
  revokeAccess(): void;
  accountPermission: AccountPermission;
  ActionMenu: React.FC<WebActionMenuProps | MobileActionMenuProps>;
}) => {
  const { BaseCard, ProtectedOverlay, View } = useGenericComponents();

  const [linkData, setLinkData] = useState<LinkDto | null>(null);
  const [userData, setUserData] = useState<UserDto | null>(null);

  useEffect(() => {
    if (invitationStatus === "invited") {
      getLinkData().then(setLinkData);
      setUserData(null);
    }
    if (invitationStatus === "accepted") {
      getUserData().then(setUserData);
      setLinkData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationStatus]);

  if (invitationStatus === "not_invited") {
    return (
      <ProtectedOverlay permission={accountPermission}>
        <View className={"w-full"}>
          <BaseCard
            title={"Invite to Class Manager"}
            titleColor={"grey-900"}
            titleSize={16}
            titleWeight={600}
            iconPosition={"left-framed"}
            icon={"sendSharp"}
            onClick={sendInvite}
          />
        </View>
      </ProtectedOverlay>
    );
  }

  if (invitationStatus === "invited") {
    if (!linkData) {
      return null;
    }

    return (
      <InviteBanner
        title={"Invitation Pending"}
        icon={{ name: "time", color: colors.grey["900"] }}
        name={getFullName(linkData)}
        email={linkData.email}
        actions={[
          {
            title: "Resend invitation email",
            onClick: () => sendInvite().then(setLinkData),
            permission: accountPermission,
          },
          {
            title: "Cancel invitation",
            onClick: cancelInvite,
            permission: accountPermission,
          },
        ]}
        ActionMenu={ActionMenu}
      />
    );
  }

  if (invitationStatus === "accepted") {
    if (!userData) {
      return null;
    }

    return (
      <InviteBanner
        title={"Account Activated"}
        name={getFullName({
          firstname: userData.firstname!,
          lastname: userData.lastname,
        })}
        email={userData.email!}
        actions={[
          {
            title: "Remove account access",
            onClick: () => revokeAccess,
          },
        ]}
        variant={"success"}
        ActionMenu={ActionMenu}
      />
    );
  }
};
