import { Children, PropsWithChildren } from "react";

import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";

export const FormFooter = ({ children }: PropsWithChildren) => {
  const { shouldShowFooter } = useFormActions();
  const { View } = useGenericComponents();

  return (
    shouldShowFooter && (
      <View
        className={
          "flex flex-col-reverse justify-between gap-4 border-t-[1px] border-t-grey-300 px-4 py-5 shadow md:flex-row"
        }>
        {/* Yes, this empty view is intentional, as it means when there is a single button, it sits on the right */}
        {Children.count(children) === 1 && <View />}
        {children}
      </View>
    )
  );
};
