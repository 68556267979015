import { CardType, getSvgForCardType } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

interface CardTypeIconProps {
  cardType: CardType;
}

export const CardTypeIcon = ({ cardType }: CardTypeIconProps) => (
  <div className="overflow-hidden rounded-sm">
    <Icon
      name={getSvgForCardType(cardType)}
      label={`${cardType} icon`}
      size={32}
    />
  </div>
);
