import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  HorizontalSeparator,
  HorizontalSeparatorProps,
} from "@/modules/common/ui/Separator";

const options: Array<Option<HorizontalSeparatorProps>> = [
  {
    title: "Spacing",
    props: [
      {
        spacing: 0,
      },
      {
        spacing: 1,
      },
      {
        spacing: 2,
      },
      {
        spacing: 4,
      },
    ],
  },
  {
    title: "With Text",
    props: [
      {
        spacing: 0,
        text: "OR",
      },
      {
        spacing: 2,
        text: "OR",
      },
    ],
  },
];

export const HorizontalSeparatorPage = () => {
  return (
    <DocWrapper
      title={"Horizontal Separator"}
      component={HorizontalSeparator}
      options={options}
    />
  );
};
