import { useEffect } from "react";

import { useFormActions } from "shared/components";

import { FormFooter } from "@/modules/common/form/FormFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";

export const CreateFooter = () => {
  const {
    isLoading,
    submit,
    setShouldCloseOnSuccess,
    allowCreateAdditional,
    createButtonText,
    setCreateButtonText,
    isSubmitDisabled,
  } = useFormActions();

  const { closeSheet } = useSheet();

  useEffect(() => {
    return () => {
      setCreateButtonText("Create");
    };
  }, [setCreateButtonText]);

  return (
    <FormFooter>
      {allowCreateAdditional ? (
        <Button
          text={"Create and add another"}
          variant="secondary"
          disabled={isLoading || isSubmitDisabled}
          loading={isLoading}
          onClick={() => {
            setShouldCloseOnSuccess(false);
            submit();
          }}
        />
      ) : (
        <Button
          text={"Cancel"}
          variant="secondary"
          disabled={isLoading}
          onClick={() => {
            closeSheet();
          }}
        />
      )}
      <Button
        variant={"brand"}
        text={createButtonText}
        disabled={isLoading || isSubmitDisabled}
        loading={isLoading}
        onClick={() => {
          setShouldCloseOnSuccess(true);
          submit();
        }}
      />
    </FormFooter>
  );
};
