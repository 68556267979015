import {
  CourseSearchValues,
  courseSearchValuesToQueryParams,
  CourseSearchBar as SharedCourseSearchBar,
  useCatalogueContext,
} from "shared/components";

import { SearchInput } from "@/modules/common/form/SearchInput";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { useCourseFilterForm } from "@/modules/public/catalogue/useCourseFilterForm";
import { Router } from "@/routing/router";

export const CourseSearchBar = ({ mode }: { mode: "enrol" | "trial" }) => {
  const { openFilterSheet } = useCourseFilterForm(mode);
  const { company } = useCatalogueContext();

  const goToResults = (searchValues: CourseSearchValues, replace: boolean) => {
    if (replace) {
      Router.replace("Courses", {
        slug: company.slug,
        ...courseSearchValuesToQueryParams(searchValues),
        mode,
      });
    } else {
      Router.push("Courses", {
        slug: company.slug,
        ...courseSearchValuesToQueryParams(searchValues),
        mode,
      });
    }
  };

  return (
    <SharedCourseSearchBar
      Button={Button}
      IconButton={IconButton}
      SearchInput={SearchInput}
      openFilter={openFilterSheet}
      goToResults={goToResults}
    />
  );
};
