import { createContext, ReactNode, useEffect, useState } from "react";

import { useAuthState } from "shared/components";

import { Sheet, SheetProps } from "@/modules/common/overlays/dialog/Sheet";

export const SheetContext = createContext<SheetContextValue>(
  undefined as unknown as SheetContextValue,
);

export interface SheetContextValue {
  openSheet: (props: SheetProps) => void;
  closeSheet: () => void;
  setTitle: (title: string) => void;
}

export const SheetProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [sheetProps, setSheetProps] = useState<SheetProps | undefined>(
    undefined,
  );

  const { user } = useAuthState();
  useEffect(() => {
    if (!user) {
      closeSheet();
    }
  }, [user]);

  const openSheet = (props: SheetProps) => {
    setSheetProps(props);
    setOpen(true);
  };

  const closeSheet = () => {
    setOpen(false);
  };

  const setTitle = (title: string) => {
    setSheetProps(prev => ({ ...prev, title }));
  };

  return (
    <SheetContext.Provider value={{ openSheet, closeSheet, setTitle }}>
      {children}
      <Sheet
        open={open}
        onOpenChange={setOpen}
        onCloseIconClick={closeSheet}
        {...sheetProps}
      />
    </SheetContext.Provider>
  );
};
