import { useState } from "react";

import {
  CourseFilterForm,
  CourseSearchValues,
  courseSearchValuesToQueryParams,
  makeCourseSearchValues,
  useCatalogueContext,
  useCourseSearch,
  useFormActions,
} from "shared/components";

import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { FilterFormFooter } from "@/modules/public/catalogue/FilterFormFooter";
import { Router } from "@/routing/router";

export const useCourseFilterForm = (mode: "enrol" | "trial") => {
  const { openSheet, closeSheet } = useSheet();
  const { setSubmit } = useFormActions();
  const { searchValues } = useCourseSearch();
  const { company } = useCatalogueContext();

  const resetSearchValues = () => {
    Router.push("Courses", {
      slug: company.slug,
      ...courseSearchValuesToQueryParams(makeCourseSearchValues()),
      mode,
    });
  };

  const [values, setValues] = useState<CourseSearchValues>(searchValues);

  setSubmit(() => {
    closeSheet();
    Router.push("Courses", {
      slug: company.slug,
      ...courseSearchValuesToQueryParams(values),
      mode,
    });
  });

  const openFilterSheet = () => {
    openSheet({
      title: "Filters",
      side: "right",
      content: (
        <CourseFilterForm
          defaultValues={searchValues}
          onChange={v => setValues({ ...v })}
        />
      ),
      footer: <FilterFormFooter onClear={resetSearchValues} />,
    });
  };

  return {
    openFilterSheet,
    closeFilterSheet: closeSheet,
  };
};
