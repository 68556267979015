import { CustomFieldResponseDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import {
  getCustomFieldResponsesDefaultValues,
  makeCustomFieldResponsesFormDefinition,
  makeCustomFieldResponsesRequest,
} from "../formDefinitions/customFieldResponsesForm";
import { useGenericComponents } from "../GenericComponentsProvider";

type Model = CustomFieldResponseDto[];

export type CustomFieldResponsesActions = {
  showUpdateForm: (
    entityId: string,
    customFieldResponses: CustomFieldResponseDto[],
  ) => void;
};

export const useCustomFieldResponsesActions =
  (): CustomFieldResponsesActions => {
    const { GenericForm } = useGenericComponents();
    const { api, usePlatformEntityActions } = getPlatformFunctions();

    const defaultActions = usePlatformEntityActions<Model>({
      entity: "customField",
    });

    return {
      showUpdateForm: (
        entityId: string,
        customFieldResponses: CustomFieldResponseDto[],
      ) =>
        defaultActions.showUpdateForm({
          form: (
            <GenericForm
              apiRequest={makeCustomFieldResponsesRequest({ api, entityId })}
              defaultValues={getCustomFieldResponsesDefaultValues(
                customFieldResponses,
              )}
              formDefinitionHook={makeCustomFieldResponsesFormDefinition({
                customFieldResponses,
              })}
              onSuccess={defaultActions.onUpdateSuccess}
            />
          ),
          usePlural: true,
        }),
    };
  };
