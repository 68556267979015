import { FC, ReactNode } from "react";

import {
  HolidayDto,
  ListHolidayWhereParameter,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { presentDateRange } from "../../dateUtils";

type Dto = HolidayDto;
type FilterForm = object;

export const useHolidayDatatable = ({
  Datatable,
  api,
  deleteMany,
  deleteOne,
  showCreateForm,
  showUpdateForm,
}: {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, FilterForm> }>;
  api: Api;
  deleteMany: (ids: string[]) => Promise<void>;
  deleteOne: (item: Dto) => Promise<void>;
  showCreateForm: () => void;
  showUpdateForm: (item: Dto) => void;
}): { datatable: ReactNode } => {
  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    request.where = (request.where ?? {}) as ListHolidayWhereParameter;
    // Sort by start date by default
    if (Object.keys(request.sort ?? {}).length === 0) {
      request.sort = { startAt: "desc" };
    }
    return api.holidays.listHoliday(request);
  };

  const config: DatatableConfiguration<Dto, object> = {
    id: "holiday",
    title: "Holidays",
    hasPagination: true,
    placeholdersCount: 5,
    createAction: showCreateForm,
    rowActions: {
      delete: deleteOne,
      edit: showUpdateForm,
    },
    groupActions: {
      deleteMany: deleteMany,
    },
    fetchData,
    columns: [
      {
        label: "Name",
        placeholder: "tile",
        value: row => {
          return {
            type: "tile",
            title: row.item.name,
            hasImage: false,
          };
        },
        sortableField: "name",
      },
      {
        label: "Date(s)",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: presentDateRange(row.item.startAt, row.item.endAt),
        }),
        sortableField: "startAt",
      },
    ],
    mobileColumn: {
      hasImage: false,
      title: row => row.item.name,
      subtitle: row => presentDateRange(row.item.startAt, row.item.endAt),
    },
    contentPlaceholders: {
      noContent: {
        icon: "airplane",
        title: "No holidays yet",
        description:
          "Specify the dates on which your classes won't take place by setting holidays.",
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
