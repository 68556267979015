import { Api } from "../../api";
import { FormBuilder } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

export interface SignAgreementsFormData {
  signatoryName: string;
}

type CreateSchema = SignAgreementsFormData;

const definition = new FormDefinitionBuilder<CreateSchema>()
  .text("signatoryName", {
    label: "Full name",
    required: true,
  })
  .getDefinition();

export const useSignPendingAgreementsForm = (
  builder: FormBuilder,
  onSubmit: (data: CreateSchema) => void,
) => {
  return builder(definition, onSubmit, { signatoryName: "" });
};

interface MakeSignPendingAgreementsRequest extends SignAgreementsFormData {
  api: Api;
  familyId: string;
  waiverIds: string[];
}

export const makeSignPendingAgreementsRequest = ({
  api,
  waiverIds,
  familyId,
  signatoryName,
}: MakeSignPendingAgreementsRequest) =>
  api.agreements.createAgreement({
    createAgreementRequestInner: waiverIds.map(waiverId => ({
      familyId,
      waiverId,
      signatoryName,
    })),
  });
