import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  FieldValues,
  DefaultValues as ReactHookFormDefaultValues,
  useForm,
} from "react-hook-form";

import { FormDefinition } from "./formBuilderTypes";
import { FieldDefinition, FormValues } from "./formDefinitionBuilder";
import { generateFormStructure } from "../formBuilderUtils";

export const getContentBlockDefinitionsForRow = <Schema extends object>(
  definition: FormDefinition<Schema>,
  fields: string[],
) => {
  const definitions = definition.contentBlocks.filter(b =>
    fields.includes(String(b.closestField)),
  );

  return {
    beforeContentBlockDefinitions: definitions.filter(
      b => b.position === "before",
    ),
    afterContentBlockDefinitions: definitions.filter(
      b => b.position === "after",
    ),
  };
};

export const shouldShowField = <
  Schema extends FieldValues,
  Fields extends Record<keyof Schema, FieldDefinition>,
>(
  field: string,
  formDefinition: FormDefinition<Schema>,
  watchedValues: FormValues<Fields>,
) => {
  const condition = formDefinition.conditions.get(field);
  if (!condition) {
    return true;
  }
  return condition.handler(watchedValues);
};

export const useFormBuilderValues = <Schema extends object>({
  definition,
  defaultValues,
}: {
  definition: FormDefinition<Schema>;
  defaultValues: Partial<Schema>;
}) => {
  type Fields = Record<keyof Schema, FieldDefinition>;

  const [showAdvanced, setShowAdvanced] = useState(false);

  const formHandlers = useForm<Schema>({
    mode: "onBlur",
    resolver: zodResolver(definition.zodSchema),
    defaultValues: defaultValues as ReactHookFormDefaultValues<Schema>,
  });

  const groups = generateFormStructure(
    Object.keys(definition.fields),
    definition.groups.map(({ heading, description, fields, advanced }) => ({
      heading,
      description,
      fields: fields.map(f => String(f)),
      advanced,
    })),
    definition.rows.map(r => r.fields.map(f => String(f))),
  );

  const watchedValues = {} as FormValues<Fields>;
  for (const field of definition.watchedFields) {
    // @ts-expect-error - This is probably fixable
    watchedValues[field] = formHandlers.watch(field);
  }

  const regularGroups = groups.filter(g => !g.advanced);
  const advancedGroups = groups.filter(g => g.advanced);

  return {
    advancedGroups,
    formHandlers,
    groups,
    regularGroups,
    showAdvanced,
    setShowAdvanced,
    watchedValues,
  };
};
