import { useState } from "react";

import { TabsProps } from "../components/interfaces";
import { EnrolmentFilter } from "../enrolmentUtils";
import { getPlatformFunctions } from "../platformSpecific";

export const useEnrolmentsData = (classId: string) => {
  const { useApi } = getPlatformFunctions();
  const [currentFilter, setCurrentFilter] =
    useState<EnrolmentFilter>("current");

  const { data: course } = useApi("getCourse", { id: classId });

  const { data: season } = useApi(
    "getSeason",
    { id: course ? course.entity.seasonId : "" },
    {
      enabled: course !== undefined,
    },
  );

  const tabItems: TabsProps["items"] = [
    {
      label: "Current",
      chip: {
        label: course?.stats.enrolments.currentCount.toString() ?? "0",
        variant: "primary",
      },
      onClick: () => setCurrentFilter("current"),
    },
    {
      label: "Upcoming",
      chip: {
        label: course?.stats.enrolments.upcomingCount.toString() ?? "0",
        variant: "success",
      },
      onClick: () => setCurrentFilter("upcoming"),
    },
    {
      label: "Past",
      chip: {
        label: course?.stats.enrolments.pastCount.toString() ?? "0",
        variant: "secondary",
      },
      onClick: () => setCurrentFilter("past"),
    },
  ];

  return {
    course,
    currentFilter,
    season,
    tabItems,
  };
};
