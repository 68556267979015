export type IconButtonSize = "xs" | "sm" | "lg" | "2xl";

export const getIconSize = (size: IconButtonSize) => {
  switch (size) {
    case "xs":
      return 16;
    case "sm":
      return 20;
    case "lg":
      return 24;
    case "2xl":
      return 32;
  }
};

export const getIconButtonHitSlop = (size: IconButtonSize) => {
  switch (size) {
    case "xs":
      return 12;
    case "sm":
      return 8;
    case "lg":
      return 4;
    case "2xl":
      return 0;
  }
};
