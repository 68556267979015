import {
  AggregateClassDto,
  AggregateEnrolmentDto,
  BillDto,
  CompanyDto,
  CourseDto,
  GetQuoteRequest,
  PublicCompanyDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { BasketContents, TrialKey } from "./basket";
import { Api } from "../api";
import { getAgeInMonths } from "../dateUtils";
import { getSeasonCutOffDate } from "../seasonUtils";

export const basketToGetQuoteRequest = (
  basket: BasketContents,
  familyId: string,
): GetQuoteRequest => {
  const enrolments = [...basket.enrolments.entries()]
    .filter(([_, studentIds]) => studentIds.length > 0)
    .flatMap(([courseId, studentIds]) =>
      studentIds.map(studentId => ({
        classId: courseId,
        studentId,
      })),
    );

  const trials = [...basket.trials.entries()]
    .filter(([_, studentIds]) => studentIds.length > 0)
    .flatMap(([trialKey, studentIds]) => {
      const { courseId, date, time } = extractTrialKey(trialKey);
      return studentIds.map(studentId => ({
        classId: courseId,
        studentId,
        trialAt: date,
        time: time,
      }));
    });

  return {
    familyId,
    enrolments,
    trials,
  };
};

export type GetQuoteFn = (contents: BasketContents) => Promise<BillDto[]>;

export const getQuoteFn =
  (api: Api, familyId: string): GetQuoteFn =>
  (contents: BasketContents) => {
    return api.basket.getQuote(basketToGetQuoteRequest(contents, familyId));
  };

export const extractTrialKey = (
  trialKey: TrialKey,
): {
  courseId: string;
  date: string;
  time: string;
} => {
  const [courseId, date, time] = trialKey.split("|");
  return { courseId, date, time };
};

export type BasketItemValidation =
  | {
      valid: true;
      error?: never;
    }
  | {
      valid: false;
      error:
        | "courseHasNoStudents"
        | "courseAtCapacity"
        | "studentCannotEnrol"
        | "tooManyStudents";
    };

export const validateBasketItem = (
  course: AggregateClassDto,
  season: SeasonDto,
  students: StudentDto[],
  enrolments: AggregateEnrolmentDto[],
  company: CompanyDto | PublicCompanyDto,
  validateUserInput: boolean = false,
): BasketItemValidation => {
  // *. each course has at least 1 student added
  // *. the course is not at capacity
  // *. the student is eligible for the course

  if (course.entity.capacity !== 0) {
    const totalStudents =
      course.stats.enrolments.currentCount +
      course.stats.enrolments.upcomingCount;

    if (totalStudents >= course.entity.capacity) {
      return { valid: false, error: "courseAtCapacity" };
    }

    if (totalStudents + students.length > course.entity.capacity) {
      return { valid: false, error: "tooManyStudents" };
    }
  }

  if (validateUserInput) {
    if (students.length === 0) {
      return { valid: false, error: "courseHasNoStudents" };
    }

    for (const student of students) {
      if (
        !validateStudentOnCourse(
          student,
          course.entity,
          season,
          enrolments,
          company,
          validateUserInput,
        ).valid
      ) {
        return { valid: false, error: "studentCannotEnrol" };
      }
    }
  }

  return { valid: true, error: undefined };
};

export const studentIsOldEnoughForCourse = (
  studentAgeInMonths: number,
  courseMinAgeInMonths: number,
) => courseMinAgeInMonths === 0 || studentAgeInMonths >= courseMinAgeInMonths;

export const studentIsYoungEnoughForCourse = (
  studentAgeInMonths: number,
  courseMaxAgeInMonths: number,
) =>
  courseMaxAgeInMonths === 0 ||
  (courseMaxAgeInMonths <= 18 && studentAgeInMonths <= courseMaxAgeInMonths) ||
  (courseMaxAgeInMonths > 18 &&
    studentAgeInMonths <= courseMaxAgeInMonths + 11);

export type StudentOnCourseValidation =
  | {
      valid: true;
      error?: never;
    }
  | {
      valid: false;
      error: "studentNeedsAge" | "incorrectAge" | "alreadyEnrolled";
    };

/**
 * Validates a student's ability to enrol on a course. Strict age validation is used to force age checking, if disabled
 *  (default), it will pass if the student has no age set despite the course having an age restriction.
 * @param student
 * @param course
 * @param season
 * @param enrolments
 * @param company
 * @param strictAgeValidation
 */

export function validateStudentOnCourse(
  student: StudentDto,
  course: CourseDto,
  season: SeasonDto,
  enrolments: AggregateEnrolmentDto[],
  company: CompanyDto | PublicCompanyDto,
  strictAgeValidation: boolean = false,
): StudentOnCourseValidation {
  /**
   * Scenario 1: student has no date of birth.  Student can be selected to enrol.
   * Scenario 2: student has date of birth, course has age restrictions.
   *             Student can only be selected if they are within the age restrictions.
   * Scenario 3: student is already enrolled in the course.
   */

  if (studentIsEnrolledInCourse(student, course, enrolments)) {
    return { valid: false, error: "alreadyEnrolled" };
  }

  if (course.minAgeMonths > 0 || course.maxAgeMonths > 0) {
    if (!student.dateOfBirth) {
      return strictAgeValidation
        ? { valid: false, error: "studentNeedsAge" }
        : { valid: true };
    }

    const studentAgeAtCourseStart = getAgeInMonths(
      student.dateOfBirth,
      getSeasonCutOffDate(company.settings.enrolment!, season.startAt),
    );

    if (
      !studentIsOldEnoughForCourse(
        studentAgeAtCourseStart,
        course.minAgeMonths,
      ) ||
      !studentIsYoungEnoughForCourse(
        studentAgeAtCourseStart,
        course.maxAgeMonths,
      )
    ) {
      return { valid: false, error: "incorrectAge" };
    }
  }

  // no reason to stop selection
  return { valid: true };
}

export function studentIsEnrolledInCourse(
  student: StudentDto,
  course: CourseDto,
  enrolments: AggregateEnrolmentDto[],
) {
  return enrolments.some(
    ({ enrolment }) =>
      enrolment.studentId === student.id && enrolment.classId === course.id,
  );
}

interface EligibleFamilyMembersForCourseParams {
  courseId: string;
  courses: Array<AggregateClassDto>;
  seasons: Array<SeasonDto>;
  company: CompanyDto | PublicCompanyDto;
  familyMembers?: StudentDto[];
  enrolments?: AggregateEnrolmentDto[];
}

export function eligibleFamilyMembersForCourse({
  courseId,
  courses,
  seasons,
  company,
  familyMembers = [],
  enrolments = [],
}: EligibleFamilyMembersForCourseParams): StudentDto[] {
  const course = courses.find(course => course.entity.id === courseId)!;
  return familyMembers?.filter(
    (student: StudentDto) =>
      validateStudentOnCourse(
        student,
        course.entity,
        seasons.find(season => season.id === course.entity.seasonId)!,
        enrolments,
        company,
        false,
      ).valid,
  );
}
