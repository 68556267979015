import { ComponentType, ReactNode } from "react";

import { CompanyDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { Api } from "../../api";
import { moneyIntegerToFloat } from "../../moneyUtils";
import { useAuthState } from "../AuthStateProvider";
import { useFormActions } from "../FormActionsProvider";
import {
  makeAgeCutoffFormDefinition,
  makeAgeCutoffFormRequest,
  transformToAgeCutoffDefaults,
} from "../formDefinitions/settings/ageCutoffForm";
import {
  makeAutomatedTuitionFormRequest,
  useAutomatedTuitionForm,
} from "../formDefinitions/settings/automatedTuitionForm";
import {
  makeCompanyAddressDeleteRequest,
  makeCompanyAddressUpdateRequest,
  useCompanyAddressFormDefiniton,
} from "../formDefinitions/settings/companyAddressForm";
import {
  makeCompanyInformationFormRequest,
  useCompanyInformationFormDefinition,
} from "../formDefinitions/settings/companyInformationForm";
import {
  makeLanguageAndCurrencyFormRequest,
  useLanguageAndCurrencyFormDefinition,
} from "../formDefinitions/settings/languageAndCurrencyForm";
import {
  makeStartDayOfWeekFormRequest,
  useStartDayOfWeekFormDefinition,
} from "../formDefinitions/settings/startDayOfWeekForm";
import {
  makeTimezoneAndTimeFormatFormRequest,
  useTimezoneAndTimeFormatFormDefinition,
} from "../formDefinitions/settings/timezoneAndTimeFormatForm";
import {
  makeTrialSettingsFormRequest,
  useTrialSettingsFormDefinition,
} from "../formDefinitions/settings/trialSettingsForm";
import {
  makeTaxSettingsUpdateRequest,
  useTaxSettingsFormDefinition,
} from "../formDefinitions/taxSettingsForm";
import { GenericFormComponent } from "../genericFormHooks";
import { useSettings } from "../useSettings";

/**
 * These actions don't need any frontend so can be shared
 */
export const useSharedCompanySettingsActions = (
  api: Api,
  showForm: (options: {
    title: string;
    content: ReactNode;
    footer: ReactNode | null;
  }) => void,
  closeForm: () => void,
  GenericForm: GenericFormComponent,
  UpdateFooter: ComponentType<{ saveButtonText?: string }>,
  LookAndFeelUpdateForm: ComponentType<{
    company: CompanyDto;
    onSuccess: () => void;
  }>,
  DeleteButton: ComponentType<{
    onDelete: (onDeleteSuccess: () => void) => void;
  }>,
) => {
  const { setAccount, account } = useAuthState();
  const { shouldCloseOnSuccess } = useFormActions();
  const { industry, locale, timezone, use12HourClock, startDayOfWeek } =
    useSettings("general");
  const { currency } = useSettings("billing");
  const enrolmentSettings = useSettings("enrolment");
  const taxSettings = useSettings("tax");
  const trialSettings = useSettings("trial");
  const billingSettings = useSettings("billing");

  const showUpdateSuccessMessage = () => {
    showAlert({
      content: `Settings updated successfully`,
    });

    if (shouldCloseOnSuccess()) {
      closeForm();
    }
  };

  return {
    showAgeCutOffUpdateForm: () => {
      const defaultValues = transformToAgeCutoffDefaults(enrolmentSettings!);
      showForm({
        title: "Age cut off date",
        content: (
          <GenericForm
            apiRequest={makeAgeCutoffFormRequest({
              api,
              setAccount,
              account: account!,
            })}
            defaultValues={defaultValues}
            formDefinitionHook={makeAgeCutoffFormDefinition(defaultValues)}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showCompanyUpdateForm: () => {
      showForm({
        title: "Company Information",
        content: (
          <GenericForm
            apiRequest={makeCompanyInformationFormRequest({
              api,
              setAccount,
              account: account!,
            })}
            defaultValues={{
              name: account!.company!.name,
              industry,
              email: account!.company!.email,
              phone: account!.company!.phone,
            }}
            formDefinitionHook={useCompanyInformationFormDefinition}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showCompanyAddressForm: () => {
      const isAddressNotEmpty =
        account!.company?.address &&
        Object.keys(account!.company?.address).length !== 0;

      showForm({
        title: "Company address",
        content: (
          <>
            <GenericForm
              defaultValues={{
                ...account!.company?.address,
              }}
              apiRequest={makeCompanyAddressUpdateRequest({
                api,
                setAccount,
                account: account!,
              })}
              formDefinitionHook={useCompanyAddressFormDefiniton}
              onSuccess={() => {
                showAlert({
                  content: `Company address updated`,
                });
                closeForm();
              }}
            />
            {isAddressNotEmpty && (
              <DeleteButton
                onDelete={(onDeleteSuccess: () => void) => {
                  makeCompanyAddressDeleteRequest({
                    api,
                    setAccount,
                    account: account!,
                  }).then(() => {
                    showAlert({
                      content: `Company address deleted successfully`,
                    });
                    onDeleteSuccess();
                    closeForm();
                  });
                }}
              />
            )}
          </>
        ),
        footer: <UpdateFooter />,
      });
    },
    showLanguageAndCurrencyUpdateForm: () => {
      showForm({
        title: "Language & Currency",
        content: (
          <GenericForm
            apiRequest={makeLanguageAndCurrencyFormRequest({
              api,
              setAccount,
              account: account!,
            })}
            defaultValues={{
              currency,
              locale,
            }}
            formDefinitionHook={useLanguageAndCurrencyFormDefinition}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showLookAndFeelUpdateForm: () => {
      showForm({
        title: "Look & Feel",
        content: (
          <LookAndFeelUpdateForm
            company={account!.company!}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showStartDayOfWeekForm: () => {
      showForm({
        title: "Week start day",
        content: (
          <GenericForm
            apiRequest={makeStartDayOfWeekFormRequest({
              api,
              setAccount,
              account: account!,
            })}
            defaultValues={{
              startDayOfWeek,
            }}
            formDefinitionHook={useStartDayOfWeekFormDefinition}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showTaxSettingsForm: () => {
      showForm({
        title: "Tax configuration",
        content: (
          <GenericForm
            apiRequest={makeTaxSettingsUpdateRequest({
              api,
              setAccount,
              account: account!,
            })}
            defaultValues={{
              ...taxSettings,
            }}
            formDefinitionHook={useTaxSettingsFormDefinition}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showTimezoneAndTimeFormatUpdateForm: () => {
      showForm({
        title: "Time format",
        content: (
          <GenericForm
            apiRequest={makeTimezoneAndTimeFormatFormRequest({
              api,
              setAccount,
              account: account!,
            })}
            defaultValues={{
              timezone,
              use12HourClock: use12HourClock ? "12-hour" : "24-hour",
            }}
            formDefinitionHook={useTimezoneAndTimeFormatFormDefinition}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showTrialsForm: () => {
      showForm({
        title: "Default trial settings",
        content: (
          <GenericForm
            apiRequest={makeTrialSettingsFormRequest({
              api,
              account: account!,
              setAccount,
            })}
            defaultValues={{
              ...trialSettings,
              price: moneyIntegerToFloat(trialSettings.price),
            }}
            formDefinitionHook={useTrialSettingsFormDefinition}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    showAutomatedTuitionSettingsForm: () => {
      showForm({
        title: "Automated tuition settings",
        content: (
          <GenericForm
            apiRequest={makeAutomatedTuitionFormRequest({
              api,
              setAccount,
              account: account!,
            })}
            defaultValues={{
              billingDayOfMonth: billingSettings.billingDayOfMonth,
              billingTiming: billingSettings.billingTiming,
            }}
            formDefinitionHook={useAutomatedTuitionForm}
            onSuccess={showUpdateSuccessMessage}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
    toggleTax: (on: boolean) => {
      api.companies
        .updateCompanySettings({
          updateCompanySettingsRequest: {
            tax: {
              enabled: on,
            },
          },
        })
        .then(company => {
          setAccount({
            ...account!,
            company,
          });
        });
    },
  };
};
