import { CourseImageWithOverlayProps } from "shared/lib";

import { CourseImage } from "@/modules/common/classes/CourseImage";

export const CourseImageWithOverlay = ({
  course,
  collapseWhenPlaceholder,
  left,
  right,
}: CourseImageWithOverlayProps) => {
  return (
    <div className={"relative"}>
      <CourseImage
        course={course}
        collapseWhenPlaceholder={collapseWhenPlaceholder}
      />
      <div className={"absolute inset-0 rounded bg-overlay-grey-light"}></div>
      <div className={"absolute left-3 top-5"}>{left}</div>
      <div className={"absolute right-3 top-5"}>{right}</div>
    </div>
  );
};
