import {
  FamilyTrialsListProps,
  findClass,
  findSeason,
  findStudent,
  formatDate,
  formatTime,
  getLessonLabel,
  useBreakpoint,
} from "shared/lib";

import { Collapsible } from "@/modules/common/ui/Collapsible";
import { PendingEnrolmentCard } from "@/modules/customer/common/PendingEnrolmentCard";

export const FamilyTrialsList = ({
  trials,
  students,
  classes,
  seasons,
  title,
  collapsible = false,
}: FamilyTrialsListProps) => {
  const MainContent = () => {
    const { md: isDesktop } = useBreakpoint();

    return (
      <section className={"flex flex-col space-y-5"}>
        {!collapsible && (
          <div>
            <span className={"text-label-400 text-grey-600"}>{title}</span>
            <h4 className={"mt-1 text-heading4-600"}>
              {trials.length} {getLessonLabel(trials.length)}
            </h4>
          </div>
        )}
        {trials.map(trial => {
          const startAt = formatDate(trial.trialAt, "dayMonthYear");
          const startTime = formatTime(trial.time, "hourMinute");
          const course = findClass(classes, trial.classId);

          if (!course) {
            return null;
          }

          const season = findSeason(seasons, course.entity.seasonId)!;

          return (
            <PendingEnrolmentCard
              key={trial.id}
              student={findStudent(students, trial.studentId)!}
              rightSlot={
                <p className={"text-body-400"}>
                  {isDesktop ? `${startAt}, ${startTime} ` : startAt}
                </p>
              }
              course={course}
              season={season}
            />
          );
        })}
      </section>
    );
  };

  return collapsible ? (
    <Collapsible title={title}>
      <MainContent />
    </Collapsible>
  ) : (
    <MainContent />
  );
};
