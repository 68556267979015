import { Table } from "@tanstack/react-table";

import { cn, DatatableContentState, useDatatable } from "shared/lib";

import { DatatableHeaderCell } from "@/modules/common/datatable/cell/DatatableHeaderCell";
import { DatatableHeaderPlaceholderRow } from "@/modules/common/datatable/rows/DatatableHeaderPlaceholderRow";

export const WebTableHeader = <ModelDto,>({
  table,
  contentState,
  showBorder,
}: {
  table: Table<ModelDto>;
  contentState: DatatableContentState;
  showBorder: boolean;
}) => {
  const { datatable } = useDatatable();

  if (contentState == DatatableContentState.Loading) {
    return <DatatableHeaderPlaceholderRow />;
  }

  if (contentState === DatatableContentState.Loaded) {
    return table.getHeaderGroups().map(headerGroup => (
      <tr
        key={headerGroup.id}
        className={cn("border-grey-300", { "border-b": showBorder })}>
        {headerGroup.headers.map(header => {
          // I'm not super-comfortable with having to check both label and sortableField,
          // but this is the way the current sort functionality works (by using the sortableField if supplied)
          // as a way to know what to put in the query string.
          const ourDef = datatable.columns?.find(c =>
            [c.label, c.sortableField].includes(header.id),
          );

          const width = header.column?.columnDef?.size
            ? `${header.column?.columnDef?.size}px`
            : ourDef?.width;

          return (
            <DatatableHeaderCell
              key={header.id}
              header={header}
              table={table}
              width={width}
            />
          );
        })}
      </tr>
    ));
  }

  return <></>;
};
