import { useContext } from "react";

import { PermissionContext } from "./permissionContext";

export const usePermission = (): PermissionContext => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermission must be used within a PermissionProvider");
  }

  return context;
};
