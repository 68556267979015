import { Chip } from "../ui/Chip";

export interface LessonChipProps {
  isOnHoliday: boolean;
  isCancelled: boolean;
}

export const LessonChip = ({ lesson }: { lesson: LessonChipProps }) => {
  if (lesson.isOnHoliday) {
    return <Chip label={"Holiday"} variant={"neutral"} size={"sm"} />;
  }

  if (lesson.isCancelled) {
    return <Chip label={"Cancelled"} variant={"danger"} size={"sm"} />;
  }

  return null;
};
