import {
  StripePaymentMethodBacsDebitDataDto,
  StripePaymentMethodCardDataDto,
  StripePaymentMethodDto,
  StripePaymentMethodDtoData,
} from "@justraviga/classmanager-sdk";

import { dayjs } from "./lib/dayjs";

function padMonth(month: number) {
  return month < 10 ? `0${month}` : month;
}

function formatYear(year: number) {
  return year.toString().slice(-2);
}

export function isCardMethod(
  data: StripePaymentMethodDtoData,
): data is StripePaymentMethodCardDataDto {
  return "brand" in data;
}

export function isBacsMethod(
  data: StripePaymentMethodDtoData,
): data is StripePaymentMethodBacsDebitDataDto {
  return "sortCode" in data;
}

export const hasPaymentMethodExpired = (
  paymentMethod: StripePaymentMethodDto,
) => {
  if (isCardMethod(paymentMethod.data)) {
    const now = dayjs();
    const expiryDate = dayjs(
      new Date(
        paymentMethod.data.expiryYear,
        paymentMethod.data.expiryMonth - 1,
      ),
    );

    return expiryDate.isSameOrBefore(now, "month");
  }

  return false;
};

export const isDefaultPaymentMethod = (
  paymentMethod: StripePaymentMethodDto,
) => {
  return paymentMethod.isDefault;
};

export function displayExpiryDate(expMonth: number, expYear: number) {
  return `${padMonth(expMonth)}/${formatYear(expYear)}`;
}

export function displayObscuredLast4(last4: string) {
  return `•••• ${last4}`;
}

export function calculatePaymentBalance(balance: number) {
  if (balance > 0) {
    return 0;
  }
  return -balance;
}
