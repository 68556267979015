import { useSharedWaiversListPage } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { useWaiverActions } from "@/modules/company/waivers/useWaiverActions";
import { Router } from "@/routing/router";

export function useWaiversListPage() {
  const waiverActions = useWaiverActions();
  const { waivers, isEmpty, showCreateForm } = useSharedWaiversListPage(
    waiverActions,
    false,
    useApi,
  );

  const goToArchivedItems = () => Router.push("CompanyWaiversArchived");

  return {
    waivers,
    isEmpty,
    showCreateForm,
    goToArchivedItems,
  };
}
