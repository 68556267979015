import { QuickActionButtonProps } from "./QuickActionButton";
import { useAlert } from "../../alertState";

interface Variation {
  title: string;
  description?: string;
  props: QuickActionButtonProps[];
}

export const useQuickActionButtonVariations = (): Array<Variation> => {
  const alert = useAlert();
  const makeClickHandler = (content: string) => () =>
    alert.showAlert({ content });

  return [
    {
      title: "Colors",
      props: [
        {
          icon: "people",
          text: "Add by student",
          onClick: makeClickHandler("Adding by student..."),
          variant: "brand",
        },
        {
          icon: "calendarNumberOutline",
          text: "Add by class",
          onClick: makeClickHandler("Adding by class..."),
          variant: "default",
        },
      ],
    },
  ];
};
