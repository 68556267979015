import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as RadixToast from "@radix-ui/react-toast";

import {
  AlertContext,
  AlertContextType,
  AlertProps,
  AlertState,
  subscribeToAlertStateChanges,
  unsubscribeFromAlertStateChanges,
} from "shared/lib";

import { Alert } from "@/modules/common/overlays/alert/Alert";
import { AlertContextImpl } from "@/modules/common/overlays/alert/AlertContextImpl";
import "./toast-styles.css";

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alerts, setAlerts] = useState<Map<string, AlertProps>>(new Map());
  const alertElementsMapRef = useRef(new Map());
  const clearAlertTimeouts = useRef<Map<string, NodeJS.Timeout>>(new Map());
  const viewportRef = useRef<HTMLOListElement>(null);

  const sortAlerts = useCallback(() => {
    const alertElements = Array.from(alertElementsMapRef.current).reverse();
    const heights: number[] = [];

    alertElements.forEach(([, alert], index) => {
      if (!alert) return;
      const height = alert.clientHeight;
      heights.push(height);
      const frontToastHeight = heights[0];
      alert.setAttribute("data-front", index === 0);
      alert.setAttribute("data-hidden", index > 2);
      alert.style.setProperty("--index", index);
      alert.style.setProperty("--height", `${height}px`);
      alert.style.setProperty("--front-height", `${frontToastHeight}px`);
      const hoverOffsetY = heights
        .slice(0, index)
        .reduce((res, next) => (res += next), 0);
      alert.style.setProperty("--hover-offset-y", `${hoverOffsetY}px`);
    });
  }, []);

  const deleteAlert = useCallback((key?: string) => {
    if (key) {
      setAlerts(currentAlerts => {
        const newMap = new Map(currentAlerts);
        newMap.delete(key);
        return newMap;
      });
    }
  }, []);

  const hideAlert = useCallback(
    (key?: string) => {
      if (key) {
        setAlerts(currentAlerts => {
          const newMap = new Map(currentAlerts);
          const currentAlert = currentAlerts.get(key);
          newMap.set(key, { ...currentAlert, show: false });
          return newMap;
        });

        setTimeout(() => {
          deleteAlert(key);
        }, 300);
      }
    },
    [deleteAlert],
  );

  const showAlert = useCallback(
    (alert: AlertProps) => {
      const newAlertKey = String(Date.now());
      setAlerts(currentAlerts => {
        const newMap = new Map(currentAlerts);
        newMap.set(newAlertKey, { ...alert, show: true });
        return newMap;
      });

      const newTimeout = setTimeout(() => {
        hideAlert(newAlertKey);
      }, 2300);

      clearAlertTimeouts.current.set(newAlertKey, newTimeout);
    },
    [hideAlert],
  );

  useEffect(() => {
    const currentClearAlertTimeouts = clearAlertTimeouts.current;
    subscribeToAlertStateChanges(
      "AlertProvider",
      ({ show, alertProps }: AlertState) => {
        if (show && alertProps?.content) {
          showAlert({ ...alertProps, show });
        }
      },
    );

    return () => {
      unsubscribeFromAlertStateChanges("AlertProvider");
      Array.from(currentClearAlertTimeouts.values()).forEach(timeout => {
        clearTimeout(timeout);
      });
    };
  }, [showAlert]);

  useEffect(() => {
    const viewport = viewportRef.current;

    if (viewport) {
      const handleFocus = () => {
        alertElementsMapRef.current.forEach(alert => {
          alert.setAttribute("data-hovering", "true");
        });
      };

      const handleBlur = (event: Event) => {
        const target = event.target as HTMLOListElement;

        if (!viewport?.contains(target) || viewport === target) {
          alertElementsMapRef.current.forEach(alert => {
            alert.setAttribute("data-hovering", "false");
          });
        }
      };

      viewport.addEventListener("pointermove", handleFocus);
      viewport.addEventListener("pointerleave", handleBlur);
      viewport.addEventListener("focusin", handleFocus);
      viewport.addEventListener("focusout", handleBlur);

      return () => {
        viewport.removeEventListener("pointermove", handleFocus);
        viewport.removeEventListener("pointerleave", handleBlur);
        viewport.removeEventListener("focusin", handleFocus);
        viewport.removeEventListener("focusout", handleBlur);
      };
    }
  }, []);

  const triggerAlertContextValue: AlertContextType = useMemo(
    () => ({
      showAlert,
      hideAlert,
    }),
    [showAlert, hideAlert],
  );

  const toastContextValue = useMemo(
    () => ({
      alertElementsMapRef,
      sortAlerts,
    }),
    [alertElementsMapRef, sortAlerts],
  );

  return (
    <RadixToast.Provider swipeDirection="right">
      <AlertContext.Provider value={triggerAlertContextValue}>
        <AlertContextImpl.Provider value={toastContextValue}>
          {children}
          {Array.from(alerts).map(([key, alert]) => (
            <Alert
              id={key}
              key={key}
              {...alert}
              onOpenChange={(open: boolean) => {
                if (!open) {
                  alertElementsMapRef.current.delete(key);
                  hideAlert(key);
                  sortAlerts();
                }
              }}
            />
          ))}
          <RadixToast.Viewport ref={viewportRef} className="ToastViewport" />
        </AlertContextImpl.Provider>
      </AlertContext.Provider>
    </RadixToast.Provider>
  );
};
