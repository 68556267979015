import { UserAccountDto } from "@justraviga/classmanager-sdk";

import { useSharedFamilyWaiversListPage } from "./useSharedFamilyWaiversListPage";

export function usePendingWaivers(account: UserAccountDto | null) {
  const { waivers, isLoading } = useSharedFamilyWaiversListPage(account);

  const filteredWaivers = (waivers?.data ?? []).filter(
    waiverWithAgreement => !waiverWithAgreement.agreement,
  );

  return {
    pendingWaivers: filteredWaivers,
    isLoading,
  };
}
