/* eslint-disable check-file/filename-naming-convention */
import { createContext } from "react";

import { AuthContextInterface } from "../authState";

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  token: null,
  account: null,
  setUser: () => {},
  setAccount: () => {},
  clearAuthState: () => {},
  isLoading: true,
  isLoggedIn: false,
  onLogin: undefined,
  setOnLogin: () => {},
});
