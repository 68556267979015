import { FC } from "react";

import { ChartAxisDto } from "@justraviga/classmanager-sdk";

import { ChartWidget } from "./ChartWidget";
import { dayjs } from "../../../../lib/dayjs";
import { getPlatformFunctions } from "../../../../platformSpecific";

interface Props {
  EnrolmentTrendChart: FC<{ data: ChartAxisDto[] }>;
}

export const EnrolmentTrendsWidget = ({ EnrolmentTrendChart }: Props) => {
  const { useApi } = getPlatformFunctions();

  const { data, isLoading } = useApi("chartByMonthEnrolment", {
    fromDate: dayjs().subtract(5, "month").format("YYYY-MM"), // current month and 5 previous
    toDate: dayjs().format("YYYY-MM"),
  });

  return (
    <ChartWidget
      title="Enrollment trends"
      chartTitle="Last 6 months"
      lastUpdated={new Date()}
      isLoading={isLoading}>
      <EnrolmentTrendChart data={data!} />
    </ChartWidget>
  );
};
