import {
  SeasonDto,
  StudentDto,
  WaitingListDto,
} from "@justraviga/classmanager-sdk";
import { AggregateClassDto } from "@justraviga/classmanager-sdk/dist/models/AggregateClassDto";

import { Api } from "../../api";
import { useClassesSeasons } from "../../data/useClassesSeasons";
import { useStudentSelect } from "../../data/useStudentSelect";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { UseApi } from "../apiQueryFactory";
import { useFormActions } from "../FormActionsProvider";

export type ExtendedWaitingListDto = WaitingListDto & {
  class: AggregateClassDto;
  student: StudentDto;
  season: SeasonDto;
};

type AddSchema = {
  classId: string;
  studentIds: string[];
};

const useFormDefinition = ({
  useApi,
  courseId,
  studentId,
}: {
  useApi: UseApi;
  courseId?: string;
  studentId?: string;
}) => {
  const { setAllowCreateAdditional, setCreateButtonText } = useFormActions();
  type Schema = AddSchema;

  const { selectedClassId } = useClassesSeasons({ useApi });
  const { studentList } = useStudentSelect({
    useApi,
    classId: courseId ?? selectedClassId,
  });

  setAllowCreateAdditional(false);
  setCreateButtonText("Add");

  const builder = new FormDefinitionBuilder<Schema>();
  if (!courseId) {
    builder.group("Class details", ["classId"]).courseId("classId", {
      required: true,
    });
  }
  if (!studentId) {
    builder.group("Students", ["studentIds"]).multiSelect("studentIds", {
      label: "Student",
      data: studentList,
      localSearch: true,
      required: true,
    });
  }

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeWaitingListAddRequest =
  ({
    api,
    courseId,
    studentId,
  }: {
    api: Api;
    courseId?: string;
    studentId?: string;
  }) =>
  (data: AddSchema) => {
    const studentIds = studentId ? [studentId] : data.studentIds;

    const waitingListData = studentIds.map(id => ({
      classId: courseId ?? data.classId,
      studentId: studentId ?? id,
    }));

    return api.waitingLists.createWaitingList({
      createWaitingListRequestInner: waitingListData,
    });
  };

export const makeWaitingListAddForm =
  (params: Parameters<typeof useFormDefinition>[0]) => () =>
    useFormDefinition(params);
