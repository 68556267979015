import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  ageRangeDescription,
  calculateWeeksBetweenDates,
  ClassEntity,
  displayLessonTimes,
  enumLabel,
  formatDate,
} from "shared/lib";

import { Button } from "@/modules/common/ui/button/Button";
import { useCourseActions } from "@/modules/company/classPlanner/classes/useCourseActions";
import { DetailProfileContainer } from "@/modules/company/common/DetailProfile";

interface ClassDetailsHeaderProps {
  classEntity: ClassEntity;
  season: SeasonDto;
}

export const ClassDetailsHeader = ({
  classEntity,
  season,
}: ClassDetailsHeaderProps) => {
  const { showPreview } = useCourseActions();
  const { class: courseDto } = classEntity;
  const dayOfWeek = enumLabel("weekDays", courseDto.dayOfWeek);

  const seasonStart = formatDate(season.startAt, "dayMonthYear");
  const seasonEnd = formatDate(season.endAt, "dayMonthYear");

  const weekCount = calculateWeeksBetweenDates(
    new Date(season.startAt),
    new Date(season.endAt),
  );

  return (
    <DetailProfileContainer>
      <div className={"flex items-center"}>
        <p className={"line-clamp-1 text-heading5-600"}>{courseDto.name}</p>
        <div>
          <Button
            variant={"tertiaryLight"}
            onClick={() => showPreview(classEntity, season)}
            text={"More details"}
          />
        </div>
      </div>
      <div className={"text-grey-600"}>
        {dayOfWeek} • {displayLessonTimes(classEntity.class)}
      </div>
      <div className={"text-grey-600"}>
        {seasonStart} - {seasonEnd} ({weekCount} weeks)
      </div>
      <div className={"text-grey-600"}>
        {ageRangeDescription(courseDto.minAgeMonths, courseDto.maxAgeMonths)}
      </div>
    </DetailProfileContainer>
  );
};
