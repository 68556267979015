import {
  AccountPermission,
  CustomFieldEntity,
  InvitationStatus,
  StaffDto,
} from "@justraviga/classmanager-sdk";

import { CustomFieldResponsesCard, InvitationWidget } from "shared/components";
import { getFullName, useBreakpoint, WebActionMenuProps } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { AddressCard } from "@/modules/company/addresses/AddressCard";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { CustomFieldResponsesContent } from "@/modules/company/customFields/entityResponses/card/CustomFieldResponsesContent";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";
import { Profile } from "@/modules/company/staff/details/Profile";
import { StaffScheduleWidget } from "@/modules/company/staff/StaffScheduleWidget";
import { useStaffActions } from "@/modules/company/staff/useStaffActions";
import { Router } from "@/routing/router";

export const StaffDetailsPage = ({ id }: { id: StaffDto["id"] }) => {
  const breakpoints = useBreakpoint();
  const staffActions = useStaffActions();
  const customFieldActions = useCustomFieldActions();

  const { data: staffData } = useApi("getStaff", { id });

  if (staffData === undefined) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  const staff = staffData as StaffDto;

  const actions: WebActionMenuProps["items"] = [
    ...(staff.invitationStatus === InvitationStatus.NotInvited
      ? [
          {
            title: "Invite to Class Manager",
            onClick: () => {
              staffActions.showInviteForm(staff);
            },
            permission: AccountPermission.Staffinvite,
          },
        ]
      : []),
    ...(breakpoints.md
      ? []
      : [
          {
            title: "Edit",
            onClick: () => staffActions.showUpdateForm(staff),
          },
        ]),
    {
      title: staff.archivedAt ? "Restore" : "Archive",
      onClick: () => {
        staff.archivedAt
          ? staffActions.unarchive(staff).then()
          : staffActions.archive(staff).then();
      },
    },
    {
      title: "Delete",
      destructive: true,
      onClick: () => {
        staffActions.deleteOne(staff).then(() => {
          Router.push("StaffList");
        });
      },
    },
  ];

  const crumbs = [
    {
      text: "Staff",
      onPress: () => {
        Router.push("StaffList");
      },
    },
    {
      text: getFullName(staff),
    },
  ];

  const InviteWidget = () => {
    return (
      <InvitationWidget
        invitationStatus={staff.invitationStatus}
        getLinkData={() => staffActions.getLinkData(staff)}
        getUserData={() => staffActions.getUserData(staff)}
        sendInvite={() => staffActions.showInviteForm(staff)}
        cancelInvite={() => staffActions.cancelInvite(staff)}
        revokeAccess={() => staffActions.revokeAccess(staff)}
        accountPermission={AccountPermission.Staffinvite}
        ActionMenu={({ items }) => (
          <ActionMenu
            items={items}
            trigger={
              <IconButton
                icon={"ellipsisHorizontal"}
                variant={"standard"}
                size={"xs"}
              />
            }
          />
        )}
      />
    );
  };

  return (
    <CompanyLayout
      alwaysShowGlobalHeader={false}
      permission={AccountPermission.Staffread}>
      <DetailPageLayout
        isDesktop={breakpoints.md}
        crumbs={crumbs}
        isArchived={staff.archivedAt !== null}
        header={
          <Profile
            staff={staff}
            isDesktop={breakpoints.md}
            uploadAction={() => staffActions.showAvatarUploadSheet(staff)}
          />
        }
        editAction={() => staffActions.showUpdateForm(staff)}
        restoreAction={() => staffActions.unarchive(staff).then()}
        archivedEntityName={"staff member"}
        actions={actions}>
        <DetailPageGrid>
          <DetailPageGridLeftColumn>
            {!breakpoints.md && <InviteWidget />}
            <StaffScheduleWidget staffId={staff.id} />
          </DetailPageGridLeftColumn>
          <DetailPageGridRightColumn>
            {breakpoints.md && <InviteWidget />}

            <div className={"w-full"}>
              <CustomFieldResponsesCard
                entityId={staff.id}
                defaultEntity={CustomFieldEntity.Staff}
                CustomFieldResponsesContent={CustomFieldResponsesContent}
                customFieldActions={customFieldActions}
              />
            </div>

            <AddressCard entityId={staff.id} />
          </DetailPageGridRightColumn>
        </DetailPageGrid>
      </DetailPageLayout>
    </CompanyLayout>
  );
};
