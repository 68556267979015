import { useSharedWaiversListPage } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { useWaiverActions } from "@/modules/company/waivers/useWaiverActions";
import { Router } from "@/routing/router";

export function useWaiversArchivedListPage() {
  const waiverActions = useWaiverActions();
  const { waivers, isEmpty } = useSharedWaiversListPage(
    waiverActions,
    true,
    useApi,
  );

  const goToWaiversPage = () => Router.push("CompanyWaivers");

  return {
    goToWaiversPage,
    isEmpty,
    waivers,
  };
}
