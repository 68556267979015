import { useContext } from "react";

import { SheetContext } from "@/modules/common/overlays/dialog/context/SheetProvider";

export const useSheet = () => {
  const context = useContext(SheetContext);

  if (context === undefined) {
    throw new Error("Sheet Context used outside of Provider");
  }

  return context;
};
