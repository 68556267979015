import { useStudentDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

export const StudentList = () => {
  const studentActions = useStudentActions();
  const { datatable } = useStudentDatatable({
    Datatable,
    api,
    archive: studentActions.archive,
    archiveMany: studentActions.archiveMany,
    deleteMany: studentActions.deleteMany,
    deleteOne: studentActions.deleteOne,
    onRowClick: item => Router.push("StudentDetails", { id: item.id }),
    showCreateForm: studentActions.showCreateForm,
    showUpdateForm: studentActions.showUpdateForm,
    unarchive: studentActions.unarchive,
  });

  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};
