import { SortingState } from "@tanstack/react-table";

import {
  DatatableConfiguration,
  DatatableFilter,
  DatatableFilterManager,
  DatatableModelSelection,
  DatatablePaginatedData,
  DatatableQueryProps,
  DatatableRequest,
  DatatableRow,
  Dto,
  PossibleFilterValues,
} from "./datatableTypes";
import { FilterOptions } from "../forms/filterFormDefinitionBuilder";
import {
  FilterFormDefinition,
  PossibleFormValues,
} from "../forms/formBuilderTypes";

export function transformDataIntoPaginatedData<ModelDto>({
  rawData,
  onClick,
}: {
  rawData: DatatablePaginatedData<ModelDto>;
  onClick?: (item: ModelDto) => void;
}): DatatablePaginatedData<DatatableRow<ModelDto>> {
  const transformedData: DatatableRow<ModelDto>[] = rawData.data.map(
    (model: ModelDto): DatatableRow<ModelDto> => {
      return {
        item: model,
        onClick: onClick ? () => onClick(model) : undefined,
      };
    },
  );

  return {
    data: transformedData,
    pagination: {
      count: rawData.pagination.count,
      total: rawData.pagination.total,
      perPage: rawData.pagination.perPage,
      currentPage: rawData.pagination.currentPage,
      totalPages: rawData.pagination.totalPages,
    },
  };
}

export function datatableIdsSelection(
  modelsSelected: string[],
  setModelsSelected: (models: string[]) => void,
): DatatableModelSelection<string> {
  return {
    getAll: () => {
      return modelsSelected;
    },
    count: () => {
      return modelsSelected.length;
    },
    isEmpty: () => {
      return modelsSelected.length === 0;
    },
    isNotEmpty: () => {
      return modelsSelected.length > 0;
    },
    has: (item: string) => {
      return modelsSelected.includes(item);
    },
    hasAny: (items: string[]) => {
      return items.some(item => modelsSelected.some(model => model === item));
    },
    hasAll: (items: string[]) => {
      return items.every(item => modelsSelected.some(model => model === item));
    },
    add: (item: string) => {
      setModelsSelected([...modelsSelected, item]);
    },
    addMany: (items: string[]) => {
      setModelsSelected([...modelsSelected, ...items]);
    },
    remove: (item: string) => {
      setModelsSelected(modelsSelected.filter(model => model !== item));
    },
    toggle: (item: string) => {
      if (modelsSelected.some(model => model === item)) {
        setModelsSelected(modelsSelected.filter(model => model !== item));
      } else {
        setModelsSelected([...modelsSelected, item]);
      }
    },
    toggleAll: (items: string[]) => {
      if (
        datatableIdsSelection(modelsSelected, setModelsSelected).hasAll(items)
      ) {
        datatableIdsSelection(modelsSelected, setModelsSelected).clear();
      } else {
        setModelsSelected(items);
      }
    },
    clear: () => {
      setModelsSelected([]);
    },
    state: modelsSelected,
  };
}

export function fetchAndSelectAll<
  ModelDto extends Dto,
  FilterFormSchema extends object,
>(
  query: DatatableQueryProps,
  datatable: DatatableConfiguration<ModelDto, FilterFormSchema>,
  modelSelection: DatatableModelSelection<string>,
) {
  const queryPage = query.page;

  query.page = 1;
  query.selectAll = true;

  datatable.fetchData(query).then(data => {
    modelSelection.toggleAll(
      data.data.map(item => {
        return item.id;
      }),
    );
  });

  query.selectAll = false;
  query.page = queryPage;
}

export function datatableFilters(
  filters: DatatableFilter[],
  setFilters: (filters: DatatableFilter[]) => void,
): DatatableFilterManager {
  return {
    count: () => {
      //Walk through all values and if value is array then count it
      return filters.reduce((totalCount, filter) => {
        const value = Array.isArray(filter.value) ? filter.value.length : 1;
        return totalCount + value;
      }, 0);
    },
    getAll: () => {
      return filters;
    },
    add: (filter: DatatableFilter) => {
      setFilters([...filters, filter]);
    },
    update: (filter: DatatableFilter) => {
      // Update the filter by field and operator
      // Field and operator should be always unique

      const index = filters.findIndex(
        f => f.field === filter.field && f.operator === filter.operator,
      );

      if (index > -1) {
        filters[index] = filter;
      } else {
        filters.push(filter);
      }
    },
    replace: (filters: DatatableFilter[]) => {
      setFilters(filters);
    },
    remove: (filter: DatatableFilter) => {
      setFilters(filters.filter(f => f.field !== filter.field));
    },
    clear: () => {
      setFilters([]);
    },

    state: filters,
  };
}

export function convertSortToRequestSort(sort: SortingState) {
  return sort.reduce(
    (
      acc: {
        [key: string]: "asc" | "desc";
      },
      sort,
    ) => {
      const sortField = sort.id as string;
      acc[sortField] = sort.desc ? "desc" : "asc";
      return acc;
    },
    {},
  );
}

export function convertFiltersToRequestWhere(filters: DatatableFilter[]) {
  return filters.reduce(
    (
      acc: {
        [key: string]: {
          [key: string]: PossibleFilterValues;
        };
      },
      filter,
    ) => {
      const field = filter.field;

      if (field === "isArchived") {
        return acc;
      }

      if (!acc[field]) {
        acc[field] = {};
      }

      acc[field][filter.operator] = filter.value;

      return acc;
    },
    {},
  );
}

export function datatableQueryToRequest(query: DatatableQueryProps) {
  const request: DatatableRequest = {
    search: query.search,
    page: query.page,
    pageSize: query.pageSize,
    selectAll: query.selectAll,
    where: convertFiltersToRequestWhere(query.filters),
    sort: convertSortToRequestSort(query.sort),
    onlyArchived: query.filters.some(v => v.field === "isArchived" && v.value),
  };

  return request;
}

export function getInitialQuery(): DatatableQueryProps {
  const query: DatatableQueryProps = {
    search: "",
    page: 1,
    pageSize: 20,
    filters: [],
    sort: [],
    selectAll: false,
    toRequest: () => datatableQueryToRequest(query),
    isEmpty: () => {
      return query.search === "" && query.filters.length === 0;
    },
  };
  return query;
}

export const defaultPaginatedData = () => {
  return {
    data: [],
    pagination: {
      total: 0,
      perPage: 0,
      currentPage: 0,
    },
  };
};

export const mapFilterFormValues = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<any, any>,
  filterOptions: Record<
    keyof typeof values,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FilterOptions<any>
  >,
) => {
  const filters: DatatableFilter[] = [];
  for (const field in values) {
    const { filterField, operator, transformer } = filterOptions[field];
    const value = transformer ? transformer(values[field]) : values[field];
    if (
      value === undefined ||
      value === null ||
      (field === "isArchived" && value === false)
    ) {
      continue;
    }
    filters.push({
      field: String(filterField),
      operator,
      value,
    });
  }
  return filters;
};

export const groupListFilterDefaultValues = ({
  filterForm,
  appliedFilters,
}: {
  filterForm: FilterFormDefinition<object, object>;
  appliedFilters: DatatableFilter[];
}) => {
  return Object.keys(filterForm.formDefinition.fields).reduce<
    Record<string, PossibleFormValues>
  >((acc, field) => {
    const filter = appliedFilters.find(filter => filter.field === field);
    if (field === "isArchived") {
      acc[field] = filter ? (filter.value as PossibleFormValues) : false;
    } else {
      acc[field] = filter ? (filter.value as PossibleFormValues) : undefined;
    }

    return acc;
  }, {});
};
