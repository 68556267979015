import { useGenericComponents } from "./GenericComponentsProvider";
import { colors } from "../colors";

export const CompanyLogoPlaceholder = () => {
  const { Icon, View } = useGenericComponents();
  return (
    <View className="flex h-full w-full items-center justify-center bg-brand-900">
      <Icon name={"businessOutline"} color={colors.white} size={24} />
    </View>
  );
};
