import { IconName } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export const ClickableNavItem = ({
  text,
  iconName,
  onClick,
  badgeCount,
}: {
  text: string;
  iconName: IconName;
  onClick?: () => void;
  badgeCount?: number;
}) => {
  return (
    <button
      className={"w-full hover:bg-grey-100"}
      onClick={() => onClick && onClick()}>
      <div
        className={
          "flex w-full flex-row items-center justify-between rounded p-2"
        }>
        <div className={"flex flex-row items-center space-x-4"}>
          <div className={"rounded-sm bg-grey-300 p-1.5"}>
            <Icon name={iconName} />
          </div>
          <span className={"whitespace-nowrap"}>{text}</span>
          {badgeCount !== undefined && (
            <span className="flex h-5 w-5 items-center justify-center rounded-full bg-red-600">
              <span className="text-10 text-white">{badgeCount}</span>
            </span>
          )}
        </div>
        <Icon name={"chevronForwardOutline"} />
      </div>
    </button>
  );
};
