import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  ColorPicker,
  ColorPickerProps,
} from "@/modules/common/form/ColorPicker";

const options: Array<Option<ColorPickerProps>> = [
  {
    title: "States",
    props: [
      { label: "Default" },
      { label: "Disabled", disabled: true },
      { label: "Error", error: true },
    ],
  },
  {
    title: "Example values",
    props: [
      { label: "Red", value: "#ff0000" },
      { label: "Green", value: "#00ff00" },
      { label: "Blue", value: "#0000ff" },
      { label: "Yellow", value: "#ffff00" },
      { label: "Orange", value: "#ff8000" },
      { label: "Purple", value: "#ff00ff" },
    ],
  },
];

export const ColorPickerPage = () => {
  return (
    <div>
      <DocWrapper
        title="Color Picker"
        component={ColorPicker}
        options={options}
      />
    </div>
  );
};
