import {
  Timezone,
  UpdateCompanySettingsRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { setIntlFormatterOptions } from "../../../intlFormatter";

type ClockFormat = "12-hour" | "24-hour";

type UpdateGeneral = Omit<
  Required<UpdateCompanySettingsRequest>["general"],
  "use12HourClock"
> & {
  use12HourClock: ClockFormat;
};

export const useTimezoneAndTimeFormatFormDefinition = () => {
  const form = new FormDefinitionBuilder<UpdateGeneral>()
    .select("timezone", {
      label: "Time zone",
      data: Object.entries(Timezone).map(([_, value]) => ({
        value: value,
        label: value.replace("_", " "),
      })),
      required: true,
      localSearch: true,
    })
    .select("use12HourClock", {
      label: "Clock format",
      selectedOnTop: false,
      data: [
        {
          value: "12-hour",
          label: "12 hour",
        },
        {
          value: "24-hour",
          label: "24 hour",
        },
      ],
      required: true,
    });
  return form.getDefinition();
};

export const makeTimezoneAndTimeFormatFormRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  (formData: UpdateGeneral) => {
    return api.companies
      .updateCompanySettings({
        updateCompanySettingsRequest: {
          general: {
            ...formData,
            use12HourClock: formData.use12HourClock === "12-hour",
          },
        },
      })
      .then(company => {
        setAccount({
          ...account,
          company,
        });

        // Update time format settings to see changes immediately on other pages after refresh (without re-login)
        setIntlFormatterOptions({
          timezone: company.settings.general.timezone,
          locale: company.settings.general.locale,
          use12HourClock: company.settings.general.use12HourClock,
          currency: company.settings.billing.currency,
        });

        return company;
      });
  };
