import { UseQueryResult } from "@tanstack/react-query";

import { DefinedEntity, EmptyEntity, UseEntityResponse } from "./entityTypes";

export const emptyEntity = (): EmptyEntity => ({
  type: "empty",
  isArchivable: () => false,
  isArchived: () => false,
  isDeletable: () => false,
  isEditable: () => false,
  isRestorable: () => false,
  isDefined: () => false,
});

export function useEntity<T, E extends DefinedEntity<T>>(
  entityFactory: (entityDto: T) => E,
  apiResponse: UseQueryResult<T>,
): UseEntityResponse<T, E> {
  const { data, ...rest } = apiResponse;

  if (!data) {
    return {
      entity: emptyEntity(),
      ...rest,
    };
  }

  return {
    entity: entityFactory(data),
    ...rest,
  };
}
