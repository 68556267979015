import { PropsWithChildren } from "react";

import { ContentPlaceholder, ProtectionChecker } from "shared/components";

import { FamilyBackground } from "@/modules/common/layout/FamilyBackground";
import { FamilyContent } from "@/modules/common/layout/FamilyContent";
import { FamilyFooter } from "@/modules/common/layout/FamilyFooter";
import { CustomerHeader } from "@/modules/customer/CustomerHeader";

interface ProtectedPlaceholderProps extends PropsWithChildren {
  protector: ProtectionChecker;
}

export const ProtectedPlaceholder = ({
  children,
  protector,
}: ProtectedPlaceholderProps) => {
  if (!protector.isProtected()) {
    return children;
  }

  return (
    <FamilyBackground>
      <CustomerHeader />
      <FamilyContent>
        <ContentPlaceholder
          icon={"alertCircleOutline"}
          title={"You don't currently have access to the Family Portal"}
          description={"Contact your organization for more information."}
        />
      </FamilyContent>
      <FamilyFooter />
    </FamilyBackground>
  );
};
