import {
  PricingScheme,
  PricingSchemeValueDto,
} from "@justraviga/classmanager-sdk";

import { minutesToHumanReadable } from "../../../../classUtils";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { SeasonPricingListCardTableProps } from "../../../interfaces";

export const SeasonPricingList = ({
  values,
  scheme,
}: SeasonPricingListCardTableProps) => {
  const { View } = useGenericComponents();

  const getSchemeText = (scheme: PricingScheme) => {
    switch (scheme) {
      case PricingScheme.ClassCount:
        return "Classes / week";
      case PricingScheme.TotalDuration:
        return "Hours / week";
    }
  };

  const formatValue = (value: number) => {
    switch (scheme) {
      case PricingScheme.ClassCount:
        return value;
      case PricingScheme.TotalDuration:
        return minutesToHumanReadable(value);
    }
  };

  const getQuantityText = (value: PricingSchemeValueDto, index: number) => {
    return `${formatValue(value.quantity)} ${index === values.length - 1 ? "or more" : ""}`;
  };

  return (
    <View className={"flex flex-col space-y-2"}>
      <View>
        <RowItem
          leftText={getSchemeText(scheme) ?? ""}
          rightText={"Price per month"}
          isPrice={false}
        />
      </View>
      {values.map((value, i) => (
        <View key={`${value.seasonId}-${i}`}>
          <RowItem
            leftText={getQuantityText(value, i)}
            rightText={formatMoneyFromInteger(value.price)}
          />
        </View>
      ))}
    </View>
  );
};

const RowItem = ({
  leftText,
  rightText,
  isPrice = true,
}: {
  leftText: string;
  rightText: string;
  isPrice?: boolean;
}) => {
  const { View, Text } = useGenericComponents();

  return (
    <View className={"flex flex-row items-center justify-between"}>
      <Text className={"text-grey-600 text-body-400"}>{leftText}</Text>
      <Text
        className={`text-body-400 ${isPrice ? "text-grey-900" : "text-grey-600"}`}>
        {rightText}
      </Text>
    </View>
  );
};
