import {
  CustomTransactionItem,
  useCustomTransactionItemsDatatable,
} from "shared/components";
import { formatMoneyFromInteger, useBreakpoint } from "shared/lib";

import { Datatable } from "@/modules/common/datatable/Datatable";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { useTransactionActions } from "@/modules/company/billing/transactions/useTransactionActions";

export const TransactionsCreatePageItemsDatatable = ({
  items,
  setItems,
}: {
  items: CustomTransactionItem[];
  setItems: (items: CustomTransactionItem[]) => void;
}) => {
  const { md: isDesktop } = useBreakpoint();

  const {
    showCustomTransactionCreateItemSheet,
    showCustomTransactionUpdateItemSheet,
  } = useTransactionActions();

  const showCreateForm = () => {
    showCustomTransactionCreateItemSheet({
      addItem: item => setItems([...items, item]),
    });
  };

  const showUpdateForm = (item: CustomTransactionItem) => {
    showCustomTransactionUpdateItemSheet({
      item,
      updateItem: updatedItem => {
        const updatedItems = items.map(i =>
          i.id === item.id ? updatedItem : i,
        );
        setItems(updatedItems);
      },
    });
  };

  const deleteOne = async (item: CustomTransactionItem) => {
    setItems(items.filter(i => i.id !== item.id));
  };

  const renderDescription = (item: CustomTransactionItem) => (
    <p className={"text-body-600 text-grey-900"}>{item.description}</p>
  );

  const renderUnitPrice = (item: CustomTransactionItem) => (
    <p className={"text-body-600 text-grey-900"}>
      {formatMoneyFromInteger(item.price)}
    </p>
  );

  const { datatable } = useCustomTransactionItemsDatatable({
    Datatable,
    items,
    showCreateForm,
    showUpdateForm,
    deleteOne,
    showRowActions: isDesktop,
    renderDescription,
    renderUnitPrice,
    renderMobile: item => {
      return (
        <div className={"-mb-1 flex flex-col space-y-1"}>
          <div className={"flex items-center justify-between"}>
            <div>{renderDescription(item)}</div>

            <div className={"-mr-1"}>
              <ActionMenu
                trigger={
                  <IconButton
                    variant="standard"
                    icon={"ellipsisHorizontal"}
                    size="sm"
                  />
                }
                items={[
                  {
                    title: "Edit",
                    onClick: () => showUpdateForm(item),
                  },
                  {
                    title: "Delete",
                    onClick: () => deleteOne(item),
                  },
                ]}
              />
            </div>
          </div>

          <div className={"flex items-center justify-between"}>
            <div className={"text-body-400 text-grey-600"}>Unit Price</div>
            <div className={"text-body-400 text-grey-600"}>
              {formatMoneyFromInteger(item.price)}
            </div>
          </div>

          {item.discount ? (
            <div className={"flex items-center justify-between"}>
              <div className={"text-body-400 text-grey-600"}>Discounts</div>
              <div className={"text-body-400 text-grey-600"}>
                -{formatMoneyFromInteger(item.discount)}
              </div>
            </div>
          ) : null}

          {item.taxRate ? (
            <div className={"flex items-center justify-between"}>
              <div className={"text-body-400 text-grey-600"}>
                {item.taxRate.label}
              </div>
              <div className={"text-body-400 text-grey-600"}>
                -{formatMoneyFromInteger(item.taxAmount)}
              </div>
            </div>
          ) : null}

          <div className={"flex items-center justify-between"}>
            <div className={"text-body-400 text-grey-900"}>Amount</div>
            <div className={"text-body-400 text-grey-900"}>
              {formatMoneyFromInteger(item.total)}
            </div>
          </div>
        </div>
      );
    },
  });

  return datatable;
};
