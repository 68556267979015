export interface FetchRetryParams {
  retries?: number;
  retryDelay?: number;
}

function validateWithRetriesParams(
  params: FetchRetryParams,
  defaults: Required<FetchRetryParams>,
): Required<FetchRetryParams> {
  return {
    retries: params.retries ?? defaults.retries,
    retryDelay: params.retryDelay ?? defaults.retryDelay,
  };
}

export function withRetries<F extends (...args: unknown[]) => Promise<unknown>>(
  fetchFunc: F,
  params: FetchRetryParams = {},
): (
  input?: Parameters<F>[0],
  init?: Parameters<F>[1] & FetchRetryParams,
) => ReturnType<F> {
  const defaults = validateWithRetriesParams(params, {
    retries: 3,
    retryDelay: 500,
  });

  return function (
    input: Parameters<F>[0],
    init?: Parameters<F>[1] & FetchRetryParams,
  ): ReturnType<F> {
    const sParams = validateWithRetriesParams(
      {
        retries: init?.retries,
        retryDelay: init?.retryDelay,
      },
      defaults,
    );

    return new Promise(function (resolve, reject): void {
      const extendedFetch = function (attempt: number): void {
        fetchFunc(input, init)
          .then(function (response: unknown): void {
            resolve(response);
          })
          .catch(function (error: Error): void {
            if (attempt < sParams.retries) {
              retry(attempt);
            } else {
              reject(error);
            }
          });
      };

      function retry(attempt: number): void {
        setTimeout(function (): void {
          extendedFetch(++attempt);
        }, sParams.retryDelay);
      }

      extendedFetch(0);
    }) as ReturnType<F>;
  };
}
