import { colors } from "../../../../colors";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ButtonComponent, IconComponent } from "../../../interfaces";

export const JoinWaitingListSuccessPage = ({
  Button,
  Icon,
  goToDashboard,
  goToEnrollments,
}: {
  Button: ButtonComponent;
  Icon: IconComponent;
  goToDashboard: () => void;
  goToEnrollments: () => void;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-col items-center"}>
      <View className={"flex flex-col items-center p-2"}>
        <Icon name={"checkmarkCircle"} color={colors.green["600"]} size={24} />
        <Text className={"text-heading6-600 pt-3"}>
          You’re on the waiting list.
        </Text>
      </View>
      <View className={"flex flex-col p-2"}>
        <Button
          variant={"brand"}
          text={"Enroll for classes"}
          size={"lg"}
          onClick={goToEnrollments}
        />
        <View className={"mt-4"}>
          <Button
            variant={"secondary"}
            text={"Go to dashboard"}
            size={"lg"}
            onClick={goToDashboard}
          />
        </View>
      </View>
    </View>
  );
};
