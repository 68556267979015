import { useState } from "react";

import { ClassColor } from "@justraviga/classmanager-sdk";

import { cn, colorPaletteColors, ColorPaletteProps } from "shared/lib";

import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";

export const StatefulColorPalette = (props: ColorPaletteProps) => {
  const [value, setValue] = useState<ClassColor>(
    props.value ?? ClassColor.Grey,
  );

  const handleChange = (colorId: string) => {
    setValue(colorId as ClassColor);
    props.onChange?.(colorId);
  };

  return <ColorPalette {...props} value={value} onChange={handleChange} />;
};

export const ColorPalette = ({
  label,
  required,
  description,
  value,
  onBlur,
  onChange,
  name,
}: ColorPaletteProps) => {
  const colors = Object.entries(colorPaletteColors) as [ClassColor, string][];

  return (
    <Label text={label} required={required} useNativeLabel={false}>
      <div className="flex flex-row flex-wrap gap-4" data-name={name}>
        {colors.map(([colorId, hexValue]) => (
          <button
            key={colorId}
            type="button"
            disabled={colorId === value}
            className={cn(
              "h-6 w-6 rounded border",
              colorId === value
                ? "border-grey-900"
                : "border-transparent hover:border-overlay-grey-lighter",
            )}
            style={{ backgroundColor: hexValue }}
            onClick={e => {
              e.stopPropagation();
              onChange?.(colorId);
              onBlur?.();
            }}
          />
        ))}
      </div>
      <InputDescription description={description} />
    </Label>
  );
};
