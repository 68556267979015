import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Avatar, AvatarProps } from "@/modules/common/ui/avatar/Avatar";

const AvatarOptions: Option<AvatarProps>[] = [
  {
    title: "Base",
    props: [
      {
        src: "https://picsum.photos/200",
      },
    ],
  },
  {
    title: "Without src",
    props: [{}],
  },
  {
    title: "Without src but with name",
    props: [
      {
        name: "CM",
        size: "sm",
      },
      {
        name: "CM",
      },
      {
        name: "CM",
        size: "lg",
      },
      {
        name: "CM",
        size: "xl",
      },
    ],
  },
  {
    title: "Sizes",
    props: [
      {
        src: "https://picsum.photos/200",
        size: "sm",
      },
      {
        src: "https://picsum.photos/200",
        size: "md",
      },
      {
        src: "https://picsum.photos/200",
        size: "lg",
      },
      {
        src: "https://picsum.photos/200",
        size: "xl",
      },
      {
        size: "sm",
      },
      {
        size: "md",
      },
      {
        size: "lg",
      },
      {
        size: "xl",
      },
    ],
  },
];

export const AvatarPage = () => {
  return (
    <DocWrapper title={"Avatar"} component={Avatar} options={AvatarOptions} />
  );
};
