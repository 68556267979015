import React, { useState } from "react";

import { cn, colors, IconName } from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Card } from "@/modules/common/ui/card/Card";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { NoContent } from "@/modules/company/members/student/detail/NoContent";

interface DetailCardProps {
  title?: string;
  header?: React.ReactNode;
  headerClass?: string;
  children?: React.ReactNode;
  containerClass?: string;
  showPlaceholder?: boolean;
  headerButton?: React.ReactNode;
  footerButton?: React.ReactNode;
  footer?: React.ReactNode;
  isFoldable?: boolean;
  icon?: IconName;
  onClick?: () => void;
}

export const DetailCard = ({
  title,
  header,
  headerClass,
  children,
  showPlaceholder,
  headerButton,
  footerButton,
  footer,
  isFoldable = false,
  icon,
  onClick,
}: DetailCardProps) => {
  const [folded, setFolded] = useState(false);
  return (
    <Card
      className={cn(
        "flex h-fit w-full max-w-full flex-col space-y-1 p-2",
        onClick && "cursor-pointer hover:bg-grey-100",
      )}
      onClick={onClick}>
      <div
        className={cn(
          "flex flex-row items-center justify-between p-2",
          headerClass,
        )}>
        <div className={"flex flex-row items-center space-x-2"}>
          {isFoldable && (
            <div className="-ml-1">
              <IconButton
                onClick={() => setFolded(!folded)}
                variant="standard"
                icon={folded ? "chevronDown" : "chevronUp"}
                size="xs"
                aria-label={folded ? "Open" : "Close"}
              />
            </div>
          )}

          {icon && <Icon name={icon} size={16} color={colors.grey["600"]} />}

          {title && (
            <span className={"text-label-400 text-grey-600"}>{title}</span>
          )}
          {header}
        </div>
        <div className={"-m-2"}>{headerButton}</div>
      </div>

      {!folded && showPlaceholder && (
        <div className={"p-2 pb-4"}>
          <NoContent />
        </div>
      )}
      {!folded && !showPlaceholder && children}
      {footer}
      {footerButton}
    </Card>
  );
};
