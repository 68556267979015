import { Cell, flexRender } from "@tanstack/react-table";

export const DatatableMobileCell = <ModelDto,>({
  cell,
}: {
  cell: Cell<ModelDto, unknown>;
}) => {
  return (
    <td className={`px-5 py-3 last:w-8 last:pr-0 only:w-full only:pr-5`}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};
