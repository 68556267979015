import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { TextInput, TextInputProps } from "@/modules/common/form/TextInput";

const options: Array<Option<TextInputProps>> = [
  {
    title: "States",
    props: [
      { label: "Enabled" },
      { disabled: true, label: "Disabled" },
      { value: "Some value", error: true, label: "Error" },
    ],
  },
  {
    title: "Labels, Descriptions & Placeholders",
    props: [
      { label: "Name", description: "Your full name", required: true },
      {
        label: "Field with description and error",
        value: "EX",
        description: "Must be at least 5 characters",
        error: true,
      },
      { label: "With placeholder text", placeholder: "Some placeholder text" },
    ],
  },
  {
    title: "Icons & Prefixes",
    props: [
      {
        prependIcon: "mailOutline",
        label: "Prepend Icon",
        value: "Some value",
      },
      {
        appendIcon: "mailOutline",
        label: "Append Icon",
        value: "Some value",
      },
      { prefix: "£", label: "With prefix", value: "Some value" },
      {
        prependIcon: "mailOutline",
        prefix: "£",
        label: "With icon and prefix",
        value: "Some value",
      },
    ],
  },
  {
    title: "Different sizes",
    props: [
      {
        label: "Small",
        height: "sm",
      },
      {
        label: "Default size",
      },
      {
        label: "Medium",
        height: "md",
      },
    ],
  },
  {
    title: "Multiline",
    props: [
      {
        placeholder: "default size",
        numberOfLines: 1,
      },
      {
        placeholder: "5 rows",
        numberOfLines: 5,
      },
      {
        placeholder: "10 rows",
        numberOfLines: 10,
      },
    ],
  },
];

export const TextInputPage = () => {
  return (
    <DocWrapper title="Text Input" component={TextInput} options={options} />
  );
};
