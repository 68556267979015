import { UpdateUserEmailOrPasswordRequest } from "@justraviga/classmanager-sdk/dist/models/UpdateUserEmailOrPasswordRequest";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = UpdateUserEmailOrPasswordRequest;

export const userEmailUpdateFormDefinition = () => {
  const builder = new FormDefinitionBuilder<Schema>()
    .group("Verify your account", ["currentPassword"])
    .group("Change email", ["email"])
    .text("currentPassword", {
      label: "Current password",
      required: true,
      type: "password",
    })
    .text("email", {
      label: "New email address",
      required: true,
      type: "email",
    });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeUserEmailUpdateRequest =
  ({ api }: { api: Api }) =>
  (formData: Schema) => {
    return api.users.updateUserEmailOrPassword({
      updateUserEmailOrPasswordRequest: formData,
    });
  };
