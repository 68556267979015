import { CodeProps, DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Tooltip, TooltipProps } from "@/modules/common/overlays/Tooltip";
import { Button } from "@/modules/common/ui/button/Button";

const options: Array<Option<TooltipProps>> = [
  {
    title: "default",
    props: [
      {
        trigger: <Button text={"hover for tooltip"} variant="brand" />,
      },
    ],
    children: <span>Hi, I'm a tooltip!</span>,
  },
];

export const TooltipPage = () => {
  const getTriggerCode = (props: TooltipProps[]): CodeProps<TooltipProps>[] => {
    return props.map(function (prop: TooltipProps) {
      return {
        ...prop,
        trigger: "<Button text={'hover for tooltip'} />",
      };
    });
  };
  return (
    <>
      <DocWrapper
        title="Tooltip"
        component={Tooltip}
        options={options}
        transformProps={getTriggerCode}
      />
    </>
  );
};
