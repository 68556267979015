import { Banner } from "@/modules/common/ui/Banner";

export const HowToUseWaiversBanner = () => (
  <>
    <Banner.title>How to use</Banner.title>
    <Banner.content>
      Here you can customize your company's Terms & conditions and Privacy
      policy. Additional waivers can be created for members to digitally sign
      upon registration, tailored to your organization (e.g medical consent,
      photo/video release)
    </Banner.content>
  </>
);
