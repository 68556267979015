import { SortSchema, WaiverDto } from "@justraviga/classmanager-sdk";

import { buildWaiverToAgreementMap } from "./buildWaiverToAgreementMap";
import { combineWaiversAndAgreements } from "./combineWaiversAndAgreements";
import { WaiverEntity, waiverEntityFactory } from "./waiver";
import { UseApi } from "../../components/apiQueryFactory";
import { WaiverMemberType } from "../../memberUtils";
import { mapDtoToEntity } from "../entityListUtils";

export function useSharedWaiversMembersListPage(
  useApi: UseApi,
  memberId: string,
  source: WaiverMemberType,
) {
  const { data: student, isLoading: isStudentLoading } = useApi(
    "getStudent",
    {
      id: memberId,
    },
    {
      enabled: source === "Students",
    },
  );

  const familyId = source === "Families" ? memberId : student?.familyId || "";

  const { data: family, isLoading: isFamilyLoading } = useApi(
    "getFamily",
    {
      id: familyId,
    },
    {
      enabled: source === "Families",
    },
  );

  const waitForStudent = source === "Students" && !student;

  const { data: agreements, isLoading: areAgreementsLoading } = useApi(
    "listAgreement",
    {
      where: {
        familyId: {
          equals: familyId,
        },
      },
    },
    {
      enabled: !waitForStudent,
    },
  );

  const agreementMap = buildWaiverToAgreementMap(agreements);

  const { data: waivers, isLoading: areWaiversLoading } = useApi("listWaiver", {
    sort: {
      createdAt: SortSchema.Asc,
    },
  });

  const isLoading =
    isFamilyLoading ||
    isStudentLoading ||
    areAgreementsLoading ||
    areWaiversLoading;

  const isEmpty = () => !waivers?.data || waivers.data.length === 0;

  const mappedWaivers = waivers
    ? combineWaiversAndAgreements(
        mapDtoToEntity<WaiverDto, WaiverEntity>(waivers, waiverEntityFactory),
        agreementMap,
      )
    : undefined;

  return {
    waivers: mappedWaivers,
    isLoading,
    familyName: student?.lastname || family?.name,
    isEmpty,
  };
}
