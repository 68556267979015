import { useEffect } from "react";

import clarity from "@microsoft/clarity";

import { useAuthState } from "shared/components";
import { getFullName } from "shared/lib";

import { appEnvironment, appVersion } from "@/lib/appVersion";
import { clarityEnabled } from "@/lib/clarity/clarityEnabled";

export const ClarityIntegration = () => {
  const { user, account } = useAuthState();

  useEffect(() => {
    if (clarityEnabled()) {
      if (account && user) {
        try {
          clarity.identify(
            account.id, // account id
            undefined, // auth token
            window.location.href, // url
            getFullName(user), // user firstname lastname (account id)
          );

          clarity.setTag("environment", appEnvironment);
          clarity.setTag("appVersion", appVersion);
          clarity.setTag("userId", user.id);
          clarity.setTag("accountId", account.id);
          clarity.setTag("accountRole", account.role);

          if (account.company) {
            clarity.setTag("companyId", account.company.id);
            clarity.setTag("companyCountry", account.company.countryCode);
          }
        } catch (e) {
          // we don't want to the apps to crash if this fails
          console.error(e);
        }
      }
    }
  }, [user, account]);

  return null;
};
