import { BillDto } from "@justraviga/classmanager-sdk";

import { Transaction } from "./Transaction";
import { useBreakpoint } from "../../../../breakpoints";
import { cn } from "../../../../cssUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../../../interfaces";

export const TransactionList = ({
  transactions,
  HorizontalSeparator,
}: {
  transactions: BillDto[];
  HorizontalSeparator: HorizontalSeparatorComponent;
}) => {
  const { View } = useGenericComponents();
  const { md } = useBreakpoint();

  return (
    <View
      className={cn("flex flex-col", { "space-y-10": md, "space-y-8": !md })}>
      {transactions?.map((transaction, index) => (
        <View key={transaction.description + index}>
          <Transaction
            transaction={transaction}
            HorizontalSeparator={HorizontalSeparator}
          />
        </View>
      ))}
    </View>
  );
};
