import { createContext } from "react";

import type { WeekDay } from "@justraviga/classmanager-sdk";

import { isString } from "../../stringUtils";

// Let's have a string union rather than having to explicitly reference the WeekDay enum everywhere
export type DayOfWeek = `${WeekDay}`;

export interface CourseSearchValues {
  ages: number[];
  days: DayOfWeek[];
  locationIds: string[];
  query: string;
  seasonIds: string[];
}

export interface CourseSearchContext {
  filterCount: number;
  searchValues: CourseSearchValues;
  // Unique key for the current search values.
  // Can be helpful to use as a React key for any components that need to re-render when search values change.
  searchKey: string;
}

export const CourseSearchContext = createContext(
  undefined as unknown as CourseSearchContext,
);

export const routeParamsToSearchValues = (params: {
  ages?: string[];
  days?: string[];
  locationIds?: string[];
  query?: string;
  seasonIds?: string[];
}): CourseSearchValues => {
  return {
    ages: (isString(params.ages) ? [params.ages] : params.ages ?? []).map(
      Number,
    ),
    days: (isString(params.days)
      ? [params.days]
      : params.days ?? []) as DayOfWeek[],
    locationIds: isString(params.locationIds)
      ? [params.locationIds]
      : params.locationIds ?? [],
    query: isString(params.query) ? params.query : "",
    seasonIds: isString(params.seasonIds)
      ? [params.seasonIds]
      : params.seasonIds ?? [],
  };
};

export const makeCourseSearchValues = (): CourseSearchValues => ({
  ages: [],
  days: [],
  locationIds: [],
  query: "",
  seasonIds: [],
});

export const courseSearchValuesToQueryParams = (
  values: CourseSearchValues,
) => ({
  ...values,
  ages: values.ages.map(String),
  query: values.query.trim().length > 0 ? values.query.trim() : undefined,
});
