import { SeasonDto, TaxRateDto } from "@justraviga/classmanager-sdk";

import { moneyIntegerToFloat } from "../../moneyUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeSeasonUpdateRequest,
  useSeasonFormDefinition,
} from "../formDefinitions/seasonForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import {
  DefaultEntityActions,
  EntityActions,
} from "../useDefaultEntityActions";

export type SeasonImplementedActions = Omit<
  EntityActions<Model>,
  "deleteMany" | "archiveMany" | "unarchiveMany"
> & {
  showPricingSchemeUpdateForm: (entity: Model) => void;
  showPreview: (entity: Model) => void;
};

type Model = SeasonDto;

export const useSharedSeasonActions = (
  defaultActions: DefaultEntityActions<Model>,
  taxRates: TaxRateDto[],
) => {
  const { GenericForm } = useGenericComponents();
  const { api } = getPlatformFunctions();

  const updateFormDefinition = useSeasonFormDefinition(taxRates);

  return {
    showUpdateForm: (entity: Model) => {
      entity.registrationFee = moneyIntegerToFloat(entity.registrationFee);
      entity.maxRegistrationFee = moneyIntegerToFloat(
        entity.maxRegistrationFee ?? 0,
      );

      return defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeSeasonUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={() => updateFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      });
    },

    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.seasons.deleteSeason({ id: entity.id }),
      ),

    archive: (entity: Model) =>
      defaultActions.archive(() =>
        api.seasons.archiveSeason({ id: entity.id }),
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.seasons.unarchiveSeason({ id: entity.id }),
      ),
  };
};
