import { useEffect, useState } from "react";

import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";
import { TransactionInfo, TransactionPreviewContent } from "../transactions";

export const CreditNoteDetails = ({
  setTitle,
  transaction,
  originalTransaction,
}: {
  setTitle: (title: string) => void;
  transaction: AggregateTransactionDto;
  originalTransaction: AggregateTransactionDto;
}) => {
  const [showMore, setShowMore] = useState(false);
  const { Button, View } = useGenericComponents();
  const { setShouldShowFooter } = useFormActions();

  useEffect(() => {
    // Using setTimeout is a bit of a hack, but seems necessary to get mobile's FullScreenModal title to change
    setTimeout(() => {
      setTitle("Transaction details");
    });
  }, [setTitle]);

  useEffect(() => {
    setShouldShowFooter(false);
  }, [setShouldShowFooter]);

  return (
    <View>
      <TransactionPreviewContent
        transaction={transaction}
        isFamilyPortal={false}
      />

      <View>
        <View className="mt-4 -ml-2">
          <Button
            variant="subtle"
            onClick={() => {
              setShowMore(v => !v);
            }}
            rightIcon={showMore ? "chevronUp" : "chevronDown"}
            text="More details"
            size="xs"
          />
        </View>
        {showMore && (
          <View className="mt-4">
            <TransactionInfo
              transaction={originalTransaction}
              title="Original transaction"
            />
          </View>
        )}
      </View>
    </View>
  );
};
