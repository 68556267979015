import { Button } from "@/modules/common/ui/button/Button";

export const DatatableFiltersSheetFooter = ({
  onApply,
  onClear,
}: {
  onApply: () => void;
  onClear: () => void;
}) => {
  return (
    <div
      className={
        "flex flex-col-reverse justify-between gap-4 px-5 py-4 md:flex-row"
      }>
      <Button
        text={"Clear"}
        variant={"secondary"}
        role={"filter-clear-button"}
        className={"no-underline"}
        onClick={onClear}
      />
      <Button
        role="filter-apply-button"
        text={"Apply"}
        variant={"brand"}
        onClick={onApply}
      />
    </div>
  );
};
