import {
  CustomFieldResponseDto,
  CustomFieldType,
  UpdateCustomFieldResponsesRequest,
  UpdateCustomFieldResponsesRequestFieldsInner,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Dto = CustomFieldResponseDto;
type Schema = UpdateCustomFieldResponsesRequest;

export const getCustomFieldResponsesDefaultValues = (
  customFieldResponses: Dto[],
) => {
  const defaultValues: { [key: string]: unknown } = {};

  customFieldResponses.forEach(response => {
    if (response.value == null) {
      defaultValues[response.customField.id] = null;
    } else {
      defaultValues[response.customField.id] = response.value;
    }
  });

  return defaultValues;
};

export const makeCustomFieldResponsesFormDefinition =
  ({ customFieldResponses }: { customFieldResponses: Dto[] }) =>
  () => {
    const builder = new FormDefinitionBuilder<Schema>();
    customFieldResponses.forEach(response => {
      switch (response.customField.type) {
        case CustomFieldType.Bool:
          // @ts-expect-error - We know these are dynamic
          builder.switch(response.customField.id, {
            label: response.customField.title,
          });
          break;
        case CustomFieldType.Date:
          // @ts-expect-error - We know these are dynamic
          builder.date(response.customField.id, {
            label: response.customField.title,
          });
          break;
        case CustomFieldType.Number:
          // @ts-expect-error - We know these are dynamic
          builder.decimal(response.customField.id, {
            label: response.customField.title,
            decimalPlaces: 2,
          });
          break;
        case CustomFieldType.Text:
          // @ts-expect-error - We know these are dynamic
          builder.text(response.customField.id, {
            label: response.customField.title,
            numberOfLines: 1,
          });
          break;
        case CustomFieldType.Textarea:
          // @ts-expect-error - We know these are dynamic
          builder.text(response.customField.id, {
            label: response.customField.title,
            numberOfLines: 4,
          });
          break;
        case CustomFieldType.Select:
          // @ts-expect-error - We know these are dynamic
          builder.select(response.customField.id, {
            label: response.customField.title,
            data:
              response.customField.options?.map(option => ({
                label: option,
                value: option,
              })) ?? [],
          });
          break;
        case CustomFieldType.Multiselect:
          // @ts-expect-error - We know these are dynamic
          builder.multiSelect(response.customField.id, {
            label: response.customField.title,
            data:
              response.customField.options?.map(option => ({
                label: option,
                value: option,
              })) ?? [],
          });
          break;
      }
    });

    return builder.getDefinition();
  };

export const makeCustomFieldResponsesRequest =
  ({ api, entityId }: { api: Api; entityId: string }) =>
  (formData: Schema) => {
    const values: UpdateCustomFieldResponsesRequestFieldsInner[] =
      Object.entries(formData).map(([customFieldId, value]) => {
        return {
          id: customFieldId,
          value,
        };
      });

    const request: Schema = {
      entityId,
      fields: values,
    };

    return api.customFields.updateCustomFieldResponses({
      updateCustomFieldResponsesRequest: request,
    });
  };
