import { useSharedCompanySettingsActions } from "shared/components";
import { SettingItemKey } from "shared/lib";

export const mapSettingItemsToActions = ({
  actions,
}: {
  actions: ReturnType<typeof useSharedCompanySettingsActions> & {
    showTaxSettingsPage: () => void;
  };
}) => {
  const links: Record<SettingItemKey, () => void> = {
    companyInfo: () => actions.showCompanyUpdateForm(),
    companyAddress: () => actions.showCompanyAddressForm(),
    lookAndFeel: () => actions.showLookAndFeelUpdateForm(),
    languageAndCurrency: () => actions.showLanguageAndCurrencyUpdateForm(),
    timeFormat: () => actions.showTimezoneAndTimeFormatUpdateForm(),
    startDayOfWeek: () => actions.showStartDayOfWeekForm(),
    ageCutOff: () => actions.showAgeCutOffUpdateForm(),
    trialSettings: () => actions.showTrialsForm(),
    taxSettings: () => actions.showTaxSettingsPage(),
    automatedTuitionSettings: () => actions.showAutomatedTuitionSettingsForm(),
  };

  return links;
};
