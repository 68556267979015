import { LoginDto, UserDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import { useAuthState } from "../AuthStateProvider";
import {
  makeUserEmailUpdateRequest,
  userEmailUpdateFormDefinition,
} from "../formDefinitions/userEmailUpdateForm";
import {
  makeUserPasswordUpdateRequest,
  userPasswordUpdateFormDefinition,
} from "../formDefinitions/userPasswordUpdateForm";
import { useGenericComponents } from "../GenericComponentsProvider";

type Dto = UserDto;

export const useSharedAccountActions = () => {
  const { setUser, token } = useAuthState();
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const defaultActions = usePlatformEntityActions<Dto>({
    entity: "user",
  });
  return {
    showEmailUpdateForm: () =>
      defaultActions.showUpdateForm({
        title: "Edit account email",
        form: (
          <GenericForm
            apiRequest={makeUserEmailUpdateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={userEmailUpdateFormDefinition}
            onSuccess={data => {
              defaultActions.onUpdateSuccess();
              /**
               * When updating email address, we don't get a new token,
               * so we'll pass in the current token from the auth state.
               */
              setUser(data as UserDto, token as string);
            }}
          />
        ),
      }),

    showPasswordUpdateForm: () =>
      defaultActions.showUpdateForm({
        title: "Reset password",
        form: (
          <GenericForm
            apiRequest={makeUserPasswordUpdateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={userPasswordUpdateFormDefinition}
            onSuccess={data => {
              defaultActions.onUpdateSuccess();
              /**
               * When updating password, we get a new token, so
               * we need to take that from the API and pass it into
               * the auth state.
               */
              const { user, token } = data as LoginDto;
              setUser(user, token);
            }}
          />
        ),
      }),
  };
};
