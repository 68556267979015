import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { colors } from "../../colors";
import { cn } from "../../cssUtils";
import { formatDate } from "../../intlFormatter";
import { enumLabel } from "../../translateUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export const TransactionInfo = ({
  transaction,
  title,
}: {
  transaction: AggregateTransactionDto;
  title?: string;
}) => {
  const { Text, View, Icon } = useGenericComponents();
  return (
    <View className="flex flex-col space-y-2">
      <View className="flex flex-row items-center space-x-2 flex-1">
        <Icon name="documentTextOutline" color={colors.grey["900"]} />
        <Text
          className="text-body-600 font-semibold text-grey-900"
          truncate={true}>
          {title || "Transaction information"}
        </Text>
      </View>

      <InfoRow
        left="Transaction ID"
        right={transaction.transaction.id}
        wrapOnSmallScreens={true}
      />
      <InfoRow
        left="Type"
        right={enumLabel("transactionType", transaction.transaction.type)}
      />
      <InfoRow
        left="Date"
        right={formatDate(transaction.transaction.date, "dayMonthYear")}
      />
    </View>
  );
};

const InfoRow = ({
  left,
  right,
  wrapOnSmallScreens = false,
}: {
  left: string;
  right: string;
  wrapOnSmallScreens?: boolean;
}) => {
  const { Text, View } = useGenericComponents();
  return (
    <View
      className={cn("flex flex-row items-center justify-between", {
        "flex-wrap": wrapOnSmallScreens,
      })}>
      <View>
        <Text className="text-body-400 text-grey-600">{left}</Text>
      </View>

      <View>
        <Text className="text-body-400 text-grey-900">{right}</Text>
      </View>
    </View>
  );
};
