import { cva } from "class-variance-authority";

export const baseVariants = cva(
  "fixed flex flex-col z-50 gap-4 bg-white shadow-lg transition ease-out data-[state=open]:animate-in data-[state=closed]:animate-out duration-400",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b h-fit data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 h-fit border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom rounded-t-3xl gap-0",
        right:
          "inset-y-0 right-0 h-full w-[512px] max-w-full md:border-l border-grey-300 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right",
      },
      dialog: {
        true: "left-[50%] top-[50%] h-fit max-h-[calc(100vh-128px)] z-50 w-[480px] max-w-lg translate-x-[-50%] translate-y-[-50%] gap-8 border bg-white p-5 sm:py-10 sm:px-8 shadow-lg duration-200  data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
      },
    },
  },
);
