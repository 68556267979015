import { useGenericComponents } from "../../../GenericComponentsProvider";
import { IconButtonComponent } from "../../../interfaces";

interface RemoveEnrolmentButtonProps {
  onClick: () => void;
  IconButton: IconButtonComponent;
}

export const RemoveFromBasketButton = ({
  onClick,
  IconButton,
}: RemoveEnrolmentButtonProps) => {
  const { View } = useGenericComponents();
  return (
    <View className={"flex flex-1 justify-end"}>
      <IconButton
        icon={"trashOutline"}
        variant="secondary-outline"
        onClick={onClick}
        size="lg"
      />
    </View>
  );
};
