import { PropsWithChildren } from "react";

import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { InvalidHeader } from "@/modules/auth/common/InvalidHeader";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { Button } from "@/modules/common/ui/button/Button";
import { LoadingSpinnerGrey } from "@/modules/common/ui/LoadingSpinnerGrey";
import { Router } from "@/routing/router";

interface InvitationPageProps extends PropsWithChildren {
  isLoading: boolean;
  hasError: boolean;
}

export const InvitationBasePage = ({
  isLoading,
  hasError,
  children,
}: InvitationPageProps) => {
  if (hasError) {
    return (
      <NonAuthLayout>
        <AuthPageCard
          showTerms={false}
          header={<InvalidHeader />}
          title={"Invitation link is invalid"}
          subtitle={
            "This link is invalid, it may have expired or be unavailable. Please contact your organization to request a new one."
          }
          content={
            <div className="flex w-full justify-center">
              <Button
                variant="tertiary"
                text="Go to log in"
                onClick={() => Router.push("Login")}
              />
            </div>
          }
        />
      </NonAuthLayout>
    );
  }

  if (isLoading) {
    return (
      <NonAuthLayout>
        <LoadingSpinnerGrey />
      </NonAuthLayout>
    );
  }

  return <NonAuthLayout branded>{children}</NonAuthLayout>;
};
