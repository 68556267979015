import { CodeBlock } from "react-code-blocks";

import { Toaster, ToasterProps } from "@/modules/admin/docs/components/Toaster";
import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";

const options: Option<ToasterProps>[] = [
  {
    title: "Undo",
    props: [
      {
        id: "undo-invoices",
        actionLabel: "Undo",
        title: "Invoices were approved",
        description: "Invoice #123, #456, #789 were approved.",
        triggerLabel: "Show Undo Toast",
        action: () => {
          console.log("Undo(cancel) invoices...");
        },
      },
    ],
  },
  {
    title: "Info",
    props: [
      {
        id: "info-invoices",
        title: "Invoice emails were sent",
        description: "Invoice #123, #456, #789 were sent.",
        triggerLabel: "Show Info Toast",
      },
    ],
  },
];

export const ToastPage = () => {
  const intro = (
    <div className="my-5 border-2 p-5">
      <div className="mb-5 flex items-center justify-between">
        <h1 className="text-16">Trigger toast programmatically</h1>
      </div>
      <div>
        <code>
          <CodeBlock
            as={undefined}
            forwardedAs={undefined}
            codeContainerStyle={{
              borderRadius: "0.5rem",
              padding: "1.2rem",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
            text={
              "import { useToast } from '@/modules/common/toast/Toast';" +
              "\n" +
              "const toaster = useToast();\n" +
              "\n" +
              "toaster.toast({\n" +
              'title: "Invoices were approved",\n' +
              'description: "Invoice #123, #456, #789 were approved.",\n' +
              'actionLabel: "Undo",\n' +
              "action: () => {\n" +
              '    console.log("Undo(cancel) invoices..."); \n' +
              "}"
            }
            showLineNumbers={false}
            language={"typescript"}
            wrapLongLines={true}
          />
        </code>
      </div>
    </div>
  );

  return (
    <DocWrapper
      title={"Toast"}
      intro={intro}
      component={Toaster}
      options={options}
      importPath={"{Toaster} from '@/modules/common/toast/Toaster'"}
    />
  );
};
