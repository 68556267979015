import { useState } from "react";

import {
  CustomTransactionBlueprint,
  TaxRateDto,
} from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { Api } from "../../api";
import { toUtcIsoDateString } from "../../dateUtils";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { moneyFloatToInteger, moneyIntegerToFloat } from "../../moneyUtils";
import { UseApi } from "../apiQueryFactory";
import { useAuthState } from "../AuthStateProvider";
import { useFormActions } from "../FormActionsProvider";
import { useCurrencySymbol } from "../useCurrencySymbol";

type Schema = {
  description: string;
  price: number;
  discount?: number;
  taxRateId?: string;
};

export type CustomTransactionItem = {
  id: string;
  description: string;
  price: number;
  discount: number;
  taxRate?: TaxRateDto;
  taxAmount: number;
  total: number;
};

interface CustomTransactionItemFormDefinition {
  useApi: UseApi;
  currentItem?: CustomTransactionItem;
  close: () => void;
  saveItem: (item: CustomTransactionItem) => void;
}

export const useCustomTransactionAddItemDefinition = ({
  useApi,
  currentItem,
  close,
  saveItem,
}: CustomTransactionItemFormDefinition) => {
  const { account } = useAuthState();
  const { data: taxRates } = useApi("listTaxRate", { selectAll: true });

  const hasTax = account?.company?.settings.tax.enabled ?? false;
  const defaultTax = hasTax
    ? taxRates?.data.find(taxRate => taxRate.isDefault)
    : undefined;
  const currency = useCurrencySymbol();

  const [item, setItem] = useState<CustomTransactionItem>({
    id: "",
    description: "",
    price: 0,
    discount: 0,
    taxRate: undefined,
    taxAmount: 0,
    total: 0,
  });

  const [editingTaxRate, setEditingTaxRate] = useState(false);

  const [editingDiscount, setEditingDiscount] = useState(
    (currentItem?.discount ?? 0) > 0,
  );
  const {
    setCreateButtonText,
    setAllowCreateAdditional,
    shouldCloseOnSuccess,
  } = useFormActions();

  if (currentItem) {
    setAllowCreateAdditional(false);
    setCreateButtonText("Update");
  }

  const success = () => {
    saveItem({
      ...item,
      id: generateIdForItem(item),
      price: moneyFloatToInteger(item.price),
      discount: moneyFloatToInteger(item.discount),
      taxAmount: moneyFloatToInteger(item.taxAmount),
      total: moneyFloatToInteger(item.total),
    });

    if (shouldCloseOnSuccess()) {
      close();
    }
  };

  const formHook = new FormDefinitionBuilder<Schema>()
    .group("Item details", ["description", "price", "discount", "taxRateId"])
    .conditional(
      ["description", "price", "discount", "taxRateId"],
      ["description", "price", "discount", "taxRateId"],
      v => {
        const newPrice = (v.price as number) ?? 0;
        const newDiscount = (v.discount as number) ?? 0;
        let newTaxAmount = 0;
        let taxRateChanged = false;

        const taxRate = hasTax
          ? v.taxRateId
            ? taxRates?.data?.find(taxRate => taxRate.id === v.taxRateId)
            : taxRates?.data?.find(taxRate => taxRate.isDefault)
          : undefined;
        if (taxRate) {
          newTaxAmount = Math.max(
            0,
            ((newPrice - newDiscount) * taxRate.rate) / 100,
          ); // calculate exclusive tax amount
          taxRateChanged = taxRate.id !== item.taxRate?.id;
        }

        const total = Math.max(0, newPrice - newDiscount + newTaxAmount);

        // Check if any value has changed to minimize state updates
        if (
          newPrice !== item.price ||
          newDiscount !== item.discount ||
          taxRateChanged ||
          total !== item.total ||
          v.description !== item.description
        ) {
          setItem({
            id: generateIdForItem(item),
            description: v.description as string,
            price: newPrice,
            discount: newDiscount,
            taxRate: taxRate,
            taxAmount: newTaxAmount,
            total: total,
          });
        }

        return true;
      },
    )
    .text("description", {
      label: "Description",
      required: true,
    })
    .decimal("price", {
      label: "Price",
      prefix: currency,
      decimalPlaces: 2,
      required: true,
    })
    .conditional(["discount"], ["discount"], () => editingDiscount)
    .decimal("discount", {
      label: "Discount",
      prefix: currency,
      decimalPlaces: 2,
    })
    .conditional(["taxRateId"], ["taxRateId"], () => hasTax && editingTaxRate)
    .select("taxRateId", {
      label: "Tax Rate",
      data: (taxRates?.data ?? []).map(taxRate => {
        return {
          label: `${taxRate.label} (${taxRate.rate}%)`,
          value: taxRate.id,
        };
      }),
      required: editingTaxRate,
    })
    .getDefinition() as FormDefinition<Schema>;

  const validateRequest = (formData: Schema) =>
    new Promise<object>((resolve, reject) => {
      if ((formData.discount ?? 0) > formData.price) {
        reject({
          statusCode: 422,
          validationErrors: {
            discount: "Discount must be less than or equal to Price",
          },
        });
      } else {
        resolve({});
      }
    });

  const defaultValues = currentItem
    ? {
        description: currentItem.description,
        price: moneyIntegerToFloat(currentItem.price),
        discount: currentItem.discount
          ? moneyIntegerToFloat(currentItem.discount)
          : undefined,
        taxRateId: currentItem.taxRate?.id,
      }
    : {
        taxRateId: defaultTax?.id,
      };

  return {
    formHook,
    validateRequest,
    defaultValues,
    editingTaxRate,
    setEditingTaxRate,
    editingDiscount,
    setEditingDiscount,
    item: {
      ...item,
      id: generateIdForItem(item),
      price: moneyFloatToInteger(item.price),
      discount: moneyFloatToInteger(item.discount),
      taxAmount: moneyFloatToInteger(item.taxAmount),
      total: moneyFloatToInteger(item.total),
    },
    hasTax,
    defaultTax,
    taxRates,
    success: success,
  };
};

function generateIdForItem(item: CustomTransactionItem) {
  return `${item.description}-${item.price}-${item.discount}-${item.taxRate?.id}`;
}

function formatValidationError(name: string, error: string): string {
  const [trnNum, field, element, part] = name.split(".");
  const trn = Number.parseInt(trnNum) + 1;
  const fmtError = error.replace(name, "");
  if (element) {
    const elem = Number.parseInt(element) + 1;
    return `Transaction ${trn} the ${field} entry ${elem} ${part} ${fmtError}`;
  }
  return error;
}

export function createTransactions({
  api,
  families,
  date,
  items,
  onSuccess,
}: {
  api: Api;
  date: Date;
  families: string[];
  items: CustomTransactionItem[];
  onSuccess: () => void;
}) {
  if (families.length === 0) {
    showAlert({
      variant: "warning",
      content: `Select at least one family to bill`,
    });
    return;
  }

  if (items.length === 0) {
    showAlert({
      variant: "warning",
      content: `Add at least one item to bill`,
    });
    return;
  }

  const transactions: CustomTransactionBlueprint[] = families.map(familyId => {
    return {
      familyId,
      automaticCharge: false, // todo: this is typed in the API so we'll provide a default value
      date: toUtcIsoDateString(date),
      details: items.map(item => {
        return {
          description: item.description,
          amount: item.price,
          discount: item.discount,
          taxRateId: item.taxRate?.id,
        };
      }),
    };
  });

  api.transactions
    .createCustomTransaction({
      customTransactionBlueprint: transactions,
    })
    .then(() => {
      showAlert({
        variant: "success",
        content:
          families.length === 1
            ? `Transaction created`
            : `Transactions created`,
      });
      onSuccess();
    })
    .catch(error => {
      if (error.validationErrors) {
        Object.entries(error.validationErrors).forEach(([name, errors]) => {
          (errors as string[]).forEach((error: string) => {
            showAlert({
              variant: "error",
              content: formatValidationError(name, error),
              dismissible: true,
              timeout: 5000,
            });
          });
        });
      } else {
        error.messages?.forEach((error: string) =>
          showAlert({
            variant: "error",
            content: error,
            dismissible: true,
            timeout: 5000,
          }),
        );
      }
    });
}
