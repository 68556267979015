import {
  AggregateClassDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../api";
import { uniqueValuesForKey } from "../collectionUtils";

export interface AggregateClassRelatedData<T> {
  classes: AggregateClassDto[];
  list: T[];
  seasons: SeasonDto[];
  students: StudentDto[];
}

export const aggregateClassRelatedDataEmptyList = <
  T,
>(): AggregateClassRelatedData<T> => ({
  classes: [],
  students: [],
  seasons: [],
  list: [],
});

type GenericType = {
  classId: string;
  studentId: string;
};

export const fetchClassRelatedData = async <T extends GenericType>(
  api: Api,
  fetchListFn: () => Promise<{ data: T[] }>,
): Promise<AggregateClassRelatedData<T>> => {
  const { data: list } = await fetchListFn();

  if (list.length === 0) {
    return aggregateClassRelatedDataEmptyList<T>();
  }

  const classIds: string[] = uniqueValuesForKey("classId", list);
  const studentIds: string[] = uniqueValuesForKey("studentId", list);

  const classesPromise = api.courses.listCourse({
    where: { id: { in: classIds } },
  });
  const studentsPromise = api.students.listStudent({
    where: { id: { in: studentIds } },
    selectAll: true,
  });

  const [{ data: classes }, { data: students }] = await Promise.all([
    classesPromise,
    studentsPromise,
  ]);

  const seasonIds = uniqueValuesForKey(
    "seasonId",
    classes.map(c => c.entity),
  );

  const { data: seasons } = await api.seasons.listSeason({
    where: { id: { in: seasonIds } },
    selectAll: true,
  });

  return {
    classes,
    students,
    seasons,
    list,
  };
};
