import { useEffect } from "react";

import { UserAccountDto, UserDto } from "@justraviga/classmanager-sdk";

import { useAuthState } from "../components/AuthStateProvider";

export interface AppcuesAdapterInterface {
  identifyUser(user: UserDto, account: UserAccountDto | null): void;
}

export const AppcuesAdapter = ({
  adapter,
}: {
  adapter: AppcuesAdapterInterface;
}) => {
  const { user, account } = useAuthState();

  useEffect(() => {
    if (user && account && account.role === "staff") {
      adapter.identifyUser(user, account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, account]);

  return null;
};
