import { FC, ReactNode } from "react";

import {
  EnrolmentAdjustmentDto,
  EnrolmentDto,
  PaginatedResourceResponse,
  SeasonDto,
  SortSchema,
  StudentDto,
} from "@justraviga/classmanager-sdk";
import { AggregateClassDto } from "@justraviga/classmanager-sdk/dist/models/AggregateClassDto";

import { Api } from "../../api";
import { getClassSubtitle } from "../../classUtils";
import { uniqueValuesForKey } from "../../collectionUtils";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import {
  buildEnrolmentFilter,
  DetailedEnrolment,
  EnrolmentFilter,
  getEnrolmentEndDate,
  getEnrolmentStartDate,
} from "../../enrolmentUtils";
import { formatDate } from "../../intlFormatter";
import { EnrolmentStatusChip } from "../enrolments/EnrolmentStatusChip";
import { useGenericComponents } from "../GenericComponentsProvider";

type Dto = EnrolmentDto;
type RowDto = DetailedEnrolment;
type FilterForm = object;

interface EnrolmentDatatableData {
  data: {
    enrolmentAdjustments: EnrolmentAdjustmentDto[];
    class: AggregateClassDto;
    season: SeasonDto;
    student: StudentDto;
    id: string;
    companyId: string;
    classId: string;
    studentId: string;
  }[];
  pagination: PaginatedResourceResponse;
}

function sortResults(
  mergedData: EnrolmentDatatableData,
  orderedSeason: string[],
  classOrder: string[],
): EnrolmentDatatableData {
  return {
    ...mergedData,
    data: mergedData.data.sort((a, b) => {
      const aSeasonIndex = orderedSeason.indexOf(a.season.id);
      const bSeasonIndex = orderedSeason.indexOf(b.season.id);

      if (aSeasonIndex !== bSeasonIndex) {
        return aSeasonIndex - bSeasonIndex;
      }

      // If seasons are the same, compare classes
      const aClassIndex = classOrder.indexOf(a.classId);
      const bClassIndex = classOrder.indexOf(b.classId);

      return aClassIndex - bClassIndex;
    }),
  };
}

export const useStudentEnrolmentsDatatable = ({
  Datatable,
  api,
  studentId,
  filter,
  goToClass,
  showUpdateStartForm,
  showUpdateEndForm,
}: {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, RowDto> }>;
  api: Api;
  studentId: string;
  filter: EnrolmentFilter;
  goToClass: (id: string) => void;
  showUpdateStartForm: (entity: RowDto) => void;
  showUpdateEndForm: (entity: RowDto) => void;
}): { datatable: ReactNode } => {
  const applyFilter = buildEnrolmentFilter(filter);
  const placeholderTitle = {
    current: "This student is not enrolled for any classes",
    upcoming: "No upcoming enrollments",
    past: "No past enrollments",
  }[filter];

  const { View, Text } = useGenericComponents();

  const fetchData = async (
    query: DatatableQueryProps,
  ): Promise<EnrolmentDatatableData> => {
    const { where, ...rest } = query.toRequest();

    // dependent on student id
    const enrolmentsPromise = api.enrolments.listEnrolment({
      ...rest,
      selectAll: true,
      where: {
        ...where,
        studentId: {
          equals: studentId,
        },
      },
    });
    const studentPromise = api.students.getStudent({ id: studentId });

    const [enrolmentsResponse, student] = await Promise.all([
      enrolmentsPromise,
      studentPromise,
    ]);

    // dependent on enrolmentsResponse
    const classIds = uniqueValuesForKey(
      "classId",
      enrolmentsResponse.data.map(aggEnrolmentDto => aggEnrolmentDto.enrolment),
    );
    const { data: courses } = await api.courses.listCourse({
      where: {
        id: {
          in: classIds,
        },
      },
    });
    const sortedClassIds: string[] = courses.map(course => course.entity.id);

    // dependent on courses response
    const seasonIds = uniqueValuesForKey(
      "seasonId",
      courses.map(c => c.entity),
    );
    const { data: seasons } = await api.seasons.listSeason({
      where: {
        id: {
          in: seasonIds,
        },
      },
      sort: { startAt: SortSchema.Desc },
      selectAll: true,
    });
    const sortedSeasonIds: string[] = seasons.map(season => season.id);

    const filteredDetailedEnrolments = enrolmentsResponse.data
      .map(e => {
        const aggClass = courses.find(
          c => c.entity.id === e.enrolment.classId,
        )!;
        return {
          ...e.enrolment,
          enrolmentAdjustments: e.adjustments,
          class: aggClass,
          season: seasons.find(s => s.id === aggClass.entity.seasonId)!,
          student,
        };
      })
      .filter(applyFilter);

    const mergedData = {
      ...enrolmentsResponse,
      data: filteredDetailedEnrolments,
    };

    return sortResults(mergedData, sortedSeasonIds, sortedClassIds);
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: `student-enrolments-${studentId}-${filter}`,
    createLabel: "Enroll",
    rowActions: {
      title: item => item.class.entity.name,
      click: item => goToClass(item.classId),
      additionalRowActions: () => [
        {
          icon: "openOutline",
          label: "Go to class",
          onClick: item => goToClass(item.classId),
        },
        {
          title: "Enrollment",
          actions: [
            {
              icon: "calendarNumberOutline",
              label: "Edit start date",
              onClick: showUpdateStartForm,
            },
            {
              icon: "calendarOutline",
              label: "Edit end date",
              onClick: showUpdateEndForm,
            },
          ],
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: placeholderTitle,
        description: "",
      },
    },
    hasPagination: false,
    hasSearch: false,
    showTotalRecords: false,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Class",
        placeholder: "tile",
        value: row => {
          return {
            type: "custom",
            children: (
              <View className="flex flex-col space-y-1 overflow-hidden">
                <View className="flex flex-row space-between items-center space-x-3">
                  <Text
                    className="text-body-600 font-semibold text-grey-900"
                    truncate={true}>
                    {row.item.class.entity.name}
                  </Text>
                  <EnrolmentStatusChip detailedEnrolment={row.item} />
                </View>
                <Text className="text-label-400 text-grey-600" truncate={true}>
                  {getClassSubtitle(row.item.class.entity, row.item.season)}
                </Text>
              </View>
            ),
          };
        },
      },
      {
        label: "Start date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDate(getEnrolmentStartDate(row.item), "dayMonthYear"),
        }),
      },
      {
        label: "End date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDate(getEnrolmentEndDate(row.item), "dayMonthYear"),
        }),
      },
    ],
    mobileColumn: {
      children: row => (
        <View className="flex flex-col space-y-1">
          <View className="flex flex-row justify-start items-center space-x-3">
            <View className="flex-1 overflow-hidden">
              <Text
                className="text-body-600 font-semibold text-grey-900"
                truncate={true}>
                {row.item.class.entity.name}
              </Text>
            </View>
            <View className="flex-none">
              <EnrolmentStatusChip detailedEnrolment={row.item} />
            </View>
          </View>
          <Text className="text-label-400 text-grey-600" truncate={true}>
            {getClassSubtitle(row.item.class.entity, row.item.season)}
          </Text>
          <Text className="text-label-400 text-grey-600" truncate={true}>
            {formatDate(getEnrolmentStartDate(row.item), "dayMonthYear")} -{" "}
            {formatDate(getEnrolmentEndDate(row.item), "dayMonthYear")}
          </Text>
        </View>
      ),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
