import { useAuthState } from "shared/components";
import { ContentPlaceholder } from "shared/components";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CustomerCardPage } from "@/modules/common/cardContainer/CustomerCardPage";
import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { Button } from "@/modules/common/ui/button/Button";
import { PaymentMethodCard } from "@/modules/common/ui/card/PaymentMethodCard";
import { useFamilyPaymentMethods } from "@/modules/customer/billing/useFamilyPaymentMethods";

export const CustomerPaymentMethodsPage = () => {
  const { paymentMethods, isSuccess, isLoading } = useFamilyPaymentMethods();
  const { account } = useAuthState();
  const { showAddPaymentMethodForm } = usePaymentMethods();

  const handleAddClick = () =>
    showAddPaymentMethodForm(account?.entityId || "");

  return (
    <CustomerCardPage
      title="Payment methods"
      primaryAction={{
        icon: "addOutline",
        title: "Add",
        onClick: handleAddClick,
      }}
      forceBackButton
      isEmpty={(!isSuccess && !isLoading) || paymentMethods?.length === 0}
      isLoading={isLoading}>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon="helpCircleOutline"
          title="No payment methods yet"
          description="You do not have any saved payment methods yet."
          action={
            <Button
              variant={"brand"}
              text={"Add"}
              size={"sm"}
              onClick={() => handleAddClick()}
            />
          }
        />
      </CardContainerPlaceholder>
      <CardContainerContent>
        {isSuccess &&
          paymentMethods.map(paymentMethod => (
            <PaymentMethodCard
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              familyId={account?.entityId ?? ""}
            />
          ))}
      </CardContainerContent>
    </CustomerCardPage>
  );
};
