import { useFormActions } from "shared/components";

import { Button } from "@/modules/common/ui/button/Button";

interface DeleteButtonProps {
  onDelete: (onDeleteSuccess: () => void) => void;
}

export const DeleteButton = ({ onDelete }: DeleteButtonProps) => {
  const { setShouldCloseOnSuccess, setIsLoading } = useFormActions();

  return (
    <div className="-ml-3 flex justify-start">
      <Button
        text={"Delete address"}
        variant={"destructive-tertiary"}
        onClick={async () => {
          setIsLoading(true);
          setShouldCloseOnSuccess(true);
          onDelete(() => setIsLoading(false));
        }}
      />
    </div>
  );
};
