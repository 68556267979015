import {
  EmptyEntity,
  isDefinedEntity,
  useSharedWaiverDetailsPage,
  WaiverEntity,
} from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { Breadcrumb } from "@/modules/company/CompanyLayout";
import { useWaiverActions } from "@/modules/company/waivers/useWaiverActions";
import { useWaiverDetailActionMenu } from "@/modules/company/waivers/useWaiverDetailActionMenu";
import { Router } from "@/routing/router";
import { routes } from "@/routing/routes";

function buildWaiverDetailBreadcrumbs(
  waiver: WaiverEntity | EmptyEntity,
  from?: string,
) {
  const crumbs: Breadcrumb[] = [
    {
      text: "Waivers & policies",
      onPress: () => {
        Router.push("CompanyWaivers");
      },
    },
  ];

  if (from && from === routes.CompanyWaiversArchived) {
    crumbs.push({
      text: "Archived waivers & policies",
      onPress: () => {
        Router.push("CompanyWaiversArchived");
      },
    });
  }

  if (isDefinedEntity(waiver)) {
    crumbs.push({
      text: waiver.props.name,
    });
  }

  return crumbs;
}

interface UseWaiversDetailsPageArgs {
  id: string;
  from?: string;
}

export function useWaiverDetailsPage({ id, from }: UseWaiversDetailsPageArgs) {
  const breakpoints = useBreakpoint();
  const waiverActions = useWaiverActions();
  const { isLoading, waiver, restore, showEditForm } =
    useSharedWaiverDetailsPage(id, waiverActions, useApi);

  const breadcrumbs = buildWaiverDetailBreadcrumbs(waiver, from);
  const actionMenuItems = useWaiverDetailActionMenu(waiver, waiverActions);

  return {
    actionMenuItems,
    breadcrumbs,
    breakpoints,
    isLoading,
    waiver,
    restore,
    showEditForm,
  };
}
