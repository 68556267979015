import { useCatalogueContext } from "shared/components";

import { UserRegistrationForm } from "@/modules/auth/catalogue/UserRegistrationForm";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { LoginHeader } from "@/modules/auth/common/LoginHeader";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";
import { SwitchAuthType } from "@/modules/auth/common/SwitchAuthType";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { Router } from "@/routing/router";

export const CatalogueRegisterPage = () => {
  const { company } = useCatalogueContext();

  return (
    <NonAuthLayout branded>
      <AuthPageCard
        header={
          <LoginHeader
            companyLogo={company.logo}
            handleBackButton={() => window.history.back()}
          />
        }
        title={company.name}
        subtitle={"Register for an account on Class Manager."}
        content={<UserRegistrationForm />}
        footer={
          <SwitchAuthType
            message="Already have an account?"
            onClick={() =>
              Router.push("CatalogueLogin", { slug: company.slug })
            }
            linkText="Log in">
            <PoweredBy />
          </SwitchAuthType>
        }
      />
    </NonAuthLayout>
  );
};
