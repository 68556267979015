import {
  useEnrolmentsActions,
  useStudentEnrolmentsDatatable,
} from "shared/components";
import {
  getEnrolmentEndDate,
  getEnrolmentStartDate,
  getFullName,
  useStudentEnrolmentsData,
} from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Tabs } from "@/modules/common/nav/Tabs";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { Button } from "@/modules/common/ui/button/Button";
import {
  Breadcrumb,
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

export const StudentEnrolmentsPage = ({ studentId }: { studentId: string }) => {
  const actions = useEnrolmentsActions();

  const { currentFilter, student, tabItems } = useStudentEnrolmentsData({
    studentId,
    useApi,
  });

  const { datatable } = useStudentEnrolmentsDatatable({
    Datatable,
    api,
    studentId,
    filter: currentFilter,
    goToClass: id => Router.push("ClassDetails", { id }),
    showUpdateStartForm: item =>
      actions.showUpdateStartForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentStartDate(item),
      }),
    showUpdateEndForm: item =>
      actions.showUpdateEndForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentEndDate(item),
      }),
  });

  if (!student) {
    return <LoadingPlaceholder />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: "Students",
      onPress: () => Router.push("StudentList"),
    },
    {
      text: getFullName(student),
      onPress: () => Router.push("StudentDetails", { id: student.id }),
    },
    {
      text: "Enrollments",
    },
  ];

  return (
    <CompanyLayout datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Enrollments"} />
        <Button
          text={"Enroll"}
          size="sm"
          onClick={() =>
            actions.showCreateForm({
              studentIds: [studentId],
              showStudentSelector: false,
            })
          }
          variant="brand"
        />
      </CompanyTitleBar>
      <Tabs items={tabItems} />
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};
