import { ReactNode } from "react";

import {
  AggregateEnrolmentDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { ItemListWidget } from "./ItemListWidget";
import { BasketItem } from "./MemberBasketPage";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  BannerComponent,
  HorizontalSeparatorComponent,
  IconButtonComponent,
  IconComponent,
  MultiSelectComponent,
} from "../../../interfaces";

export interface ItemBySeasonProps {
  seasons: SeasonDto[];
  basketItems: BasketItem[];
  familyMembers: StudentDto[];
  enrolments: AggregateEnrolmentDto[];
  shouldValidate: boolean;
  Banner: BannerComponent;
  IconButton: IconButtonComponent;
  MultiSelect: MultiSelectComponent;
  useStudentActions: () => StudentImplementedActions;
  HorizontalSeparator: HorizontalSeparatorComponent;
  Icon: IconComponent;
  itemTitle: (basketItem: BasketItem) => ReactNode;
  itemSubtitle: (basketItem: BasketItem) => ReactNode;
}

export const ItemListBySeason = ({
  seasons,
  basketItems,
  familyMembers,
  enrolments,
  shouldValidate,
  Banner,
  IconButton,
  MultiSelect,
  useStudentActions,
  HorizontalSeparator,
  Icon,
  itemTitle,
  itemSubtitle,
}: ItemBySeasonProps) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className={"mb-4"}>
      {seasons.map(season => (
        <ItemListWidget
          header={
            <Text className={"pb-3 text-heading5-600 text-grey-900"}>
              {season.name}
            </Text>
          }
          itemTitle={itemTitle}
          itemSubtitle={itemSubtitle}
          basketItems={basketItems.filter(item => item.season.id === season.id)}
          familyMembers={familyMembers}
          enrolments={enrolments}
          key={`enrolment-widget-${season.id}`}
          Banner={Banner}
          IconButton={IconButton}
          MultiSelect={MultiSelect}
          useStudentActions={useStudentActions}
          HorizontalSeparator={HorizontalSeparator}
          shouldValidate={shouldValidate}
          Icon={Icon}
        />
      ))}
    </View>
  );
};
