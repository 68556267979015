import { WeekDay } from "@justraviga/classmanager-sdk";

import { useSettings } from "./useSettings";

export const useOrderedWeekDays = () => {
  const { startDayOfWeek } = useSettings("general");

  const weekDays = [
    WeekDay.Monday,
    WeekDay.Tuesday,
    WeekDay.Wednesday,
    WeekDay.Thursday,
    WeekDay.Friday,
    WeekDay.Saturday,
    WeekDay.Sunday,
  ];

  // Find the index of the weekStartDate in the weekDays array
  const adjustedWeekStartDate = weekDays.indexOf(startDayOfWeek);

  // Create a new array starting from adjustedWeekStartDate
  return adjustedWeekStartDate !== undefined
    ? weekDays
        .slice(adjustedWeekStartDate)
        .concat(weekDays.slice(0, adjustedWeekStartDate))
    : weekDays;
};
