import { ReactNode } from "react";

import { z, ZodSchema } from "zod";

import {
  BigRadioProps,
  CheckboxWithTextProps,
  ColorPaletteProps,
  ColorPickerProps,
  DatePickerProps,
  DayMonthPickerProps,
  DecimalInputProps,
  ImagePickerProps,
  IntegerInputProps,
  PasswordInputProps,
  RichTextInputProps,
  SwitchProps,
  TextInputProps,
  TimePickerProps,
} from "./formComponentProps";
import { CourseSelectProps } from "../components/forms/CourseSelect";
import {
  LessonSelectProps,
  LessonSelectValue,
} from "../components/forms/LessonSelect";
import { MultiSelectProps, SelectProps } from "../components/interfaces";

export type FieldType =
  | "bigRadio"
  | "checkbox"
  | "color"
  | "colorPalette"
  | "courseId"
  | "lessonDateTime"
  | "date"
  | "dayMonth"
  | "decimal"
  | "image"
  | "integer"
  | "multiSelect"
  | "password"
  | "richText"
  | "select"
  | "switch"
  | "text"
  | "time";

export type FieldDefinition =
  | { type: "bigRadio"; props: BigRadioProps }
  | { type: "checkbox"; props: CheckboxWithTextProps }
  | { type: "color"; props: ColorPickerProps }
  | { type: "colorPalette"; props: ColorPaletteProps }
  | { type: "courseId"; props: CourseSelectProps }
  | { type: "lessonDateTime"; props: LessonSelectProps }
  | { type: "date"; props: DatePickerProps }
  | { type: "dayMonth"; props: DayMonthPickerProps }
  | { type: "decimal"; props: DecimalInputProps }
  | { type: "image"; props: ImagePickerProps }
  | { type: "integer"; props: IntegerInputProps }
  | { type: "multiSelect"; props: MultiSelectProps }
  | { type: "password"; props: PasswordInputProps }
  | { type: "richText"; props: RichTextInputProps }
  | { type: "select"; props: SelectProps }
  | { type: "switch"; props: SwitchProps }
  | { type: "text"; props: TextInputProps }
  | { type: "time"; props: TimePickerProps };

type FieldValueTypeMap = {
  bigRadio: string | null;
  checkbox: boolean;
  color: string | null;
  colorPalette: string | null;
  courseId: string | null;
  lessonDateTime: LessonSelectValue | null;
  date: string | null;
  dayMonth: string | null;
  decimal: number | null;
  image: string | null;
  integer: number | null;
  multiSelect: string[];
  password: string | null;
  richText: string | null;
  select: string | null;
  switch: boolean;
  text: string | null;
  time: string | null;
};

type InputValueWithDefault<T extends FieldType> = FieldValueTypeMap[T];

export interface Row<FieldName extends string | number | symbol> {
  fields: FieldName[];
  columnWidths: number[];
}

export interface Group<FieldName extends string | number | symbol> {
  heading: string;
  description?: string;
  fields: FieldName[];
  advanced: boolean;
}

export interface Condition<Fields extends Record<string, FieldDefinition>> {
  field: keyof Fields;
  watchedFields: Array<keyof Fields>;
  handler: (v: FormValues<Fields>) => boolean;
}

type InferFieldDefinitionType<T extends FieldDefinition> = T extends {
  type: infer U extends FieldType;
}
  ? InputValueWithDefault<U>
  : never;

// Convert all fields to their input value type
export type FormValues<Fields extends Record<string, FieldDefinition>> = {
  [K in keyof Fields]: Fields[K] extends FieldDefinition
    ? InferFieldDefinitionType<Fields[K]>
    : never;
};

export interface ContentBlockDefinition<
  FieldName extends string | number | symbol,
> {
  closestField: FieldName;
  position: "before" | "after";
  content: ReactNode;
}

const stringOrNumber = z.union([z.string().min(1), z.number()]);

const fieldSchemas: Record<FieldType, ZodSchema> = {
  bigRadio: z.string().min(1),
  checkbox: z.boolean(),
  color: z.string().min(6),
  colorPalette: z.string().min(6),
  courseId: z.string().min(1),
  lessonDateTime: z.array(z.string()).min(3).max(3),
  date: z.string().min(10).max(10),
  dayMonth: z.string().min(5).max(5),
  decimal: z.number(),
  image: z.string().min(1),
  integer: z.number().int(),
  multiSelect: z.array(stringOrNumber).nonempty("Required"),
  password: z.string().min(1),
  richText: z.string().min(1),
  select: stringOrNumber,
  switch: z.boolean(),
  text: z.string().min(1),
  time: z.string().min(5).max(5),
};

export class FormDefinitionBuilder<Schema extends object> {
  private fields: Record<keyof Schema, FieldDefinition>;
  private readonly groups: Array<Group<keyof typeof this.fields>>;
  private readonly contentBlocks: Array<ContentBlockDefinition<keyof Schema>>;
  private readonly rows: Array<Row<keyof typeof this.fields>>;
  private readonly conditions: Map<
    keyof typeof this.fields,
    Condition<typeof this.fields>
  >;
  private readonly watchedFields: Set<keyof typeof this.fields>;

  constructor() {
    this.fields = {} as Record<keyof Schema, FieldDefinition>;
    this.groups = [];
    this.contentBlocks = [];
    this.rows = [];
    this.conditions = new Map();
    this.watchedFields = new Set();
  }

  contentBlock(
    position: "before" | "after",
    closestField: keyof Schema,
    content: React.ReactNode,
  ) {
    this.contentBlocks.push({ closestField, position, content });
    return this;
  }

  courseId(
    name: keyof Schema,
    props: Omit<CourseSelectProps, "Select" | "value" | "onChange">,
  ) {
    this.fields = { ...this.fields, [name]: { type: "courseId", props } };
    return this;
  }

  lessonDateTime(
    name: keyof Schema,
    props: Omit<LessonSelectProps, "Select" | "value" | "onChange">,
  ) {
    this.fields = { ...this.fields, [name]: { type: "lessonDateTime", props } };
    return this;
  }

  text(name: keyof Schema, props: TextInputProps) {
    this.fields = { ...this.fields, [name]: { type: "text", props } };
    return this;
  }

  richText(name: keyof Schema, props: RichTextInputProps) {
    this.fields = { ...this.fields, [name]: { type: "richText", props } };
    return this;
  }

  password(name: keyof Schema, props: PasswordInputProps) {
    this.fields = { ...this.fields, [name]: { type: "password", props } };
    return this;
  }

  image(name: keyof Schema, props: ImagePickerProps) {
    this.fields = { ...this.fields, [name]: { type: "image", props } };
    return this;
  }

  integer(name: keyof Schema, props: IntegerInputProps) {
    this.fields = { ...this.fields, [name]: { type: "integer", props } };
    return this;
  }

  decimal(name: keyof Schema, props: DecimalInputProps) {
    this.fields = { ...this.fields, [name]: { type: "decimal", props } };
    return this;
  }

  checkbox(name: keyof Schema, props: CheckboxWithTextProps) {
    this.fields = { ...this.fields, [name]: { type: "checkbox", props } };
    return this;
  }

  color(name: keyof Schema, props: ColorPickerProps) {
    this.fields = { ...this.fields, [name]: { type: "color", props } };
    return this;
  }

  colorPalette(name: keyof Schema, props: ColorPaletteProps) {
    this.fields = { ...this.fields, [name]: { type: "colorPalette", props } };
    return this;
  }

  switch(name: keyof Schema, props: SwitchProps) {
    this.fields = { ...this.fields, [name]: { type: "switch", props } };
    return this;
  }

  date(name: keyof Schema, props: Omit<DatePickerProps, "value">) {
    this.fields = { ...this.fields, [name]: { type: "date", props } };
    return this;
  }

  dayMonth(name: keyof Schema, props: Omit<DayMonthPickerProps, "value">) {
    this.fields = { ...this.fields, [name]: { type: "dayMonth", props } };
    return this;
  }

  time(name: keyof Schema, props: Omit<TimePickerProps, "value">) {
    this.fields = { ...this.fields, [name]: { type: "time", props } };
    return this;
  }

  select(name: keyof Schema, props: SelectProps) {
    this.fields = { ...this.fields, [name]: { type: "select", props } };
    return this;
  }

  multiSelect(name: keyof Schema, props: MultiSelectProps) {
    this.fields = { ...this.fields, [name]: { type: "multiSelect", props } };
    return this;
  }

  bigRadio(name: keyof Schema, props: BigRadioProps) {
    this.fields = { ...this.fields, [name]: { type: "bigRadio", props } };
    return this;
  }

  /**
   * Group fields together under a heading (similar to HTML's fieldset)
   */
  group(
    heading: string,
    fields: Array<keyof typeof this.fields>,
    { advanced, description }: { advanced?: boolean; description?: string } = {
      advanced: false,
    },
  ) {
    this.groups.push({
      heading,
      description,
      fields,
      advanced: advanced ?? false,
    });
    return this;
  }

  /**
   * Show multiple fields side-by-side in a single row.
   */
  row(
    fields: Array<keyof typeof this.fields>,
    columnWidthsInPercent: number[] = [],
  ) {
    this.rows.push({ fields, columnWidths: columnWidthsInPercent });
    return this;
  }

  /**
   * Remove one or more fields from the form definition.
   */
  without(fields: Array<keyof typeof this.fields>) {
    fields.forEach(field => {
      delete this.fields[field];
    });
    return this; // type this?
  }

  /**
   * Make one or more fields conditionally visible based on the values of other fields.
   */
  conditional<WatchedFields extends Array<keyof typeof this.fields>>(
    dependentFields: Array<keyof typeof this.fields>,
    watchedFields: WatchedFields,
    handler: (v: FormValues<typeof this.fields>) => boolean,
  ) {
    dependentFields.forEach(field => {
      this.conditions.set(field, {
        field,
        watchedFields,
        handler,
      });
      watchedFields.forEach(f => {
        this.watchedFields.add(f);
      });
    });
    return this;
  }

  getDefinition() {
    return {
      contentBlocks: this.contentBlocks,
      fields: this.fields,
      groups: this.groups,
      rows: this.rows,
      conditions: this.conditions,
      watchedFields: this.watchedFields,
      zodSchema: this.getZodSchema(),
    } as const;
  }

  getZodSchema() {
    // Find all the fields that have a truthy `required` prop and build a zod schema to represent them
    // This is a simplified example and doesn't handle nested objects or arrays
    const schemaObj = {} as Record<keyof Schema, ZodSchema>;

    for (const fieldName in this.fields) {
      if (this.fields[fieldName].props.required) {
        const fieldDefinition = this.fields[fieldName];

        schemaObj[fieldName] = fieldSchemas[fieldDefinition.type];
      } else {
        schemaObj[fieldName] = z.any();
      }
    }

    return z.object(schemaObj);
  }
}
