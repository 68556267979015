import { MultiSearchRequestSchema } from "typesense/lib/Typesense/MultiSearch";

import { SearchCollection } from "./searchTypes";

interface CollectionQueryMapping {
  [key: string]: string[];
}

const searchableFieldsConfig: CollectionQueryMapping = {
  [SearchCollection.student]: ["firstname", "lastname"],
  [SearchCollection.family]: ["name"],
  [SearchCollection.staff]: ["firstname", "lastname", "email", "phone"],
  [SearchCollection.season]: ["name"],
  [SearchCollection.course]: ["name"],
};

export interface MultiSearchRequestsSchema {
  searches: MultiSearchRequestSchema[];
}

export function getQueryBuilder(
  collections: SearchCollection[],
): (q: string) => MultiSearchRequestsSchema {
  const baseSearches = collections.map(collection => {
    const queryByFields = searchableFieldsConfig[collection];
    return {
      collection,
      // eslint-disable-next-line camelcase
      query_by: queryByFields.join(","),
      limit: 10,
    };
  });

  return (q: string) => ({
    searches: baseSearches.map(fields => ({ q, ...fields })),
  });
}

export const queryBuilder = getQueryBuilder([
  SearchCollection.family,
  SearchCollection.student,
  SearchCollection.staff,
  SearchCollection.course,
  SearchCollection.season,
]);
