import { useContext } from "react";

import { AlertContextImpl } from "@/modules/common/overlays/alert/AlertContextImpl";

export const useAlertContext = () => {
  const context = useContext(AlertContextImpl);

  if (!context) {
    throw new Error("useAlertContext must be used within AlertProvider");
  }

  return context;
};
