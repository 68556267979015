import {
  FamilyMakeUpLessonsListProps,
  findClass,
  findSeason,
  findStudent,
  formatDate,
  formatTime,
  getLessonLabel,
  useBreakpoint,
} from "shared/lib";

import { Collapsible } from "@/modules/common/ui/Collapsible";
import { PendingEnrolmentCard } from "@/modules/customer/common/PendingEnrolmentCard";

export const FamilyMakeUpLessonsList = ({
  makeUpLessons,
  students,
  classes,
  seasons,
  title,
  collapsible = false,
}: FamilyMakeUpLessonsListProps) => {
  const { md: isDesktop } = useBreakpoint();

  const MainContent = () => {
    return (
      <section className={"flex flex-col space-y-5"}>
        {!collapsible && (
          <div>
            <span className={"text-label-400 text-grey-600"}>{title}</span>
            <h4 className={"mt-1 text-heading4-600"}>
              {makeUpLessons.length} {getLessonLabel(makeUpLessons.length)}
            </h4>
          </div>
        )}
        {makeUpLessons.map(entity => {
          const startAt = formatDate(entity.startAt, "dayMonthYear");
          const startTime = entity.time
            ? formatTime(entity.time, "hourMinute")
            : "";
          const course = findClass(classes, entity.classId);

          if (!course) {
            return null;
          }

          const season = findSeason(seasons, course.entity.seasonId)!;

          return (
            <PendingEnrolmentCard
              key={entity.id}
              student={findStudent(students, entity.studentId)!}
              rightSlot={
                <p className={"text-body-400"}>
                  {isDesktop ? `${startAt}, ${startTime} ` : startAt}
                </p>
              }
              course={course}
              season={season}
            />
          );
        })}
      </section>
    );
  };

  return collapsible ? (
    <Collapsible title={title}>
      <MainContent />
    </Collapsible>
  ) : (
    <MainContent />
  );
};
