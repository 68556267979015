import { UserAccountDto } from "@justraviga/classmanager-sdk";

import { sdkCache } from "./cache/sdkCache";
import { resetBrandColors, setBrandColors } from "./colors";
import { useAuthState } from "./components/AuthStateProvider";
import { setIntlFormatterOptions } from "./intlFormatter";
import { queryClient } from "./tanstackQuery";

type AccountChangeHandler = () => void;

export function useSelectAccountCallback(
  account: UserAccountDto | null,
  onAccountChange: AccountChangeHandler,
) {
  const { setAccount } = useAuthState();

  return async function selectAccount(newAccount: UserAccountDto) {
    if (newAccount.id !== account?.id) {
      queryClient.clear();
      sdkCache.clear();
      setAccount(newAccount);
      if (newAccount.company) {
        const generalSettings = newAccount.company!.settings.general;
        setIntlFormatterOptions({
          locale: generalSettings.locale,
          timezone: generalSettings.timezone,
          use12HourClock: generalSettings.use12HourClock,
          currency: newAccount.company.settings.billing.currency,
        });
        setBrandColors(
          generalSettings.brandColor!,
          generalSettings.brandColors!,
        );
      } else {
        // Probably the Admin Portal
        resetBrandColors();
      }
    }
    onAccountChange();
  };
}
