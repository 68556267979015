import { LocationDto } from "@justraviga/classmanager-sdk";

import {
  EntityActions,
  makeLocationCreateRequest,
  makeLocationUpdateRequest,
  useLocationFormDefinition,
  useSharedLocationActions,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";

type Model = LocationDto;

type ImplementedActions = Omit<
  EntityActions<Model>,
  "deleteMany" | "archiveMany" | "unarchiveMany"
>;

export const useLocationActions = (): ImplementedActions => {
  const defaultActions = usePlatformEntityActions<LocationDto>({
    entity: "location",
  });

  const sharedActions = useSharedLocationActions(defaultActions, api);

  return {
    ...sharedActions,

    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeLocationCreateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={useLocationFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeLocationUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={useLocationFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),
  };
};
