import { getPlatformFunctions } from "../../../../../platformSpecific";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const ClassCountWidget = ({ seasonId }: { seasonId: string }) => {
  const { View, Text } = useGenericComponents();
  const { useApi } = getPlatformFunctions();
  const { data: classes } = useApi("listCourse", {
    where: {
      seasonId: {
        equals: seasonId,
      },
    },
  });
  return (
    <View className={"flex flex-row items-center p-1 md:items-end"}>
      <Text className={"mr-1 text-2xl font-semibold text-grey-900"}>
        {classes?.pagination?.total ?? "Loading"} classes
      </Text>
      <Text className={"pt-1 text-grey-600"}>/week</Text>
    </View>
  );
};
