import { Icon } from "@/modules/common/ui/icon/Icon";

export const Email = ({ email }: { email: string | null | undefined }) => {
  return (
    <div className={"flex flex-row items-center space-x-2"}>
      <Icon size={24} name={"mailOutline"} />
      <span>{email ?? "-"}</span>
    </div>
  );
};
