import { TransactionDetailType } from "@justraviga/classmanager-sdk";

export type TransactionPrimaryType =
  | "creditNote"
  | "fee"
  | "manualDebit"
  | "payment"
  | "paymentFailed"
  | "paymentFailedReversal"
  | "refund"
  | "unknown";

export const transactionTypeDetailMap: Record<
  TransactionDetailType,
  TransactionPrimaryType | null
> = {
  [TransactionDetailType.CreditNote]: "creditNote",
  [TransactionDetailType.Discount]: null,
  [TransactionDetailType.DiscountCredit]: null,
  [TransactionDetailType.FeeClassManager]: null,
  [TransactionDetailType.FeeCompany]: "fee",
  [TransactionDetailType.ManualDebit]: "manualDebit",
  [TransactionDetailType.ManualDebitCredit]: "creditNote",
  [TransactionDetailType.PaymentAuto]: "payment",
  [TransactionDetailType.PaymentAutoFailed]: "paymentFailed",
  [TransactionDetailType.PaymentAutoFailedReversal]: "paymentFailedReversal",
  [TransactionDetailType.PaymentManual]: "payment",
  [TransactionDetailType.Refund]: "refund",
  [TransactionDetailType.RegistrationFee]: "fee",
  [TransactionDetailType.RegistrationFeeCredit]: "creditNote",
  [TransactionDetailType.Tax]: null,
  [TransactionDetailType.TaxCredit]: null,
  [TransactionDetailType.Trial]: "fee",
  [TransactionDetailType.TrialCredit]: "creditNote",
  [TransactionDetailType.Tuition]: "fee",
  [TransactionDetailType.TuitionCredit]: "creditNote",
};
