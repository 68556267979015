import { PropsWithChildren, useState } from "react";

import { colors } from "shared/lib";

import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const DetailCard = ({
  title,
  children,
}: PropsWithChildren<{
  title: string;
}>) => {
  const { Icon, Text, View, Pressable } = useGenericComponents();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <View
      className={
        "space-y-1 rounded border border-grey-300 p-2 bg-white md:flex md:h-fit md:w-full md:max-w-full md:flex-col"
      }>
      <Pressable onClick={() => setIsOpen(!isOpen)}>
        <View className="flex flex-row items-center justify-between p-2">
          <View className="flex flex-1 flex-row items-center space-x-2">
            <Icon
              name={isOpen ? "chevronUp" : "chevronDown"}
              color={colors.grey["600"]}
              aria-label={isOpen ? "Open" : "Close"}
            />
            <Text className="flex-initial text-label-600 text-grey-600">
              {title}
            </Text>
          </View>
        </View>
      </Pressable>
      {isOpen ? <View>{children}</View> : null}
    </View>
  );
};
