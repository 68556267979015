import { useState } from "react";

import {
  AgeCutOffType,
  CompanyEnrolmentSettingsDto,
  UpdateCompanySettingsRequestEnrolment,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { BigRadioOption } from "../../../forms/formComponentProps";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { useSettings } from "../../useSettings";

type CustomType =
  | "7daysAfter"
  | "28daysAfter"
  | "specificDate"
  | "firstDayOfSeason";

type CustomSchema = {
  customType: CustomType;
};

type AgeCutoffUpdateSchema = Omit<
  UpdateCompanySettingsRequestEnrolment,
  "ageCutOffType" | "ageCutOffDays"
> &
  CustomSchema;

export const transformToAgeCutoffDefaults = (
  settings: CompanyEnrolmentSettingsDto,
): AgeCutoffUpdateSchema => {
  return {
    ageCutOffDate: settings.ageCutOffDate ?? undefined,
    customType:
      settings.ageCutOffType === AgeCutOffType.FirstDayOfSeason
        ? "firstDayOfSeason"
        : settings.ageCutOffType === AgeCutOffType.DaysAfterStart
          ? settings.ageCutOffDays === 7
            ? "7daysAfter"
            : "28daysAfter"
          : "specificDate",
  };
};

export const makeAgeCutoffFormDefinition = (
  defaultValues: AgeCutoffUpdateSchema,
) => {
  return () => {
    const [isDateFieldRequired, setIsDateFieldRequired] = useState(
      defaultValues.customType === "specificDate",
    );
    const { locale } = useSettings("general");

    return new FormDefinitionBuilder<AgeCutoffUpdateSchema>()
      .bigRadio("customType", {
        singleColOnly: true,
        options: cutOffTypes,
      })
      .dayMonth("ageCutOffDate", {
        label: "Day",
        required: isDateFieldRequired,
        locale,
      })
      .conditional(["ageCutOffDate"], ["customType"], v => {
        setIsDateFieldRequired(v.customType === "specificDate");
        return v.customType === "specificDate";
      })
      .getDefinition();
  };
};

export const makeAgeCutoffFormRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  async (formData: AgeCutoffUpdateSchema) => {
    // Logic to convert data from custom form to fit api request, the reason why we need to do this is so the api request is not a direct match to the form data
    // on api, setting 7days and 28days are both "days_after_start" but the form needs different values for each, otherwise they will be treated as the same
    const ageCutOffType =
      formData.customType === "firstDayOfSeason"
        ? AgeCutOffType.FirstDayOfSeason
        : formData.customType === "7daysAfter"
          ? AgeCutOffType.DaysAfterStart
          : formData.customType === "28daysAfter"
            ? AgeCutOffType.DaysAfterStart
            : AgeCutOffType.OnSpecificDate;
    const ageCutOffDays =
      ageCutOffType === AgeCutOffType.DaysAfterStart
        ? formData.customType === "7daysAfter"
          ? 7
          : 28
        : undefined;

    const data = {
      ageCutOffType,
      ageCutOffDate: formData.ageCutOffDate,
      ageCutOffDays,
    };

    const company = await api.companies.updateCompanySettings({
      updateCompanySettingsRequest: {
        enrolment: data,
      },
    });
    setAccount({
      ...account,
      company,
    });
    return company;
  };

const cutOffTypes: BigRadioOption[] = [
  {
    label: "First day of season",
    value: "firstDayOfSeason",
    description: "Each season will have a different age cut off date.",
    icon: "calendarClearOutline",
  },
  {
    label: "7 days after the start of the season",
    value: "7daysAfter",
    description: "Each season will have a different age cut off date.",
    icon: "arrowForwardCircleOutline",
  },
  {
    label: "28 days after the start of the season",
    value: "28daysAfter",
    description: "Each season will have a different age cut off date.",
    icon: "arrowForwardCircleOutline",
  },
  {
    label: "From specific date",
    value: "specificDate",
    description: "All seasons will use the same cut off date.",
    icon: "calendarNumberOutline",
  },
];
