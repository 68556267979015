import { AggregateClassDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { getPricingForCourse } from "../../classUtils";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { useCatalogueContext } from "../catalogue/useCatalogueContext";
import { useGenericComponents } from "../GenericComponentsProvider";

export const CoursePrice = ({
  season,
  course,
  mode,
}: {
  season: SeasonDto;
  course: AggregateClassDto;
  mode: "enrol" | "trial";
}) => {
  const { Text, View } = useGenericComponents();
  const { company } = useCatalogueContext();

  const pricing = getPricingForCourse(
    mode,
    course.entity.price,
    season.pricingScheme,
    company.settings.trial.price,
  );

  if (typeof pricing === "string") {
    return <Text className="text-label-400 text-grey-600">{pricing}</Text>;
  }

  return (
    <View>
      <View className="flex flex-row items-baseline space-x-0.5">
        <Text className="text-label-600 text-grey-900">
          {formatMoneyFromInteger(pricing.amount)}
        </Text>
        <Text className="text-label-400 text-grey-600">/</Text>
        <Text className="text-label-400 text-grey-600">{pricing.unit}</Text>
      </View>
    </View>
  );
};
