import {
  CompanyIndustry,
  UpdateCompanyRequest,
  UpdateCompanySettingsRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { enumLabel } from "../../../translateUtils";

type UpdateGeneral = Required<UpdateCompanyRequest> &
  UpdateCompanySettingsRequest["general"];

export const useCompanyInformationFormDefinition = () => {
  const form = new FormDefinitionBuilder<UpdateGeneral>()
    .group("Company details", ["name", "industry"])
    .text("name", {
      required: true,
      label: "Company Name",
    })
    .select("industry", {
      label: "Industry",
      data: Object.entries(CompanyIndustry).map(([_, value]) => ({
        value: value,
        label: enumLabel("industry", value),
      })),
    })
    .group("Contact information", ["email", "phone"])
    .text("email", {
      label: "Email address",
      description:
        "This is the email your members can reply to when you contact them through Class Manager.",
      required: true,
    })
    .text("phone", {
      label: "Phone number",
    });

  return form.getDefinition();
};

export const makeCompanyInformationFormRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  async (formData: UpdateGeneral) => {
    await api.companies.updateCompanySettings({
      updateCompanySettingsRequest: {
        general: {
          industry: formData.industry,
        },
      },
    });

    const company = await api.companies.updateCompany({
      id: account.company!.id,
      updateCompanyRequest: {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
      },
    });

    setAccount({
      ...account,
      company,
    });

    return company;
  };
