import { ClassColor } from "@justraviga/classmanager-sdk";

import { ColorPaletteProps } from "shared/lib";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { StatefulColorPalette } from "@/modules/common/form/ColorPalette";

const options: Array<Option<ColorPaletteProps>> = [
  {
    title: "Example values",
    props: [
      { label: "No value (should default to grey)" },
      { label: "Known value (purple)", value: ClassColor.Purple },
    ],
  },
  {
    title: "Variants",
    props: [
      { label: "With description", description: "This is a description" },
    ],
  },
];

export const ColorPalettePage = () => {
  return (
    <div>
      <DocWrapper
        title="Color Palette"
        component={StatefulColorPalette}
        options={options}
      />
    </div>
  );
};
