import { useCatalogueContext } from "shared/components";

import { CreateAccountForm } from "@/modules/auth/catalogue/CreateAccountForm";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { LoginHeader } from "@/modules/auth/common/LoginHeader";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";
import { SwitchAuthType } from "@/modules/auth/common/SwitchAuthType";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { Router } from "@/routing/router";

export const CatalogueCreateAccountPage = () => {
  const { company } = useCatalogueContext();

  return (
    <NonAuthLayout>
      <AuthPageCard
        header={<LoginHeader companyLogo={company.logo} />}
        title={company.name}
        subtitle={`Provide your details for ${company.name}`}
        content={<CreateAccountForm company={company} />}
        footer={
          <SwitchAuthType
            message="Already have an account?"
            onClick={() =>
              Router.push("CatalogueLogin", { slug: company.slug })
            }
            linkText="Log in">
            <PoweredBy />
          </SwitchAuthType>
        }
      />
    </NonAuthLayout>
  );
};
