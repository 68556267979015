import { Currency } from "@justraviga/classmanager-sdk";

import { useAuthState } from "./AuthStateProvider";

/**
 * Using the Currency type from the SDK here means if we add a new currency in the API,
 * eslint will fail here to let us know we need to add it here too.
 */
const currencies: Record<Currency, string> = {
  AUD: "$",
  CAD: "$",
  EUR: "€",
  GBP: "£",
  NZD: "$",
  USD: "$",
};

export const useCurrencySymbol = () => {
  const { account } = useAuthState();

  return currencies[account?.company?.settings.billing.currency ?? "USD"];
};
