import {
  UpdateCompanyLookAndFeelRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { setBrandColors } from "../../../colors";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";

type UpdateSchema = UpdateCompanyLookAndFeelRequest;

export const useLookAndFeelFormDefinition = () => {
  return new FormDefinitionBuilder<UpdateSchema>()
    .group("Select Colour", ["brandColor"])
    .group("Logo", ["logo"])
    .image("logo", {
      aspectRatio: [4, 4],
      description: "We accept PNG and JPG format (maximum 8MB).",
    })
    .color("brandColor", {
      label: "Enter HEX code",
      description: "This is a 6 character combination of letters and numbers.",
    })
    .getDefinition();
};

export const makeLookAndFeelUpdateRequest =
  ({
    api,
    setAccount,
    account,
    setColor = true,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
    setColor?: boolean;
  }) =>
  (formData: UpdateSchema) => {
    // If the logo is a URL, we don't want to send it to the server
    if (formData.logo && /^https?:\/\//.test(formData.logo)) {
      delete formData.logo;
    }

    return api.companies
      .updateCompanyLookAndFeel({
        updateCompanyLookAndFeelRequest: formData,
      })
      .then(company => {
        const generalSettings = company.settings.general;
        if (setColor) {
          setBrandColors(
            generalSettings.brandColor!,
            generalSettings.brandColors!,
          );
        }
        setAccount({ ...account, company });
        return company;
      });
  };
