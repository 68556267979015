import { BillDto } from "@justraviga/classmanager-sdk";

import { quoteDetails } from "../../../../checkoutUtils";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const TransactionSummary = ({
  transactions,
}: {
  transactions: Array<BillDto>;
}) => {
  const { Text, View } = useGenericComponents();
  const { subTotal, discounts, tax, total } = quoteDetails(transactions);

  return (
    <View className={"space-y-2"}>
      <Text className={"overflow-hidden text-heading6-600 text-grey-900"}>
        Summary
      </Text>
      <View className={"flex w-full flex-row items-center justify-between"}>
        <Text className="overlow-hidden text-body-400 text-grey-600">
          Subtotal
        </Text>
        <Text className="text-body-400 text-grey-600">
          {formatMoneyFromInteger(subTotal || 0)}
        </Text>
      </View>
      <View className={"flex w-full flex-row items-center justify-between"}>
        <Text className="overlow-hidden text-body-400 text-grey-600">
          Total discounts
        </Text>
        <Text className="text-body-400 text-grey-600">
          {formatMoneyFromInteger(discounts || 0)}
        </Text>
      </View>
      <View className={"flex w-full flex-row items-center justify-between"}>
        <Text className="overlow-hidden text-body-400 text-grey-600">
          Total tax
        </Text>
        <Text className="text-body-400 text-grey-600">
          {formatMoneyFromInteger(tax || 0)}
        </Text>
      </View>
      <View
        className={
          "flex w-full flex-row items-center justify-between text-heading5-600 text-grey-900"
        }>
        <Text className="overlow-hidden">Total</Text>
        <Text className="">{formatMoneyFromInteger(total || 0)}</Text>
      </View>
    </View>
  );
};
