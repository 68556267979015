import { ContactDto } from "@justraviga/classmanager-sdk";

import { useSharedContactActions } from "shared/components";
import { enumLabel, getFullName } from "shared/lib";

import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { DetailCard } from "@/modules/company/common/DetailCard";
import { ContactCardItem } from "@/modules/company/common/details/ContactItem";

export const CustomerContactCard = ({ contact }: { contact: ContactDto }) => {
  const contactActions = useSharedContactActions();

  let actionItems: ActionMenuItemProps[] = [
    {
      title: "Edit",
      onClick: () => {
        contactActions.showUpdateForm(contact);
      },
      leftIcon: "createOutline",
    },
    {
      title: "Delete",
      destructive: true,
      onClick: () => contactActions.deleteOne(contact),
      leftIcon: "trashOutline",
    },
  ];

  if (!contact.isPrimary) {
    const primaryAction: ActionMenuItemProps[] = [
      {
        title: "Make primary",
        onClick: () => {
          contactActions.makePrimary(contact);
        },
        leftIcon: "swapHorizontalOutline",
      },
    ];

    actionItems = primaryAction.concat(actionItems);
  }

  return (
    <DetailCard
      headerClass={"items-start"}
      header={
        <div
          className={
            "flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-2 md:space-y-0"
          }>
          <span className={"text-body-600 capitalize text-grey-900"}>
            {getFullName(contact)}
          </span>
          <div className={"flex flex-row space-x-2"}>
            {contact.isPrimary && <Chip size={"sm"} label={"Primary"} />}
            {contact.isEmergency && (
              <Chip label={"Emergency"} size={"sm"} variant={"danger"} />
            )}
          </div>
        </div>
      }
      headerButton={
        <ActionMenu
          header={
            <h6 className={"text-heading6-600"}>{getFullName(contact)}</h6>
          }
          showActionsAsBottomSheetOnMobile={true}
          trigger={
            <IconButton
              variant="standard"
              icon={"ellipsisHorizontal"}
              size="sm"
              aria-label="Close"
            />
          }
          items={actionItems}
        />
      }>
      <div className={"flex flex-col space-y-1"}>
        <div className={"p-2"}>
          <ContactCardItem
            icon={"personOutline"}
            text={
              contact.relation && enumLabel("contactRelation", contact.relation)
            }
          />
        </div>
        <div className={"p-2"}>
          <ContactCardItem text={contact.phone} icon={"callOutline"} />
        </div>
        <div className={"p-2"}>
          <ContactCardItem icon={"mailOutline"} text={contact.email} />
        </div>
      </div>
    </DetailCard>
  );
};
