import {
  LessonAdjustmentDto,
  LessonAdjustmentType,
  LessonDto,
} from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { formatDate } from "../../intlFormatter";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeLessonChangeDateRequest,
  useLessonChangeDateForm,
} from "../formDefinitions/lessonChangeDateForm";
import {
  makeLessonAddRequest,
  useLessonAddForm,
} from "../formDefinitions/lessonForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ConfirmationDialogProps } from "../useDefaultEntityActions";

type Model = LessonAdjustmentDto;

export const useSharedLessonActions = ({
  onSuccess,
  cancelDialog,
}: {
  onSuccess: (message: string) => void;
  cancelDialog: ConfirmationDialogProps;
}) => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const defaultActions = usePlatformEntityActions<LessonAdjustmentDto>({
    entity: "lessonAdjustment",
  });

  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.lessonAdjustments.deleteLessonAdjustment({ id: entity.id }),
      ),
    undoCancelledLesson: (entity: Model) =>
      api.lessonAdjustments
        .deleteLessonAdjustment({ id: entity.id })
        .then(() => onSuccess("Lesson restored successfully")),
    showCreateForm: ({
      classId,
      date,
      time,
    }: {
      classId: string;
      date: string;
      time: string;
    }) =>
      defaultActions.showCreateForm({
        title: "Add lesson",
        form: (
          <GenericForm
            apiRequest={makeLessonAddRequest({ api, classId })}
            defaultValues={{ date, time }}
            formDefinitionHook={useLessonAddForm}
            onSuccess={() => onSuccess("Lesson created successfully")}
          />
        ),
      }),
    cancelLesson: (lesson: LessonDto) => {
      const formattedDate = formatDate(lesson.date, "weekdayDayMonthYear");

      cancelDialog({
        title: "Cancel lesson",
        description: `Are you sure you want to cancel this lesson **${formattedDate}**?`,
        cancelText: "I do not want to cancel",
        confirmButtonText: "Cancel lesson",
        confirmButtonStyle: "primary",
      }).then(result => {
        if (result) {
          api.lessonAdjustments
            .cancelLessonLessonAdjustment({
              lessonCancellation: [
                {
                  ...lesson,
                  time: lesson.startTime,
                },
              ],
            })
            .then(() =>
              showAlert({
                content: `The lesson on ${formattedDate} was cancelled successfully`,
              }),
            );
        }
      });
    },
    changeDate: ({
      classId,
      oldDate,
      oldTime,
      redirectToNewLesson,
    }: {
      classId: string;
      oldDate: string;
      oldTime: string;
      redirectToNewLesson?: (newDate: string, newTime: string) => void;
    }) =>
      defaultActions.showUpdateForm({
        title: "Change Date",
        form: (
          <GenericForm
            apiRequest={makeLessonChangeDateRequest({
              api,
              classId,
              oldDate,
              oldTime,
            })}
            defaultValues={{ newDate: oldDate, newTime: oldTime }}
            formDefinitionHook={useLessonChangeDateForm}
            onSuccess={records => {
              const newLessonRecord = records.find(
                record => record.type === LessonAdjustmentType.Addition,
              )!;

              onSuccess("Lesson updated successfully");

              redirectToNewLesson &&
                redirectToNewLesson(
                  newLessonRecord.startAt!,
                  newLessonRecord.time!,
                );
            }}
          />
        ),
      }),
  };
};
