import {
  StripePaymentIntentDtoPaymentMethod,
  StripePaymentMethodCardDataDto,
} from "@justraviga/classmanager-sdk";

import { CardPaymentDetails } from "./PaymentDetails/CardPaymentDetails";
import { getPlatformFunction } from "../../../../platformSpecific";
import { useAuthState } from "../../../AuthStateProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const PaymentSuccessDetails = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const { Text, View } = useGenericComponents();
  const useApi = getPlatformFunction("useApi");

  const { account } = useAuthState();

  const familyId = account?.entityId;

  const { data: paymentDetails, isPending: isPaymentDetailsPending } = useApi(
    "stripeRetrievePaymentIntent",
    {
      transactionDetail: transactionId,
      familyId: familyId ?? "",
    },
    { enabled: !!familyId },
  );

  const getPaymentDetails = (
    paymentDetails: StripePaymentIntentDtoPaymentMethod,
  ) => {
    switch (paymentDetails.type) {
      case "card":
        return (
          <CardPaymentDetails
            cardDetails={paymentDetails.data as StripePaymentMethodCardDataDto}
            id={paymentDetails.id}
          />
        );
      default:
        return null;
    }
  };

  return isPaymentDetailsPending || !paymentDetails?.paymentMethod ? (
    <Text>Loading payment details...</Text>
  ) : (
    <View className={"w-full"}>
      {getPaymentDetails(paymentDetails.paymentMethod)}
    </View>
  );
};
