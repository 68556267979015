import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";

export const CenteredLoadingSpinner = () => {
  return (
    <div
      className={"flex h-full w-full items-center justify-center bg-white p-8"}>
      <LoadingSpinnerDark />
    </div>
  );
};
