import {
  FormBuilder,
  getPlatformFunctions,
  WaiverEntityWithAgreement,
} from "shared/lib";

import {
  makeSignPendingAgreementsRequest,
  SignAgreementsFormData,
  useSignPendingAgreementsForm,
} from "./useSignPendingAgreementsForm";
import { useAuthState } from "../AuthStateProvider";

export const useSignPendingWaiversForm = (
  pendingWaivers: WaiverEntityWithAgreement[],
  formBuilder: FormBuilder,
) => {
  const { api } = getPlatformFunctions();
  const { account } = useAuthState();
  const companyName: string = account?.company?.name || "";

  const signAgreements = ({ signatoryName }: SignAgreementsFormData) =>
    makeSignPendingAgreementsRequest({
      api,
      familyId: account?.entityId || "",
      waiverIds: pendingWaivers.map(
        waiverAgreement => waiverAgreement.props.id,
      ),
      signatoryName,
    });

  const {
    form,
    formHandlers: { handleSubmit, formState },
  } = useSignPendingAgreementsForm(formBuilder, signAgreements);

  const handleFormSubmission = handleSubmit(signAgreements);

  return {
    companyName,
    form,
    formState,
    handleFormSubmission,
  };
};
