import { CompanyDto, PublicCompanyDto } from "@justraviga/classmanager-sdk";

import { useAuthState } from "shared/components";
import { breakpoint, cn } from "shared/lib";

import { UserAccountLinks } from "@/modules/common/nav/UserAccountLinks";
import { CompanyLogo } from "@/modules/company/CompanyLogo";
import { Router } from "@/routing/router";

export const CustomerHeader = ({
  company,
}: {
  company?: PublicCompanyDto | CompanyDto | null;
}) => {
  const { account } = useAuthState();

  if (!company) {
    company = account?.company;
  }

  if (!company) return null;

  const goToDashboard = () => {
    Router.push("CustomerHome");
  };

  return (
    <div className="flex flex-row items-center justify-between px-8 py-5">
      <div
        role="link"
        className="flex cursor-pointer flex-row items-center justify-start gap-x-4"
        onClick={goToDashboard}>
        <CompanyLogo logo={company.logo} />
        <span
          className={cn("text-heading4-600 text-grey-900", {
            "text-heading5-600": !breakpoint.md,
            "text-heading4-600": breakpoint.md,
          })}>
          {company.name}
        </span>
      </div>
      <UserAccountLinks />
    </div>
  );
};
