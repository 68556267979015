import {
  CustomFieldActions,
  useSharedCustomFieldActions,
} from "shared/components";
import { colors } from "shared/lib";

import { ActionBanner } from "@/modules/common/ui/ActionBanner";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const useCustomFieldActions = (): CustomFieldActions => {
  return useSharedCustomFieldActions(CustomFieldActionBanner);
};

// todo: We should move the ActionBanner component to shared, then this whole file can disappear
// eslint-disable-next-line react-refresh/only-export-components
const CustomFieldActionBanner = () => (
  <ActionBanner
    icon={
      <Icon name={"informationCircle"} size={24} color={colors.blue[600]} />
    }
    title={"Need to change the answer type?"}
    description={
      "Once you’ve set an answer type for a custom field it cannot be changed without affecting the data already provided. \n" +
      "Create a new one and delete the original instead."
    }
  />
);
