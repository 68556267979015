import * as Sentry from "@sentry/react";

// check if should be enabled
import { appEnvironment, appVersion } from "@/lib/appVersion";

const sentryEnabled = (): boolean => {
  return ["dev", "stage", "prod"].includes(appEnvironment);
};

const sentryReplaySessions = (): number => {
  return appEnvironment === "prod" ? 1.0 : 0;
};
const sentryReplayOnError = (): number => {
  return 1.0;
};

// Configure Sentry
if (sentryEnabled()) {
  try {
    Sentry.init({
      dsn: "https://fbb612483d48fb742f27447ee77bce32@o4508171079385088.ingest.de.sentry.io/4508171086659664",
      integrations: [Sentry.replayIntegration()],
      environment: appEnvironment,
      release: appVersion,
      replaysSessionSampleRate: sentryReplaySessions(),
      replaysOnErrorSampleRate: sentryReplayOnError(),
    });
    Sentry.setContext("platform", { name: "web" });
  } catch (e) {
    // we don't want to the apps to crash if this fails
    console.error(e);
  }
}
