import { IdPrefix } from "@justraviga/classmanager-sdk";

import {
  CacheDependencyMap,
  CollectionCacheEntry,
  EntityCacheEntry,
  Id,
} from "./cacheTypes";

/**
 * Entity cache stores things in this format:
 * {
 *   'lesson_123': { expiresAt: Date, data: { id: 'lesson_123', ... } },
 *   'lesson_456': { expiresAt: Date, data: { id: 'lesson_456', ... } },
 * }
 */
export type EntityCache = Map<Id, EntityCacheEntry>;
/**
 * Collection cache stores things in this format:
 * {
 *   '/v1/lessons': {
 *     expiresAt: Date,
 *     data: {
 *        ids: [ 'lesson_123', 'lesson_456', ... } ],
 *        pagination: { ... },
 *     }
 *   },
 * }
 * Basically, it is the same as the API response, but with the data array
 * replaced with an array of IDs.
 */
export type CollectionCache = Map<string, CollectionCacheEntry>;

const entityCache: EntityCache = new Map();
const collectionCache: CollectionCache = new Map();

export const sdkCache = {
  clear: () => {
    entityCache.clear();
    collectionCache.clear();
  },
  clearByPrefix: (
    primaryPrefix: IdPrefix,
    cacheDependencyMap: CacheDependencyMap,
  ) => {
    const dependentPrefixes = cacheDependencyMap[primaryPrefix] ?? [];
    const prefixesToClear = [primaryPrefix, ...dependentPrefixes];
    prefixesToClear.forEach(prefix => {
      entityCache.forEach((_, key) => {
        if (key.startsWith(prefix)) {
          entityCache.delete(key);
        }
      });
      collectionCache.forEach((value, key) => {
        if (value.data.ids.some(id => id.startsWith(prefix))) {
          collectionCache.delete(key);
        }
      });
    });
  },
  deleteCollection: (key: string) => {
    collectionCache.delete(key);
  },
  deleteExpiredCacheEntries: () => {
    const now = new Date();
    entityCache.forEach((value, key) => {
      if (value.expiresAt < now) {
        entityCache.delete(key);
      }
    });
    collectionCache.forEach((value, key) => {
      if (value.expiresAt < now) {
        collectionCache.delete(key);
      }
    });
  },
  getEntity: (id: Id) => {
    return entityCache.get(id);
  },
  getCollection: (key: string) => {
    return collectionCache.get(key);
  },
  getCacheContents: () => {
    return {
      entityCache: Object.fromEntries(entityCache),
      collectionCache: Object.fromEntries(collectionCache),
    };
  },
  setEntity: (id: Id, value: EntityCacheEntry) => {
    entityCache.set(id, value);
  },
  setCollection: (key: string, value: CollectionCacheEntry) => {
    collectionCache.set(key, value);
  },
};
