import { colors } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export const NumberInputButtons = ({
  increment,
  decrement,
}: {
  increment: () => void;
  decrement: () => void;
}) => {
  return (
    <div className="flex flex-col">
      <button
        type="button"
        onClick={increment}
        aria-label="Increase the value by one"
        className="r -mr-[12px] flex h-[19px] w-[32px] flex-col items-center justify-end rounded-tr hover:bg-grey-200"
        tabIndex={-1}>
        <Icon name="chevronUp" size={16} color={colors.grey[500]} />
      </button>
      <button
        type="button"
        onClick={decrement}
        aria-label="Decrease the value by one"
        className="-mr-[12px] flex h-[19px] w-[32px] flex-col items-center justify-start rounded-br hover:bg-grey-200"
        tabIndex={-1}>
        <Icon name="chevronDown" size={16} color={colors.grey[500]} />
      </button>
    </div>
  );
};
