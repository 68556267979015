import { FC, useState } from "react";

import {
  DiscountSchemeDto,
  DiscountSchemeValueDto,
  DiscountType,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { getDiscountSchemeApplicationDetails } from "../../discountScheme";
import { moneyIntegerToFloat } from "../../moneyUtils";
import { useCurrencySymbol } from "../useCurrencySymbol";

type Dto = DiscountSchemeValueDto;
export const useDiscountSchemeTiersDatatable = ({
  Datatable,
  api,
  discountScheme,
  showCreateForm,
  showUpdateForm,
  noContentDescription,
  deleteOne,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, object, Dto>;
  }>;
  api: Api;
  discountScheme: DiscountSchemeDto;
  showCreateForm: () => void;
  showUpdateForm: (model: Dto, discountScheme: DiscountSchemeDto) => void;
  noContentDescription: string;
  deleteOne: (item: Dto) => Promise<void>;
}) => {
  const currency = useCurrencySymbol();
  const [maxTier, setMaxTier] = useState<number>(0);
  const [lastTierId, setLastTierId] = useState<string | undefined>(undefined);

  const fetchData = async (query: DatatableQueryProps) => {
    const request = {
      ...query.toRequest(),
      selectAll: true,
      where: {
        ...query.toRequest().where,
        discountSchemeId: {
          equals: discountScheme.id,
        },
      },
      sort: {
        quantity: SortSchema.Asc,
      },
    };

    const response =
      await api.discountSchemeValues.listDiscountSchemeValue(request);

    setMaxTier(response.pagination.total);

    if (response.data.length > 0) {
      setLastTierId(response.data[response.data.length - 1].id);
    }

    return response;
  };

  const discountValue = (row: Dto): string => {
    if (discountScheme.type === DiscountType.Percent) {
      return `${row.amount}%`;
    }

    return `${currency}${moneyIntegerToFloat(row.amount)}`;
  };

  const getFirstColumnValue = (row: Dto): string => {
    return getDiscountSchemeApplicationDetails(discountScheme)[
      "firstColumnMapper"
    ](row, row.id === lastTierId);
  };

  const config: DatatableConfiguration<Dto, object, Dto> = {
    id: "discountSchemeValues",
    title: "Discount list",
    titleSize: "sm",
    createAction: showCreateForm,
    createVariant: "brand",
    createLabel: "Add discount tier",
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "No discount tiers",
        description: noContentDescription,
      },
    },
    hasSearch: false,
    hasPagination: false,
    placeholdersCount: 5,
    scrollEnabledOnMobile: false,
    showTotalRecords: false,
    fetchData,
    rowActions: {
      edit: row => showUpdateForm(row, discountScheme),
      delete: deleteOne,
      deleteEnabled: item => item.quantity === maxTier,
    },
    columns: [
      {
        label:
          getDiscountSchemeApplicationDetails(discountScheme).firstColumnName,
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text: getFirstColumnValue(row.item),
          };
        },
      },
      {
        label:
          discountScheme.type === DiscountType.Percent
            ? "Percentage off %"
            : "Fixed amount off",
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text: discountValue(row.item),
          };
        },
      },
    ],
    mobileColumn: {
      title: row => getFirstColumnValue(row.item),
      subtitle: row => discountValue(row.item),
      isPendingDeleted: row => !!row.item.deletedAt,
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
    totalRecords: maxTier,
  };
};
