import { ContentPlaceholder } from "shared/components";

export const TypeToSearchPlaceholder = () => (
  <div className="mt-5 md:my-10">
    <ContentPlaceholder
      icon={"searchOutline"}
      title="Start typing to search"
      description="Find students, families, staff, classes, seasons and more..."
    />
  </div>
);
