import { PropsWithChildren, useEffect } from "react";

import { PublicCompanyDto } from "@justraviga/classmanager-sdk";

import { CatalogueContext } from "./catalogueContext";
import { setPublicCompanyId } from "../../api";
import { useStripeLib } from "../../third-party/stripe/useStripeLib";

export const CatalogueContextProvider = ({
  company,
  children,
}: PropsWithChildren<{ company: PublicCompanyDto }>) => {
  const { setStripeIntegration } = useStripeLib();
  // Set the global variable that will be used by the SDK middleware
  setPublicCompanyId(company.id);

  useEffect(() => {
    setStripeIntegration(company.settings.integrations.stripe!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    company.settings.integrations.stripe?.connectAccountId,
    company.settings.integrations.stripe?.region,
  ]);

  const value: CatalogueContext = {
    company: company,
  };

  return (
    <CatalogueContext.Provider value={value}>
      {children}
    </CatalogueContext.Provider>
  );
};
