import {
  LessonAdjustmentReason,
  LessonDto,
  LessonStatus,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../platformSpecific";

export interface LessonEntity extends LessonDto {
  isOnHoliday: boolean;
  isCancelled: boolean;
}

export const useLessonEntities = (courseId: string | null) => {
  const { useApi } = getPlatformFunctions();

  const { data: lessons } = useApi(
    "listLesson",
    {
      where: { classId: { in: [courseId!] } },
    },
    { enabled: !!courseId },
  );

  const cancellationReasons = [
    LessonAdjustmentReason.CancelledLesson,
    LessonAdjustmentReason.CancelledClass,
  ];

  return (lessons ?? []).map(lesson => ({
    ...lesson,
    isOnHoliday: lesson.holidays.length > 0,
    isCancelled:
      lesson.status == LessonStatus.Inactive &&
      lesson.adjustments.some(adjustment =>
        cancellationReasons.includes(adjustment.reason),
      ),
  }));
};
