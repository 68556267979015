import { createTransactions, CustomTransactionItem } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { Button } from "@/modules/common/ui/button/Button";
import { Router } from "@/routing/router";

export const TransactionsCreatePageFooter = ({
  families,
  date,
  items,
  resetForm,
}: {
  families: string[];
  date: Date;
  items: CustomTransactionItem[];
  resetForm: () => void;
}) => {
  function submit({ createAndAddAnother }: { createAndAddAnother: boolean }) {
    createTransactions({
      api,
      families,
      date,
      items,
      onSuccess: () => {
        resetForm();

        if (!createAndAddAnother) {
          Router.push("TransactionList");
        }
      },
    });
  }

  return (
    <div className={"flex justify-between border-t border-grey-300 px-5 py-4"}>
      <div className={"hidden md:block"}>
        <Button
          variant={"secondary"}
          text={"Cancel"}
          onClick={() => {
            resetForm();
            window.history.back();
          }}
        />
      </div>

      <div
        className={
          "flex w-full flex-col-reverse gap-y-4 md:w-fit md:flex-row md:gap-y-0 md:space-x-5"
        }>
        <Button
          variant={"secondary"}
          text={"Create and add another"}
          onClick={() => {
            submit({ createAndAddAnother: true });
          }}
        />

        <Button
          variant={"brand"}
          text={"Create"}
          onClick={() => {
            submit({ createAndAddAnother: false });
          }}
        />
      </div>
    </div>
  );
};
