import { MultiSelectComponent, useFamiliesSearchData } from "shared/lib";

export const FamiliesSearch = ({
  selectedIds,
  onSelect,
  MultiSelect,
}: {
  selectedIds: string[];
  onSelect: (ids: string[]) => void;
  MultiSelect: MultiSelectComponent;
}) => {
  const { familySelectOptions, isLoading } = useFamiliesSearchData();

  return (
    <MultiSelect
      label={"Select family"}
      required={true}
      loading={isLoading}
      localSearch={true}
      data={familySelectOptions}
      value={selectedIds}
      selectedOnTop={false}
      onSelect={value => onSelect(value.map(v => v as string))}
    />
  );
};
