import { useLessonEntities } from "../../../../data/useLessonList";
import { formatDate } from "../../../../intlFormatter";
import { LessonChip } from "../../../course/LessonChip";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CoursePreviewLessonList = ({ classId }: { classId: string }) => {
  const { Text, View } = useGenericComponents();
  const lessons = useLessonEntities(classId);

  return (
    <View className={"flex flex-col space-y-1"}>
      {lessons?.map((lesson, i) => {
        const isGrey = lesson.isCancelled || lesson.isOnHoliday;

        return (
          <View
            className={"flex flex-row"}
            key={`${lesson.classId}-lesson-${i}`}>
            <Text
              className={`text-body-400 pr-2 ${isGrey ? "text-grey-600" : "text-grey-900"}`}>
              {formatDate(lesson.date, "dayMonthYear")}
            </Text>
            <LessonChip lesson={lesson} />
          </View>
        );
      })}
    </View>
  );
};
