import { createContext } from "react";

import { PublicCompanyDto } from "@justraviga/classmanager-sdk";

export interface CatalogueContext {
  company: PublicCompanyDto;
}

/**
 * The purpose of the Catalogue Context is to keep track of the current company
 * being browsed in the catalogue. This could be different from the company the user
 * is currently logged in to (which is held in the Auth Context).
 */
export const CatalogueContext = createContext(
  undefined as unknown as CatalogueContext,
);
