import { Nav, NavLink } from "@/modules/common/nav/Nav";
import { Router } from "@/routing/router";

const topLinks: NavLink[] = [
  {
    path: Router.AdminHome(),
    label: "Dashboard",
    icon: "gridOutline",
  },
  {
    path: Router.DocsHome(),
    label: "Documentation",
    icon: "bookOutline",
    subItems: [
      {
        path: Router.ActionBannerDoc(),
        label: "Action Banner",
      },
      {
        path: Router.AlertDialogDoc(),
        label: "Alert Dialog",
      },
      {
        path: Router.AlertDoc(),
        label: "Alert",
      },
      {
        path: Router.AvatarDoc(),
        label: "Avatar",
      },
      {
        path: Router.BannerDoc(),
        label: "Banner",
      },
      {
        path: Router.BreadcrumbsDoc(),
        label: "Breadcrumbs",
      },
      {
        path: Router.ButtonDoc(),
        label: "Button",
      },
      {
        path: Router.QuickActionButtonDoc(),
        label: "Button (Quick Action)",
      },
      {
        path: Router.CardDoc(),
        label: "Card",
      },
      {
        path: Router.CardContainerDoc(),
        label: "CardContainer",
      },
      {
        path: Router.CheckboxDoc(),
        label: "Checkbox",
      },
      {
        path: Router.ChipDoc(),
        label: "Chip",
      },
      {
        path: Router.ColorPaletteDoc(),
        label: "Color Palette",
      },
      {
        path: Router.ColorPickerDoc(),
        label: "Color Picker",
      },
      {
        path: Router.ContentPlaceholderDoc(),
        label: "Content Placeholder",
      },
      {
        path: Router.DatatableDoc(),
        label: "Data Table",
      },
      {
        path: Router.DayMonthPickerDoc(),
        label: "Day Month Picker",
      },
      {
        path: Router.DecimalInputDoc(),
        label: "Decimal Input",
      },
      {
        path: Router.FormBuilderDoc(),
        label: "Form Builder",
      },
      {
        path: Router.HorizontalSeparatorDoc(),
        label: "Horizontal Separator",
      },
      {
        path: Router.IconButtonDoc(),
        label: "Icon Button",
      },
      {
        path: Router.IntegerInputDoc(),
        label: "Integer Input",
      },
      {
        path: Router.ListDoc(),
        label: "List",
      },
      {
        path: Router.ActionMenuDoc(),
        label: "Action Menu",
      },
      {
        path: Router.MultiSelectDoc(),
        label: "Multi Select",
      },
      {
        path: Router.PasswordInputDoc(),
        label: "Password Input",
      },
      {
        path: Router.RangeDatePickerDoc(),
        label: "Date Range Picker",
      },
      {
        path: Router.SearchInputDoc(),
        label: "Search Input",
      },
      {
        path: Router.SelectDoc(),
        label: "Select",
      },
      {
        path: Router.SheetDoc(),
        label: "Sheet",
      },
      {
        path: Router.TabsDoc(),
        label: "Tabs",
      },
      {
        path: Router.TimePickerDoc(),
        label: "Time Picker",
      },
      {
        path: Router.SingleDatePickerDoc(),
        label: "Date Picker",
      },
      {
        path: Router.SwitchDoc(),
        label: "Switch",
      },
      {
        path: Router.TextInputDoc(),
        label: "Text Input",
      },
      {
        path: Router.ToastDoc(),
        label: "Toast",
      },
      {
        path: Router.ToolTipDoc(),
        label: "Tool Tip",
      },
    ].sort((a, b) => a.label.localeCompare(b.label)),
  },
];

export const AdminNav = () => {
  return <Nav topLinks={topLinks} bottomLinks={[]} />;
};
