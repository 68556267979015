import { ReactNode } from "react";

import { TaxRateDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import {
  makeCreateRequest,
  makeUpdateRequest,
  useCreateFormDefinition,
  useUpdateFormDefinition,
} from "../formDefinitions/taxRateForm";
import { GenericFormComponent } from "../genericFormHooks";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Dto = TaxRateDto;

export const useSharedTaxRateActions = (
  api: Api,
  defaultActions: DefaultEntityActions<Dto>,
  GenericForm: GenericFormComponent,
  renderTaxAmountBelowUpdateForm: (item: Dto) => ReactNode,
) => {
  return {
    showCreateForm: () =>
      defaultActions.showCreateForm({
        title: "Create tax rate",
        form: (
          <GenericForm
            apiRequest={makeCreateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={useCreateFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),
    showUpdateForm: (dto: Dto) =>
      defaultActions.showUpdateForm({
        title: "Edit tax rate",
        form: (
          <>
            <GenericForm
              apiRequest={makeUpdateRequest({ api, id: dto.id })}
              defaultValues={dto}
              formDefinitionHook={useUpdateFormDefinition}
              onSuccess={defaultActions.onUpdateSuccess}
            />
            {renderTaxAmountBelowUpdateForm(dto)}
          </>
        ),
      }),
    makeDefault: (dto: Dto) => {
      api.taxRates
        .makeDefaultTaxRate({
          id: dto.id,
        })
        .then(() => {
          defaultActions.onUpdateSuccess();
        });
    },
    deleteOne: (dto: Dto) =>
      defaultActions.deleteOne(() => {
        return api.taxRates.deleteTaxRate({
          id: dto.id,
        });
      }),
  };
};
