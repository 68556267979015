import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  SearchInput,
  SearchInputProps,
} from "@/modules/common/form/SearchInput";

const options: Array<Option<SearchInputProps>> = [
  {
    title: "Variations",
    props: [
      { label: "Small, Rounded", borderRadius: "rounded", height: "sm" },
      { label: "Medium, Rounded", borderRadius: "rounded", height: "md" },
      {
        label: "Small, Rounded-full",
        borderRadius: "rounded-full",
        height: "sm",
      },
      {
        label: "Medium, Rounded-full",
        borderRadius: "rounded-full",
        height: "md",
      },
    ],
  },
  {
    title: "Clear button as icon",
    props: [
      {
        label: "Small, rounded",
        borderRadius: "rounded",
        height: "sm",
        clearBtn: "icon",
        value: "Some value",
      },
      {
        label: "Small, rounded-full",
        borderRadius: "rounded-full",
        height: "sm",
        clearBtn: "icon",
        value: "Some value",
      },
      {
        label: "Medium, rounded",
        borderRadius: "rounded",
        height: "md",
        clearBtn: "icon",
        value: "Some value",
      },
      {
        label: "Medium, rounded-full",
        borderRadius: "rounded-full",
        height: "md",
        clearBtn: "icon",
        value: "Some value",
      },
    ],
  },
  {
    title: "Clear button as text",
    props: [
      {
        label: "Small, rounded",
        borderRadius: "rounded",
        height: "sm",
        clearBtn: "text",
        value: "Some value",
      },
      {
        label: "Small, rounded-full",
        borderRadius: "rounded-full",
        height: "sm",
        clearBtn: "text",
        value: "Some value",
      },
      {
        label: "Medium, rounded",
        borderRadius: "rounded",
        height: "md",
        clearBtn: "text",
        value: "Some value",
      },
      {
        label: "Medium, rounded-full",
        borderRadius: "rounded-full",
        height: "md",
        clearBtn: "text",
        value: "Some value",
      },
    ],
  },
];

export const SearchInputPage = () => {
  return (
    <>
      <DocWrapper
        title="Search Input"
        component={SearchInput}
        options={options}
      />
    </>
  );
};
