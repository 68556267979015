import { useBasket } from "../../../basket/useBasket";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ButtonComponent } from "../../../interfaces";
import { Chip } from "../../../ui/Chip";

interface GoToSummaryButtonProps {
  Button: ButtonComponent;
  gotoBasket: () => void;
}

export const GoToSummaryButton = ({
  Button,
  gotoBasket,
}: GoToSummaryButtonProps) => {
  const { View } = useGenericComponents();
  const { count } = useBasket();

  return (
    <View
      className={
        // absolute for mobile
        "absolute bottom-7 md:bottom-14 flex left-0 right-0 justify-center"
      }>
      {count > 0 && (
        <View
          className={
            // mx-auto for mobile
            "w-full px-5 bg-brand-600 rounded-md max-w-sm py-4 flex flex-row items-center justify-between shadow mx-auto"
          }>
          <Chip label={`${count} selected`} variant={"neutral"} />
          <Button
            variant={"secondary"}
            text={"Go to summary"}
            size={"xs"}
            onClick={gotoBasket}
          />
        </View>
      )}
    </View>
  );
};
