import { useAuthState } from "../components/AuthStateProvider";
import { useCatalogueContext } from "../components/catalogue/useCatalogueContext";

export const useFamilyCompany = () => {
  const { account } = useAuthState();
  const { company } = useCatalogueContext();

  if (account?.company) {
    return { company: account.company };
  }

  return { company: company ?? null };
};
