import { DayMonthPickerProps } from "shared/lib";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { DayMonthPicker } from "@/modules/common/form/DayMonthPicker";

const locale = "en-us";

const options: Array<Option<DayMonthPickerProps>> = [
  {
    title: "Labels & Descriptions",
    props: [
      {
        label: "With label",
        locale,
      },
      {
        label: "A required field, with label",
        required: true,
        locale,
      },
      {
        label: "With label & description",
        description: "This is a description",
        locale,
      },
    ],
  },
  {
    title: "States",
    props: [
      {
        label: "Empty field",
        locale,
      },
      {
        label: "Field with value",
        value: "12-14",
        locale,
      },
    ],
  },
  {
    title: "Localisation",
    props: [
      {
        label: "French (day, then month)",
        value: "12-14",
        locale: "fr-fr",
      },
      {
        label: "Chinese (month, then day)",
        value: "12-14",
        locale: "zh-CN",
      },
    ],
  },
];

export const DayMonthPickerPage = () => (
  <DocWrapper
    title={"Day Month Picker"}
    component={DayMonthPicker}
    options={options}
  />
);
