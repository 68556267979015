import { FC, ReactNode } from "react";

import { MessageType } from "@justraviga/classmanager-sdk";
import { AggregateMessageDto } from "@justraviga/classmanager-sdk/dist/models/AggregateMessageDto";

import { Api } from "../../api";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { formatDateTime } from "../../intlFormatter";
import { messageIsDraft } from "../../messageUtils";
import { removeMarkdown, truncateString } from "../../stringUtils";
import { useSharedEmailActions } from "../actions/useSharedEmailActions";
import { useGenericComponents } from "../GenericComponentsProvider";
import { Chip } from "../ui/Chip";

type Dto = AggregateMessageDto & {
  id: string;
};
type FilterForm = object;
type Actions = ReturnType<typeof useSharedEmailActions>;

const getMobileSubtitle = (item: Dto) =>
  [
    item.entity.status === "draft" ? "Draft" : "",
    item.entity.status === "sent"
      ? // todo - use real date
        formatDateTime(new Date(), "dayMonthYear")
      : "",
    item.entity.body.length ? getBodyPreviewText(item) : false,
  ]
    .filter(Boolean)
    .join(" • ");

const getBodyPreviewText = (item: Dto) => {
  const withoutMarkdown = removeMarkdown(item.entity.body);
  const withoutNewLines = withoutMarkdown.replace(/\n/g, " ");
  return item.entity.body.length ? truncateString(withoutNewLines, 50) : "-";
};

export const useEmailsDatatable = ({
  Datatable,
  api,
  showCreateForm,
  showUpdateForm,
  deleteOne,
  showPreview,
  editAndResend,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, object>;
  }>;
  api: Api;
  showCreateForm: () => void;
  showUpdateForm: (id: string) => void;
  editAndResend: (item: AggregateMessageDto) => void;
  deleteOne: Actions["deleteOne"];
  showPreview: (id: string) => void;
}): { datatable: ReactNode } => {
  const { Text, View } = useGenericComponents();

  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    const response = await api.messages.listMessage({
      ...request,
      where: { ...request.where, type: { equals: MessageType.Custom } },
    });

    return {
      ...response,
      data: response.data.map(item => ({
        ...item,
        id: item.entity.id,
      })),
    };
  };

  const config: DatatableConfiguration<Dto, FilterForm> = {
    id: `emails`,
    title: "Email",
    createLabel: "Create",
    createAction: showCreateForm,
    rowActions: {
      title: item => item.entity.subject,
      delete: deleteOne,
      click: item =>
        item.entity.sentAt
          ? showPreview(item.entity.id)
          : showUpdateForm(item.entity.id),
      additionalRowActions: item => [
        item.entity.sentAt
          ? {
              icon: "sendOutline",
              label: "Edit and resend",
              onClick: () => editAndResend(item),
            }
          : {
              icon: "createOutline",
              label: "Edit",
              onClick: () => showUpdateForm(item.entity.id),
            },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "You do not have any emails currently.",
        description: "",
      },
    },
    hasPagination: true,
    hasSearch: true,
    showTotalRecords: true,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Content",
        placeholder: "tile",
        value: row => ({
          type: "custom",
          children: (
            <View className="flex flex-col space-y-1 overflow-hidden">
              <View className="flex flex-row space-between items-center space-x-3">
                {row.item.entity.subject.length ? (
                  <Text
                    className="text-body-600 font-semibold text-grey-900"
                    truncate={true}>
                    {row.item.entity.subject}
                  </Text>
                ) : (
                  <Text className="text-body-400 text-grey-900" truncate={true}>
                    No subject
                  </Text>
                )}
                {messageIsDraft(row.item) && (
                  <Chip label="Draft" size="sm" variant="info" />
                )}
              </View>
              <Text className="text-label-400 text-grey-600" truncate={true}>
                {getBodyPreviewText(row.item)}
              </Text>
            </View>
          ),
        }),
      },
      {
        label: "Date sent",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: row.item.entity.sentAt
            ? formatDateTime(row.item.entity.sentAt, "dayMonthYear")
            : "",
        }),
      },
      {
        label: "Recipients",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: row.item.stats.recipients.count,
        }),
      },
    ],
    mobileColumn: {
      hasImage: false,
      children: row => (
        <View className="flex flex-col space-y-1">
          <View className="flex flex-row justify-start items-center space-x-3">
            <View className="flex-1 overflow-hidden">
              {row.item.entity.subject.length ? (
                <Text
                  className="text-body-600 font-semibold text-grey-900"
                  truncate={true}>
                  {row.item.entity.subject}
                </Text>
              ) : (
                <Text className="text-body-400 text-grey-900" truncate={true}>
                  No subject
                </Text>
              )}
            </View>
            {messageIsDraft(row.item) && (
              <View className="flex-none">
                <Chip label="Draft" size="sm" variant="info" />
              </View>
            )}
          </View>
          <Text className="text-label-400 text-grey-600" truncate={true}>
            {getMobileSubtitle(row.item)}
          </Text>
        </View>
      ),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
