import {
  AggregateClassDto,
  AggregateEnrolmentDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { StudentNotEligible } from "./StudentNotEligible";
import { validateStudentOnCourse } from "../../../../basket/basketUtils";
import { formatDate } from "../../../../intlFormatter";
import { getFullName } from "../../../../personUtil";
import { getSeasonCutOffDate } from "../../../../seasonUtils";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useCatalogueContext } from "../../../catalogue/useCatalogueContext";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { BannerComponent, IconComponent } from "../../../interfaces";

export const BasketItemStudentErrors = ({
  students,
  course,
  season,
  enrolments,
  useStudentActions,
  enforceValidation,
  Banner,
  Icon,
}: {
  students: StudentDto[];
  course: AggregateClassDto;
  season: SeasonDto;
  enrolments: AggregateEnrolmentDto[];
  useStudentActions: () => StudentImplementedActions;
  enforceValidation: boolean;
  Banner: BannerComponent;
  Icon: IconComponent;
}) => {
  const { Text, View } = useGenericComponents();
  const { showAddDateOfBirthForm } = useStudentActions();

  const { company } = useCatalogueContext();

  return (
    <View className={"space-y-2"}>
      {students.map(student => {
        const { valid, error: studentError } = validateStudentOnCourse(
          student,
          course.entity,
          season,
          enrolments,
          company,
          true,
        );

        if (valid) {
          return null;
        }

        switch (studentError) {
          case "studentNeedsAge":
            return (
              <View key={student.id}>
                <Banner
                  content={`You need to add a date of birth for ${getFullName(student)}.`}
                  variant={enforceValidation ? "error" : "warning"}
                  action={{
                    text: "Add",
                    onClick: () => showAddDateOfBirthForm(student),
                  }}
                />
              </View>
            );
          case "incorrectAge":
            return (
              <View key={student.id}>
                <StudentNotEligible
                  Icon={Icon}
                  date={formatDate(
                    getSeasonCutOffDate(
                      company.settings.enrolment,
                      season.startAt,
                    ),
                    "dayMonthYear",
                  )}
                  student={student}
                />
              </View>
            );
          case "alreadyEnrolled":
            return (
              <View key={student.id}>
                <View className={"flex flex-row space-x-1 items-center"}>
                  <Icon name={"alertCircle"} color={"red"} size={24} />
                  <Text className={"text-red-600 text-sm"}>
                    {getFullName(student)} is already enrolled for this course.
                  </Text>
                </View>
              </View>
            );
          default:
            return (
              <View key={student.id}>
                <Banner
                  content={`There is an error with enrolling ${getFullName(student)} on this course.`}
                  variant={enforceValidation ? "error" : "warning"}
                />
              </View>
            );
        }
      })}
    </View>
  );
};
