import { GlobalSearchResults } from "shared/lib";

const SingleResultReturned = function () {
  return (
    <>
      1&nbsp;
      <span className="text-grey-600">result</span>
    </>
  );
};

const AllResultsReturned = function ({ count }: { count: number }) {
  return (
    <>
      {count}&nbsp;
      <span className="text-grey-600">results</span>
    </>
  );
};

const SomeResultsReturned = function ({
  count,
  totalReturnedCount,
}: {
  count: number;
  totalReturnedCount: number;
}) {
  return (
    <>
      {totalReturnedCount}&nbsp;/&nbsp;
      <span className="text-grey-600">{count} results</span>
    </>
  );
};

export const SearchResultsCount = function ({
  results,
}: {
  results: GlobalSearchResults;
}) {
  const totalReturnedCount = Object.entries(results.data).reduce(
    (acc, [_, value]) => {
      return acc + (value?.documents?.length || 0);
    },
    0,
  );

  const count = results.totalCount;

  const renderResultCount = () => {
    switch (true) {
      case count === 1:
        return <SingleResultReturned />;
      case count === totalReturnedCount:
        return <AllResultsReturned count={count} />;
      default:
        return (
          <SomeResultsReturned
            count={count}
            totalReturnedCount={totalReturnedCount}
          />
        );
    }
  };

  return (
    <div
      className="flex justify-between border-b border-grey-400 p-5"
      data-testid="search-results-count">
      <div>
        <p className="text-heading6-600 text-grey-900">Results</p>
      </div>
      <div>
        <p className="text-heading6-400">{renderResultCount()}</p>
      </div>
    </div>
  );
};
