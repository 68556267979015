import {
  StripeDeletePaymentMethodRequest,
  StripeSetDefaultPaymentMethodRequest,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions, queryClient, showAlert } from "shared/lib";

import { ConfirmationDialogProps } from "../useDefaultEntityActions";

export const useSharedPaymentMethodActions = (
  showConfirmationDialog: ConfirmationDialogProps,
) => {
  const { api } = getPlatformFunctions();

  return {
    deletePaymentMethod: ({
      paymentMethodId,
      familyId,
      last4,
    }: { last4: string } & StripeDeletePaymentMethodRequest) => {
      return new Promise<void>((resolve, reject) => {
        showConfirmationDialog({
          title: "Delete payment method",
          description: `Are you sure you want to delete the card ${last4}? This action cannot be undone.`,
          confirmButtonText: "Delete",
          confirmButtonStyle: "destructive",
        }).then(result => {
          if (!result) {
            return;
          }

          return api.stripe
            .stripeDeletePaymentMethod({ paymentMethodId, familyId })
            .then(() => {
              showAlert({
                content: `The card ${last4} was deleted successfully`,
              });
              queryClient.invalidateQueries({
                queryKey: ["listStripePaymentMethod"],
              });
              resolve(undefined);
            })
            .catch(reject);
        });
      });
    },
    setDefaultPaymentMethod: (
      setDefaultPaymentMethodRequest: StripeSetDefaultPaymentMethodRequest,
    ) => {
      return api.stripe
        .stripeSetDefaultPaymentMethod(setDefaultPaymentMethodRequest)
        .then(() => {
          showAlert({
            content: "Default payment method updated successfully",
          });
        });
    },
  };
};
