import { SeasonDto } from "@justraviga/classmanager-sdk";

import { formatDate } from "shared/lib";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";

export const BillingPeriodSummaryCard = ({
  season,
  billingMonth,
  onClick,
}: {
  season: SeasonDto;
  billingMonth: string;
  onClick: () => void;
}) => {
  return (
    <BaseCard
      title={"Billing period"}
      headerAction={{ text: "Edit", onClick: onClick }}
      bodySlot={
        <div>
          <h6 className={"text-heading6-600"}>{season.name}</h6>
          <p className={"text-body-400"}>
            {formatDate(billingMonth, "monthYear")}
          </p>
        </div>
      }
    />
  );
};
