import { PropsWithChildren } from "react";

import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import { match } from "ts-pattern";

import { ApiMethod, useFormActions, useStripeLib } from "shared/components";
import { fiveMinutesMs, showAlert } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";

import { appearance } from "./stripeAppearence";

export const AddPaymentMethodForm = () => {
  const client = useQueryClient();
  const { closeSheet: hideForm } = useSheet();
  const { setSubmit, setIsLoading } = useFormActions();
  const stripe = useStripe();
  const elements = useElements();

  const handleSetupStatus = (status: SetupIntent.Status) => {
    match(status)
      .with("succeeded", () => {
        client.invalidateQueries({
          predicate: query => {
            return [
              "listStripePaymentMethod",
              "stripeCreateSetupIntent",
            ].includes(query.queryKey[0] as ApiMethod);
          },
        });
        showAlert({
          content: "Payment added successfully",
        });
        hideForm();
      })
      .with("processing", () => {})
      .with("requires_payment_method", () => {
        showAlert({
          content: "a payment method is required",
        });
      })
      .with("requires_confirmation", () => {
        showAlert({
          content: "Your payment method requires confirmation",
        });
      })
      .with("requires_action", () => {
        showAlert({
          content: "Your payment method requires action",
        });
      })
      .with("canceled", () => {
        showAlert({
          content: "Adding payment method was canceled",
        });
      })
      .exhaustive();
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // eslint-disable-next-line camelcase
        return_url: `${window.location.origin}${window.location.pathname}`,
      },
      redirect: "if_required",
    });

    if (error) {
      showAlert({
        content: "A card or validation error occurred.",
      });
    } else if (setupIntent) {
      handleSetupStatus(setupIntent.status);
    }

    setIsLoading(false);
  };

  setSubmit(handleSubmit);

  if (!stripe || !elements) {
    return null;
  }

  return (
    <form className="space-y-4">
      <PaymentElement />
    </form>
  );
};

interface AddStripePaymentMethodProps extends PropsWithChildren {
  familyId: string;
}

export const AddStripePaymentMethod = ({
  familyId,
}: AddStripePaymentMethodProps) => {
  const { data: setupIntent } = useApi(
    "stripeCreateSetupIntent",
    {
      familyId,
    },
    {
      staleTime: fiveMinutesMs,
    },
  );
  const { stripe } = useStripeLib();

  return (
    <>
      {stripe && setupIntent?.clientSecret && (
        <Elements
          stripe={stripe}
          options={{
            clientSecret: setupIntent.clientSecret,
            appearance: appearance,
          }}>
          <AddPaymentMethodForm />
        </Elements>
      )}
    </>
  );
};
