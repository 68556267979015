import { formatDate } from "shared/lib";

export const JoinedDate = ({ date }: { date: string | null }) => {
  return (
    <div className={"flex gap-2"}>
      <span className={"text-body-400 text-grey-600"}>Joined on</span>
      <span className={"text-body-400"}>
        {date ? formatDate(date, "dayMonthYear") : "-"}
      </span>
    </div>
  );
};
