import { PropsWithChildren, useState } from "react";

import { AccountSelectionDialogContext } from "@/modules/company/switcher/accountSelectionDialogContext";

export const AccountSelectionDialogProvider = ({
  children,
}: PropsWithChildren) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <AccountSelectionDialogContext.Provider value={{ isOpen, setOpen }}>
      {children}
    </AccountSelectionDialogContext.Provider>
  );
};
