import { TabsProps } from "shared/lib";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Tabs } from "@/modules/common/nav/Tabs";

const options: Array<Option<TabsProps>> = [
  {
    title: "Complete example",
    props: [
      {
        initialTab: 1,
        items: [
          {
            label: "Current",
            chip: { label: "20", variant: "primary" },
            onClick: () => console.log("Tab 1 clicked"),
          },
          {
            label: "Upcoming",
            chip: { label: "1", variant: "success" },
            onClick: () => console.log("Tab 2 clicked"),
          },
          {
            label: "Past",
            chip: { label: "3", variant: "secondary" },
            onClick: () => console.log("Tab 3 clicked"),
          },
        ],
      },
    ],
  },
  {
    title: "No chips",
    props: [
      {
        items: [
          {
            label: "Tab 1",
            onClick: () => console.log("Tab 1 clicked"),
          },
          {
            label: "Tab 2",
            onClick: () => console.log("Tab 2 clicked"),
          },
        ],
      },
    ],
  },
];

export const TabsPage = () => {
  return (
    <>
      <DocWrapper title="Tabs" component={Tabs} options={options} />
    </>
  );
};
