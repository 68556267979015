import React from "react";

import { isEqualIgnoreCase, propsToString } from "./stringUtils";

/**
 * Returns the first object in the array that has the given key matching the given value.
 *
 * @param searchTerm
 * @param key
 * @param arr
 * @param strict
 */
export function findObjectByKey<T, K extends keyof T>(
  searchTerm: T[K],
  key: K,
  arr: Array<T>,
  strict: boolean = false,
): T | undefined {
  return arr.find(obj =>
    strict
      ? obj[key] == searchTerm
      : isEqualIgnoreCase(
          obj[key] as string | number,
          searchTerm as string | number,
        ),
  );
}

/**
 * Returns a string representation of the given component.
 *
 * @param componentName
 * @param props
 * @param children
 */
export function generateComponentString<T>(
  componentName: string,
  props: T,
  children?: React.ReactNode,
) {
  const propsString = propsToString(props as object);
  let childrenString = "";

  if (React.Children.count(children) > 0) {
    React.Children.forEach(children, child => {
      if (React.isValidElement(child)) {
        childrenString += generateComponentString(
          getComponentName(child.type as React.ComponentType),
          child.props,
          child.props.children,
        );
      } else {
        childrenString += child; // If it's not a component, it might be a string or number.
      }
    });
  }
  if (childrenString) {
    return `<${componentName} ${propsString}>
    ${childrenString}
</${componentName}>`;
  } else {
    return `<${componentName} ${propsString} />`;
  }
}

/**
 * Returns the name of the given component.
 *
 * @param component
 */
export function getComponentName(component: React.ComponentType): string {
  return component.displayName || component.name || "Unknown";
}

type Path = string | string[];
/**
 * Returns the value at the given path in the object, or the default value if it doesn't exist.
 *
 * @param obj
 * @param path
 * @param defaultValue
 */
export function getPath<T, R = unknown>(
  obj: T,
  path: Path,
  defaultValue?: R,
): R | undefined {
  let current: unknown = obj;
  const pathArray =
    typeof path === "string" ? path.split(/[,[\].]+/).filter(Boolean) : path;

  for (let i = 0; i < pathArray.length; i++) {
    if (
      current == null ||
      !Object.prototype.hasOwnProperty.call(current, pathArray[i])
    ) {
      return defaultValue;
    }
    current = (current as Record<string, unknown>)[pathArray[i]];
  }

  return current as R;
}
