import {
  CreateWaiverRequest,
  UpdateWaiverRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type CreateSchema = CreateWaiverRequest;
type UpdateSchema = UpdateWaiverRequest;
type Action = "create" | "update" | "updateLocked";

const useDefinition = <Mode extends Action>({ mode }: { mode: Mode }) => {
  type Schema = CreateSchema & UpdateSchema;

  const formBuilder = new FormDefinitionBuilder<Schema>();

  if (mode !== "updateLocked") {
    formBuilder.text("name", {
      label: "Name",
      required: mode !== "updateLocked",
      disabled: mode === "updateLocked",
    });
  }

  return formBuilder
    .text("content", { label: "Content", numberOfLines: 10, required: true })
    .getDefinition() as FormDefinition<
    Mode extends "create" ? CreateSchema : UpdateSchema
  >;
};

export const useWaiverCreateFormDefinition = () =>
  useDefinition({ mode: "create" });

export const useWaiverUpdateFormDefinition = () =>
  useDefinition({ mode: "update" });

export const useWaiverUpdateLockedFormDefinition = () =>
  useDefinition({ mode: "updateLocked" });

export const makeWaiverCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.waivers.createWaiver({
      createWaiverRequest: formData,
    });
  };

export const makeWaiverUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.waivers.updateWaiver({
      id,
      updateWaiverRequest: formData,
    });
  };
