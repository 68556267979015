import { FilePurpose } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "./platformSpecific";

export const saveFile = async (
  entityId: string,
  purpose: FilePurpose,
  path: string,
) => {
  const { api } = getPlatformFunctions();

  return api.files.createFile({
    createFileRequest: {
      entityId,
      purpose,
      path,
    },
  });
};
