import { SeasonDto } from "@justraviga/classmanager-sdk";

import { SeasonStatusChip } from "shared/components";
import { calculateWeeksBetweenDates, formatDate } from "shared/lib";

import { Button } from "@/modules/common/ui/button/Button";
import { useSeasonActions } from "@/modules/company/classPlanner/seasons/useSeasonActions";
import { CompanyTitleBarTitle } from "@/modules/company/CompanyLayout";

export const SeasonDetailsHeader = ({
  season,
  isDesktop,
}: {
  season: SeasonDto;
  isDesktop: boolean;
}) => {
  const seasonActions = useSeasonActions();

  const weeksCount = calculateWeeksBetweenDates(
    new Date(season.startAt),
    new Date(season.endAt),
  );

  if (isDesktop) {
    return (
      <section className={"space-y-4"}>
        <div className={"flex items-center space-x-4"}>
          <CompanyTitleBarTitle title={season.name} />
          <SeasonStatusChip season={season} />
          <Button
            variant={"tertiaryLight"}
            text={"More details"}
            size={"sm"}
            onClick={() => seasonActions.showPreview(season)}
          />
        </div>
        <div>
          <h4 className={"text-body-400 text-grey-600"}>
            {formatDate(season.startAt, "dayMonthYear")} -{" "}
            {formatDate(season.endAt, "dayMonthYear")} ({weeksCount} weeks)
          </h4>
        </div>
      </section>
    );
  }

  return (
    <section className={"flex-grow"}>
      <div className={"mb-4 flex flex-row items-center justify-between"}>
        <CompanyTitleBarTitle title={season.name} />
        <SeasonStatusChip season={season} />
      </div>

      <div className={"mb-2"}>
        <h4 className={"text-body-400 text-grey-600"}>
          {formatDate(season.startAt, "dayMonthYear")} -{" "}
          {formatDate(season.endAt, "dayMonthYear")} ({weeksCount} weeks)
        </h4>
      </div>

      <div className={"-ml-4 flex"}>
        <Button
          variant={"tertiaryLight"}
          text={"More details"}
          size={"sm"}
          onClick={() => seasonActions.showPreview(season)}
        />
      </div>
    </section>
  );
};
