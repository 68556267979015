import { LogInRequest } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = LogInRequest;

export const useLoginFormDefinition = () =>
  new FormDefinitionBuilder<Schema>()
    .text("email", {
      label: "Email address",
      type: "email",
      required: true,
    })
    .password("password", { label: "Password", required: true })
    .getDefinition();

export const makeLoginRequest =
  ({ api }: { api: Api }) =>
  (formData: Schema) => {
    return api.auth.logIn({
      logInRequest: formData,
    });
  };
