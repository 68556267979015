import { useEffect } from "react";

import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";
import type { StripePaymentIntentDto } from "@justraviga/classmanager-sdk/dist/models";

import { colors } from "../../colors";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { moneyIntegerToFloat } from "../../moneyUtils";
import { isStripeCardData } from "../../third-party/stripe/stripeUtils";
import { FamilyTransaction } from "../datatables/useTransactionsDatatable";
import { useFormActions } from "../FormActionsProvider";
import {
  makeRefundForm,
  makeRefundRequest,
} from "../formDefinitions/refundForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { CardPaymentDetails } from "../modules/customer/checkout/PaymentDetails/CardPaymentDetails";
import { TransactionPreviewHeader } from "../transactions";

export const RefundForm = ({
  onSuccess,
  paymentIntent,
  transaction,
}: {
  onSuccess: (transaction: AggregateTransactionDto) => void;
  paymentIntent: StripePaymentIntentDto;
  transaction: FamilyTransaction;
}) => {
  const { GenericForm, View, BaseCard } = useGenericComponents();

  const familyId = transaction.transaction.familyId;
  const transactionDetailId = transaction.details[0].details.id;

  const availableAmount = paymentIntent
    ? paymentIntent.amount - (paymentIntent.charge?.amountRefunded ?? 0)
    : null;
  const hasAmountToRefund = availableAmount !== null && availableAmount > 0;

  const { setCreateButtonText, setAllowCreateAdditional, setSubmitDisabled } =
    useFormActions();

  useEffect(() => {
    // Tweak the form footer to match designs
    setAllowCreateAdditional(false);
    setCreateButtonText("Confirm");
  }, [setAllowCreateAdditional, setCreateButtonText]);

  useEffect(() => {
    // Disable the submit button if there's no amount to refund
    setSubmitDisabled(!hasAmountToRefund);
  }, [hasAmountToRefund, setSubmitDisabled]);

  return (
    <View className="flex flex-col space-y-4">
      <TransactionPreviewHeader
        transaction={transaction}
        isFamilyPortal={false}
      />
      <View className="flex flex-col space-y-5">
        <BaseCard
          fillColor={colors.grey[100]}
          title={formatMoneyFromInteger(availableAmount ?? 0)}
          titleColor="grey-900"
          titleSize={16}
          titleWeight={600}
          description="Available to refund"
          descriptionSize={14}
          descriptionColor="grey-900"
          padding={2}
          gap={1}
        />
        {isStripeCardData(paymentIntent?.paymentMethod?.data) && (
          <View className="pb-8">
            <CardPaymentDetails
              cardDetails={paymentIntent.paymentMethod.data}
              id={paymentIntent.id}
            />
          </View>
        )}
      </View>

      {hasAmountToRefund && (
        <GenericForm
          apiRequest={makeRefundRequest({ familyId, transactionDetailId })}
          defaultValues={{
            amount: availableAmount
              ? moneyIntegerToFloat(availableAmount)
              : null,
          }}
          formDefinitionHook={makeRefundForm()}
          onSuccess={onSuccess}
        />
      )}
    </View>
  );
};
