import { AccountActions } from "shared/components";

import {
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";

export const CompanyAccountPage = () => {
  return (
    <CompanyLayout>
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Account"} />
      </CompanyTitleBar>
      <CompanyContent>
        <AccountActions />
      </CompanyContent>
    </CompanyLayout>
  );
};
