import { TimePickerProps } from "shared/lib";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { TimePicker } from "@/modules/common/form/TimePicker";

const locale = "en-US";

const options: Array<Option<TimePickerProps>> = [
  {
    title: "Clocks",
    props: [
      { label: "12 hour", clockType: "12-hour", locale },
      { label: "24 hour", clockType: "24-hour", locale },
    ],
  },
  {
    title: "Minute increments",
    props: [
      {
        label: "1 minute increments",
        clockType: "12-hour",
        minutesStep: 1,
        locale,
      },
      {
        label: "5 minute increments",
        clockType: "12-hour",
        minutesStep: 5,
        locale,
      },
      {
        label: "15 minute increments",
        clockType: "12-hour",
        minutesStep: 15,
        locale,
      },
    ],
  },
  {
    title: "Descriptions",
    props: [
      {
        label: "Start time",
        clockType: "12-hour",
        description: "The time the event starts",
        locale,
      },
    ],
  },
  {
    title: "States",
    props: [
      {
        error: false,
        label: "A required field",
        required: true,
        clockType: "12-hour",
        locale,
      },
      {
        error: true,
        label: "12 hour clock with error",
        clockType: "12-hour",
        locale,
      },
      {
        error: true,
        label: "24 hour clock with error",
        clockType: "24-hour",
        locale,
      },
      {
        label: "24 hour, prefilled with 13:00",
        clockType: "24-hour",
        value: "13:00",
        locale,
      },
      {
        label: "12 hour, prefilled with 13:00",
        clockType: "12-hour",
        value: "13:00",
        locale,
      },
    ],
  },
];

export const TimePickerPage = () => {
  return (
    <DocWrapper title="Time Picker" component={TimePicker} options={options} />
  );
};
