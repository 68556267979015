import {
  ErrorResponse,
  instanceOfErrorResponse,
} from "@justraviga/classmanager-sdk";
import { SearchClient as TypesenseSearchClient } from "typesense";
import { ConfigurationOptions as TypesenseConfigurationOptions } from "typesense/lib/Typesense/Configuration";

import { SearchKeyLoader } from "./searchKeyLoader";

const defaultConfig: TypesenseConfigurationOptions = {
  nodes: [],
  apiKey: "",
  connectionTimeoutSeconds: 5,
};

export interface BuildSearchClientConfigParams {
  host: string;
  protocol?: string;
  port?: number;
  apiKey?: string;
}

export function buildSearchClientConfig({
  host,
  port = 443,
  protocol = "https",
  apiKey = "",
}: BuildSearchClientConfigParams) {
  if (!host) {
    throw new Error("a host is required");
  }

  return {
    ...defaultConfig,
    nodes: [
      {
        host,
        port,
        protocol,
      },
    ],
    apiKey,
  };
}

export class SearchClientBuilder {
  private client: TypesenseSearchClient | undefined;
  private baseConfig: TypesenseConfigurationOptions;
  private keyLoader: SearchKeyLoader;

  constructor(
    keyLoader: SearchKeyLoader,
    baseConfig: TypesenseConfigurationOptions,
  ) {
    this.keyLoader = keyLoader;
    this.baseConfig = baseConfig;
  }

  async resetKey() {
    return this.getClient();
  }

  async getClient() {
    let apiKey;
    try {
      apiKey = await this.keyLoader.load();
    } catch (err) {
      if (instanceOfErrorResponse(err as ErrorResponse)) {
        if ((err as ErrorResponse).statusCode === 401) {
          return null;
        }
      }
    }

    if (!apiKey) {
      throw new Error("Unexpected error generating search key for client");
    }

    return this.buildClient(apiKey);
  }

  private buildClient(apiKey: string): TypesenseSearchClient {
    this.client = new TypesenseSearchClient({
      ...this.baseConfig,
      apiKey,
    });

    return this.client;
  }
}
