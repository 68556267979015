import { PropsWithChildren, useEffect } from "react";

import {
  AggregatePaymentLinkSuccessDto,
  CompanyIntegrationStripeSettingsDto,
  StripePaymentSessionDto,
} from "@justraviga/classmanager-sdk";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { match } from "ts-pattern";

import {
  useFormActions,
  useGenericComponents,
  useStripeLib,
} from "shared/components";
import { checkoutPublicPayment, showAlert } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { appearance } from "@/modules/common/payments/stripeAppearence";

type OnSuccessCallback = (
  result: AggregatePaymentLinkSuccessDto | null | void,
) => void;

interface TakePaymentFormProps {
  onSuccess: OnSuccessCallback;
  paymentLinkId: string;
  setReadyForPayment: (ready: boolean) => void;
}

const TakePaymentForm = ({
  onSuccess,
  paymentLinkId,
  setReadyForPayment,
}: TakePaymentFormProps) => {
  const { setSubmit, setIsLoading } = useFormActions();
  const stripe = useStripe();
  const elements = useElements();
  const { Loader } = useGenericComponents();

  const handlePaymentStatus = (paymentIntent: PaymentIntent) => {
    return match(paymentIntent.status)
      .with("succeeded", async () => {
        onSuccess();
        showAlert({
          content: "Payment taken successfully",
        });
      })
      .with("requires_capture", async () => {
        await checkoutPublicPayment(api, paymentLinkId, paymentIntent.id).then(
          result => onSuccess(result),
        );
      })
      .with("processing", () => {})
      .with("requires_payment_method", () => {
        showAlert({
          content: "a payment method is required",
        });
      })
      .with("requires_confirmation", () => {
        showAlert({
          content: "Your payment method requires confirmation",
        });
      })
      .with("requires_action", () => {
        showAlert({
          content: "Your payment method requires action",
        });
      })
      .with("canceled", () => {
        showAlert({
          content: "Adding payment method was canceled",
        });
      })
      .exhaustive();
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // eslint-disable-next-line camelcase
        return_url: `${window.location.origin}${window.location.pathname}`,
      },
      redirect: "if_required",
    });

    if (error) {
      showAlert({
        content: "A card or validation error occurred.",
      });
    } else if (paymentIntent) {
      try {
        await handlePaymentStatus(paymentIntent);
      } catch (e) {
        console.error(e);
      }
    } else {
      showAlert({
        content: "Something unexpected happened.",
        variant: "error",
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setSubmit(handleSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!stripe || !elements) {
    return <Loader />;
  }

  return (
    <form className="space-y-4">
      <PaymentElement
        onReady={() => {
          setReadyForPayment(true);
        }}
      />
    </form>
  );
};

const TakePaymentContainer = ({ children }: PropsWithChildren) => (
  <div className="w-full">{children}</div>
);

interface TakePaymentProps extends PropsWithChildren {
  onSuccess: (result: AggregatePaymentLinkSuccessDto | null | void) => void;
  paymentSession: StripePaymentSessionDto;
  stripeIntegration: CompanyIntegrationStripeSettingsDto;
  paymentLinkId: string;
  setReadyForPayment: (ready: boolean) => void;
}

export const TakePayment = ({
  onSuccess,
  paymentSession,
  stripeIntegration,
  paymentLinkId,
  setReadyForPayment,
}: TakePaymentProps) => {
  const { stripe, setStripeIntegration } = useStripeLib();
  const { Loader } = useGenericComponents();

  const { paymentIntent, customerSession } = paymentSession;

  useEffect(() => {
    setStripeIntegration(stripeIntegration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeIntegration]);

  return (
    <TakePaymentContainer>
      {stripe && customerSession?.clientSecret && paymentIntent ? (
        <Elements
          stripe={stripe}
          options={{
            clientSecret: paymentIntent.clientSecret,
            customerSessionClientSecret: customerSession.clientSecret,
            appearance: appearance,
          }}>
          <TakePaymentForm
            onSuccess={onSuccess}
            paymentLinkId={paymentLinkId}
            setReadyForPayment={setReadyForPayment}
          />
        </Elements>
      ) : (
        <Loader />
      )}
    </TakePaymentContainer>
  );
};
