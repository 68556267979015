import type { ResetPasswordRequest } from "@justraviga/classmanager-sdk/dist/models";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = ResetPasswordRequest;

export const useResetPasswordFormDefinition = () =>
  new FormDefinitionBuilder<Schema>()
    .password("password", { label: "Password" })
    .getDefinition();

export const makeResetPasswordRequest =
  ({ api, token, email }: { api: Api; token: string; email: string }) =>
  (formData: Schema) => {
    return api.auth.resetPassword({
      resetPasswordRequest: {
        ...formData,
        token,
        email,
      },
    });
  };
