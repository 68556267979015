import { FC } from "react";

import { CustomFieldDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeCustomFieldCreateRequest,
  makeCustomFieldUpdateRequest,
  useCustomFieldCreateFormDefinition,
  useCustomFieldUpdateFormDefinition,
} from "../formDefinitions/customFieldForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { EntityActions } from "../useDefaultEntityActions";

type Model = CustomFieldDto;

export type CustomFieldActions = Omit<
  EntityActions<Model>,
  "archiveMany" | "unarchiveMany" | "archive" | "unarchive" | "deleteMany"
>;

export const useSharedCustomFieldActions = (
  CustomFieldActionBanner: FC,
): CustomFieldActions => {
  const { GenericForm } = useGenericComponents();
  const { api, usePlatformEntityActions } = getPlatformFunctions();

  const defaultActions = usePlatformEntityActions<CustomFieldDto>({
    entity: "customField",
  });

  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.customFields.deleteCustomField({ id: entity.id }),
      ),

    showCreateForm: (customField: Partial<CustomFieldDto> = {}) =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeCustomFieldCreateRequest({ api })}
            defaultValues={customField}
            formDefinitionHook={useCustomFieldCreateFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <>
            <GenericForm
              apiRequest={makeCustomFieldUpdateRequest({ api, id: entity.id })}
              defaultValues={entity}
              formDefinitionHook={useCustomFieldUpdateFormDefinition}
              onSuccess={defaultActions.onUpdateSuccess}
            />
            <CustomFieldActionBanner />
          </>
        ),
      }),
  };
};
