import { z } from "zod";

import { customErrorMap } from "shared/lib";

import "./setup/sentry";
import "./setup/appcues";
import "./setup/clarity";

// Set our global custom error map for Zod validation
z.setErrorMap(customErrorMap);
