import { ComponentType, ReactNode } from "react";

import { FamilyDto, StudentDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { getPlatformFunctions } from "../../platformSpecific";
import { useAuthState } from "../AuthStateProvider";
import {
  makeStudentCreateRequest,
  makeStudentUpdateRequest,
  usePortalStudentCreateFormDefinition,
  usePortalStudentDateOfBirthChangeFormDefinition,
  usePortalStudentUpdateFormDefinition,
  useStudentUpdateFormDefinition,
} from "../formDefinitions/studentForm";
import { MakeUpLessonForm } from "../forms/MakeUpLessonForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { EntityActions } from "../useDefaultEntityActions";

type Model = StudentDto;

export type StudentImplementedActions = EntityActions<Model> & {
  showAvatarUploadSheet: (entity: Model, args?: unknown) => void;
  showPreview: (entity: Model) => void;
  showAddDateOfBirthForm: (entity: Model) => void;
  showCreateWithFamilyForm: (family: FamilyDto) => void;
  showPortalCreateForm: () => void;
  showPortalUpdateForm: (entity: Model) => void;
  makeUpLesson: (student: Model) => void;
};

// This file only implements these actions:
type SharedActions = Omit<
  StudentImplementedActions,
  | "showPreview"
  | "showAvatarUploadSheet"
  | "showCreateWithFamilyForm"
  | "showCreateForm"
>;

export const useSharedStudentActions = (
  openForm: (options: {
    title: string;
    content: ReactNode;
    footer: ReactNode | null;
  }) => void,
  UpdateFooter: ComponentType<{ saveButtonText?: string }>,
  onSuccess: (message: string) => void,
): SharedActions => {
  const { account } = useAuthState();
  const { GenericForm } = useGenericComponents();
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<StudentDto>({
    entity: "student",
  });

  function onDateOfBirthChangeSuccess() {
    showAlert({
      content: `Date of birth updated successfully`,
    });
    defaultActions.hideForm();
  }

  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.students.deleteStudent({ id: entity.id }),
      ),

    showPortalCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeStudentCreateRequest({
              api,
              familyId: account?.entityId as string,
            })}
            defaultValues={{ familyId: account?.entityId }}
            formDefinitionHook={usePortalStudentCreateFormDefinition}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showPortalUpdateForm: (entity: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeStudentUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={usePortalStudentUpdateFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeStudentUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={useStudentUpdateFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),

    showAddDateOfBirthForm: (entity: Model) =>
      openForm({
        title: "Add date of birth",
        content: (
          <GenericForm
            apiRequest={makeStudentUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={usePortalStudentDateOfBirthChangeFormDefinition}
            onSuccess={onDateOfBirthChangeSuccess}
          />
        ),
        footer: <UpdateFooter />,
      }),

    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () =>
          api.students.deleteManyStudent({ deleteManyStudentRequest: { ids } }),
        ids.length,
      ),

    archive: (entity: Model) =>
      defaultActions.archive(
        () => api.students.archiveStudent({ id: entity.id }),
        {
          extendedDescription:
            "This student will no longer be able to access Class Manager.",
        },
      ),

    archiveMany: (ids: string[]) =>
      defaultActions.archiveMany(
        () => api.students.archiveStudents({ archiveStudentsRequest: { ids } }),
        ids.length,
        {
          extendedDescription:
            "They will no longer be able to access Class Manager.",
        },
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.students.unarchiveStudent({ id: entity.id }),
      ),

    unarchiveMany: (ids: string[]) =>
      defaultActions.unarchiveMany(() =>
        api.students.unarchiveStudents({ unarchiveStudentsRequest: { ids } }),
      ),

    makeUpLesson: (student: Model) =>
      openForm({
        title: "Assign make up lesson",
        content: <MakeUpLessonForm student={student} onSuccess={onSuccess} />,
        footer: <UpdateFooter saveButtonText={"Assign"} />,
      }),
  };
};
