import { useLocation } from "@swan-io/chicane";

import { WaiverEntity } from "shared/lib";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { LockedWaiverIcon } from "@/modules/company/waivers/LockedWaiverIcon";
import { useWaiverActions } from "@/modules/company/waivers/useWaiverActions";
import { useWaiverDetailActionMenu } from "@/modules/company/waivers/useWaiverDetailActionMenu";
import { WaiverCardContent } from "@/modules/company/waivers/WaiverCardContent";
import { WaiverCardTitle } from "@/modules/company/waivers/WaiverCardTitle";
import { WaiverChip } from "@/modules/company/waivers/WaiverChip";
import { Router } from "@/routing/router";

export interface WaiverCardProps {
  waiver: WaiverEntity;
}

export const WaiverCard = ({ waiver }: WaiverCardProps) => {
  const location = useLocation();
  const waiverActions = useWaiverActions();
  const forceEditMenu = true;
  const actionMenuItems = useWaiverDetailActionMenu(
    waiver,
    waiverActions,
    forceEditMenu,
  );

  const openWaiver = () => {
    Router.push("CompanyWaiverDetails", {
      id: waiver.props.id,
      sourcePage: location.raw.path,
    });
  };

  return (
    <BaseCard
      title={
        <WaiverCardTitle>
          <p className="line-clamp-1">{waiver.props.name}</p>
          <LockedWaiverIcon isLocked={waiver.isLocked()} />
        </WaiverCardTitle>
      }
      onClick={openWaiver}
      headerActions={actionMenuItems}
      bodySlot={<WaiverCardContent content={waiver.props.content} />}
      footerSlot={
        <div className="flex justify-start pt-2">
          <WaiverChip waiver={waiver} />
        </div>
      }
    />
  );
};
