import React from "react";

type LabelProps = React.PropsWithChildren & {
  text?: string;
  required?: boolean;
  useNativeLabel?: boolean;
};

export const Label = ({
  text,
  required,
  children,
  useNativeLabel = true,
}: LabelProps) => {
  return (
    <WrappingElement useNativeLabel={useNativeLabel}>
      {typeof text === "string" && (
        <div className="mb-2 flex gap-x-1 text-label-400 text-grey-900">
          {text.length > 0 ? <span>{text}</span> : <span>&nbsp;</span>}
          {required === true && (
            <span className="text-grey-500">(required)</span>
          )}
        </div>
      )}
      {children}
    </WrappingElement>
  );
};

const WrappingElement = ({
  children,
  useNativeLabel,
}: {
  children: React.ReactNode;
  useNativeLabel: boolean;
}) => {
  return useNativeLabel ? (
    <label>{children}</label>
  ) : (
    <div role="label">{children}</div>
  );
};
