import { InviteFamilyRequest, LinkDto } from "@justraviga/classmanager-sdk";

import {
  makeFamilyInviteRequest,
  useFamilyInviteFormDefinition,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { Banner } from "@/modules/common/ui/Banner";

export const FamilyInviteForm = ({
  familyId,
  defaultValues,
  onSuccess,
}: {
  familyId: string;
  defaultValues: InviteFamilyRequest;
  onSuccess: (linkData: LinkDto) => void;
}) => {
  return (
    <>
      <Banner
        content={
          <>
            <Banner.title>Primary contact details</Banner.title>
            <Banner.content>
              This will be the person that will access Class Manager on behalf
              of the family. Please confirm the details are correct and up to
              date.
            </Banner.content>
          </>
        }
        variant={"info"}
      />
      <GenericForm
        apiRequest={makeFamilyInviteRequest({ api, id: familyId })}
        defaultValues={defaultValues}
        formDefinitionHook={useFamilyInviteFormDefinition}
        onSuccess={onSuccess}
      />
    </>
  );
};
