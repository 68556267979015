import { PropsWithChildren, useMemo } from "react";

import { CourseSearchContext, CourseSearchValues } from "./courseSearchContext";

const getFilterCount = (values: CourseSearchValues) =>
  values.ages.length +
  values.days.length +
  values.locationIds.length +
  values.seasonIds.length;

interface CourseSearchProviderProps extends PropsWithChildren {
  searchValues: CourseSearchValues;
}

export const CourseSearchProvider = ({
  children,
  searchValues,
}: CourseSearchProviderProps) => {
  const searchKey = useMemo(() => JSON.stringify(searchValues), [searchValues]);

  const value: CourseSearchContext = {
    filterCount: getFilterCount(searchValues),
    searchKey,
    searchValues,
  };

  return (
    <CourseSearchContext.Provider value={value}>
      {children}
    </CourseSearchContext.Provider>
  );
};
