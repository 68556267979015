import { AttendanceDto } from "@justraviga/classmanager-sdk";

import { Chip } from "../ui/Chip";

export const AttendanceStatusChip = ({
  attendance,
}: {
  attendance?: AttendanceDto;
}) => {
  if (attendance?.status === "present") {
    return <Chip size="sm" variant="success" label="Present" />;
  }

  if (attendance?.status === "absent") {
    return <Chip size="sm" variant="danger" label="Absent" />;
  }

  return null;
};
