import { WaiverDto } from "@justraviga/classmanager-sdk";

import { WaiverEntity, waiverEntityFactory } from "./waiver";
import { UseApi } from "../../components/apiQueryFactory";
import { useEntity } from "../useEntity";

export function useWaiverEntity(useApi: UseApi, id: string) {
  return useEntity<WaiverDto, WaiverEntity>(
    waiverEntityFactory,
    useApi("getWaiver", { id }),
  );
}
