import { ReactNode } from "react";

import {
  AggregateEnrolmentDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { AddStudentsToBasketItemWidget } from "./AddStudentsToBasketItemWidget";
import { BasketItem } from "./MemberBasketPage";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  BannerComponent,
  HorizontalSeparatorComponent,
  IconButtonComponent,
  IconComponent,
  MultiSelectComponent,
} from "../../../interfaces";

interface ItemListWidgetProps {
  basketItems: BasketItem[];
  familyMembers: StudentDto[];
  enrolments: AggregateEnrolmentDto[];
  HorizontalSeparator: HorizontalSeparatorComponent;
  Banner: BannerComponent;
  MultiSelect: MultiSelectComponent;
  useStudentActions: () => StudentImplementedActions;
  IconButton: IconButtonComponent;
  shouldValidate: boolean;
  Icon: IconComponent;
  header: ReactNode;
  itemTitle: (basketItem: BasketItem) => ReactNode;
  itemSubtitle: (basketItem: BasketItem) => ReactNode;
}

export const ItemListWidget = ({
  basketItems,
  familyMembers,
  enrolments,
  HorizontalSeparator,
  Banner,
  MultiSelect,
  useStudentActions,
  IconButton,
  shouldValidate,
  Icon,
  header,
  itemTitle,
  itemSubtitle,
}: ItemListWidgetProps) => {
  const { View } = useGenericComponents();

  return (
    <View className={"mb-4"}>
      {header}
      {basketItems.map((basketItem, index: number) => (
        <View key={basketItem.course.entity.id}>
          <AddStudentsToBasketItemWidget
            title={itemTitle}
            subtitle={itemSubtitle}
            basketItem={basketItem}
            familyMembers={familyMembers}
            enrolments={enrolments.filter(
              ({ enrolment }) =>
                enrolment.classId === basketItem.course.entity.id,
            )}
            Banner={Banner}
            MultiSelect={MultiSelect}
            useStudentActions={useStudentActions}
            IconButton={IconButton}
            shouldValidate={shouldValidate}
            Icon={Icon}
          />
          {index < basketItems.length - 1 && (
            <HorizontalSeparator spacing={5} />
          )}
        </View>
      ))}
    </View>
  );
};
