import { LogInRequest } from "@justraviga/classmanager-sdk";

import { DefaultValues, FormBuilder } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = LogInRequest;

const definition = new FormDefinitionBuilder<Schema>()
  .conditional(["email"], ["email"], () => {
    return false;
  })
  .text("email", {
    label: "Email address",
    type: "email",
    required: true,
  })
  .password("password", { label: "Password", required: true })
  .getDefinition();

export const useStaffLoginForm = (
  builder: FormBuilder,
  onSubmit: (data: Schema) => void,
  defaultValues: DefaultValues<Schema>,
) => {
  return builder(definition, onSubmit, defaultValues);
};
