import { Row, Table } from "@tanstack/react-table";
import { VirtualItem } from "@tanstack/react-virtual";

import { cn, useDatatable } from "shared/lib";

import { DatatableMobileCell } from "@/modules/common/datatable/cell/DatatableMobileCell";

export const MobileTableBody = <ModelDto,>({
  table,
  data,
}: {
  table: Table<ModelDto>;
  data?: VirtualItem[];
}) => {
  const { datatable } = useDatatable();
  const { rows } = table.getRowModel();

  if (!data) {
    return <></>;
  }

  return data.map((virtualRow, index) => {
    const row = rows[virtualRow.index] as Row<ModelDto>;

    return (
      <tr
        key={`row-${index}-${row.id}`}
        className={cn(
          "h-full border-b-[1px] border-grey-300 hover:bg-grey-100",
          datatable.tableRowClass,
        )}>
        {row.getVisibleCells().map(cell => {
          return <DatatableMobileCell cell={cell} key={cell.id} />;
        })}
      </tr>
    );
  });
};
