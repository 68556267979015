export function getFullName(item: {
  firstname: string | null;
  lastname: string | null | undefined;
}): string {
  return item.lastname
    ? `${item.firstname} ${item.lastname}`
    : item.firstname ?? "";
}

export function getFirstLetters(inputString: string) {
  return inputString
    .split(" ")
    .slice(0, 2)
    .map(word => word[0])
    .join("");
}

export function getInitials(item: {
  firstname: string | null;
  lastname: string | null | undefined;
}): string {
  return getFirstLetters(getFullName(item));
}
