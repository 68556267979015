import { Time } from "@justraviga/classmanager-sdk";

import { SelectItem } from "./components/interfaces";
import { LessonEntity } from "./data/useLessonList";
import { formatDate, formatTime } from "./intlFormatter";

export type TrialDate = `${number}-${number}-${number}`;
export type TrialDateTime = `${TrialDate} ${Time}`;

export const splitDateTime = (dateTime: TrialDateTime) =>
  dateTime.split(" ") as [TrialDate, Time];

const lessonSelectLabel = (lesson: LessonEntity): string => {
  const suffix = lesson.isCancelled
    ? "(cancelled)"
    : lesson.isOnHoliday
      ? "(holiday)"
      : "";

  return `${formatDate(lesson.date, "weekdayDayMonthYear")} ${suffix}`.trim();
};

export const lessonSelectData = (lessons: LessonEntity[]): SelectItem[] =>
  (lessons ?? []).map(lesson => ({
    label: lessonSelectLabel(lesson),
    description: formatTime(lesson.startTime, "hourMinute"),
    value: `${lesson.date} ${lesson.startTime}`,
    disabled: lesson.isCancelled || lesson.isOnHoliday,
  }));
