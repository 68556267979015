import { useEffect, useState } from "react";

import { UseApi } from "../components/apiQueryFactory";

export const useFamilyMembers = (useApi: UseApi) => {
  const [goNext, setGoNext] = useState(false);
  const [hasSetFamilyMember, setHasSetFamilyMember] = useState(false);

  const { data: members, isPending: isFamilyMembersLoading } = useApi(
    "listStudent",
    {
      selectAll: true,
    },
  );

  useEffect(() => {
    if (!hasSetFamilyMember && !isFamilyMembersLoading) {
      if ((members?.data.length ?? 0) > 0) {
        setGoNext(true);
      }
      setHasSetFamilyMember(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFamilyMembersLoading]);

  return {
    members: members?.data ?? [],
    isFamilyMembersLoading,
    goNext,
    setGoNext,
  };
};
