import { UseFormReturn } from "react-hook-form";

import { cn } from "../../../../../cssUtils";
import { DatatableFilter, mapFilterFormValues } from "../../../../../datatable";
import {
  FilterFormDefinition,
  PossibleFormValues,
} from "../../../../../forms/formBuilderTypes";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const GroupListFilter = ({
  filterForm,
  onFilter,
  appliedFilters,
  showWebSize,
  open,
  close,
  DatatableFiltersSheetFooter,
  form,
  formHandlers,
}: {
  filterForm: FilterFormDefinition<object, object>;
  onFilter: (filters: DatatableFilter[]) => void;
  appliedFilters: DatatableFilter[];
  showWebSize: boolean;
  open: ({
    title,
    onClose,
    side,
    content,
    footer,
  }: {
    title: string;
    content: React.ReactNode;
    footer: React.ReactNode | null;
    onClose: () => void;
    side: "right" | "top" | "bottom" | undefined;
  }) => void;
  close: () => void;
  DatatableFiltersSheetFooter: React.ComponentType<{
    onApply: () => void;
    onClear: () => void;
  }>;
  form: React.JSX.Element;
  formHandlers: UseFormReturn<Record<string, PossibleFormValues>>;
}) => {
  const { IconButton, Button, Text, View } = useGenericComponents();

  const reset = () => {
    close();
    formHandlers.reset();
    onFilter([]);
  };

  const applyFilters = () => {
    close(); // looks neater if we close the sheet before applying the filters, not flickering

    const filters = mapFilterFormValues(
      formHandlers.getValues(),
      filterForm.filterOptions,
    );

    onFilter(filters);
  };

  const submit = formHandlers.handleSubmit(applyFilters);

  const appliedFiltersCount = () => {
    return appliedFilters.map(filter => filter.value).flat().length;
  };

  function showFilters() {
    open({
      title: "Filters",
      onClose: close,
      side: "right",
      footer: <DatatableFiltersSheetFooter onApply={submit} onClear={reset} />,
      content: form,
    });
  }

  return (
    <View className={"flex"}>
      {showWebSize ? (
        <Button
          leftIcon={"filterOutline"}
          size={"sm"}
          text={"Filter"}
          variant={"secondary"}
          role={"filter-trigger-button"}
          className={cn({ "border-grey-900": appliedFiltersCount() > 0 })}
          onClick={showFilters}>
          {appliedFiltersCount() > 0 ? (
            <View className={"py-1"}>
              <Text
                className={
                  "flex h-5 w-5 items-center justify-center rounded-full bg-grey-900 p-1 text-10 text-white"
                }>
                {appliedFiltersCount()}
              </Text>
            </View>
          ) : null}
        </Button>
      ) : (
        <IconButton
          role={"filter-trigger-button"}
          icon={"filterOutline"}
          variant={"secondary-outline"}
          hasIndicator={appliedFiltersCount() > 0}
          indicatorNumber={appliedFiltersCount()}
          indicatorVariant={"primary"}
          className={appliedFiltersCount() > 0 ? "border-grey-900" : ""}
          onClick={showFilters}
        />
      )}
    </View>
  );
};
