import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { Button } from "@/modules/common/ui/button/Button";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { Router } from "@/routing/router";

export const PasswordResetLinkSentPage = () => {
  return (
    <NonAuthLayout>
      <AuthPageCard
        showTerms={false}
        header={
          <div
            className={
              "mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-purple-600"
            }>
            <Icon name={"mailUnreadOutline"} color={"white"} size={24} />
          </div>
        }
        title={"Password reset link sent"}
        subtitle={
          "If the email you have entered matches an account on our system, you will receive a link to reset your password."
        }
        content={
          <Button
            onClick={() => Router.push("Login")}
            variant="classmanager"
            text="Back to log in"
            isFullWidth={true}
          />
        }
      />
    </NonAuthLayout>
  );
};
