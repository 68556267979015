import { instanceOfCompanyDto } from "@justraviga/classmanager-sdk";

import { useFamilyCompany } from "shared/components";
import { breakpoint, cn } from "shared/lib";

import { UserAccountLinks } from "@/modules/common/nav/UserAccountLinks";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { CompanyLogo } from "@/modules/company/CompanyLogo";
import { PublicAccountLinks } from "@/modules/public/nav/PublicAccountLinks";

export interface FamilyHeaderProps {
  backButton?: {
    onClick: () => void;
    text?: string;
  };
}

export const FamilyHeader = ({ backButton }: FamilyHeaderProps) => {
  const { company } = useFamilyCompany();

  const isLoggedIn = instanceOfCompanyDto(company);

  return (
    <div>
      <div className="flex flex-row items-center justify-between px-8 py-5">
        <div className="flex flex-row items-center justify-start gap-x-4">
          <CompanyLogo logo={company.logo} />
          <span
            className={cn("text-heading4-600 text-grey-900", {
              "text-heading5-600": !breakpoint.md,
              "text-heading4-600": breakpoint.md,
            })}>
            {company.name}
          </span>
        </div>
        {isLoggedIn ? <UserAccountLinks /> : <PublicAccountLinks />}
      </div>
      {backButton && (
        <div className={"px-4 py-4 md:px-8"}>
          <span
            className={"inline-flex cursor-pointer items-center"}
            onClick={backButton.onClick}>
            <Icon name={"chevronBackOutline"} />
            {backButton?.text && (
              <p className={"py-2 pl-2 text-label-400 underline"}>
                {backButton.text}
              </p>
            )}
          </span>
        </div>
      )}
    </div>
  );
};
