import { Button } from "@/modules/common/ui/button/Button";
import { useRoomActions } from "@/modules/company/locations/useRoomActions";

export const RoomsCardCreateNewButton = ({
  locationId,
}: {
  locationId: string;
}) => {
  const { showCreateForm } = useRoomActions();

  return (
    <div>
      <Button
        className={"p-2"}
        variant={"subtle"}
        text={"Create new"}
        size={"xs"}
        onClick={() => showCreateForm({ locationId })}
      />
    </div>
  );
};
