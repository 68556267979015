import { FC } from "react";

import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  pricingSchemeDescription,
  pricingSchemeTypeIcon,
} from "../../../pricingSchemeUtils";
import { enumLabel } from "../../../translateUtils";
import { SeasonImplementedActions } from "../../actions/useSharedSeasonActions";

export const PricingTiersContentCard = <T,>({
  BaseCard,
  season,
  seasonActions,
  props,
}: {
  BaseCard: FC<T>;
  season: SeasonDto;
  seasonActions: SeasonImplementedActions;
  props?: T;
}) => {
  const defaultProps = {
    title: enumLabel("pricingScheme", season.pricingScheme),
    titleColor: "grey-900",
    titleWeight: 600,
    titleSize: 16,
    description: pricingSchemeDescription[season.pricingScheme],
    icon: pricingSchemeTypeIcon[season.pricingScheme],
    iconPosition: "left-framed",
    headerAction: {
      text: "Edit",
      onClick: () => {
        seasonActions.showPricingSchemeUpdateForm(season);
      },
    },
    onClick: () => {
      seasonActions.showPricingSchemeUpdateForm(season);
    },
  } as T;

  return <BaseCard {...defaultProps} {...props} />;
};
