import { LocationDto } from "@justraviga/classmanager-sdk";

import { DefaultEntityActions } from "shared/components";

import { Api } from "../../api";

type Model = LocationDto;

export const useSharedLocationActions = (
  defaultActions: DefaultEntityActions<Model>,
  api: Api,
) => {
  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.locations.deleteLocation({ id: entity.id }),
      ),

    archive: (entity: Model) =>
      defaultActions.archive(() =>
        api.locations.archiveLocation({ id: entity.id }),
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.locations.unarchiveLocation({ id: entity.id }),
      ),
  };
};
