import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";

export const PoweredBy = () => (
  <div className="flex flex-col items-center justify-center gap-2 align-middle">
    <div>
      <p className="text-label-400 text-grey-500">Powered by</p>
    </div>
    <div>
      <ClassManagerLogo color="grey-500" width="124px" />
    </div>
  </div>
);
