import { ReactNode, useCallback, useMemo } from "react";

import {
  AccountPermission,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { PermissionContext } from "./permissionContext";
import { getPlatformFunctions } from "../../platformSpecific";
import { useAuthState } from "../AuthStateProvider";

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const { account } = useAuthState();

  const { permissions, hasPermission } = usePermissions(account);

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        hasPermission,
      }}>
      {children}
    </PermissionContext.Provider>
  );
};

function usePermissions(account: UserAccountDto | null) {
  const { useApi } = getPlatformFunctions();

  const { data: accountPermissionResponse } = useApi(
    "getAccountPermissions",
    {},
    { enabled: !!account },
  );

  const permissions: string[] = useMemo(
    () => accountPermissionResponse?.permissions ?? [],
    [accountPermissionResponse],
  );

  const hasPermission = useCallback(
    (permission: AccountPermission) => {
      return permissions.includes(permission);
    },
    [permissions],
  );

  return {
    permissions,
    hasPermission,
  };
}
