import { useEffect, useState } from "react";

import { colors, toIntegerString } from "shared/lib";

import {
  BaseInput,
  BaseInputProps,
  BaseInputValue,
} from "@/modules/common/form/BaseInput";
import { NumberInputButtons } from "@/modules/common/form/NumberInputButtons";
import { Icon, IconName } from "@/modules/common/ui/icon/Icon";

export type IntegerInputValues = number | null;

export interface IntegerInputProps
  extends Omit<BaseInputProps, "append" | "prepend" | "value" | "onChange"> {
  value?: IntegerInputValues;
  prependIcon?: IconName;
  onChange?: (value: IntegerInputValues) => void;
}

export const IntegerInput = ({
  onChange,
  prependIcon,
  value,
  ...props
}: IntegerInputProps) => {
  const [innerValue, setInnerValue] = useState<BaseInputValue>(
    toIntegerString(value ?? null),
  );

  const prepend = prependIcon ? (
    <div data-testid="prepend-icon">
      <Icon name={prependIcon} size={18} color={colors.grey[500]} />
    </div>
  ) : null;

  const format = (
    value: BaseInputValue | IntegerInputValues,
  ): IntegerInputValues => {
    const sanitizedValue = toIntegerString(value ?? "").trim();
    return sanitizedValue.length > 0 ? Number(sanitizedValue) : null;
  };

  const innerOnChange = (e: BaseInputValue) => {
    const newValue = toIntegerString(e);
    setInnerValue(newValue);
    if (onChange) {
      onChange(format(newValue));
    }
  };

  useEffect(() => {
    setInnerValue(toIntegerString(value));
  }, [value]);

  const increment = () => {
    const newValue = toIntegerString(Number(innerValue) + 1);
    setInnerValue(newValue);
    if (onChange) {
      onChange(format(newValue));
    }
  };

  const decrement = () => {
    const newValue = toIntegerString(Math.max(0, Number(innerValue) - 1));
    setInnerValue(newValue);
    if (onChange) {
      onChange(format(newValue));
    }
  };

  const append = <NumberInputButtons {...{ increment, decrement }} />;

  return (
    <BaseInput
      {...props}
      append={append}
      onChange={innerOnChange}
      prepend={prepend}
      selectTextOnFocus={true}
      type="numeric"
      value={innerValue}
    />
  );
};
