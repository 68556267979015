import { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { AuthContext } from "../auth/AuthContext";
import {
  AuthState,
  AuthStateInterface,
  createEmptyAuthState,
} from "../authState";

/** @deprecated - import from `src/auth/AuthContext` instead */
// eslint-disable-next-line react-refresh/only-export-components
export * from "../auth/AuthContext";

/** @deprecated - import from `src/auth/useAuthState` instead */
// eslint-disable-next-line react-refresh/only-export-components
export * from "../auth/useAuthState";

function buildInnerState(state: AuthState): AuthState {
  const { user, token, account } = state;
  return {
    user,
    token,
    account,
    isLoggedIn: !!(user && account && token),
  };
}

export const AuthStateProvider = ({
  authState,
  children,
}: PropsWithChildren<{
  authState: AuthStateInterface;
}>) => {
  // We keep a synced local copy of the auth state from local storage in order to provide
  // components with up-to-date auth state
  const [innerState, setInnerState] = useState<AuthState>(
    createEmptyAuthState(),
  );
  const [isLoading, setIsLoading] = useState(true);

  // Allow us to programmatically redirect with a callback after successful login
  const [onLogin, setOnLogin] = useState<(() => void) | undefined>();

  const { addListener, setUser, setAccount, clearAuthState, removeListener } =
    authState;

  // subscribe to changes in non-reactive auth state so this can be cleared outside of this provider
  useEffect(() => {
    authState.load().then(state => {
      setInnerState(buildInnerState(state));
      setIsLoading(false);
    });

    addListener("AuthProvider", state => {
      setInnerState(buildInnerState(state));
    });

    return () => removeListener("AuthProvider");
  }, [addListener, authState, removeListener]);

  const contextValue = useMemo(
    () => ({
      ...innerState,
      setUser,
      setAccount,
      clearAuthState,
      isLoading,
      onLogin,
      setOnLogin: (newOnLogin: (() => void) | undefined) =>
        setOnLogin(() => newOnLogin),
    }),
    [innerState, clearAuthState, setAccount, setUser, isLoading, onLogin],
  );

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
