export enum StatusIconType {
  Default = "default",
  Invited = "invited",
  Success = "success",
}

export enum StatusIconSize {
  sm = "sm",
  md = "md",
  lg = "lg",
}
