import { EmailPreview, EmailRecipientChips } from "shared/components";
import { formatDateTime, useBreakpoint } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { Button } from "@/modules/common/ui/button/Button";
import { DetailPageLayout } from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { CompanyLogo } from "@/modules/company/CompanyLogo";
import { EmailBody } from "@/modules/company/messages/preview/EmailBody";
import { useEmailActions } from "@/modules/company/messages/useEmailActions";
import { Router } from "@/routing/router";

const goBack = () => {
  Router.push("Emails");
};

export const ViewEmailPage = ({ id }: { id: string }) => {
  const actions = useEmailActions();
  const breakpoint = useBreakpoint();

  const { data: message } = useApi("getMessage", { id });
  const { data: messageInstances } = useApi("listMessageInstance", {
    messageId: id,
    selectAll: true,
  });

  if (!message || !messageInstances) {
    return <LoadingPlaceholder />;
  }

  const recipientIds = messageInstances.data.map(instance => instance.entityId);

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        header={
          <div>
            <h1 className="text-heading5-600 text-grey-900">
              {message.entity.subject}
            </h1>
            <p className="mt-2 text-body-400 text-grey-600">
              {message.entity.sentAt
                ? formatDateTime(message.entity.sentAt, "dayMonthYear")
                : "-"}
            </p>
          </div>
        }
        archivedEntityName={"Email"}
        isDesktop={breakpoint.md}
        crumbs={[
          { text: "Emails", onPress: () => Router.push("Emails") },
          { text: message.entity.subject },
        ]}
        actions={[
          {
            leftIcon: "sendOutline",
            title: "Edit and resend",
            onClick: () =>
              actions.editAndResend(message, actions.showUpdateForm),
          },
          {
            leftIcon: "trashOutline",
            title: "Delete",
            onClick: async () => {
              if (message) {
                await actions.deleteOne(message);
                goBack();
              }
            },
            destructive: true,
          },
        ]}>
        <div>
          <h2 className="mt-2 text-body-600 text-grey-900">Recipients</h2>
          <div className="mt-5">
            <EmailRecipientChips
              recipientIds={recipientIds}
              Button={props => <Button {...props} />}
              Tooltip={Tooltip}
            />
          </div>
          <h2 className="mt-10 text-body-600 text-grey-900">Message preview</h2>
          <div className="mt-5">
            <EmailPreview
              body={message.entity.body}
              EmailBody={EmailBody}
              CompanyLogo={CompanyLogo}
              classManagerLogo={
                <ClassManagerLogo color={"grey-600"} height={"24px"} />
              }
            />
          </div>
        </div>
      </DetailPageLayout>
    </CompanyLayout>
  );
};
