import React from "react";

import { AccountPermission } from "@justraviga/classmanager-sdk";
import * as ReactMenubar from "@radix-ui/react-menubar";
import { Align } from "@radix-ui/react-popper";

import { ProtectionChecker } from "shared/components";
import { cn, useBreakpoint, WebActionMenuProps } from "shared/lib";

import { ActionMenuDesktopItems } from "@/modules/common/ui/actionMenu/desktop/ActionMenuDesktopItems";
import { MobileActionMenuBottomSheet } from "@/modules/common/ui/actionMenu/mobile/MobileActionMenuBottomSheet";
import { IconName } from "@/modules/common/ui/icon/Icon";

export interface ActionMenuProps {
  header?: React.ReactNode;
  trigger: React.ReactNode;
  items: ActionMenuItemProps[];
  borderRadius?: "rounded" | "rounded-md";
  align?: Align;
  width?: string;
  showActionsAsBottomSheetOnMobile?: boolean;
}

export interface ActionMenuItemGroupProps {
  items: ActionMenuItemProps[];
}

export interface ActionMenuItemProps {
  title: string;
  description?: string;
  rightIcon?: IconName;
  leftIcon?: IconName;
  children?: React.ReactNode;
  justifyContent?: "start" | "between";
  disabled?: boolean;
  destructive?: boolean;
  hasDestructiveStyle?: boolean;
  onClick?: () => void;
  protector?: ProtectionChecker;
  group?: ActionMenuItemGroupProps;
  permission?: AccountPermission;
}

export const ActionMenu = ({
  header,
  trigger,
  items,
  borderRadius = "rounded",
  align = "end",
  width = "w-56",
  showActionsAsBottomSheetOnMobile,
}: WebActionMenuProps) => {
  const { md: showWebSize } = useBreakpoint();

  if (showActionsAsBottomSheetOnMobile && !showWebSize) {
    return (
      <MobileActionMenuBottomSheet
        header={header}
        items={items}
        trigger={trigger}
      />
    );
  }

  return (
    <ReactMenubar.Root>
      <ReactMenubar.Menu>
        <ReactMenubar.Trigger asChild={true} onClick={e => e.stopPropagation()}>
          {trigger}
        </ReactMenubar.Trigger>
        <ReactMenubar.Portal>
          <ReactMenubar.Content
            className={cn(
              width,
              "border border-grey-900 bg-white p-1 shadow-md",
              borderRadius,
            )}
            align={align}
            sideOffset={10}
            alignOffset={1}>
            {header && (
              <section className={"p-2 text-body-600"}>{header}</section>
            )}
            <ActionMenuDesktopItems items={items} />
          </ReactMenubar.Content>
        </ReactMenubar.Portal>
      </ReactMenubar.Menu>
    </ReactMenubar.Root>
  );
};
