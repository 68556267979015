import { useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  BillDto,
  CheckoutOperationRequest,
  CheckoutRequestItems,
} from "@justraviga/classmanager-sdk";

import {
  BannerComponent,
  basketToGetQuoteRequest,
  ButtonComponent,
  checkoutPayment,
  formatMoneyFromInteger,
  getPlatformFunctions,
  getQuoteFn,
  HorizontalSeparatorComponent,
  quoteDetails,
} from "shared/lib";

import { CheckoutLayout } from "./CheckoutLayout";
import { CheckoutLeftColumn } from "./CheckoutLeftColumn";
import { CheckoutRightColumn } from "./CheckoutRightColumn";
import { SpaceNotGuaranteedBanner } from "./SpaceNotGuaranteedBanner";
import { TransactionList } from "./TransactionList";
import { TransactionSummary } from "./TransactionSummary";
import { useAuthState } from "../../../AuthStateProvider";
import { useBasket } from "../../../basket/useBasket";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface CustomerCheckoutSummaryPageProps {
  Banner: BannerComponent;
  Button: ButtonComponent;
  HorizontalSeparator: HorizontalSeparatorComponent;
  openPaymentForm: (
    familyId: string,
    balance: number,
    requestItems: CheckoutRequestItems,
  ) => Promise<void>;
  onSuccess?: (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => void;
  isTakingPayment?: boolean;
}

export const CustomerCheckoutSummaryPage = ({
  Banner,
  Button,
  HorizontalSeparator,
  openPaymentForm,
  onSuccess,
  isTakingPayment = false,
}: CustomerCheckoutSummaryPageProps) => {
  const { View, Text } = useGenericComponents();
  const { api } = getPlatformFunctions();

  const { account } = useAuthState();

  const { getQuote, contents } = useBasket();

  const [transactions, setTransactions] = useState<BillDto[] | null>(null);
  const [isPendingTransaction, setIsPendingTransaction] = useState(false);

  useEffect(() => {
    getQuote(getQuoteFn(api, account!.entityId!)).then(setTransactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { total } = quoteDetails(transactions!);

  if (transactions === null) {
    return <Text>Loading basket...</Text>;
  }

  return (
    <CheckoutLayout>
      <CheckoutLeftColumn>
        <View className={"flex flex-col items-start justify-center space-y-8"}>
          <View className={"w-full"}>
            <SpaceNotGuaranteedBanner Banner={Banner} />
          </View>
          <View className={"w-full"}>
            <TransactionList
              transactions={transactions}
              HorizontalSeparator={HorizontalSeparator}
            />
          </View>
          <View className={"flex w-full flex-col space-y-2"}>
            <TransactionSummary transactions={transactions} />
          </View>
        </View>
        <View className={"w-full md:hidden"}>
          <HorizontalSeparator spacing={0} />
        </View>
      </CheckoutLeftColumn>
      <CheckoutRightColumn>
        <View
          className={
            "flex flex-col items-start justify-start space-y-4 py-4 md:py-0"
          }>
          <View className="flex w-full flex-row items-center justify-between">
            <Text className="text-heading5-600 text-grey-900">Total</Text>
            <Text className="text-heading5-600 text-grey-900">
              {formatMoneyFromInteger(total || 0)}
            </Text>
          </View>
          <View className={"w-full"}>
            <Button
              loading={isTakingPayment || isPendingTransaction}
              disabled={isTakingPayment || isPendingTransaction}
              className={"w-full"}
              variant={"brand"}
              text={total > 0 ? "Continue to pay" : "Confirm booking"}
              isFullWidth={true}
              onClick={() => {
                setIsPendingTransaction(true);
                // this function returns all properties on the requestInner plus familyID
                const quote = basketToGetQuoteRequest(
                  contents,
                  account?.entityId || "",
                ) as unknown as CheckoutRequestItems;
                if (total > 0) {
                  openPaymentForm(
                    account?.entityId || "",
                    total || 0,
                    quote,
                  ).finally(() => {
                    setIsPendingTransaction(false);
                  });
                } else {
                  const checkoutOperation = {
                    familyId: account!.entityId!,
                    checkoutRequest: {
                      paymentIntentId: "",
                      items: quote,
                    },
                  };
                  checkoutPayment(api, checkoutOperation, {
                    onSuccess: result => {
                      onSuccess?.(result, checkoutOperation);
                    },
                  }).finally(() => {
                    setIsPendingTransaction(false);
                  });
                }
              }}
            />
          </View>
        </View>
      </CheckoutRightColumn>
    </CheckoutLayout>
  );
};
