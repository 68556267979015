import { SeasonDto } from "@justraviga/classmanager-sdk";

import { SeasonStatusChip } from "./SeasonStatusChip";
import { calculateWeeksBetweenDates } from "../../../../dateUtils";
import { formatDate, formatMoneyFromInteger } from "../../../../intlFormatter";
import { numberToOrdinal } from "../../../../numberUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { taxRateLabel } from "../../../../taxRateUtils";
import { enumLabel } from "../../../../translateUtils";
import { useAuthState } from "../../../AuthStateProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../../../interfaces";
import {
  PreviewSheetContent,
  PreviewSheetContentDetailSection,
  PreviewSheetContentInfoPairRow,
  PreviewSheetContentInfoRowStatus,
  PreviewSheetContentRow,
  PreviewSheetContentSubTitle,
  PreviewSheetContentTitle,
} from "../../../preview/PreviewSheetContent";

interface SeasonPreviewProps {
  season: SeasonDto;
  navigateToSettings: () => void;
  HorizontalSeparator: HorizontalSeparatorComponent;
}

export const SeasonPreview = ({
  season,
  navigateToSettings,
  HorizontalSeparator,
}: SeasonPreviewProps) => {
  const { useApi } = getPlatformFunctions();
  const { Button, View } = useGenericComponents();
  const { account } = useAuthState();

  const { billingDayOfMonth, billingTiming } =
    account!.company!.settings.billing;

  const { data: taxRate } = useApi(
    "getTaxRate",
    { id: season.registrationFeeTaxRateId ?? "" },
    { enabled: season.registrationFeeTaxRateId !== null },
  );

  return (
    <PreviewSheetContent>
      <PreviewSheetContentDetailSection>
        <View className="flex flex-row justify-between space-x-1">
          <View className={"flex-grow flex-1"}>
            <PreviewSheetContentTitle title={season.name} />
          </View>
          <SeasonStatusChip season={season} />
        </View>
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle
          title="Schedule"
          icon="calendarClearOutline"
        />
        <PreviewSheetContentInfoPairRow
          left={"Start date"}
          right={formatDate(season.startAt, "dayMonthYear")}
        />
        <PreviewSheetContentInfoPairRow
          left={"End date"}
          right={formatDate(season.endAt, "dayMonthYear")}
        />
        <PreviewSheetContentInfoPairRow
          left={"Length"}
          right={
            calculateWeeksBetweenDates(
              new Date(season.startAt),
              new Date(season.endAt),
            ) + " weeks"
          }
        />
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Registration" />

        <PreviewSheetContentInfoRowStatus
          content="Allow registration via Member Portal"
          status={season.registrationOpen}
        />

        <PreviewSheetContentInfoPairRow
          left={"Registration fee / student"}
          right={formatMoneyFromInteger(season.registrationFee)}
          justify="start"
        />

        {season.hasMaxRegistrationFee && (
          <PreviewSheetContentInfoPairRow
            left={"Maximum registration fee / family"}
            right={formatMoneyFromInteger(season.maxRegistrationFee!)}
            justify="start"
          />
        )}

        <PreviewSheetContentInfoPairRow
          left={"Registration fee tax rate"}
          right={taxRateLabel(taxRate)}
          justify="start"
        />
      </PreviewSheetContentDetailSection>

      <HorizontalSeparator spacing={4} />

      <PreviewSheetContentDetailSection>
        <PreviewSheetContentSubTitle title="Automated tuition" />

        <PreviewSheetContentRow
          title={`Tuition due on ${numberToOrdinal(billingDayOfMonth)} of the month`}
        />
        <PreviewSheetContentRow
          title={enumLabel("billingTiming", billingTiming)}
          content={enumLabel("billingTimingDescription", billingTiming)}
        />

        <View>
          <Button
            variant={"link"}
            text={"Go to settings"}
            rightIcon={"openOutline"}
            onClick={navigateToSettings}
          />
        </View>
      </PreviewSheetContentDetailSection>
    </PreviewSheetContent>
  );
};
