import { EmailPreviewProps } from "./emailBody";
import { EmailCompanyFooter } from "./EmailCompanyFooter";
import { EmailCompanyHeader } from "./EmailCompanyHeader";
import { colors } from "../../../colors";
import { useAuthState } from "../../AuthStateProvider";
import { useGenericComponents } from "../../GenericComponentsProvider";

export const EmailPreview = ({
  body,
  EmailBody,
  CompanyLogo,
  classManagerLogo,
}: EmailPreviewProps) => {
  const { View } = useGenericComponents();

  const { account } = useAuthState();

  if (!account?.company) {
    return null;
  }

  const company = account.company;

  return (
    <View
      className={"flex flex-col items-center space-y-8 px-5 pb-8 pt-5 md:p-16"}
      style={{ backgroundColor: colors.brand["100"] }}>
      <View
        className={"w-full max-w-[600px] space-y-8 rounded-md bg-white p-10"}>
        <EmailCompanyHeader company={company} CompanyLogo={CompanyLogo} />
        <EmailBody body={body} />
      </View>
      <EmailCompanyFooter
        company={company}
        classManagerLogo={classManagerLogo}
      />
    </View>
  );
};
