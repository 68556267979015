import { BasketData, BasketStorageAdaptor } from "./basket";

const jsonReplacer = (_: unknown, value: unknown) => {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: [...value],
    };
  } else {
    return value;
  }
};

const serializedDateTimeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

const jsonReviver = (_: unknown, value: unknown) => {
  if (typeof value === "object" && value !== null) {
    // Handle Map objects
    if (
      "dataType" in value &&
      "value" in value &&
      value.dataType === "Map" &&
      Array.isArray(value.value)
    ) {
      return new Map(value.value);
    }
  }
  // Handle Date objects
  else if (typeof value === "string" && serializedDateTimeRegex.test(value)) {
    return new Date(value);
  }
  return value;
};

const isBasketData = (value: unknown): value is BasketData => {
  // This could be fleshed out if we need to migrate the basket format
  return value instanceof Map;
};

export const deserializeBasketData = (
  serializedValue: string,
): BasketData | null => {
  try {
    const parsed = JSON.parse(serializedValue, jsonReviver);
    if (!isBasketData(parsed)) {
      console.error("Invalid basket data format");
      return null;
    }
    return parsed;
  } catch (e) {
    console.error("Error parsing basket storage:", e);
    return null;
  }
};

export const serializeBasketData = (basketData: BasketData): string => {
  return JSON.stringify(basketData, jsonReplacer);
};

export const loadBasketDataFromStorage = async (
  adaptor: BasketStorageAdaptor,
): Promise<BasketData> => {
  const serializedValue = await adaptor.get();
  const deserializedValue = serializedValue
    ? deserializeBasketData(serializedValue)
    : null;
  return deserializedValue ?? new Map();
};

export const saveBasketDataToStorage = (
  adaptor: BasketStorageAdaptor,
  basketData: BasketData,
) => {
  const serializedValue = serializeBasketData(basketData);
  adaptor.set(serializedValue);
};
