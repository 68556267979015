import {
  TuitionFormMonthFormStepType,
  TuitionForMonthFormSchema,
} from "./tuitionForMonthForm";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";

export const useTuitionForMonthSecondStepForm = (
  builder: FormDefinitionBuilder<TuitionForMonthFormSchema>,
  step: TuitionFormMonthFormStepType,
) => {
  builder
    .group("Billing & payment details", ["billDate"])
    .conditional(["billDate"], ["billDate"], () => {
      return step === 2;
    })
    // .conditional(["cardId"], ["chargeCardOnFile"], v => {
    //   const payWithCard = v.chargeCardOnFile === true;
    //   setChargeCardOnFile(payWithCard);
    //
    //   return step === 2 && payWithCard;
    // })
    .date("billDate", {
      label: "Post on",
      required: step === 2,
    });
};
