import { getPlatformFunctions } from "./platformSpecific";

/**
 * Returns a full URL based on environment PUBLIC_URL value with a trailing slash
 */
export function basePath() {
  const { getEnv } = getPlatformFunctions();

  const baseUrl = getEnv("PUBLIC_URL") as string;

  // always make sure there's a trailing slash
  return baseUrl.replace(/\/$/, "") + "/";
}
