import { WaiverType } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { UseApi } from "../components/apiQueryFactory";

export const useWaivers = (useApi: UseApi) => {
  return useApi("listWaiver", {
    selectAll: true,
  });
};

export const useTermsAndConditions = (useApi: UseApi) => {
  const { data: waivers, ...rest } = useWaivers(useApi);
  const { status } = rest;

  return match(status)
    .with("pending", () => ({
      termsAndConditions: null,
      ...rest,
    }))
    .with("error", () => ({
      termsAndConditions: null,
      ...rest,
    }))
    .with("success", () => {
      const termsAndConditions = waivers?.data.find(
        waiver => waiver.type === WaiverType.TermsAndConditions,
      );
      if (!termsAndConditions) {
        return {
          termsAndConditions: null,
          ...rest,
          error: new Error("Terms and conditions not found"),
        };
      }

      return {
        termsAndConditions,
        ...rest,
      };
    })
    .exhaustive();
};

export const usePrivacyPolicy = (useApi: UseApi) => {
  const { data: waivers, ...rest } = useWaivers(useApi);
  const { status } = rest;

  return match(status)
    .with("pending", () => ({
      privacyPolicy: null,
      ...rest,
    }))
    .with("error", () => ({
      privacyPolicy: null,
      ...rest,
    }))
    .with("success", () => {
      const privacyPolicy = waivers?.data.find(
        waiver => waiver.type === WaiverType.PrivacyPolicy,
      );
      if (!privacyPolicy) {
        return {
          privacyPolicy: null,
          ...rest,
          error: new Error("Privacy policy not found"),
        };
      }

      return {
        privacyPolicy,
        ...rest,
        error: null,
      };
    })
    .exhaustive();
};

// TODO: use dynamic content when it becomes available
export const useClassManagerTermsAndConditions = (): {
  termsAndConditions: { name: string; content: string };
} => {
  return {
    termsAndConditions: {
      name: "Terms & conditions",
      content: `
Maecenas a elementum elit, sit amet ultrices ipsum. Nullam viverra sit amet nunc id malesuada. Aliquam posuere risus et magna dapibus, id bibendum enim mattis. Aenean leo libero, tincidunt sed malesuada eleifend, eleifend non enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus sollicitudin hendrerit erat, in fringilla nunc blandit id. Vivamus gravida sodales tortor, id elementum urna ullamcorper eu.

Etiam semper commodo felis quis luctus. Pellentesque tempor fringilla eros ac facilisis. Donec molestie lorem ipsum, vitae efficitur augue mollis non. Quisque vulputate ullamcorper ipsum, at eleifend augue condimentum nec. Mauris vel nisl ligula. Proin feugiat ultricies turpis sed laoreet. Vestibulum hendrerit magna sit amet viverra aliquet. Praesent placerat lectus id purus commodo, id congue ipsum pretium.`,
    },
  };
};

// TODO: use dynamic content when it becomes available
export const useClassManagerPrivacyPolicy = () => {
  return {
    privacyPolicy: {
      name: "Privacy policy",
      content: `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce commodo neque ut tellus luctus, in tristique turpis luctus. Fusce convallis aliquet nisi, ut porta nisi ullamcorper vitae. Ut molestie, est a pulvinar finibus, urna massa condimentum sem, nec pulvinar enim lacus ac nibh. Ut sit amet neque sagittis, semper nibh sagittis, faucibus tellus. Vestibulum quis porta ipsum. Ut posuere aliquam luctus. Suspendisse potenti. Phasellus et massa nec ante commodo pretium. Donec cursus neque sit amet felis auctor venenatis. Etiam efficitur, ante ac faucibus ultrices, velit sem convallis neque, nec egestas massa mi sed dui. Sed nec velit eget eros egestas lacinia. Sed semper at nibh eget mattis. Mauris in urna dapibus, tincidunt lorem quis, ultricies justo. Donec et semper arcu, at gravida orci.

Sed ultricies purus eget quam interdum tincidunt. Curabitur mattis eros neque, a porta velit sollicitudin vitae. Morbi ultricies consequat faucibus. Cras sed fermentum erat. Duis cursus euismod sapien, eu condimentum ligula malesuada ut. Nunc mattis ut metus ut aliquet. Donec pretium metus nec arcu mollis, id porttitor justo aliquam. Suspendisse nec ante non nisi convallis consectetur id ac felis. Praesent consequat sed felis nec maximus. Fusce id feugiat ipsum, id pellentesque libero.`,
    },
  };
};
