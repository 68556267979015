import { ReactNode } from "react";

import { ContentPlaceholder } from "shared/components";

interface ErrorLoadingPlaceholderProps {
  title: string;
  description: string;
  action?: ReactNode;
}

export const ContentErrorPlaceholder = ({
  title,
  description,
  action,
}: ErrorLoadingPlaceholderProps) => {
  return (
    <ContentPlaceholder
      icon={"alertCircleOutline"}
      title={title}
      description={description}
      action={action}
    />
  );
};
