import type { CustomFieldResponseDto } from "@justraviga/classmanager-sdk";
import { CustomFieldType } from "@justraviga/classmanager-sdk";

import { cn, colors, formatDate } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export const CustomFieldResponsesContent = ({
  fieldResponses,
}: {
  fieldResponses: CustomFieldResponseDto[];
}) => {
  const fieldResponseValue = (field: CustomFieldResponseDto) => {
    if (field.customField.type === CustomFieldType.Bool) {
      return field.value ? (
        <Icon name={"checkmarkCircle"} size={24} color={colors.green["600"]} />
      ) : (
        <Icon name={"closeCircle"} size={24} color={colors.grey["500"]} />
      );
    }

    if (typeof field.value == "number") {
      return field.value.toString();
    }

    if (Array.isArray(field.value)) {
      return field.value.join(", ");
    }

    if (typeof field.value === "string") {
      // If the field is a date, format it
      if (field.customField.type == CustomFieldType.Date) {
        return formatDate(field.value, "dayMonthYear");
      }
      return field.value;
    }

    return "-";
  };
  return (
    <div className={"flex flex-col space-y-4 p-2"}>
      {fieldResponses.map((field, index) => {
        return (
          <div
            key={index}
            className={cn("flex flex-col items-start space-y-1", {
              "flex-row items-center justify-between space-x-4 space-y-0":
                field.customField.type === CustomFieldType.Bool,
            })}>
            <span className={"text-sm font-normal text-grey-600"}>
              {field.customField.title}
            </span>
            <span className={"text-base text-grey-900"}>
              {fieldResponseValue(field)}
            </span>
          </div>
        );
      })}
    </div>
  );
};
