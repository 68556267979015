import { FC, ReactNode } from "react";

import { StudentDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { formatDate, formatDateTime } from "../../intlFormatter";
import {
  displayStudentAge,
  mobileStudentDetailSubtitle,
} from "../../studentUtils";
import {
  StudentFilterFormSchema,
  useStudentFilterForm,
} from "../formDefinitions/studentFilterForm";

type Dto = StudentDto;
type RowDto = Dto & {
  familyName: string;
};
type FilterForm = StudentFilterFormSchema;

export const useStudentDatatable = ({
  Datatable,
  api,
  archive,
  archiveMany,
  deleteMany,
  deleteOne,
  onRowClick,
  showCreateForm,
  showUpdateForm,
  unarchive,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, FilterForm, RowDto>;
  }>;
  api: Api;
  archive: (item: Dto) => Promise<void | Dto>;
  archiveMany: (ids: string[]) => Promise<void>;
  deleteMany: (ids: string[]) => Promise<void>;
  deleteOne: (item: Dto) => Promise<void>;
  onRowClick: (item: Dto) => void;
  showCreateForm: () => void;
  showUpdateForm: (item: Dto) => void;
  unarchive: (item: Dto) => Promise<Dto>;
}): { datatable: ReactNode } => {
  const filterForm = useStudentFilterForm();

  const fetchData = async (query: DatatableQueryProps) => {
    const studentResponse = await api.students.listStudent(query.toRequest());

    const familyIds = studentResponse.data.map(student => student.familyId);

    const { data: families } = await api.families.listFamily({
      where: {
        id: {
          in: familyIds,
        },
      },
      includeArchived: query.filters.some(
        f => f.field === "isArchived" && f.value === true,
      ),
    });

    return {
      ...studentResponse,
      data: studentResponse.data.map(student => ({
        ...student,
        familyName:
          families.find(family => family.id === student.familyId)?.name ?? "-",
      })),
    };
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: "student",
    title: "Students",
    createAction: showCreateForm,
    rowActions: {
      archive: archive,
      click: onRowClick,
      delete: deleteOne,
      edit: showUpdateForm,
      restore: unarchive,
      additionalRowActions: () => [
        {
          label: "Go to student",
          onClick: onRowClick,
        },
      ],
    },
    groupActions: {
      archiveMany: archiveMany,
      deleteMany: deleteMany,
    },
    contentPlaceholders: {
      noContent: {
        icon: "schoolOutline",
        title: "No students yet",
        description: "Create a student and add to a family",
      },
    },
    hasPagination: true,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Student",
        placeholder: "tile",
        value: row => {
          return {
            type: "tile",
            title: `${row.item.firstname} ${row.item.lastname}`,
            subtitle: displayStudentAge(row.item),
            image: row.item.profilePicture ?? undefined,
          };
        },
        sortableField: "firstname",
      },
      {
        label: "Date of Birth",
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text: row.item.dateOfBirth
              ? formatDate(row.item.dateOfBirth, "dayMonthYear")
              : "-",
          };
        },
      },
      {
        label: "Family",
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text: row.item.familyName,
          };
        },
      },
      {
        label: "Date created",
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text: row.item.createdAt
              ? formatDateTime(row.item.createdAt, "dayMonthYear")
              : "-",
          };
        },
      },
    ],
    mobileColumn: {
      hasImage: true,
      image: row => row.item.profilePicture ?? undefined,
      title: row => `${row.item.firstname} ${row.item.lastname}`,
      subtitle: row => mobileStudentDetailSubtitle(row.item),
      isPendingDeleted: row => !!row.item.deletedAt,
    },
    filterForm,
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
