import { LoginDto } from "@justraviga/classmanager-sdk";

import { useAuthState, useFormActions } from "shared/components";
import { showAlert } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { Router } from "@/routing/router";

export const useResetPasswordPageData = (email: string, token: string) => {
  const { setUser } = useAuthState();
  const { submit, isLoading: isSubmitting } = useFormActions();

  const { data, isLoading } = useApi(
    "checkToken",
    {
      checkTokenRequest: { email, token },
    },
    /**
     * Rob: I don't know why, but when the window re-focussed without the code below, React went
     * on an infinite re-render spree.
     */
    {
      refetchOnWindowFocus: false,
    },
  );

  const onSuccess = async ({ user, token }: LoginDto) => {
    setUser(user, token);
    showAlert({
      content: "Password updated successfully",
      variant: "success",
    });
    Router.push("Accounts");
  };

  return {
    onSuccess,
    submit,
    isLoading,
    isTokenValid: data?.message === "ok",
    isSubmitting,
  };
};
