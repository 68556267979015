import { WaiverMemberType } from "shared/lib";

import { Breadcrumb } from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

interface UseWaiversMembersBreadcrumbsParams {
  memberId: string;
  source: WaiverMemberType;
  name?: string;
}

export function useWaiversMembersBreadcrumbs({
  memberId,
  source,
  name,
}: UseWaiversMembersBreadcrumbsParams): Breadcrumb[] {
  const listBreadcrumb: Breadcrumb = {
    text: source,
    onPress: () =>
      Router.push(source === "Families" ? "FamilyList" : "StudentList"),
  };
  const currentBreadcrumb: Breadcrumb = {
    text: "Waivers & policies",
  };

  if (!name) {
    return [listBreadcrumb, currentBreadcrumb];
  }

  return [
    listBreadcrumb,
    {
      text: name,
      onPress: () =>
        Router.push(
          source === "Families" ? "FamilyDetails" : "StudentDetails",
          { id: memberId },
        ),
    },
    currentBreadcrumb,
  ];
}
