import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  ListEnrollmentsBySeason,
  SeasonStatusChip,
  useAuthState,
  useGenericComponents,
} from "shared/components";
import { colors } from "shared/lib";

import { CardContainer } from "@/modules/common/cardContainer/CardContainer";
import { BaseSelect } from "@/modules/common/form/select/BaseSelect";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { CustomerTitleBarTitle } from "@/modules/customer/common/CustomerTitle";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

export const FamilyCourseListPage = () => {
  const { View, Text } = useGenericComponents();

  const { account } = useAuthState();

  return (
    <CustomerLayout>
      <View className={"lg:px-16"}>
        <View className={"lg:px-8"}>
          <View className="flex flex-row items-center justify-between py-5">
            <View className="flex flex-row items-center justify-start gap-2">
              <CustomerTitleBarTitle title={"Classes"} />
            </View>
            <Button
              variant={"brand"}
              type={"button"}
              text={"Enroll"}
              onClick={() =>
                Router.push("CourseFilterWizard", {
                  slug: account!.company!.slug,
                  mode: "enroll",
                })
              }
            />
          </View>
        </View>
        <View className={"flex flex-col gap-8 py-4 lg:px-8"}>
          <CardContainer variant={"section"}>
            <View className={"grid grid-cols-2 gap-5 md:grid-cols-3 md:gap-8"}>
              <View>
                <BaseCard
                  icon={"time"}
                  title={"Trials"}
                  titleSize={16}
                  onClick={() => Router.push("FamilyTrials")}
                />
              </View>
              <View>
                <BaseCard
                  icon={"reorderFour"}
                  title={"Waiting lists"}
                  titleSize={16}
                  onClick={() => Router.push("FamilyWaitingList")}
                />
              </View>
              <View>
                <BaseCard
                  icon={"swapHorizontalOutline"}
                  title={"Make ups"}
                  titleSize={16}
                  onClick={() => Router.push("FamilyMakeUpLessons")}
                />
              </View>
            </View>
          </CardContainer>
          <View
            className={
              "rounded bg-white p-0 md:border md:border-grey-300 md:p-4"
            }>
            <ListEnrollmentsBySeason
              SeasonStatusChip={SeasonStatusChip}
              BaseSelect={BaseSelect}
              courseItem={(body, footer) => (
                <BaseCard
                  padding={2}
                  gap={0}
                  fillColor={colors.brand["100"]}
                  bodySlot={body}
                  footerSlot={footer}
                />
              )}
              HorizontalSeparator={HorizontalSeparator}
              SelectPlaceholder={({
                selectedSeason,
              }: {
                selectedSeason: SeasonDto;
              }) => (
                <View className={"flex flex-row items-center space-x-4"}>
                  <View
                    className={"min-w-0 flex-auto text-body-400 text-grey-900"}>
                    <Text truncate={true}>{selectedSeason.name}</Text>
                  </View>
                  <View className={"flex-initial"}>
                    <SeasonStatusChip season={selectedSeason} />
                  </View>
                </View>
              )}
            />
          </View>
        </View>
      </View>
    </CustomerLayout>
  );
};
