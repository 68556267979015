import { useState } from "react";

import {
  CompanyDto,
  LinkFamilyToCompanyRequest,
  PublicCompanyDto,
} from "@justraviga/classmanager-sdk";

import { useAuthState, useNameAndPhoneForm } from "shared/components";
import { displayApiValidationErrors } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { RegistrationWithTerms } from "@/modules/auth/common/RegistrationWithTerms";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";

interface CreateAccountFormProps {
  company: PublicCompanyDto | CompanyDto;
}

export const CreateAccountForm = ({ company }: CreateAccountFormProps) => {
  const { setAccount } = useAuthState();
  const [isLoading, setLoading] = useState(false);

  const createAccount = async (
    linkFamilyToCompanyRequest: LinkFamilyToCompanyRequest,
  ) => {
    setLoading(true);
    try {
      const { data: accounts } = await api.auth
        .linkFamilyToCompany({
          linkFamilyToCompanyRequest,
        })
        .then(() => api.auth.listAccounts());

      if (accounts && accounts.length === 1) {
        setAccount(accounts[0]);
      }
    } catch (e) {
      // @ts-expect-error We need to figure out these 'watch' types at some point
      displayApiValidationErrors(formHandlers)(e);
    } finally {
      setLoading(false);
    }
  };

  const { form, formHandlers } = useNameAndPhoneForm(
    useFormBuilder,
    createAccount,
    {
      firstname: "",
      lastname: "",
      phone: "",
    },
  );

  return (
    <RegistrationWithTerms
      companyName={company.name}
      displayTerms={true}
      form={form}
      isLoading={isLoading}
      onFormSubmission={formHandlers.handleSubmit(createAccount)}
    />
  );
};
