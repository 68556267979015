import { FieldValues, UseFormReturn } from "react-hook-form";

import { FieldDefinition } from "shared/lib";

import {
  createBigRadio,
  createCheckbox,
  createColorPalette,
  createColorPicker,
  createCourseSelect,
  createDate,
  createDayMonth,
  createDecimalInput,
  createImagePicker,
  createIntegerInput,
  createLessonSelect,
  createMultiSelect,
  createPassword,
  createRichTextInput,
  createSelect,
  createSwitch,
  createTextInput,
  createTime,
} from "@/modules/common/formBuilder/formHelpers";

const inputMap = {
  bigRadio: createBigRadio,
  checkbox: createCheckbox,
  color: createColorPicker,
  colorPalette: createColorPalette,
  courseId: createCourseSelect,
  lessonDateTime: createLessonSelect,
  date: createDate,
  dayMonth: createDayMonth,
  decimal: createDecimalInput,
  image: createImagePicker,
  integer: createIntegerInput,
  multiSelect: createMultiSelect,
  password: createPassword,
  richText: createRichTextInput,
  select: createSelect,
  switch: createSwitch,
  text: createTextInput,
  time: createTime,
};

export const FieldController = <Schema extends FieldValues>({
  field,
  formHandlers,
  fieldDef,
}: {
  field: keyof Schema;
  formHandlers: UseFormReturn<Schema>;
  fieldDef: FieldDefinition;
}) => {
  const render = inputMap[fieldDef.type];
  // @ts-expect-error - This is probably fixable, but it's not worth the effort
  const controllerFn = render(field.toString(), fieldDef.props);
  // @ts-expect-error - This is probably fixable, but it's not worth the effort
  return controllerFn(formHandlers);
};
