import { SortSchema } from "@justraviga/classmanager-sdk";
import type { ListTrialWhereParameter } from "@justraviga/classmanager-sdk/dist/models";

import { DatatableFilterOperator } from "../../datatable";
import { FilterFormDefinitionBuilder } from "../../forms/filterFormDefinitionBuilder";
import { UseApi } from "../apiQueryFactory";

export interface TrialsFilterFormSchema {
  classId: string;
  archivedOnly: boolean;
}

export const useTrialsFilterForm = ({ useApi }: { useApi: UseApi }) => {
  const { data: classData } = useApi("listCourse", {
    sort: {
      name: SortSchema.Asc,
    },
  });
  const { data: seasonData } = useApi("listSeason", {
    sort: {
      name: SortSchema.Asc,
    },
    selectAll: true,
  });

  return new FilterFormDefinitionBuilder<
    TrialsFilterFormSchema,
    ListTrialWhereParameter
  >()
    .select(
      "classId",
      {
        label: "Class",
        localSearch: true,
        data: classData
          ? classData.data.map(c => ({
              label: c.entity.name,
              description: (seasonData?.data || []).find(
                s => s.id === c.entity.seasonId,
              )?.name,
              value: c.entity.id,
            }))
          : [],
      },
      {
        filterField: "classId",
        operator: DatatableFilterOperator.Equals,
      },
    )
    .withArchiveFilter()
    .getDefinition();
};
