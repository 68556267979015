import { ReactNode, useState } from "react";

import { SeasonDto, SortSchema } from "@justraviga/classmanager-sdk";

import { FamilyCourseList } from "./FamilyCourseList";
import { colors } from "../../../../colors";
import { beginningOfToday } from "../../../../dateUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { loadingSeason, seasonPriority } from "../../../../seasonUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  BaseSelectComponent,
  HorizontalSeparatorComponent,
  SelectItem,
} from "../../../interfaces";

interface ListEnrollmentsBySeasonProps {
  BaseSelect: BaseSelectComponent;
  SeasonStatusChip: (props: { season: SeasonDto }) => JSX.Element;
  HorizontalSeparator: HorizontalSeparatorComponent;
  SelectPlaceholder: (props: { selectedSeason: SeasonDto }) => ReactNode;
  courseItem: (body: ReactNode, footer: ReactNode) => ReactNode;
}

export const ListEnrollmentsBySeason = ({
  BaseSelect,
  SeasonStatusChip,
  HorizontalSeparator,
  SelectPlaceholder,
  courseItem,
}: ListEnrollmentsBySeasonProps) => {
  const { View, Loader, Icon, Text } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const [selectedSeason, setSelectedSeason] =
    useState<SeasonDto>(loadingSeason);
  const { data: seasons, isLoading: isSeasonsLoading } = useApi("listSeason", {
    sort: {
      name: SortSchema.Desc,
    },
    selectAll: true,
  });

  // Seasons should not be archived and ordered newest start date at top
  const filteredSeasons =
    seasons?.data
      .filter(season => !season.archivedAt)
      .sort((a, b) => {
        return b.startAt > a.startAt ? 1 : b.startAt < a.startAt ? -1 : 0;
      }) ?? [];

  // selected item should be the oldest start date item from current, then upcoming then past
  const orderedFilteredSeasons = [...filteredSeasons]
    .sort((a, b) => {
      return b.startAt > a.startAt ? -1 : b.startAt < a.startAt ? 1 : 0;
    })
    .sort((a, b) => seasonPriority(b) - seasonPriority(a));
  if (!selectedSeason?.companyId && orderedFilteredSeasons.length > 0)
    setSelectedSeason(orderedFilteredSeasons[0]);

  return (
    <View className={"flex flex-col gap-5"}>
      <View className={"flex flex-col items-start gap-1"}>
        <Text className={"text-label-400 text-grey-600"}>Enrollments</Text>
        <Text className={"text-heading4-600 text-grey-900"}>By season</Text>
      </View>
      {isSeasonsLoading || !selectedSeason ? (
        <View className={"flex flex-row justify-center"}>
          <Loader />
        </View>
      ) : filteredSeasons.length === 0 ? (
        <View
          className={"flex flex-col items-center justify-center space-y-3 p-2"}>
          <View className={"rounded-full bg-brand-100 p-2"}>
            <Icon
              color={colors.brand["800"]}
              name={"helpCircleOutline"}
              size={24}
            />
          </View>
          <Text className={"text-heading6-600"}>No classes yet</Text>
          <Text className={"text-body-400 text-grey-600"}>
            You haven't enrolled for any classes.
          </Text>
        </View>
      ) : (
        <View>
          <BaseSelect
            data={filteredSeasons.map(season => ({
              label: season.name,
              value: season.id,
              append: <SeasonStatusChip season={season} />,
            }))}
            isSelected={item => item.value === (selectedSeason?.id ?? null)}
            onSelect={(value: SelectItem) => {
              const newSeason = filteredSeasons.find(
                season => season.id === value?.value,
              );
              newSeason && setSelectedSeason(newSeason);
            }}
            placeholder={<SelectPlaceholder selectedSeason={selectedSeason} />}
            clearable={false}
            onClickRemoveAll={() => {}}
          />
          <FamilyCourseList
            season={selectedSeason}
            courseItem={courseItem}
            today={beginningOfToday}
            HorizontalSeparator={HorizontalSeparator}
          />
        </View>
      )}
    </View>
  );
};
