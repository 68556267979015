import { useMemo, useState } from "react";

import { BaseInputProps, colors, debounce } from "shared/lib";

import { BaseInput, BaseInputValue } from "@/modules/common/form/BaseInput";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";

export interface SearchInputProps
  extends Omit<BaseInputProps, "append" | "prepend" | "prefix"> {
  /** Debounce time in milliseconds */
  debounceDelay?: number;
  clearBtn?: "text" | "icon";
}

export const SearchInput = ({
  placeholder,
  debounceDelay = 500,
  onChange,
  height = "sm",
  clearBtn = "icon",
  ...props
}: SearchInputProps) => {
  const [innerValue, setInnerValue] = useState<BaseInputValue>(
    props.value ?? null,
  );
  const emptyOnChange = () => {};
  const debouncedOnChange = useMemo(
    () => debounce(onChange ?? emptyOnChange, debounceDelay),
    [debounceDelay, onChange],
  );
  const clear = () => {
    setInnerValue("");
    onChange?.("");
  };

  const clearButtonIcon = (
    <div className="-mr-2">
      <IconButton
        aria-label="Clear search input"
        onClick={clear}
        variant="standard"
        icon="closeOutline"
        size={height === "sm" ? "xs" : "sm"}
        border={props.borderRadius === "rounded-full" ? "rounded" : "square"}
        color={colors.grey["900"]}
      />
    </div>
  );

  const clearButtonText = (
    <div className="-mr-2">
      <Button
        text="Clear"
        onClick={clear}
        variant="subtle"
        size={height === "sm" ? "xs" : "sm"}
        color={colors.grey["600"]}
      />
    </div>
  );

  const clearButton = clearBtn === "icon" ? clearButtonIcon : clearButtonText;
  const showClearButton = innerValue && innerValue !== "";

  return (
    <BaseInput
      {...props}
      value={innerValue}
      height={height}
      placeholder={placeholder || "Search"}
      prepend={<Icon name="searchOutline" size={20} color={colors.grey[500]} />}
      append={showClearButton && clearButton}
      onChange={value => {
        setInnerValue(value);
        debouncedOnChange(value);
      }}
      selectTextOnFocus={true}
    />
  );
};
