import { PropsWithChildren } from "react";

import { ContentPlaceholder } from "shared/components";
import {
  GlobalSearchResultsType,
  GlobalSearchStatus,
  useSearchResults,
  useSearchStatus,
} from "shared/lib";

import { GlobalSearchInput } from "@/modules/common/search/GlobalSearchInput";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { NoResultsPlaceholder } from "@/modules/common/search/NoResultsPlaceholder";
import { SearchResults } from "@/modules/common/search/SearchResults";
import { SearchResultsCount } from "@/modules/common/search/SearchResultsCount";
import { TypeToSearchPlaceholder } from "@/modules/common/search/TypeToSearchPlaceholder";

const SearchResultsContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <div
    data-testid="search-dialog-container"
    className={`min-h-96 relative flex h-[100vh] min-w-min flex-col md:h-fit md:max-h-[calc(100vh-(2*4rem))]`}>
    <GlobalSearchInput />
    {children}
  </div>
);

const SearchResultsListContainer: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <div
    className={
      "flex grow flex-col overflow-x-hidden px-1 py-1 md:justify-center"
    }
    data-testid="search-results-list-container">
    {children}
  </div>
);

export const SearchDialogContent = () => {
  const { status } = useSearchStatus();
  const { searchResults } = useSearchResults();

  if (status === GlobalSearchStatus.LOADING) {
    return (
      <SearchResultsContainer>
        <LoadingPlaceholder />
      </SearchResultsContainer>
    );
  }

  if (status === GlobalSearchStatus.OK) {
    if (searchResults.type === GlobalSearchResultsType.clean) {
      return (
        <SearchResultsContainer>
          <SearchResultsListContainer>
            <TypeToSearchPlaceholder />
          </SearchResultsListContainer>
        </SearchResultsContainer>
      );
    }

    if (
      searchResults.type === GlobalSearchResultsType.response &&
      searchResults.totalCount === 0
    ) {
      return (
        <SearchResultsContainer>
          <SearchResultsListContainer>
            <NoResultsPlaceholder />
          </SearchResultsListContainer>
        </SearchResultsContainer>
      );
    }

    if (
      searchResults.type === GlobalSearchResultsType.response &&
      searchResults.totalCount > 0
    ) {
      return (
        <SearchResultsContainer>
          <SearchResultsCount results={searchResults} />
          <SearchResultsListContainer>
            <SearchResults results={searchResults} />
          </SearchResultsListContainer>
        </SearchResultsContainer>
      );
    }
  }

  return (
    <SearchResultsContainer>
      <SearchResultsListContainer>
        <ContentPlaceholder
          icon="helpCircleOutline"
          title="Search feature unavailable"
          description="The search feature could not be loaded at this time. Please close the window and try again later."
        />
      </SearchResultsListContainer>
    </SearchResultsContainer>
  );
};
