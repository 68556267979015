import { BannerProps, colors } from "shared/lib";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { Banner } from "@/modules/common/ui/Banner";

const options: Option<BannerProps>[] = [
  {
    title: "Basic",
    props: [
      {
        content: "Something happened",
      },
      {
        title: "Information",
        content: "Something happened",
      },
      {
        // This is just to demonstrate that any component can be passed as 'content'
        content: (
          <p
            className={
              "rounded bg-gradient-to-r from-blue-500 to-green-500 px-2 py-1 font-semibold text-black"
            }>
            Custom content component
          </p>
        ),
      },
    ],
  },
  {
    title: "With close button",
    props: [
      {
        content: "Something happened",
        onClose() {},
      },
    ],
  },
  {
    title: "With Icon",
    props: [
      {
        content: "This feature is still in a testing phase.",
        icon: { name: "chatbubblesOutline", color: colors.blue["600"] },
      },
      {
        content: "Deleting a student will delete all related data.",
        icon: { name: "trashOutline", color: colors.red["600"] },
      },
      {
        title: "I have a title...",
        content: "...and a bit of content",
        icon: { name: "arrowDownCircle", color: colors.blue["600"] },
      },
    ],
  },
  {
    title: "With button",
    props: [
      {
        content: "Something happened",
        action: {
          text: "Do something",
          onClick() {},
        },
      },
      {
        content: "Something happened",
        onClose() {},
        action: {
          text: "Do something",
          onClick() {},
        },
      },
      {
        title: "I have a title...",
        content: "...and a bit of content",
        onClose() {},
        action: {
          text: "Do something",
          onClick() {},
        },
      },
    ],
  },
  {
    title: "Variants",
    props: [
      {
        variant: "success",
        content: "Success",
      },
      {
        variant: "error",
        content: "Error",
      },
      {
        variant: "info",
        content: "Info",
      },
      {
        variant: "warning",
        content: "Warning",
      },
      {
        variant: "critical",
        content: "Critical",
      },
      {
        variant: "important",
        content: "Important",
      },
    ],
  },
  {
    title: "Kitchen sink",
    props: [
      {
        icon: { name: "bagHandleOutline" },
        title: "Item successfully added",
        content: "3x Extra large socks (£3.99 ea)",
        action: {
          text: "Go to basket",
          onClick() {},
        },
        onClose() {},
      },
      {
        variant: "critical",
        title: "Critical with title",
        content: "And a custom icon",
        icon: {
          name: "bagHandleOutline",
          color: colors.yellow["500"],
        },
        action: {
          text: "To the Bat cave!",
          onClick() {},
        },
        onClose() {},
      },
    ],
  },
];

export const BannerPage = () => {
  return <DocWrapper title={"Banner"} component={Banner} options={options} />;
};
