import { ForgotPasswordRequest } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = ForgotPasswordRequest;

export const useForgottenPasswordFormDefinition = () =>
  new FormDefinitionBuilder<Schema>()
    .text("email", {
      label: "Email address",
      type: "email",
    })
    .getDefinition();

export const makeForgottenPasswordRequest =
  ({ api }: { api: Api }) =>
  async (formData: Schema) => {
    return api.auth
      .forgotPassword({
        forgotPasswordRequest: formData,
      })
      .then(() => ({}));
  };
