import { RegisterCompanyRequest } from "@justraviga/classmanager-sdk";

import {
  Api,
  CountryCodeParam,
  FormDefinitionBuilder,
  getTimezone,
  toCountryCodeEnum,
} from "shared/lib";

export const useRegistrationFormDefinition = (
  withoutAuthFields: boolean = false,
) => {
  const form = new FormDefinitionBuilder<RegisterCompanyRequest>().text(
    "name",
    {
      label: "Company name",
      required: true,
    },
  );

  if (withoutAuthFields) {
    form
      .text("email", {
        label: "Email address",
        type: "email",
        required: true,
      })
      .password("password", {
        label: "Password",
        required: true,
        placeholder: "Minimum 10 characters",
      })
      .row(["firstname", "lastname"])
      .text("firstname", { label: "First name", required: true })
      .text("lastname", { label: "Last name", required: true });
  }

  form.text("phone", { label: "Phone", required: true });

  return form.getDefinition();
};

export const useRegistrationForm = () => useRegistrationFormDefinition(true);
export const useRegistrationFormWithAuth = () =>
  useRegistrationFormDefinition(false);

export const makeRegistrationRequest =
  ({ api, countryCode }: { api: Api; countryCode: CountryCodeParam }) =>
  (formData: RegisterCompanyRequest) => {
    formData.countryCode = toCountryCodeEnum(countryCode);
    formData.timezone = getTimezone();

    return api.auth.registerCompany({
      registerCompanyRequest: formData,
    });
  };
