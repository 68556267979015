import { formatDateTime } from "../../../../intlFormatter";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const ChartTitle = ({
  title,
  lastUpdated,
}: {
  title: string;
  lastUpdated: Date;
}) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className={"md:flex md:items-center md:justify-between"}>
      <Text className={"text-heading4-600"}>{title}</Text>
      <Text className={"text-caption-400 text-grey-600"}>
        Last updated: {formatDateTime(lastUpdated, "dayMonthYearHourMinute")}
      </Text>
    </View>
  );
};
