import { RegisterUserRequest } from "@justraviga/classmanager-sdk";

import { DefaultValues, FormBuilder } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = RegisterUserRequest;

function formDefinition(hideEmail = false) {
  return new FormDefinitionBuilder<Schema>()
    .conditional(["email"], ["email"], () => {
      return !hideEmail;
    })
    .text("email", {
      label: "Email address",
      type: "email",
      required: true,
    })
    .password("password", { label: "Password", required: true })
    .getDefinition();
}

export const useUserRegisterForm = (
  builder: FormBuilder,
  onSubmit: (data: Schema) => void,
  defaultValues: DefaultValues<Schema>,
  hideEmail = true,
) => {
  return builder(formDefinition(hideEmail), onSubmit, defaultValues);
};
