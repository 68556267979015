import {
  JoinWaitingListSuccessPage as SharedJoinWaitingListSuccessPage,
  useCatalogueContext,
} from "shared/components";

import { Button } from "@/modules/common/ui/button/Button";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { PublicLayout } from "@/modules/public/layout/PublicLayout";
import { Router } from "@/routing/router";

export const JoinWaitingListSuccessPage = () => {
  const { company } = useCatalogueContext();

  return (
    <PublicLayout>
      <SharedJoinWaitingListSuccessPage
        Button={Button}
        Icon={Icon}
        goToDashboard={() => Router.push("CustomerHome")}
        goToEnrollments={() => Router.push("Courses", { slug: company.slug })}
      />
    </PublicLayout>
  );
};
