import { formatMoneyFromInteger } from "../../intlFormatter";
import { getBalanceColor } from "../../transactions/transactionStyles";
import { useGenericComponents } from "../GenericComponentsProvider";

export const BalanceIndicator = ({
  balance,
  direction = "horizontal",
}: {
  balance: number;
  direction?: "horizontal" | "vertical";
}) => {
  const { Text, View } = useGenericComponents();
  const verticalClass = "flex-col gap-y-2";
  const horizontalClass = "flex-row items-baseline gap-x-1 pb-2";

  return (
    <View
      className={`flex ${direction === "vertical" ? verticalClass : horizontalClass}`}>
      <Text
        className="text-heading4-600 font-semibold"
        style={{
          color: getBalanceColor(balance),
        }}>
        {formatMoneyFromInteger(balance)}
      </Text>
      <Text className="text-body-400 text-grey-900">Account balance</Text>
    </View>
  );
};
