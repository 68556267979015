import { CodeProps, DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  RangeDatePicker,
  RangeDatePickerProps,
} from "@/modules/common/form/datePicker/RangeDatePicker";

const options: Array<Option<RangeDatePickerProps>> = [
  {
    title: "range select",
    props: [{}],
  },
  {
    title: "locales",
    props: [
      {
        locale: "en-US",
      },
      {
        locale: "en-GB",
      },
      {
        locale: "en-CA",
      },
    ],
  },
];

export const RangeDatePickerPage = () => {
  const getLocaleCode = (
    props: RangeDatePickerProps[],
  ): CodeProps<RangeDatePickerProps>[] => {
    return props.map(function (prop: RangeDatePickerProps) {
      return {
        ...prop,
      };
    });
  };

  return (
    <DocWrapper
      title={"Date Range Picker"}
      component={RangeDatePicker}
      options={options}
      transformProps={getLocaleCode}
    />
  );
};
