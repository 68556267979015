import { StudentDto } from "@justraviga/classmanager-sdk";

import { displayStudentAge, getFullName, getInitials } from "shared/lib";

import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { StudentActionMenu } from "@/modules/customer/students/menus/StudentActionMenu";

export const StudentMobileCard = ({ student }: { student: StudentDto }) => {
  const { showPreview } = useStudentActions();

  return (
    <BaseCard
      bodySlot={
        <div className={"flex justify-between pb-2"}>
          <Avatar
            size="xl"
            src={student.profilePicture ?? undefined}
            name={getInitials(student)}
          />

          <StudentActionMenu student={student} />
        </div>
      }
      footerSlot={
        <div className={"space-y-3"}>
          <div>
            <h3 className={"text-heading6-600 text-grey-900"}>
              {getFullName(student)}
            </h3>
          </div>

          <div className={"space-y-2"}>
            <div className={"flex space-x-1"}>
              <p className={"text-body-400 text-grey-600"}>Age</p>
              <p className={"text-body-400 text-grey-900"}>
                {student.dateOfBirth ? displayStudentAge(student, "") : "-"}
              </p>
            </div>

            <div className={"-ml-4 flex h-8"}>
              <Button
                variant={"tertiary"}
                text={"More details"}
                onClick={() => showPreview(student)}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
