import { useEffect, useState } from "react";

import { useFormActions } from "./FormActionsProvider";
import {
  AvatarUploadState,
  makeAvatarCreateOrDeleteRequest,
} from "./formDefinitions/avatarForm";
import { showAlert } from "../alertState";
import { getPlatformFunctions } from "../platformSpecific";

interface UserWithAvatar {
  profilePicture: string | null;
  id: string;
}

export function useAvatarSubmit(user: UserWithAvatar, closeSheet: () => void) {
  const { api } = getPlatformFunctions();
  const { setSubmit, shouldCloseOnSuccess } = useFormActions();
  const [avatarUploadState, setAvatarUploadState] = useState<AvatarUploadState>(
    {
      status: user.profilePicture ? "has-avatar" : "no-avatar",
    },
  );

  useEffect(() => {
    setSubmit(
      makeAvatarCreateOrDeleteRequest({
        api,
        entityId: user.id,
        avatarUploadState,
        onSuccess: (hasUpdated: boolean) => {
          if (hasUpdated) {
            showAlert({
              content: "Profile picture updated successfully",
            });
          }
          if (shouldCloseOnSuccess()) {
            closeSheet();
          }
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarUploadState, user]);

  const addNewAvatar = (path: string) => {
    setAvatarUploadState({
      status: "new-avatar",
      uploadedPath: path,
    });
  };

  const deleteAvatar = () => {
    setAvatarUploadState({
      status: "delete-avatar",
    });
  };

  return { addNewAvatar, deleteAvatar };
}
