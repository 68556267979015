import { ReactNode } from "react";

import { colors } from "shared/lib";

import { useGenericComponents } from "../../../GenericComponentsProvider";

export interface CheckoutSuccessMessageProps {
  action?: ReactNode;
  message?: string;
}

export const CheckoutSuccessMessage = ({
  action,
  message = "You're all booked in!",
}: CheckoutSuccessMessageProps) => {
  const { Icon, Text, View } = useGenericComponents();
  return (
    <View className={"flex flex-col justify-center items-center px-5 py-4"}>
      <View className="flex flex-col w-full justify-center p-2 text-center items-center space-y-3">
        <View
          className={
            "flex h-10 w-10 items-center justify-center rounded-full bg-green-600"
          }>
          <Icon size={24} name={"checkmarkCircle"} color={colors.white} />
        </View>

        <View>
          <Text className={"text-center text-heading6-600 text-grey-900"}>
            {message}
          </Text>
        </View>
      </View>
      {action && <View className={"px-5 py-2 inline-block"}>{action}</View>}
    </View>
  );
};
