import {
  Bar,
  BarChart,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  BillingHistoryChartData,
  CustomTooltip,
  TooltipSection,
} from "shared/components";
import { colors, formatDate, formatMoneyFromInteger } from "shared/lib";

import { ChartContainer } from "@/modules/company/dashboard/charts/ChartContainer";
import { referenceLines } from "@/modules/company/dashboard/charts/referenceLines";

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: BillingHistoryChartData }>;
}

const calculateYAxisBounds = (chartData: BillingHistoryChartData[]) => {
  const allValues = chartData.flatMap(item => [
    item.totalCharges,
    item.totalPayments,
  ]);
  const maxAbs = Math.max(...allValues.map(Math.abs));
  const yAxisMax = Math.ceil(maxAbs / 10) * 10;
  const yAxisMin = Math.min(...allValues) < 0 ? -yAxisMax : 0;
  return { yAxisMin, yAxisMax };
};

const Tooltip = ({ active, payload }: CustomTooltipProps) => {
  if (!active || !payload || !payload.length) return null;

  const {
    transactions,
    creditNotes,
    totalCharges,
    payments,
    refunds,
    totalPayments,
    month,
  } = payload[0].payload;

  return (
    <CustomTooltip title={formatDate(`${month}-01`, "monthYear")}>
      <TooltipSection
        title="Charges"
        data={[
          {
            value: formatMoneyFromInteger(transactions),
            label: "Transactions",
          },
          {
            value: formatMoneyFromInteger(creditNotes),
            label: "Credit notes",
          },
        ]}
        total={formatMoneyFromInteger(totalCharges)}
        colour={colors.brand["800"]}
      />
      <TooltipSection
        title="Payments"
        data={[
          {
            value: formatMoneyFromInteger(payments),
            label: "Payments",
          },
          {
            value: formatMoneyFromInteger(refunds),
            label: "Refunds",
          },
        ]}
        total={formatMoneyFromInteger(totalPayments)}
        colour={colors.brand["600"]}
      />
    </CustomTooltip>
  );
};

export const BillingHistoryChart = ({
  data,
}: {
  data: BillingHistoryChartData[];
}) => {
  const { yAxisMin, yAxisMax } = calculateYAxisBounds(data);

  return (
    <ChartContainer>
      <BarChart data={data} barGap={4}>
        {...referenceLines({
          yAxisMin,
          yAxisMax,
          yAxisDivisions: 4,
        })}
        <XAxis dataKey="name" axisLine={false} tickLine={false} />
        <YAxis hide domain={[yAxisMin, yAxisMax]} />
        <RechartsTooltip
          cursor={{ fill: "transparent" }}
          content={<Tooltip />}
        />
        <Bar dataKey="totalCharges" fill={colors.brand["800"]} radius={4} />
        <Bar dataKey="totalPayments" fill={colors.brand["600"]} radius={4} />
      </BarChart>
    </ChartContainer>
  );
};
