import { useGenericComponents } from "../../../GenericComponentsProvider";
import { IconComponent } from "../../../interfaces";

interface NoStudentsSelectedProps {
  Icon: IconComponent;
}
export const NoStudentsSelected = ({ Icon }: NoStudentsSelectedProps) => {
  const { View, Text } = useGenericComponents();
  return (
    <View className={"flex flex-row space-x-1 items-center"}>
      <Icon name={"alertCircle"} color={"red"} size={24} />
      <Text className={"text-red-600 text-sm"}>
        No student assigned to this class.
      </Text>
    </View>
  );
};
