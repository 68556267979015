import { LocationDto } from "@justraviga/classmanager-sdk";

import { formatTime } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";
import { DetailProfileContainer } from "@/modules/company/common/DetailProfile";

export const LocationDetailHeader = ({
  location,
}: {
  location: LocationDto;
}) => {
  return (
    <DetailProfileContainer>
      <p className={"line-clamp-1 text-heading5-600"}>{location.name}</p>
      <div className={"flex items-center gap-2"}>
        <Icon name={"timeOutline"} />
        <span>
          {formatTime(location.openTime, "hourMinute")} -{" "}
          {formatTime(location.closeTime, "hourMinute")}
        </span>
      </div>
    </DetailProfileContainer>
  );
};
