import { FC, ReactNode } from "react";

import {
  ContactDto,
  FamilyDto,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import {
  DatatableConfiguration,
  DatatablePaginatedData,
  DatatableQueryProps,
} from "../../datatable";
import { getFullName } from "../../personUtil";

type Dto = FamilyDto;
type RowDto = Dto & {
  primaryContact: ContactDto;
};

export const useMinimalFamilyDatatable = ({
  Datatable,
  api,
  onRowClick,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, Record<string, never>, RowDto>;
  }>;
  api: Api;
  onRowClick: (item: Dto) => void;
}): { datatable: ReactNode } => {
  const fetchData = async (
    query: DatatableQueryProps,
  ): Promise<DatatablePaginatedData<RowDto>> => {
    const page = query.search !== "" ? 1 : query.page;

    const familyResponse = await api.families.listFamily({
      ...query.toRequest(),
      page: page,
      sort: { name: SortSchema.Asc },
    });

    const familyIds = familyResponse.data.map(family => family.id);

    const contactQuery = api.contacts.listContact({
      where: { familyId: { in: familyIds } },
      selectAll: true,
    });

    const [{ data: contacts }] = await Promise.all([contactQuery]);

    const data = familyResponse.data.map(family => ({
      ...family,
      primaryContact: contacts.find(
        c => c.familyId === family.id && c.isPrimary,
      )!,
    }));

    return {
      ...familyResponse,
      data,
    };
  };

  const config: DatatableConfiguration<Dto, Record<string, never>, RowDto> = {
    id: "familyMinimal",
    showTotalRecords: true,
    rowActions: {
      click: onRowClick,
    },
    fetchData,
    isMobile: true,
    hasPagination: true,
    tableRowClass: "border-0",
    scrollEnabledOnMobile: false,
    mobileColumn: {
      title: row => row.item.name,
      hasImage: true,
      image: () => undefined,
      subtitle: row => {
        return getFullName(row.item.primaryContact);
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
