import React, { MouseEventHandler, ReactNode, RefObject } from "react";
import { ScrollView } from "react-native";

import { PricingSchemeValueDto, SeasonDto } from "@justraviga/classmanager-sdk";
import {
  FocusOutsideEvent,
  PointerDownOutsideEvent,
} from "@radix-ui/react-dismissable-layer";

import { Api } from "../../api";
import { CreatePricingTierCard } from "../modules/pricingSchemes/CreatePricingTierCard";
import { UpdatePricingTierCard } from "../modules/pricingSchemes/UpdatePricingTierCard";
import {
  DefaultEntityActions,
  EntityActions,
} from "../useDefaultEntityActions";

type Model = PricingSchemeValueDto;
type ImplementedActions = Omit<
  EntityActions<Model>,
  | "deleteMany"
  | "archiveMany"
  | "archive"
  | "unarchive"
  | "unarchiveMany"
  | "showCreateForm"
> & {
  showCreateForm: (season: SeasonDto, quantity: number) => void;
};

type UpdateFooterProps = {
  saveButtonText?: string;
};
type ShowFormProps = {
  onClose?: () => void;
  scrollable?: boolean;
  showHeader?: boolean;
  hasPadding?: boolean;
  onContentSizeChange?: (h: number) => void;
  scrollViewRef?: RefObject<ScrollView>;
  side?: "top" | "bottom" | "right";
  title?: string | React.ReactNode;
  content?: React.ReactNode | React.ReactNode[];
  footer?: React.ReactNode | React.ReactNode[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onCloseIconClick?: MouseEventHandler<HTMLButtonElement>;
  onInteractOutside?: (
    event: PointerDownOutsideEvent | FocusOutsideEvent,
  ) => void;
  isModal?: boolean;
  padding?: string;
  zIndex?: string | number;
  dialog?: boolean;
  containerPadding?: number;
  hasBackdropOnOpen?: boolean;
};
export const useSharedPricingTierActions = (
  api: Api,
  defaultActions: DefaultEntityActions<Model>,
  showForm:
    | ((props: ShowFormProps) => void)
    | ((props: {
        onClose?: () => void;
        title?: string;
        footer?: ReactNode;
        content: ReactNode;
        scrollable?: boolean;
        showHeader?: boolean;
        hasPadding?: boolean;
        onContentSizeChange?: (h: number) => void;
        scrollViewRef?: RefObject<ScrollView>;
      }) => void),
  CreateFooter: () => JSX.Element,
  UpdateFooter: (props: UpdateFooterProps) => JSX.Element,
): ImplementedActions => {
  return {
    deleteOne: item =>
      defaultActions.deleteOne(() =>
        api.pricingSchemeValues.deletePricingSchemeValue({ id: item.id }),
      ),
    showCreateForm: (season: SeasonDto, quantity: number) =>
      showForm({
        title: "Add pricing tier",
        content: (
          <CreatePricingTierCard
            season={season}
            api={api}
            classCountQuantity={quantity}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
        footer: <CreateFooter />,
      }),
    showUpdateForm: (entity, season) => {
      showForm({
        title: "Edit pricing tier",
        content: (
          <UpdatePricingTierCard
            api={api}
            entity={entity}
            season={season}
            defaultActions={defaultActions}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
  };
};
