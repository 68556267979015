import { TodaysScheduleWidget as SharedTodaysScheduleWidget } from "shared/components";

import { Select } from "@/modules/common/form/select/Select";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { Router } from "@/routing/router";

export const TodaysScheduleWidget = () => {
  return (
    <SharedTodaysScheduleWidget
      BaseCard={BaseCard}
      Button={Button}
      Icon={Icon}
      Select={Select}
      goToAttendances={params =>
        Router.push("ClassLessonAttendances", {
          ...params,
          id: params.courseId,
        })
      }
      goToLesson={params =>
        Router.push("LessonDetails", { ...params, id: params.courseId })
      }
    />
  );
};
