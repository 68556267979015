import { AdditionalLesson } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { useFormActions } from "../FormActionsProvider";
import { useSettings } from "../useSettings";

export type LessonAddSchema = Omit<AdditionalLesson, "classId">;

export const useLessonAddForm = () => {
  const { setAllowCreateAdditional, setCreateButtonText } = useFormActions();
  const { locale, use12HourClock } = useSettings("general");
  const clockType = use12HourClock ? "12-hour" : "24-hour";

  setAllowCreateAdditional(false);
  setCreateButtonText("Save");

  const builder = new FormDefinitionBuilder<LessonAddSchema>()
    .date("date", { label: "Select date", required: true })
    .time("time", { label: "Start time", locale, required: true, clockType });

  return builder.getDefinition() as FormDefinition<LessonAddSchema>;
};

export const makeLessonAddRequest =
  ({ api, classId }: { api: Api; classId: string }) =>
  (data: LessonAddSchema) => {
    const lessonData: AdditionalLesson = {
      ...data,
      classId,
    };

    return api.lessonAdjustments.additionalLessonLessonAdjustment({
      additionalLesson: [lessonData],
    });
  };
