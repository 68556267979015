import { PropsWithChildren, useEffect, useState } from "react";

import {
  CompanyIntegrationStripeSettingsDto,
  StripeRegion,
} from "@justraviga/classmanager-sdk";
import { Stripe } from "@stripe/stripe-js";

import { StripeContext } from "./StripeContext";
import { StripeLoader } from "./stripeLoader";
import { useAuthState } from "../../components/AuthStateProvider";

interface StripeConfigProviderProps extends PropsWithChildren {
  stripeLoader: StripeLoader;
}

const mobileStripeContext = {
  stripe: null,
  setStripeIntegration: () => {},
};

export const MobileStripeProvider = ({ children }: PropsWithChildren) => (
  <StripeContext.Provider value={mobileStripeContext}>
    {children}
  </StripeContext.Provider>
);

export const StripeProvider = ({
  stripeLoader,
  children,
}: StripeConfigProviderProps) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const { account } = useAuthState();
  const [stripeIntegration, setStripeIntegration] = useState<
    CompanyIntegrationStripeSettingsDto | object
  >(account?.company?.settings.integrations?.stripe || {});

  const { region, connectAccountId } =
    stripeIntegration as CompanyIntegrationStripeSettingsDto;

  useEffect(() => {
    const getStripe = async (
      region: StripeRegion,
      connectAccountId: string,
    ) => {
      try {
        const newStripe = await stripeLoader.load({
          region,
          connectAccountId,
        });
        setStripe(currentStripe => {
          if (currentStripe !== newStripe) {
            return newStripe;
          }
          return currentStripe;
        });
      } catch (e) {
        console.error(e);
      }
    };

    if (region && connectAccountId) {
      getStripe(region, connectAccountId);
    }
  }, [region, connectAccountId, stripeLoader]);

  return (
    <StripeContext.Provider value={{ stripe, setStripeIntegration }}>
      {children}
    </StripeContext.Provider>
  );
};
