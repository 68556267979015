import { AgreementDto } from "@justraviga/classmanager-sdk";

import { formatDateTime, WaiverEntityWithAgreement } from "shared/lib";

import { PreviewSheetContent } from "@/modules/common/sheet/preview/PreviewSheetContent";
import { WaiverAgreementChip } from "@/modules/company/waivers/WaiverAgreementChip";

interface AgreementDetailsProps {
  agreement?: AgreementDto;
}

const AgreementDetails = ({ agreement }: AgreementDetailsProps) => {
  return (
    <PreviewSheetContent.detailCard>
      <PreviewSheetContent.infoPairRow
        left={"Agreed on"}
        right={
          agreement
            ? formatDateTime(
                agreement.createdAt,
                "dayMonthNumericYearHourMinute",
              )
            : "-"
        }
      />
      <PreviewSheetContent.infoPairRow
        left={"Agreed by"}
        right={agreement ? agreement.signatoryName : "-"}
      />
    </PreviewSheetContent.detailCard>
  );
};

interface WaiverAgreementDetailsProps {
  waiver: WaiverEntityWithAgreement;
}

export const WaiverAgreementDetails = ({
  waiver,
}: WaiverAgreementDetailsProps) => {
  return (
    <PreviewSheetContent>
      <PreviewSheetContent.label
        content={`Last updated: ${waiver.lastUpdatedDate()}`}
      />
      <div className="flex justify-start">
        <WaiverAgreementChip waiver={waiver} />
      </div>
      <PreviewSheetContent.label content={waiver.props.name} />
      <AgreementDetails agreement={waiver?.agreement} />
      <div data-testid="waiver-content" className="text-body-400">
        {waiver.props.content}
      </div>
    </PreviewSheetContent>
  );
};
