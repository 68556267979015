import { PropsWithChildren, ReactNode } from "react";

import { cn, colors } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { CardContainerContext } from "@/modules/common/cardContainer/cardContainerContext";
import { useCardContainer } from "@/modules/common/cardContainer/cardContainerHooks";
import {
  CardContainerVariant,
  PrimaryActionItemProps,
} from "@/modules/common/cardContainer/cardContainerTypes";
import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";

export interface CardContainerProps extends PropsWithChildren {
  variant: CardContainerVariant;
  isEmpty?: boolean;
  primaryAction?: PrimaryActionItemProps;
  menuItems?: ActionMenuItemProps[];
}

export const CardContainer = ({
  children,
  variant,
  isEmpty = false,
  primaryAction,
  menuItems = [],
}: CardContainerProps) => {
  return (
    <CardContainerContext.Provider
      value={{ isEmpty, variant, primaryAction, menuItems }}>
      {children}
    </CardContainerContext.Provider>
  );
};

export const CardContainerPlaceholder = ({
  children,
  title,
}: {
  children?: ReactNode;
  title?: string;
}): ReactNode | null => {
  const { isEmpty } = useCardContainer();

  if (!isEmpty) {
    return null;
  }

  return children ? (
    children
  ) : (
    <div className={"flex flex-col items-center justify-center gap-y-8"}>
      <div className={"flex flex-col items-center gap-y-3"}>
        <div
          className={
            "mb-3 flex h-10 w-10 items-center justify-center rounded-full bg-brand-100"
          }>
          <Icon
            size={24}
            name={"helpCircleOutline"}
            color={colors.brand["800"]}
          />
        </div>
        {title && (
          <h6 className={"text-heading6-600 text-grey-900"}>{title}</h6>
        )}
      </div>
      <CardContainerPrimaryAction />
    </div>
  );
};

export const CardContainerPrimaryAction = (): ReactNode | null => {
  const breakpoints = useBreakpoint();
  const { variant, primaryAction, menuItems } = useCardContainer();

  if (!primaryAction) {
    return null;
  }

  const hasMenuItems = menuItems.length > 0;

  if (!breakpoints.md) {
    return (
      <IconButton
        icon={primaryAction.icon}
        border={"rounded"}
        onClick={() => primaryAction.onClick()}
        variant={"secondary-fill"}
      />
    );
  }

  if (variant === "page") {
    return (
      <Button
        data-testid="card-container-page-primary-action"
        variant={"brand"}
        size={primaryAction.size}
        text={primaryAction.title}
        onClick={() => primaryAction.onClick()}
      />
    );
  }

  if (variant === "section") {
    return (
      <Button
        data-testid="card-container-section-primary-action"
        className={cn(!hasMenuItems && "pr-0")}
        size={"sm"}
        text={primaryAction.title}
        variant={"tertiary"}
        onClick={() => primaryAction.onClick()}
      />
    );
  }

  return null;
};

export const CardContainerMenu = (): ReactNode | null => {
  const { menuItems } = useCardContainer();

  return menuItems.length > 0 ? (
    <ActionMenu
      trigger={
        <IconButton
          data-testid="card-container-action-menu-trigger"
          size={"lg"}
          icon={"ellipsisHorizontal"}
          variant={"standard"}
          aria-label="Open action menu"
        />
      }
      items={menuItems}
    />
  ) : null;
};

export const CardContainerActions = (): ReactNode | null => {
  const { isEmpty } = useCardContainer();

  return isEmpty ? null : (
    <div className="flex flex-row gap-x-2">
      <CardContainerPrimaryAction />
      <CardContainerMenu />
    </div>
  );
};

interface CardContainerContentProps extends PropsWithChildren {
  className?: string;
  maxColumns?: 2 | 3;
}

export const CardContainerContent = ({
  children,
  maxColumns = 3,
}: CardContainerContentProps): ReactNode | null => {
  const { isEmpty } = useCardContainer();

  return isEmpty ? null : (
    <div
      data-testid="card-display-content"
      className={`${maxColumns === 3 ? "md:grid-cols-2 lg:grid-cols-3" : "md:grid-cols-2"} grid grid-cols-1 gap-8 gap-y-5`}>
      {children}
    </div>
  );
};
