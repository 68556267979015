import { BrandColorsDto } from "@justraviga/classmanager-sdk";

import { getAccessibleHexColor } from "./colorUtils";

export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  blue: {
    100: "#F0F9FE",
    200: "#DDEFFD",
    300: "#C3E1F9",
    400: "#9ECFF5",
    500: "#66ADEB",
    600: "#2988E0",
    700: "#1A6BB7",
    800: "#04478A",
    900: "#00254C",
  },
  yellow: {
    100: "#FFFBEB",
    200: "#FDF5CB",
    300: "#F7EFA8",
    400: "#F2E76E",
    500: "#F3D749",
    600: "#E6BF25",
    700: "#A4690A",
    800: "#5F3300",
    900: "#5F3300",
  },
  red: {
    100: "#FFF0F5",
    200: "#FEDDE8",
    300: "#FDC8D1",
    400: "#FCA1B2",
    500: "#F95377",
    600: "#D80B49",
    700: "#BC0A3D",
    800: "#6F0B23",
    900: "#47000F",
  },
  green: {
    100: "#ECFDF9",
    200: "#CFF9EE",
    300: "#B3F7E8",
    400: "#86E9D5",
    500: "#29D1AF",
    600: "#119C7C",
    700: "#116F5E",
    800: "#065346",
    900: "#032A24",
  },
  grey: {
    100: "#F9F9FB",
    200: "#EFF1F8",
    300: "#E2E4EE",
    400: "#D4D7E3",
    500: "#A5A8BB",
    600: "#62657A",
    700: "#404256",
    800: "#212330",
    900: "#0D0D12",
  },
  purple: {
    100: "#F8F5FF",
    200: "#F1EBFE",
    300: "#E4D8FE",
    400: "#C5AFFD",
    500: "#856ADC",
    600: "#734BD2",
    700: "#3E1993",
    800: "#270354",
    900: "#1A0F39",
  },
  brand: {
    /* Remember to keep these in sync with those in globals.css */
    contrast: "#0D0D12", // grey-900
    DEFAULT: "#E2E4EE",
    100: "#F9F9FB",
    200: "#EFF1F8",
    300: "#E2E4EE",
    400: "#D4D7E3",
    500: "#A5A8BB",
    600: "#62657A",
    700: "#404256",
    800: "#212330",
    900: "#0D0D12",
  },
};

const defaultBrandColors = { ...colors.brand };

const variants = [
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
] as const;
const keys = ["contrast", "DEFAULT", ...variants] as const;

const hexToRgb = (hex: string) => {
  const color = hex.replace(/#/g, "");
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return `${r} ${g} ${b}`;
};

const setCssBrandVariable = (key: string, hexValue: string) => {
  /**
   * Todo: this shouldn't be in SHARED
   */
  if (typeof document === "undefined") {
    return;
  }

  document.documentElement.style.setProperty(
    `--brand-${key}`,
    hexToRgb(hexValue),
  );
};

const setBrandColor = (key: (typeof keys)[number], hexValue: string) => {
  colors.brand[key] = hexValue;
  setCssBrandVariable(key, hexValue);
};

export const resetBrandColors = () => {
  keys.forEach(key => {
    setBrandColor(key, defaultBrandColors[key]);
  });
};

export const setBrandColors = (brandColor: string, palette: BrandColorsDto) => {
  variants.forEach(key => {
    const hexValue = palette[`_${key}`];
    setBrandColor(key, hexValue);
  });
  setBrandColor("DEFAULT", brandColor);
  setBrandColor("contrast", getAccessibleHexColor(brandColor));
};
