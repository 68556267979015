import { AgreementDto, WaiverDto } from "@justraviga/classmanager-sdk";

import { formatDateTime } from "../../intlFormatter";
import { DefinedEntity } from "../entityTypes";

export type WaiverAgreementMap = { [key: string]: AgreementDto };

export interface WaiverEntityWithAgreement extends WaiverEntity {
  agreement?: AgreementDto;
}
export interface WaiverEntity extends DefinedEntity<WaiverDto> {
  lastUpdatedDate: () => string;
  requiresUpdate: () => boolean;
  isLocked: () => boolean;
}

export function waiverEntityFactory(waiverDto: WaiverDto): WaiverEntity {
  return {
    type: "defined",
    props: { ...waiverDto },
    isArchived: () => {
      return Boolean(waiverDto.archivedAt);
    },
    isArchivable: () => {
      return Boolean(!waiverDto.isLocked && !waiverDto.archivedAt);
    },
    isDeletable: () => {
      return Boolean(!waiverDto.isLocked);
    },
    isEditable: () => {
      return true;
    },
    isRestorable: () => {
      return Boolean(waiverDto.archivedAt);
    },
    isLocked: () => Boolean(waiverDto.isLocked),
    requiresUpdate: () =>
      Boolean(
        waiverDto.isLocked &&
          (!waiverDto.updatedAt ||
            waiverDto.updatedAt.getTime() === waiverDto.createdAt.getTime()),
      ),
    lastUpdatedDate: () => {
      return formatDateTime(
        new Date(waiverDto.updatedAt ?? waiverDto.createdAt),
        "dayMonthYear",
      );
    },
    isDefined: () => true,
  };
}
