import { apiQueryFactory, suspenseQueryFactory } from "shared/components";
import { flattenApi, makeApi } from "shared/lib";

import { authState } from "@/modules/auth/authState";
import { isInCatalogue } from "@/modules/auth/utils/isInCatalogue";
import { Router } from "@/routing/router";

function getSlugFromCatalogueUrl(url: string) {
  const [, slug] = url.split("/");
  return slug;
}

/**
 * The main API object that we'll use to make requests to the API.
 * e.g. `api.auth.login()`
 */
export const api = makeApi({
  authState,
  basePath: import.meta.env.VITE_API_BASE_URL,
  onLogout: () => {
    if (isInCatalogue()) {
      const slug = getSlugFromCatalogueUrl(window.location.pathname);
      Router.push("CatalogueLogin", { slug });
    } else {
      Router.push("Login");
    }
  },
});

export const useApi = apiQueryFactory(flattenApi(api));

/**
 * This is the React Suspense-friendly version of `useApi`.
 */
export const useData = suspenseQueryFactory(flattenApi(api));
