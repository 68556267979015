import React from "react";

import { cn } from "shared/lib";

import { NavLink } from "@/modules/common/nav/Nav";
import { NavItemNoSubMenu } from "@/modules/common/nav/NavItemNoSubMenu";
import { NavItemWithSubMenu } from "@/modules/common/nav/NavItemWithSubMenu";

type NavItemListProps = {
  isExpanded: boolean;
  items: NavLink[];
  showSubMenu: (item: NavLink) => void;
  selectedMenuPath: string | undefined;
  onNavigate: () => void;
};

export const NavItemList = React.memo(
  ({
    isExpanded,
    items,
    showSubMenu,
    selectedMenuPath,
    onNavigate,
  }: NavItemListProps) => (
    <ul
      className={cn(
        "flex flex-col gap-y-2",
        isExpanded ? "w-full px-4" : "items-center px-2",
      )}>
      {items.map((item, index) =>
        item.subItems?.length ? (
          <NavItemWithSubMenu
            item={item}
            onClick={showSubMenu}
            isSelected={selectedMenuPath === item.path}
            isExpanded={isExpanded}
            key={`nav-item-${index}`}
          />
        ) : (
          <NavItemNoSubMenu
            path={item.path}
            onNavigate={onNavigate}
            label={item.label}
            icon={item.icon}
            isSelected={selectedMenuPath === item.path}
            isExpanded={isExpanded}
            key={`nav-item-${index}`}
          />
        ),
      )}
    </ul>
  ),
);
