import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  DecimalInput,
  DecimalInputProps,
} from "@/modules/common/form/DecimalInput";

const onChange = () => {};

const options: Array<Option<DecimalInputProps>> = [
  {
    title: "Basic functionality",
    props: [
      { label: "Price", onChange, decimalPlaces: 2, prefix: "£" },
      {
        label: "Amount with 3 decimal places",
        value: 4,
        decimalPlaces: 3,
        onChange,
      },
    ],
  },
];

export const DecimalInputPage = () => {
  return (
    <DocWrapper
      title={"Decimal Input"}
      component={DecimalInput}
      options={options}
    />
  );
};
