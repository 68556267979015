import { ReactNode } from "react";

import { WaiverEntityWithAgreement } from "../../../../../entities";
import { formatDateTime } from "../../../../../intlFormatter";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { BaseCardComponent } from "../../../../interfaces";

interface SignWaiversContentProps {
  companyName: string;
  pendingWaivers: WaiverEntityWithAgreement[];
  form: ReactNode;
  BaseCard: BaseCardComponent;
}

export const SignWaiversContent = ({
  companyName,
  form,
  pendingWaivers,
  BaseCard,
}: SignWaiversContentProps) => {
  const { View, Text } = useGenericComponents();

  return (
    <View className="flex flex-col space-y-6">
      <Text className="text-body-400 text-grey-600">
        Please read and confirm you agree to the policies at {companyName}{" "}
        before continuing to the family portal.
      </Text>
      {pendingWaivers.map(waiverAgreement => (
        <View key={waiverAgreement.props.id}>
          <BaseCard
            startOpen={false}
            collapsible
            title={waiverAgreement.props.name}
            titleSize={16}
            description={waiverAgreement.props.content}
            descriptionColor={"grey-900"}
            descriptionSize={16}
            footerSlot={
              <Text className={"pt-2 text-grey-600"}>
                Last updated{" "}
                {formatDateTime(
                  waiverAgreement.props.updatedAt,
                  "dayMonthYear",
                )}
              </Text>
            }
          />
        </View>
      ))}
      <View className="flex flex-col space-y-4">
        <Text className="text-body-600 text-grey-900">Confirmation</Text>
        <Text className="text-body-400 text-grey-600">
          By entering your full legal name, you acknowledge that you have read
          and agree with our waivers and policies.
        </Text>
        <View>{form}</View>
      </View>
    </View>
  );
};
