import { makeApi } from "./apis";

/**
 * This is basically about constructing a platform-independent interface for the
 * API client, including only the important methods from each API class
 */
export type Api = ReturnType<typeof makeApi>;

// Helper type to transform a union of types to an intersection
// Credit to the TypeScript community for this utility type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

// Dynamically generate the ApiFlattened type
type ApiKeys = keyof Api;
export type ApiFlattened = UnionToIntersection<Api[ApiKeys]>;

const getApiMethods = (obj: object) =>
  Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).filter(
    method => method !== "constructor",
  );

// Function to flatten the api object
export const flattenApi = (api: Api): ApiFlattened => {
  const flattened = {} as ApiFlattened;
  for (const key in api) {
    const obj = api[key as keyof Api];
    const methods = getApiMethods(obj);
    for (const methodName of methods) {
      // @ts-expect-error The typing here is a bit tricky, so we've got unit tests instead
      flattened[methodName] = obj[methodName].bind(obj);
    }
  }
  return flattened;
};
