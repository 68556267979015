import {
  CompanyRegistrationDto,
  RegisterCompanyRequest,
} from "@justraviga/classmanager-sdk";

import {
  useAuthState,
  useFormActions,
  useSelectAccountCallback,
} from "shared/components";
import { CountryCodeParam } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { hubspotEnabled } from "@/lib/hubspot/hubspotEnabled";
import { submitToHubSpot } from "@/modules/auth/companyRegistration/hubspotIntegration";
import {
  makeRegistrationRequest,
  useRegistrationForm,
  useRegistrationFormWithAuth,
} from "@/modules/auth/companyRegistration/registrationFormDefinition";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { Button } from "@/modules/common/ui/button/Button";
import { Router } from "@/routing/router";

interface RegistrationFormProps {
  defaultValues: Partial<RegisterCompanyRequest>;
  countryCode: CountryCodeParam;
}

export const RegistrationForm = ({
  defaultValues,
  countryCode,
}: RegistrationFormProps) => {
  const { submit, isLoading } = useFormActions();
  const { setUser, user } = useAuthState();
  const onAccountChange = () => Router.push("CompanyHome");
  const selectAccount = useSelectAccountCallback(null, onAccountChange);

  const onSuccess = async (response: CompanyRegistrationDto) => {
    // Submit to HubSpot
    if (hubspotEnabled()) {
      try {
        await submitToHubSpot(response);
      } catch (error) {
        // Log error but continue with normal flow
        console.error("Failed to submit to HubSpot:", error);
      }
    }

    if (user === null) {
      setUser(response.user, response.token);
    }
    await selectAccount(response.account);
    Router.push("CompanyHome");
  };

  return (
    <>
      <div>
        <GenericForm
          apiRequest={makeRegistrationRequest({ api, countryCode })}
          defaultValues={defaultValues}
          formDefinitionHook={
            user === null ? useRegistrationForm : useRegistrationFormWithAuth
          }
          onSuccess={onSuccess}
        />
      </div>
      <Button
        onClick={submit}
        loading={isLoading}
        variant={"classmanager"}
        text="Register"
        isFullWidth={true}
      />
    </>
  );
};
