export const TileCellPlaceholder = () => {
  return (
    <div className={"flex items-center"}>
      <div className={"mr-2"}>
        <div className={"h-10 w-10 rounded-full bg-grey-300"} />
      </div>
      <div>
        <div className="mb-2 h-3 w-32 rounded-sm bg-grey-300"></div>
        <div className="h-3 w-24 rounded-sm bg-grey-300"></div>
      </div>
    </div>
  );
};
