import { StudentDto } from "@justraviga/classmanager-sdk";

import { getFullName } from "shared/lib";

import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const StudentActionMenu = ({ student }: { student: StudentDto }) => {
  const { showPortalUpdateForm: showUpdateForm } = useStudentActions();

  return (
    <section>
      <ActionMenu
        showActionsAsBottomSheetOnMobile={true}
        trigger={
          <IconButton
            variant="standard"
            icon={"ellipsisHorizontal"}
            size="lg"
            aria-label="Toggle menu"
          />
        }
        header={<h3 className={"text-heading6-600"}>{getFullName(student)}</h3>}
        items={[
          {
            title: "Edit",
            leftIcon: "createOutline",
            onClick: () => showUpdateForm(student),
          },
        ]}
      />
    </section>
  );
};
