import { Api, withRetries } from "../api";

export interface SearchKeyLoader {
  load: () => Promise<string>;
}
export interface SearchKeyLoaderParams {
  api: Api;
  retries?: number;
}

export function searchKeyLoader({ api, retries = 3 }: SearchKeyLoaderParams) {
  const getSearchKey = () => {
    return api.search.generateAccountSearchKey().then(({ apiKey }) => apiKey);
  };

  const loadSearchKey = withRetries(getSearchKey, { retries });

  return {
    load: () => loadSearchKey(),
  };
}
