import { useEffect } from "react";

import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import {
  creditableDetailTypes,
  getCreditAmounts,
  getTransactionDiscountAmounts,
} from "../../transactionUtils";
import { useFormActions } from "../FormActionsProvider";
import {
  makeCreateCreditNoteRequest,
  makeCreditNoteForm,
} from "../formDefinitions/creditNoteForm";
import { useGenericComponents } from "../GenericComponentsProvider";

export const CreditNoteForm = ({
  onSuccess,
  transaction,
}: {
  onSuccess: (transaction: AggregateTransactionDto) => void;
  transaction: AggregateTransactionDto;
}) => {
  const { GenericForm, View, Text, Loader } = useGenericComponents();
  const { api, useApi } = getPlatformFunctions();

  const { setCreateButtonText, setAllowCreateAdditional, setSubmitDisabled } =
    useFormActions();

  useEffect(() => {
    // Tweak the form footer to match designs
    setAllowCreateAdditional(false);
    setCreateButtonText("Continue");
  }, [setAllowCreateAdditional, setCreateButtonText]);

  // Find out whether there have been any credits given to any of the detail lines already
  const { data: relatedTransactionResponse } = useApi("listTransaction", {
    where: { relatedTransactionId: { equals: transaction.transaction.id } },
    selectAll: true,
  });

  // Apply any discounts to our lines
  const discounts = getTransactionDiscountAmounts(transaction.details);

  const relatedTransactionDetailLines = (
    relatedTransactionResponse?.data ?? []
  ).flatMap(transaction => transaction.details);
  const credits = getCreditAmounts(relatedTransactionDetailLines);

  const creditableLines = transaction.details
    .filter(d => creditableDetailTypes.includes(d.details.type))
    .map(({ details }) => {
      const discountedAmount = details.amount + (discounts[details.id] ?? 0);
      const alreadyCreditedAmount = credits[details.id] ?? 0;
      return {
        id: details.id,
        description: details.description,
        discountedAmount,
        availableToCredit: Math.max(
          0,
          discountedAmount - alreadyCreditedAmount,
        ),
      };
    });

  const hasAmountToRefund = creditableLines.some(
    line => line.availableToCredit > 0,
  );

  useEffect(() => {
    // Disable the submit button if there's no amount to refund
    setSubmitDisabled(!hasAmountToRefund);
  }, [hasAmountToRefund, setSubmitDisabled]);

  if (!relatedTransactionResponse) {
    return <Loader />;
  }

  return (
    <View className="flex flex-col space-y-2">
      <View>
        <Text className="text-heading6-600 text-grey-900">
          Credit note details
        </Text>
      </View>

      <View className="pb-5">
        <Text className="text-body-400 text-grey-600">
          A credit note will reverse a charge within Class Manager.
        </Text>
      </View>

      <GenericForm
        apiRequest={makeCreateCreditNoteRequest(
          api,
          transaction.transaction.id,
        )}
        defaultValues={{}}
        formDefinitionHook={makeCreditNoteForm(creditableLines)}
        onSuccess={onSuccess}
      />
    </View>
  );
};
