import { FC, ReactElement } from "react";

import { SeasonDto } from "@justraviga/classmanager-sdk";

import { pricingSchemeTypeIcon } from "../../../pricingSchemeUtils";
import { enumLabel } from "../../../translateUtils";

export const SeasonPricingSchemeCard = <T,>({
  season,
  BaseCard,
  FooterSlot,
  shouldShowFooter,
  props,
}: {
  season: SeasonDto;
  BaseCard: FC;
  FooterSlot: ReactElement;
  shouldShowFooter: boolean;
  props?: T;
}) => {
  const defaultProps = {
    title: "Tuition & pricing",
    description: enumLabel("pricingScheme", season.pricingScheme),
    icon: pricingSchemeTypeIcon[season.pricingScheme],
    iconPosition: "left-framed",
    descriptionColor: "grey-900",
    descriptionWeight: 600,
    descriptionSize: 16,
    footerSlot: shouldShowFooter ? FooterSlot : null,
  } as T;

  return <BaseCard {...defaultProps} {...props} />;
};
