import {
  CreatePricingSchemeValueRequest,
  UpdatePricingSchemeValueRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { range } from "../../arrayUtils";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { moneyFloatToInteger } from "../../moneyUtils";
import { useCurrencySymbol } from "../useCurrencySymbol";

type UpdatePricingSchema = UpdatePricingSchemeValueRequest;
type CreatePricingSchema = CreatePricingSchemeValueRequest;
type ClassCountSchema = UpdatePricingSchema | CreatePricingSchema;

interface UpdateDurationPricingSchema {
  hours: number;
  minutes: number;
  price: number;
}

interface CreateDurationPricingSchema {
  seasonId: string;
  hours: number;
  minutes: number;
  price: number;
}

type DurationSchema = UpdateDurationPricingSchema | CreateDurationPricingSchema;

export const useClassPricingTierDefinition = <T extends ClassCountSchema>() => {
  const currencySymbol = useCurrencySymbol();
  return new FormDefinitionBuilder<T>()
    .decimal("price", {
      label: "Price",
      required: true,
      prefix: currencySymbol,
      decimalPlaces: 2,
      description:
        "The class fee will be pro-rated depending on the number of months remaining in the season.",
    })
    .group("Price per month", ["price"])
    .getDefinition();
};

export const makeUpdateClassPricingTierRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdatePricingSchema) => {
    // convert to integer price amount
    const price = moneyFloatToInteger(formData.price);

    return api.pricingSchemeValues.updatePricingSchemeValue({
      id,
      updatePricingSchemeValueRequest: { ...formData, price },
    });
  };

export const makeCreateClassPricingTierRequest = ({
  api,
  id,
  quantity,
}: {
  api: Api;
  id: string;
  quantity: number;
}) => {
  // Keep a local copy, because subsequent requests ("Create and add another") need to increment the quantity
  let qty = quantity - 1;
  return (formData: CreatePricingSchema) => {
    qty++;
    // convert to integer price amount
    const price = moneyFloatToInteger(formData.price);

    return api.pricingSchemeValues.createPricingSchemeValue({
      createPricingSchemeValueRequest: {
        ...formData,
        seasonId: id,
        quantity: qty,
        price,
      },
    });
  };
};

export const useDurationPricingTierDefinition = <
  T extends DurationSchema,
>() => {
  const currencySymbol = useCurrencySymbol();
  return new FormDefinitionBuilder<T>()
    .select("hours", {
      label: "Hours",
      required: true,
      // need to start at 0 and have plurals not on 1
      data: range(49)
        .map(i => i - 1)
        .map(i => ({
          label: i.toString() + (i == 1 ? " hr" : " hrs"),
          value: i,
        })),
    })
    .select("minutes", {
      label: "Minutes",
      required: true,
      data: [0, 15, 30, 45].map(i => ({
        label: i.toString() + " mins",
        value: i,
      })),
    })
    .decimal("price", {
      label: "Price per month",
      required: true,
      prefix: currencySymbol,
      decimalPlaces: 2,
      description:
        "The class fee will be pro-rated depending on the number of months remaining in the season.",
    })
    .row(["hours", "minutes"])
    .group("Hours per student / week", ["hours", "minutes"])
    .group("Price", ["price"])
    .getDefinition();
};

interface UpdateDurationPricingSchemaForm {
  hours: number;
  minutes: number;
  price: number;
}
export const makeUpdateDurationPricingTierRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateDurationPricingSchemaForm) => {
    // convert to integer price amount
    const price = moneyFloatToInteger(formData.price);
    const quantity = formData.hours * 60 + formData.minutes;

    return api.pricingSchemeValues
      .updatePricingSchemeValue({
        id,
        updatePricingSchemeValueRequest: { price, quantity },
      })
      .catch(err => {
        // the GenericForm we are creating has different fields to the API validation rules so we're just catching
        // quantity and re-throwing as hours!
        const quantityErrors = err.validationErrors.quantity;
        const hourErrors = { hours: quantityErrors };
        throw {
          ...err,
          validationErrors: {
            ...err.validationErrors,
            ...hourErrors,
          },
        };
      });
  };

export const makeCreateDurationPricingTierRequest = ({
  api,
  id,
}: {
  api: Api;
  id: string;
}) => {
  return (formData: CreateDurationPricingSchema) => {
    // convert to integer price amount
    const price = moneyFloatToInteger(formData.price);
    const quantity = formData.hours * 60 + formData.minutes;

    return api.pricingSchemeValues
      .createPricingSchemeValue({
        createPricingSchemeValueRequest: {
          seasonId: id,
          quantity,
          price,
        },
      })
      .catch(err => {
        // the GenericForm we are creating has different fields to the API validation rules so we're just catching
        // quantity and re-throwing as hours!
        const quantityErrors = err.validationErrors.quantity;
        const hourErrors = { hours: quantityErrors };
        throw {
          ...err,
          validationErrors: {
            ...err.validationErrors,
            ...hourErrors,
          },
        };
      });
  };
};
