import {
  InvitationStatus,
  ListFamilyWhereParameter,
} from "@justraviga/classmanager-sdk";

import { FilterFormDefinitionBuilder } from "shared/lib";
import { DatatableFilterOperator } from "shared/lib";
import { enumLabel } from "shared/lib";

export interface FamilyFilterFormSchema {
  invitationStatus: InvitationStatus[];
  joinedAfter: string;
  joinedBefore: string;
}
export const useFamilyFilterForm = () =>
  new FilterFormDefinitionBuilder<
    FamilyFilterFormSchema,
    ListFamilyWhereParameter
  >()
    .multiSelect(
      "invitationStatus",
      {
        label: "Account Access",
        data: Object.entries(InvitationStatus).map(([, value]) => ({
          label: enumLabel("invitationStatus", value),
          value,
        })),
      },
      {
        filterField: "invitationStatus",
        operator: DatatableFilterOperator.In,
      },
    )
    .date(
      "joinedAfter",
      { label: "Joined After" },
      {
        filterField: "joinedAt",
        operator: DatatableFilterOperator.GreaterThanOrEqual,
      },
    )
    .date(
      "joinedBefore",
      { label: "Joined Before" },
      {
        filterField: "joinedAt",
        operator: DatatableFilterOperator.LessThanOrEqual,
      },
    )
    .withArchiveFilter()
    .getDefinition();
