import { CompanyLogoPlaceholder } from "shared/components";
import { CompanyLogoProps, logoSizeMap } from "shared/lib";

export const CompanyLogo = ({ logo, size = "sm" }: CompanyLogoProps) => (
  <div
    className={`flex shrink-0 items-center justify-center overflow-hidden rounded border border-grey-300 ${logoSizeMap[size]}`}
    style={
      logo
        ? {
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }
        : {}
    }>
    <div
      className={
        "flex h-full w-full items-center justify-center backdrop-blur-sm"
      }>
      {logo ? (
        <img
          className={"max-h-full max-w-full object-contain"}
          src={logo}
          alt="company logo"
        />
      ) : (
        <CompanyLogoPlaceholder />
      )}
    </div>
  </div>
);
