export interface Breadcrumb {
  text: string;
  onPress?: () => void;
}

export interface BreadcrumbsProps {
  items: Array<Breadcrumb>;
}

const LinkableCrumb = ({ text, onPress }: Breadcrumb) => (
  <button
    className="text-label-600 text-grey-900 underline underline-offset-4 hover:text-grey-600"
    onClick={onPress}>
    {text}
  </button>
);

const NonLinkableCrumb = ({ text }: { text: string }) => (
  <span className="text-label-400 text-grey-600">{text}</span>
);

const Divider = () => (
  <span className="mx-3 text-label-600 text-grey-400">/</span>
);

// A React component that displays the breadcrumbs for the current page.
// The individual breadcrumbs will be supplied as an array of objects.
export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <div className="flex">
      {items.map((item, index) => (
        <span key={index}>
          {item.onPress ? (
            <LinkableCrumb {...item} />
          ) : (
            <NonLinkableCrumb {...item} />
          )}
          {index < items.length - 1 && <Divider />}
        </span>
      ))}
    </div>
  );
};
