import {
  PricingScheme,
  PricingSchemeValueDto,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import {
  formatMoneyFromInteger,
  minutesToHumanReadable,
  SeasonPricingListCardTableProps,
} from "shared/lib";

import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { SeasonPricingListCardWarning } from "@/modules/company/classPlanner/seasons/card/seasonPricing/SeasonPricingListCardWarning";
import { Router } from "@/routing/router";

export const SeasonPricingListCard = ({
  season,
  values,
}: {
  season: SeasonDto;
  values: PricingSchemeValueDto[];
}) => {
  return (
    <BaseCard
      title={"Pricing list"}
      headerMenu={
        <div className={"mt-1 flex"}>
          <Button
            onClick={() => {
              Router.push("PricingTiers", {
                seasonId: season.id,
              });
            }}
            variant={"subtle"}
            size={"xs"}
            text={"View"}
          />
        </div>
      }
      bodySlot={
        values.length > 0 ? (
          <SeasonPricingListCardTable
            values={values}
            scheme={season.pricingScheme}
          />
        ) : (
          <div className={"-mx-2 -mb-2 pt-2"}>
            <SeasonPricingListCardWarning />
          </div>
        )
      }
    />
  );
};

export const SeasonPricingListCardTable = ({
  values,
  scheme,
}: SeasonPricingListCardTableProps) => {
  const getSchemeText = (scheme: PricingScheme) => {
    switch (scheme) {
      case PricingScheme.ClassCount:
        return "Classes / week";
      case PricingScheme.TotalDuration:
        return "Hours / week";
    }
  };
  const formatValue = (value: number) => {
    switch (scheme) {
      case PricingScheme.ClassCount:
        return value;
      case PricingScheme.TotalDuration:
        return minutesToHumanReadable(value);
    }
  };
  return (
    <div className={"w-full"}>
      <table className={"w-full"}>
        <thead>
          <tr>
            <th
              className={"py-2 text-left text-body-400 text-sm text-grey-600"}>
              {getSchemeText(scheme)}
            </th>
            <th
              className={"py-2 text-right text-body-400 text-sm text-grey-600"}>
              Price / month
            </th>
          </tr>
        </thead>
        <tbody>
          {values.map((value, index) => (
            <tr key={value.id}>
              <td className={"py-2 text-body-400 text-sm text-grey-600"}>
                {formatValue(value.quantity)}{" "}
                {index == values.length - 1 ? "or more" : ""}
              </td>
              <td
                className={
                  "py-2 text-right text-body-400 text-sm text-grey-900"
                }>
                {formatMoneyFromInteger(value.price)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
