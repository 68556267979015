import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { MobileActionMenuDefaultItems } from "@/modules/common/ui/actionMenu/mobile/MobileActionMenuDefaultItems";
import { MobileActionMenuDestructiveItems } from "@/modules/common/ui/actionMenu/mobile/MobileActionMenuDestructiveItems";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const MobileActionMenuItems = ({
  items,
  onActionClick,
}: {
  items: ActionMenuItemProps[];
  onActionClick: () => void;
}) => {
  const defaultItems = items.filter(i => !i.destructive);
  const destructiveItems = items.filter(i => i.destructive);

  return (
    <>
      <MobileActionMenuDefaultItems
        items={defaultItems}
        onActionClick={onActionClick}
      />

      {defaultItems.length > 0 && destructiveItems.length > 0 && (
        <HorizontalSeparator spacing={3} />
      )}

      {destructiveItems.length > 0 && (
        <MobileActionMenuDestructiveItems items={destructiveItems} />
      )}
    </>
  );
};
