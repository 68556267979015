import { useState } from "react";

import { cn, colorPickerColors, colors } from "shared/lib";

import {
  BaseInput,
  BaseInputProps,
  BaseInputValue,
} from "@/modules/common/form/BaseInput";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@/modules/common/overlays/Popover";

const hexValues = Object.values(colorPickerColors);

export interface ColorPickerProps extends BaseInputProps {}

export type ColorPickerValue = string | null;

const toInternalValue = (
  value: string | null | undefined,
): ColorPickerValue => {
  if (typeof value === "string") {
    return (
      `#` +
      value
        .replace(/[^0-9a-fA-F]/g, "")
        .toLowerCase()
        .slice(0, 6)
    );
  }
  return value ?? null;
};

const toBaseInputValue = (value: ColorPickerValue): BaseInputValue => {
  if (typeof value === "string") {
    return value.replace("#", "");
  }
  return value;
};

export const ColorPicker = ({
  value,
  onBlur,
  onChange,
  ...props
}: ColorPickerProps) => {
  const [internalValue, setInternalValue] = useState<BaseInputValue>(
    value ?? null,
  );

  const ColorPickerInput = () => (
    <Popover>
      <PopoverTrigger asChild>
        <button
          data-testid="open-picker"
          disabled={props.disabled}
          className="-mx-2 flex h-10 w-10 items-center justify-center">
          <div
            className="h-4 w-4 rounded-full border border-transparent hover:border-grey-600 hover:shadow-inner"
            style={{
              backgroundColor: internalValue ?? colors.grey[200],
            }}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent align="start" alignOffset={-5} className="p-4">
        <div
          className="flex flex-row justify-between gap-2"
          data-testid="swatches">
          {hexValues.map((row, i) => (
            <div className="flex flex-col justify-between gap-2" key={i}>
              {row.map((color, j) => (
                <PopoverClose
                  onClick={() => {
                    setInternalValue(color);
                    if (onChange) {
                      onChange(color);
                    }
                  }}
                  key={j}
                  className={cn([
                    "h-4 w-4 rounded",
                    internalValue === color ? "border border-grey-900" : "",
                  ])}
                  style={{ backgroundColor: color }}></PopoverClose>
              ))}
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );

  return (
    <BaseInput
      {...props}
      prefix="#"
      prepend={<ColorPickerInput />}
      value={toBaseInputValue(internalValue)}
      onChange={v => {
        const newValue = toInternalValue(v);
        setInternalValue(newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={() => {
        // If value isn't valid, reset to null
        if (internalValue === null || internalValue.length !== 7) {
          setInternalValue(null);
          if (onChange) {
            onChange(null);
          }
        }
        if (onBlur) {
          onBlur();
        }
      }}
    />
  );
};
