import { PropsWithChildren } from "react";

import { useGenericComponents } from "../GenericComponentsProvider";

export const CustomTooltip = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className="min-w-[250px] space-y-2 rounded-md bg-white p-2 shadow-md">
      <Text className="text-label-600">{title}</Text>
      <View className="flex flex-col space-y-2">{children}</View>
    </View>
  );
};
