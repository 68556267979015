import { SortSchema } from "@justraviga/classmanager-sdk";

import { ContentPlaceholder } from "shared/components";

import { useApi } from "@/lib/api/apiClient";
import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { SeasonCard } from "@/modules/company/classPlanner/seasons/card/SeasonCard";
import { Router } from "@/routing/router";

export const ArchivedSeasonsPage = () => {
  const { data: seasons } = useApi("listSeason", {
    onlyArchived: true,
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });

  const isEmpty = !seasons?.data || seasons.data.length === 0;

  const crumbs = [
    {
      text: "Seasons",
      onPress: () => {
        Router.push("SeasonList");
      },
    },
    {
      text: "Archived seasons",
    },
  ];

  return (
    <CompanyCardPage
      title={"Archived seasons"}
      crumbs={crumbs}
      isEmpty={isEmpty}>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon={"archiveOutline"}
          title={"No archived seasons"}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {seasons?.data.map((season, key) => (
          <SeasonCard season={season} key={key} />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};
