import { useContext } from "react";

import { AuthRedirectContext } from "@/modules/auth/redirect/authRedirectContext";

export function useAuthRedirect() {
  const context = useContext(AuthRedirectContext);
  if (!context) {
    throw new Error(
      "useAuthRedirect must be used within a <AuthRedirectProvider />",
    );
  }
  return context;
}
