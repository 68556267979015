import {
  SortSchema,
  UserAccountDto,
  WaiverDto,
} from "@justraviga/classmanager-sdk";

import { mapDtoToEntity } from "../entities";
import { WaiverEntity, waiverEntityFactory } from "../entities";
import { buildWaiverToAgreementMap } from "../entities/waiver/buildWaiverToAgreementMap";
import { combineWaiversAndAgreements } from "../entities/waiver/combineWaiversAndAgreements";
import { sortWaiversList } from "../entities/waiver/sortWaiversList";
import { getPlatformFunctions } from "../platformSpecific";

export function useSharedFamilyWaiversListPage(account: UserAccountDto | null) {
  const { useApi } = getPlatformFunctions();

  const queryOptions = {
    enabled: !!(account && account.company && account.entityId),
  };

  const { data: agreements, isPending: areAgreementsLoading } = useApi(
    "listAgreement",
    {},
    queryOptions,
  );

  const agreementMap = buildWaiverToAgreementMap(agreements);

  const { data: waivers, isPending: areWaiversLoading } = useApi(
    "listWaiver",
    { sort: { createdAt: SortSchema.Asc } },
    queryOptions,
  );

  const isLoading = areAgreementsLoading || areWaiversLoading;

  const isEmpty = () => !waivers?.data || waivers.data.length === 0;

  const mappedWaivers = waivers
    ? combineWaiversAndAgreements(
        sortWaiversList(
          mapDtoToEntity<WaiverDto, WaiverEntity>(waivers, waiverEntityFactory),
        ),
        agreementMap,
      )
    : undefined;

  return {
    waivers: mappedWaivers,
    isLoading,
    isEmpty,
  };
}
