import { useEffect, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Bold,
  ClassicEditor,
  Essentials,
  FileRepository,
  Heading,
  ImageBlock,
  ImageInsert,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  List,
  Markdown,
  Paragraph,
  Undo,
} from "ckeditor5";

import { RichTextInputProps } from "shared/lib";

import { InputDescription } from "@/modules/common/form/InputDescription";
import { Label } from "@/modules/common/form/Label";
import { ImageUploadAdapterPlugin } from "@/modules/common/form/richText/ImageUploadAdaptor";

import "ckeditor5/ckeditor5.css";
import "./richTextEditorStyles.css";

const contentPadding = 40;
const lineHeight = 24;
const additionalMargin = 36;

export const RichTextInput = ({
  description,
  disabled,
  label,
  numberOfLines = 5,
  onBlur,
  onChange,
  required,
  value,
}: RichTextInputProps) => {
  // Keep a copy of value as internal state for those situations (like docs pages), where the inputs aren't controlled
  const [internalValue, setInternalValue] = useState<string>(value ?? "");
  const [editor, setEditor] = useState<ClassicEditor | null>(null);

  useEffect(() => {
    if (editor && value && value !== internalValue) {
      editor.setData(value);
      setInternalValue(value);
    }
  }, [editor, internalValue, value]);

  return (
    <Label text={label} required={required} useNativeLabel={false}>
      <CKEditor
        disabled={disabled}
        disableWatchdog={true}
        editor={ClassicEditor}
        onReady={editor => {
          setEditor(editor);
          editor.editing.view.change(writer => {
            writer.setStyle(
              {
                height: `${contentPadding + additionalMargin + lineHeight * numberOfLines}px`,
              },
              editor.editing.view.document.getRoot()!,
            );
          });
        }}
        config={{
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Body",
                class: "ck-heading_paragraph",
              },
              // It's necessary to specify these, as CKEditor starts at H2 by default (even though they call it H1)
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
            ],
          },
          toolbar: {
            shouldNotGroupWhenFull: true, // allow toolbar items to wrap on small screens
            items: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "link",
              "insertImage",
              "|",
              "bulletedList",
              "numberedList",
            ],
          },
          plugins: [
            Markdown,
            FileRepository,
            ImageUploadAdapterPlugin,
            Bold,
            Essentials,
            Italic,
            Paragraph,
            Undo,
            Link,
            LinkImage,
            List,
            Heading,
            ImageBlock,
            ImageInsert,
            ImageToolbar,
            ImageUpload,
          ],
          initialData: internalValue,
        }}
        onBlur={onBlur}
        onChange={(_, editor) => {
          const newValue = editor.getData();
          setInternalValue(newValue);
          if (onChange && newValue !== value) {
            onChange(newValue);
          }
        }}
        onError={e => {
          console.error("CKEditor error", e);
        }}
      />
      <InputDescription description={description} />
    </Label>
  );
};
