import { SelectItem, SelectProps } from "shared/lib";

import { BaseSelect } from "@/modules/common/form/select/BaseSelect";

export const Select = ({
  placeholder,
  disabled,
  onSelect,
  value,
  onBlur,
  ...props
}: SelectProps) => {
  const selectedLabel =
    props.data.find(item => item.value === value)?.label || "";
  const selectedValue = value === 0 || value ? value : "";

  const onClickRemoveAll = () => {
    onSelect && onSelect(null);
  };

  const placeholderValue =
    selectedValue !== "" ? (
      <span className="text-grey-900">{selectedLabel}</span>
    ) : (
      placeholder
    );

  return (
    <BaseSelect
      disabled={disabled}
      isSelected={(item: SelectItem) => item.value === selectedValue}
      onBlur={onBlur}
      onClickRemoveAll={onClickRemoveAll}
      onSelect={(item: SelectItem) => {
        if (disabled) {
          return;
        }

        if (item) {
          onSelect && onSelect(item.value);
        }
      }}
      placeholder={placeholderValue}
      {...{ value, ...props }}
    />
  );
};
