import { ReactNode } from "react";

import { AccountPermission, EntityName } from "@justraviga/classmanager-sdk";
import { SortingState } from "@tanstack/react-table";

import { IconName } from "../availableIcons";
import {
  ButtonVariant,
  ContentPlaceholderProps,
} from "../components/interfaces";
import { ProtectionChecker } from "../components/protector";
import { FilterFormDefinition } from "../forms/formBuilderTypes";

export interface Dto {
  id: string;
}

export enum DatatableFilterOperator {
  Equals = "equals",
  NotEquals = "notEquals",

  Contains = "contains",

  In = "in",
  NotIn = "notIn",

  GreaterThan = "greaterThan",
  LessThan = "lessThan",

  GreaterThanOrEqual = "greaterThanOrEqual",
  LessThanOrEqual = "lessThanOrEqual",

  isNull = "isNull",
  isNotNull = "isNotNull",

  Month = "month",
  Year = "year",
}

export interface DatatableRequest {
  search?: string;
  page?: number;
  pageSize?: number;
  selectAll?: boolean;
  where?: object;
  sort?: object;
  onlyArchived?: boolean;
}

export enum DatatableContentState {
  NoContent,
  NoResults,
  Loading,
  Error,
  Loaded,
}

export interface DatatableFilterManager {
  count: () => number;
  getAll: () => DatatableFilter[];
  add: (filter: DatatableFilter) => void;
  remove: (filter: DatatableFilter) => void;
  update: (filter: DatatableFilter) => void;
  replace: (filters: DatatableFilter[]) => void;
  clear: () => void;
  state: DatatableFilter[];
}

export type PossibleFilterValues =
  | string
  | number
  | Array<string>
  | Array<number>
  | boolean
  | null;

export interface DatatableFilter {
  field: string;
  operator: DatatableFilterOperator;
  value: PossibleFilterValues;
  formField?: string;
}

export interface DatatableModelSelection<ModelId> {
  getAll: () => ModelId[];
  count: () => number;
  isEmpty: () => boolean;
  isNotEmpty: () => boolean;
  has: (item: ModelId) => boolean;
  hasAny: (items: ModelId[]) => boolean;
  hasAll: (items: ModelId[]) => boolean;
  add: (item: ModelId) => void;
  addMany: (items: ModelId[]) => void;
  remove: (item: ModelId) => void;
  toggle: (item: ModelId) => void;
  toggleAll: (items: ModelId[]) => void;
  clear: () => void;
  state: string[];
}

export interface DatatableMenuAction<RowDto> {
  label: string | ((item: RowDto) => string);
  description?: string;
  hasDestructiveStyle?: boolean;
  isDestructive?: boolean;
  isUpdating?: boolean;
  onClick: (row: RowDto) => void;
  enabled?: (row: RowDto) => boolean;
  icon?: IconName;
  permission?: AccountPermission;
  checkProtection?: ProtectionChecker;
}

export interface DatatableMenuGroup<RowDto> {
  title: string;
  actions: Array<DatatableMenuAction<RowDto>>;
}

export type DatatableMenuItem<RowDto> =
  | DatatableMenuAction<RowDto>
  | DatatableMenuGroup<RowDto>;

export interface DatatableAdditionalAction {
  title: string;
  onClick?: () => void;
  icon?: IconName;
  protector?: ProtectionChecker;
  items?: DatatableAdditionalAction[];
}

export interface DatatableGroupActionProps {
  icon?: IconName;
  title: string;
  description?: string;
  destructive?: boolean;
  isDestructive?: boolean;
  leftIcon?: IconName;
  onClick: (rows: string[]) => Promise<void>;
  permission?: AccountPermission;
}

export interface CustomCellProps {
  type: "custom";
  children: ReactNode;
}

export interface TextCellProps {
  type: "text";
  text: string | number | undefined;
  color?: string;
}

export interface TileCellProps {
  type: "tile";
  title: string;
  subtitle?: string | null | undefined;
  image?: string;
  icon?: ReactNode;
  hasImage?: boolean;
  multilineSubtitle?: boolean;
}

export interface IconCellProps {
  type: "icon";
  title: string;
  subtitle?: string | null | undefined;
  icon: IconName;
  iconColor: string;
  iconBgColor: string;
}

export interface DatatableColumnProps<RowDto> {
  placeholder: "text" | "tile";
  label: string;
  width?: string;
  align?: "left" | "center" | "right";
  sortableField?: string;
  value: (
    row: DatatableRow<RowDto>,
  ) => TileCellProps | TextCellProps | CustomCellProps | IconCellProps;
}

export interface DatatableRow<RowDto> {
  item: RowDto;
  onClick?: (row: DatatableRow<RowDto>) => void;
}

export interface MobileDatatableColumnProps<RowDto> {
  hasImage?: boolean;
  image?: (row: DatatableRow<RowDto>) => string | null | undefined;
  title?: (row: DatatableRow<RowDto>) => string;
  subtitle?: (row: DatatableRow<RowDto>) => string | null | undefined;
  icon?: (row: DatatableRow<RowDto>) => ReactNode;
  isPendingDeleted?: (row: DatatableRow<RowDto>) => boolean;
  children?: (row: DatatableRow<RowDto>) => ReactNode;
  placeholder?: ReactNode | string;
}

export interface DatatablePaginatedData<ModelDto> {
  data: ModelDto[];
  pagination: DatatablePagination;
}

export interface DatatablePagination {
  total?: number;
  count?: number;
  perPage?: number;
  currentPage?: number;
  totalPages?: number;
}

export interface DatatableQueryProps {
  search: string;
  page: number;
  pageSize: number;
  selectAll: boolean;
  filters: DatatableFilter[];
  sort: SortingState;
  isEmpty: () => boolean;
  toRequest: () => DatatableRequest;
}

export interface DatatableConfiguration<
  ModelDto extends Dto,
  FilterFormSchema extends object = NonNullable<unknown>,
  RowDto extends ModelDto = ModelDto,
> {
  id: EntityName | string;
  title?: string | ReactNode;
  titleSize?: "sm" | "lg";
  isMobile?: boolean;
  createAction?: () => void;
  createLabel?: string;
  createVariant?: ButtonVariant;
  createPermission?: AccountPermission;
  additionalActions?: {
    title?: string;
    items: DatatableAdditionalAction[];
  };
  tableRowClass?: string;
  permissions?: {
    archive?: AccountPermission;
    create?: AccountPermission;
    delete?: AccountPermission;
    edit?: AccountPermission;
    restore?: AccountPermission;
  };
  rowActions?: {
    title?: (item: RowDto) => string;
    click?: (item: RowDto) => void;
    archive?: (item: RowDto) => Promise<ModelDto | void>;
    delete?: (item: RowDto) => Promise<void>;
    deleteEnabled?: (item: RowDto) => boolean;
    edit?: (item: RowDto) => void;
    restore?: (item: RowDto) => Promise<ModelDto>;
    additionalRowActions?: (item: RowDto) => DatatableMenuItem<RowDto>[];
  };
  groupActions?: {
    title?: string;
    archiveMany?: (ids: string[]) => Promise<void>;
    deleteMany?: (ids: string[]) => Promise<void>;
    additionalGroupActions?: DatatableGroupActionProps[];
  };
  columns?: Array<DatatableColumnProps<RowDto>>;
  mobileColumn: MobileDatatableColumnProps<RowDto>;
  /**
   * If true, the datatable will be scrollable on the mobile app.
   * We need this when parent component is required to be scrollable.
   * It prevents nested scroll view issues.
   */
  scrollEnabledOnMobile?: boolean;
  alwaysShowHeaderOnMobile?: boolean;
  fetchData: (
    query: DatatableQueryProps,
  ) => Promise<DatatablePaginatedData<RowDto>>;
  hasPagination?: boolean;
  hasSearch?: boolean;
  placeholdersCount?: number;
  filterForm?: FilterFormDefinition<
    FilterFormSchema & { isArchived?: boolean },
    object
  >;
  showTotalRecords?: boolean;
  customCellClass?: string;
  contentPlaceholders?: {
    noContent?: Omit<ContentPlaceholderProps, "action">;
    noResultsContent?: ContentPlaceholderProps;
    errorContent?: ContentPlaceholderProps;
  };
}
