import { PricingSchemeValueDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { SeasonPricingSchemeCard as SharedSeasonPricingSchemeCard } from "shared/components";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { SeasonPricingListCard } from "@/modules/company/classPlanner/seasons/card/seasonPricing/SeasonPricingListCard";
import { SeasonPricingListCardWarning } from "@/modules/company/classPlanner/seasons/card/seasonPricing/SeasonPricingListCardWarning";
import { useSeasonActions } from "@/modules/company/classPlanner/seasons/useSeasonActions";
import { Router } from "@/routing/router";

export const SeasonPricingSchemeCard = ({
  season,
  isDesktop,
  pricingSchemeValues,
}: {
  season: SeasonDto;
  isDesktop: boolean;
  pricingSchemeValues: PricingSchemeValueDto[];
}) => {
  const seasonActions = useSeasonActions();
  const hasTiers = pricingSchemeValues.length > 0;
  const shouldShowPricingList = isDesktop && season.pricingScheme !== "none";
  const shouldShowPriceListWarning =
    !isDesktop && season.pricingScheme !== "none" && !hasTiers;

  return (
    <div className={"w-full space-y-5 md:space-y-8"}>
      <SharedSeasonPricingSchemeCard
        season={season}
        BaseCard={BaseCard}
        FooterSlot={
          <div className={"pt-1"}>
            <SeasonPricingListCardWarning />
          </div>
        }
        shouldShowFooter={shouldShowPriceListWarning}
        props={{
          onClick: isDesktop
            ? undefined
            : season.pricingScheme !== "none"
              ? () => {
                  Router.push("PricingTiers", {
                    seasonId: season.id,
                  });
                }
              : undefined,
          headerAction:
            season.pricingScheme === "none"
              ? {
                  text: "Edit",
                  onClick: () => {
                    seasonActions.showPricingSchemeUpdateForm(season);
                  },
                }
              : {
                  text: "View",
                  onClick: isDesktop
                    ? () => {
                        Router.push("PricingTiers", {
                          seasonId: season.id,
                        });
                      }
                    : undefined,
                },
        }}
      />
      {shouldShowPricingList && (
        <SeasonPricingListCard season={season} values={pricingSchemeValues} />
      )}
    </div>
  );
};
