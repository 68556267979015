import { ActivityLogDto, ActivityLogType } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import {
  colors,
  getDifferenceBetweenTwoDates,
  getPlatformFunctions,
  IconName,
} from "shared/lib";

import { dayjs } from "../../../../lib/dayjs";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  activityLogItems: ActivityLogDto[];
  goToStudent: (studentId: string) => void;
}

export const ActivityLogList = ({ activityLogItems, goToStudent }: Props) => {
  const { Icon, View, Text, Pressable } = useGenericComponents();
  const { renderMarkdown } = getPlatformFunctions();

  return (
    <View className={"space-y-2 -mx-2"}>
      {activityLogItems.map((item: ActivityLogDto) => (
        <Pressable onClick={() => goToStudent(item.entityId)} key={item.id}>
          <View
            className={
              "flex flex-1 flex-row items-center justify-between space-x-3 py-2 hover:bg-grey-100 cursor-pointer px-2"
            }>
            <View>
              <View
                className={
                  "flex h-10 w-10 items-center justify-center rounded-full bg-brand-100"
                }>
                <Icon
                  size={24}
                  name={iconName(item)}
                  color={colors.brand[800]}
                />
              </View>
            </View>
            <View className={"flex-initial flex-grow"}>
              <Text className={"text-label-400"}>
                {renderMarkdown(item.message)}
              </Text>
            </View>
            <View className={"flex-shrink-0"}>
              <Text className={"flex-1 text-label-400 text-grey-600"}>
                {dateDiffMessage(item.createdAt)}
              </Text>
            </View>
          </View>
        </Pressable>
      ))}
    </View>
  );
};

function iconName(item: ActivityLogDto): IconName {
  return match<ActivityLogType, IconName>(item.activityType)
    .with(ActivityLogType.Enrolment, () => "calendar")
    .with(ActivityLogType.Trial, () => "time")
    .with(ActivityLogType.WaitingList, () => "reorderFour")
    .exhaustive();
}

function dateDiffMessage(date: Date): string {
  const diffInDays = getDifferenceBetweenTwoDates(
    date.toString(),
    new Date().toString(),
  );

  return match<number, string>(diffInDays.days)
    .with(0, () => "Today")
    .otherwise(() => dayjs(date).format("DD MMM"));
}
