import { PropsWithChildren } from "react";

import { CompanyDto, PublicCompanyDto } from "@justraviga/classmanager-sdk";

import { FamilyBackground } from "@/modules/common/layout/FamilyBackground";
import { FamilyContent } from "@/modules/common/layout/FamilyContent";
import { FamilyFooter } from "@/modules/common/layout/FamilyFooter";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { CustomerHeader } from "@/modules/customer/CustomerHeader";
import { CustomerNav } from "@/modules/customer/CustomerNav";

interface CustomerLayoutProps extends PropsWithChildren {
  hideNav?: boolean;
  showBackButton?: boolean;
  backButtonText?: string;
  backButtonOnClick?: () => void;
  company?: CompanyDto | PublicCompanyDto | null;
}

export const CustomerLayout = ({
  children,
  hideNav = false,
  showBackButton = false,
  backButtonText = "Back",
  backButtonOnClick = () => {
    window.history.back();
  },
  company,
}: CustomerLayoutProps) => {
  return (
    <>
      <FamilyBackground>
        <CustomerHeader company={company} />
        {showBackButton && (
          <div className="px-5 py-4 md:px-8">
            <button
              className="p-x-2 flex flex-row justify-between justify-items-center space-x-2 text-label-600 text-grey-900 underline underline-offset-4 hover:text-grey-600"
              onClick={backButtonOnClick}>
              <Icon name={"chevronBackOutline"} size={24} />
              <span className="pt-1 text-label-400 text-grey-900">
                {backButtonText}
              </span>
            </button>
          </div>
        )}
        <FamilyContent hideNav={hideNav}>{children}</FamilyContent>
        <FamilyFooter />
      </FamilyBackground>
      {!hideNav && (
        <div className="fixed bottom-5 left-1/2 w-full -translate-x-1/2 px-5 md:bottom-14 md:w-auto">
          <CustomerNav />
        </div>
      )}
    </>
  );
};
