import { ToggleTrigger } from "@/modules/admin/docs/components/ToggleDoc";
import { IconButton } from "@/modules/common/ui/button/IconButton";

interface DocHeaderProps {
  title: string;
  description?: string;
}

export const DocHeader = ({ title, description }: DocHeaderProps) => (
  <div className="mb-5 flex flex-col gap-y-2">
    <div className="flex items-center justify-between">
      <h1 className="text-16">{title}</h1>
      <ToggleTrigger
        renderTrigger={toggleContent => (
          <div className="-mr-2">
            <IconButton
              onClick={toggleContent}
              variant="standard"
              icon="codeSlashOutline"
              size="sm"
              aria-label="Toggle example code"
            />
          </div>
        )}
      />
    </div>
    {description && (
      <p className="text-label-400 text-grey-600">{description}</p>
    )}
  </div>
);
