import {
  UpdateCompanySettingsRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { AuthContextInterface } from "../../authState";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = Required<UpdateCompanySettingsRequest>["tax"];

export const useTaxSettingsFormDefinition = () => {
  const formBuilder = new FormDefinitionBuilder<Schema>();

  return formBuilder
    .text("taxIdLabel", {
      label: "Tax identification label",
    })
    .text("taxIdNumber", {
      label: "Tax identification number",
    })
    .group("Tax ID", ["taxIdLabel", "taxIdNumber"], {
      description:
        "This is used to identify your company for tax purposes and will appear on invoices.",
    })
    .getDefinition();
};

export const makeTaxSettingsUpdateRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  (formData: Schema) => {
    return api.companies
      .updateCompanySettings({
        updateCompanySettingsRequest: {
          tax: formData,
        },
      })
      .then(company => {
        setAccount({
          ...account,
          company,
        });

        return company;
      });
  };
