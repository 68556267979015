import { ReactNode } from "react";

import { FooterLinks } from "@/modules/common/layout/FooterLinks";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardSubTitle,
  CardTitle,
} from "@/modules/common/ui/card/Card";
import { Version } from "@/modules/common/Version";

type AuthCardProps = {
  header: ReactNode;
  title: string;
  subtitle?: string;
  content: ReactNode;
  footer?: ReactNode;
  showTerms?: boolean;
};

export const AuthPageCard = ({
  header,
  title,
  subtitle,
  content,
  footer,
  showTerms = true,
}: AuthCardProps) => {
  return (
    <Card className="w-full space-y-8 border-0 px-8 py-10 md:max-w-lg md:border md:px-8 md:py-12">
      <CardHeader>{header}</CardHeader>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {subtitle && <CardSubTitle>{subtitle}</CardSubTitle>}
      </CardHeader>
      <CardContent className={"space-y-8"}>{content}</CardContent>
      <CardFooter className="flex-col justify-center space-y-4 text-center">
        {footer}
        {showTerms && <AuthPageCard.TermsAndPrivacyPolicy />}
        <Version />
      </CardFooter>
    </Card>
  );
};

AuthPageCard.TermsAndPrivacyPolicy = () => (
  <div className="text-body-400 text-grey-600 md:hidden">
    <FooterLinks />
  </div>
);
