import { useCatalogueContext } from "shared/components";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { PublicLayout } from "@/modules/public/layout/PublicLayout";
import { Router } from "@/routing/router";

export const CompanyLandingPage = () => {
  const { company } = useCatalogueContext();

  const goToEnrol = () => {
    Router.push("CourseFilterWizard", { slug: company.slug, mode: "enrol" });
  };

  const goToTrial = () => {
    Router.push("CourseFilterWizard", { slug: company.slug, mode: "trial" });
  };

  return (
    <PublicLayout>
      <h1 className="mb-10 text-body-400 text-grey-600">
        Welcome to the {company.name} Class Manager dashboard.
      </h1>
      <div className="flex w-full flex-col gap-5 md:grow md:flex-row md:gap-8">
        <div className="flex-1">
          <BaseCard
            title="Enroll for classes"
            titleSize={16}
            icon="school"
            iconPosition="left-framed"
            onClick={goToEnrol}
          />
        </div>
        {company.settings.trial.enableOnPortal && (
          <div className="flex-1">
            <BaseCard
              title="Book a trial"
              titleSize={16}
              icon="time"
              iconPosition="left-framed"
              onClick={goToTrial}
            />
          </div>
        )}
      </div>
    </PublicLayout>
  );
};
