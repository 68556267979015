import { ReactNode } from "react";

import {
  CourseDto,
  LocationDto,
  PricingSchemeValueDto,
  PublicStaffDto,
  RoomDto,
  SeasonDto,
  StaffDto,
} from "@justraviga/classmanager-sdk";
import { CompanyTrialSettingsDto } from "@justraviga/classmanager-sdk/dist/models/CompanyTrialSettingsDto";

import { CoursePreviewMode } from "./CoursePreviewButton";
import { CoursePreviewLessonList } from "./CoursePreviewLessonList";
import { CoursePreviewPrice } from "./CoursePreviewPrice";
import { SeasonPricingList } from "./SeasonPricingList";
import { IconName } from "../../../../availableIcons";
import {
  ageRangeDescription,
  displayCourseDates,
  displayLessonTimes,
} from "../../../../classUtils";
import { colors } from "../../../../colors";
import { cn } from "../../../../cssUtils";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { getFullName } from "../../../../personUtil";
import { enumLabel } from "../../../../translateUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  CourseImageWithOverlayComponent,
  HorizontalSeparatorComponent,
  IconComponent,
} from "../../../interfaces";
import { Chip } from "../../../ui/Chip";

export interface CoursePreviewPageProps {
  courseId: string;
  mode?: CoursePreviewMode;
}

export const CoursePreview = ({ children }: { children: ReactNode }) => {
  return children;
};

//Row item
const CoursePreviewRowItem = ({
  icon,
  text,
  weight = "400",
  color = "600",
  Icon,
  manualPadding = true,
  semibold = false,
}: {
  Icon: IconComponent;
  icon?: IconName;
  text?: string;
  weight?: "400" | "600";
  color?: "900" | "600";
  manualPadding?: boolean;
  semibold?: boolean;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View
      className={cn("flex flex-row items-center space-x-2", {
        "pb-2": manualPadding,
      })}>
      {icon && <Icon name={icon} size={20} color={colors.grey[color]} />}
      <Text
        className={cn({
          "text-body-600": weight === "600",
          "text-body-400": weight === "400",
          "text-grey-600": color === "600",
          "text-grey-900": color === "900",
          "font-semibold": semibold,
        })}>
        {text ?? "-"}
      </Text>
    </View>
  );
};
CoursePreview.RowItem = CoursePreviewRowItem;

//Row pair
const CoursePreviewRowPair = ({
  leftText,
  rightText,
  manualPadding = true,
}: {
  leftText: string;
  rightText: string;
  manualPadding?: boolean;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View
      className={cn("flex flex-row items-center justify-between", {
        "pb-2": manualPadding,
      })}>
      <Text className={"text-body-400 text-grey-600"}>{leftText}</Text>
      <Text className={"text-body-400 text-grey-900"}>{rightText}</Text>
    </View>
  );
};
CoursePreview.RowPair = CoursePreviewRowPair;

//Description
const CoursePreviewDescription = ({
  course,
  Icon,
  title,
  showIcon = true,
}: {
  course: CourseDto;
  Icon: IconComponent;
  title?: string;
  showIcon?: boolean;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreview.RowItem
        icon={showIcon ? "informationCircleOutline" : undefined}
        text={title ?? "More details"}
        weight={"600"}
        color={"900"}
        Icon={Icon}
        semibold={true}
      />
      <Text className={"text-grey-600 text-body-400"}>
        {course.description}
      </Text>
    </View>
  );
};
CoursePreview.Description = CoursePreviewDescription;

//Lessons
const CoursePreviewLessons = ({
  classId,
  Icon,
  iconName,
}: {
  classId: string;
  Icon: IconComponent;
  iconName?: IconName;
}) => {
  const { View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreview.RowItem
        icon={iconName ?? "calendarNumberOutline"}
        text={"Lessons"}
        weight={"600"}
        color={"900"}
        Icon={Icon}
        semibold={true}
      />
      <CoursePreviewLessonList classId={classId} />
    </View>
  );
};
CoursePreview.Lessons = CoursePreviewLessons;

//Preview details
const CoursePreviewDetails = ({
  course,
  teacher,
  Icon,
  HorizontalSeparator,
}: {
  course: CourseDto;
  teacher?: StaffDto;
  Icon: IconComponent;
  HorizontalSeparator: HorizontalSeparatorComponent;
}) => {
  const { View } = useGenericComponents();

  return (
    <>
      {course.description && (
        <View className={"space-y-5"}>
          <CoursePreview.Description course={course} Icon={Icon} />
          <HorizontalSeparator spacing={0} />
        </View>
      )}
      {teacher && (
        <View className={"space-y-5"}>
          <CoursePreview.Teacher staff={teacher} Icon={Icon} />
          <HorizontalSeparator spacing={0} />
        </View>
      )}
      <CoursePreview.Lessons classId={course.id} Icon={Icon} />
    </>
  );
};
CoursePreview.Details = CoursePreviewDetails;

//Teacher
const CoursePreviewTeacher = ({
  staff,
  Icon,
  title,
}: {
  staff?: StaffDto | PublicStaffDto;
  Icon: IconComponent;
  title?: string;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreview.RowItem
        icon={"personOutline"}
        text={title ?? "Teacher"}
        weight={"600"}
        color={"900"}
        Icon={Icon}
        semibold={true}
      />
      <Text className={"text-grey-600 text-body-400"}>
        {staff ? getFullName(staff) : "-"}
      </Text>
    </View>
  );
};
CoursePreview.Teacher = CoursePreviewTeacher;

const CourseName = ({ course }: { course: CourseDto }) => {
  const { Text } = useGenericComponents();
  return <Text className={"text-body-600 font-semibold"}>{course.name}</Text>;
};
CoursePreview.Name = CourseName;

//Course time
const CourseTime = ({
  course,
  isMobile,
  Icon,
}: {
  course: CourseDto;
  Icon: IconComponent;
  isMobile?: boolean;
}) => {
  const courseTime = `${!isMobile ? enumLabel("weekDaysShort", course.dayOfWeek) + " • " : ""}${displayLessonTimes(
    {
      startTime: course.startTime,
      durationInMinutes: course.durationInMinutes,
    },
  )}`;

  return (
    <CoursePreview.RowItem
      text={courseTime}
      Icon={Icon}
      manualPadding={false}
    />
  );
};
CoursePreview.Time = CourseTime;

const CoursePreviewSummary = ({
  course,
  season,
  showPrice,
  location,
  isMobile = false,
  Icon,
}: {
  course: CourseDto;
  season: SeasonDto;
  showPrice: boolean;
  Icon: IconComponent;
  location?: LocationDto;
  isMobile?: boolean;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <View className={"flex flex-row items-center justify-between"}>
        <CourseTime course={course} Icon={Icon} isMobile={isMobile} />
        {showPrice ? (
          <CoursePreviewPrice course={course} season={season} />
        ) : (
          isMobile && (
            <Text className={"text-grey-600 text-body-400"}>
              See pricing list
            </Text>
          )
        )}
      </View>
      <CourseName course={course} />
      <CoursePreview.RowItem
        icon={"calendarClearOutline"}
        text={displayCourseDates(season.startAt, season.endAt)}
        Icon={Icon}
      />
      <CoursePreview.RowItem
        icon={"personOutline"}
        text={ageRangeDescription(course.minAgeMonths, course.maxAgeMonths)}
        Icon={Icon}
      />
      {location && (
        <CoursePreview.RowItem
          icon={"locationOutline"}
          text={location.name}
          Icon={Icon}
          manualPadding={false}
        />
      )}
    </View>
  );
};
CoursePreview.Summary = CoursePreviewSummary;

const CoursePreviewTrialPrice = ({
  trialSettings,
  mode,
}: {
  trialSettings: CompanyTrialSettingsDto;
  mode: CoursePreviewMode;
}) => {
  const { Text, View } = useGenericComponents();

  if (
    mode !== "trial" ||
    !trialSettings.enableOnPortal ||
    trialSettings.price === 0
  ) {
    return null;
  }

  return (
    <View className={"mt-2 flex justify-between text-body-400 text-grey-600"}>
      <Text>Trial</Text>
      <View className={"flex flex-row items-center"}>
        <Text className={"text-grey-900"}>
          {formatMoneyFromInteger(trialSettings.price)}
        </Text>
        <Text className={"text-grey-600"}>/lesson</Text>
      </View>
    </View>
  );
};
CoursePreview.TrialPrice = CoursePreviewTrialPrice;

const CoursePreviewPriceList = ({
  season,
  pricingSchemeValues,
  Icon,
}: {
  season: SeasonDto;
  pricingSchemeValues: PricingSchemeValueDto[];
  Icon: IconComponent;
}) => {
  const { View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreview.RowItem
        icon={"cashOutline"}
        text={"Pricing list"}
        weight={"600"}
        color={"900"}
        Icon={Icon}
      />
      <SeasonPricingList
        values={pricingSchemeValues}
        scheme={season.pricingScheme}
      />
    </View>
  );
};
CoursePreview.PriceList = CoursePreviewPriceList;

const ImageWithOverlay = ({
  course,
  OverlayImage,
  isFull = false,
}: {
  course: CourseDto;
  OverlayImage: CourseImageWithOverlayComponent;
  isFull?: boolean;
}) => {
  return (
    <OverlayImage
      course={course}
      collapseWhenPlaceholder={course.image === null}
      left={
        <Chip
          label={enumLabel("weekDaysShort", course.dayOfWeek)}
          variant={"neutral"}
        />
      }
      right={isFull && <Chip label={"Class full"} variant={"danger"} />}
    />
  );
};
CoursePreview.ImageWithOverlay = ImageWithOverlay;

const LocationAndRoom = ({
  location,
  room,
  Icon,
}: {
  location: LocationDto;
  room: RoomDto;
  Icon: IconComponent;
}) => {
  const { View, Text } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreview.RowItem
        icon={"locationOutline"}
        text={"Location"}
        weight={"600"}
        color={"900"}
        Icon={Icon}
      />
      <Text className={"text-grey-600 text-body-400"}>
        {location.name}-{room.name}
      </Text>
    </View>
  );
};
CoursePreview.LocationAndRoom = LocationAndRoom;
