import { FC, ReactNode } from "react";

import { StaffDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { formatDateTime } from "../../intlFormatter";
import { enumLabel } from "../../translateUtils";
import {
  StaffFilterFormSchema,
  useStaffFilterForm,
} from "../formDefinitions/staffFilterForm";

type Dto = StaffDto;
type FilterForm = StaffFilterFormSchema;

export const useStaffDatatable = ({
  Datatable,
  api,
  archive,
  archiveMany,
  deleteMany,
  deleteOne,
  invitationStatus,
  onRowClick,
  showCreateForm,
  showInviteForm,
  showUpdateForm,
  unarchive,
}: {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, FilterForm> }>;
  api: Api;
  archive: (item: Dto) => Promise<void | Dto>;
  archiveMany: (ids: string[]) => Promise<void>;
  deleteMany: (ids: string[]) => Promise<void>;
  deleteOne: (item: Dto) => Promise<void>;
  invitationStatus: (item: Dto) => ReactNode;
  onRowClick: (item: Dto) => void;
  showCreateForm: () => void;
  showInviteForm: (item: Dto) => void;
  showUpdateForm: (item: Dto) => void;
  unarchive: (item: Dto) => Promise<Dto>;
}): { datatable: ReactNode } => {
  const filterForm = useStaffFilterForm();

  const fetchData = async (query: DatatableQueryProps) => {
    return api.staff.listStaff(query.toRequest());
  };

  const config: DatatableConfiguration<Dto, FilterForm> = {
    id: "staff",
    title: "Staff",
    hasPagination: true,
    placeholdersCount: 5,
    createAction: showCreateForm,
    rowActions: {
      archive: archive,
      click: onRowClick,
      delete: deleteOne,
      edit: showUpdateForm,
      restore: unarchive,
      additionalRowActions: () => [
        {
          label: "Invite to Class Manager",
          onClick: item => showInviteForm(item),
          enabled: item => item.invitationStatus === "not_invited",
        },
      ],
    },
    groupActions: {
      archiveMany: archiveMany,
      deleteMany: deleteMany,
    },
    fetchData,
    columns: [
      {
        label: "Name",
        placeholder: "tile",
        value: row => ({
          type: "tile",
          title: `${row.item.firstname} ${row.item.lastname}`,
          subtitle: row.item.email,
          image: row.item.profilePicture ?? undefined,
          icon: invitationStatus(row.item),
        }),
        sortableField: "firstname",
      },
      {
        label: "Permission Level",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: enumLabel("accountPermissionSet", row.item.permissionSet),
        }),
      },
      {
        label: "Date created",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: row.item.createdAt
            ? formatDateTime(row.item.createdAt, "dayMonthYear")
            : "-",
        }),
      },
    ],
    mobileColumn: {
      hasImage: true,
      image: row => row.item.profilePicture ?? undefined,
      title: row => `${row.item.firstname} ${row.item.lastname}`,
      subtitle: row => row.item.email,
      isPendingDeleted: row => !!row.item.deletedAt,
      icon: row =>
        row.item.deletedAt ? undefined : invitationStatus(row.item),
    },
    filterForm,
    contentPlaceholders: {
      noContent: {
        title: "No staff members yet",
        description: "Create a staff member and invite them to Class Manager.",
        icon: "people",
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
