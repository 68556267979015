import {
  WaiverAgreementMap,
  WaiverEntity,
  WaiverEntityWithAgreement,
} from "./waiver";
import { EntityListResponse } from "../entityListUtils";

export function combineWaiversAndAgreements(
  waiverListResponse: EntityListResponse<WaiverEntity>,
  agreementMap: WaiverAgreementMap,
): EntityListResponse<WaiverEntityWithAgreement> {
  return {
    ...waiverListResponse,
    data: waiverListResponse.data.map(waiver => ({
      ...waiver,
      agreement: agreementMap[waiver.props.id] || null,
    })),
  };
}
