import { CourseDto } from "@justraviga/classmanager-sdk";

import { LessonChip } from "shared/components";
import {
  cn,
  colors,
  formatDate,
  formatTime,
  LessonRecord,
  timeToUrlParam,
  useBreakpoint,
} from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { Button } from "@/modules/common/ui/button/Button";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { ClassLessonActionMenu } from "@/modules/company/classPlanner/lessons/menus/ClassLessonActionMenu";
import { Router } from "@/routing/router";

export const ClassLessonRow = ({
  courseDto,
  record,
}: {
  courseDto: CourseDto;
  record: LessonRecord;
}) => {
  const { md: isDesktop } = useBreakpoint();
  const lesson = record.lesson;
  const lessonStartDate = lesson.date;
  const lessonStartTime = lesson.startTime;
  const lessonTime = timeToUrlParam(lessonStartTime);

  const shouldShowTakeAttendanceButton =
    !record.isCancelled && !record.isOnHoliday;

  return (
    <section
      key={`${lesson.classId}-${lesson.date}`}
      onClick={() => {
        Router.push("LessonDetails", {
          id: courseDto.id,
          date: lessonStartDate,
          time: lessonTime,
        });
      }}
      className={cn(
        "flex items-center justify-between border-b border-grey-200 p-3 last:border-b-0 hover:bg-grey-100",
        { "cursor-pointer": shouldShowTakeAttendanceButton },
      )}>
      <div className={"space-y-1"}>
        <div className={"flex items-center space-x-2 "}>
          <p className={"text-body-400 text-grey-900"}>
            {formatDate(lessonStartDate, "weekdayDayMonthYear")}
          </p>

          {record.isAttendanceCompleted && (
            <Tooltip
              trigger={
                <div>
                  <Icon name="checkmarkCircle" color={colors.green["600"]} />
                </div>
              }
              children={<p>Attendance completed</p>}
            />
          )}

          <LessonChip lesson={record} />
        </div>
        <div>
          <LessonDescription record={record} />
        </div>
      </div>

      <div className={"flex items-center space-x-2"}>
        {shouldShowTakeAttendanceButton && (
          <Button
            text={isDesktop ? "Take attendance" : "Attendance"}
            size={"xs"}
            variant={record.isAttendanceCompleted ? "secondary" : "brand"}
            onClick={event => {
              event.stopPropagation(); // Prevent row click event from firing

              Router.push("ClassLessonAttendances", {
                id: courseDto.id,
                date: lessonStartDate,
                time: lessonTime,
              });
            }}
          />
        )}

        <ClassLessonActionMenu record={record} />
      </div>
    </section>
  );
};

const LessonDescription = ({ record }: { record: LessonRecord }) => {
  if (record.isOnHoliday) {
    return (
      <p className={"text-label-400 text-grey-500"}>No lesson on this day</p>
    );
  }

  if (record.isCancelled) {
    return (
      <p className={"text-label-400 text-grey-500"}>
        This lesson has been cancelled
      </p>
    );
  }

  const timeInformation = `${formatTime(record.lesson.startTime, "hourMinute")} - ${formatTime(record.lesson.endTime, "hourMinute")}`;

  const trialsInformation = record.trialsInformation
    ? `• ${record.trialsInformation}`
    : "";

  return (
    <p className={"text-label-400 text-grey-600"}>
      {timeInformation} {trialsInformation}
    </p>
  );
};
