import { TransactionItem } from "./TransactionItem";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../../../interfaces";

export const TransactionItemsList = ({
  items,
  HorizontalSeparator,
}: {
  items: {
    description: string;
    subtotal: number;
  }[];
  HorizontalSeparator: HorizontalSeparatorComponent;
}) => {
  const { View } = useGenericComponents();
  return (
    <View>
      {items.map((item, index) => {
        return (
          <View key={item.description} className={"space-y-1"}>
            <TransactionItem
              description={item.description}
              total={item.subtotal}
            />
            {index !== items.length - 1 && <HorizontalSeparator spacing={2} />}
          </View>
        );
      })}
    </View>
  );
};
