import { ReactNode } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { AlignedCell } from "@/modules/common/datatable/cell/AlignedCell";

export const DatatablePlaceholderRow = <ModelDto,>({
  columns,
  id,
}: {
  columns: ColumnDef<ModelDto>[];
  id?: string | number;
}) => {
  return (
    <>
      <tr
        className={
          "duration-800 animate-pulse border-b-[1px] border-grey-300 ease-out"
        }>
        {columns.map(column => {
          const meta = column.meta as {
            align: "left" | "right";
            placeholder: ReactNode;
          };

          return (
            <td
              key={`placeholder-column-${column.id}-${id}`}
              className="px-4 py-3.5">
              <AlignedCell align={meta.align ? meta.align : "left"}>
                {meta.placeholder}
              </AlignedCell>
            </td>
          );
        })}
      </tr>
    </>
  );
};
