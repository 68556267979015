import { MultiStepsHeaderProps } from "shared/lib";

import { CircularIndicator } from "@/modules/common/ui/CircularIndicator";

export const MultiStepsHeader = ({
  currentStep,
  steps,
}: MultiStepsHeaderProps) => {
  const totalSteps = steps.length;
  return (
    <div className="flex h-20 flex-row items-center justify-between rounded-md bg-grey-200 p-4">
      <span className="text-body-600 font-semibold">
        {steps.find(step => step.index === currentStep)?.label}
      </span>
      <CircularIndicator
        className={"h-12 w-12"}
        value={currentStep}
        maxValue={totalSteps}
      />
    </div>
  );
};
