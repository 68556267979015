import { FC, useMemo } from "react";

import { ChartAxisDto, ChartDataDto } from "@justraviga/classmanager-sdk";

import { ChartWidget } from "./ChartWidget";
import { dayjs } from "../../../../lib/dayjs";
import { getPlatformFunctions } from "../../../../platformSpecific";

interface Props {
  BillingHistoryChart: FC<{ data: BillingHistoryChartData[] }>;
}

export interface BillingHistoryChartData {
  month: string;
  transactions: number;
  creditNotes: number;
  totalCharges: number;
  payments: number;
  refunds: number;
  totalPayments: number;
}

export const BillingHistoryWidget = ({ BillingHistoryChart }: Props) => {
  const { useApi } = getPlatformFunctions();
  const { data, isLoading } = useApi("chartByChargeAndPaymentTransaction", {});
  const chartData = useMemo(
    () => (data ? generateChartData(data) : []),
    [data],
  );

  return (
    <ChartWidget
      title="Billing"
      chartTitle="Last 6 months"
      lastUpdated={new Date()}
      isLoading={isLoading}>
      <BillingHistoryChart data={chartData} />
    </ChartWidget>
  );
};

const monthsToDisplay = 6;

const extractValue = (monthData: ChartDataDto[], label: string): number =>
  monthData.flatMap(item => item.values).find(item => item.label === label)
    ?.value || 0;

const generateChartData = (data: ChartAxisDto[]) => {
  const currentDate = dayjs();

  return Array.from({ length: monthsToDisplay }, (_, index) => {
    const date = currentDate.subtract(monthsToDisplay - 1 - index, "month");
    const month = date.format("YYYY-MM");
    const monthData = data.find(item => item.name === month)?.data || [];

    const transactions = extractValue(monthData, "Transactions");
    const creditNotes = extractValue(monthData, "Credit Notes");
    const totalCharges = transactions + creditNotes;
    const payments = extractValue(monthData, "Payments");
    const refunds = extractValue(monthData, "Refunds");
    const totalPayments = payments + refunds;

    return {
      month,
      transactions,
      creditNotes,
      totalCharges,
      payments,
      refunds,
      totalPayments,
    };
  });
};
