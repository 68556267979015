import { ContentPlaceholder } from "./ContentPlaceholder";
import { IconName } from "../../availableIcons";
import { useGenericComponents } from "../GenericComponentsProvider";

interface Props {
  icon: IconName;
  title: string;
  description?: string;
  createAction: {
    text: string;
    onClick: () => void;
  };
  goToArchivedItems?: () => void;
}

export const NoItemsContentPlaceholder = ({
  icon,
  title,
  description,
  createAction,
  goToArchivedItems,
}: Props) => {
  const { Button, View } = useGenericComponents();
  return (
    <ContentPlaceholder
      icon={icon}
      title={title}
      description={description}
      action={
        <>
          <View className={"mx-auto"}>
            <Button
              variant={"brand"}
              size={"sm"}
              onClick={createAction.onClick}
              text={createAction.text}
            />
          </View>
          {goToArchivedItems && (
            <View className={"mx-auto"}>
              <Button
                variant={"tertiaryLight"}
                onClick={goToArchivedItems}
                text={"Go to archived items"}
              />
            </View>
          )}
        </>
      }
    />
  );
};
