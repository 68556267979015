import { LocationDto } from "@justraviga/classmanager-sdk";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { useLocationActions } from "@/modules/company/locations/useLocationActions";
import { Router } from "@/routing/router";

type LocationCardProps = {
  location: LocationDto;
  roomCount: number;
};

export const LocationCard = ({ location, roomCount }: LocationCardProps) => {
  const locationActions = useLocationActions();

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Edit",
      onClick: () => locationActions.showUpdateForm(location),
    },
    {
      title: location.archivedAt ? "Restore" : "Archive",
      onClick: () =>
        location.archivedAt
          ? locationActions.unarchive(location)
          : locationActions.archive(location),
    },
    {
      title: "Delete",
      onClick: () => locationActions.deleteOne(location),
      destructive: true,
    },
  ];

  const plural = roomCount === 1 ? "" : "s";

  return (
    <BaseCard
      onClick={() => Router.push("LocationDetails", { id: location.id })}
      headerActions={menuItems}
      gap={2}
      title={location.name}
      titleColor={"grey-900"}
      titleSize={16}
      titleWeight={600}
      description={`${roomCount} room${plural}`}
      descriptionColor={"grey-900"}
      descriptionSize={16}
      footerSlot={
        <div className={"flex justify-start"}>
          <LocationStatusChip
            status={location.archivedAt ? "Archived" : "Active"}
          />
        </div>
      }
    />
  );
};

const LocationStatusChip = ({ status }: { status: "Archived" | "Active" }) => {
  const variant = status === "Archived" ? "neutral" : "success";

  return <Chip label={status} variant={variant} />;
};
