import { truncateString } from "shared/lib";

import { CodeProps, DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { ActionMenu, ActionMenuProps } from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";

const options: Array<Option<ActionMenuProps>> = [
  {
    title: "Default menubar",
    props: [
      {
        trigger: <Button text={"Options"} variant={"secondary"} />,
        items: [
          {
            title: "Edit",
          },
          {
            title: "Duplicate",
          },
          {
            title: "Delete",
            destructive: true,
          },
        ],
      },
    ],
  },

  {
    title: "Menubar with description",
    props: [
      {
        trigger: (
          <Button text={"Options with descriptions"} variant={"secondary"} />
        ),
        items: [
          {
            title: "Edit",
            description: "Edit the record",
          },
          {
            title: "Duplicate",
            description: "Copy the record",
          },
          {
            title: "Delete",
            description: "Delete the record",
            destructive: true,
          },
        ],
      },
    ],
  },

  {
    title: "Menubar with header and icons",
    props: [
      {
        trigger: <Button text={"Options with header"} variant={"secondary"} />,
        header: (
          <div className={"flex justify-between"}>
            <span className={"text-sm font-semibold text-grey-900"}>
              Karla Smith
            </span>
            <span className={"text-sm font-normal text-grey-600"}>Admin</span>
          </div>
        ),
        items: [
          {
            title: "Calendar",
            rightIcon: "calendarOutline",
          },
          {
            title: "Card",
            rightIcon: "cardOutline",
          },
        ],
      },
    ],
  },
  {
    title: "Menubar triggered by icon button",
    props: [
      {
        trigger: (
          <IconButton
            icon={"ellipsisHorizontal"}
            variant={"standard"}
            size={"sm"}
          />
        ),
        items: [
          {
            title: "Archive",
            destructive: true,
          },
          {
            title: "Reject",
            destructive: true,
          },
        ],
      },
    ],
  },

  {
    title: "Menubar with rounded-md border",
    props: [
      {
        borderRadius: "rounded-md",
        trigger: <Button text={"Options"} variant={"secondary"} />,
        items: [
          {
            title: "Approve",
          },
          {
            title: "Reject",
          },
          {
            title: "Delete",
            destructive: true,
          },
        ],
      },
    ],
  },

  {
    title: "Menubar with grouped items",
    props: [
      {
        borderRadius: "rounded-md",
        trigger: <Button text={"Grouped options"} variant={"secondary"} />,
        items: [
          {
            title: "Approve",
          },
          {
            title: "Schedule",
            group: {
              items: [
                {
                  title: "Add to schedule",
                },
                {
                  title: "Remove from schedule",
                },
              ],
            },
          },
          {
            title: "Reject",
            destructive: true,
            hasDestructiveStyle: false,
          },
          {
            title: "Delete",
            destructive: true,
          },
        ],
      },
    ],
  },

  {
    title: "Menubar with grouped items and alternative mobile sheet view",
    props: [
      {
        borderRadius: "rounded-md",
        showActionsAsBottomSheetOnMobile: true,
        header: (
          <h3 className={"text-lg font-semibold text-grey-900"}>
            Season actions
          </h3>
        ),
        trigger: (
          <Button text={"Alternative mobile view"} variant={"secondary"} />
        ),
        items: [
          {
            title: "Approve",
          },
          {
            title: "Schedule",
            group: {
              items: [
                {
                  title: "Add to schedule",
                },
                {
                  title: "Remove from schedule",
                },
              ],
            },
          },
          {
            title: "Reject",
            destructive: true,
            hasDestructiveStyle: false,
          },
          {
            title: "Delete",
            destructive: true,
          },
        ],
      },
    ],
  },

  {
    title: "Grouped actions",
    props: [
      {
        showActionsAsBottomSheetOnMobile: true,
        trigger: (
          <Button
            text={"Alternative grouped with long title"}
            variant={"secondary"}
          />
        ),
        header: (
          <h3 className={"text-lg font-semibold text-grey-900"}>
            {truncateString(
              "Jazz Dance Class long title that should be truncated",
              35,
            )}
          </h3>
        ),
        items: [
          {
            title: "Go to class",
            leftIcon: "openOutline",
            onClick: () => alert("Go to class is not implemented yet"),
          },
          {
            title: "Preview",
            leftIcon: "listOutline",
            onClick: () => alert("Preview is not implemented yet"),
          },
          {
            title: "Edit",
            leftIcon: "createOutline",
            onClick: () => alert("Edit is not implemented yet"),
          },
          {
            title: "Email enrollments",
            leftIcon: "mailOutline",
            onClick: () => alert("Email enrollments is not implemented yet"),
          },
          {
            title: "Schedule",
            group: {
              items: [
                {
                  title: "Edit start & end dates",
                  leftIcon: "calendarClearOutline",
                  onClick: () =>
                    alert("Edit start & end dates is not implemented yet"),
                },
              ],
            },
          },
          {
            title: "Archive",
            leftIcon: "archiveOutline",
            onClick: () => alert("Archive is not implemented yet"),
            destructive: true,
            hasDestructiveStyle: false,
          },
          {
            title: "Delete",
            leftIcon: "trashOutline",
            onClick: () => alert("Delete is not implemented yet"),
            destructive: true,
          },
        ],
      },
    ],
  },

  {
    title: "Grouped actions",
    props: [
      {
        showActionsAsBottomSheetOnMobile: true,
        trigger: (
          <Button
            text={"Alternative group with long title"}
            variant={"secondary"}
          />
        ),
        items: [
          {
            title: "Go to class",
            leftIcon: "openOutline",
            onClick: () => alert("Go to class is not implemented yet"),
          },
          {
            title: "Group title which is very long and should be truncated",
            group: {
              items: [
                {
                  title: "Edit start & end dates",
                  leftIcon: "calendarClearOutline",
                  onClick: () =>
                    alert("Edit start & end dates is not implemented yet"),
                },
              ],
            },
          },
          {
            title: "Delete",
            leftIcon: "trashOutline",
            onClick: () => alert("Delete is not implemented yet"),
            destructive: true,
          },
        ],
      },
    ],
  },
];

export const ActionMenuPage = () => {
  const getTriggerCode = (
    props: ActionMenuProps[],
  ): CodeProps<ActionMenuProps>[] => {
    return props.map(function (prop: ActionMenuProps) {
      return {
        ...prop,
        trigger: "<Button text={'Options'} />",
      };
    });
  };
  return (
    <DocWrapper
      title={"Menubar"}
      component={ActionMenu}
      options={options}
      transformProps={getTriggerCode}
    />
  );
};
