import {
  DiscountSchemeTierCreateFormProps,
  makeDiscountSchemeTierCreateRequest,
  useDiscountSchemeTierForm,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { DiscountSchemeTierFormBanner } from "@/modules/company/billing/discountSchemes/tiers/DiscountSchemeTierFormBanner";

export const DiscountSchemeTierCreateForm = ({
  onCreateSuccess,
  discountScheme,
  quantity,
}: DiscountSchemeTierCreateFormProps) => {
  const form = useDiscountSchemeTierForm(discountScheme.type);

  return (
    <div className={"flex flex-col space-y-8"}>
      <DiscountSchemeTierFormBanner
        discountScheme={discountScheme}
        quantity={quantity}
      />
      <GenericForm
        apiRequest={makeDiscountSchemeTierCreateRequest({
          api,
          quantity,
          discountScheme,
        })}
        defaultValues={{}}
        formDefinitionHook={() => form}
        onSuccess={onCreateSuccess}
      />
    </div>
  );
};
