import React from "react";

import { DiscountSchemeDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { Api } from "../../api";
import { DiscountSchemeCreateFormProps } from "../formDefinitions/discountSchemeForm";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Model = DiscountSchemeDto;

export const useSharedDiscountSchemeActions = (
  defaultActions: DefaultEntityActions<Model>,
  api: Api,
  createForm: React.FC<DiscountSchemeCreateFormProps>,
) => {
  const CreateForm = createForm;

  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.discountSchemes.deleteDiscountScheme({ id: entity.id }),
      ),
    showCreateForm: () =>
      defaultActions.showCreateForm({
        title: "Create discount scheme",
        form: (
          <CreateForm
            description={
              "The smallest discount will be applied to students and classes."
            }
            onFinalSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),
    updateStatus: (id: string, status: boolean) =>
      api.discountSchemes
        .statusDiscountScheme({
          id,
          statusDiscountSchemeRequest: { status },
        })
        .then(() =>
          showAlert({
            content: `Discount scheme ${status ? "enabled" : "disabled"} successfully`,
          }),
        ),
  };
};
