import { flexRender, Header, Table } from "@tanstack/react-table";

import { colors } from "shared/lib";

import { AlignedCell } from "@/modules/common/datatable/cell/AlignedCell";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const DatatableHeaderCell = <ModelDto,>({
  header,
  table,
  width,
}: {
  header: Header<ModelDto, unknown>;
  table: Table<ModelDto>;
  width?: string;
}) => {
  const meta = header.column.columnDef.meta as {
    onHeaderClick?: (table: Table<ModelDto>) => void;
    align: "left" | "center" | "right";
  };

  return (
    <th
      style={{
        ...(width && { width }),
      }}
      key={header.id}
      colSpan={header.colSpan}
      className={`box-content px-4 py-3 text-xs font-normal text-grey-500 ${
        meta.onHeaderClick ? "cursor-pointer" : ""
      }`}
      onClick={() => {
        if (meta && meta.onHeaderClick) {
          meta.onHeaderClick(table);
        }
      }}>
      {header.isPlaceholder ? null : (
        <div
          {...{
            className: header.column.getCanSort()
              ? "cursor-pointer select-none"
              : "",
            onClick: header.column.getToggleSortingHandler(),
          }}>
          <AlignedCell align={meta.align}>
            {flexRender(header.column.columnDef.header, header.getContext())}

            {header.column.getCanSort() ? (
              <div className={"ml-2 inline-block"}>
                {header.column.getIsSorted() ? (
                  (header.column.getIsSorted() as string) === "desc" ? (
                    <Icon
                      name={"arrowDownOutline"}
                      color={colors.grey[500]}
                      size={16}
                    />
                  ) : (
                    <Icon
                      name={"arrowUpOutline"}
                      color={colors.grey[500]}
                      size={16}
                    />
                  )
                ) : null}
              </div>
            ) : null}
          </AlignedCell>
        </div>
      )}
    </th>
  );
};
