import { useAuthenticatedAccountEntity, useAuthState } from "shared/components";
import { getEntityName, isFamily } from "shared/lib";

import { ProfileAvatar } from "@/modules/common/ui/avatar/ProfileAvatar";
import { Icon } from "@/modules/common/ui/icon/Icon";

export const AccountMenuItem = () => {
  const { user } = useAuthState();
  const entity = useAuthenticatedAccountEntity();

  return (
    <div className={"flex items-center gap-4"}>
      <ProfileAvatar />
      <div className={"flex-grow"}>
        <div className={"line-clamp-1"}>{getEntityName(entity ?? user)}</div>
        {isFamily(entity) && (
          <div className={"text-body-400 text-grey-600"}>Family account</div>
        )}
      </div>
      <div>
        <Icon name={"chevronForwardOutline"} />
      </div>
    </div>
  );
};
