import { useState } from "react";

import { LinkDto, LogInRequest } from "@justraviga/classmanager-sdk";

import { useAuthState, useStaffLoginForm } from "shared/components";
import { processInvite } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Button } from "@/modules/common/ui/button/Button";
import { Router } from "@/routing/router";

interface LinkedLoginFormProps {
  linkDetails?: LinkDto;
  email?: string;
}

export const LinkedLoginForm = ({
  linkDetails,
  email,
}: LinkedLoginFormProps) => {
  const { setUser } = useAuthState();
  const [isLoading, setLoading] = useState(false);

  const loginUser = async (data: LogInRequest) => {
    if (linkDetails) {
      processInvite(api, {
        linkDetails,
        email: data.email,
        password: data.password,
        requiresRegistration: false,
        onError: () => setLoading(false),
        onSuccess: () => {
          setLoading(false);
          Router.push("Accounts");
        },
        setUser,
      });
    }
  };

  const {
    form,
    formHandlers: { handleSubmit },
  } = useStaffLoginForm(useFormBuilder, loginUser, {
    email: email!,
    password: "",
  });

  return (
    <>
      {form}
      <div className="flex justify-start">
        <Button variant="tertiary" text="Forgot my password" />
      </div>
      <Button
        loading={isLoading}
        onClick={handleSubmit(loginUser)}
        variant="brand"
        text="Accept invitation"
        isFullWidth={true}
      />
    </>
  );
};
