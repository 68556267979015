import { useGenericComponents } from "../GenericComponentsProvider";

export const TooltipSection = ({
  title,
  data,
  total,
  colour,
}: {
  title: string;
  data: Array<{ value: number | string; label: string }>;
  total: number | string;
  colour: string;
}) => {
  const { Text, View } = useGenericComponents();
  return (
    <View>
      <View className="flex flex-row items-center space-y-1">
        <View
          className="mr-2 block h-4 w-4 rounded"
          style={{ backgroundColor: colour }}
        />
        <View>
          <Text className="text-caption-400 text-grey-900">{title}</Text>
        </View>
        {data.length === 0 && (
          <>
            <View className="grow"></View>
            <View>
              <Text className="text-caption-400 text-grey-600">{total}</Text>
            </View>
          </>
        )}
      </View>
      <View className="flex flex-col space-y-1">
        {data.map(({ label, value }, index) => (
          <View
            className="flex flex-row items-center justify-between"
            key={index}>
            <Text className="text-caption-400 text-grey-600">{label}</Text>
            <Text className="text-caption-400 text-grey-600 text-right">
              {value}
            </Text>
          </View>
        ))}
        {data.length > 0 && (
          <View className="flex flex-row items-center justify-between">
            <Text className="text-caption-400 text-grey-900">Total</Text>
            <Text className="text-caption-400 text-grey-900">{total}</Text>
          </View>
        )}
      </View>
    </View>
  );
};
