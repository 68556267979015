import React from "react";

import {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/modules/common/toast/Toast";
import { useToast } from "@/modules/common/toast/useToast";

interface ToasterListProviderProps {
  children: React.ReactNode;
}

export const ToasterProvider = ({ children }: ToasterListProviderProps) => {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        actionLabel,
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action && actionLabel && (
              <ToastAction onClick={action} altText={actionLabel}>
                {actionLabel}
              </ToastAction>
            )}
            <ToastClose />
          </Toast>
        );
      })}
      {toasts.length > 0 && <ToastViewport />}
      {children && children}
    </ToastProvider>
  );
};
