import { ContentPlaceholder } from "shared/components";
import {
  AttendanceRecord,
  AttendanceStatuses,
  cn,
  formatDate,
  urlParamToTime,
  useBreakpoint,
  useLessonAttendancesData,
  useLessonDetailsData,
} from "shared/lib";

import { Breadcrumbs } from "@/modules/common/nav/Breadcrumbs";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { LessonDetailsHeader } from "@/modules/company/classPlanner/lessons/headers/LessonDetailsHeader";
import { useLessonActionMenuItems } from "@/modules/company/classPlanner/lessons/menus/useLessonActionMenuItems";
import { StudentRow } from "@/modules/company/classPlanner/lessons/students/row/StudentRow";
import {
  Breadcrumb,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
} from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

export const LessonDetailsPage = ({
  courseId,
  date,
  time,
}: {
  courseId: string;
  date: string;
  time: string;
}) => {
  const { md: isDesktop } = useBreakpoint();

  const formattedTime = urlParamToTime(time);

  const {
    lesson,
    season,
    courseDto,
    records,
    isLoading,
    attendanceProgress,
    isEmpty,
    isCancelled,
    isHoliday,
    holidayName,
  } = useLessonDetailsData({
    courseId,
    date,
    time: formattedTime,
  });

  const { statuses } = useLessonAttendancesData({
    classId: courseId,
    date,
    time: formattedTime,
  });

  const { actions } = useLessonActionMenuItems({
    lesson,
    isCancelled,
  });

  const crumbs: Breadcrumb[] = [
    {
      text: "Seasons",
      onPress: () => Router.push("SeasonList"),
    },
    {
      text: season?.name ?? "",
      onPress: () => {
        if (courseDto) {
          Router.push("SeasonDetails", { id: courseDto?.seasonId });
        }
      },
    },
    {
      text: courseDto?.name ?? "",
      onPress: () => {
        if (courseDto) {
          Router.push("ClassDetails", { id: courseDto?.id });
        }
      },
    },
    {
      text: formatDate(date, "weekdayShortDayMonthYear"),
    },
  ];

  if (isLoading || !courseDto || !season) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <CompanyTitleBar isTopLevel={false}>
        {isDesktop ? (
          <Breadcrumbs items={crumbs} />
        ) : (
          <>
            <div className={"-ml-2"}>
              <IconButton
                icon={"chevronBackOutline"}
                variant={"standard"}
                size={"lg"}
                onClick={() => history.back()}
              />
            </div>
          </>
        )}

        <div className={"md:hidden"}>
          {!isEmpty && !isHoliday && (
            <ActionMenu
              header={
                <h3 className={"text-heading6-600 text-grey-900"}>
                  {formatDate(date, "weekdayShortDayMonthYear")}
                </h3>
              }
              showActionsAsBottomSheetOnMobile={true}
              trigger={
                <IconButton icon={"ellipsisHorizontal"} variant={"standard"} />
              }
              items={actions}
            />
          )}
        </div>
      </CompanyTitleBar>
      <CompanyContent>
        <LessonDetailsHeader
          lesson={lesson}
          courseDto={courseDto}
          date={date}
          time={formattedTime}
          progress={attendanceProgress}
          recordCount={records.length}
          isCancelled={isCancelled}
          isHoliday={isHoliday}
          isDesktop={isDesktop}
        />

        <div
          className={cn({
            "-mx-4": !isDesktop,
          })}>
          <LessonDetailsContent
            isHoliday={isHoliday}
            holidayName={holidayName}
            records={records}
            isCancelled={isCancelled}
            isEmpty={isEmpty}
            statuses={statuses}
          />
        </div>
      </CompanyContent>
    </CompanyLayout>
  );
};

const LessonDetailsContent = ({
  isHoliday,
  holidayName,
  isEmpty,
  records,
  isCancelled,
  statuses,
}: {
  isHoliday: boolean;
  holidayName: string | undefined;
  isEmpty: boolean;
  records: AttendanceRecord[];
  isCancelled: boolean;
  statuses: AttendanceStatuses;
}) => {
  const { md: isDesktop } = useBreakpoint();

  if (isHoliday) {
    return (
      <div className={"p-2"}>
        <ContentPlaceholder
          icon={"airplaneOutline"}
          title={"Closed"}
          description={holidayName}
        />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className={"p-2"}>
        <ContentPlaceholder
          icon={"schoolOutline"}
          title={"No students yet"}
          description={
            "When students are enrolled or trialling this class, they will appear here."
          }
        />
      </div>
    );
  }

  return records.map((record, index) => (
    <div key={`lesson-student-${record.student.id}`}>
      <StudentRow
        record={record}
        isCancelled={isCancelled}
        isDesktop={isDesktop}
        status={statuses[record.student.id]}
      />

      {index !== records.length - 1 && <HorizontalSeparator spacing={0} />}
    </div>
  ));
};
