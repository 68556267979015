import { LinkDto, StaffDto } from "@justraviga/classmanager-sdk";

import { useSharedStaffActions } from "shared/components";
import { showAlert } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { AvatarUploadForm } from "@/modules/common/form/AvatarUploadForm";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { StaffSendInvitationForm } from "@/modules/company/staff/forms/StaffSendInvitationForm";

type Model = StaffDto;

export const useStaffActions = () => {
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "staff",
  });
  const sharedActions = useSharedStaffActions(defaultActions, api);

  const { openSheet: showForm, closeSheet: hideForm } = useSheet();

  const onInviteSuccess = () => {
    showAlert({
      content: `Invitation sent successfully`,
    });
    hideForm();
  };

  return {
    ...sharedActions,

    showInviteForm: (entity: Model): Promise<LinkDto> => {
      return new Promise(resolve => {
        showForm({
          title: `Invite Staff`,
          content: (
            <StaffSendInvitationForm
              staff={entity}
              onSuccess={linkData => {
                onInviteSuccess();
                resolve(linkData);
              }}
            />
          ),
          footer: <UpdateFooter saveButtonText={"Send invitation"} />,
        });
      });
    },

    showAvatarUploadSheet: (entity: Model) => {
      showForm({
        title: "Add Profile Image",
        content: <AvatarUploadForm dto={entity} />,
        footer: <UpdateFooter />,
      });
    },
  };
};
