import {
  CourseSearchValues,
  FilteredCourses,
  GoToSummaryButton,
  useAuthState,
  useCatalogueContext,
} from "shared/components";
import { IconButtonProps } from "shared/lib";

import { CourseImage } from "@/modules/common/classes/CourseImage";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { CourseSearchBar } from "@/modules/public/catalogue/CourseSearchBar";
import { PublicLayout } from "@/modules/public/layout/PublicLayout";
import { Router } from "@/routing/router";

export interface CoursesPageProps extends CourseSearchValues {}

export const CoursesPage = ({
  ages,
  days,
  locationIds,
  mode = "enrol",
  query,
  seasonIds,
}: CoursesPageProps & { mode?: "enrol" | "trial" }) => {
  const sheet = useSheet();
  const { company } = useCatalogueContext();
  const auth = useAuthState();

  const switchMode = () => {
    Router.push("Courses", {
      slug: company.slug,
      ages: ages.map(String),
      days,
      locationIds,
      query: query.length > 0 ? query : undefined,
      seasonIds,
      mode: mode === "enrol" ? "trial" : "enrol",
    });
  };

  const goToCourse = (courseId: string) => {
    Router.push("CatalogueCoursePreview", {
      slug: company.slug,
      courseId: courseId,
      mode,
    });
  };

  return (
    <PublicLayout>
      <h1 className="mb-2 text-heading4-600 text-grey-900">
        {mode === "enrol" ? "Classes" : "Trials"}
      </h1>
      <div className={"flex flex-row justify-between"}>
        {auth.isLoggedIn && (
          <Button
            className="mb-5 px-0"
            variant="tertiary"
            text={"Return to Dashboard"}
            onClick={() => Router.push("Home")}
          />
        )}
        {(mode === "trial" || company.settings.trial.enableOnPortal) && (
          <Button
            className="mb-5 px-0"
            variant="tertiary"
            text={mode === "enrol" ? "View trials" : "View classes"}
            onClick={switchMode}
          />
        )}
      </div>
      <CourseSearchBar mode={mode} />
      <div className="mt-8 pb-24 md:pb-0">
        <FilteredCourses
          mode={mode}
          onClick={goToCourse}
          Button={Button}
          CourseImage={CourseImage}
          IconButton={(props: IconButtonProps) => <IconButton {...props} />}
          sheet={sheet}
        />
      </div>
      <div className="fixed bottom-7 left-7 right-7 md:bottom-14">
        <GoToSummaryButton
          Button={Button}
          gotoBasket={() => Router.push("MemberBasket", { slug: company.slug })}
        />
      </div>
    </PublicLayout>
  );
};
