import { useState } from "react";

import {
  CreateFamilyRequest,
  InviteFamilyRequest,
  UpdateFamilyRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { formatDateTime } from "../../intlFormatter";

type CreateSchema = CreateFamilyRequest;
type UpdateSchema = UpdateFamilyRequest;
type InviteSchema = InviteFamilyRequest;

const useFamilyFormDefinition = (mode: "create" | "update") => {
  const [sendInvite, setSendInvite] = useState(false);

  const form = new FormDefinitionBuilder<CreateSchema & UpdateSchema>()
    .group("Basic Information", ["name"])
    .text("name", { label: "Family name", required: true })
    .switch("sendInvite", {
      label: "Send invitation to sign up for Class Manager",
      description:
        "Please provide the details of the primary contact so we can send an invitation.",
    })
    .group("Primary Contact", [
      "primaryContactFirstname",
      "primaryContactLastname",
      "primaryContactEmail",
    ])
    .text("primaryContactFirstname", {
      label: "First name",
      required: true,
    })
    .text("primaryContactLastname", {
      label: "Last name",
    })
    .text("primaryContactEmail", {
      label: "Email address",
      required: mode === "create" && sendInvite,
    })
    .group("Enrollment Details", ["joinedAt"], { advanced: true })
    .date("joinedAt", { label: "Joined Date" });

  if (mode === "create") {
    form.conditional(["primaryContactEmail"], ["sendInvite"], v => {
      setSendInvite(v.sendInvite == true);
      return true;
    });
  }

  if (mode === "update") {
    form.without(["sendInvite"]);
  }

  return form.getDefinition();
};

export const useFamilyInviteFormDefinition = () => {
  return new FormDefinitionBuilder<InviteSchema>()
    .text("firstname", {
      label: "First name",
      required: true,
    })
    .text("lastname", {
      label: "Last name",
      required: true,
    })
    .text("email", {
      label: "Email",
      type: "email",
      required: true,
    })
    .getDefinition();
};

export const useFamilyCreateFormDefinition = () =>
  useFamilyFormDefinition("create") as FormDefinition<CreateSchema>;

export const useFamilyUpdateFormDefinition = () =>
  useFamilyFormDefinition("update") as FormDefinition<UpdateSchema>;

export const makeFamilyCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.families.createFamily({
      createFamilyRequest: {
        ...formData,
        joinedAt: formData.joinedAt
          ? formData.joinedAt
          : formatDateTime(new Date(), "isoDate"),
      },
    });
  };

export const makeFamilyUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.families.updateFamily({
      id,
      updateFamilyRequest: formData,
    });
  };

export const makeFamilyInviteRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: InviteSchema) => {
    return api.families.inviteFamily({ id, inviteFamilyRequest: formData });
  };
