import { PropsWithChildren } from "react";

import { useFamilyStripeStatus } from "shared/components";

import { PendingWaivers } from "@/modules/customer/accessDialog/PendingWaivers";
import { ProtectedPlaceholder } from "@/modules/customer/placeholders/ProtectedPlaceholder";

export const CustomerAccessOverlay = ({ children }: PropsWithChildren) => {
  const { protector } = useFamilyStripeStatus();

  return (
    <ProtectedPlaceholder protector={protector}>
      <PendingWaivers>{children}</PendingWaivers>
    </ProtectedPlaceholder>
  );
};
