import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import {
  applyUnaryOperator,
  formatMoneyFromInteger,
  getTransactionAmountColor,
  getTransactionPrimaryType,
  isDebit,
} from "shared/lib";

import { useGenericComponents } from "../GenericComponentsProvider";

export const TransactionAmountText = ({
  transaction,
  showUnaryOperator = false,
}: {
  transaction: AggregateTransactionDto;
  showUnaryOperator?: boolean;
}) => {
  const { Text } = useGenericComponents();
  const primaryType = getTransactionPrimaryType(transaction);
  const color = getTransactionAmountColor(primaryType);

  const { total } = transaction.stats;
  const amount = showUnaryOperator
    ? applyUnaryOperator(isDebit(transaction), total)
    : total;
  const displayAmount = formatMoneyFromInteger(amount);

  return (
    <Text
      className={"text-right text-body-400"}
      style={{
        color,
      }}>
      {displayAmount}
    </Text>
  );
};
