import React, { useState } from "react";

export type NavContextType = {
  isShowing: boolean;
  hide: () => void;
  show: () => void;
};

export const NavContext = React.createContext<NavContextType>({
  isShowing: false,
  hide: () => {},
  show: () => {},
});

export const NavProvider = ({ children }: { children: React.ReactNode }) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const defaultValue: NavContextType = {
    isShowing,
    hide: () => setIsShowing(false),
    show: () => setIsShowing(true),
  };

  return (
    <NavContext.Provider value={defaultValue}>{children}</NavContext.Provider>
  );
};
