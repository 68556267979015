import { useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  TransactionDto,
} from "@justraviga/classmanager-sdk";

import { CreditNoteDetails } from "./CreditNoteDetails";
import { CreditNoteForm } from "./CreditNoteForm";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";

export const CreditNoteProcess = ({
  setTitle,
  transaction,
}: {
  setTitle: (title: string) => void;
  transaction: AggregateTransactionDto;
}) => {
  const [creditNoteTransaction, setCreditNoteTransaction] =
    useState<AggregateTransactionDto | null>();
  const { usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<TransactionDto>({
    entity: "transaction",
  });
  const { setShouldCloseOnSuccess } = useFormActions();

  useEffect(() => {
    setShouldCloseOnSuccess(false);
  }, [setShouldCloseOnSuccess]);

  const onSuccess = (transaction: AggregateTransactionDto) => {
    defaultActions.onCreateSuccess();
    setCreditNoteTransaction(transaction);
  };

  return creditNoteTransaction ? (
    <CreditNoteDetails
      originalTransaction={transaction}
      setTitle={setTitle}
      transaction={creditNoteTransaction}
    />
  ) : (
    <CreditNoteForm onSuccess={onSuccess} transaction={transaction} />
  );
};
