import { colors, sortDiscountScheme } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { DiscountSchemeCard } from "@/modules/company/billing/discountSchemes/DiscountSchemeCard";
import { NoDiscountSchemePlaceholder } from "@/modules/company/billing/discountSchemes/NoDiscountSchemePlaceholder";
import { useDiscountSchemeActions } from "@/modules/company/billing/discountSchemes/useDiscountSchemeActions";

export const DiscountSchemeList = () => {
  const discountSchemes =
    useApi("listDiscountScheme", { selectAll: true }).data?.data ?? [];
  const { showCreateForm } = useDiscountSchemeActions();

  const InfoCard = () => {
    return (
      <div className="mb-5">
        <BaseCard
          fillColor={colors.grey["100"]}
          title={"Discount types"}
          titleColor={"grey-900"}
          titleWeight={600}
          titleSize={16}
          description={
            "You can apply one discount from each category: multiple student discounts, class discounts by student, and class discounts by family."
          }
          descriptionColor={"grey-900"}
          descriptionSize={14}
          descriptionWeight={400}
        />
      </div>
    );
  };

  return (
    <CompanyCardPage
      title={"Discount schemes"}
      primaryAction={{
        title: "Create",
        onClick: showCreateForm,
        icon: "addOutline",
        size: "sm",
      }}
      isEmpty={discountSchemes.length < 1}>
      <InfoCard />
      <CardContainerPlaceholder>
        <NoDiscountSchemePlaceholder />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {sortDiscountScheme(discountSchemes).map(discountScheme => (
          <DiscountSchemeCard
            discountScheme={discountScheme}
            key={discountScheme.id}
          />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};
