import { ReactNode, useEffect } from "react";

import {
  AggregateTransactionDetailsDto,
  AggregateTransactionDto,
  BillDto,
  BillItemDto,
} from "@justraviga/classmanager-sdk";

import { cn, HorizontalSeparatorComponent, useBreakpoint } from "shared/lib";

import { CheckoutLayout } from "./CheckoutLayout";
import { CheckoutLeftColumn } from "./CheckoutLeftColumn";
import { CheckoutRightColumn } from "./CheckoutRightColumn";
import { CheckoutSuccessMessage } from "./CheckoutSuccessMessage";
import { PaymentSuccessDetails } from "./PaymentSuccessDetails";
import { TransactionList } from "./TransactionList";
import { TransactionSummary } from "./TransactionSummary";
import { useBasket } from "../../../basket/useBasket";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface CustomerCheckoutSuccessPageProps {
  DashboardButton: ReactNode;
  HorizontalSeparator: HorizontalSeparatorComponent;
  gotoDashboard: () => void;
}

export const CustomerCheckoutSuccessPage = ({
  DashboardButton,
  HorizontalSeparator,
  gotoDashboard,
}: CustomerCheckoutSuccessPageProps) => {
  const { View, Text } = useGenericComponents();
  const { md } = useBreakpoint();

  const { getSuccessfulTransaction } = useBasket();
  const { transactions: aggregateTransactions } =
    getSuccessfulTransaction() ?? {};

  const paymentDetailsId = aggregateTransactions
    ?.find(
      (transaction: AggregateTransactionDto) =>
        transaction.transaction.type === "credit",
    )
    ?.details.find(
      (details: AggregateTransactionDetailsDto) =>
        details.details.type === "payment-auto",
    )?.details.id;

  const transactions = aggregateTransactions
    ?.filter(
      (transaction: AggregateTransactionDto) =>
        transaction.transaction.type !== "credit",
    )
    .map(aggregateTransaction => {
      return {
        description: aggregateTransaction.transaction.description,
        items: aggregateTransaction.details
          .filter(detail => detail.details.type !== "discount")
          .filter(detail => detail.details.type !== "tax")
          .map(detail => {
            return {
              type: detail.details.type,
              description: detail.details.description,
              subtotal: detail.details.amount,
            } as BillItemDto;
          }),
        subtotal: aggregateTransaction.stats.subtotal,
        // discountTotal: aggregateTransaction.details
        //   .filter(detail => detail.details.type === "discount")
        //   .reduce((acc, detail) => acc + detail.details.amount, 0),
        discountTotal: aggregateTransaction.stats.discountTotal,
        // taxTotal: aggregateTransaction.details
        //   .filter(detail => detail.details.type === "tax")
        //   .reduce((acc, detail) => acc + detail.details.amount, 0),
        taxTotal: aggregateTransaction.stats.taxTotal,
        total: aggregateTransaction.stats.total,
      } as BillDto;
    });

  useEffect(() => {
    if (!aggregateTransactions) {
      gotoDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregateTransactions]);

  if (!aggregateTransactions || !transactions) {
    return null;
  }

  return (
    <View>
      <View className={"px-8 py-4"}>
        <View className={"w-full"}>
          <CheckoutSuccessMessage action={DashboardButton} />
        </View>
      </View>
      <CheckoutLayout>
        <CheckoutLeftColumn>
          <View
            className={cn("flex flex-col items-start justify-center", {
              "space-y-10": md,
              "space-y-8": !md,
            })}>
            <View className={"w-full"}>
              <Text className="text-heading5-600 text-grey-900">
                Transaction details
              </Text>
            </View>
            <View className={"w-full"}>
              <TransactionList
                transactions={transactions}
                HorizontalSeparator={HorizontalSeparator}
              />
            </View>
            <View className={"flex w-full flex-col space-y-2"}>
              <TransactionSummary transactions={transactions} />
            </View>
          </View>
        </CheckoutLeftColumn>
        <CheckoutRightColumn>
          {paymentDetailsId && (
            <View
              className={
                "flex flex-col items-start justify-start space-y-8 py-4 md:py-0"
              }>
              <View className="flex w-full flex-row items-center justify-between">
                <Text className="text-heading5-600 text-grey-900">
                  Payment details
                </Text>
              </View>
              <View className={"w-full"}>
                <PaymentSuccessDetails transactionId={paymentDetailsId} />
              </View>
            </View>
          )}
        </CheckoutRightColumn>
      </CheckoutLayout>
    </View>
  );
};
