import {
  DetailedEnrolment,
  getEnrolmentEndDate,
  getEnrolmentStartDate,
} from "../../enrolmentUtils";
import { Chip } from "../ui/Chip";

export const EnrolmentStatusChip = ({
  detailedEnrolment,
}: {
  detailedEnrolment: DetailedEnrolment;
}) => {
  if (getEnrolmentEndDate(detailedEnrolment) < detailedEnrolment.season.endAt) {
    return <Chip variant="primary" label="Withdrawing early" size="sm" />;
  }

  if (
    getEnrolmentStartDate(detailedEnrolment) > detailedEnrolment.season.startAt
  ) {
    return <Chip variant="secondary" label="Started late" size="sm" />;
  }

  return null;
};
