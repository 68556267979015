import {
  AggregateClassDto,
  LocationDto,
  RoomDto,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { CourseSearchMode } from "./courseSearchTypes";

export const activeSeasons = (seasons: Array<SeasonDto>) =>
  seasons.filter(s => s.registrationOpen).filter(s => !s.archivedAt);

export const courseIsBookable = (
  course: AggregateClassDto,
  mode?: CourseSearchMode,
) =>
  mode
    ? mode === "enrol"
      ? course.settings.portalEnrolmentEnabled
      : course.settings.trialsEnabled
    : course.settings.portalEnrolmentEnabled || course.settings.trialsEnabled;

export const coursesAreBookable = (
  mode: CourseSearchMode,
  courses: Array<AggregateClassDto>,
) =>
  courses.some((course: AggregateClassDto) => courseIsBookable(course, mode));

export const activeCourses = (
  courses: Array<AggregateClassDto>,
  mode?: CourseSearchMode,
  seasons?: Array<SeasonDto>,
) => {
  // filter seasons that are not active, extract ids as array, otherwise leave undefined
  const activeSeasonIds = seasons
    ? activeSeasons(seasons).map(s => s.id)
    : undefined;

  // filter active courses based on active seasons, if any, else all active courses
  return courses
    .filter(c => activeSeasonIds?.includes(c.entity.seasonId) ?? true)
    .filter(c => !c.entity.archivedAt)
    .filter(c => courseIsBookable(c, mode));
};

/**
 * Returns locations that are active and in use dependent on the variables passed in.
 * rooms, courses, seasons are optional and will be used to filter the locations, if passed in.
 * @param locations array of locations to check
 * @param rooms array of rooms to filter locations based if room is active
 * @param courses array of courses to filter location based if course is active
 * @param mode enrol or trial
 * @param seasons array of seasons to filter location based if season is active
 */
export const activeAndInUseLocations = (
  locations: Array<LocationDto>,
  rooms?: Array<RoomDto>,
  courses?: Array<AggregateClassDto>,
  mode?: CourseSearchMode,
  seasons?: Array<SeasonDto>,
) => {
  const assignedActiveRoomIds = courses
    ? activeCourses(courses, mode, seasons)
        .filter(c => c.entity.roomId)
        .map(c => c.entity.roomId)
    : undefined;

  // extract location ids from passed in rooms
  const assignedLocationIds = rooms
    ?.filter(r => assignedActiveRoomIds?.includes(r.id) ?? true)
    .filter(r => !r.archivedAt)
    .map(r => r.locationId);

  // return the passed in locations that are active and in use
  return locations
    .filter(l => !l.archivedAt)
    .filter(l => assignedLocationIds?.includes(l.id) ?? true);
};

export const coursesHaveMultipleAges = (
  courses: Array<AggregateClassDto>,
  mode: CourseSearchMode,
  seasons?: Array<SeasonDto>,
) =>
  activeCourses(courses, mode, seasons).filter(
    course => course.entity.minAgeMonths > 0 || course.entity.maxAgeMonths > 0,
  ).length > 1;
