import { Icon } from "@/modules/common/ui/icon/Icon";

export const InvalidHeader = () => (
  <div
    className={
      "mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-red-600"
    }>
    <Icon name={"alertCircleOutline"} color={"white"} size={24} />
  </div>
);
