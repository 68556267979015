import { LessonDto } from "@justraviga/classmanager-sdk";

import { useSharedLessonActions } from "shared/components";
import { makeLessonId, showAlert } from "shared/lib";

import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Router } from "@/routing/router";

export const useLessonActions = () => {
  const { showAlert: showAlertDialog } = useAlertDialog();
  const { closeSheet: hideForm } = useSheet();
  const onSuccess = (message: string) => {
    showAlert({ content: message });
    hideForm();
  };
  const sharedActions = useSharedLessonActions({
    onSuccess,
    cancelDialog: showAlertDialog,
  });

  return {
    ...sharedActions,
    addFeeForLesson: (lesson: LessonDto) => {
      const lessonId = makeLessonId(
        lesson.classId,
        lesson.date,
        lesson.startTime,
      );

      Router.push("TransactionCreateFromLesson", {
        lessonId,
      });
    },
  };
};
