import { PropsWithChildren } from "react";

import { Tooltip } from "@/modules/common/overlays/Tooltip";

export interface InteractionOverlayProps extends PropsWithChildren {
  message?: string;
  enabled?: boolean;
}

export const InteractionOverlay = ({
  children,
  enabled,
  message,
}: InteractionOverlayProps) => {
  if (!enabled) {
    return children;
  }

  return (
    <Tooltip
      trigger={
        <div
          className="relative w-full"
          onClickCapture={e => {
            e.stopPropagation();
            e.preventDefault();
          }}>
          {children}
          <div className="absolute inset-0 bg-overlay-white-heavy"></div>
        </div>
      }
      children={<div className={"text-center"}>{message}</div>}
    />
  );
};
