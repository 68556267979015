import { colors } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

interface InputErrorMessageProps {
  error?: string;
}

export const InputErrorMessage = ({ error }: InputErrorMessageProps) => {
  return (
    <div className="min-h-8">
      {error ? (
        <span className="flex flex-row items-start space-x-1 pb-2 pt-1">
          <Icon name="alertCircle" size={18} color={colors.red[600]} />
          <span className="whitespace-pre-line text-caption-400 text-red-600">
            {error}
          </span>
        </span>
      ) : (
        "\u00A0"
      )}
    </div>
  );
};
