import { FamilyDto, StudentDto } from "@justraviga/classmanager-sdk";

import {
  makeStudentCreateRequest,
  MultiStepStudentForm,
  MultiStepStudentFormFamilyCard,
  StudentImplementedActions,
  useSharedStudentActions,
  useStudentCreateFormDefinition,
} from "shared/components";
import { showAlert } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { AvatarUploadForm } from "@/modules/common/form/AvatarUploadForm";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { CircularIndicator } from "@/modules/common/ui/CircularIndicator";
import { StudentPreview } from "@/modules/company/members/student/preview/StudentPreview";

type Model = StudentDto;

export const useStudentActions = (): StudentImplementedActions => {
  const { openSheet } = useSheet();
  const defaultActions = usePlatformEntityActions<StudentDto>({
    entity: "student",
  });
  const { openSheet: showForm, closeSheet: hideForm } = useSheet();
  const onSuccess = (message: string) => {
    showAlert({ content: message });
    hideForm();
  };

  const sharedActions = useSharedStudentActions(
    openSheet,
    UpdateFooter,
    onSuccess,
  );

  return {
    ...sharedActions,

    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <MultiStepStudentForm
            CircularIndicator={CircularIndicator}
            Datatable={Datatable}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showAvatarUploadSheet: (entity: Model) => {
      showForm({
        title: "Add Profile Image",
        content: <AvatarUploadForm dto={entity} />,
        footer: <UpdateFooter />,
      });
    },

    showCreateWithFamilyForm: (family: FamilyDto) =>
      defaultActions.showCreateForm({
        form: (
          <>
            <MultiStepStudentFormFamilyCard family={family} />
            <GenericForm
              apiRequest={makeStudentCreateRequest({
                api,
                familyId: family.id,
              })}
              defaultValues={{}}
              formDefinitionHook={useStudentCreateFormDefinition}
              onSuccess={defaultActions.onCreateSuccess}
            />
          </>
        ),
      }),

    showPreview: (entity: Model) => {
      openSheet({
        title: "Student information",
        content: <StudentPreview student={entity} />,
      });
    },
  };
};
