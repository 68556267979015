import { NoLocationsPlaceholder } from "shared/components";
import { useLocationsAndRooms } from "shared/lib";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { LocationCard } from "@/modules/company/locations/LocationCard";
import { useLocationActions } from "@/modules/company/locations/useLocationActions";
import { Router } from "@/routing/router";

export const LocationsPage = () => {
  const { locations, roomCountForLocation, isEmpty } = useLocationsAndRooms();

  const { showCreateForm } = useLocationActions();

  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Create",
    onClick: showCreateForm,
  };

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Go to archived items",
      leftIcon: "archiveOutline",
      onClick: () => Router.push("LocationListArchived"),
    },
  ];

  return (
    <CompanyCardPage
      title={"Locations"}
      isEmpty={isEmpty}
      primaryAction={primaryAction}
      menuItems={menuItems}>
      <CardContainerPlaceholder>
        <NoLocationsPlaceholder
          onCreateClick={showCreateForm}
          goToArchivedItems={() => Router.push("LocationListArchived")}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {locations.map((location, key) => (
          <LocationCard
            location={location}
            roomCount={roomCountForLocation(location)}
            key={key}
          />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};
