import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";
import { InvitationBasePage } from "@/modules/auth/linked/InvitationBasePage";
import { LinkedHeader } from "@/modules/auth/linked/LinkedHeader";
import { LinkedRegisterForm } from "@/modules/auth/linked/LinkedRegisterForm";
import { useInvitation } from "@/modules/auth/linked/useInvitation";

export interface LinkedRegisterProps {
  linkId: string;
  email?: string;
}

export const LinkedRegister = ({ linkId, email }: LinkedRegisterProps) => {
  const { isLoading, hasError, linkDetails, companyDetails } =
    useInvitation(linkId);

  return (
    <InvitationBasePage hasError={hasError} isLoading={isLoading}>
      <AuthPageCard
        showTerms={false}
        header={
          <LinkedHeader
            companyLogo={companyDetails?.logo}
            linkId={linkDetails?.id || ""}
          />
        }
        title={`Create a new account`}
        subtitle={`Create an account on Class Manager to accept the invitation to join ${companyDetails?.name}.`}
        content={
          <LinkedRegisterForm
            companyName={companyDetails?.name || ""}
            email={email}
            linkDetails={linkDetails}
          />
        }
        footer={
          <div className="flex flex-col justify-center gap-8">
            <div>
              <p className="text-center text-label-400 text-grey-600">
                By continuing you agree to Class Manager's Terms & conditions
                and Privacy policy
              </p>
            </div>
            <PoweredBy />
          </div>
        }
      />
    </InvitationBasePage>
  );
};
