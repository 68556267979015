import {
  ContactRelation,
  CreateContactRequest,
  UpdateContactRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

type Schemas = CreateContactRequest | UpdateContactRequest;
export type Action = "create" | "update";

const useDefinition = <Mode extends Schemas>() => {
  type Schema = CreateContactRequest & UpdateContactRequest;

  const builder = new FormDefinitionBuilder<Schema>()
    .group("Basic Information", ["firstname", "lastname", "relation"])
    .group("Contact Details", ["email", "phone"])
    .group("Preferences", ["isEmergency", "isBilling", "isAdditional"])
    .text("firstname", { label: "First Name", required: true })
    .text("lastname", { label: "Last Name" })
    .select("relation", {
      label: "Relation",
      data: Object.entries(ContactRelation).map(([, value]) => ({
        label: enumLabel("contactRelation", value as ContactRelation),
        value,
      })),
    })
    .text("phone", { label: "Phone" })
    .text("email", { label: "Email" })
    .switch("isEmergency", {
      description:
        "Mark this contact as emergency, they will show in the student overview and attendance registers",
      label: "Use in emergency",
    })
    .switch("isBilling", {
      description: "Account Statement, receipts, payment reminders",
      label: "Send billing information",
    })
    .switch("isAdditional", {
      description: "Scheduling, class enrollment",
      label: "Send additional communication",
    });

  return builder.getDefinition() as FormDefinition<Mode>;
};

export const useContactCreateFormDefinition = () =>
  useDefinition<CreateContactRequest>();

export const useContactUpdateFormDefinition = () =>
  useDefinition<UpdateContactRequest>();

export const makeContactCreateRequest =
  ({ api, familyId }: { api: Api; familyId: string }) =>
  (formData: CreateContactRequest) => {
    return api.contacts.createContact({
      createContactRequest: {
        ...formData,
        familyId,
      },
    });
  };

export const makeContactUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateContactRequest) => {
    return api.contacts.updateContact({
      id,
      updateContactRequest: formData,
    });
  };
