import {
  ContactDto,
  FamilyDto,
  InviteFamilyRequest,
  LinkDto,
} from "@justraviga/classmanager-sdk";

import {
  makeFamilyCreateRequest,
  makeFamilyUpdateRequest,
  useFamilyCreateFormDefinition,
  useFamilyUpdateFormDefinition,
  useSharedFamilyActions,
} from "shared/components";
import { showAlert } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { AvatarUploadForm } from "@/modules/common/form/AvatarUploadForm";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { FamilyInviteForm } from "@/modules/company/members/family/forms/FamilyInviteForm";

type Model = FamilyDto;

export const useFamilyActions = () => {
  const defaultActions = usePlatformEntityActions<FamilyDto>({
    entity: "family",
  });

  const sharedActions = useSharedFamilyActions(defaultActions, api);

  const { openSheet: showForm, closeSheet: hideForm } = useSheet();

  const onInviteSuccess = () => {
    showAlert({
      content: `Invitation sent successfully`,
    });
    hideForm();
  };

  return {
    ...sharedActions,

    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            onSuccess={defaultActions.onCreateSuccess}
            apiRequest={makeFamilyCreateRequest({ api })}
            defaultValues={{}}
            formDefinitionHook={useFamilyCreateFormDefinition}
          />
        ),
      }),

    showUpdateForm: (
      entity: Model,
      { primaryContact }: { primaryContact: ContactDto },
    ) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            onSuccess={defaultActions.onUpdateSuccess}
            apiRequest={makeFamilyUpdateRequest({ api, id: entity.id })}
            defaultValues={{
              name: entity.name,
              joinedAt: entity.joinedAt,
              primaryContactFirstname: primaryContact.firstname,
              primaryContactLastname: primaryContact.lastname as
                | string
                | undefined,
              primaryContactEmail: primaryContact.email as string | undefined,
            }}
            formDefinitionHook={useFamilyUpdateFormDefinition}
          />
        ),
      }),

    showInviteForm(entity: Model, args: InviteFamilyRequest): Promise<LinkDto> {
      return new Promise(resolve => {
        showForm({
          title: "Invite Family",
          content: (
            <FamilyInviteForm
              familyId={entity.id}
              defaultValues={args}
              onSuccess={linkData => {
                onInviteSuccess();
                resolve(linkData);
              }}
            />
          ),
          footer: <UpdateFooter saveButtonText={"Send invitation"} />,
        });
      });
    },

    showAvatarUploadSheet: (entity: Model) => {
      showForm({
        title: "Add Profile Image",
        content: (
          <AvatarUploadForm
            dto={{
              firstname: entity.name,
              lastname: undefined,
              ...entity,
            }}
          />
        ),
        footer: <UpdateFooter />,
      });
    },
  };
};
