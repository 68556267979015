import { cn } from "shared/lib";

type SpacingWithoutText = 0 | 1 | 2 | 3 | 4 | 5;
type SpacingWithText = 0 | 2;

interface HorizontalSeparatorWithoutText {
  spacing: SpacingWithoutText;
  text?: "" | null | undefined;
}

interface HorizontalSeparatorWithText {
  spacing: SpacingWithText;
  text: string;
}

export type HorizontalSeparatorProps =
  | HorizontalSeparatorWithoutText
  | HorizontalSeparatorWithText;

export const HorizontalSeparator = ({
  text,
  spacing = 2,
}: HorizontalSeparatorProps) => {
  return (
    <div
      className={cn("w-full", {
        "py-0": spacing === 0,
        "py-1": spacing === 1,
        "py-2": spacing === 2,
        "py-3": spacing === 3,
        "py-4": spacing === 4,
        "py-5": spacing === 5,
      })}>
      {text && text.length ? (
        <div className={"flex flex-row items-center space-x-2"}>
          <div
            data-testid={"Text-horizontal-separator"}
            className={"h-[1px] w-full bg-grey-300"}
          />
          <p className={"text-grey-500"}>{text}</p>
          <div className={"h-[1px] w-full bg-grey-300"} />
        </div>
      ) : (
        <div
          data-testid={"noText-horizontal-separator"}
          className={"h-[1px] bg-grey-300"}></div>
      )}
    </div>
  );
};

export interface VerticalSeparatorProps {
  spacing: 2 | 4;
}

export const VerticalSeparator = ({ spacing }: VerticalSeparatorProps) => {
  return (
    <div
      className={cn("h-6 py-1", {
        "px-2": spacing === 2,
        "px-4": spacing === 4,
      })}>
      <div
        data-testid={"vertical-separator"}
        className={"h-full w-[1px] bg-grey-300"}></div>
    </div>
  );
};
