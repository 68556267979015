import { useEffect, useState } from "react";

import { EnrolmentAdjustmentDto } from "@justraviga/classmanager-sdk";
import { EnrolmentAdjustmentReason } from "@justraviga/classmanager-sdk/dist/models/EnrolmentAdjustmentReason";

import {
  AggregateClassRelatedData,
  aggregateClassRelatedDataEmptyList,
  fetchClassRelatedData,
} from "./fetchClassRelatedData";
import { Api } from "../api";
import { isGreaterOrEqualThanToday, isLessThanToday } from "../dateUtils";

export interface FamilyMakeUpLessonsList
  extends Omit<AggregateClassRelatedData<EnrolmentAdjustmentDto>, "list"> {
  upComingMakeUpLessons: EnrolmentAdjustmentDto[];
  pastMakeUpLessons: EnrolmentAdjustmentDto[];
  isLoading: boolean;
  isEmpty: boolean;
}

export type FamilyMakeUpLessonsListProps = Omit<
  FamilyMakeUpLessonsList,
  "upComingMakeUpLessons" | "pastMakeUpLessons" | "isEmpty" | "isLoading"
> & {
  makeUpLessons: EnrolmentAdjustmentDto[];
  title: string;
  collapsible?: boolean;
};

export const useFamilyMakeUpLessons = (api: Api): FamilyMakeUpLessonsList => {
  const [wholeList, setWholeList] = useState<
    AggregateClassRelatedData<EnrolmentAdjustmentDto>
  >(aggregateClassRelatedDataEmptyList);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMakeUpLessons = async (api: Api) =>
      await fetchClassRelatedData<EnrolmentAdjustmentDto>(api, () =>
        api.enrolmentAdjustments.listEnrolmentAdjustment({
          where: {
            reason: { equals: EnrolmentAdjustmentReason.MakeUpLesson },
          },
          selectAll: true,
        }),
      );

    fetchMakeUpLessons(api).then(allList => {
      setWholeList(allList);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    upComingMakeUpLessons: wholeList.list.filter(t =>
      isGreaterOrEqualThanToday(t.startAt),
    ),
    pastMakeUpLessons: wholeList.list.filter(t => isLessThanToday(t.startAt)),
    students: wholeList.students,
    classes: wholeList.classes,
    seasons: wholeList.seasons,
    isLoading,
    isEmpty: wholeList.list.length === 0,
  };
};
