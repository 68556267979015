import { useState } from "react";

import type {
  AggregateEnrolmentDto,
  MakeUpLesson,
} from "@justraviga/classmanager-sdk/";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { formatDate } from "../../intlFormatter";
import { getPlatformFunctions } from "../../platformSpecific";
import { LessonSelectValue } from "../forms/LessonSelect";

type Schema = MakeUpLesson & {
  seasonId: string;
  lessonDateTime: LessonSelectValue;
};

function checkStudentEnrolment(
  studentId: string,
  classId: string,
  enrolments: AggregateEnrolmentDto[],
) {
  return enrolments.some(
    e => e.enrolment.studentId === studentId && e.enrolment.classId === classId,
  );
}

export const useMakeUpLessonForm = (studentId: string) => {
  const [selectedLessonDate, setSelectedLessonDate] = useState<string>("");
  const { useApi } = getPlatformFunctions();
  const { data: enrolments } = useApi("listEnrolment", { selectAll: true });

  const builder = new FormDefinitionBuilder<Schema>()
    .lessonDateTime("lessonDateTime", {
      transformCourseOptions: course => {
        const isStudentEnrolled = checkStudentEnrolment(
          studentId,
          course.entity.id,
          enrolments?.data ?? [],
        );

        return isStudentEnrolled
          ? { label: `${course.entity.name} (enrolled)`, disabled: true }
          : {};
      },
      required: true,
    })
    .conditional(["lessonDateTime"], ["lessonDateTime"], v => {
      if (v.lessonDateTime) {
        const [, date] = v.lessonDateTime as LessonSelectValue;

        date && setSelectedLessonDate(formatDate(date, "dayMonthYear"));
      }

      return true;
    });

  return {
    form: builder.getDefinition() as FormDefinition<Schema>,
    lessonDate: selectedLessonDate,
  };
};

export const makeMakeUpLessonRequest =
  ({ api, studentId }: { api: Api; studentId: string }) =>
  (formData: Schema) => {
    const [courseId, date, time] = formData.lessonDateTime!;

    return api.enrolmentAdjustments.makeUpLessonEnrolmentAdjustment({
      makeUpLesson: [
        {
          classId: courseId!,
          studentId,
          date: date!,
          time: time!,
        },
      ],
    });
  };
