import { AggregateClassDto } from "@justraviga/classmanager-sdk";

import { TrialLessonForm } from "./TrialLessonForm";
import { BasketContents, makeTrialKey } from "../../basket/basket";
import { useBasket } from "../basket/useBasket";
import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ButtonComponent, Sheet } from "../interfaces";
import { FullScreenModal } from "../interfaces/fullScreenModal";

export const useCourseSnippetTrialAction = ({
  course,
  sheet,
  Button,
  basket,
}: {
  course: AggregateClassDto;
  sheet: Sheet | FullScreenModal;
  Button: ButtonComponent;
  basket: BasketContents;
}) => {
  const { View } = useGenericComponents();
  const { submit } = useFormActions();
  const { addTrial } = useBasket();

  const addTrialToBasket = (dateAndTime: string) => {
    const [date, time] = dateAndTime.split(" ");
    addTrial(makeTrialKey(course.entity.id, date, time));
  };

  const isSheet = (sheet: Sheet | FullScreenModal): sheet is Sheet => {
    return (sheet as Sheet).openSheet !== undefined;
  };

  const showTrialForm = () => {
    const isModal = !isSheet(sheet);

    const props = {
      title: "Select trial date",
      content: (
        <TrialLessonForm
          course={course}
          onSubmit={formData => {
            addTrialToBasket(formData.dateAndTime);
            isModal ? sheet.closeModal() : sheet.closeSheet();
            return Promise.resolve({});
          }}
          basket={basket}
        />
      ),
      footer: (
        <View className={"w-full md:px-4 md:py-5"}>
          <Button
            variant={"brand"}
            isFullWidth={true}
            className={"w-full"}
            text="Confirm"
            onClick={submit}
          />
        </View>
      ),
    };

    isModal ? sheet.openModal(props) : sheet.openSheet(props);
  };

  return {
    showTrialForm,
  };
};
