import { CompanyDto } from "@justraviga/classmanager-sdk";

import {
  makeLookAndFeelUpdateRequest,
  useAuthState,
  useLookAndFeelFormDefinition,
  useSettings,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";

export const LookAndFeelUpdateForm = ({
  company,
  onSuccess,
}: {
  company: CompanyDto;
  onSuccess: () => void;
}) => {
  const { account, setAccount } = useAuthState();
  const { brandColor } = useSettings("general");

  return (
    <GenericForm
      formDefinitionHook={useLookAndFeelFormDefinition}
      apiRequest={makeLookAndFeelUpdateRequest({
        api,
        setAccount,
        account: account!,
      })}
      defaultValues={{
        ...company,
        brandColor,
      }}
      onSuccess={onSuccess}
    />
  );
};
