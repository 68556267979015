import { useAuthState } from "shared/components";
import { useSharedFamilyWaiversListPage } from "shared/lib";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CustomerCardPage } from "@/modules/common/cardContainer/CustomerCardPage";
import { Button } from "@/modules/common/ui/button/Button";
import { WaiverAgreementCard } from "@/modules/company/waivers/WaiverAgreementCard";
import { ContentErrorPlaceholder } from "@/modules/customer/placeholders/ContentErrorPlaceholder";
import { Router } from "@/routing/router";

export const CustomerWaiversPage = () => {
  const { account } = useAuthState();
  const { waivers, isLoading, isEmpty } =
    useSharedFamilyWaiversListPage(account);

  return (
    <CustomerCardPage
      title="Waivers & policies"
      isEmpty={isEmpty()}
      isLoading={isLoading}>
      <CardContainerPlaceholder>
        <ContentErrorPlaceholder
          title={"Error loading waivers"}
          description={
            "There was an error loading your waivers & policies. Please try again later."
          }
          action={
            <Button
              variant={"brand"}
              onClick={() => Router.replace("CustomerHome")}
              text={"Go back to home page"}
            />
          }
        />
      </CardContainerPlaceholder>
      <CardContainerContent>
        {waivers?.data.map(waiver => (
          <WaiverAgreementCard waiver={waiver} key={waiver.props.id} />
        ))}
      </CardContainerContent>
    </CustomerCardPage>
  );
};
