import { useState } from "react";

import { CustomTransactionItem, FamiliesSearch } from "shared/components";
import { formatMoneyFromInteger } from "shared/lib";

import { MultiSelect } from "@/modules/common/form/select/MultiSelect";
import { TransactionsCreatePageItemsDatatable } from "@/modules/company/billing/transactions/datatables/TransactionsCreatePageItemsDatatable";
import { TransactionsCreatePageFooter } from "@/modules/company/billing/transactions/shared/TransactionsCreatePageFooter";
import { TransactionsCreatePageItemsSummary } from "@/modules/company/billing/transactions/shared/TransactionsCreatePageItemsSummary";
import {
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  ResponsiveCompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";

export const TransactionsCreatePage = ({
  familyIds = [],
}: {
  familyIds?: string[];
}) => {
  const [selectedFamilies, setSelectedFamilies] = useState<string[]>(familyIds);
  const [items, setItems] = useState<CustomTransactionItem[]>([]);

  return (
    <CompanyLayout
      footer={
        <TransactionsCreatePageFooter
          families={selectedFamilies}
          date={new Date()} // TODO While we don't have a date picker on create page
          items={items}
          resetForm={() => {
            setItems([]);
            setSelectedFamilies([]);
          }}
        />
      }>
      <CompanyTitleBar isTopLevel={true}>
        <ResponsiveCompanyTitleBarTitle title={"Create transaction"} />
      </CompanyTitleBar>
      <CompanyContent>
        <section
          className={
            "flex flex-col-reverse gap-y-5 pb-5 md:flex-row md:justify-between md:gap-y-0 md:pb-16"
          }>
          <div className={"mb-5 space-y-2 md:w-2/5"}>
            <h4 className={"text-body-600 text-grey-900"}>Bill to</h4>
            <div>
              <FamiliesSearch
                selectedIds={selectedFamilies}
                onSelect={setSelectedFamilies}
                MultiSelect={MultiSelect}
              />
            </div>
          </div>

          <div
            className={
              "flex flex-row items-center justify-between md:flex-col md:items-end md:justify-start"
            }>
            <div className={"text-heading6-400 text-grey-600 md:text-body-400"}>
              Amount due
            </div>
            <div className={"text-heading4-600 text-grey-900"}>
              {formatMoneyFromInteger(
                items.reduce((acc, detail) => acc + detail.total, 0),
              )}
            </div>
          </div>
        </section>
        <section className={"py-4"}>
          <TransactionsCreatePageItemsDatatable
            items={items}
            setItems={setItems}
          />
        </section>
        {items.length > 0 && (
          <section
            className={
              "space-y-2 rounded border border-grey-300 bg-grey-100 p-3 md:border-0 md:p-4 "
            }>
            <TransactionsCreatePageItemsSummary items={items} />
          </section>
        )}
      </CompanyContent>
    </CompanyLayout>
  );
};
