import { ComponentType, ReactNode, useState } from "react";

import { ContactDto } from "@justraviga/classmanager-sdk";

import { useBreakpoint } from "../../breakpoints";
import { getFullName } from "../../personUtil";
import { getPlatformFunction } from "../../platformSpecific";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ButtonVariant } from "../interfaces";
import { Chip } from "../ui/Chip";

type ButtonComponent = ComponentType<{
  onClick: () => void;
  size?: "sm" | "lg" | "xs";
  text?: string;
  variant: ButtonVariant;
}>;

type TooltipComponent = ComponentType<{
  trigger: ReactNode;
  children?: ReactNode;
}>;

export const EmailRecipientChips = ({
  Button,
  recipientIds,
  Tooltip,
  removeRecipient,
}: {
  Button: ButtonComponent;
  recipientIds: string[];
  Tooltip: TooltipComponent;
  removeRecipient?: (id: string) => void;
}) => {
  const [recipientsExpanded, setRecipientsExpanded] = useState(false);

  const { Text, View } = useGenericComponents();
  const useApi = getPlatformFunction("useApi");
  const breakpoint = useBreakpoint();

  const numberOfRecipientsToPreview = breakpoint.xl
    ? 4
    : breakpoint.lg
      ? 3
      : breakpoint.md
        ? 2
        : 1;

  const { data: contacts } = useApi("listContact", {
    selectAll: true,
    where: { id: { in: recipientIds } },
  });
  const { data: families } = useApi("listFamily", { selectAll: true });

  if (!contacts || !families) {
    return <></>;
  }

  const recipients = contacts.data;

  const displayedRecipients = recipients.slice(
    0,
    recipientsExpanded ? recipients.length : numberOfRecipientsToPreview,
  );

  const getFamilyName = (recipient: ContactDto) =>
    families?.data.find(family => family.id === recipient.familyId)?.name;

  return (
    <View className="flex flex-row flex-wrap items-center gap-4">
      {displayedRecipients.map(recipient => (
        <View key={recipient.id}>
          <Tooltip
            trigger={
              <View>
                <Chip
                  key={recipient.id}
                  label={`${getFullName(recipient)} (${getFamilyName(recipient)})`}
                  rightIcon={removeRecipient ? "closeOutline" : undefined}
                  rightIconAction={
                    removeRecipient
                      ? () => removeRecipient(recipient.id)
                      : undefined
                  }
                  variant="secondary"
                />
              </View>
            }>
            <Text>{recipient.email}</Text>
          </Tooltip>
        </View>
      ))}
      <View>
        {recipientsExpanded &&
        recipients.length > numberOfRecipientsToPreview ? (
          <Button
            text="View less"
            variant="subtle"
            size="xs"
            onClick={() => setRecipientsExpanded(false)}
          />
        ) : recipients.length > numberOfRecipientsToPreview ? (
          <Button
            text={`View all (${recipients.length})`}
            variant="subtle"
            size="xs"
            onClick={() => setRecipientsExpanded(true)}
          />
        ) : null}
      </View>
    </View>
  );
};
