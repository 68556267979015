import {
  GlobalSearchStatus,
  useSearchDialog,
  useSearchStatus,
} from "shared/lib";

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/modules/common/overlays/dialog/Dialog";
import { SearchDialogContent } from "@/modules/common/search/SearchDialogContent";
import { Button } from "@/modules/common/ui/button/Button";

export const GlobalSearchDialog = () => {
  const { status } = useSearchStatus();
  const { isSearchOpen, handleOpenChange } = useSearchDialog();

  return status === GlobalSearchStatus.HIDDEN ? null : (
    <Dialog open={isSearchOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild className="hidden md:flex">
        <Button
          leftIcon={"searchOutline"}
          text={"Search"}
          variant={"secondary"}
          className={"no-underline"}
          data-testid="dialog-trigger"
        />
      </DialogTrigger>
      <DialogContent
        className={`min-h-96 fixed w-full overflow-hidden rounded bg-white md:my-16 md:w-[700px]`}>
        <SearchDialogContent />
      </DialogContent>
    </Dialog>
  );
};
