import { Icon } from "@/modules/common/ui/icon/Icon";

export const Phone = ({ phone }: { phone: string | null | undefined }) => {
  return (
    <div className={"flex flex-row items-center space-x-2"}>
      <Icon name={"callOutline"} />
      <span>{phone ?? "-"}</span>
    </div>
  );
};
