interface HasId {
  id: string;
}

/**
 * Removes duplicates from an array of objects by looking at their `id` property.
 */
export const uniqueRecordsById = <T extends HasId>(items: T[]): T[] => {
  return uniqueIds(items).map(id => items.find(item => item.id === id)!);
};

/**
 * Extracts the `id` property from an array of objects.
 */
export const uniqueIds = (items: HasId[]) => uniqueValuesForKey("id", items);

/**
 * Extracts all the unique values for a given key from an array of objects.
 */
export const uniqueValuesForKey = <
  Key extends string,
  T extends Record<Key, unknown>,
>(
  key: Key,
  items: T[],
): Array<T[Key]> => {
  return [...new Set(items.map(item => item[key]))];
};
