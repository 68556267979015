import { useFormActions } from "shared/components";

import { FormFooter } from "@/modules/common/form/FormFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";

export const FilterFormFooter = ({ onClear }: { onClear: () => void }) => {
  const { isLoading, submit, setShouldCloseOnSuccess, isSubmitDisabled } =
    useFormActions();
  const { closeSheet } = useSheet();

  return (
    <FormFooter>
      <Button
        text={"Clear"}
        variant="secondary"
        onClick={() => {
          onClear();
          closeSheet();
        }}
      />
      <Button
        variant={"brand"}
        text="Apply"
        disabled={isLoading || isSubmitDisabled}
        loading={isLoading}
        onClick={() => {
          setShouldCloseOnSuccess(true);
          submit();
        }}
      />
    </FormFooter>
  );
};
