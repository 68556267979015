import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import {
  VerticalSeparator,
  VerticalSeparatorProps,
} from "@/modules/common/ui/Separator";

const options: Array<Option<VerticalSeparatorProps>> = [
  {
    title: "Spacing",
    props: [
      {
        spacing: 2,
      },
      {
        spacing: 4,
      },
    ],
  },
];

export const VerticalSeparatorPage = () => {
  return (
    <DocWrapper
      title={"Horizontal Separator"}
      component={VerticalSeparator}
      options={options}
    />
  );
};
