import { SortSchema } from "@justraviga/classmanager-sdk";

import { useBreakpoint } from "../breakpoints";
import { getPlatformFunctions } from "../platformSpecific";

export const useFamilyStudentsPageData = () => {
  const { useApi } = getPlatformFunctions();
  const { md: isDesktop } = useBreakpoint();

  const { data: students, isLoading } = useApi("listStudent", {
    sort: {
      firstname: SortSchema.Asc,
      lastname: SortSchema.Asc,
    },
    selectAll: true,
  });

  return {
    students,
    isLoading,
    isDesktop,
  };
};
