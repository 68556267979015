import { IconButton } from "@/modules/common/ui/button/IconButton";
import { CompanyLogo } from "@/modules/company/CompanyLogo";

interface LoginHeaderProps {
  companyLogo?: string | null;
  handleBackButton?: () => void;
}

export const LoginHeader = ({
  companyLogo,
  handleBackButton,
}: LoginHeaderProps) => (
  <div className="relative">
    <div className="flex w-full justify-center">
      {handleBackButton && (
        <IconButton
          onClick={handleBackButton}
          className="absolute left-0"
          icon="chevronBackOutline"
          variant="standard"
        />
      )}
      <CompanyLogo logo={companyLogo} />
    </div>
  </div>
);
