import { LoginDto, LogInRequest } from "@justraviga/classmanager-sdk";

import { makeLoginRequest, useLoginFormDefinition } from "shared/components";
import { ButtonVariant } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { Button } from "@/modules/common/ui/button/Button";
import { Router } from "@/routing/router";

interface LoginFormProps {
  variant?: ButtonVariant;
  defaultValues: Partial<LogInRequest>;
  onSuccess: (loginDto: LoginDto) => void;
  submitHandler: () => void;
  isLoading: boolean;
}

export const LoginForm = ({
  variant = "classmanager",
  submitHandler,
  isLoading,
  defaultValues,
  onSuccess,
}: LoginFormProps) => {
  return (
    <>
      <div>
        <GenericForm
          apiRequest={makeLoginRequest({ api })}
          defaultValues={defaultValues}
          formDefinitionHook={useLoginFormDefinition}
          onSuccess={onSuccess}
        />
        {/* Wrapper to horizontally align the button text with surrounding elements*/}
        <div className={"-ml-3"}>
          <Button
            variant={"tertiaryLight"}
            onClick={() => Router.push("ForgottenPassword")}>
            Forgot my password
          </Button>
        </div>
      </div>
      <Button
        onClick={submitHandler}
        loading={isLoading}
        variant={variant}
        text="Log in"
        isFullWidth={true}
      />
    </>
  );
};
