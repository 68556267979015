import { AttendanceStatus } from "@justraviga/classmanager-sdk";

import { colors } from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const PresentIconButton = ({
  status,
  onClick,
}: {
  status?: AttendanceStatus;
  onClick: () => void;
}) => {
  if (status === "present") {
    return (
      <Tooltip
        trigger={
          <div>
            <IconButton
              icon={"checkmarkCircle"}
              variant={"standard"}
              color={colors.green[600]}
              onClick={onClick}
            />
          </div>
        }
        children={<span>Marked as present</span>}
      />
    );
  }

  return (
    <Tooltip
      trigger={
        <div>
          <IconButton
            icon={"checkmarkCircleOutline"}
            variant={"standard"}
            color={colors.grey[500]}
            onClick={onClick}
          />
        </div>
      }
      children={<span>Mark as present</span>}
    />
  );
};
