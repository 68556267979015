import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeDelayStartDateRequest,
  useUpdateStartFormDefinition,
} from "../formDefinitions/enrolmentForm";
import { useGenericComponents } from "../GenericComponentsProvider";

export interface EnrolmentKey {
  classId: string;
  studentId: string;
}

export const UpdateEnrolmentStartForm = ({
  date,
  classId,
  studentId,
}: EnrolmentKey & { date: string }) => {
  const { GenericForm } = useGenericComponents();
  const { usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<EnrolmentKey>({
    entity: "enrolment",
  });
  return (
    <GenericForm
      apiRequest={makeDelayStartDateRequest({
        classId,
        studentId,
      })}
      defaultValues={{ date }}
      formDefinitionHook={useUpdateStartFormDefinition}
      onSuccess={defaultActions.onUpdateSuccess}
    />
  );
};
