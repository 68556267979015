import { useContext } from "react";

import { AccountSelectionDialogContext } from "@/modules/company/switcher/accountSelectionDialogContext";

export const useAccountSelectionDialog = () => {
  const context = useContext(AccountSelectionDialogContext);
  if (!context) {
    throw new Error(
      "useAccountSelectionDialog needs to be rendered within the <AccountSelectionDialogProvider />",
    );
  }

  return context;
};
