import { StudentDto } from "@justraviga/classmanager-sdk";

import {
  displayStudentAge,
  enumLabel,
  formatDate,
  getFullName,
  getInitials,
} from "shared/lib";

import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { StudentActionMenu } from "@/modules/customer/students/menus/StudentActionMenu";

export const StudentCard = ({ student }: { student: StudentDto }) => {
  const { showPreview } = useStudentActions();

  return (
    <BaseCard
      bodySlot={
        <div
          className={
            "relative flex w-full flex-col items-center space-y-4 p-4"
          }>
          <Avatar
            size="xl"
            src={student.profilePicture ?? undefined}
            name={getInitials(student)}
          />

          <div className={"absolute right-0 top-0 px-4"}>
            <StudentActionMenu student={student} />
          </div>

          <div>
            <h3 className={"text-heading6-600 text-grey-900"}>
              {getFullName(student)}
            </h3>
          </div>

          <div className={"flex flex-col items-center space-y-2"}>
            <StudentCardInfoRow
              left={"Age"}
              right={student.dateOfBirth ? displayStudentAge(student, "") : "-"}
            />
            <StudentCardInfoRow
              left={"Date of birth"}
              right={
                student.dateOfBirth
                  ? formatDate(student.dateOfBirth, "dayMonthYear")
                  : "-"
              }
            />
            <StudentCardInfoRow
              left={"Gender"}
              right={
                student.gender
                  ? enumLabel("studentGender", student.gender)
                  : "-"
              }
            />

            <Button
              variant={"tertiary"}
              text={"More details"}
              onClick={() => showPreview(student)}
            />
          </div>
        </div>
      }
    />
  );
};

const StudentCardInfoRow = ({
  left,
  right,
}: {
  left: string;
  right: string;
}) => {
  return (
    <div className={"flex space-x-1"}>
      <p className={"text-body-400 text-grey-600"}>{left}</p>
      <p className={"text-body-400 text-grey-900"}>{right}</p>
    </div>
  );
};
