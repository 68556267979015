import { PricingScheme, SeasonDto } from "@justraviga/classmanager-sdk";

import { CreatePricingTierCardContents } from "./CreatePricingTierCardContents";
import { CreatePricingTierCardHeader } from "./CreatePricingTierCardHeader";
import { Api } from "../../../api";
import {
  pricingSchemeDescriptionIcon,
  pricingSchemeTypeVariant,
} from "../../../pricingSchemeUtils";
import {
  makeCreateClassPricingTierRequest,
  makeCreateDurationPricingTierRequest,
  useClassPricingTierDefinition,
  useDurationPricingTierDefinition,
} from "../../formDefinitions/pricingTierForm";
import { useGenericComponents } from "../../GenericComponentsProvider";

export const CreatePricingTierCard = ({
  season,
  api,
  classCountQuantity,
  onSuccess,
}: {
  season: SeasonDto;
  api: Api;
  classCountQuantity: number;
  onSuccess: () => void;
}) => {
  const { GenericForm, View, Banner } = useGenericComponents();
  let form;
  switch (season.pricingScheme) {
    case PricingScheme.ClassCount:
      form = (
        <GenericForm
          apiRequest={makeCreateClassPricingTierRequest({
            api,
            id: season.id,
            quantity: classCountQuantity,
          })}
          defaultValues={{}}
          formDefinitionHook={useClassPricingTierDefinition}
          onSuccess={onSuccess}
        />
      );
      break;
    case PricingScheme.TotalDuration:
      form = (
        <GenericForm
          apiRequest={makeCreateDurationPricingTierRequest({
            api,
            id: season.id,
          })}
          defaultValues={{ hours: 0, minutes: 0 }}
          formDefinitionHook={useDurationPricingTierDefinition}
          onSuccess={onSuccess}
        />
      );
      break;
    default:
      throw new Error("Undefined pricing scheme");
  }
  return (
    <View className={"flex flex-col"}>
      <View className={"pb-4"}>
        <Banner
          variant={pricingSchemeTypeVariant[season.pricingScheme]}
          icon={pricingSchemeDescriptionIcon[season.pricingScheme]}
          content={
            <View className={"flex flex-col"}>
              <CreatePricingTierCardHeader
                season={season}
                classCountQuantity={classCountQuantity}
              />
              <CreatePricingTierCardContents season={season} />
            </View>
          }
        />
      </View>
      <View className={"pt-4"}>{form}</View>
    </View>
  );
};
