import { useEffect } from "react";

import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
} from "@justraviga/classmanager-sdk";
import { CheckoutRequestItems } from "@justraviga/classmanager-sdk/dist/models/CheckoutRequestItems";

import { useFormActions } from "shared/components";
import { formatMoneyFromInteger } from "shared/lib";

import { TakePayment } from "@/modules/common/payments/TakePayment";

interface CheckoutPaymentFormProps {
  amount: number;
  familyId: string;
  onSuccess?: (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => void;
  requestItems: CheckoutRequestItems;
}

export const CheckoutPaymentForm = ({
  familyId,
  amount,
  onSuccess,
  requestItems,
}: CheckoutPaymentFormProps) => {
  const { setCreateButtonText, setAllowCreateAdditional } = useFormActions();

  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText(`Pay ${formatMoneyFromInteger(amount)}`);
  }, [amount, setAllowCreateAdditional, setCreateButtonText]);

  return (
    <TakePayment
      familyId={familyId}
      amount={amount}
      onSuccess={onSuccess}
      requestItems={requestItems}
      operationType="checkout"
    />
  );
};
