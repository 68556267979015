import { CompanyRegistrationDto } from "@justraviga/classmanager-sdk";

export async function submitToHubSpot(data: CompanyRegistrationDto) {
  const portalId = "139761362";
  const formGuid = "882c0d64-ee1e-43ce-ac27-8b058d9eeb4a";

  const hubspotData = {
    fields: [
      {
        name: "email",
        value: data.user.email,
      },
      {
        name: "firstname",
        value: data.user.firstname,
      },
      {
        name: "lastname",
        value: data.user.lastname,
      },
      {
        name: "0-2/name", //company name
        value: data.account.company?.name,
      },
      {
        name: "phone", //contact phone
        value: data.account.company?.phone,
      },
      {
        name: "0-2/phone", // company phone
        value: data.account.company?.phone,
      },
    ],
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
  };

  try {
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(hubspotData),
      },
    );

    if (!response.ok) {
      console.error("HubSpot submission failed");
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error("Error submitting to HubSpot:", error);
    return null;
  }
}
