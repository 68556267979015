import { ReactNode } from "react";

import { cn, colors } from "shared/lib";

import { Icon, IconName } from "@/modules/common/ui/icon/Icon";

export const PreviewSheetContent = ({ children }: { children: ReactNode }) => {
  return <div className={cn("flex flex-col space-y-4 pb-8")}>{children}</div>;
};

PreviewSheetContent.title = ({ title }: { title: string }) => {
  return (
    <p className="line-clamp-1 text-heading6-600 text-grey-900">{title}</p>
  );
};

PreviewSheetContent.subTitle = ({
  title,
  icon,
  shouldTruncate,
}: {
  title: string;
  icon?: IconName;
  shouldTruncate?: boolean;
}) => {
  return (
    <div
      className={cn("flex flex-row items-center space-x-2 text-grey-900", {
        truncate: shouldTruncate,
      })}>
      {icon && <Icon name={icon} />}
      <p
        className={cn("truncate text-body-600", {
          truncate: shouldTruncate,
        })}>
        {title}
      </p>
    </div>
  );
};

PreviewSheetContent.label = ({ content }: { content: string }) => (
  <p className="text-label-400 text-grey-600">{content}</p>
);

PreviewSheetContent.detailCard = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col space-y-4 text-grey-600">{children}</div>
  );
};

PreviewSheetContent.infoRow = ({
  text,
  spacing = 2,
}: {
  text?: string;
  spacing?: 0 | 1 | 2;
}) => {
  return (
    <p
      className={cn("text-body-400 text-grey-600", {
        "mt-2": spacing === 2,
        "mt-1": spacing === 1,
        "mt-0": spacing === 0,
      })}>
      {text}
    </p>
  );
};

PreviewSheetContent.infoPairRow = ({
  left,
  right,
  justify = "between",
}: {
  left: string | ReactNode;
  right: string | ReactNode;
  justify?: "between" | "start";
}) => {
  return (
    <div
      className={cn("flex", {
        "justify-between": justify === "between",
        "justify-start space-x-2": justify === "start",
      })}>
      {typeof left == "string" ? (
        <p className="text-body-400 text-grey-600">{left}</p>
      ) : (
        left
      )}

      {typeof right == "string" ? (
        <p className="text-body-400 text-grey-900">{right}</p>
      ) : (
        right
      )}
    </div>
  );
};

PreviewSheetContent.infoRowStatus = ({
  content,
  status,
}: {
  content: string | ReactNode;
  status: boolean;
}) => {
  return (
    <div className="flex items-center space-x-2">
      {typeof content === "string" ? (
        <p className={"text-body-400"}>{content}</p>
      ) : (
        content
      )}
      {status ? (
        <Icon name="checkmarkCircle" color={colors.green["600"]} />
      ) : (
        <Icon name="closeCircle" color={colors.grey["500"]} />
      )}
    </div>
  );
};
