import {
  ClickableSettingItem,
  mapSettingItemsToActions,
  SettingGroupKey,
  SettingItemKey,
  settingItems,
} from "shared/lib";

import { useCompanySettingsActions } from "@/modules/company/settings/useCompanySettingsActions";

export const useSettingItems = () => {
  const actions = useCompanySettingsActions();
  const itemActions = mapSettingItemsToActions({ actions });

  return {
    getItemsForGroup: (group: SettingGroupKey): Array<ClickableSettingItem> => {
      return Object.entries(settingItems)
        .filter(([_, item]) => item.group === group)
        .map(([key, item]) => ({
          ...item,
          onClick: itemActions[key as SettingItemKey],
        }));
    },
  };
};
