import { CourseDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../../../alertState";
import { BannerComponent } from "../../../interfaces";

export interface CourseAtCapacityBannerProps {
  Banner: BannerComponent;
  course: CourseDto;
}
export const CourseAtCapacityBanner = ({
  Banner,
  course,
}: CourseAtCapacityBannerProps) => {
  return (
    <Banner
      className={"text-label-400 text-grey-900"}
      content={`${course.name} has now reached capacity.`}
      variant={"error"}
      action={{
        text: "Join waiting list",
        onClick: () => {
          showAlert({
            content: "Not implemented yet",
          });
        },
      }}
    />
  );
};
