import { ReactNode } from "react";

import { AggregateClassDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { CourseItem } from "./CourseItem";
import { colors } from "../../../../colors";
import {
  DatatableFilterOperator,
  datatableQueryToRequest,
  getInitialQuery,
} from "../../../../datatable";
import {
  ClassEntity,
  classEntityFactory,
  mapDtoToEntity,
} from "../../../../entities";
import { dayjs } from "../../../../lib/dayjs";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { enumLabel } from "../../../../translateUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../../../interfaces";
import { useOrderedWeekDays } from "../../../useOrderedWeekDays";

interface FamilyCourseListProps {
  season: SeasonDto;
  today: dayjs.Dayjs;
  HorizontalSeparator: HorizontalSeparatorComponent;
  courseItem: (body: ReactNode, footer: ReactNode) => ReactNode;
}

export const FamilyCourseList = ({
  season,
  today,
  HorizontalSeparator,
  courseItem,
}: FamilyCourseListProps) => {
  // const { md: showWebSize } = useBreakpoint();
  const weekDays = useOrderedWeekDays();
  const { View, Text, Icon, Loader } = useGenericComponents();
  const { useApi } = getPlatformFunctions();

  const { data: familyMembers, isLoading: isFamilyMembersLoading } = useApi(
    "listStudent",
    {
      selectAll: true,
    },
  );

  const { data: classes, isLoading: isClassesLoading } = useApi("listCourse", {
    ...datatableQueryToRequest({
      ...getInitialQuery(),
      selectAll: true,
      sort: [
        {
          id: "startTime",
          desc: true,
        },
      ],
      filters: [
        {
          field: "seasonId",
          operator: DatatableFilterOperator.Equals,
          value: season.id,
        },
      ],
    }),
  });

  const { data: enrollments, isLoading: isEnrollmentsLoading } = useApi(
    "listEnrolment",
    {
      ...datatableQueryToRequest({
        ...getInitialQuery(),
        selectAll: true,
        filters: [
          {
            field: "studentId",
            operator: DatatableFilterOperator.In,
            value: familyMembers?.data.map(member => member.id) ?? [],
          },
          {
            field: "classId",
            operator: DatatableFilterOperator.In,
            value:
              classes?.data.map(classEntity => classEntity.entity.id) ?? [],
          },
        ],
      }),
    },
    {
      enabled:
        !isFamilyMembersLoading &&
        (familyMembers?.data.length ?? 0) > 0 &&
        !isClassesLoading &&
        (classes?.data.length ?? 0) > 0,
    },
  );

  const isLoading =
    isFamilyMembersLoading || isClassesLoading || isEnrollmentsLoading;

  if (isLoading) {
    return (
      <View className={"flex items-center justify-center py-6"}>
        <Loader />
      </View>
    );
  }

  if (!classes || !enrollments || !familyMembers) {
    return (
      <View
        className={
          "mt-5 flex flex-col items-center justify-center space-y-3 p-2"
        }>
        <View className={"rounded-full bg-brand-100 p-2"}>
          <Icon
            color={colors.brand["800"]}
            name={"helpCircleOutline"}
            size={24}
          />
        </View>
        <Text className={"text-heading6-600"}>No classes yet</Text>
        <Text className={"text-body-400 text-grey-600"}>
          You haven't enrolled for any classes in this season.
        </Text>
      </View>
    );
  }

  const weekDayItems = weekDays
    .map(dayOfWeek => {
      const groupItems = mapDtoToEntity<AggregateClassDto, ClassEntity>(
        classes,
        classEntityFactory,
      )
        .data.map(course => ({
          entity: course,
          enrollments:
            enrollments.data
              .filter(enrolment => {
                return (
                  enrolment.enrolment.classId === course.class.id &&
                  !enrolment.adjustments?.find(
                    adjustment =>
                      adjustment.reason === "cancelled-enrolment" &&
                      adjustment.studentId === enrolment.enrolment.studentId &&
                      dayjs(adjustment.startAt).endOf("day").isBefore(today),
                  )
                );
              })
              .map(enrolment => enrolment.enrolment) ?? [],
        }))
        .filter(({ entity: course, enrollments }) => {
          return !course.isArchived() && enrollments.length > 0;
        })
        .sort((a, b) => {
          const aStartTime = a.entity.class.startTime;
          const bStartTime = b.entity.class.startTime;
          if (aStartTime < bStartTime) {
            return -1;
          }
          if (aStartTime > bStartTime) {
            return 1;
          }
          return 0;
        })
        .filter(({ entity: course }) => course.class.dayOfWeek === dayOfWeek);
      return {
        title: enumLabel("weekDays", dayOfWeek),
        titleAddition: `(${groupItems.length} ${groupItems.length === 1 ? "class" : "classes"})`,
        items: groupItems,
      };
    })
    .filter(group => group.items.length > 0);

  return (
    <View className={"pb-4 pt-6"}>
      {weekDayItems.map((weekday, index) => (
        <View key={weekday.title}>
          <Text className={"text-sm text-grey-600"}>
            {weekday.title} {weekday.titleAddition}
          </Text>
          <View className={"mb-2"}>
            {weekday.items.map(item => (
              <CourseItem
                key={item.entity.class.id}
                item={item}
                familyMembers={familyMembers.data}
                courseItem={courseItem}
              />
            ))}
          </View>
          {index !== weekDayItems.length - 1 ? (
            <HorizontalSeparator spacing={5} />
          ) : null}
        </View>
      ))}
    </View>
  );
};
