import { UpdateRoomRequest } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = UpdateRoomRequest;

export const useRoomFormDefinition = () => {
  return new FormDefinitionBuilder<Schema>()
    .text("name", { label: "Name", required: true })
    .getDefinition() as FormDefinition<Schema>;
};

export const makeRoomCreateRequest =
  ({ api, locationId }: { api: Api; locationId: string }) =>
  (formData: Schema) => {
    return api.rooms.createRoom({
      updateRoomRequest: {
        ...formData,
        locationId,
      },
    });
  };

export const makeRoomUpdateRequest =
  ({ api, id, locationId }: { api: Api; id: string; locationId: string }) =>
  (formData: Schema) => {
    return api.rooms.updateRoom({
      id,
      updateRoomRequest: {
        ...formData,
        locationId,
      },
    });
  };
