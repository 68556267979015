import { SortSchema } from "@justraviga/classmanager-sdk";
import { CreateManualPaymentTransactionRequest } from "@justraviga/classmanager-sdk/dist/models/CreateManualPaymentTransactionRequest";
import { ManualPaymentType } from "@justraviga/classmanager-sdk/dist/models/ManualPaymentType";

import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { moneyFloatToInteger } from "../../moneyUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { useCurrencySymbol } from "../useCurrencySymbol";

type CreateSchema = CreateManualPaymentTransactionRequest;

export const makeRecordPaymentForm =
  ({ familyId }: { familyId?: string }) =>
  () => {
    const currencySymbol = useCurrencySymbol();
    const { useApi } = getPlatformFunctions();
    const { data: familyList } = useApi("listFamily", {
      selectAll: true,
      sort: {
        name: SortSchema.Asc,
      },
    });
    const paymentMethods = Object.values(ManualPaymentType);

    const builder = new FormDefinitionBuilder<CreateSchema>().text(
      "description",
      {
        label: "Description",
      },
    );

    if (!familyId) {
      builder.select("familyId", {
        label: "Select family",
        required: true,
        data: (familyList?.data ?? []).map(family => ({
          label: family.name,
          value: family.id,
        })),
        localSearch: true,
      });
    }

    builder
      .select("paymentMethod", {
        label: "Payment method",
        data: paymentMethods.map(method => ({
          label: method,
          value: method,
        })),
      })
      .decimal("amount", {
        label: "Amount",
        required: true,
        decimalPlaces: 2,
        prefix: currencySymbol,
      })
      .date("date", {
        label: "Received on",
        required: true,
      })
      .group("Billing information", ["amount", "date"]);

    return builder.getDefinition();
  };

export const makeRecordPaymentRequest = ({
  familyId,
}: {
  familyId?: string;
}) => {
  const { api } = getPlatformFunctions();
  return (formData: CreateSchema) => {
    return api.transactions.createManualPaymentTransaction({
      createManualPaymentTransactionRequest: {
        ...formData,
        amount: moneyFloatToInteger(formData.amount),
        familyId: familyId ?? formData.familyId,
      },
    });
  };
};
