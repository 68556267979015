import { WaiverDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Model = WaiverDto;

/**
 * These actions don't need any frontend so can be shared
 */
export const useSharedWaiverActions = (
  defaultActions: DefaultEntityActions<Model>,
  api: Api,
) => {
  return {
    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.waivers.deleteWaiver({ id: entity.id }),
      ),

    archive: (entity: Model) =>
      defaultActions.archive(() =>
        api.waivers.archiveWaiver({ id: entity.id }),
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.waivers.unarchiveWaiver({ id: entity.id }),
      ),
  };
};
