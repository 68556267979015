import { IconName } from "../../availableIcons";
import { colors } from "../../colors";
import { cn } from "../../cssUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export interface QuickActionButtonProps {
  icon: IconName;
  text?: string;
  onClick: () => void;
  variant: "brand" | "default";
  size?: "sm" | "md";
}

export const QuickActionButton = ({
  icon,
  text,
  onClick,
  variant,
  size = "md",
}: QuickActionButtonProps) => {
  const { Text, View, Icon, Pressable } = useGenericComponents();

  return (
    <Pressable onClick={onClick} className="cursor-pointer">
      <View
        className={cn(
          "rounded-full flex items-center justify-center mb-2 mx-auto",
          {
            "bg-brand-800 hover:bg-brand-700": variant === "brand",
            "bg-grey-200 hover:bg-grey-300": variant === "default",
          },
          {
            "w-12 h-12": size === "md",
            "w-10 h-10": size === "sm",
          },
        )}>
        <Icon
          name={icon}
          size={24}
          color={variant === "brand" ? colors.white : colors.grey[900]}
        />
      </View>
      {text && (
        <Text className="text-grey-900 text-label-400 text-center hover:text-grey-700">
          {text}
        </Text>
      )}
    </Pressable>
  );
};
