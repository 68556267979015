import { WaiverEntityWithAgreement } from "shared/lib";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { useWaiverAgreementActions } from "@/modules/company/waivers/useWaiverAgreementActions";
import { WaiverAgreementChip } from "@/modules/company/waivers/WaiverAgreementChip";
import { WaiverCardContent } from "@/modules/company/waivers/WaiverCardContent";
import { WaiverCardTitle } from "@/modules/company/waivers/WaiverCardTitle";

export interface WaiverAgreementCardProps {
  waiver: WaiverEntityWithAgreement;
}

export const WaiverAgreementCard = ({ waiver }: WaiverAgreementCardProps) => {
  const { showDetail } = useWaiverAgreementActions();

  const openWaiver = () => {
    showDetail(waiver);
  };

  return (
    <BaseCard
      title={<WaiverCardTitle>{waiver.props.name}</WaiverCardTitle>}
      onClick={openWaiver}
      bodySlot={<WaiverCardContent content={waiver.props.content} />}
      footerSlot={
        <div className="flex justify-start pt-2">
          <WaiverAgreementChip waiver={waiver} />
        </div>
      }
    />
  );
};
