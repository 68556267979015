import { createContext, MutableRefObject } from "react";

interface AlertContextImplType {
  alertElementsMapRef: MutableRefObject<Map<string, HTMLElement>>;
  sortAlerts: () => void;
}

export const AlertContextImpl = createContext(
  undefined as unknown as AlertContextImplType,
);
