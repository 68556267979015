import { PropsWithChildren, useEffect } from "react";

import { useFamilyCompany } from "shared/components";

import { setCompanyGlobals } from "@/lib/setCompanyGlobals";
import { FamilyBackground } from "@/modules/common/layout/FamilyBackground";
import { FamilyContent } from "@/modules/common/layout/FamilyContent";
import { FamilyFooter } from "@/modules/common/layout/FamilyFooter";
import {
  FamilyHeader,
  FamilyHeaderProps,
} from "@/modules/common/layout/FamilyHeader";

interface PublicLayoutProps extends PropsWithChildren {
  branded?: boolean;
  backButton?: FamilyHeaderProps["backButton"];
}

export const PublicLayout = ({ children, backButton }: PublicLayoutProps) => {
  const { company } = useFamilyCompany();

  const companySettings = company.settings;

  useEffect(() => {
    if (companySettings) {
      setCompanyGlobals(companySettings);
    }
  }, [companySettings]);

  return (
    <FamilyBackground>
      <FamilyHeader backButton={backButton} />
      <FamilyContent>{children}</FamilyContent>
      <FamilyFooter />
    </FamilyBackground>
  );
};
