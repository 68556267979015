import { Row } from "@tanstack/react-table";

import {
  DatatableConfiguration,
  DatatableMenuAction,
  DatatableRow,
  Dto,
} from "shared/lib";

import { CheckboxCellPlaceholder } from "@/modules/common/datatable/placeholders/CheckboxCellPlaceholder";
import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";

export const rowActionsColumn = <T extends Dto, RowDto extends T = T>({
  rowActions,
  permissions,
}: {
  rowActions: Required<DatatableConfiguration<T, object, RowDto>>["rowActions"];
  permissions?: Required<
    DatatableConfiguration<T, object, RowDto>
  >["permissions"];
}) => {
  return {
    id: "actions",
    size: 38,
    meta: {
      align: "left",
      placeholder: <CheckboxCellPlaceholder />,
    },
    cell: ({ row }: { row: Row<DatatableRow<RowDto>> }) => {
      const additionalRowActions = rowActions?.additionalRowActions
        ? rowActions?.additionalRowActions(row.original.item)
        : [];
      const actions = additionalRowActions.filter(action =>
        "enabled" in action && action.enabled
          ? action.enabled(row.original.item)
          : true,
      );
      if (rowActions.edit) {
        actions.push({
          icon: "createOutline",
          label: "Edit",
          onClick: rowActions.edit,
          permission: permissions?.edit,
        });
      }
      if (
        rowActions.archive &&
        "archivedAt" in row.original.item &&
        !row.original.item.archivedAt
      ) {
        actions.push({
          icon: "archiveOutline",
          label: "Archive",
          onClick: rowActions.archive,
          permission: permissions?.archive,
        });
      }
      if (
        rowActions.restore &&
        "archivedAt" in row.original.item &&
        row.original.item.archivedAt
      ) {
        actions.push({
          icon: "reloadOutline",
          label: "Restore",
          onClick: rowActions.restore,
          permission: permissions?.restore,
        });
      }

      if (rowActions.delete) {
        const isActionEnabled =
          rowActions.deleteEnabled !== undefined
            ? rowActions.deleteEnabled(row.original.item)
            : true;

        if (isActionEnabled) {
          actions.push({
            icon: "trashOutline",
            label: "Delete",
            onClick: rowActions.delete,
            isDestructive: true,
            permission: permissions?.delete,
          });
        }
      }

      const mapActionToMenuItem = (
        action: DatatableMenuAction<RowDto>,
      ): ActionMenuItemProps => {
        return {
          leftIcon: action.icon,
          title:
            typeof action.label === "string"
              ? action.label
              : action.label(row.original.item),
          description: action.description,
          destructive: action.isDestructive,
          hasDestructiveStyle: action.hasDestructiveStyle,
          onClick: () => action.onClick(row.original.item),
          disabled: action.enabled ? !action.enabled(row.original.item) : false,
          permission: action.permission,
        };
      };

      /**
       * If any of the actions have an icon, we want to show the wide menu to make sure there is
       * enough space for the text still
       */
      const showWideMenu = actions.some(action => "icon" in action);

      return (
        <div className="flex h-full items-start justify-end pr-3 md:items-center md:pr-1">
          <ActionMenu
            width={showWideMenu ? "w-80" : "w-56"}
            header={
              rowActions.title ? (
                <div className="truncate text-heading6-600 text-grey-900">
                  {rowActions.title(row.original.item)}
                </div>
              ) : undefined
            }
            showActionsAsBottomSheetOnMobile={true}
            trigger={
              <div className="-mt-2 md:mt-0">
                <IconButton icon={"ellipsisHorizontal"} variant={"standard"} />
              </div>
            }
            items={actions.map((action): ActionMenuItemProps => {
              if ("actions" in action) {
                return {
                  title: action.title,
                  group: {
                    items: action.actions.map(mapActionToMenuItem),
                  },
                };
              }

              return mapActionToMenuItem(action);
            })}
          />
        </div>
      );
    },
  };
};
