import {
  CourseDto,
  LessonAdjustmentDto,
  LessonDto,
} from "@justraviga/classmanager-sdk";

import {
  calculateEndTime,
  colors,
  formatDate,
  formatTime,
  getCancelledAdjustment,
  timeToUrlParam,
} from "shared/lib";

import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { Banner } from "@/modules/common/ui/Banner";
import { Button } from "@/modules/common/ui/button/Button";
import { useLessonActionMenuItems } from "@/modules/company/classPlanner/lessons/menus/useLessonActionMenuItems";
import { useLessonActions } from "@/modules/company/classPlanner/lessons/useLessonActions";
import { Router } from "@/routing/router";

export const LessonDetailsHeader = ({
  lesson,
  courseDto,
  date,
  time,
  progress = 0,
  recordCount,
  isCancelled,
  isHoliday,
  isDesktop,
}: {
  lesson: LessonDto;
  courseDto: CourseDto;
  date: string;
  time: string;
  progress: number;
  recordCount: number;
  isCancelled: boolean;
  isHoliday: boolean;
  isDesktop: boolean;
}) => {
  const startTime = time;
  const endTime = calculateEndTime(startTime, courseDto.durationInMinutes);
  const { actions } = useLessonActionMenuItems({
    lesson,
    isCancelled,
    isLessonDetailsPage: true,
  });
  const cancelledAdjustment = getCancelledAdjustment(lesson);

  return (
    <div className={"space-y-4"}>
      <section className={"flex items-start justify-between"}>
        <div className={"space-y-4"}>
          <div className={"flex items-center"}>
            <p className={"text-heading5-600"}>
              {formatDate(date, "weekdayShortDayMonthYear")}
            </p>
          </div>
          {!isHoliday && (
            <div className={"text-grey-600"}>
              {formatTime(startTime, "hourMinute")} -{" "}
              {formatTime(endTime, "hourMinute")}
            </div>
          )}
        </div>

        {!isHoliday && (
          <div className={"flex space-x-4"}>
            <div className={"hidden md:flex"}>
              <ActionMenu
                header={
                  <h3 className={"text-heading6-600 text-grey-900"}>
                    {formatDate(date, "weekdayShortDayMonthYear")}
                  </h3>
                }
                width={"w-80"}
                trigger={
                  <Button
                    text={"Actions"}
                    size={"sm"}
                    rightIcon={"chevronDown"}
                    variant={"secondary"}
                  />
                }
                items={actions}
              />
            </div>

            {isDesktop && !isCancelled && (
              <TakeAttendanceButton
                courseDto={courseDto}
                date={date}
                time={time}
              />
            )}
          </div>
        )}
      </section>

      {isCancelled && (
        <CancelledLessonBanner cancelledAdjustment={cancelledAdjustment} />
      )}

      {!isCancelled && !isHoliday && (
        <section className={"space-y-2 md:space-y-3"}>
          <div className={"flex items-center justify-between"}>
            <h3 className={"text-heading6-600 text-grey-900"}>Students</h3>

            {!isDesktop && (
              <TakeAttendanceButton
                courseDto={courseDto}
                date={date}
                time={time}
              />
            )}
          </div>

          <div className={"flex items-center justify-between py-2"}>
            <p className={"text-label-400"}>
              {recordCount} record{recordCount === 1 ? "" : "s"}
            </p>

            {!isCancelled && <AttendanceProgress progress={progress} />}
          </div>
        </section>
      )}
    </div>
  );
};

const CancelledLessonBanner = ({
  cancelledAdjustment,
}: {
  cancelledAdjustment?: LessonAdjustmentDto;
}) => {
  const { undoCancelledLesson } = useLessonActions();

  return (
    <section>
      <Banner
        icon={{ name: "warning", color: colors.white }}
        variant={"important"}
        content={
          <p className={"text-label-400"}>This lesson has been cancelled.</p>
        }
        action={{
          text: "Undo",
          onClick: () => {
            cancelledAdjustment && undoCancelledLesson(cancelledAdjustment);
          },
        }}
      />
    </section>
  );
};

const AttendanceProgress = ({ progress }: { progress: number }) => (
  <div className={"flex space-x-2"}>
    <span className={"text-label-400 text-grey-900"}>Attendance</span>
    <span className={"text-label-400 text-grey-600"}>{progress}% complete</span>
  </div>
);

const TakeAttendanceButton = ({
  courseDto,
  date,
  time,
}: {
  courseDto: CourseDto;
  date: string;
  time: string;
}) => {
  const navigateToTakeAttendance = () => {
    Router.push("ClassLessonAttendances", {
      id: courseDto.id,
      date: date,
      time: timeToUrlParam(time),
    });
  };

  return (
    <Button
      text={"Take attendance"}
      size={"xs"}
      variant={"brand"}
      onClick={navigateToTakeAttendance}
    />
  );
};
