import { PricingSchemeValueDto } from "@justraviga/classmanager-sdk";

import { useSharedPricingTierActions } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { CreateFooter } from "@/modules/common/form/CreateFooter";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";

type Model = PricingSchemeValueDto;

export const usePricingTierActions = () => {
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "pricingSchemeValue",
  });

  const { openSheet } = useSheet();

  return useSharedPricingTierActions(
    api,
    defaultActions,
    openSheet,
    CreateFooter,
    UpdateFooter,
  );
};
