import { useGenericComponents } from "../../../GenericComponentsProvider";

export const NoAccountsPlaceholder = () => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"text-center"}>
      <Text className={"text-body-400 text-grey-600"}>
        This Class Manager user account isn't linked to any companies.
      </Text>
    </View>
  );
};
