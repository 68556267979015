import { CircularIndicatorProps, cn } from "shared/lib";

export const CircularIndicator = ({
  value,
  maxValue,
  className,
}: CircularIndicatorProps) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const strokeVal =
    value < maxValue
      ? ((value / maxValue) * circumference).toFixed(2)
      : circumference - 0.1;

  return (
    <div
      className={cn(
        "relative flex h-24 w-24 items-center justify-center",
        className,
      )}>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          className={`stroke-grey-400`}
          strokeWidth="10"
          strokeDasharray={`${circumference} ${circumference}`}
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          strokeDasharray={`${strokeVal} ${circumference}`}
          strokeWidth="10"
          className={`stroke-brand-800`}
          transform="rotate(-90 50 50)"
        />
      </svg>
      <div className="absolute text-label-400 text-grey-900">
        {value}/{maxValue}
      </div>
    </div>
  );
};
