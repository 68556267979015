import {
  createEnrolmentStartDate,
  DetailedEnrolment,
} from "../../enrolmentUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { UpdateEnrolmentEndForm } from "../enrolments/UpdateEnrolmentEndForm";
import {
  EnrolmentKey,
  UpdateEnrolmentStartForm,
} from "../enrolments/UpdateEnrolmentStartForm";
import {
  CreateFormActionSchema,
  makeEnrolmentAddForm,
  makeEnrolmentAddRequest,
} from "../formDefinitions/enrolmentForm";
import { useGenericComponents } from "../GenericComponentsProvider";

type Dto = DetailedEnrolment;

export const useEnrolmentsActions = () => {
  const { api, useApi, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const defaultActions = usePlatformEntityActions<Dto>({
    entity: "enrolment",
  });

  return {
    deleteOne: (entity: Dto) =>
      defaultActions.deleteOne(() =>
        api.enrolments.deleteEnrolment({ id: entity.id }),
      ),

    showCreateForm: ({
      course,
      season,
      studentIds = [],
      showStudentSelector = true,
    }: CreateFormActionSchema) => {
      const dateStart = season ? createEnrolmentStartDate(season) : undefined;

      return defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeEnrolmentAddRequest({
              api,
              classId: course?.entity?.id,
              season,
            })}
            defaultValues={{
              seasonId: season?.id,
              classId: course?.entity?.id,
              startAt: dateStart,
              endAt: season?.endAt,
              studentIds,
            }}
            formDefinitionHook={makeEnrolmentAddForm({
              useApi,
              classId: course?.entity?.id,
              seasonId: season?.id,
              showStudentSelector,
            })}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      });
    },

    showUpdateStartForm: ({
      date,
      classId,
      studentId,
    }: EnrolmentKey & { date: string }) =>
      defaultActions.showUpdateForm({
        title: "Edit start date",
        form: (
          <UpdateEnrolmentStartForm
            date={date}
            classId={classId}
            studentId={studentId}
          />
        ),
      }),

    showUpdateEndForm: ({
      date,
      classId,
      studentId,
    }: EnrolmentKey & { date: string }) => {
      return defaultActions.showUpdateForm({
        title: "Edit end date",
        form: (
          <UpdateEnrolmentEndForm
            date={date}
            classId={classId}
            studentId={studentId}
          />
        ),
      });
    },
  };
};
