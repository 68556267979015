/* eslint-disable check-file/filename-naming-convention */
import React from "react";

import {
  CompanyIntegrationStripeSettingsDto,
  StripeRegion,
} from "@justraviga/classmanager-sdk";
import { Stripe } from "@stripe/stripe-js";

export interface StripeConfig {
  publishableKey: {
    [StripeRegion.Us]: string;
    [StripeRegion.Gb]: string;
  };
}

export interface StripeContext {
  stripe: Stripe | null;
  setStripeIntegration: (
    stripeIntegration: CompanyIntegrationStripeSettingsDto,
  ) => void;
}

// This must be undefined so we can catch it in the hook
export const StripeContext = React.createContext<StripeContext>(
  undefined as unknown as StripeContext,
);
