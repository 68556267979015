import { useContext } from "react";

import { CardContainerContext } from "@/modules/common/cardContainer/cardContainerContext";

export function useCardContainer() {
  const context = useContext(CardContainerContext);
  if (!context) {
    throw new Error(
      "useCardContainer() must be used within a <CardContainer /> component",
    );
  }
  return context;
}
