import {
  DatatableContentState,
  fetchAndSelectAll,
  useDatatable,
} from "shared/lib";

import { Button } from "@/modules/common/ui/button/Button";

export const MobileSelectButton = ({
  contentState,
  totalResults,
}: {
  contentState: DatatableContentState;
  totalResults: number;
}) => {
  const { datatable, selectMode, setSelectMode, modelSelection, query } =
    useDatatable();

  if (
    contentState !== DatatableContentState.Loaded ||
    !datatable?.groupActions
  ) {
    return <></>;
  }

  if (!selectMode) {
    return (
      <Button
        text={"Select"}
        variant={"tertiary"}
        className={"px-1"}
        size={"sm"}
        onClick={() => {
          setSelectMode(true);
        }}
      />
    );
  }

  const allSelected = modelSelection.count() === totalResults;

  return (
    <Button
      text={allSelected ? "Deselect All" : "Select All"}
      variant={"tertiary"}
      className={"px-1"}
      size={"sm"}
      onClick={() => {
        if (allSelected) {
          modelSelection.clear();
        } else {
          fetchAndSelectAll(query, datatable, modelSelection);
        }
      }}
    />
  );
};
