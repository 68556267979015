import clarity from "@microsoft/clarity";

import { clarityEnabled } from "@/lib/clarity/clarityEnabled";

if (clarityEnabled()) {
  try {
    clarity.init("p1zv58y8wj");
  } catch (e) {
    // we don't want to the apps to crash if this fails
    console.error(e);
  }
}
