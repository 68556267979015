import { useState } from "react";

import { RegisterUserRequest } from "@justraviga/classmanager-sdk";

import {
  useAuthState,
  useFamilyCompany,
  useUserRegisterForm,
} from "shared/components";
import { displayApiValidationErrors } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import {
  ClassManagerPrivacyPolicy,
  ClassManagerTermsAndConditions,
} from "@/modules/auth/common/ViewPolicy";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Button } from "@/modules/common/ui/button/Button";
import { Router } from "@/routing/router";

export const UserRegistrationForm = () => {
  const { company } = useFamilyCompany();
  const { setUser } = useAuthState();

  const [isLoading, setLoading] = useState(false);
  const registerUser = async (registerUserRequest: RegisterUserRequest) => {
    setLoading(true);

    api.auth
      .registerUser({
        registerUserRequest,
      })
      .then(({ user, token }) => {
        if (user && user.id && token) {
          setUser(user, token);
          Router.push("CatalogueCreateAccount", {
            slug: company.slug,
          });
        }
      })
      .catch(e => {
        // @ts-expect-error - This is probably fixable, but it's not worth the effort
        displayApiValidationErrors(formHandlers)(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { form, formHandlers } = useUserRegisterForm(
    useFormBuilder,
    registerUser,
    {
      email: "",
      password: "",
    },
    false,
  );

  return (
    <>
      {form}
      <div className="flex flex-col gap-3">
        <p className="text-label-400 text-grey-600">
          By clicking register you have read and agree with Class Manager{" "}
          <ClassManagerTermsAndConditions /> and <ClassManagerPrivacyPolicy />
        </p>
        <Button
          loading={isLoading}
          onClick={formHandlers.handleSubmit(registerUser)}
          variant="brand"
          text="Register"
          isFullWidth={true}
        />
      </div>
    </>
  );
};
