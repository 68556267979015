import { DiscountSchemeTierFormBannerProps } from "shared/components";
import {
  colors,
  getDiscountSchemeApplicationDetails,
  getDiscountSchemeDetails,
} from "shared/lib";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";

export const DiscountSchemeTierFormBanner = ({
  discountScheme,
  quantity,
}: DiscountSchemeTierFormBannerProps) => {
  const discountSchemeDetails = getDiscountSchemeDetails(discountScheme);
  const { bannerTitle, bannerDescription } =
    getDiscountSchemeApplicationDetails(discountScheme);

  return (
    <BaseCard
      fillColor={colors.grey["900"]}
      title={bannerTitle(quantity)}
      titleColor={"white"}
      titleWeight={600}
      titleSize={16}
      description={bannerDescription(quantity)}
      descriptionColor={"white"}
      icon={discountSchemeDetails.typeDetails.icon}
      iconPosition={"left"}
      iconColor={colors.white}
    />
  );
};
