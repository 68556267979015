import { useFamilyCompany } from "shared/components";

import { PreAuthCatalogueRoutes } from "@/routing/CatalogueRouter";
import { Router } from "@/routing/router";

export function useLoginReturnRoute() {
  const { company } = useFamilyCompany();
  const route = Router.getRoute(PreAuthCatalogueRoutes);

  if (!route) {
    return {
      name: "CompanyLandingPage" as const,
      slug: company.slug,
    };
  }

  return {
    name: route.name,
    ...route.params,
  };
}
