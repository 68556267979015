import { PropsWithChildren } from "react";

import { IdPrefix, idPrefix as Prefix } from "@justraviga/classmanager-sdk";
import { DocumentSchema } from "typesense/lib/Typesense/Documents";

export const searchableIdPrefixes = [
  Prefix.Staff,
  Prefix.Student,
  Prefix.Family,
  Prefix.Course,
  Prefix.Season,
] as const;
export type SearchableIdPrefix = Extract<
  IdPrefix,
  (typeof searchableIdPrefixes)[number]
>;
export type SearchableId = `${SearchableIdPrefix}_${string}`;

export const SearchCollection = {
  student: "student",
  family: "family",
  staff: "staff",
  course: "course",
  season: "season",
  location: "location",
} as const;
export type SearchCollection =
  (typeof SearchCollection)[keyof typeof SearchCollection];

export const GlobalSearchStatus = {
  // We haven't attempted to initialise yet
  PENDING: "pending",
  // Everything is working as expected
  OK: "ok",
  // We are loading search results
  LOADING: "loading",
  // There was an error loading results
  ERROR: "error",
  // We are unable to contact typesense due to missing apikey or other reason
  UNAVAILABLE: "unavailable",
  // the current user role doesn't shouldn't have search
  // functionality available
  HIDDEN: "hidden",
} as const;
export type GlobalSearchStatus =
  (typeof GlobalSearchStatus)[keyof typeof GlobalSearchStatus];

export interface SearchStatusContextType {
  status: GlobalSearchStatus;
}

export interface SearchDialogContextType {
  isSearchOpen: boolean;
  handleOpenChange: (isOpen: boolean) => void;
}

export interface SearchResultsContextType {
  searchResults: GlobalSearchResults;
}

export interface SearchContextType {
  search: (query: string | null) => void;
}

export const GlobalSearchResultsType = {
  clean: "clean",
  response: "response",
} as const;
export type GlobalSearchResultsType =
  (typeof GlobalSearchResultsType)[keyof typeof GlobalSearchResultsType];

export interface LinkedSearchResultType extends PropsWithChildren {
  id: SearchableId;
}

export interface SearchResultTitleType {
  title: string;
  count: number;
}

export interface SearchResultType {
  id: SearchableId;
  title: string;
  description: string | null;
  children: React.ReactNode;
}

export interface StudentSearchResultType {
  id: SearchableId;
  firstname: string;
  lastname: string;
  birthdate: string;
  profilePicture: string | null;
}

export interface StaffSearchResultType {
  id: SearchableId;
  firstname: string;
  lastname: string;
  email: string;
  profilePicture: string | null;
}

export interface StaffDocument extends DocumentSchema {
  id: SearchableId;
  companyId: string;
  email: string;
  firstname: string;
  lastname: string;
  permissionRequired: string;
  phone: string;
  profilePicture: string | null;
}

export interface CourseDocument extends DocumentSchema {
  id: SearchableId;
  companyId: string;
  name: string;
  startTime: string;
}
export interface LocationDocument extends DocumentSchema {
  id: SearchableId;
  companyId: string;
  name: string;
  openTime: string;
}
export interface SeasonDocument extends DocumentSchema {
  id: SearchableId;
  companyId: string;
  name: string;
  startAt: string;
  endAt: string;
}

export interface FamilyDocument extends DocumentSchema {
  id: SearchableId;
  companyId: string;
  name: string;
  permissionRequired: string;
  profilePicture: string | null;
}

export interface StudentDocument extends DocumentSchema {
  id: SearchableId;
  companyId: string;
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  permissionRequired: string;
  profilePicture: string | null;
}

export interface SearchCollectionResponse<T> {
  count: number;
  documents: T[];
}

export interface GlobalSearchData {
  [SearchCollection.family]: SearchCollectionResponse<FamilyDocument>;
  [SearchCollection.staff]: SearchCollectionResponse<StaffDocument>;
  [SearchCollection.student]: SearchCollectionResponse<StudentDocument>;
  [SearchCollection.season]: SearchCollectionResponse<SeasonDocument>;
  [SearchCollection.course]: SearchCollectionResponse<CourseDocument>;
  [SearchCollection.location]: SearchCollectionResponse<LocationDocument>;
}

export interface GlobalSearchResults {
  type: GlobalSearchResultsType;
  totalCount: number;
  data: GlobalSearchData;
}
