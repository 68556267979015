import { useLayoutEffect, useRef } from "react";

import * as RadixToast from "@radix-ui/react-toast";

import { AlertProps } from "shared/lib";

import { useAlertContext } from "@/modules/common/overlays/alert/useAlertContext";
import { Banner } from "@/modules/common/ui/Banner";

interface ToastProps extends AlertProps {
  id: string;
  onOpenChange: (open: boolean) => void;
}

export const Alert = ({
  id,
  content,
  action,
  icon,
  variant = "success",
  dismissible = true,
  show = false,
  onOpenChange,
  ...rest
}: ToastProps) => {
  const handleBannerClose = dismissible ? () => onOpenChange(false) : undefined;
  const ref = useRef<HTMLLIElement>(null);
  const context = useAlertContext();
  const { sortAlerts, alertElementsMapRef } = context;
  const alertElementsMap = alertElementsMapRef.current;

  useLayoutEffect(() => {
    if (ref.current) {
      alertElementsMap.set(id, ref.current);
      sortAlerts();
    }
  }, [id, sortAlerts, alertElementsMap]);

  return (
    <RadixToast.Root
      ref={ref}
      {...rest}
      className="ToastRoot"
      open={show}
      onOpenChange={onOpenChange}>
      <div className="ToastInner">
        <RadixToast.Description className="ToastDescription" asChild>
          <Banner
            content={content}
            className={"shadow"}
            variant={variant}
            action={action}
            onClose={handleBannerClose}
            icon={icon}
          />
        </RadixToast.Description>
      </div>
    </RadixToast.Root>
  );
};
