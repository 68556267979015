//@todo DELETE THIS WHEN CLASS FORM DEFINITION IS DONE
export function omit<T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> {
  const result = { ...obj }; // Shallow copy of the object
  keys.forEach(key => {
    delete result[key];
  });

  return result;
}
